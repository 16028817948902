import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import history from '~/services/history';

import Context from '~/context';
import { toast } from 'react-toastify';
import { requestForToken, onMessageListener } from '../../services/firebase';
import WhoIManage from './WhoIManage';
import WhoIFollow from './WhoIFollow';
// import ModalOptions from './ModalScreens/Options';
import SubHeader from '~/components/SubHeader';
import AvatarUpload from '~/components/AvatarUpload';
// import ModalWrapper from '~/components/ModalWrapper';
import Header from '~/components/Header';
import { signOut } from '~/store/modules/auth/authAction';
import {
  uploadProfileImage,
  fetchUserById,
  fetchUserByEmail,
  followUserByEmail,
  getNotificaitonData,
} from '~/store/modules/user/userAction';

import {
  Container,
  StyledTabs as Tabs,
  StyledTab as Tab,
  UserInfo,
  UserData,
  SubHeaderDesktop,
  LoaderDiv,
  LoadingText,
} from './styles';
import CircularIndeterminate from '~/components/Loader';
import { fetchEventImageByIdDone } from '~/store/modules/event/eventAction';
import {
  fetchThankuCard,
  fetchThankuCardPending,
  getNotificationByUserId,
  getNotificationByUserIdDone,
} from '~/store/modules/notification/notificationAction';

function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MyProfile() {
  const Dispatch = useDispatch();
  const profile = useSelector((state) => state.userReducer.profile);
  const notification = useSelector(
    (state) => state.notificationReducer.notifications,
  );
  const { setFlow } = useContext(Context);
  const { colors } = useContext(ThemeContext);
  const tabs = [<WhoIManage />, <WhoIFollow />];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [value, setValue] = useState(0);
  const [loader, setLoader] = useState(false);
  // var socket;
  if (!profile) {
    Dispatch(signOut());
  }
  const handleChange = (event, newValue) => {
    setActiveTab(tabs[newValue]);
    setValue(newValue);
  };

  useEffect(() => {
    localStorage.removeItem('follow');
    localStorage.removeItem('giftData');
    localStorage.removeItem('cardData');
    localStorage.removeItem('addinfo');
    localStorage.removeItem('gift');
    localStorage.removeItem('cardPendingData');
    setFlow({ onboarding: false, giftOption: '' });
  }, []);

  useEffect(() => {
    setLoader(true);
    Dispatch(fetchEventImageByIdDone(''));
    Dispatch(fetchUserById(profile.id));
    Dispatch(followUserByEmail(profile.email));
    Dispatch(fetchThankuCard(profile.id));
    Dispatch(fetchThankuCardPending(profile.id));

    if (profile && profile) {
      setLoader(false);
    }
    localStorage.removeItem('close');
    // if (notification === null) {
    Dispatch(getNotificationByUserId(profile.id));
    // }
  }, [Dispatch, profile.id]);

  const handleProfileImage = (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    Dispatch(uploadProfileImage(profile.id, formData));
  };

  if (profile.account_status === 'REGISTERED') {
    history.push('/welcome');
  }

  return (
    <>
      <Header />
      <SubHeader
        backgroundColor={colors.lightPurple}
        mobileDescVisible
        mobileShowMenu
        mobileShowNotifications
        more
        className="hide-desktop"
      >
        <h2>My Profile</h2>
      </SubHeader>
      <SubHeaderDesktop className="hide-mobile" />
      <UserInfo>
        <AvatarUpload
          image={profile && profile.user_image}
          gender={profile && profile.gender}
          onChange={handleProfileImage}
        />
        <UserData>
          <h2>
            {profile && profile.first_name} {profile && profile.last_name}
          </h2>
        </UserData>
      </UserInfo>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Who I Manage" key={a11yProps(0).key} id={a11yProps(0).id} />
        <Tab label="Who I Follow" key={a11yProps(1).key} id={a11yProps(1).id} />
      </Tabs>
      <Container>
        {!loader ? (
          activeTab
        ) : (
          <LoaderDiv>
            <CircularIndeterminate />
            <LoadingText>Loading...</LoadingText>
          </LoaderDiv>
        )}
      </Container>
      {/* <ModalWrapper>
        <ModalOptions />
      </ModalWrapper> */}
    </>
  );
}
