import React from 'react';
import { useSelector } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import { IoIosAdd } from 'react-icons/io';
import history from '~/services/history';
import Avatar from '~/components/Avatar';
import EmptyList from './EmptyList';

import { Container, Card } from './styles';

export default function WhoIManage() {
  const receivers = useSelector((state) => state.userReducer.profile.receiver);
  const checkedOnboarding = localStorage.getItem('onbording');

  const handleAddGift = () => {
    if (checkedOnboarding === 'later') {
      history.push('/add-receiver');
    } else {
      const nextPage = receivers.length ? '/add-receiver' : '/onboarding';
      history.push(nextPage);
    }
  };

  const handleOpenReceiver = (id) => {
    history.push(`/receiver-profile/${id}`);
  };

  return (
    <>
      {receivers && receivers.length > 0 ? (
        <Container>
          {receivers.map((receiver) => (
            <Card
              key={receiver.id}
              onClick={() => handleOpenReceiver(receiver.id)}
              className='chlidprofile'
            >
              <Avatar image={receiver.child_image} size={55} />
              <div>
                <h3>
                  {receiver.type === 'individual'
                    ? receiver.first_name
                    : receiver.group_name}{' '}
                  {receiver.last_name}
                </h3>
                <small>{receiver.location}</small>
              </div>
            </Card>
          ))}
        </Container>
      ) : (
        <EmptyList />
      )}
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => handleAddGift()}
        className={receivers && receivers.length ? '' : 'show-indicator'}
      >
        <IoIosAdd color="#fff" size={45} />
      </Fab>
    </>
  );
}
