import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 300px;
  padding: 20px;

  @media (max-width: 767px) {
    height: 170px;
    padding: 0;
  }

  p {
    font-size: 18px;
    text-align: center;
    color: #888;
    font-weight: bold;
    max-width: 400px;
    margin: 0 auto;

    @media (max-width: 767px) {
      max-width: 200px;
      font-size: 16px;
      line-height: 1.2;
    }
  }
`;
