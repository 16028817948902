import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Input from '~/components/Input';
import UploadButton from '~/components/UploadButton';

import {
  Container,
  InputColumn,
  InputWrapper,
  DeleteIcon,
  ImageDiv,
} from './styles';
import { IoIosClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import {
  uploadAccountImage,
  uploadAccountImagee,
  uploadProfileImage,
} from '~/api/imageAPI';
import { toast } from 'react-toastify';
import CircularIndeterminate from '~/components/Loader';

export default function SecondForm({ formData }) {
  const {
    streetAddress,
    suburb,
    postCode,
    setStreetAddress,
    setSuburb,
    setPostCode,
    giftImageFile,
    setGiftImageFile,
    giftImageFileback,
    setGiftImageFileBack,
    frontImageFile,
    backImageFile,
    setFrontImageFile,
    setBackImageFile,
    phone,
    setPhone,
    city,
    setCity,
    states,
    setStates,
  } = formData;

  const [file, setFile] = useState();
  const [fileBack, setFileBack] = useState();
  const [fileFrontLoader, setFileFrontLoader] = useState(false);
  const [fileBackLoader, setFileBackLoader] = useState(false);

  useEffect(() => {
    document.querySelector('.subheader-main').scrollIntoView();
  }, []);

  const userProfile = useSelector((state) => state.userReducer.profile);
  const { id } = userProfile;

  const changeImageName = (ImageName, ImageType) => {
    if (ImageName) {
      const currentTimeStamp = Date.now();
      const extension = ImageName.name.substring(
        ImageName.name.lastIndexOf('.'),
      );
      const newName = `${ImageType}-${id}-${currentTimeStamp}${extension}`;
      const modifiedFile = new File([ImageName], newName, {
        type: ImageName.type,
      });
      return modifiedFile;
    }
  };

  const handleGiftImage = async (event) => {
    const file = event.target.files[0];
    // Check if file size is less than 10MB
    if (file && file.size < 10 * 1024 * 1024) {
      setFileFrontLoader(true);
      setFile(URL.createObjectURL(file));
      setGiftImageFile(file);
      const appendFrontImage = changeImageName(file, 'Front');
      const formData = new FormData();
      formData.append('file', appendFrontImage);
      await uploadAccountImage(id, formData)
        .then((res) => {
          setFrontImageFile(res.data.path);
          setFileFrontLoader(false);
        })
        .catch((err) => {
          setFileFrontLoader(false);
        });
    } else {
      setFileFrontLoader(false);
      toast.error('Please select the document smaller than 10MB.');
    }
  };

  const handleGiftImageback = async (event) => {
    const file = event.target.files[0];
    // Check if file size is less than 10MB
    if (file && file.size < 10 * 1024 * 1024) {
      setFileBackLoader(true);
      setGiftImageFileBack(file);
      setFileBack(URL.createObjectURL(file));
      const appendBackImage = changeImageName(file, 'Back');
      const formData = new FormData();
      formData.append('file', appendBackImage);

      await uploadAccountImage(id, formData)
        .then((res) => {
          setBackImageFile(res.data.path);
          setFileBackLoader(false);
        })
        .catch((err) => {
          setFileBackLoader(false);
        });
    } else {
      setFileBackLoader(false);
      toast.error('Please select the document smaller than 10MB.');
    }
  };
  const handleChangeImage = () => {
    setGiftImageFile(null);
    setFrontImageFile(null);
  };
  const handleChangeImageBack = () => {
    setGiftImageFileBack(null);
    setBackImageFile(null);
  };

  return (
    <Container>
      <InputColumn>
        <InputWrapper>
          <Input
            label="Street Address"
            placeholder="1 Sample Street"
            name="street-address"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label="Suburb"
            placeholder="Eg: Vic"
            name="suburb"
            value={suburb}
            onChange={(e) => setSuburb(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label="State"
            placeholder="Western Australia"
            name="states"
            value={states}
            onChange={(e) => setStates(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label="Post Code"
            placeholder="3000"
            name="postcode"
            value={postCode}
            onChange={(e) => setPostCode(e.target.value)}
            maxLength={4}
          />
        </InputWrapper>
      </InputColumn>
      <InputColumn>
        <InputWrapper>
          <Input
            label="Phone Number"
            placeholder="4530773582"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            maxLength={10}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label="City"
            placeholder="Brown Hill"
            name="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper className="upload-photo">
          <span className="label">Upload Photo ID</span>
          <div className="upload-wrapper">
            <div>
              {!fileFrontLoader ? (
                frontImageFile && frontImageFile !== null ? (
                  frontImageFile && (
                    <ImageDiv>
                      <img
                        src={frontImageFile}
                        alt={'image'}
                        height="90px"
                        width="90px"
                      />
                      <DeleteIcon>
                        <IoIosClose onClick={handleChangeImage} />
                      </DeleteIcon>
                    </ImageDiv>
                  )
                ) : (
                  <>
                    <UploadButton
                      onChange={handleGiftImage}
                      accept=".png,.jpg,.pdf"
                    />
                    <span>Front</span>
                  </>
                )
              ) : (
                <CircularIndeterminate />
              )}
            </div>
            <div>
              {!fileBackLoader ? (
                backImageFile && backImageFile !== null ? (
                  backImageFile && (
                    <ImageDiv>
                      <img
                        src={backImageFile}
                        alt={'image'}
                        height="90px"
                        width="90px"
                      />
                      <DeleteIcon>
                        <IoIosClose onClick={handleChangeImageBack} />
                      </DeleteIcon>
                    </ImageDiv>
                  )
                ) : (
                  <>
                    <UploadButton
                      onChange={handleGiftImageback}
                      id="fileback"
                      htmlFor="fileback"
                      accept=".png,.jpg,.pdf"
                    />
                    <span>Back</span>
                  </>
                )
              ) : (
                <CircularIndeterminate />
              )}
            </div>
          </div>
          <p>
            A photo of a government issued ID or passport is required to satisfy
            Australian anti-money laundering laws.
            <br /> Photo ID should be in (.png, .jpg or .pdf) format.
          </p>
        </InputWrapper>
      </InputColumn>
    </Container>
  );
}

SecondForm.propTypes = {
  formData: PropTypes.shape({
    streetAddress: PropTypes.string,
    suburb: PropTypes.string,
    postCode: PropTypes.string,
    setStreetAddress: PropTypes.func,
    setSuburb: PropTypes.func,
    setPostCode: PropTypes.func,
  }).isRequired,
};
