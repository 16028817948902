import { imageAxiosInstance } from '../services/axios';
import { getJWTToken } from '../utils/utility';

const tokenData = JSON.parse(getJWTToken());
const headers = {};

if (tokenData && tokenData.token) {
  headers.Authorization = `Bearer ${tokenData.token}`;
}

export const uploadProfileImage = async (userId, data) => {
  try {
    const result = await imageAxiosInstance.post(`user/${userId}/image`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};


export const uploadAccountImage = async (userId, data) => {
  try {
    const result = await imageAxiosInstance.post(`receiver/${userId}/imagee`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const uploadAccountImagee = async (userId, data) => {
  try {
    const result = await imageAxiosInstance.post(`receiver/${userId}/backimage`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};


export const uploadReceiverImage = async (receiverId, data) => {
  try {
    const result = await imageAxiosInstance.post(
      `receiver/${receiverId}/image`,
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const uploadGiftImage = async (giftId, data) => {
  try {
    const result = await imageAxiosInstance.post(`gift/${giftId}/image`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const inviteEventCardImage = async (eventId, data) => {
  try {
    const result = await imageAxiosInstance.post(`event/${eventId}/eventcardimage`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const uploadGiftCardImage = async (giftId, data) => {
  try {
    const result = await imageAxiosInstance.post(`gift/${giftId}/cardimage`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};