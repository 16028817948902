import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background: ${({ backgroundColor }) => backgroundColor || '#92278f'};
  color: #fff;

  .subheader-main {
    padding: 0 30px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;

    @media (max-width: 767px) {
      padding: 0;
      height: 57px;
      background: ${({ backgroundColor }) =>
        darken(0.05, backgroundColor) || darken(0.05, '#92278f')};
    }
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    text-transform: uppercase;

    & + h3 {
      margin-top: 10px;
    }

    @media (max-width: 767px) {
      margin-bottom: 0;
      line-height: 20px;
      font-size: 20px;
    }
  }

  h3 {
    font-size: 19px;
    font-weight: 600;

    @media (max-width: 767px) {
      font-size: 17px;
      margin-bottom: 0;
      text-align: center;
      max-width: 270px;
    }
  }

  a {
    color: #92278f;
  }

  svg {
    opacity: 0.7;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    @media (max-width: 767px) {
      height: 30px;
      width: 30px;
    }
  }
`;

export const Content = styled.div`
  flex: 1;

  @media (max-width: 767px) {
    h3,
    div {
      display: none;
    }
  }
`;

export const Side = styled.div`
  display: flex;
  width: ${({ side }) => (side === 'space-between' ? '20%' : '15%')};
  margin: 0 10px;
  justify-content: ${(props) => props.side};

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${({ side }) => (side === 'space-between' ? '20%' : '10%')};
  }
`;

export const MobileDesc = styled.div`
  background: ${({ backgroundColor }) => backgroundColor || '#92278f'};
  padding: 20px;
  min-height: 88px;
  align-items: center;
  display: flex;
  justify-content: center;

  h2 {
    display: none;

    & + h3,
    & + div {
      margin-top: 0;
      text-align: center;
    }
  }
  p {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
