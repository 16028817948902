import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { uploadProfileImage } from '../../../api/imageAPI';
import {
  getUserById,
  getUpdateUser,
  getUser,
  getUserByEmail,
  changePassword,
  getFollowUserByEmail,
} from '../../../api/userAPI';
import {
  updateProfileSuccess,
  updateProfileFailure,
  fetchUserByIdDone,
  fetchUserDone,
  uploadProfileImageDone,
  updatePasswordSuccess,
  updatePasswordFailure,
  fetchFollowUserByIdDone,
} from './userAction';

export function* updateProfile({ userId, data }) {
  try {
    const result = yield call(getUpdateUser, userId, data);
    if (result.status === 200) {
      yield put(updateProfileSuccess(result));
    }
  } catch (err) {
    toast.error('Error: Profile Not Updated.');
    yield put(updateProfileFailure());
  }
}

export function* watchUpdateProfileImage({ userId, data }) {
  try {
    const result = yield call(uploadProfileImage, userId, data);
    if (result.status === 200) {
      yield put(uploadProfileImageDone(result.data.path));
    }
  } catch (err) {
    toast.error('Error: Profile Not Updated.');
    yield put(updateProfileFailure());
  }
}

export function* watchUserDetailsById({ userId }) {
  try {
    const result = yield call(getUserById, userId);
    if (result.status === 200) {
      yield put(fetchUserByIdDone(result));
    }
    return result;
  } catch (err) {
    return err;
  }
}

export function* watchFollowUserDetailsByEmail({ email }) {
  try {
    const result = yield call(getFollowUserByEmail, email);
    
    if (result.status === 200) {
      yield put(fetchFollowUserByIdDone(result));
    }
    return result;
  } catch (err) {
    return err;
  }
}

export function* watchUserDetailsByEmail({ email }) {
  try {
    const result = yield call(getUserByEmail, email);
    if (result.status === 200) {
      yield put(fetchUserByIdDone(result));
    }
    return result;
  } catch (err) {
    return err;
  }
}

export function* watchUserDetails() {
  try {
    const result = yield call(getUser);
    if (result.status === 200) {
      yield put(fetchUserDone(result.data));
    }
    return result;
  } catch (err) {
    return err;
  }
}

export function* updateUserPassword({ userId, data }) {
  try {
    const result = yield call(changePassword, userId, data);
    if (result.response) {
      yield put(updatePasswordFailure(result.response.data));
    }
    if (result.data) {
      yield put(updatePasswordSuccess(result.data));
    }
  } catch (err) {
    toast.error('Error:please enter correct current password.');
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@user/FETCH_USER', watchUserDetails),
  takeLatest('FETCH_USER_BY_ID', watchUserDetailsById),
  takeLatest('FETCH_FOLLOWUSER_BY_EMAIL', watchFollowUserDetailsByEmail),
  takeLatest('FETCH_USER_BY_EMAIL', watchUserDetailsByEmail),
  takeLatest('UPLOAD_PROFILE_IMAGE', watchUpdateProfileImage),
  takeLatest('@user/UPDATE_USER_PASSWORD', updateUserPassword),
]);
