import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Context from '~/context';
import ModalWrapper from '~/components/ModalWrapper';
import Options from './ModalScreens/Options';
import Invitation from './ModalScreens/Invitation';
// Action
import {
  fetchEventById,
  fetchEventByIdFollow,
} from '../../store/modules/event/eventAction';

import OneGift from './OneGift';
import Party from './Party';
import GiftList from './GiftList';
var identify;
var checkValue;
export default function EventDetails() {
  const Dispatch = useDispatch();
  const { showModal, handleShowModal } = useContext(Context);
  const { id } = useParams();
  const location = useLocation();
  const eventDetail = location && location.data;
  // const event = events.find(x => x.id === parseInt(id, 10));
  const event = useSelector((state) => state.eventReducer.event.data);
  const eventData = useSelector((state) => state.eventReducer);
  let value = 0;
  let raised = 0;
  const [modal, setModal] = useState(
    !showModal ? (
      <Options eventId={event ? event.id : ''} />
    ) : (
      <Options eventId={event ? event.id : ''} />
    ),
  );
  const getFollow = localStorage.getItem('follow');

  const [checkedd, setCheckedd] = useState(false);

  if (event) {
    const { gift } = event;
    if (gift.length > 0) {
      value = gift.map((cont) => parseInt(cont.cost)).reduce((a, b) => a + b);
      raised = gift
        .map((cont) => parseInt(cont.raised))
        .reduce((a, b) => a + b);
    }
  }
  useEffect(() => {
    getFollow && getFollow !== null
      ? Dispatch(fetchEventByIdFollow(id))
      : eventDetail && eventDetail.event_id !== null
      ?  Dispatch(fetchEventByIdFollow(id))
      : Dispatch(fetchEventById(id));
  }, [Dispatch, id]);

  const handleInvitation = (data, identifier) => {
    identify = identifier;
    localStorage.setItem('close', 'closed');
    setModal(<Invitation inviteData={data} />);
    handleShowModal();
  };

  const eventTypes = {
    onegift: (
      <OneGift
        event={event || ''}
        raised={raised}
        value={value}
        handleInvitation={handleInvitation}
      />
    ),
    party: (
      <Party
        event={event || ''}
        raised={raised}
        value={value}
        eventData={eventData}
        handleInvitation={handleInvitation}
      />
    ),
    giftlist: (
      <GiftList
        event={event || ''}
        raised={raised}
        value={value}
        handleInvitation={handleInvitation}
      />
    ),
  };
  checkValue = localStorage.getItem('close');

  return (
    <>
      {/* {eventTypes[type]} */}
      {eventTypes.party}
      {checkValue === 'closed' ? (
        <ModalWrapper>{modal}</ModalWrapper>
      ) : (
        <ModalWrapper>
          {<Options eventId={event ? event.id : ''} />}
        </ModalWrapper>
      )}
    </>
  );
}
