import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextInput, Label, Info, LabelWrapper } from './styles';

export default function Input({
  name,
  value,
  label,
  placeholder,
  onChange,
  className,
  info,
  infoFunction,
  type,
  error,
  maxLength,
}) {
  const [placeholderText, setPlaceholderText] = useState(placeholder);

  const handleFocus = () => setPlaceholderText('');
  const handleBlur = () => setPlaceholderText(placeholder);

  return (
    <>
      {label && (
        <LabelWrapper>
          <Label htmlFor={name}>{label}</Label>
          {info && (
            <Info className="info-button" onClick={() => infoFunction()}>
              i
            </Info>
          )}
        </LabelWrapper>
      )}

      <TextInput
        name={name}
        placeholder={placeholderText}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => onChange(e)}
        value={value}
        className={className}
        type={type}
        error={error}
        maxLength={maxLength}
      />
    </>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  info: PropTypes.bool,
  infoFunction: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.bool,
  maxLength: PropTypes.number,
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  className: '',
  info: false,
  infoFunction: () => {},
  type: 'text',
  error: false,
};
