import React from 'react';

import { Container } from './styles';

function FindOutMoreModal() {
  return (
    <Container>
      <h3>Fund one special gift</h3>

      <p>
        Ideal for friends, colleagues and relatives who just want to get people
        to help fund one special gift for someone.{' '}
      </p>

      <p>
        These gifts usually need to be funded quickly (e.g. a surprise gift for
        someone).
      </p>

      <p>Examples:</p>
      <ul>
        <li>
          A mum arranges a gift for a teacher or another kid from her daughter's
          class
        </li>
        <li>
          A teen asks his mates to help fund a present for his best friend
        </li>
        <li>An uncle organises a surprise gift for his twin nephews</li>
      </ul>

      <h3>Fund multiple gifts</h3>

      <p>
        Ideal for parents, partners and those organising parties and
        celebrations for individuals, couples or groups.
      </p>

      <p>
        You can create a list of gift wishes and then share it with friends.{' '}
      </p>

      <p>
        You can also create a personalized party invitation, request RSVPs and
        upload photos from the event for your invitees to view and comment on.
      </p>

      <p>
        It can be used for gifts needed now or in the future like trips,
        education and more costly items.
      </p>

      <p>Examples:</p>
      <ul>
        <li>
          A dad invites family to his son's graduation party and shares gift
          wishes for him that they can contribute to
        </li>
        <li>
          A husband invites friends to his wife's 40th and shares gift wishes
          for her
        </li>
        <li>
          A daughter shares with her relatives a few Xmas gift wishes for her
          grandparents{' '}
        </li>
      </ul>
    </Container>
  );
}

export default FindOutMoreModal;
