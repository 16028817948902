import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import gift from './gift';

import SubHeader from '~/components/SubHeader';

import Button from '~/components/Button';
import { Container, ImageWrapper, Card } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getReceiverById } from '~/api/receiverAPI';
import { fetchReceiverByIdDone } from '~/store/modules/receiver/receiverAction';
import { getContributionById } from '~/api/userAPI';
import CircularIndeterminate from '~/components/Loader';

export default function EventNearEnd() {
  const { colors } = useContext(ThemeContext);
  const Dispatch = useDispatch();
  const location = useLocation();
  const getData = location && location.data;
  const [giftData, setGiftData] = useState('');
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [loader, setLoader] = useState(false);

  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : JSON.parse(getData && getData.data);

  useEffect(() => {
    getUserDetail(parsedData.gift_id);
  }, [parsedData.gift_id]);

  const getUserDetail = async (id) => {
    // const giftId = funding && funding.gift_id;
    setLoader(true);
    try {
      await getContributionById(id).then((res) => {
        setGiftData(res.data);
        setLoader(false);
      });
    } catch (error) {
      console.log('err', error);
      setLoader(false);
    }
  };

  const contributions = (giftData && giftData.contributor_contributions) || [];

  // Calculate total donation
  const totalDonation =
    contributions &&
    contributions.reduce((total, contribution) => {
      return total + parseFloat(contribution.donation);
    }, 0);

  return (
    <>
      <SubHeader backgroundColor={colors.purple}>
        <h2>EVENT DATE IS APPROACHING</h2>
        <h3>One day to go!</h3>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <ImageWrapper
              backgroundImage={
                giftData && giftData.gift_images
                  ? giftData.gift_images[0].url
                  : gift.image
              }
            />
            <div>
              <Card>
                <span>The event you created for</span>
                <h2>
                  {parsedData && parsedData.childName}&apos;s{' '}
                  {giftData && giftData.name}
                </h2>
                <span>will end tomorrow.</span>
              </Card>
              <div className="notice">
                <span>So far all contributions have raised</span>
                <span className="large">${totalDonation && totalDonation}</span>
              </div>
              <div className="button-wrapper">
                <Button
                  text="VIEW EVENT DETAILS "
                  buttonFunction={() =>
                    history.push(
                      `/event-details/${parsedData && parsedData.event_id}`,
                      { childId: parsedData.child_id },
                    )
                  }
                />
              </div>
            </div>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}
