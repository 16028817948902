import React, { useState } from 'react';

import memories from './memories';

import CardCheckOverlay from '~/components/CardCheckOverlay';

import EmptyList from '../EmptyList';

import { Container, Memory, MemoryThumbnail } from './styles';

export default function Memories() {
  const [checkedMemories, setCheckedMemories] = useState(
    memories.map((memory) => ({ ...memory, checked: false })),
  );

  const handleSelectMemory = (id) => {
    setCheckedMemories(
      checkedMemories.map((memory) => ({
        ...memory,
        checked: memory.id === id ? !memory.checked : memory.checked,
      })),
    );
  };

  return (
    <>
      {checkedMemories.length > 0 ? (
        <Container>
          {checkedMemories.map((memory) => (
            <Memory
              key={memory.id}
              onClick={() => handleSelectMemory(memory.id)}
            >
              <MemoryThumbnail image={memory.image} />
              <span>{memory.title}</span>
              <small>{memory.date}</small>
              <CardCheckOverlay checked={memory.checked} />
            </Memory>
          ))}
        </Container>
      ) : (
        <EmptyList>Click below to add pictures or videos to events.</EmptyList>
      )}
    </>
  );
}
