import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import malePlaceholder from '~/assets/images/avatar-placeholder.png';
import femalePlaceholder from '~/assets/images/girl-face.svg';
import UploadFile from '~/components/UploadFile';

export default function AvatarUpload({ image, gender, onChange }) {
  return (
    <Container
      image={image || (gender === 'M' ? malePlaceholder : femalePlaceholder)}
    >
      <UploadFile onChange={onChange} />
    </Container>
  );
}

AvatarUpload.propTypes = {
  image: PropTypes.string,
  gender: PropTypes.string,
};

AvatarUpload.defaultProps = {
  image: '',
  gender: 'male',
};
