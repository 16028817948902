import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Context from '~/context';

import Button from '~/components/Button';
import ModalWrapper from '~/components/ModalWrapper';
import Options from './Options';

import { ReactComponent as EnvelopeIconBig } from '~/assets/images/envelope-icon-big.svg';

import { OuterContainer, Container } from './styles';
import { useSelector } from 'react-redux';
import history from '~/services/history';

export default function OnBoarding(data = '') {
  const { handleShowModal, flow } = useContext(Context);
  const event = useSelector((state) => state.eventReducer.event);

  const handleSubmit = () => {
    if (event) {
      history.push(`/event-details/${event.id || data.event_id}`, {
        receiverIdd: data && data.receiver_id,
      });
    }
  };
  const handleGreeting = () => {
    if (flow.giftOption !== 'multiple-gifts-invite') {
      return 'Excellent';
    }
    if (flow.inviteOption === 'webpage') {
      return 'Page Saved!';
    }
    return 'Event Saved!';
  };

  return (
    <OuterContainer>
      <Container>
        <div>
          <EnvelopeIconBig alt="Envelope" />
          <h1>{handleGreeting()}</h1>
          <h2>
            You are now ready to start inviting friends to fund Eugene’s gift.
          </h2>
          <Button
            text="Invite Friends"
            backgroundColor="#E38BC6"
            textColor="#ffffff"
            buttonFunction={() => handleSubmit()}
          />
          <Link to={`/receiver-profile/${data.receiver_id}/events`}>Later</Link>
        </div>
      </Container>
      <ModalWrapper>
        <Options />
      </ModalWrapper>
    </OuterContainer>
  );
}
