import styled from 'styled-components';
import { KeyboardTimePicker } from '@material-ui/pickers';

export const TimePickerInput = styled(KeyboardTimePicker)`
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    display: none;
  }

  .MuiInput-underline.Mui-focused:after {
    display: none;
  }

  &.date-picker {
    input {
      margin-bottom: 0;
    }

    label {
      display: block !important;
      position: initial;
      color: #888;
      font-weight: bold;
      font-size: 15px;
      transition: none;
      transform: none;
      line-height: 21px;

      & + .MuiInput-formControl {
        margin-top: 0;
        background: #fff;
      }
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;

    button.MuiButtonBase-root {
      margin-bottom: 0;
    }
  }
`;
