import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    width: 100%;
    padding: 25px 10px;
    margin: 0;
    min-height: calc(100% - 135px);
    justify-content: initial;
  }
`;
