import styled from 'styled-components';

import { ReactComponent as EnvelopeSentSVG } from '~/assets/images/envelope-sent.svg';

export const OuterContainer = styled.div`
  background-color: #f6f6f6;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  max-width: 720px;
  max-height: 500px;
  height: 100%;
  margin: 40px auto;
  width: 90%;
  background: linear-gradient(0deg, #03fafc, #912a91);
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 767px) {
    min-height: 100vh;
  }

  h1 {
    color: #fff;
    font-size: 35px;
    animation: 1s fadeIn forwards;
    animation-delay: 2s;
    opacity: 0;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    height: 100%;
  }

  div {
    max-width: 370px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  svg {
    margin-bottom: 20px;
  }
`;

export const EnvelopeSent = styled(EnvelopeSentSVG)`
  max-width: 250px;
  position: relative;
  animation: inFromLeft 2s ease-in-out;

  @media (min-width: 767px) {
    animation: inFromLeft 1.5s ease-in-out;
  }

  @keyframes inFromLeft {
    from {
      opacity: 0;
      transform: translate3d(-1000px, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0px, 0, 0);
    }
  }

  #envelope-sent {
    animation: upAndDown 3s ease-in-out infinite;

    @keyframes upAndDown {
      0% {
        transform: translate3d(53px, 0px, 0);
      }
      50% {
        transform: translate3d(50px, 20px, 0);
      }
      100% {
        transform: translate3d(53px, 0px, 0);
      }
    }
  }

  #all-wrapper {
    & > *:not(#envelope) {
      opacity: 1;
      animation: appear 2s ease-in-out infinite;
      animation-delay: 0.6s;
      &:nth-child(1) {
        animation-delay: 0.2s;
      }
      &:nth-child(2) {
        animation-delay: 0.3s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
      &:nth-child(4) {
        animation-delay: 0.5s;
      }
      &:nth-child(5) {
        animation-delay: 0.6s;
      }
      &:nth-child(6) {
        animation-delay: 0.7s;
      }
      &:nth-child(7) {
        animation-delay: 0.8s;
      }
    }

    #envelope {
      z-index: 2;
    }
  }

  @keyframes appear {
    0% {
      transform: translate(35%, 0);
      opacity: 1;
    }
    80% {
      opacity: 1;
    }

    100% {
      transform: translate(-5%, 0);
      opacity: 0;
    }
  }
`;
