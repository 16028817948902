import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  transform: translate(-50%, -50%);
  top: 50vh;
  left: 50vw;
  z-index: 200;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  button {
    width: 100%;
    max-width: 305px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    background-color: #b3b3b3;
    font-size: 20px;
    letter-spacing: 0.45px;
    margin: 10px 0;
    border: 0;
    cursor: pointer;

    &.red {
      background-color: ${({ theme }) => theme.colors.pink};
    }

    &.blue {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100vh;
  background: #000000eb;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  &.visible {
    display: block;
  }
`;

export const CloseWrapper = styled.div`
  position: absolute;
  z-index: 300;

  svg {
    position: fixed;
    right: 5px;
    top: 2px;
    cursor: pointer;
  }
`;
