import { apiAxiosInstance } from '../services/axios';

import { getJWTToken } from '../utils/utility';

const tokenData = JSON.parse(getJWTToken());
const headers = {};
if (tokenData && tokenData.token) {
  headers.Authorization = `Bearer ${tokenData.token}`;
}

export const getNotification = async (userId) => {
  try {
    const result = await apiAxiosInstance.get(`user/${userId}/notification`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const getThankuCard = async (userId) => {
  try {
    const result = await apiAxiosInstance.get(`user/${userId}/thankyou`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const readThankuCardNotification = async (id) => {
  try {
    const result = await apiAxiosInstance.put(
      `gift/${id}/update_thankyou_card`,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};
export const getAllNotification = async (userId) => {
  try {
    const result = await apiAxiosInstance.get(
      `user/${userId}/all_notification`,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getThankyouPendingCard = async (userid) => {
  try {
    const result = await apiAxiosInstance.get(
      `user/${userid}/get_thankyou_pending_card`,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getThankyouCardDetail = async (cardId) => {
  try {
    const result = await apiAxiosInstance.get(
      `user/${cardId}/get_thankyou_detail_by_id`,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};
