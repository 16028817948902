import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 15px;
    margin: 0;
    grid-template-columns: auto;
  }

  button {
    max-width: 100%;
    max-height: 50px;
  }
`;

export const Card = styled.div`
  background: white;
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  display: flex;
  flex-direction: column;
  padding: 15px;

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 115px;
    margin-bottom: 10px;

    img {
      width: 55px;
      height: 55px;
      min-width: 55px;
      border-radius: 28px;
      object-fit: cover;
      object-position: center;
      margin-right: 20px;

      @media (max-width: 767px) {
        margin-right: 10px;
      }
    }

    div {
      display: flex;
    }

    > div {
      justify-content: space-between;
      flex-direction: column;
      width: 100%;

      > div {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        div:first-child {
          flex-direction: column;
          flex: 1;
        }

        div:last-child {
          align-items: center;

          @media (max-width: 767px) {
            align-items: start;
          }
        }
      }
    }

    h3 {
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 18px;
      text-transform: capitalize;
    }

    small {
      color: #aeaeae;
      font-size: 13px;
    }

    .donation {
      color: #888;
      font-size: 18px;
    }
  }
`;

export const Contributions = styled.div`
  .avatar-content {
    position: relative;
    flex-direction: row !important;
  }
  .avatar-inner {
    display: flex !important;
    flex-wrap: nowrap;
    flex-direction: row !important;
    width: 50% !important;
  }
  .avatar-inner span {
    width: 33.3%;
  }
  .avatar-inner img {
    margin-right: 0 !important;
    gap: 0 !important;
  }
  .avatar-inner span:nth-child(2) {
    position: relative;
    left: -24px !important;
  }
  .avatar-inner span:last-child {
    position: relative;
    left: -49px;
  }
  .avatar-right {
    width: 50%;
  }
  .avatar-right div {
    width: 100%;
  }
  .avatar-right {
    flex-wrap: wrap;
  }
  .donation_price {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    top: -37px;
  }
`;
