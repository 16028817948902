import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { IoMdGrid, IoIosList, IoMdHeartEmpty } from 'react-icons/io';

import GridView from './GridView';
import ListView from './ListView';
import CelebratoryCard from './CelebratoryCard';
import Post from './Post';
import Options from './ModalScreens/Options';
import Fab from '@material-ui/core/Fab';
import { IoIosAdd } from 'react-icons/io';
import Header from '~/components/Header';
import SubHeader from '~/components/SubHeader';
import ModalWrapper from '~/components/ModalWrapper';

import { Container, StyledTabs as Tabs, StyledTab as Tab } from './styles';
import memories from '../ArchivedItems/Memories/memories';
import { useDispatch, useSelector } from 'react-redux';
import { addEventDoneImage } from '~/store/modules/event/eventAction';
import { Box, CircularProgress } from '@material-ui/core';

function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EventAlbum() {
  const { eventId, postId } = useParams();
  const { colors } = useContext(ThemeContext);
  const tabs = [
    <GridView eventId={eventId} />,
    <ListView eventId={eventId} />,
    <CelebratoryCard />,
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [value, setValue] = useState(0);
  const getFollow = localStorage.getItem('follow');
  const receiverId = useSelector((state) => state.receiverReducer);
  const currentURL = window && window.location.href;
  const includesPost = currentURL && currentURL.includes('/post/');
  const memories = useSelector((state) => state.eventReducer.memoriesData);
  const [loader, setLoader] = useState(false);

  const [giftImageFile, setGiftImageFile] = useState(null);
  const [file, setFile] = useState();
  const Dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setActiveTab(tabs[newValue]);
    setValue(newValue);
  };

  const handleSubmit = () => {
    console.log('@######');
  };

  const handleAddMemory = (event) => {
    setLoader(true);
    const data = {
      event_id: parseInt(eventId),
      child_id: parseInt(receiverId.id),
    };

    setGiftImageFile(event.target.files[0]);
    setFile(URL.createObjectURL(event.target.files[0]));

    const formData = new FormData();
    if (event.target.files) {
      formData.append('file', event.target.files[0]);
      formData.append('event_id', parseInt(eventId));
      formData.append('child_id', parseInt(receiverId.id));
    }
    Dispatch(addEventDoneImage(data, event.target.files ? formData : null));
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  };

  const matchingMemory =
    memories &&
    memories.find((memory) => memory.event_id === parseInt(eventId));

  return (
    <>
      <Header />
      {getFollow && getFollow !== null ? (
        <SubHeader
          backgroundColor={colors.secondary}
          buttonBackgroundColor="#8991C1"
          buttonTextColor="#fff"
          buttonFunction={() => handleSubmit()}
          mobileDescVisible={!postId}
        >
          <h2>Event Album</h2>
          <h3>
            {matchingMemory &&
              matchingMemory.album_name
                .toLowerCase()
                .replace(/(^|\s)\S/g, (match) => match.toUpperCase())}
          </h3>
        </SubHeader>
      ) : includesPost && includesPost ? (
        <SubHeader
          backgroundColor={colors.secondary}
          buttonBackgroundColor="#8991C1"
          buttonTextColor="#fff"
          buttonFunction={() => handleSubmit()}
          mobileDescVisible={!postId}
        >
          <h2>Event Album</h2>
          <h3>
            {matchingMemory &&
              matchingMemory.album_name
                .toLowerCase()
                .replace(/(^|\s)\S/g, (match) => match.toUpperCase())}
          </h3>
        </SubHeader>
      ) : (
        <SubHeader
          backgroundColor={colors.secondary}
          buttonBackgroundColor="#8991C1"
          buttonTextColor="#fff"
          buttonFunction={() => handleSubmit()}
          more
          mobileDescVisible={!postId}
        >
          <h2>Event Album</h2>
          <h3>
            {matchingMemory &&
              matchingMemory.album_name
                .toLowerCase()
                .replace(/(^|\s)\S/g, (match) => match.toUpperCase())}
          </h3>
        </SubHeader>
      )}

      {postId ? (
        <Post post={postId} />
      ) : (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              key={a11yProps(0).key}
              id={a11yProps(0).id}
              label={<IoMdGrid size={20} />}
            />
            <Tab
              label={<IoIosList size={20} />}
              key={a11yProps(1).key}
              id={a11yProps(1).id}
            />
            <Tab
              label={<IoMdHeartEmpty size={20} />}
              key={a11yProps(2).key}
              id={a11yProps(2).id}
            />
          </Tabs>
          {!loader ? (
            <Container>{activeTab}</Container>
          ) : (
            <Container>
              <Box sx={{ display: 'flex', padding: '50px' }}>
                <CircularProgress size={20} color={'secondary'} />
              </Box>
            </Container>
          )}
          <ModalWrapper>
            <Options eventId={eventId} postId={postId} />
          </ModalWrapper>
        </>
      )}
      {getFollow && getFollow !== null ? (
        ''
      ) : (
        <div className="fabicon">
          <label htmlFor={'file'}>
            <Fab
              color="primary"
              aria-label="add"
              className={memories && memories.length ? '' : 'show-indicator'}
            >
              <input
                type="file"
                name="file[]"
                id="inputfilecss"
                className="inputfilecss"
                data-multiple-caption="{count} files selected"
                multiple=""
                onChange={(e) => handleAddMemory(e)}
                accept=".jpg, .jpeg, .png"
              />
              <span className="fabaddcss">
                <IoIosAdd size={45} color={'#fff'} />
              </span>
            </Fab>
          </label>
        </div>
      )}
    </>
  );
}
