import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { IoIosLock } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

import history from '~/services/history';

import cards from './cards';

import Amex from '~/assets/images/card-flag-amex.svg';
import MasterCard from '~/assets/images/card-flag-mc.svg';
import Visa from '~/assets/images/card-flag-visa.svg';
import { toast } from 'react-toastify';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import CreditCardForm from '~/components/CreditCardForm';

import {
  Container,
  InputColumn,
  SecureBankingBadge,
  OrderTotal,
  CardsRow,
} from './styles';
import { makeStripePayment } from '~/api/userAPI';
import SubHeaderButtonWithLoader from '~/components/SubHeader/SubHeaderButtonWithLoader';

export default function CardPayment() {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const subtotal =
    location.state && location.state.subtotal ? location.state.subtotal : 0;
  const rspvChecked =
    location.state && location.state.check ? location.state.check : '1';

  const fee = 1;
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [CCV, setCCV] = useState('');
  const [isValidCardNumber, setIsValidCardNumber] = useState(true);
  const [isValidExpiry, setIsValidExpiry] = useState(true);
  const [isValidCVV, setIsValidCVV] = useState(true);
  const [loader, setLoader] = useState(false);
  const checkRSPV = localStorage.getItem('rspv');

  const formatValue = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'AUD',
    }).format(value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const formattedCardNumber = cardNumber && cardNumber.replace(/\s/g, '');
    const [month, year] = expirationDate && expirationDate.split('/');
    const data = {
      number: `${formattedCardNumber}`,
      exp_month: parseInt(month),
      exp_year: parseInt(20 + year),
      cvc: parseInt(CCV),
      currency: 'AUD',
      amount: subtotal + fee,
      giftObj: location.state.selectedGifts,
    };
    if (
      isValidCardNumber === true &&
      isValidExpiry === true &&
      isValidCVV === true
    ) {
      if (checkRSPV && checkRSPV === 'no') {
      } else {
        localStorage.setItem('rspv', rspvChecked);
      }
      await makeStripePayment(data)
        .then((res) => {
          if (res && res.data.message) {
            setLoader(false);
            history.push({
              pathname: '/success',
              state: {
                destination: `/message-recipient`,
                message: 'Thank you for your contribution!',
                colors: { start: colors.lightPurple, end: colors.purple },
              },
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log('err', err);
        });
    } else {
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    toast.error('All field are required!');
  };

  return (
    <>
      <SubHeader backgroundColor={colors.secondary}>
        <h2>Card Payment</h2>
        <h3>
          Enter your credit or debit card details to pay for your
          gift&nbsp;contribution(s).
        </h3>
      </SubHeader>
      <Container>
        <SecureBankingBadge>
          <IoIosLock /> Secure Banking
        </SecureBankingBadge>
        <form>
          <InputColumn>
            <CreditCardForm
              cards={cards}
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              expirationDate={expirationDate}
              setExpirationDate={setExpirationDate}
              CCV={CCV}
              setCCV={setCCV}
              isValidCardNumber={isValidCardNumber}
              setIsValidCardNumber={setIsValidCardNumber}
              isValidExpiry={isValidExpiry}
              setIsValidExpiry={setIsValidExpiry}
              isValidCVV={isValidCVV}
              setIsValidCVV={setIsValidCVV}
            />
          </InputColumn>
          <InputColumn>
            <OrderTotal>
              <div>
                <div>Subtotal:</div>
                <div>{formatValue(subtotal)}</div>
              </div>
              <div>
                <div>Purposit Fee:</div>
                <div>{formatValue(fee)}</div>
              </div>
              <div className="total">
                <div>Total</div>
                <div>{formatValue(subtotal + fee)}</div>
              </div>
            </OrderTotal>
            {!loader ? (
              cardNumber !== '' && expirationDate !== '' && CCV !== '' ? (
                <Button
                  text="Confirm Payment"
                  buttonFunction={(e) => handleSubmit(e)}
                />
              ) : (
                <Button
                  text="Confirm Payment"
                  buttonFunction={(e) => handleSubmit1(e)}
                />
              )
            ) : (
              <SubHeaderButtonWithLoader
                backgroundColor={'grey'}
                text={'Confirm Payment'}
                textColor={'white'}
                disableBoolean={true}
              />
            )}

            <CardsRow>
              <img src={MasterCard} alt="MasterCard" />
              <img src={Visa} alt="Visa" />
              <img src={Amex} alt="American Express" />
            </CardsRow>
          </InputColumn>
        </form>
      </Container>
    </>
  );
}
