import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward, IoIosLock } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

import history from '~/services/history';

import cards from './cards';

import Amex from '~/assets/images/card-flag-amex.svg';
import MasterCard from '~/assets/images/card-flag-mc.svg';
import Visa from '~/assets/images/card-flag-visa.svg';
import { toast } from 'react-toastify';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import CreditCardForm from '~/components/CreditCardForm';

import {
  Container,
  InputColumn,
  SecureBankingBadge,
  OrderTotal,
  CardsRow,
  Card,
  RadioButton,
  RadioControl,
  InputWrapper,
  InputCard,
  InputCardValidation,
  InputRow,
  SelectExisting,
} from './styles';
import { makeCardStripePayment, makeStripePayment } from '~/api/userAPI';
import SubHeaderButtonWithLoader from '~/components/SubHeader/SubHeaderButtonWithLoader';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import {
  bankListById,
  cardDetailsByUserId,
} from '~/store/modules/bankAccount/bankAccountAction';
import { number, expirationDate as expirydate, cvv } from 'card-validator';
import { addCreditCard } from '~/store/modules/creditCard/creditCardAction';

export default function SelectCardPayment() {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const [radioValue, setRadioValue] = useState(1);

  const fee = 1;
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [CCV, setCCV] = useState('');
  const [isValidCardNumber, setIsValidCardNumber] = useState(true);
  const [isValidExpiry, setIsValidExpiry] = useState(true);
  const [isValidCVV, setIsValidCVV] = useState(true);
  const [loader, setLoader] = useState(false);
  const checkRSPV = localStorage.getItem('rspv');
  const bankAccountReducer = useSelector((state) => state.bankAccountReducer);
  const { userBankDetails, userCardDetails } = bankAccountReducer;
  const Dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.profile);
  const userId = user.id;
  const [addNewCard, setAddNewCard] = useState(
    userCardDetails && userCardDetails.length === 0,
  );
  const userIdd = useSelector((state) => state.userReducer.profile.id);
  const getUserData = localStorage.getItem('giftData');
  const giftData = JSON.parse(getUserData);

  const [selectedCardData, setSelectedCardData] = useState('');
  const formatValue = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'AUD',
    }).format(value);

  const detectCardType = (cardNumber) => {
    const visaPattern = /^4/;
    const mastercardPattern = /^5[1-5]/;
    const amexPattern = /^3[47]/;
    const discoverPattern = /^6(?:011|5)/;

    if (visaPattern.test(cardNumber)) {
      return 'visa';
    } else if (mastercardPattern.test(cardNumber)) {
      return 'mastercard';
    } else if (amexPattern.test(cardNumber)) {
      return 'amex';
    } else if (discoverPattern.test(cardNumber)) {
      return 'discover';
    } else {
      return 'default'; // You can handle other card types or return 'unknown' if needed.
    }
  };

  useEffect(() => {
    Dispatch(bankListById(userId));
    Dispatch(cardDetailsByUserId(userId));
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const formattedCardNumber = cardNumber && cardNumber.replace(/\s/g, '');
    const [month, year] = expirationDate && expirationDate.split('/');
    const data = {
      number: `${formattedCardNumber}`,
      exp_month: parseInt(month),
      exp_year: parseInt(20 + year),
      cvc: parseInt(CCV),
      currency: 'AUD',
      amount: giftData.subtotal + fee,
      giftObj: giftData.selectedGifts,
    };
    if (
      isValidCardNumber === true &&
      isValidExpiry === true &&
      isValidCVV === true
    ) {
      await makeStripePayment(data)
        .then((res) => {
          if (res && res.data.message) {
            setLoader(false);
            history.push('/message-recipient');
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log('err', err);
        });
    } else {
    }
  };

  const handleSubmit11 = (e) => {
    e.preventDefault();
    toast.error('Field are required!');
  };

  const handleRadioChange = (event) => {
    setRadioValue(parseInt(event.target.value, 10));
  };
  const handleSubmitSelect = async (e) => {
    e.preventDefault();
    const data = {
      currency: 'AUD',
      amount: giftData.subtotal + fee,
      giftObj: giftData.selectedGifts,
      cardData: selectedCardData,
    };
    setLoader(true);
    await makeCardStripePayment(data)
      .then((res) => {
        if (res && res.data.message) {
          setLoader(false);
          history.push('/message-recipient');
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log('err', err);
      });
  };

  const handleSelectedCard = (data) => {
    setSelectedCardData(data);
  };

  return (
    <>
      {addNewCard ? (
        <>
          <SubHeader backgroundColor={colors.secondary}>
            <h2>Card Payment</h2>
            <h3>
              Enter your credit or debit card details to pay for your
              gift&nbsp;contribution(s).
            </h3>
          </SubHeader>
          <Container>
            <SecureBankingBadge>
              <IoIosLock /> Secure Banking
            </SecureBankingBadge>
            <form>
              <InputColumn>
                <CreditCardForm
                  cards={cards}
                  cardNumber={cardNumber}
                  setCardNumber={setCardNumber}
                  expirationDate={expirationDate}
                  setExpirationDate={setExpirationDate}
                  CCV={CCV}
                  setCCV={setCCV}
                  isValidCardNumber={isValidCardNumber}
                  setIsValidCardNumber={setIsValidCardNumber}
                  isValidExpiry={isValidExpiry}
                  setIsValidExpiry={setIsValidExpiry}
                  isValidCVV={isValidCVV}
                  setIsValidCVV={setIsValidCVV}
                />
              </InputColumn>
              <InputColumn>
                <OrderTotal>
                  <div>
                    <div>Subtotal:</div>
                    <div>{formatValue(giftData.subtotal)}</div>
                  </div>
                  <div>
                    <div>Purposit Fee:</div>
                    <div>{formatValue(fee)}</div>
                  </div>
                  <div className="total">
                    <div>Total</div>
                    <div>{formatValue(giftData.subtotal + fee)}</div>
                  </div>
                </OrderTotal>
                {!loader ? (
                  cardNumber !== '' && expirationDate !== '' && CCV !== '' ? (
                    <Button
                      text="Confirm Payment"
                      buttonFunction={(e) => handleSubmit(e)}
                    />
                  ) : (
                    <Button
                      text="Confirm Payment"
                      buttonFunction={(e) => handleSubmit11(e)}
                    />
                  )
                ) : (
                  <SubHeaderButtonWithLoader
                    backgroundColor={'grey'}
                    text={'Confirm Payment'}
                    textColor={'white'}
                    disableBoolean={true}
                  />
                )}

                <CardsRow>
                  <img src={MasterCard} alt="MasterCard" />
                  <img src={Visa} alt="Visa" />
                  <img src={Amex} alt="American Express" />
                </CardsRow>
              </InputColumn>
            </form>
            {userCardDetails.length !== 0 && (
              <SelectExisting
                className="select-existing"
                onClick={() => setAddNewCard(false)}
              >
                Or select existing card.
              </SelectExisting>
            )}
          </Container>
        </>
      ) : (
        <>
          <SubHeader backgroundColor={colors.secondary}>
            <h2>Card Payment</h2>
            <h3>
              Enter your credit or debit card details to pay for your
              gift&nbsp;contribution(s).
            </h3>
          </SubHeader>
          <Container>
            <SecureBankingBadge>
              <IoIosLock /> Secure Banking
            </SecureBankingBadge>
            <form>
              <InputColumn>
                <RadioControl component="fieldset">
                  <RadioGroup
                    aria-label="funds-instructions"
                    name="funds-instructions"
                    value={radioValue}
                    onChange={handleRadioChange}
                  >
                    {userCardDetails &&
                      userCardDetails.map((card) => (
                        <Card
                          key={card.id}
                          onClick={() => handleSelectedCard(card)}
                        >
                          <FormControlLabel
                            value={card.id}
                            control={<RadioButton />}
                            labelPlacement="start"
                            label={
                              <div>
                                xxxx - xxxx - xxxx -<span>{card.last4}</span>
                              </div>
                            }
                          />
                        </Card>
                      ))}
                  </RadioGroup>
                </RadioControl>
                <Card onClick={() => setAddNewCard(true)}>
                  <span>Enter new card</span>
                  <IoIosArrowForward size={25} color="#d6d6d6" />
                </Card>
              </InputColumn>
              <InputColumn>
                <OrderTotal>
                  <div>
                    <div>Subtotal:</div>
                    <div>{formatValue(giftData.subtotal)}</div>
                  </div>
                  <div>
                    <div>Purposit Fee:</div>
                    <div>{formatValue(fee)}</div>
                  </div>
                  <div className="total">
                    <div>Total</div>
                    <div>{formatValue(giftData.subtotal + fee)}</div>
                  </div>
                </OrderTotal>
                {!loader ? (
                  selectedCardData !== '' ? (
                    <Button
                      text="Confirm Payment"
                      buttonFunction={(e) => handleSubmitSelect(e)}
                    />
                  ) : (
                    <Button
                      text="Confirm Payment"
                      buttonFunction={(e) => handleSubmit11(e)}
                    />
                  )
                ) : (
                  <SubHeaderButtonWithLoader
                    backgroundColor={'grey'}
                    text={'Confirm Payment'}
                    textColor={'white'}
                    disableBoolean={true}
                  />
                )}

                <CardsRow>
                  <img src={MasterCard} alt="MasterCard" />
                  <img src={Visa} alt="Visa" />
                  <img src={Amex} alt="American Express" />
                </CardsRow>
              </InputColumn>
            </form>
          </Container>
        </>
      )}
    </>
  );
}
