import React, { useState, useContext } from 'react';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import { IoIosTrash } from 'react-icons/io';

import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';

import { Container, Card, InputColumn, InputWrapper } from './styles';

export default function AddInvitee() {
  const { colors } = useContext(ThemeContext);
  const [contacts, setContacts] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ name: false, email: false });

  const validateEmail = () =>
    /* eslint-disable no-useless-escape */
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  const handleAddToList = () => {
    const checkErrors = {
      ...errors,
      name: name.length === 0,
      email: email.length === 0 || !validateEmail(email),
    };

    setErrors(checkErrors);

    if (!checkErrors.name && !checkErrors.email) {
      setContacts([{ id: contacts.length + 1, name, email }, ...contacts]);
      setName('');
      setEmail('');
    }
  };

  const handleRemoveContact = (id) => {
    setContacts(contacts.filter((contact) => contact.id !== id));
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonText="Next"
        buttonTextColor="#fff"
        buttonFunction={() =>
          history.push({ pathname: '/review-contacts', state: { contacts } })
        }
      >
        <h2>Add Invitee</h2>
        <h3>Add the name and email of your invitees to your list</h3>
      </SubHeader>
      <Container>
        <InputColumn>
          <InputWrapper>
            <Input
              placeholder="Enter name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={errors.name && 'error'}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              placeholder="Enter email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={errors.email && 'error'}
            />
          </InputWrapper>

          <Button text="Add To List" buttonFunction={() => handleAddToList()} />
        </InputColumn>

        <InputColumn>
          {contacts.length === 0 && <h3>No contacts added.</h3>}
          <>
            {contacts.map((contact) => (
              <Card key={contact.id}>
                <div>
                  <span>{contact.name}</span>
                </div>
                <div>
                  <span>{contact.email}</span>
                </div>
                <div>
                  <IoIosTrash
                    size={20}
                    color="#888"
                    onClick={() => handleRemoveContact(contact.id)}
                  />
                </div>
              </Card>
            ))}
          </>
        </InputColumn>
      </Container>
    </>
  );
}
