import styled, { css } from 'styled-components';

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  cursor: pointer;

  svg {
    color: #fff;

    @media (min-width: 768px) {
      color: #888;
    }
  }

  ${(props) =>
    props.hasUnread &&
    css`
      &::after {
        position: absolute;
        left: 20px;
        top: 4px;
        width: 24px;
        height: 24px;
        background: ${props.color};
        content: '${props.count ? props.count : 0}';
        border-radius: 50%;
        color: #fff;
        display: flex;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        line-height: 24px;
      }
    `}
`;

export const NotificationList = styled.div`
  position: absolute;
  width: 260px;
  left: calc(50% - 130px);
  top: calc(100% + 30px);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 15px 5px;
  display: ${(props) => (props.visible ? 'block' : 'none')};

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid rgba(0, 0, 0, 0.6);
  }
`;
