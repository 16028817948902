import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FabIndicator from '~/assets/images/fab-indicator.svg';

export const Container = styled.div`
  max-width: 720px;
  margin: 0px auto 40px;
  width: 90%;
  background-color: #f6f6f6;
  padding: 20px;
  position: relative;

  @media (max-width: 767px) {
    padding: 0;

    > div {
      padding: 20px 0;
    }
  }

  .MuiFab-primary {
    right: calc(50% - 430px);
    bottom: 80px;
    position: fixed;

    @media (max-width: 900px) {
      right: 20px;
      position: fixed;
    }

    &.show-indicator {
      position: absolute;
      right: 20px;

      @media (max-width: 767px) {
        right: 20px;
        bottom: -80px;
        position: absolute;
        margin-bottom: 20px;
      }

      &:before {
        content: '';
        display: block;
        width: 280px;
        height: 200px;
        background-image: url(${FabIndicator});
        position: absolute;
        right: 80px;
        bottom: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;

        @media (max-width: 767px) {
          width: 130px;
          background-size: cover;
          height: 120px;
          bottom: 20px;
          right: 70px;
        }
      }
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  max-width: 720px;
  margin: 20px auto 0;
  width: 90%;
  display: flex;

  .MuiTabs-indicator {
    top: 0;
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    width: 125px;
    height: 44px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin: 0 1px;
    cursor: pointer;
    background-color: #f1f1f1;
    opacity: 1;

    @media (max-width: 767px) {
      flex: 1;
    }

    .MuiTab-wrapper {
      color: #888;
    }

    &.MuiTab-textColorInherit.Mui-selected {
      background-color: #f6f6f6;

      span {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;
export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;
