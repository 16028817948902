import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import gift from './gift';

import SubHeader from '~/components/SubHeader';

import Button from '~/components/Button';
import { Container, ImageWrapper, Card } from './styles';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function FundingContinues() {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const getData = location && location.giftData;

  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : JSON.parse(getData && getData.data);

  const childName = parsedData && parsedData.child_name;

  console.log('##########', getData);
  console.log('notificationData', notificationData);

  return (
    <>
      <SubHeader backgroundColor={colors.purple} mobileDescVisible={false}>
        <h2>Funding Continues</h2>
      </SubHeader>
      <Container>
        <ImageWrapper
          backgroundImage={
            getData && getData.gift_images
              ? getData.gift_images[0].url
              : gift.image
          }
        />
        <div>
          <Card>
            <h3>New contributions for</h3>
            <h2>
              {childName && childName}&apos;s {getData && getData.name}
            </h2>
            <span>will be accepted until</span>
            <h3>10th January 2018</h3>
          </Card>

          <Button
            text="View Gift Details"
            buttonFunction={() =>
              history.push(
                `/gift-details/${
                  (getData && getData.id) ||
                  (notificationData && notificationData.gift_id)
                }`,
                { data: 'funding' },
              )
            }
          />
        </div>
      </Container>
    </>
  );
}
