import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { IoIosCalendar } from 'react-icons/io';

import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import Avatar from '~/components/Avatar';

import { ReactComponent as GiftIcon } from '~/assets/images/gift-icon.svg';

import {
  Container,
  TopWrapper,
  Contributions,
  GiftWishesWrapper,
  GiftWishesButton,
  OtherButtonsWrapper,
  DateContainer,
} from './styles';

function GiftList({ event, raised, value, handleInvitation }) {
  const { colors } = useContext(ThemeContext);
  const receiverId = useSelector((state) => state.receiverReducer.receiverId);

  return (
    <>
      <SubHeader
        more
        backgroundColor={colors.purple}
        backButtonFunction={() =>
          history.push(`/receiver-profile/${receiverId}`)
        }
      >
        <h2>Gift List Details</h2>
        <div className="hide-mobile">
          <DateContainer>
            <IoIosCalendar />
            {event.date}
          </DateContainer>
        </div>
      </SubHeader>
      <Container>
        <TopWrapper>
          <div>
            <Avatar image={event ? event.receiver.avatar : ''} size={120} />
            <div className="hide-desktop">
              <h1>{event.name}</h1>
              <DateContainer>
                <IoIosCalendar />
                {event.date}
              </DateContainer>
            </div>
          </div>
          <Contributions className="hide-mobile">
            <span className="raised">
              <strong>${raised}</strong> <small>Raised</small>
            </span>
            <div className="contributors">
              <div className="avatar-row">
                {event &&
                  event.contributors.avatars.slice(0, 4).map((avatar) => (
                    <div className="image-wrapper" key={avatar.image}>
                      <Avatar image={avatar.image} size={40} />
                    </div>
                  ))}
              </div>
              <Link to={`/event-contributors/${event.id}`}>
                <span>
                  {event ? event.contributors.total : ''} contributors
                </span>
              </Link>
            </div>
          </Contributions>
          <OtherButtonsWrapper>
            <Button
              text="View Invite"
              backgroundColor="#fff"
              textColor="#999"
              buttonFunction={() => history.push()}
            />
            <Button
              text="Memories"
              backgroundColor="#fff"
              textColor="#999"
              buttonFunction={() => history.push('/event-album/1')}
            />
          </OtherButtonsWrapper>
        </TopWrapper>
        <GiftWishesWrapper>
          {event &&
            event.gift.map((gift) => (
              <GiftWishesButton
                key={gift.id}
                onClick={() => history.push(`/gift-details/${gift.id}`)}
              >
                <GiftIcon />
                <div>
                  <h2>{gift.title}</h2>
                  <p>{parseInt((raised / value) * 100, 10)}% Funded</p>
                </div>
              </GiftWishesButton>
            ))}
          <Button
            text="Share List With Friends"
            buttonFunction={handleInvitation}
            className="share-list hide-mobile"
          />
        </GiftWishesWrapper>
        <div className="hide-desktop">
          <Contributions>
            <span className="raised">
              <strong>${raised}</strong> <small>Raised</small>
            </span>
            <div className="contributors">
              <div className="avatar-row">
                {event &&
                  event.contributors.avatars.slice(0, 4).map((avatar) => (
                    <div className="image-wrapper" key={avatar.image}>
                      <Avatar image={avatar.image} size={40} />
                    </div>
                  ))}
              </div>
              <Link to={`/event-contributors/${event.id}`}>
                <span>
                  {event ? event.contributors.total : ''} contributors
                </span>
              </Link>
            </div>
          </Contributions>

          <Button
            text="Share List With Friends"
            buttonFunction={handleInvitation}
            className="share-list"
          />
        </div>
      </Container>
    </>
  );
}

GiftList.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    receiver: PropTypes.object,
    background: PropTypes.string,
    raised: PropTypes.number,
    gift: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      raised: PropTypes.number,
    }),
    contributors: PropTypes.shape({
      total: PropTypes.number,
      avatars: PropTypes.array,
    }),
    rsvps: PropTypes.shape({
      total: PropTypes.number,
      avatars: PropTypes.array,
    }),
  }).isRequired,
  handleInvitation: PropTypes.func.isRequired,
};

export default GiftList;
