import styled from 'styled-components';

export const Container = styled.div`
  width: 120px;
  height: 120px;
  margin: 0 auto -60px;
  border-radius: 50%;
  background-color: #e6e6e6;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  transform: translateY(calc(-50% - 25px));
  position: relative;
  cursor: pointer;

  @media (max-width: 767px) {
    margin: 0 auto 20px;
    transform: none;
  }

  div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #03e3fa;
    color: white;
    top: 50%;
    transform: translate(50%, -50%);
    right: 0;
    transition: 0.5s ease-in-out all;
  }

  &:hover {
    div {
      transform: translate(50%, -70%);
    }
  }
`;
