import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import malePlaceholder from '~/assets/images/avatar-placeholder.png';
import femalePlaceholder from '~/assets/images/girl-face.svg';
import {
  Container,
  InputColumn,
  CheckboxControl,
  Card,
  InputValidation,
} from './styles';
import { fetchArchiveReceiverById } from '~/store/modules/receiver/receiverAction';
import { unHideReceivers } from '~/api/eventAPI';

export default function ArchivedProfiles() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const receiver = useSelector((state) => state.userReducer.profile);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const receiverProfile = useSelector(
    (state) => state.receiverReducer.archivedProfile,
  );

  const [contacts, setContacts] = useState();
  const [selectedReceiverIds, setSelectedReceiverIds] = useState([]);
  const handleContactCheck = (id) => (e) => {
    setContacts((prevCheckedReceiver) => {
      const updatedCheckedReceiver = prevCheckedReceiver.map((receiver) => ({
        ...receiver,
        checked: receiver.id === id ? !receiver.checked : receiver.checked,
      }));
      const updatedSelectedReceiverIds = updatedCheckedReceiver
        .filter((receiver) => receiver.checked)
        .map((receiver) => receiver.id);
      setSelectedReceiverIds(updatedSelectedReceiverIds);
      return updatedCheckedReceiver;
    });
  };
  const getArchiveReceiver = (id) => {
    Dispatch(fetchArchiveReceiverById(id));
  };

  useEffect(() => {
    getArchiveReceiver(receiver.id);
  }, []);

  useEffect(() => {
    if (receiverProfile && receiverProfile) {
      const receiverProfileData = receiverProfile.map((receiver) => ({
        ...receiver,
        checked: false,
      }));
      setContacts(receiverProfileData);
    }
  }, [receiverProfile]);

  const validateForm = () => {
    if (selectedReceiverIds.length === 0 && receiverProfile.length > 0) {
      setFormError(`validation failed: please select atleast one receiver!`);
      setSubmitButtonLoading(false);
      return 'validationFailed';
    } else if (receiverProfile.length === 0 && receiverProfile.length === 0) {
      setFormError(`validation failed: receivers not found!`);
      setSubmitButtonLoading(false);
      return 'validationFailed';
    }
  };

  const handleNext = async () => {
    setSubmitButtonLoading(true);
    const error = validateForm();
    if (error !== 'validationFailed') {
      const ids = {
        receiver_id: selectedReceiverIds,
      };
      await unHideReceivers(ids)
        .then((res) => {
          if (res && res.data) {
            setSubmitButtonLoading(false);
            history.push({
              pathname: '/success',
              state: {
                destination: `/app-settings`,
                message: 'Profile(s) are now visible!',
                colors: { start: colors.lightPurple, end: colors.secondary },
              },
            });
          }
        })
        .catch((err) => {
          setSubmitButtonLoading(false);
          console.log('err', err);
        });
    }
  };

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 3000);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonText="Unhide"
        buttonTextColor="#fff"
        buttonFunction={handleNext}
        submitButtonLoading={submitButtonLoading}
      >
        <h2>Archived Profiles</h2>
        <h3>Select the profile you’d like to make visable again:</h3>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        <InputColumn>
          {contacts && contacts.length > 0 ? (
            <>
              {contacts &&
                contacts.map((contact) => (
                  <CheckboxControl
                    key={contact.id}
                    control={
                      <Card>
                        <div>
                          <img
                            src={
                              contact.child_image
                                ? contact.child_image
                                : contact.gender === 'M'
                                ? malePlaceholder
                                : femalePlaceholder
                            }
                            alt={`${
                              contact.first_name
                                ? contact.first_name + ' ' + contact.last_name
                                : contact.group_name
                            }`}
                          />
                          <div>
                            <h3>{`${
                              contact.first_name
                                ? contact.first_name + ' ' + contact.last_name
                                : contact.group_name
                            }`}</h3>
                          </div>
                        </div>
                        <div>
                          <Checkbox
                            checked={contact.checked}
                            onChange={handleContactCheck(contact.id)}
                            name={toString(contact.id)}
                            color="primary"
                          />
                        </div>
                      </Card>
                    }
                  />
                ))}
            </>
          ) : (
            <h3>You haven&apos;t .</h3>
          )}
        </InputColumn>
      </Container>
    </>
  );
}
