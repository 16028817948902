import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);
  }

  h2 {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 10px;
  }

  p {
    max-width: 360px;
    margin: 0 auto 20px;
    color: #656565;
    font-size: 15px;
    text-align: center;

    a {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  .gift-wish {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid #d2d2d2;
    max-width: 240px;

    img {
      margin-right: 10px;
      margin-left: 20px;
      max-width: 36px;
      max-height: 36px;
      height: auto;
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
  }

  button {
    margin: 30px 0;
    max-width: 350px;
  }
`;
