import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import Context from '~/context';
import { Wrapper } from './styles';
import Menu from '~/components/Menu';
import Footer from '~/components/Footer';

export default function DefaultLayout({ children }) {
  const { events, receivers, gifts } = useContext(Context);
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [flow, setFlow] = useState(
    localStorage.getItem('flow')
      ? JSON.parse(localStorage.getItem('flow'))
      : { giftOption: '', onboarding: false },
  );

  const resetFlow = () => {
    setFlow({ giftOption: '', onboarding: false });
  };

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleHideFooter = (value = false) => {
    setHideFooter(value);
  };

  const handleFlowUpdate = (value) => {
    const newFlow = { ...flow, ...value };
    setFlow(newFlow);
    localStorage.setItem('flow', JSON.stringify(newFlow));
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      handleShowMenu();
    }
  });

  const doNothing = () => null;

  useEffect(() => {
    localStorage.setItem('flow', JSON.stringify(flow));
  }, [flow]);

  return (
    <Context.Provider
      value={{
        handleShowMenu,
        handleShowModal,
        showModal,
        handleHideFooter,
        hideFooter,
        setFlow: (value) => handleFlowUpdate(value),
        flow,
        resetFlow,
        events,
        receivers,
        gifts,
      }}
    >
      <Wrapper className={showMenu ? 'show-menu' : ''}>
        <Menu />
        <main
          onClick={showMenu ? handleShowMenu : doNothing}
          onKeyDown={escFunction}
          role="presentation"
        >
          {children}
          {hideFooter || <Footer />}
        </main>
      </Wrapper>
    </Context.Provider>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
