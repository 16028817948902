import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  getAddGift,
  getFetchGiftDetailsById,
  getUpdateGift,
  getDeleteGift,
} from '~/api/giftAPI';
import { uploadGiftImage } from '~/api/imageAPI';
import {
  addGiftDone,
  createGiftDone,
  fetchGiftDetailsByIdDone,
  updateGiftDone,
  deleteGiftDone,
} from '~/store/modules/gift/giftAction';

export function* watchGetAddGift({ data, imageFile }) {
  try {
    const result = yield call(getAddGift, data, imageFile);
    const giftId = result.data.id;
    if (giftId) {
      if (imageFile) yield call(uploadGiftImage, giftId, imageFile);
      yield put(addGiftDone(giftId));
    }
    localStorage.setItem('onbordingGift', 'laterGift');
  } catch (err) {
    yield put(createGiftDone(true));
  }
}

export function* watchGetFetchGiftDetailsById({ id }) {
  try {
    const result = yield call(getFetchGiftDetailsById, id);
    if (result.status === 200) {
      yield put(fetchGiftDetailsByIdDone(result.data));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetGiftUpdate({ giftId, data }) {
  try {
    const result = yield call(getUpdateGift, giftId, data);
    if (result.status === 200) {
      yield put(updateGiftDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetGiftDelete({ giftId }) {
  try {
    const result = yield call(getDeleteGift, giftId);
    if (result.status === 200) {
      yield put(deleteGiftDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchUploadImage({ giftId, data }) {
  try {
    yield call(uploadGiftImage, giftId, data);
  } catch (err) {
    return err;
  }
}

export default all([
  takeLatest('CREATE_GIFT', watchGetAddGift),
  takeLatest('FETCH_GIFT_DETAILS_BY_ID', watchGetFetchGiftDetailsById),
  takeLatest('UPDATE_GIFT', watchGetGiftUpdate),
  takeLatest('DELETE_GIFT', watchGetGiftDelete),
  takeLatest('UPLOAD_GIFT_IMAGE', watchUploadImage),
]);
