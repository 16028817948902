import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import Context from '~/context';

import { ReactComponent as TrashCan } from '~/assets/images/trash-can.svg';

import { Container, OptionsWrapper } from './styles';

export default function DeleteBankModal() {
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);

  const handleDeleteAlbum = () => {
    handleShowModal();
    history.push({
      pathname: '/success',
      state: {
        destination: `/payment-details`,
        message: 'Credit Card Deleted.',
        colors: { start: colors.pink, end: colors.lightPurple },
      },
    });
  };

  return (
    <Container>
      <TrashCan />
      <h2>Are you sure you want to delete this Credit Card?</h2>
      <OptionsWrapper>
        <button type="submit" onClick={() => handleShowModal()}>
          Cancel
        </button>
        <button
          type="submit"
          className="red"
          onClick={() => handleDeleteAlbum()}
        >
          Delete
        </button>
      </OptionsWrapper>
    </Container>
  );
}
