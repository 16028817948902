import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  max-width: 720px;
  margin: 50px auto;
  width: 90%;
  background-color: #f6f6f6;
  flex-direction: column;
  display: flex;
  padding: 70px 40px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  button:not(:first-of-type) {
    margin-top: 20px;
  }
`;

export const BigButton = styled.button`
  padding: 0 15px;
  max-width: 398px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.purple};
  background-color: #fff;
  text-align: left;
  transition: all 0.5s;
  border: 0;
  cursor: pointer;

  &.open > svg {
    transform: rotate(90deg);
  }

  h3 {
    line-height: 1.2;

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  &:hover {
    box-shadow: 0px 0px 10px 0px #cacaca8a;

    > svg {
      opacity: 1;
    }
  }
`;
