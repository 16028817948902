import { takeLatest, call, put, all } from 'redux-saga/effects';
import { uploadReceiverImage } from '../../../api/imageAPI';
import history from '~/services/history';
import {
  getAddIndividualReceiver,
  getReceiverById,
  getUpdateReceiver,
  getDeleteReceiver,
  getArchivedEventById,
  getArchivedReceiverById,
} from '../../../api/receiverAPI';
import {
  addReceiverDone,
  fetchReceiverByIdDone,
  updateReceiverDone,
  deleteReceiverDone,
  fetchReceiverByIdError,
  uploadImageActionDone,
  fetchArchivedEventByIdDone,
  fetchArchiveReceiverByIdDone,
} from './receiverAction';

export function* watchGetAddReceiver({ data, imageFile }) {
  try {
    const result = yield call(getAddIndividualReceiver, data);
    const receiverId = result.data.id;
    if (receiverId) {
      if (imageFile) yield call(uploadReceiverImage, receiverId, imageFile);
      yield put(addReceiverDone(receiverId, result));
    }
    localStorage.setItem('onbording', 'later');
    return result;
  } catch (err) {
    return err;
  }
}

export function* watchGetReceiverById({ id }) {
  try {
    const result = yield call(getReceiverById, id);
    if (result.status === 200) {
      yield put(fetchReceiverByIdDone(result.data));
    } else {
      yield fetchReceiverByIdError();
    }
    return result;
  } catch (err) {
    return err;
  }
}
export function* watchGetArchiveEventById({ id }) {
  try {
    const result = yield call(getArchivedEventById, id);
    if (result.status === 200) {
      yield put(fetchArchivedEventByIdDone(result.data));
    }
    return result;
  } catch (err) {
    return err;
  }
}

export function* watchGetUpdateReceiver({ id, data }) {
  try {
    const result = yield call(getUpdateReceiver, id, data);
    if (result.status === 200) {
      yield put(updateReceiverDone(true));
    }
    return result;
  } catch (err) {
    return err;
  }
}

export function* watchUploadImage({ id, data }) {
  try {
    const result = yield call(uploadReceiverImage, id, data);
    if (result.status === 200) {
      yield put(uploadImageActionDone(result.data.path || null));
    }
    return result;
  } catch (err) {
    return err;
  }
}

export function* watchGetDeleteReceiver({ id }) {
  try {
    const result = yield call(getDeleteReceiver, id);
    if (result.status === 200) {
      yield put(deleteReceiverDone(true));
    }
    return result;
  } catch (err) {
    return err;
  }
}
export function* watchGetArchivedReceiverById({ id }) {
  try {
    const result = yield call(getArchivedReceiverById, id);
    if (result.status === 200) {
      yield put(fetchArchiveReceiverByIdDone(result.data));
    } else {
      // yield fetchReceiverByIdError();
    }
    return result;
  } catch (err) {
    return err;
  }
}

export default all([
  takeLatest('ADD_RECEIVER', watchGetAddReceiver),
  takeLatest('FETCH_RECEIVER_BY_ID', watchGetReceiverById),
  takeLatest('FETCH_ARCHIVE_EVENT_BY_ID', watchGetArchiveEventById),
  takeLatest('UPDATE_RECEIVER', watchGetUpdateReceiver),
  takeLatest('DELETE_RECEIVER', watchGetDeleteReceiver),
  takeLatest('UPLOAD_RECEIVER_IMAGE', watchUploadImage),
  takeLatest('FETCH_ARCHIVE_RECEIVER_BY_ID', watchGetArchivedReceiverById),
]);
