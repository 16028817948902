import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { Mobile } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';

import placeholder from './placeholder';

import { useDispatch, useSelector } from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Action
import { createEvent, editEvent } from '../../store/modules/event/eventAction';
import Context from '~/context';
import {
  Container,
  InputWrapper,
  InputColumn,
  SelectControl,
  DatePickerStyled as DatePicker,
  TimePickerStyled as TimePicker,
  InputValidation,
  LoaderDiv,
  LoadingText,
  RadioControl,
  RadioButton,
} from './styles';
import { useLocation } from 'react-router-dom';
import CircularIndeterminate from '~/components/Loader';

export default function EditPage() {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const Dispatch = useDispatch();
  const eventReducer = useSelector((state) => state.eventReducer);
  const organiserName = useSelector(
    (state) => state.userReducer.profile.first_name,
  );
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const { event, eventEditAddOption, isEventEdit } = eventReducer;
  const eventData = location.state && location.state;
  const [formError, setFormError] = useState(null);
  const [formError1, setFormError1] = useState(null);
  const [loader, setLoader] = useState(false);

  const [relation, setRelation] = useState(
    eventEditAddOption ? event.data.invite_greeting : '',
  );
  const [date, setDate] = useState(
    eventEditAddOption ? new Date(event.data.date) : new Date(),
  );
  const [startsAt, setStartsAt] = useState('');
  const [endsAt, setEndsAt] = useState(
    new Date(eventEditAddOption ? event.data.venue_end_date : ''),
  );
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const { flow } = useContext(Context);
  const [venue, setVenue] = useState('no');
  const [rsvp, setRsvp] = useState('no');
  const [eventTitle, setEventTitle] = useState('');
  const [venueName, setVenueName] = useState(
    eventEditAddOption ? event.data.venue : '',
  );
  const [des, setDec] = useState('');
  const [description, setDescription] = useState('');
  const { first_name, id, group_name } = receiver;

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };
  const handleVenueChange = (event) => {
    setVenue(event.target.value);
  };
  const handleRelationChange = (event) => {
    setRelation(event.target.value);
  };

  const handleRsvpChange = (event) => {
    setRsvp(event.target.value);
  };

  const handleStartsAt = (newDate) => {
    setStartsAt(newDate);
  };

  const handleEndsAt = (newDate) => {
    setEndsAt(newDate);
  };

  const validateForm = () => {
    if (eventTitle === '') {
      setFormError(`validation failed: event title required!`);
      return 'validationFailed';
    }
    if (des === '') {
      setFormError(`validation failed: event invite required!`);
      return 'validationFailed';
    }
    if (relation === '') {
      setFormError(`validation failed: select invite greeting`);
      return 'validationFailed';
    }
  };
  const validateForm1 = () => {
    if (startsAt === '') {
      setFormError1(`validation failed: start time required!`);
      return 'validationFailed';
    }
    if (venueName === '') {
      setFormError1(`validation failed: venue required!`);
      return 'validationFailed';
    }
  };

  const handleSubmit = () => {
    const error = validateForm();
    if (venue !== 'no' && venueName === '') {
      const error1 = validateForm1();
      if (error1 !== 'validationFailed') {
      }
    } else {
      if (error !== 'validationFailed') {
        if (!eventEditAddOption) {
          setSubmitButtonLoading(true);

          const data = {
            title: eventTitle,
            description: des,
            event_date: date,
            venue_start_date: startsAt === '' ? null : startsAt,
            venue_end_date: endsAt,
            venue: venue === 'yes' ? venueName : venue,
            child_id: id,
            invite_greeting: relation,
            type: 'onegift',
          };
          Dispatch(createEvent(data));
          setSubmitButtonLoading(false);
          history.push('/invite-design', {
            eventData: data,
            // imageKey: eventData.giftdata.imagekey,
            description: {
              des: description || des,
              name: first_name || group_name,
              giftName: "null",
              organiser: organiserName,
            },
            list:"true"
          });
        }
        if (eventEditAddOption) {
          setSubmitButtonLoading(true);
          const data = {
            title: eventTitle,
            description: des,
            event_date: date,
            venue_start_date: startsAt,
            venue_end_date: endsAt,
            venue: venue === 'yes' ? venueName : venue,
            child_id: id,
            invite_greeting: relation,
            type: event.data.type,
          };
          Dispatch(editEvent(event.data.id, data));
          setSubmitButtonLoading(false);
        }
      }
    }
  };

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
    if (formError1 !== null) {
      setFormError1(null);
    }
  }, 3000);

  return (
    <>
      <SubHeader
        backgroundColor={colors.lightPurple}
        buttonText="Next"
        buttonFunction={() => handleSubmit()}
      >
        <>
          <h2>EDIT Page</h2>
          <h3>Enter the details of {first_name || group_name}'s page.</h3>
        </>
      </SubHeader>
      <InputValidation>
        {formError}
        {formError1}
      </InputValidation>
      <Container>
        {!loader ? (
          <>
            <form>
              <InputColumn>
                <InputWrapper>
                  <label htmlFor="choose-greeting">Invite Greeting</label>
                  <SelectControl variant="filled">
                    <InputLabel
                      id="choose-greeting"
                      disableAnimation
                      shrink={false}
                    >
                      Choose a greeting
                    </InputLabel>
                    <Select
                      labelId="choose-greeting"
                      id="relation"
                      value={relation}
                      onChange={handleRelationChange}
                    >
                      <MenuItem value="Family">
                        Dear Family and Friends
                      </MenuItem>
                      <MenuItem value="Friends">Dear Friends</MenuItem>
                      <MenuItem value="NoGreeting">No Greeting</MenuItem>
                    </Select>
                  </SelectControl>
                </InputWrapper>
                <InputWrapper>
                  <label htmlFor="gift-description">EVENT INVITE</label>
                  <textarea
                    rows={4}
                    name="gift-description"
                    placeholder={placeholder}
                    value={des}
                    onChange={(e) => setDec(e.target.value)}
                  />
                </InputWrapper>
              </InputColumn>
              <InputColumn>
                <InputWrapper>
                  <Input
                    name="event-tile"
                    label="Event Title"
                    placeholder="Eg: Surfing lessons..."
                    value={eventTitle}
                    onChange={(e) => setEventTitle(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper>
                  <DatePicker
                    label="Date of Event"
                    name="gift-expected"
                    date={date}
                    onChange={handleDateChange}
                    minDate={new Date()}
                  />
                </InputWrapper>
                {(flow.giftOption &&
                  !flow.giftOption.includes('multiple-gifts')) || (
                  <>
                    <InputWrapper>
                      <RadioControl component="fieldset">
                        <label>Is there a venue for this event?</label>
                        <RadioGroup
                          aria-label="venue"
                          name="venue"
                          value={venue}
                          onChange={handleVenueChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<RadioButton />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<RadioButton />}
                            label="No"
                          />
                        </RadioGroup>
                      </RadioControl>
                    </InputWrapper>
                    {venue !== 'no' && (
                      <>
                        <InputWrapper className="time-picker">
                          <InputColumn>
                            <TimePicker
                              label="Starts at"
                              name="Starts At"
                              date={startsAt}
                              onChange={handleStartsAt}
                              minDate={new Date()}
                            />
                          </InputColumn>
                          <InputColumn>
                            <TimePicker
                              label="Ends at (Optional)"
                              name="ends-at"
                              date={endsAt}
                              onChange={handleEndsAt}
                              minDate={new Date()}
                            />
                          </InputColumn>
                        </InputWrapper>
                        <InputWrapper>
                          <Input
                            label="Venue"
                            placeholder="Add venue here"
                            value={venueName}
                            onChange={(e) => setVenueName(e.target.value)}
                          />
                        </InputWrapper>
                      </>
                    )}
                  </>
                )}
              </InputColumn>
            </form>
            <Mobile>
              <Button
                text="Save"
                textColor="#ffffff"
                backgroundColor={colors.purple}
                buttonFunction={() => handleSubmit()}
              />
            </Mobile>
          </>
        ) : (
          <LoaderDiv>
            <CircularIndeterminate />
            <LoadingText>Loading...</LoadingText>
          </LoaderDiv>
        )}
      </Container>
    </>
  );
}
