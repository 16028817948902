import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import { Mobile, Default } from '~/config/ReactResponsiveConfig';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import {
  Container,
  InputWrapper,
  EnvelopeHeart,
  InputValidation,
} from './styles';

export default function NoRSVP() {
  const location = useLocation();
  const { colors } = useContext(ThemeContext);

  const [formError, setFormError] = useState(null);
  const userData = location.state && location.state;

  const handleSubmit = () => {
    localStorage.removeItem('rspv');
    history.push('/rsvp-closed-confirmed', { rspv: 'rspvChecked' });
  };

  const handleSubmitAttend = () => {
    localStorage.removeItem('rspv');
    history.push('/rsvp-closed-confirmed', { rspv: 'norspv' });
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.lightPurple}
        mobileDescVisible={false}
        hideBackButton
      >
        <h2>
          <Mobile>RSVP</Mobile>
          <Default>Will YOU BE ATTENDING {userData && userData.name} PARTY?</Default>
        </h2>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        <>
          <EnvelopeHeart />
          <Mobile>
            <h2>Will YOU BE ATTENDING {userData && userData.name} PARTY?.</h2>
          </Mobile>
          <form>
            <Button
              text="I will be attending"
              backgroundColor="#01e3fa"
              buttonFunction={handleSubmit}
            />
            <button
              onClick={handleSubmitAttend}
              style={{
                background: 'none',
                fontSize: '16px',
                textTransform: 'none',
                border: 'none',
                color: 'rgb(19, 40, 127)',
              }}
            >
              Sorry, I won't be able to attend.
            </button>
          </form>
        </>
      </Container>
    </>
  );
}
