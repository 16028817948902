const initialState = {
  notifications: null,
  thankuCard: null,
  thankuCardPending: null,
  fetchNotification: false,
  notificationLinkData: null,
};
export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_NOTIFICATION_BY_USER_ID_DONE':
      return {
        ...state,
        notifications: action.data.data,
        fetchNotification: false,
      };
    case 'FETCH_THANKU_CARD_DONE':
      return { ...state, thankuCard: action.data.data };
    case 'FETCH_THANKU_CARD_DONE_PENDING':
      return { ...state, thankuCardPending: action.data.data };
    case 'FETCH_GIFT_CARD_DONE':
      return { ...state, notificationLinkData: action.data };
    default:
      return state;
  }
}
