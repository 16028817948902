import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { IoIosMail, IoIosLock, IoIosPerson } from 'react-icons/io';
import { toast } from 'react-toastify';

import { signInRequest, signUpRequest } from '~/store/modules/auth/authAction';
import SocialLogin from '~/components/SocialLogin';

import {
  InputWrapper,
  ContainerFooter,
  TopWrapper,
} from '~/pages/_layout/auth/styles';
import { fetchUserByEmail } from '~/store/modules/user/userAction';
import history from '~/services/history';
import { getSignUp } from '~/api/authAPI';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insert a valid email.')
    .required('A valid email is required.'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters long.')
    .required('Please, insert your password.'),
  name: Yup.string().required('Name required'),
});
export default function SignUp() {
  const dispatch = useDispatch();
  const [isFetchByEmail, setIsFetchByEmail] = useState(false);
  const profile = useSelector((state) => state.userReducer.profile);
  const location = useLocation();
  const userData = location.state && location.state;

  useEffect(() => {
    if (profile && profile) {
      setIsFetchByEmail(false);
      history.push('/welcome');
    }
  });
  
  const userRequestCheck = (email, password) => {
    setTimeout(() => {
      dispatch(signInRequest(email, password, 'NULL'));
    }, 2000);
    setTimeout(() => {
      dispatch(fetchUserByEmail(email));
    }, 4000);
  };

  async function handleSubmit({ name, email, password }) {
    setIsFetchByEmail(true);
    // dispatch(signUpRequest(name, email, password, 'NULL'));
    const payload = {
      name: name,
      email: email,
      password: password,
      signupType: 'NULL',
    };
    try {
      const result = await getSignUp(payload);
      if (result && result.status === 200) {
        userRequestCheck(email, password);
      } else {
        toast.error('email already exits');
        setIsFetchByEmail(false);
      }
    } catch (err) {
      setIsFetchByEmail(false);
      console.log(err);
    }
  }

  return (
    <>
      <TopWrapper>
        <h2>We make group gifting fun, personal and easy</h2>

        <Form schema={schema} onSubmit={handleSubmit} initialData={userData}>
          <InputWrapper>
            <IoIosPerson size={20} color="#a6cce2" />
            <Input name="name" type="text" placeholder="Name" />
          </InputWrapper>
          <InputWrapper>
            <IoIosMail size={20} color="#a6cce2" />
            <Input name="email" type="email" placeholder="Email" />
          </InputWrapper>
          <InputWrapper>
            <IoIosLock size={20} color="#a6cce2" />
            <Input name="password" type="password" placeholder="Password" />
          </InputWrapper>
          <button type="submit">
            {isFetchByEmail ? 'Please wait...' : 'Sign up for free'}
          </button>
        </Form>
        <SocialLogin />
      </TopWrapper>
      <ContainerFooter>
        <Link to="/">
          Already have an account? <strong>LOG IN</strong>
        </Link>
      </ContainerFooter>
    </>
  );
}
