import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';
import SubHeader from '~/components/SubHeader';
import { Container, GiftCard } from './styles';
import { darken } from 'polished';

export default function AboutUs() {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
        mobileDescVisible={false}
      >
        <h2>About Us</h2>
      </SubHeader>
      <Container className="scroller">
        <GiftCard>
          <div>
            <p className="paragraph">
              How great would it be if every gift we gave or received had a
              positive impact on everyone involved? Gifts with meaning. Gifts
              that are useful and fun. Gifts that are educational and needed.
              Gifts that put a smile on someone’s face. Gifts with purpose.{' '}
              <br /> <br />
              At Purposit, that’s our mission - to make gifting a rewarding,
              memorable experience for both the gift recipient as well as the
              giver.
              <br /> <br /> For us, gifting is more than just the gesture. It’s
              about providing a friend, child, relative or anyone you care about
              with something they will truly enjoy, use or benefit from.
              <br /> <br /> From toys to clothing; activities to experiences;
              travelling to education; gadgets to charity. From gifts needed
              today to something needed in the future - No gift is off limits
              with Purposit. Anything is possible.
            </p>

            <h3>The gifting re-evolution</h3>

            <p className="paragraph">
              Gifting is one of the oldest, most daring rituals in society. The
              simple gesture of granting someone a present to celebrate an
              occasion, a visit or special moment is inherited in most cultures.
              <br /> <br />
              Interestingly, the basics of gifting have not changed much over
              time. Most of us still go out and buy something we hope the gift
              receiver will need or enjoy. Sometimes that works, but more often
              than not, those gifts end up being re-gifted, exchanged, sold,
              thrown away or simply not used.
              <br /> <br /> The truth is that finding the right gift is never
              easy. We may not be close enough to the recipient to know what
              (s)he needs and might not feel comfortable to ask. We are also
              limited by our budget and lack of time for finding that perfect
              gift. <br /> <br />
              In an ideal world, every gift would be appreciated, enjoyed,
              useful and memorable. Everybody loves the thought of their
              hard-earned money going towards a gift that is truly wanted or
              needed. Less waste, more joy.
              <br /> <br /> That’s why we created Purposit – so the gift
              recipient can appreciate both the gesture of the person giving it
              and the gift itself. For every gift, every time. <br /> <br />
              Imagine you cannot find the gift you are looking for, the cost is
              too high, or you simply have no idea what to get. Purposit is
              there to help! We remove the guess-work, so you always know that
              what you are contributing towards will be enjoyed.
            </p>
            <h3>From babies to grandpas and everyone in between</h3>
            <p className="paragraph">
              We first created Purposit with a focus on funding gifts for
              children. As new parents, we wanted to reduce the number of
              duplicated or unnecessary gifts our children received and channel
              that generosity from friends towards the gifts that matter most.
              <br /> <br /> We quickly realised the problems we identified with
              gifting for kids also applied to adults. Regardless of age,
              finding the right gift for someone is difficult.
              <br /> <br /> So we’ve made Purposit suitable for all ages and
              groups - from babies to children, teens to grandparents, teachers
              to colleagues, yourself to someone you want to help. Purposit can
              be enjoyed by anyone, anytime, anywhere.
            </p>
            <h3>Gift funding is just the beginning</h3>
            <p className="paragraph">
              While funding meaningful and useful gifts is at the heart of
              Purposit, we wanted to create something unique and personal with a
              clear connection between gift givers and recipients.
              <br /> <br /> Purposit was designed to be a private, secure and
              fun platform for friends and families to celebrate and remember
              special events taking place in the lives of those they care about.
              <br /> <br /> The platform is also a way to make everyone involved
              in gifting more conscious about what they buy and the implications
              to our environment. When it comes to waste, less is always more.
              <br /> <br /> Welcome to the gifting re-evolution. I truly hope
              you enjoy Purposit.
              <br />
              <br />
              Sincerely,
              <br />
              <br />
              Johanna Kollmann
              <br />
              Co-Founder
            </p>
          </div>
        </GiftCard>
      </Container>
    </>
  );
}
