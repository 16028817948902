import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';
import Context from '~/context';

import LaterScreen from './LaterScreen';
import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import eventTypes from './eventTypes';

import { Container, BigButton, LoaderDiv, LoadingText } from './styles';
import { useLocation } from 'react-router-dom';
import {
  giftMultiRec,
  giftSingleRec,
  partyMultiRec,
  partySingleRec,
} from '~/api/eventAPI';
import CircularIndeterminate from '~/components/Loader';

export default function SelectReceiver() {
  const { REACT_APP_S3_IMAGE_URL } = process.env;
  const [events, setEvents] = useState(eventTypes);

  const [multiParty, setMultiParty] = useState([]);
  const [singleParty, setSingleParty] = useState([]);
  const [multiGift, setMultiGift] = useState([]);
  const [singleGift, setSingleGift] = useState([]);
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const { colors } = useContext(ThemeContext);
  const eventData = location.state && location.state;
  const [showLater, setShowLater] = useState(false);
  const { flow } = useContext(Context);
  const receiverName = useSelector((state) => state.receiverReducer.receiver);

  const handleSubmit = async (data) => {
    if (
      ((receiverName && receiverName.group_name === null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name === null)) &&
      eventData.type === 'party' &&
      eventData.user === 'single'
    ) {
      history.push('/edit-event', {
        type: 'party',
        user: 'single',
        giftdata: data,
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name === null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name === null)) &&
      eventData.type === 'gift' &&
      eventData.user === 'single'
    ) {
      history.push('/edit-event', {
        type: 'gift',
        user: 'single',
        giftdata: data,
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'party' &&
      eventData.user === 'multiple'
    ) {
      history.push('/edit-event', {
        type: 'party',
        user: 'multiple',
        giftdata: data,
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'gift' &&
      eventData.user === 'multiple'
    ) {
      history.push('/edit-event', {
        type: 'gift',
        user: 'multiple',
        giftdata: data,
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'party' &&
      eventData.user === 'single'
    ) {
      history.push('/edit-event', {
        type: 'party',
        user: 'single',
        giftdata: data,
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'gift' &&
      eventData.user === 'single'
    ) {
      history.push('/edit-event', {
        type: 'gift',
        user: 'single',
        giftdata: data,
        receiverData: eventData && eventData.receiver,
      });
    }
  };
  const handleCreateEvent = () => {
    if (
      ((receiverName && receiverName.group_name === null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name === null)) &&
      eventData.type === 'party' &&
      eventData.user === 'single'
    ) {
      history.push('/edit-event', {
        type: 'party',
        user: 'single',
        giftdata: 'null',
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name === null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name === null)) &&
      eventData.type === 'gift' &&
      eventData.user === 'single'
    ) {
      history.push('/edit-event', {
        type: 'gift',
        user: 'single',
        giftdata: 'null',
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'party' &&
      eventData.user === 'multiple'
    ) {
      history.push('/edit-event', {
        type: 'party',
        user: 'multiple',
        giftdata: 'null',
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'gift' &&
      eventData.user === 'multiple'
    ) {
      history.push('/edit-event', {
        type: 'gift',
        user: 'multiple',
        giftdata: 'null',
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'party' &&
      eventData.user === 'single'
    ) {
      history.push('/edit-event', {
        type: 'party',
        user: 'single',
        giftdata: 'null',
        receiverData: eventData && eventData.receiver,
      });
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'gift' &&
      eventData.user === 'single'
    ) {
      history.push('/edit-event', {
        type: 'gift',
        user: 'single',
        giftdata: 'null',
        receiverData: eventData && eventData.receiver,
      });
    }
  };

  useEffect(() => {
    if (
      ((receiverName && receiverName.group_name === null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name === null)) &&
      eventData.type === 'party' &&
      eventData.user === 'single'
    ) {
      getPartySingleRec();
    } else if (
      ((receiverName && receiverName.group_name === null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name === null)) &&
      eventData.type === 'gift' &&
      eventData.user === 'single'
    ) {
      getSingleGiftRec();
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'party' &&
      eventData.user === 'multiple'
    ) {
      getMultiPartyRec();
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'gift' &&
      eventData.user === 'multiple'
    ) {
      getMultiGiftRec();
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'party' &&
      eventData.user === 'single'
    ) {
      getPartySingleRec();
    } else if (
      ((receiverName && receiverName.group_name !== null) ||
        (eventData &&
          eventData.receiver &&
          eventData.receiver.group_name !== null)) &&
      eventData.type === 'gift' &&
      eventData.user === 'single'
    ) {
      getSingleGiftRec();
    }
    localStorage.removeItem('giftId');
    localStorage.removeItem('cardData');
    localStorage.removeItem('addinfo');
  }, []);

  const getSingleGiftRec = async () => {
    setLoader(true);
    await giftSingleRec('inviteTemplates')
      .then((res) => {
        setSingleGift(res.inviteTemplates);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getMultiGiftRec = async () => {
    setLoader(true);
    await giftMultiRec('inviteTemplates')
      .then((res) => {
        setMultiGift(res.inviteTemplates);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getMultiPartyRec = async () => {
    setLoader(true);
    await partyMultiRec('inviteTemplates')
      .then((res) => {
        setMultiParty(res.inviteTemplates);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getPartySingleRec = async () => {
    setLoader(true);
    await partySingleRec('inviteTemplates')
      .then((res) => {
        setSingleParty(res.inviteTemplates);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleOthersVisible = (id) => {
    const visibleEvents = events.map((event) => ({
      ...event,
      visible: event.id === id && !event.visible,
    }));
    setEvents(visibleEvents);
  };
  const handleOthersVisible1 = (data) => {
    const visibleEvents = singleParty.map((event) => ({
      ...event,
      visible: event.key === data.key && !event.visible,
    }));
    setSingleParty(visibleEvents);
  };
  const handleOthersVisible2 = (data) => {
    const visibleEvents = singleGift.map((event) => ({
      ...event,
      visible: event.key === data.key && !event.visible,
    }));
    setSingleGift(visibleEvents);
  };
  const handleOthersVisible3 = (data) => {
    const visibleEvents = multiParty.map((event) => ({
      ...event,
      visible: event.key === data.key && !event.visible,
    }));
    setMultiParty(visibleEvents);
  };
  const handleOthersVisible4 = (data) => {
    const visibleEvents = multiGift.map((event) => ({
      ...event,
      visible: event.key === data.key && !event.visible,
    }));
    setMultiGift(visibleEvents);
  };
  
  return (
    <>
      {showLater ? (
        <LaterScreen />
      ) : (
        <>
          <SubHeader
            backgroundColor={colors.primary}
            buttonBackgroundColor="#4FF3FE"
            buttonText={flow.giftOption === 'one-gift' ? 'Later' : ''}
            buttonTextColor="#142484"
            mobileNavButtons={false}
            buttonFunction={() => setShowLater(true)}
          >
            <h2>
              {eventData && eventData.type === 'party'
                ? 'ADD EVENT'
                : 'ADD GIFT INVITE'}
            </h2>
            <h3 style={{ color: '#142484' }}>
              {eventData && eventData.type === 'party'
                ? `Let’s create an invite for ${
                    (receiverName && receiverName.first_name) ||
                    (receiverName && receiverName.group_name) ||
                    (eventData && eventData.receiver.first_name) ||
                    (eventData &&
                      eventData.receiver &&
                      eventData.receiver.group_name)
                  }’s special occasion`
                : `Let’s create an invite for ${
                    (receiverName && receiverName.first_name) ||
                    (receiverName && receiverName.group_name) ||
                    (eventData && eventData.receiver.first_name) ||
                    (eventData &&
                      eventData.receiver &&
                      eventData.receiver.group_name)
                  }’s gift`}
            </h3>
          </SubHeader>
          <Container>
            {!loader ? (
              ((receiverName && receiverName.group_name === null) ||
                (eventData &&
                  eventData.receiver &&
                  eventData.receiver.group_name === null)) &&
              eventData.type === 'party' &&
              eventData.user === 'single' ? (
                <div>
                  {singleParty.map((event) => (
                    <BigButton
                      key={event.key}
                      onClick={() => handleOthersVisible1(event)}
                      className={event.visible ? 'open' : ''}
                    >
                      {/* <div className="eventImageContainer"> */}
                      <div>
                        <div className="image-wrapper">
                          <img
                            className="eventIcon"
                            src={`${REACT_APP_S3_IMAGE_URL}/templates/icons/${event.icon}`}
                            alt=""
                          />
                        </div>
                        <div className="text-content">
                          <h2>{event.key}</h2>
                        </div>
                        <IoIosArrowForward size={25} color={colors.primary} />
                      </div>
                      {/* </div> */}
                      <div
                        className={`other-options${
                          event.visible ? ' open' : ''
                        }`}
                      >
                        <div className="top-detail" />
                        <div className="options">
                          {event.categories.map((option) => (
                            <button
                              key={option.filekey}
                              type="submit"
                              onClick={() => handleSubmit(option)}
                            >
                              <img
                                src={`${REACT_APP_S3_IMAGE_URL}/templates/jpeg/${option.imagekey}.jpg`}
                                alt=""
                              />
                              <p>{option.name}</p>
                            </button>
                          ))}
                        </div>
                      </div>
                    </BigButton>
                  ))}
                  <Button
                    text="Create Your Own"
                    textColor="#fff"
                    backgroundColor={colors.purple}
                    buttonFunction={() => handleCreateEvent()}
                  />
                </div>
              ) : ((receiverName && receiverName.group_name === null) ||
                  (eventData &&
                    eventData.receiver &&
                    eventData.receiver.group_name === null)) &&
                eventData.type === 'gift' &&
                eventData.user === 'single' ? (
                <div>
                  {singleGift.map((event) => (
                    <BigButton
                      key={event.key}
                      onClick={() => handleOthersVisible2(event)}
                      className={event.visible ? 'open' : ''}
                    >
                      {/* <div className="eventImageContainer"> */}
                      <div>
                        <div className="image-wrapper">
                          <img
                            className="eventIcon"
                            src={`${REACT_APP_S3_IMAGE_URL}/templates/icons/${event.icon}`}
                            alt=""
                          />
                        </div>
                        <div className="text-content">
                          <h2>{event.key}</h2>
                        </div>
                        <IoIosArrowForward size={25} color={colors.primary} />
                        {/* </div> */}
                      </div>
                      <div
                        className={`other-options${
                          event.visible ? ' open' : ''
                        }`}
                      >
                        <div className="top-detail" />
                        <div className="options">
                          {event.categories.map((option) => (
                            <button
                              key={option.filekey}
                              type="submit"
                              onClick={() => handleSubmit(option)}
                            >
                              <img
                                src={`${REACT_APP_S3_IMAGE_URL}/templates/jpeg/${option.imagekey}.jpg`}
                                alt=""
                              />
                              <p>{option.name}</p>
                            </button>
                          ))}
                        </div>
                      </div>
                    </BigButton>
                  ))}
                  <Button
                    text="Create Your Own"
                    textColor="#fff"
                    backgroundColor={colors.purple}
                    buttonFunction={() => handleCreateEvent()}
                  />
                </div>
              ) : ((receiverName && receiverName.group_name !== null) ||
                  (eventData &&
                    eventData.receiver &&
                    eventData.receiver.group_name !== null)) &&
                eventData.type === 'party' &&
                eventData.user === 'multiple' ? (
                <div>
                  {multiParty.map((event) => (
                    <BigButton
                      key={event.key}
                      onClick={() => handleOthersVisible3(event)}
                      className={event.visible ? 'open' : ''}
                    >
                      {/* <div className="eventImageContainer"> */}
                      <div>
                        <div className="image-wrapper">
                          <img
                            className="eventIcon"
                            src={`${REACT_APP_S3_IMAGE_URL}/templates/icons/${event.icon}`}
                            alt=""
                          />
                        </div>
                        <div className="text-content">
                          <h2>{event.key}</h2>
                        </div>
                        <IoIosArrowForward size={25} color={colors.primary} />
                      </div>
                      {/* </div> */}
                      <div
                        className={`other-options${
                          event.visible ? ' open' : ''
                        }`}
                      >
                        <div className="top-detail" />
                        <div className="options">
                          {event.categories.map((option) => (
                            <button
                              key={option.filekey}
                              type="submit"
                              onClick={() => handleSubmit(option)}
                            >
                              <img
                                src={`${REACT_APP_S3_IMAGE_URL}/templates/jpeg/${option.imagekey}.jpg`}
                                alt=""
                              />
                              <p>{option.name}</p>
                            </button>
                          ))}
                        </div>
                      </div>
                    </BigButton>
                  ))}
                  <Button
                    text="Create Your Own"
                    textColor="#fff"
                    backgroundColor={colors.purple}
                    buttonFunction={() => handleCreateEvent()}
                  />
                </div>
              ) : ((receiverName && receiverName.group_name !== null) ||
                  (eventData &&
                    eventData.receiver &&
                    eventData.receiver.group_name !== null)) &&
                eventData.type === 'gift' &&
                eventData.user === 'multiple' ? (
                <div>
                  {multiGift.map((event) => (
                    <BigButton
                      key={event.key}
                      onClick={() => handleOthersVisible4(event)}
                      className={event.visible ? 'open' : ''}
                    >
                      {/* <div className="eventImageContainer"> */}
                      <div>
                        <div className="image-wrapper">
                          <img
                            className="eventIcon"
                            src={`${REACT_APP_S3_IMAGE_URL}/templates/icons/${event.icon}`}
                            alt=""
                          />
                        </div>
                        <div className="text-content">
                          <h2>{event.key}</h2>
                        </div>
                        <IoIosArrowForward size={25} color={colors.primary} />
                      </div>
                      {/* </div> */}
                      <div
                        className={`other-options${
                          event.visible ? ' open' : ''
                        }`}
                      >
                        <div className="top-detail" />
                        <div className="options">
                          {event.categories.map((option) => (
                            <button
                              key={option.filekey}
                              type="submit"
                              onClick={() => handleSubmit(option)}
                            >
                              <img
                                src={`${REACT_APP_S3_IMAGE_URL}/templates/jpeg/${option.imagekey}.jpg`}
                                alt=""
                              />
                              <p>{option.name}</p>
                            </button>
                          ))}
                        </div>
                      </div>
                    </BigButton>
                  ))}
                  <Button
                    text="Create Your Own"
                    textColor="#fff"
                    backgroundColor={colors.purple}
                    buttonFunction={() => handleCreateEvent()}
                  />
                </div>
              ) : ((receiverName && receiverName.group_name !== null) ||
                  (eventData &&
                    eventData.receiver &&
                    eventData.receiver.group_name !== null)) &&
                eventData.type === 'party' &&
                eventData.user === 'single' ? (
                <div>
                  {singleParty.map((event) => (
                    <BigButton
                      key={event.key}
                      onClick={() => handleOthersVisible1(event)}
                      className={event.visible ? 'open' : ''}
                    >
                      {/* <div className="eventImageContainer"> */}
                      <div>
                        <div className="image-wrapper">
                          <img
                            className="eventIcon"
                            src={`${REACT_APP_S3_IMAGE_URL}/templates/icons/${event.icon}`}
                            alt=""
                          />
                        </div>
                        <div className="text-content">
                          <h2>{event.key}</h2>
                        </div>
                        <IoIosArrowForward size={25} color={colors.primary} />
                      </div>
                      {/* </div> */}
                      <div
                        className={`other-options${
                          event.visible ? ' open' : ''
                        }`}
                      >
                        <div className="top-detail" />
                        <div className="options">
                          {event.categories.map((option) => (
                            <button
                              key={option.filekey}
                              type="submit"
                              onClick={() => handleSubmit(option)}
                            >
                              <img
                                src={`${REACT_APP_S3_IMAGE_URL}/templates/jpeg/${option.imagekey}.jpg`}
                                alt=""
                              />
                              <p>{option.name}</p>
                            </button>
                          ))}
                        </div>
                      </div>
                    </BigButton>
                  ))}
                  <Button
                    text="Create Your Own"
                    textColor="#fff"
                    backgroundColor={colors.purple}
                    buttonFunction={() => handleCreateEvent()}
                  />
                </div>
              ) : ((receiverName && receiverName.group_name !== null) ||
                  (eventData &&
                    eventData.receiver &&
                    eventData.receiver.group_name !== null)) &&
                eventData.type === 'gift' &&
                eventData.user === 'single' ? (
                <div>
                  {singleGift.map((event) => (
                    <BigButton
                      key={event.key}
                      onClick={() => handleOthersVisible2(event)}
                      className={event.visible ? 'open' : ''}
                    >
                      {/* <div className="eventImageContainer"> */}
                      <div>
                        <div className="image-wrapper">
                          <img
                            className="eventIcon"
                            src={`${REACT_APP_S3_IMAGE_URL}/templates/icons/${event.icon}`}
                            alt=""
                          />
                        </div>
                        <div className="text-content">
                          <h2>{event.key}</h2>
                        </div>
                        <IoIosArrowForward size={25} color={colors.primary} />
                        {/* </div> */}
                      </div>
                      <div
                        className={`other-options${
                          event.visible ? ' open' : ''
                        }`}
                      >
                        <div className="top-detail" />
                        <div className="options">
                          {event.categories.map((option) => (
                            <button
                              key={option.filekey}
                              type="submit"
                              onClick={() => handleSubmit(option)}
                            >
                              <img
                                src={`${REACT_APP_S3_IMAGE_URL}/templates/jpeg/${option.imagekey}.jpg`}
                                alt=""
                              />
                              <p>{option.name}</p>
                            </button>
                          ))}
                        </div>
                      </div>
                    </BigButton>
                  ))}
                  <Button
                    text="Create Your Own"
                    textColor="#fff"
                    backgroundColor={colors.purple}
                    buttonFunction={() => handleCreateEvent()}
                  />
                </div>
              ) : (
                <div></div>
              )
            ) : (
              <LoaderDiv>
                <CircularIndeterminate />
                <LoadingText>Loading...</LoadingText>
              </LoaderDiv>
            )}
          </Container>
        </>
      )}
    </>
  );
}
