import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 15px;
    margin: 0;
    grid-template-columns: auto;

    button {
      max-width: 100%;
      max-height: 50px;
      margin: 0 auto;
    }
  }

  button {
    margin-left: auto;
  }
`;
export const Container2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 15px;
    margin: 0;
    grid-template-columns: auto;

    button {
      max-width: 100%;
      max-height: 50px;
      margin: 0 auto;
    }
  }

  button {
    margin-left: auto;
  }
`;
export const GiftCard = styled.div`
  background: white;
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 115px;

    img {
      width: 80px;
      height: 80px;
      min-width: 80px;
      object-fit: cover;
      object-position: center;
      margin-right: 20px;

      @media (max-width: 767px) {
        margin-right: 10px;
      }
    }

    div {
      display: flex;
    }

    > div {
      min-height: 80px;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;

      > div {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        div:first-child {
          flex-direction: column;
        }

        div:last-child {
          align-items: center;

          @media (max-width: 767px) {
            align-items: start;
          }
        }
      }
    }

    h3 {
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 18px;
    }

    svg {
      margin-right: -10px;
    }

    small {
      color: #aeaeae;
      font-size: 13px;
    }

    .gift-price {
      font-size: 20px;
      color: #878787;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    p {
      font-size: 12px;
      line-height: 15px;
      color: #656565;
    }
  }

  .progress-bar {
    margin-top: 20px;

    > div:first-of-type {
      background-color: #e9ecee;
      border-radius: 2px;
    }

    > div {
      display: flex;
      justify-content: space-between;
      color: #888;
    }
  }

  .contribution {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.secondary};

    input {
      width: 116px;
      height: 38px;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      text-align: right;
      padding: 10px;
      font-size: 15px;
    }
  }
`;

export const TotalContainer = styled.div`
  max-width: 720px;
  background-color: #f6f6f6;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #878787;
  flex-direction: column;

  > div {
    display: flex;

    > div {
      flex: 1;

      &:last-of-type {
        text-align: right;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
  }

  span {
    font-weight: bold;
    font-size: 22px;
  }
`;
export const TotalContainer2 = styled.div`
  background-color: #f6f6f6;
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  color: #878787;
  flex-direction: column;

  > div {
    display: flex;

    > div {
      flex: 1;

      &:last-of-type {
        text-align: right;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
  }

  span {
    font-weight: bold;
    font-size: 22px;
  }
`;

export const ErrorContainer = styled.div`
  text-align: end;
  color: red;
  padding: 9px 0px 0px 0px;
`;

export const GiftCard1 = styled.div`
  // background: #f6f6f6;
  // box-shadow: 0px 0px 10px 0px #cacaca8a;
    display: flex;
   flex-direction: column;
  padding: 15px;
  cursor: pointer;

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    // align-items: center;
    max-height: 115px;

    img {
      width: 80px;
      height: 80px;
      min-width: 80px;
      object-fit: cover;
      object-position: center;
      margin-right: 20px;

      @media (max-width: 767px) {
        margin-right: 10px;
      }
    }

    > div {
      display: flex;

      > div {
        flex: 1;

        &:last-of-type {
          text-align: right;
        }
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 25px 30px;
      margin: 0;
    }

    span {
      font-weight: bold;
      font-size: 22px;
    }

    h3 {
      font-size: 18px;
      // letter-spacing: 0.5px;
      line-height: 18px;
      color: #878787;
    }

    svg {
      margin-right: -10px;
    }

    small {
      color: #aeaeae;
      font-size: 13px;
    }

    .gift-price {
      font-size: 20px;
      color: #878787;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

  }
  p {
    font-size: 18px;
    line-height: 15px;
    color: #656565;
  }

  .progress-bar {
    margin-top: 20px;

    > div:first-of-type {
      background-color: #e9ecee;
      border-radius: 2px;
    }

    > div {
      display: flex;
      justify-content: space-between;
      color: #888;
    }
  }

  .contribution {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.secondary};

    input {
      width: 116px;
      height: 38px;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      text-align: right;
      padding: 10px;
      font-size: 15px;
    }
  }
`;
