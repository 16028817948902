import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: 100%;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  height: 230px;
`;

export const Container = styled.div`
  max-width: 720px;
  margin: 50px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
    padding-bottom: 40px;
    margin: 0;
    min-height: calc(100% - 145px);
  }

  > div {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      max-width: initial;
    }

    &:last-child {
      margin-top: -37px;
      flex: 1;
    }

    > .button-wrapper {
      flex: 1;
      width: 90%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      button:last-of-type {
        max-width: 90%;
        max-height: 57px;
      }
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.wine};
    text-align: center;
    font-size: 25px;
  }

  h3 {
    color: ${({ theme }) => theme.colors.purple};
    font-size: 20px;
    max-width: 260px;
    text-align: center;
    margin: 0 auto 10px;
  }

  p {
    color: #666;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: bold;
  }

  small:first-of-type {
    color: #bfbfbf;
  }

  .notice {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
  }
`;

export const Card = styled.div`
  background: #fff;
  text-align: center;
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin-bottom: 20px;

  img {
    max-width: 100px;
    border-radius: 50px;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  span {
    color: #666;
    font-size: 18px;
  }
`;
