import React, { useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ThemeContext } from 'styled-components';
import { IoIosCheckmark, IoIosClose } from 'react-icons/io';
import history from '~/services/history';
import {
  fetchGiftDetailsById,
  setShowNotice,
} from '~/store/modules/gift/giftAction';
import Options from './ModalScreens/Options';
import { convertUTCDateToString } from '~/utils/utility';
import giftImage from '../../assets/images/gifts.svg';

import Header from '~/components/Header';
import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import Avatar from '~/components/Avatar';
import ModalWrapper from '~/components/ModalWrapper';

import {
  Container,
  GiftCard,
  ContactList,
  Contact,
  FundingCompleted,
} from './styles';
import { getNotificationByUserId } from '~/store/modules/notification/notificationAction';

export default function GiftDetails() {
  const Dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const eventId = queryParams.get('eventId');
  const funding = location && location.state;
  const { colors } = useContext(ThemeContext);
  const gift = useSelector((state) => state.giftReducer.currentGift);
  const showNotice = useSelector((state) => state.giftReducer.showNotice);
  const getFollow = localStorage.getItem('follow');
  const profile = useSelector((state) => state.userReducer.profile);

  let funded = 0;
  let raised = 0;
  let percentages = 0;

  if (gift) {
    if (getFollow && getFollow !== null) {
      const { contributor_contributions, price } = gift;
      if (contributor_contributions.length > 0) {
        const matchingContributions = contributor_contributions.filter(
          (don) => don.gift_id === gift.id,
          // && don.event_id === parseInt(eventId),
        );

        if (matchingContributions.length > 0) {
          const donations = matchingContributions.map((don) =>
            parseInt(don.donation),
          );
          raised = donations.reduce((a, b) => a + b);

          percentages = funded ? 100 : (raised / price) * 100;
          if ((percentages === 100 || funded) && showNotice == null) {
            Dispatch(setShowNotice(true));
          }
        }
      }
    } else {
      const { contributor_contributions, price } = gift;
      if (contributor_contributions.length > 0) {
        const donations = contributor_contributions.map((don) =>
          parseInt(don.donation),
        );
        raised = donations.reduce((a, b) => a + b);
        // funded = raised >= price ? price : location.state.funded;
      }
      percentages = funded ? 100 : (raised / price) * 100;
      if ((percentages === 100 || funded) && showNotice == null) {
        Dispatch(setShowNotice(true));
      }
    }
  }
  const handleSubmit = () => {
    history.push('/onboarding-success/2');
  };

  useEffect(() => {
    Dispatch(fetchGiftDetailsById(id));
    Dispatch(getNotificationByUserId(profile.id));
  }, [Dispatch, id]);

  const handleInviteFriends = () => {
    const invitePage = gift.eventExists ? '/gift-options' : '/event-type';
    history.push(invitePage);
  };

  const handleContribute = () => {
    history.push(`/gift-contribution/${eventId}?gift_Ids=${gift.id}`);
  };

  const handleHideNotice = () => {
    Dispatch(setShowNotice(false));
  };

  return (
    <>
      <Header />
      {getFollow && getFollow !== null ? (
        <SubHeader
          buttonFunction={() => handleSubmit()}
          mobileDescVisible={false}
        >
          <h2>Gift Details</h2>
        </SubHeader>
      ) : (
        <SubHeader
          more
          buttonFunction={() => handleSubmit()}
          mobileDescVisible={false}
          backButtonFunction={() =>
            funding && funding.data === 'funding'
              ? history.push('/my-profile')
              : history.goBack()
          }
        >
          <h2>Gift Details</h2>
        </SubHeader>
      )}
      <Container>
        <GiftCard>
          <div className="description-wrapper">
            <img
              src={
                gift &&
                gift.gift_images.length > 0 &&
                gift.gift_images[0].url !== null
                  ? gift.gift_images[0].url
                  : giftImage
              }
              alt={gift ? gift.name : ''}
            />
            <div>
              <div>
                <h3>{gift ? gift.name : ''}</h3>
                <small>{gift ? convertUTCDateToString(gift.date) : ''}</small>
              </div>
              <p>{gift ? gift.desc : ''}</p>
            </div>
          </div>
          <div>
            <div className="progress-bar">
              {getFollow && getFollow !== null ? (
                <span></span>
              ) : (
                <span>${gift ? gift.price : ''}</span>
              )}
              <LinearProgress
                variant="determinate"
                value={gift ? percentages : ''}
              />
              <div>
                {getFollow && getFollow !== null ? (
                  <span></span>
                ) : (
                  <span>${raised}</span>
                )}

                <span>{gift ? Number(percentages.toFixed(2)) : ''}%</span>
              </div>
            </div>
            {getFollow && getFollow !== null ? (
              <Button
                text="I WOULD LIKE TO CONTRIBUTE"
                textColor="#fff"
                backgroundColor={colors.purple}
                buttonFunction={handleContribute}
              />
            ) : (
              !funded && (
                <Button
                  // Stop Receiving Contributions
                  text="Invite Friends to Contribute"
                  textColor="#fff"
                  backgroundColor={colors.purple}
                  buttonFunction={handleInviteFriends}
                />
              )
            )}
            {getFollow && getFollow !== null
              ? ''
              : funded && (
                  <p>
                    <strong>Your share of the funding is $80</strong>
                  </p>
                )}
          </div>
        </GiftCard>
        {gift ? (
          <ContactList>
            {getFollow && getFollow !== null
              ? gift.contributor_contributions
                  .filter(
                    (contact) =>
                      // contact.event_id === parseInt(eventId) &&
                      contact.gift_id === gift.id,
                  )
                  .map((contact) => (
                    <Contact key={contact.id}>
                      <div>
                        <div className="contact-info">
                          <Avatar size={55} image={contact.user.Avatar} />
                          <div>
                            <h3>
                              {contact.user.first_name} {contact.user.last_name}
                            </h3>
                            {getFollow && getFollow !== null ? (
                              <span>
                                {' '}
                                {convertUTCDateToString(contact.date)}
                              </span>
                            ) : (
                              <span>
                                {convertUTCDateToString(contact.date)} | Thank
                                you card sent{' '}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="donated-value">
                          <span>${contact.donation}</span>
                          {getFollow && getFollow !== null ? (
                            <span></span>
                          ) : (
                            contact.invited || (
                              <IoIosCheckmark
                                size={30}
                                color={colors.primary}
                              />
                            )
                          )}
                        </div>
                      </div>
                      {!contact.invited || (
                        <div className="invite-button">
                          <Button
                            text="Send Thank You Card"
                            buttonFunction={() =>
                              history.push('/create-thank-you-cards')
                            }
                          />
                        </div>
                      )}
                    </Contact>
                  ))
              : gift.contributor_contributions.map((contact) => (
                  <Contact key={contact.id}>
                    <div>
                      <div className="contact-info">
                        <Avatar size={55} image={contact.user.Avatar} />
                        <div>
                          <h3>
                            {contact.user.first_name} {contact.user.last_name}
                          </h3>
                          {getFollow && getFollow !== null ? (
                            <span> {convertUTCDateToString(contact.date)}</span>
                          ) : (
                            <span>
                              {convertUTCDateToString(contact.date)}
                              {/* | Thank you
                              card sent{' '} */}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="donated-value">
                        <span>${contact.donation}</span>
                        {getFollow && getFollow !== null ? (
                          <span></span>
                        ) : (
                          contact.invited || (
                            <IoIosCheckmark size={30} color={colors.primary} />
                          )
                        )}
                      </div>
                    </div>
                    {!contact.invited || (
                      <div className="invite-button">
                        <Button
                          text="Send Thank You Card"
                          buttonFunction={() =>
                            history.push('/create-thank-you-cards')
                          }
                        />
                      </div>
                    )}
                  </Contact>
                ))}
          </ContactList>
        ) : (
          <h3>You haven&apos;t received any contributions yet.</h3>
        )}
      </Container>
      <FundingCompleted className={showNotice ? 'show' : ''}>
        <IoIosClose size={30} onClick={() => handleHideNotice()} />
        <div
          onClick={() =>
            history.push({
              pathname: '/create-thank-you-cards',
              state: { funded: true },
            })
          }
          role="presentation"
        >
          <h2>100% Funded!</h2>
          <p>Share the news with contributors</p>
        </div>
      </FundingCompleted>
      <ModalWrapper>
        <Options giftId={gift ? gift.id : ''} raised={raised} />
      </ModalWrapper>
    </>
  );
}
