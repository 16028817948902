import React, { useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import history from '~/services/history';
import { Default } from '~/config/ReactResponsiveConfig';
import GiftImage from '~/assets/images/bike-gift.jpg';

import Button from '~/components/Button';
import {
  Container,
  GiftCard,
  TotalContainer,
  TotalContainer2,
  ErrorContainer,
  Container2,
  GiftCard1,
} from './styles';

import giftsList from './gifts';
import CircularIndeterminate from '~/components/Loader';
import {
  getEventGiftByOneOrMoreId,
  getSingleEventGiftById,
} from '~/api/eventAPI';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getContribution, getContributionMultiple } from '~/api/giftAPI';
import {
  fetchGiftDetailsById,
  setShowNotice,
} from '~/store/modules/gift/giftAction';
import { useDispatch, useSelector } from 'react-redux';

var raised = 0;
var percentages = 0;
var funded = 0;
export default function Gifts({
  setTotal,
  giftId,
  total,
  setBothErrorMessage,
  bothErrorMessage,
  setErrorMessage,
  errorMessage,
  setRemainingAmount,
  remainingAmount,
  setContriAmount,
  contriAmount,
  selectedGifts,
  setSelectedGifts,
  setChecked,
  check,
}) {
  const event = useParams();
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const gift_Ids = queryParams.get('gift_Ids');
  const [loader, setLoader] = useState(false);
  const [valid, setValid] = useState('');
  const [giftLength, setGiftLength] = useState('');
  const [selectedId, setSelectedId] = useState([null]);
  const giftCheck = localStorage.getItem('gift');
  const Dispatch = useDispatch();
  const newGift = useSelector((state) => state.giftReducer.currentGift);
  const showNotice = useSelector((state) => state.giftReducer.showNotice);
  const user = useSelector((state) => state.userReducer.profile);
  const userId = user.id;
  let funded = 0;
  let raised = 0;
  let percentages = 0;
  const [gifts, setGifts] = useState(
    giftsList
      .map((gift) => ({ ...gift, contribution: '' }))
      .filter((gift) => gift.id === parseInt(giftId, 10)),
  );

  const handleSum = (newGifts) => {
    let contributionArray = [];
    let newTotal = 0;
    newGifts.forEach((gift) => {
      if (gift.contribution) {
        contributionArray.push(gift.contribution);
      }
      newTotal += !gift.contribution ? 0 : parseInt(gift.contribution);
    });
    let commaSeparatedContributions = contributionArray.join(',');
    setTotal(newTotal);
    setContriAmount(commaSeparatedContributions);
  };

  if (newGift) {
    const { contributor_contributions, price } = newGift;
    if (contributor_contributions.length > 0) {
      const matchingContributions = contributor_contributions.filter(
        (don) => don.gift_id === newGift.id,
        // && don.event_id === parseInt(event.giftId),
      );

      if (matchingContributions.length > 0) {
        const donations = matchingContributions.map((don) =>
          parseInt(don.donation),
        );
        raised = donations.reduce((a, b) => a + b);

        percentages = funded ? 100 : (raised / price) * 100;
        if ((percentages === 100 || funded) && showNotice == null) {
          Dispatch(setShowNotice(true));
        }
      }
    }
  }

  const getGift = async (eventIdd, giftIdd) => {
    setLoader(true);
    if (giftIdd) {
      await getEventGiftByOneOrMoreId(giftIdd)
        .then((res) => {
          setGifts(res);
          setLoader(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    } else {
    }
  };

  const validatePrice = (inputValue, price, id) => {
    const maxPrice = parseInt(price); // Maximum allowed price (adjust as needed)
    const payAmount = parseFloat(inputValue);
    const difference = maxPrice - parseInt(raised);

    gifts.map((gift) => {
      if (gift.id === id) {
        if (payAmount > difference) {
          setErrorMessage(`Amount cannot exceed $${difference}.`);
        } else if (difference === 0) {
          setErrorMessage(`Amount cannot be accept $${difference}.`);
        } else {
          setErrorMessage('');
        }
      } else {
      }
    });
  };

  const validateMultiplePrice = (inputValue, price, id) => {
    gifts.map((gift, i) => {
      if (gift.id === id) {
        const maxPrice = parseInt(price) - parseInt(raised[i]); // Maximum allowed price (adjust as needed)
        const inputPrice = parseFloat(inputValue);
        if (inputPrice > maxPrice) {
          setErrorMessage(`Amount cannot exceed $${maxPrice}.`);
          setValid(id);
          setGiftLength(gifts.length);
          // setBothErrorMessage(`Both amount field is required.`);
        } else if (inputPrice === 0) {
          setErrorMessage(`Amount cannot be accept $${inputPrice}.`);
          setValid(id);
          setGiftLength(gifts.length);
          // setBothErrorMessage(`Both amount field is required.`);
        } else {
          setErrorMessage('');
        }
      } else {
      }
    });
  };

  useEffect(() => {
    getGift(event.giftId, gift_Ids);
    getGiftS(event.giftId, gift_Ids);
  }, [giftId]);

  const getGiftS = async (eventidd, giftIdd) => {
    setLoader(true);
    const payload = {
      event_id: eventidd,
      gift_id: giftIdd,
    };
    await getSingleEventGiftById(payload)
      .then((res) => {
        Dispatch(fetchGiftDetailsById(res));
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoader(false);
      });
  };

  const handleContribution = async (id, contribution) => {
    const newGifts = gifts.map((gift) => {
      if (gift.id === id) {
        return { ...gift, contribution };
      } else {
      }
      return gift;
    });
    setSelectedId(id);
    setGifts(newGifts);
    handleSum(newGifts);
    collectObject(newGifts, id, contribution);
  };

  const collectObject = (newGifts, id, contribution) => {
    const dataObject = {
      user_id: parseInt(userId),
      gift_id: id,
      event_id: parseInt(event.giftId),
      contriAmount: contribution,
    };

    if (!isNaN(contribution)) {
      // Add or update the object in selectedGifts
      setSelectedGifts((prevSelectedGifts) => {
        const existingIndex = prevSelectedGifts.findIndex(
          (selectedGift) => selectedGift.gift_id === id,
        );
        if (existingIndex !== -1) {
          // Update the existing object
          prevSelectedGifts[existingIndex] = dataObject;
        } else {
          // Add the new object
          prevSelectedGifts.push(dataObject);
        }
        return [...prevSelectedGifts];
      });
      // }
    } else {
      // If id matches the currently selected gift, you can remove it here
      if (id === dataObject.gift_id) {
        setSelectedGifts((prevSelectedGifts) =>
          prevSelectedGifts.filter(
            (selectedGift) => selectedGift.gift_id !== id,
          ),
        );
      }
    }
  };

  return (
    <>
      {!loader ? (
        <>
          {gifts && gifts.length === 1 && giftCheck !== 'single' ? (
            <Container>
              {gifts &&
                gifts.map((gift) => (
                  <GiftCard key={gift.id}>
                    <div>
                      <img
                        src={
                          gift.gift_images.length !== 0
                            ? gift.gift_images[0].url
                            : GiftImage
                        }
                        alt={gift.title}
                      />
                      <div>
                        <div>
                          <div>
                            <h3>{gift.title}</h3>
                            <small>{gift.date}</small>
                          </div>
                          <div>
                            <span className="gift-price">${gift.cost}</span>
                          </div>
                        </div>
                        <div>
                          <p>{gift.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="progress-bar">
                      <LinearProgress
                        variant="determinate"
                        value={gift ? percentages : ''}
                      />
                      <div>
                        <span>${raised}</span>
                        <span>
                          {gift ? Number(percentages.toFixed(2)) : ''}%
                        </span>
                      </div>
                    </div>
                    <div className="contribution">
                      {raised && raised === parseInt(gift.cost) ? (
                        <span style={{ color: 'red' }}>
                          All contributions are complete.
                        </span>
                      ) : (
                        <span>Enter your contribution</span>
                      )}
                      {raised && raised === parseInt(gift.cost) ? (
                        <input
                          placeholder="$0"
                          value={gift.contribution}
                          type="number"
                          min={0}
                          disabled
                          onChange={(e) =>
                            handleContribution(
                              gift.id,
                              parseFloat(e.target.value),
                            )
                          }
                          onBlur={(e) =>
                            validatePrice(e.target.value, gift.cost, gift.id)
                          }
                        />
                      ) : (
                        <input
                          placeholder="$0"
                          value={gift.contribution}
                          type="number"
                          min={0}
                          onChange={(e) =>
                            handleContribution(
                              gift.id,
                              parseFloat(e.target.value),
                            )
                          }
                          onBlur={(e) =>
                            validatePrice(e.target.value, gift.cost, gift.id)
                          }
                        />
                      )}
                    </div>
                    <ErrorContainer>{errorMessage}</ErrorContainer>
                  </GiftCard>
                ))}
              <Default>
                <TotalContainer>
                  <div>
                    <div>
                      <h2>TOTAL AMOUNT CONTRIBUTED</h2>
                    </div>
                    <div>
                      <span>{`$${total}`}</span>
                    </div>
                  </div>

                  <p>
                    No one can see how much you are contributing except the
                    organiser of the event
                  </p>
                  <span></span>
                  <span></span>
                </TotalContainer>
              </Default>
            </Container>
          ) : gifts && gifts.length === 1 && giftCheck === 'single' ? (
            <>
              {!loader ? (
                gifts &&
                gifts.map((item) =>
                  item.additional_instruction === null ||
                  item.additional_instruction === '' ||
                  item.additional_instruction === 'null' ? (
                    <>
                      <Container>
                        {gifts &&
                          gifts.map((gift) => (
                            <GiftCard key={gift.id}>
                              <div>
                                <img
                                  src={
                                    gift.gift_images.length !== 0
                                      ? gift.gift_images[0].url
                                      : GiftImage
                                  }
                                  alt={gift.title}
                                />
                                <div>
                                  <div>
                                    <div>
                                      <h3>{gift.title}</h3>
                                      <small>{gift.date}</small>
                                    </div>
                                    <div>
                                      <span className="gift-price">
                                        ${gift.cost}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <p>{gift.description}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="progress-bar">
                                <LinearProgress
                                  variant="determinate"
                                  value={gift ? percentages : ''}
                                />
                                <div>
                                  <span>${raised}</span>
                                  <span>
                                    {gift ? Number(percentages.toFixed(2)) : ''}
                                    %
                                  </span>
                                </div>
                              </div>
                              <div className="contribution">
                                {raised && raised === parseInt(gift.cost) ? (
                                  <span style={{ color: 'red' }}>
                                    All contributions are complete.
                                  </span>
                                ) : (
                                  <span>Enter your contribution</span>
                                )}
                                {raised && raised === parseInt(gift.cost) ? (
                                  <input
                                    placeholder="$0"
                                    value={gift.contribution}
                                    type="number"
                                    min={0}
                                    disabled
                                    onChange={(e) =>
                                      handleContribution(
                                        gift.id,
                                        parseFloat(e.target.value),
                                      )
                                    }
                                    onBlur={(e) =>
                                      validatePrice(
                                        e.target.value,
                                        gift.cost,
                                        gift.id,
                                      )
                                    }
                                  />
                                ) : (
                                  <input
                                    placeholder="$0"
                                    value={gift.contribution}
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                      handleContribution(
                                        gift.id,
                                        parseFloat(e.target.value),
                                      )
                                    }
                                    onBlur={(e) =>
                                      validatePrice(
                                        e.target.value,
                                        gift.cost,
                                        gift.id,
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <ErrorContainer>{errorMessage}</ErrorContainer>
                            </GiftCard>
                          ))}
                        <Default>
                          <TotalContainer>
                            <div>
                              <div>
                                <h2>TOTAL AMOUNT CONTRIBUTED</h2>
                              </div>
                              <div>
                                <span>{`$${total}`}</span>
                              </div>
                            </div>

                            <p>
                              No one can see how much you are contributing
                              except the organiser of the event
                            </p>
                            <span></span>
                            <span></span>
                          </TotalContainer>
                        </Default>
                      </Container>
                    </>
                  ) : (
                    <>
                      <div style={{ width: '90%' }}>
                        <Default>
                          {/* <GiftCard> */}
                          <TotalContainer2>
                            <div style={{ display: 'flex' }}>
                              <div>
                                <h2>TOTAL AMOUNT CONTRIBUTED</h2>
                              </div>
                              <div>
                                <span>{`$${total}`}</span>
                              </div>
                            </div>

                            <p>
                              No one can see how much you are contributing
                              except the organiser of the event
                            </p>
                          </TotalContainer2>
                          {/* </GiftCard> */}
                        </Default>
                      </div>
                      <br />
                      <Container2>
                        {gifts &&
                          gifts.map((gift) => (
                            <GiftCard key={gift.id}>
                              <div>
                                <img
                                  src={
                                    gift.gift_images.length !== 0
                                      ? gift.gift_images[0].url
                                      : GiftImage
                                  }
                                  alt={gift.title}
                                />
                                <div>
                                  <div>
                                    <div>
                                      <h3>{gift.title}</h3>
                                      <small>{gift.date}</small>
                                    </div>
                                    <div>
                                      <span className="gift-price">
                                        {/* ${gift.cost} */}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <p>{gift.description}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="progress-bar">
                                <LinearProgress
                                  variant="determinate"
                                  value={gift ? percentages : ''}
                                />
                                <div>
                                  <span>{/* ${raised} */}</span>
                                  <span>
                                    {gift ? Number(percentages.toFixed(2)) : ''}
                                    %
                                  </span>
                                </div>
                              </div>
                              <div className="contribution">
                                {raised && raised === parseInt(gift.cost) ? (
                                  <span style={{ color: 'red' }}>
                                    All contributions are complete.
                                  </span>
                                ) : (
                                  <span>Enter your contribution</span>
                                )}
                                {raised && raised === parseInt(gift.cost) ? (
                                  <input
                                    placeholder="$0"
                                    value={gift.contribution}
                                    type="number"
                                    min={0}
                                    disabled
                                    onChange={(e) =>
                                      handleContribution(
                                        gift.id,
                                        parseFloat(e.target.value),
                                      )
                                    }
                                    onBlur={(e) =>
                                      validatePrice(
                                        e.target.value,
                                        gift.cost,
                                        gift.id,
                                      )
                                    }
                                  />
                                ) : (
                                  <input
                                    placeholder="$0"
                                    value={gift.contribution}
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                      handleContribution(
                                        gift.id,
                                        parseFloat(e.target.value),
                                      )
                                    }
                                    onBlur={(e) =>
                                      validatePrice(
                                        e.target.value,
                                        gift.cost,
                                        gift.id,
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <ErrorContainer>{errorMessage}</ErrorContainer>
                            </GiftCard>
                          ))}

                        <GiftCard1>
                          {gifts.map((gift) => (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div>
                                  <h3>CONTRIBUTION INSTRUCTIONS</h3>
                                </div>
                                <br />
                                <div>
                                  {gift.set_contribution_amount === null ? (
                                    <p style={{ marginLeft: '20px' }}>
                                      Minimum contribution : $
                                      {gift.minimum_contribution_amount}
                                    </p>
                                  ) : (
                                    <p style={{ marginLeft: '20px' }}>
                                      Set contribution : $
                                      {gift.set_contribution_amount}
                                    </p>
                                  )}
                                </div>
                                <br />
                                <br />
                                <div>
                                  <h3>ADDITIONAL INSTRUCTIONS</h3>
                                </div>
                                <br />
                              </div>
                              <span
                                style={{
                                  border: '1.5px solid white',
                                  marginTop: '12px',
                                }}
                              ></span>
                              <br />
                              <p>{gift.additional_instruction}</p>
                            </>
                          ))}
                        </GiftCard1>
                      </Container2>
                    </>
                  ),
                )
              ) : (
                <CircularIndeterminate />
              )}
            </>
          ) : (
            <>
              {!loader ? (
                <>
                  <div style={{ width: '90%' }}>
                    <Default>
                      <TotalContainer2>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <h2>TOTAL AMOUNT CONTRIBUTED</h2>
                          </div>
                          <div>
                            <span>{`$${total}`}</span>
                          </div>
                        </div>

                        <p>
                          No one can see how much you are contributing except
                          the organiser of the event
                        </p>
                      </TotalContainer2>
                    </Default>
                  </div>
                  <br />
                  <Container2>
                    {gifts &&
                      gifts.map((gift, index) => (
                        <GiftCard key={gift.id}>
                          <div>
                            <img
                              src={
                                gift.gift_images !== null
                                  ? gift.gift_images[0].url
                                  : GiftImage
                              }
                              alt={gift.title}
                            />
                            <div>
                              <div>
                                <div>
                                  <h3>{gift.title}</h3>
                                  <small>{gift.date}</small>
                                </div>
                                <div>
                                  <span className="gift-price">
                                    ${gift.cost}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p>{gift.description}</p>
                              </div>
                            </div>
                          </div>
                          <div className="progress-bar">
                            <LinearProgress
                              variant="determinate"
                              value={parseInt(percentages[index])}
                            />
                            <div>
                              <span>${raised[index]}</span>
                              <span>{percentages[index]}%</span>
                            </div>
                          </div>

                          <div className="contribution">
                            <span>Enter your contribution</span>
                            &emsp;
                            {!errorMessage || gift.id === valid ? (
                              <>
                                {raised[index] === gift.cost ? (
                                  <input
                                    placeholder="$0"
                                    value={gift.contribution}
                                    type="number"
                                    disabled
                                    min={0}
                                    onChange={(e) =>
                                      handleContribution(
                                        gift.id,
                                        parseFloat(e.target.value),
                                      )
                                    }
                                    onBlur={(e) =>
                                      validateMultiplePrice(
                                        e.target.value,
                                        gift.cost,
                                        gift.id,
                                      )
                                    }
                                  />
                                ) : (
                                  <input
                                    placeholder="$0"
                                    value={gift.contribution}
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                      handleContribution(
                                        gift.id,
                                        parseFloat(e.target.value),
                                      )
                                    }
                                    onBlur={(e) =>
                                      validateMultiplePrice(
                                        e.target.value,
                                        gift.cost,
                                        gift.id,
                                      )
                                    }
                                  />
                                )}
                              </>
                            ) : (
                              <input
                                placeholder="$0"
                                value={gift.contribution}
                                type="number"
                                min={0}
                                disabled
                                onChange={(e) =>
                                  handleContribution(
                                    gift.id,
                                    parseFloat(e.target.value),
                                  )
                                }
                                onBlur={(e) =>
                                  validateMultiplePrice(
                                    e.target.value,
                                    gift.cost,
                                    gift.id,
                                  )
                                }
                              />
                            )}
                          </div>
                          <ErrorContainer>
                            {gift.id === valid && gifts.length === giftLength
                              ? errorMessage
                              : ''}
                          </ErrorContainer>
                        </GiftCard>
                      ))}
                  </Container2>
                </>
              ) : (
                <CircularIndeterminate />
              )}
            </>
          )}
        </>
      ) : (
        <CircularIndeterminate />
      )}
    </>
  );
}
