import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';

import { ReactComponent as MaleFace } from '~/assets/images/male-face.svg';
import { ReactComponent as MultiFaces } from '~/assets/images/multi-faces.svg';

import SubHeader from '~/components/SubHeader';

import { Container, BigButton } from './styles';

export default function SelectReceiver() {
  const { colors } = useContext(ThemeContext);

  // const handleSubmit = () => {
  //   history.push('/add-receiver/individual');
  // };

  return (
    <>
      <SubHeader>
        <h2>GIFT RECEIVER</h2>
        <h3>Who do you want to fund gift(s)&nbsp;for?</h3>
      </SubHeader>
      <Container>
        <BigButton onClick={() => history.push('/add-receiver/individual')}>
          <div className="image-wrapper">
            <MaleFace alt="An Individual" />
          </div>
          <div className="text-content">
            <h2>An Individual</h2>
            <p>Eg: daughter, partner, grandpa, friend</p>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>
        <BigButton
          backgroundColor={colors.purple}
          onClick={() => history.push('/add-receiver/multiple')}
        >
          <div className="image-wrapper">
            <MultiFaces alt="Multiple People" />
          </div>
          <div className="text-content">
            <h2>Multiple People</h2>
            <p>Eg: couple, siblings, friends, parents</p>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>
      </Container>
    </>
  );
}
