import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container1 = styled.div`
  padding: 10px 20px 10px 10px;
  background: #fff;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccd7db;
  display: flex;
  max-width: 420px;
  width: 100%;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 10px;
  }

  .image-wrapper {
    display: flex;
    align-items: center;

    img {
      max-width: 50px;
      max-height: 50px;
      margin-right: 10px;
      width: 100%;
    }
  }

  .description {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    span {
      color: #222627;
      font-size: 18px;
      font-weight: bold;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    small {
      color: #7a858a;
    }
  }
`;
export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }
  form {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const GiftCard = styled.div`
  background: white;
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;
  position: relative;

  > div:first-of-type {
    h3 {
      font-size: 23px;
      letter-spacing: 0.5px;
      line-height: 20px;
      margin-top: 3px;
      margin-bottom: 8px;
    }

    h1 {
      font-size: 37px;
    }

    h4 {
      font-size: 19.3px;
    }

    svg {
      margin-right: -10px;
    }

    small {
      color: #aeaeae;
      font-size: 13px;
    }
    p.bborderline {
      border: 1px solid #6f6c6c;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    p {
      font-size: 19.4px;
      line-height: 18px;
      letter-spacing: 0.9px;
      color: #040404;
      font-weight: 500;
      text-align: justify;
    }
    span.box {
      display: flex;
    }
    p.heading {
      margin-right: 32px;
      font-weight: 500;
      color: #040404;
    }
    span.innerbox {
      display: flex;
    }
    span.boxlast {
      display: flex;
      margin-left: 30px;
    }
    a.linktext {
      text-decoration: underline;
      color: blue;
    }
    p.subHeading {
      margin-top: 13px;
    }
    span.box1 {
      display: flex;
      margin-top: -12px;
    }
    b.boldcss {
      font-size: 22.4px;
    }
    .boxdot {
      font-size: 19.4px;
      margin-left: 45px;
    }
    p.mainparaheading {
      margin-top: -9px;
    }
    .textpara {
      margin-left: 9px;
    }
  }
`;

export const NotificationList = styled.div`
  position: absolute;
  width: 340px;
  left: calc(50% - 154px);
  top: calc(100% + 20px);
  z-index: 1;
  background: #f6f6f6;
  padding: 15px 5px;
  box-shadow: 0px 4px 6px 2px #00000024;
  display: ${(props) => (props.visible ? 'block' : 'none')};

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #f6f6f6;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 360px;
  padding: 0px 5px;
  background: #ededed;
  padding: 10px 10px;
`;
