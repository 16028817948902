import styled from 'styled-components';

export const OutterWrapper = styled.div`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    button {
      margin-bottom: 30px;
      max-width: calc(100% - 40px);
    }
  }
`;

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto 40px;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
    margin: 0;
    min-height: calc(100% - 135px);
  }
`;

export const TotalContainer = styled.div`
  max-width: 720px;
  background-color: #f6f6f6;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #878787;
  flex-direction: column;

  > div {
    display: flex;

    > div {
      flex: 1;

      &:last-of-type {
        text-align: right;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
  }

  span {
    font-weight: bold;
    font-size: 22px;
  }
`;
