import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  text-align: center;

  svg {
    max-width: 150px;
    margin-top: 20px;
  }

  h3 {
    max-width: 260px;
    color: ${({ theme }) => theme.colors.purple};

    &.empty {
      color: #888;
    }
  }
`;
