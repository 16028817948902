import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { IoIosClose } from 'react-icons/io';
import history from '~/services/history';

import Context from '~/context';

import SubHeader from '~/components/SubHeader';
import UploadButton from '~/components/UploadButton';

import Envelope from '~/assets/images/birthday-envelope.png';

import {
  Container,
  Modal,
  ImagePicker,
  LetterTextContainer,
  ColorPicker,
  ColorButton,
  Title,
  GridImage,
  ImageContainer,
  UploadContainer,
} from './styles';
import { useLocation } from 'react-router-dom';
import { inviteEventCardImage } from '~/api/imageAPI';

export default function EditEvent() {
  const { REACT_APP_S3_IMAGE_URL } = process.env;
  const location = useLocation();
  const { colors } = useContext(ThemeContext);
  const { flow } = useContext(Context);
  const [modalVisible, setModalVisible] = useState(false);
  const [imageSelection, showImageSelection] = useState(false);
  const [titleBGSelection, showTitleBGSelection] = useState(false);
  const [selectFile, setSelectFile] = useState('');
  const childData = useSelector((state) => state.receiverReducer);
  const userData = useSelector((state) => state.userReducer.profile);
  const eventId = useSelector((state) => state.eventReducer.event);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [titleBackground, setTitleBackground] = useState(colors.pink);
  const event = location.state && location.state;

  const formattedContent = event.description.des
    .replace(/<RECEIVERS_NAME>/g, event.description.name)
    .replace(/<ORGANISER_FIRST_NAME>/g, event.description.organiser)
    .replace(/<RECEIVERS_ORGANISER_NAME>/g, event.description.organiser)
    .replace(/<HERHIS>/g, event.description.name)
    .replace(/<HERHIM>/g, event.description.name)
    .replace(/<SHEHE>/g, event.description.name)
    .replace(/<GIFT_NAME>/g, event.description.giftName)
    .replace(/<I_HAVE_REC_HAS>/g, 'null')
    .replace(/<IWE>/g, 'null')
    .replace(/\\n/g, '\n')
    .replace(/<ME_RECNAME>/g, 'null')
    .replace(/<WE_RECEIVERS_NAME>/g, event.description.name)
    .replace(/<CONTRIBUTION_DATE>/g, 'null');

  const event_description = event.description.des
    .replace(/<RECEIVERS_NAME>/g, event.description.name)
    .replace(/<ORGANISER_FIRST_NAME>/g, event.description.organiser)
    .replace(/<RECEIVERS_ORGANISER_NAME>/g, event.description.organiser)
    .replace(/<HERHIS>/g, event.description.name)
    .replace(/<HERHIM>/g, event.description.name)
    .replace(/<SHEHE>/g, event.description.name)
    .replace(/<GIFT_NAME>/g, event.description.giftName)
    .replace(/<I_HAVE_REC_HAS>/g, 'null')
    .replace(/<IWE>/g, 'null')
    .replace(/<ME_RECNAME>/g, 'null')
    .replace(/<WE_RECEIVERS_NAME>/g, event.description.name)
    .replace(/<CONTRIBUTION_DATE>/g, 'null');

  const paragraphs = formattedContent.split('\n'); // Split by single line breaks
  const [backgroundImage, setBackgroundImage] = useState([
    `${REACT_APP_S3_IMAGE_URL}/templates/jpeg/${event.imageKey}.jpg`,
  ]);
  const [selectedImage, setSelectedImage] = useState([backgroundImage]);

  const [backgroundDummy, setBackgroundDummy] = useState([]);
  const [selectedDummy, setSelectedDummy] = useState([backgroundDummy]);

  const handleSelectImage = (newImage) => {
    setSelectedImage(newImage);
    setSelectedDummy(newImage);
    showImageSelection(true);
    setModalVisible(true);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setBackgroundImage([...backgroundImage, imageUrl]);
      setBackgroundDummy([...backgroundDummy, imageUrl]);
      setSelectedImage(imageUrl);
      setSelectedDummy(imageUrl);
      setSelectFile(file);
    }
  };

  var modified_string;
  const handleSubmit = async () => {
    modified_string = event_description
      .replace('\n\n', '\\n\\n')
      .replace('\n', '\\n');
    setSubmitButtonLoading(true);
    if (
      (selectedImage && selectedImage.includes('blob')) ||
      (selectedDummy && selectedDummy.includes('blob'))
    ) {
      const formData = new FormData();
      formData.append('file', selectFile);
      await inviteEventCardImage(childData.id, formData)
        .then((res) => {
          const data = {
            title: event.eventData.title,
            description: modified_string,
            user_id: userData.id,
            child_id: parseInt(childData.id),
            color: titleBackground,
            invite_greeting: event.eventData.invite_greeting,
            image: res.data.path,
            event_id: eventId && eventId.id,
          };
          const additionDetail = {
            type: event && event.selectedType,
            user: event && event.selectedUser,
          };
          if (event && event.list) {
            history.push('/choose-gifts', {
              cardData: data,
              addDetail: additionDetail,
              receiverData: event && event.receiverData,
            });
          } else {
            switch (flow.giftOption) {
              case 'one-gift':
                history.push('/choose-gifts', {
                  cardData: data,
                  addDetail: additionDetail,
                  receiverData: event && event.receiverData,
                });
                break;
              case 'multiple-gifts':
                history.push('/choose-gifts', {
                  cardData: data,
                  addDetail: additionDetail,
                  receiverData: event && event.receiverData,
                });
                break;
              case 'multiple-gifts-invite':
                history.push('/choose-gifts', {
                  cardData: data,
                  addDetail: additionDetail,
                  receiverData: event && event.receiverData,
                });
                break;
              default:
                history.push('/choose-gifts', {
                  cardData: data,
                  addDetail: additionDetail,
                  receiverData: event && event.receiverData,
                });
                break;
            }
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
      setSubmitButtonLoading(false);
    } else {
      if (event.imageKey === undefined) {
        const imagee = selectedDummy && selectedDummy[0];
        const data = {
          title: event.eventData.title,
          description: modified_string,
          user_id: userData.id,
          child_id: parseInt(childData.id),
          color: titleBackground,
          invite_greeting: event.eventData.invite_greeting,
          image: selectedDummy.length === 1 ? imagee[0] : selectedDummy,
          event_id: eventId && eventId.id,
        };
        const additionDetail = {
          type: event && event.selectedType,
          user: event && event.selectedUser,
        };
        setSubmitButtonLoading(false);
        if (event && event.list) {
          history.push('/choose-gifts', {
            cardData: data,
            addDetail: additionDetail,
            receiverData: event && event.receiverData,
          });
        } else {
          switch (flow.giftOption) {
            case 'one-gift':
              history.push('/choose-gifts', {
                cardData: data,
                addDetail: additionDetail,
                receiverData: event && event.receiverData,
              });
              break;
            case 'multiple-gifts':
              history.push('/choose-gifts', {
                cardData: data,
                addDetail: additionDetail,
                receiverData: event && event.receiverData,
              });
              break;
            case 'multiple-gifts-invite':
              history.push('/choose-gifts', {
                cardData: data,
                addDetail: additionDetail,
                receiverData: event && event.receiverData,
              });
              break;
            default:
              // history.push('/manage-cards', { cardData: data});
              history.push('/choose-gifts', {
                cardData: data,
                addDetail: additionDetail,
                receiverData: event && event.receiverData,
              });
              break;
          }
        }
      } else {
        const imagee = selectedImage && selectedImage[0];
        const data = {
          title: event.eventData.title,
          description: event_description,
          user_id: userData.id,
          child_id: parseInt(childData.id),
          color: titleBackground,
          invite_greeting: event.eventData.invite_greeting,
          image: selectedImage.length === 1 ? imagee[0] : selectedImage,
          event_id: eventId && eventId.id,
        };
        const additionDetail = {
          type: event && event.selectedType,
          user: event && event.selectedUser,
        };
        setSubmitButtonLoading(false);
        if (event && event.list) {
          history.push('/choose-gifts', {
            cardData: data,
            addDetail: additionDetail,
            receiverData: event && event.receiverData,
          });
        } else {
          switch (flow.giftOption) {
            case 'one-gift':
              history.push('/choose-gifts', {
                cardData: data,
                addDetail: additionDetail,
                receiverData: event && event.receiverData,
              });
              break;
            case 'multiple-gifts':
              history.push('/choose-gifts', {
                cardData: data,
                addDetail: additionDetail,
                receiverData: event && event.receiverData,
              });
              break;
            case 'multiple-gifts-invite':
              history.push('/choose-gifts', {
                cardData: data,
                addDetail: additionDetail,
                receiverData: event && event.receiverData,
              });
              break;
            default:
              history.push('/choose-gifts', {
                cardData: data,
                addDetail: additionDetail,
                receiverData: event && event.receiverData,
              });
              break;
          }
        }
      }
    }
  };

  const handleModalVisible = (value) => {
    setModalVisible(value);
    if (!value) {
      showImageSelection(false);
      showTitleBGSelection(false);
    }
  };

  const handleImageChange = () => {
    showImageSelection(true);
    setModalVisible(true);
  };

  const handleTitleBackgroundChange = () => {
    showTitleBGSelection(true);
    setModalVisible(true);
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.lightPurple}
        buttonText="Next"
        buttonFunction={() => handleSubmit()}
        submitButtonLoading={submitButtonLoading}
      >
        <h2>Invite Design</h2>
        <h3>
          Click on the edit buttons to change picture or background colour.
        </h3>
      </SubHeader>
      <Container backgroundImage={Envelope}>
        <div className="letter-container">
          <div
            role="presentation"
            className={`image-wrapper ${imageSelection && 'modal-open'}`}
            onClick={handleImageChange}
          >
            {event.imageKey === undefined ? (
              selectedDummy && selectedDummy[0].length === 0 ? (
                <img src="/jk-placeholder-image.jpg" alt="Invitation" />
              ) : (
                <img src={selectedDummy} alt="Invitation" />
              )
            ) : (
              <img src={selectedImage} alt="Invitation" />
            )}
          </div>
          <div>
            <Title
              onClick={handleTitleBackgroundChange}
              className={`image-wrapper ${titleBGSelection && 'modal-open'}`}
              backgroundColor={titleBackground}
              color={
                titleBackground === colors.primary ? colors.secondary : '#fff'
              }
            >
              <h2>Congrats, you’re invited to {event.eventData.title}</h2>
            </Title>
            <LetterTextContainer>
              <p>
                {event.eventData.invite_greeting === 'NoGreeting' ? (
                  ''
                ) : (
                  <>
                    Dear {event.eventData.invite_greeting},
                    <br />
                    <br />
                  </>
                )}
                {paragraphs.map((paragraph, index) => (
                  <p key={index}>
                    {paragraph.split('\n').map((line, lineIndex) => (
                      <React.Fragment key={lineIndex}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                ))}
                {/* <br /> */}
              </p>
            </LetterTextContainer>
            <ImagePicker className={imageSelection && 'visible'}>
              <p>Choose a picture:</p>
              {event.imageKey === undefined ? (
                <GridImage>
                  {backgroundDummy.map((image, index) => (
                    <ImageContainer
                      key={index}
                      onClick={() => handleSelectImage(image)}
                      className={selectedDummy === image ? 'selected' : ''}
                    >
                      <img src={image} alt="" />
                    </ImageContainer>
                  ))}
                  <UploadContainer>
                    <UploadButton
                      className="vvvvv"
                      iconColor={colors.primary}
                      backgroundColor="transparent"
                      size={58}
                      accept=".jpeg,.jpg,"
                      onChange={handleImageUpload}
                    />
                  </UploadContainer>
                </GridImage>
              ) : (
                <GridImage>
                  {backgroundImage.map((image, index) => (
                    <ImageContainer
                      key={index}
                      onClick={() => handleSelectImage(image)}
                      className={selectedImage === image ? 'selected' : ''}
                    >
                      <img src={image} alt="" />
                    </ImageContainer>
                  ))}
                  <UploadContainer>
                    <UploadButton
                      className="vvvvv"
                      iconColor={colors.primary}
                      backgroundColor="transparent"
                      size={58}
                      accept=".jpeg,.jpg,"
                      onChange={handleImageUpload}
                    />
                  </UploadContainer>
                </GridImage>
              )}
            </ImagePicker>
            <ColorPicker className={titleBGSelection && 'visible'}>
              <p>Pick a color</p>
              <div>
                <ColorButton
                  type="submit"
                  onClick={() => setTitleBackground(colors.pink)}
                  backgroundColor={colors.pink}
                />
                <ColorButton
                  type="submit"
                  onClick={() => setTitleBackground(colors.primary)}
                  backgroundColor={colors.primary}
                />
                <ColorButton
                  type="submit"
                  onClick={() => setTitleBackground(colors.secondary)}
                  backgroundColor={colors.secondary}
                />
                <ColorButton
                  type="submit"
                  onClick={() => setTitleBackground(colors.orange)}
                  backgroundColor={colors.orange}
                />
                <ColorButton
                  type="submit"
                  onClick={() => setTitleBackground(colors.purple)}
                  backgroundColor={colors.purple}
                />
              </div>
            </ColorPicker>
          </div>
        </div>
      </Container>
      <Modal
        className={modalVisible && 'visible'}
        onClick={() => handleModalVisible(false)}
      >
        <IoIosClose
          onClick={() => handleModalVisible(false)}
          color="#fff"
          size={50}
        />
      </Modal>
    </>
  );
}
