import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import history from '~/services/history';

import { Mobile } from '~/config/ReactResponsiveConfig';

// Action
import { updateProfileRequest } from '~/store/modules/user/userAction';

import SubHeader from '~/components/SubHeader';
import AvatarUpload from '~/components/AvatarUpload';
import Input from '~/components/Input';
import DatePicker from '~/components/DatePicker';
import Button from '~/components/Button';
import { uploadProfileImage } from '~/store/modules/user/userAction';

import {
  Container,
  InputRow,
  InputWrapper,
  RadioControl,
  RadioButton,
} from './styles';

export default function AddIndividual() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const profile = useSelector((state) => state.userReducer.profile);
  const [gender, setGender] = useState(profile.gender);
  const [birthDate, setBirthDate] = useState(
    profile.dob ? profile.dob : new Date(),
  );
  const [city, setCity] = useState(profile.city);
  const [firstName, setFirstName] = useState(profile.first_name);
  const [surname, setSurname] = useState(profile.last_name);
  const isUserUpdated = useSelector((state) => state.giftReducer.isUserUpdated);
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleDateChange = (newDate) => {
    setBirthDate(newDate);
  };

  const handleUploadImage = (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    Dispatch(uploadProfileImage(profile.id, formData));
  };

  const handleSubmit = () => {
    const data = {
      first_name: firstName,
      last_name: surname,
      dob: birthDate,
      city,
      gender,
    };
    Dispatch(updateProfileRequest(profile.id, data));
  };
  if (isUserUpdated) {
    history.push({
      pathname: '/success',
      state: {
        destination: `/my-profile`,
        message: 'Profile Updated!',
        colors: { start: colors.lightPurple, end: colors.purple },
      },
    });
  }

  return (
    <>
      <SubHeader
        buttonBackgroundColor="#01e3fa"
        buttonText="Save"
        buttonTextColor="#142484"
        buttonFunction={() => handleSubmit()}
      >
        <h2>Edit My Profile</h2>
        <h3>Edit your details and picture</h3>
      </SubHeader>
      <Container>
        <form>
          <AvatarUpload
            image={profile.user_image}
            gender={gender}
            onChange={handleUploadImage}
          />
          <InputRow>
            <InputWrapper>
              <Input
                label="First Name"
                name="first-name"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                label="Surname"
                name="surname"
                placeholder="Surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </InputWrapper>
          </InputRow>
          <InputRow>
            <InputWrapper>
              <DatePicker
                label="Date of Birth"
                name="birth-date"
                date={birthDate}
                onChange={handleDateChange}
                maxDate={new Date()}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                label="City Where You Live"
                name="city"
                placeholder="Mosman NSW 2088"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </InputWrapper>
          </InputRow>
          <RadioControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={gender}
              onChange={handleGenderChange}
            >
              <FormControlLabel
                value="F"
                control={<RadioButton />}
                label="Female"
              />
              <FormControlLabel
                value="M"
                control={<RadioButton />}
                label="Male"
              />
              <FormControlLabel
                value="O"
                control={<RadioButton />}
                label="Other"
              />
            </RadioGroup>
          </RadioControl>
        </form>
        <Mobile>
          <Button
            text="Save"
            backgroundColor="#01e3fa"
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
    </>
  );
}
