import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import Context from '~/context';

import { Container } from './styles';
import { giftFundYourself } from '~/api/userAPI';

export default function FullyFunded({ data }) {
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);

  const handleFullyFunded = async () => {
    handleShowModal();
    try {
      await giftFundYourself(data)
        .then((res) => {
          history.push({
            pathname: '/success',
            state: {
              destination: `/gift-details/${data.gift_id}`,
              message: 'Gift Fully Funded!',
              colors: { start: colors.primary, end: colors.secondary },
              state: {
                funded: true,
              },
            },
          });
        })
        .catch((err) => {
          console.log('err', err);
        });
    } catch (error) {
      console.log('err', error);
    }
  };

  return (
    <Container>
      <h3>
        By pressing ‘COMPLETE FUNDING’ your invitees will no longer be able to
        contribute to this gift.
      </h3>
      <p>
        If the gift is not fully funded, you agree to fund the remaining value
        yourself.
      </p>
      <p>
        Your credit card will not be charged but you are expected to honour your
        word and make this gift wish a reality.
      </p>
      <p>
        Once you press CONFIRM, you will be able to send a thank you card to all
        past contributors. Yeah!
      </p>

      <button type="submit" onClick={handleShowModal}>
        Cancel
      </button>
      <button type="submit" className="blue" onClick={handleFullyFunded}>
        Confirm
      </button>
    </Container>
  );
}
