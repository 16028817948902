import person1 from '~/assets/images/person1.jpg';
import person2 from '~/assets/images/person2.jpg';

const guests = [
  {
    id: 1,
    name: 'Brandon Marshall',
    avatar: person1,
    rsvp: true,
    gift: {
      id: 1,
      title: 'Mountain Bike',
      contributed: 50,
      receiver: 'Eugene',
      price: 250,
    },
  },
  {
    id: 2,
    name: 'Johnny Cash',
    avatar: person2,
    rsvp: false,
    gift: {
      id: 1,
      title: 'Mountain Bike',
      contributed: 50,
      receiver: 'Eugene',
      price: 250,
    },
  },
];

export default guests;
