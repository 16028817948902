import React, { useState } from 'react';

import history from '~/services/history';
import HideFooter from '~/utils/HideFooter';

import { OuterContainer, Container, SuccessCheckSVG } from './styles';

export default function OnBoarding() {
  const [hasBankLinked] = useState(false);

  setTimeout(
    () =>
      history.push({
        pathname: hasBankLinked ? '/my-profile' : '/final-step',
        state: { lastPage: 'sent' },
      }),
    3000,
  );

  return (
    <>
      <HideFooter />
      <OuterContainer>
        <Container>
          <div>
            <SuccessCheckSVG />
            <h1>Email Sent!</h1>
          </div>
        </Container>
      </OuterContainer>
    </>
  );
}
