import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { convertUTCDateToString } from '~/utils/utility';

import { Mobile } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';
// Action
import { updateGift, uploadImageAction } from '~/store/modules/gift/giftAction';
import { IoIosClose } from 'react-icons/io';

import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';
import UploadButton from '~/components/UploadButton';

import {
  Container,
  InputWrapper,
  InputColumn,
  CheckboxControl,
  ImageDiv,
  DeleteIcon,
  InputValidation,
  DollerSign,
} from './styles';
import DatePicker from '~/components/DatePicker';

export default function EditGift() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const [checkValue, setCheckValue] = useState(true);
  const { giftId } = useParams();
  const currentGift = useSelector(
    (state) => state.receiverReducer.currentReceiverGift,
  );
  const receiver = useSelector((state) => state.receiverReducer);

  const [formError, setFormError] = useState(null);
  const isGiftUpdated = useSelector((state) => state.giftReducer.isGiftUpdated);
  const [giftName, setGiftName] = useState(currentGift ? currentGift.name : '');
  const [giftPrice, setGiftPrice] = useState(
    currentGift ? currentGift.price : '',
  );
  const [giftDesc, setGiftDesc] = useState(currentGift ? currentGift.desc : '');
  const [giftDate, setGiftDate] = useState(currentGift ? currentGift.date : '');
  const [giftImageFile, setGiftImageFile] = useState(null);
  const [image, setImage] = useState(
    currentGift ? currentGift.gift_images : '',
  );
  const [file, setFile] = useState();

  const handleDateChange = (newDate) => {
    setGiftDate(newDate);
  };

  const handleChange = () => {
    setCheckValue(!checkValue);
  };

  const validateForm = () => {
    if (giftName === '') {
      setFormError(`validation failed: gift name required!`);
      return 'validationFailed';
    }
    if (giftPrice === '') {
      setFormError(`validation failed: gift cost required!`);
      return 'validationFailed';
    }
    if (giftDesc === '') {
      setFormError(`validation failed: description required!`);
      return 'validationFailed';
    }
  };

  const handleSubmit = () => {
    const data = {
      title: giftName,
      description: giftDesc,
      cost: giftPrice,
      timeframe: giftDate,
    };
    const error = validateForm();
    if (error !== 'validationFailed') {
      Dispatch(updateGift(giftId, data));

      // updating image
      const formData = new FormData();
      if (giftImageFile) {
        formData.append('file', giftImageFile);
        Dispatch(uploadImageAction(currentGift.id, formData));
      }
    }
  };

  if (isGiftUpdated) {
    history.push({
      pathname: '/success',
      state: {
        destination: `/receiver-profile/${receiver.id}`,
        message: 'Gift updated.',
        colors: { start: colors.lightPurple, end: colors.orange },
      },
    });
  }

  const handleChangeImage = () => {
    setGiftImageFile(null);
    setImage(null);
  };

  const handleGiftImage = async (event) => {
    setGiftImageFile(event.target.files[0]);
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.orange}
        buttonBackgroundColor="#01e3fa"
        buttonText="Save"
        buttonTextColor="#142484"
        buttonFunction={() => handleSubmit()}
        mobileDescVisible={false}
      >
        <h2>EDIT GIFT</h2>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        <form>
          <InputColumn>
            <InputWrapper>
              <Input
                name="name"
                label="Gift Name"
                value={giftName}
                onChange={(e) => setGiftName(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper className="cost-row">
              <div>
                <label htmlFor="gift-cost">Total Cost in AUD</label>
                {/* <DollerSign>$</DollerSign> */}
                <input
                  name="price"
                  type="number"
                  className="currency-input"
                  value={giftPrice}
                  onChange={(e) => setGiftPrice(e.target.value)}
                />
              </div>
              <div>
                <CheckboxControl
                  control={
                    <Checkbox
                      checked={checkValue}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Make cost of the gift
                visible to invitees."
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <DatePicker
                label="Gift Expected By"
                name="gift-expected"
                date={giftDate}
                onChange={handleDateChange}
                minDate={new Date()}
              />

              {/* <Input
                label="Gift Expected By"
                value={convertUTCDateToString(giftDate)}
              /> */}
            </InputWrapper>
          </InputColumn>
          <InputColumn>
            <InputWrapper>
              <label htmlFor="gift-description">Gift Description</label>
              <textarea
                rows={4}
                name="gift-description"
                value={giftDesc}
                onChange={(e) => setGiftDesc(e.target.value)}
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = giftDesc)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="gift">Gift Picture</label>
              <div>
                {image && image.length !== 0 ? (
                  <ImageDiv>
                    <img
                      src={image[0].url}
                      alt={'image'}
                      height="90px"
                      width="90px"
                    />
                    <DeleteIcon>
                      <IoIosClose onClick={handleChangeImage} />
                    </DeleteIcon>
                  </ImageDiv>
                ) : giftImageFile && giftImageFile !== null ? (
                  <ImageDiv>
                    <img src={file} alt={'image'} height="90px" width="90px" />
                    <DeleteIcon>
                      <IoIosClose onClick={handleChangeImage} />
                    </DeleteIcon>
                  </ImageDiv>
                ) : (
                  <span>
                    <UploadButton onChange={handleGiftImage} />
                  </span>
                )}
              </div>
            </InputWrapper>
          </InputColumn>
        </form>
        <Mobile>
          <Button text="Next" buttonFunction={() => handleSubmit()} />
        </Mobile>
      </Container>
    </>
  );
}
