import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { Link, useParams } from 'react-router-dom';

import history from '~/services/history';

import GiftIcon from '~/assets/images/notification-icon-9.svg';
import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import { Container } from './styles';

import receiver from './receiver';
import { getEventGiftById, getEventGiftByOneOrMoreId } from '~/api/eventAPI';
import CircularIndeterminate from '~/components/Loader';
import { getJWTToken } from '~/utils/utility';

export default function RSVPGiftWishCant() {
  const { giftId } = useParams();
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const user_id = queryParams.get('u');

  const eventName = user_id && user_id.split('?n=');

  const { colors } = useContext(ThemeContext);
  const { path } = useParams();
  const [loader, setLoader] = useState(false);
  const [gifts, setGifts] = useState('');
  const tokenData = JSON.parse(getJWTToken());

  const wontAttend = path === 'wont-attend';
  const getGiftS = async (giftId) => {
    setLoader(true);
    await getEventGiftById(giftId)
      .then((res) => {
        getEventGiftS(res);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getGiftS(giftId);
    localStorage.removeItem('rspv_data');
    localStorage.removeItem('event_name');
  }, [giftId]);

  const getEventGiftS = async (giftId) => {
    setLoader(true);
    const giftIdArray = giftId && giftId.split(',');
    if (giftIdArray.length === 1) {
      await getEventGiftByOneOrMoreId(giftIdArray[0])
        .then((res) => {
          setGifts(res);
          setLoader(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    } else if (giftIdArray.length > 1) {
      const giftIdsString = giftIdArray.join(',');
      await getEventGiftByOneOrMoreId(giftIdsString)
        .then((res) => {
          setGifts(res);
          setLoader(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    } else {
    }
  };

  const handleSubmit = () => {
    localStorage.setItem('rspv_data', 'party_with_venue_not_attend');
    eventName && localStorage.setItem('event_name', eventName[1]);
    history.push(`/contribution-one-gift/${giftId}}?u=${user_id}`);
  };

  return (
    <>
      <SubHeader
        mobileNavButtons={false}
        hideBackButton
        backgroundColor={colors.lightPurple}
      >
        <h2>Sorry to hear that</h2>
        <h3>
          Would you still like to contribute to one of{' '}
          {eventName && eventName[1]} wishes?
        </h3>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <p>
              If you’re thinking of getting him a gift please consider
              contributing towards one of the gifts below. He would love it.
            </p>
            <h2>{eventName && eventName[1]} Wishes</h2>
            {gifts &&
              gifts.map((gift) => (
                <div key={gift.id} className="gift-wish">
                  <img
                    src={
                      gift.gift_images !== null
                        ? gift.gift_images[0].url
                        : GiftIcon
                    }
                    alt="Gift"
                  />
                  <span>{gift.title}</span>
                </div>
              ))}

            <Button
              text="I WOULD LOVE TO CONTRIBUTE"
              backgroundColor="#01e3fa"
              buttonFunction={() => handleSubmit()}
            />
            {tokenData && tokenData.token !== null ? (
              <p>
                <Link to={`/rsvp-closed-confirmed/`}>No, thanks</Link>
              </p>
            ) : (
              <p>
                <Link
                  to={`/rsvp-cant/${giftId}?u=${user_id}?n=${eventName[1]}`}
                >
                  No, thanks
                </Link>
              </p>
            )}
          </>
        ) : (
          <CircularIndeterminate />
        )}
      </Container>
    </>
  );
}
