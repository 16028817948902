import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 20px 10px 10px;
  background: #fff;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccd7db;
  display: flex;
  max-width: 320px;
  width: 100%;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 10px;
  }

  .image-wrapper {
    display: flex;
    align-items: center;

    img {
      max-width: 50px;
      max-height: 50px;
      margin-right: 10px;
      width: 100%;
    }
  }

  .description {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    span {
      color: #222627;
      font-size: 18px;
      font-weight: bold;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    small {
      color: #7a858a;
    }
  }
`;
