import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { IoIosClose } from 'react-icons/io';

import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';

import { Container, Media, InputWrapper, MediaWrapper, Close } from './styles';

import mediaApi from './media';
import { useParams } from 'react-router-dom';
import { updateMemoriesName } from '~/api/eventAPI';
import history from '~/services/history';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMemoriesEvent } from '~/store/modules/event/eventAction';

export default function EditAlbum() {
  const { eventId, postId } = useParams();
  const { colors } = useContext(ThemeContext);

  const memories = useSelector((state) => state.eventReducer.memoriesData);
  const Dispatch = useDispatch();
  const receiver = useSelector((state) => state.receiverReducer);
  const albumMemoryName =
    memories &&
    memories.find((memory) => memory.event_id === parseInt(eventId));

  const matchingMemory =
    memories &&
    memories.filter((memory) => memory.event_id === parseInt(eventId));
  const [media, setMedia] = useState(matchingMemory && matchingMemory);
  const [albumName, setAlbumName] = useState(
    albumMemoryName
      ? albumMemoryName.album_name
          .toLowerCase()
          .replace(/(^|\s)\S/g, (match) => match.toUpperCase())
      : '',
  );

  const handleSubmit = async () => {
    const data = {
      event_id: eventId,
      album_name: albumName,
    };
    await updateMemoriesName(data)
      .then((res) => {
        localStorage.setItem('updateMemories', 'updatedMemories');
        history.push({
          pathname: '/success',
          state: {
            destination: `/receiver-profile/${receiver.id}/memories`,
            message: 'Album updated.',
            colors: { start: colors.lightPurple, end: colors.orange },
          },
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleDeleteMedia = (id, data) => {
    const payload = {
      id: parseInt(id),
      child_id: data.child_id,
    };
    Dispatch(deleteMemoriesEvent(payload));
    setMedia(media.filter((item) => item.id !== id));
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.secondary}
        buttonBackgroundColor="#8991C1"
        buttonTextColor="#fff"
        buttonFunction={() => handleSubmit()}
        mobileDescVisible={false}
        buttonText="Save"
      >
        <h2>Edit Album</h2>
      </SubHeader>

      <Container>
        <InputWrapper>
          <Input
            label="Album Name"
            placeholder="Graduation Album"
            value={albumName}
            onChange={(e) => setAlbumName(e.target.value)}
          />
        </InputWrapper>
        <MediaWrapper>
          {media &&
            media.map((item) => (
              <Media key={item.id} image={item.url}>
                <Close onClick={() => handleDeleteMedia(item.id, item)}>
                  <IoIosClose size={30} color="#fff" />
                </Close>
              </Media>
            ))}
        </MediaWrapper>
      </Container>
    </>
  );
}
