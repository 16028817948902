import React from 'react';

import { Container } from './styles';
import CircularIndeterminate from '~/components/Loader';

export default function SubHeaderButtonWithLoader({
  text,
  backgroundColor,
  textColor,
  disableBoolean,
}) {
  return (
    <>
      {text && (
        <Container
          backgroundColor={backgroundColor}
          textColor={textColor}
          disabled={disableBoolean}
        >
          <span>{text}</span>
          <CircularIndeterminate colorName='inherit'/>
        </Container>
      )}

    </>
  );
}
