import styled from 'styled-components';
import { lighten } from 'polished';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }

  form {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      grid-template-columns: auto;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  > div {
    display: flex;

    &.contribution-options {
      flex-direction: column;
    }

    .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
      text-transform: none;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
    }

    .contribution-params {
      margin-left: 28px;

      label {
        margin-bottom: 5px;
      }

      .MuiFormGroup-root {
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          input {
            max-width: 100px;
            border: 1px solid ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
    align-items: flex-start;

    > span:first-child {
      margin-top: -10px;
    }

    & + .info-button {
      margin-left: 10px;
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 50%;
      width: 18px;
      height: 18px;
      display: inline-block;
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }

  input,
  textarea {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }

  textarea {
    min-height: 200px;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 767px) {
      min-height: 150px;
    }
  }

  p {
    color: #888;
    font-size: 16px;

    &:first-of-type {
      margin: 20px 0;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 100%;
  }
`;

export const RadioControl = styled(FormControl)`
  max-width: 330px;
  width: 100%;

  .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const RadioButton = styled(Radio)`
  &.MuiRadio-root {
    &.Mui-checked {
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
      }
    }

    &:hover {
      background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
    }
  }
`;
export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;