import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';

import history from '~/services/history';
import HideFooter from '~/utils/HideFooter';

import Button from '~/components/Button';

import { OuterContainer, Container, SuccessCheckSVG } from './styles';

export default function LaterScreen() {
  const theme = useContext(ThemeContext);
  const colors = { start: theme.colors.lightPurple, end: theme.colors.pink };
  const receiver = useSelector((state) => state.receiverReducer);

  return (
    <OuterContainer>
      <HideFooter />
      <Container colors={colors}>
        <div>
          <SuccessCheckSVG />
          <h1>Nice Work!</h1>
          <h3>
            {receiver.first_name} gifts are saved and waiting for you to add
            them to an event and invite friends
          </h3>
        </div>
        <div className="button-wrapper">
          <Button
            backgroundColor="#ffffff70"
            textColor="#fff"
            text="Got it, Thanks!"
            buttonFunction={() =>
              history.push(`/receiver-profile/${receiver.id}/events`)
            }
          />
        </div>
      </Container>
    </OuterContainer>
  );
}
