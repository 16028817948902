import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import history from '~/services/history';
import Context from '~/context';

import HideFooter from '~/utils/HideFooter';

import Button from '~/components/Button';

import { ReactComponent as OnboardImage } from '~/assets/images/onboarding.svg';

import { OuterContainer, Container } from './styles';

export default function OnBoarding() {
  const { colors } = useContext(ThemeContext);
  const { setFlow } = useContext(Context);
  const location = useLocation();
  const fromNotification = location.state
    ? location.state.fromNotification
    : false;

  const handleStart = () => {
    history.push('/add-receiver');
    setFlow({ onboarding: true });
  };
  const handleLinkClick = () => {
    localStorage.setItem('onbording', 'later');
  };

  return (
    <OuterContainer>
      <HideFooter />
      <Container>
        <div>
          <h1>
            {fromNotification
              ? 'Are you ready to fund a gift for someone you care about?'
              : 'Let’s get started'}
          </h1>
          <h3>
            In 3 easy steps, you will be ready to send invitesto friends and
            family and fund gift(s).
          </h3>
          <OnboardImage alt="On Boarding" />
          <Button
            text="Start"
            backgroundColor={colors.purple}
            textColor="#ffffff"
            buttonFunction={() => handleStart()}
          />
          <Link to="/my-profile" onClick={handleLinkClick}>Later</Link>
        </div>
      </Container>
    </OuterContainer>
  );
}
