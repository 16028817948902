import moment from 'moment';
import dayjs from 'dayjs';

const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/;
const dateTimeFormat =
  /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}[\s](?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;
const emailFormat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const dateAndTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const emailErrorMsg = 'Email address is not valid';

export const responseHandler = (response) => {
  switch (response.status) {
    case 201: // create
    case 200: // get
      return Promise.resolve(response.data || { message: 'Success' });
    case 204: // no contents
      return Promise.reject({ response });
    default:
      return Promise.resolve({ message: 'Success' });
  }
};

export const errorHandler = (error) => {
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 404: // not found
        return Promise.reject({
          message: error.response.data.message || 'Page Not Found',
        });
      case 412:
      case 500:
        return Promise.reject({
          message:
            `${error.response.data.errorCode} : ${
              error.response.data.message
                ? error.response.data.message
                : error.response.data.errorDetail
            }` || 'Pre condition failed.',
        });
      case 422:
        return Promise.reject({
          message:
            `${error.response.data.message}: ${error.response.data.details.body[0].message}` ||
            'Invalid request body',
        });
      case 400:
        return Promise.reject({
          message:
            `${error.response.data.errorCode} : ${
              error.response.data.message
                ? error.response.data.message
                : error.response.data.errorDetail
            }` || 'Pre condition failed.',
        });
      case 409:
        return Promise.reject({
          message: error.response.data.message || 'Invalid request body',
        });
      case 204:
        return Promise.reject({ message: 'No contents' });
      case 401:
        // signOut();
        return Promise.reject({ message: 'No contents' });
      default:
        return Promise.reject({ message: 'Invalid request body' });
    }
  } else {
    return Promise.reject({ message: 'Network error' });
  }
};

export function getJWTToken() {
  try {
    const localStorage = JSON.parse(
      window.localStorage.getItem('persist:purposit'),
    );
    const authToken = localStorage.authReducer || null;
    return authToken;
  } catch (err) {
    return null;
  }
}

export function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      if (typeof arg === 'function') {
        return arg(theme);
      }

      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

export function validateAUMobile(mobile) {
  const reg =
    /^(\+\d{2}[ ]{0,1}){0,1}(((\({0,1}[ ]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ ]*(\d{4}[ ]{0,1}\d{4}))|(1[ ]{0,1}(300|800|900|902)[ ]{0,1}((\d{6})|(\d{3}[ ]{0,1}\d{3})))|(13[ ]{0,1}([\d ]{5})|((\({0,1}[ ]{0,1})0{0,1}\){0,1}4{1}[\d ]{8,10})))$|^$/;

  return reg.test(String(mobile));
}

export function validateEmail(email) {
  const reg =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return reg.test(String(email).toLowerCase());
}

export function convertNumberToUnit(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? `${Number((Math.abs(Number(labelValue)) / 1.0e9).toFixed(2))}B`
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? `${Number((Math.abs(Number(labelValue)) / 1.0e6).toFixed(2))}M`
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? `${Number((Math.abs(Number(labelValue)) / 1.0e3).toFixed(2))}K`
    : Math.abs(Number(labelValue));
}

export const convertDateFormat = (dateStr) => {
  if (dateStr) {
    if (dateformat.test(dateStr)) {
      const dt = moment(dateStr, 'DD-MM-YYYY');
      return dt.format('YYYY-MM-DD');
    }
    return dateStr;
  }
  return null;
};

export const convertDateTimeFormat = (dateStr) => {
  if (dateStr) {
    if (dateTimeFormat.test(dateStr)) {
      const dt = moment(dateStr, 'DD-MM-YYYY HH:mm:ss');
      return dt.format('YYYY-MM-DD HH:mm:ss');
    }
    return dateStr;
  }
  return null;
};

export const convertUTCDateFormat = (dateStr, format = 'YYYY-MM-DD') => {
  if (dateStr) {
    return moment.utc(dateStr).format(format);
  }
  return null;
};

export const convertUTCDateToString = (dateStr, format = 'DD MMMM YYYY') => {
  if (dateStr) {
    return dayjs(dateStr).format(format);
  }
  return null;
};

export const isValidDate = (str) => {
  const d = new Date(convertUTCDateFormat(str));

  if (Date.parse(d)) {
    return true;
  }
  return false;
};

export const checkIfCommaDelimited = (strValue) => {
  // Determine the following
  // The string contains commas
  // Fixup string (strip any trailing or leading commas)
  if (strValue.trim().length === 0) return false;

  strValue = strValue.replace(/(^,)|(,$)/g, '');
  // Store original pairs
  const pairs = strValue.split(',');
  const arrmap = pairs.map((element) => element.trim());
  const newArr = arrmap.filter((entry) => entry.indexOf(' ') > 0);
  if (newArr.length > 0) return false;
  // Get all of the valid pairs
  const validPairs = arrmap.filter((p) => p.length !== 0);
  // Determine if all pairs are valid
  return pairs.length === validPairs.length;
};

/**
 * This method return true if email is valid, else return false
 * @param {*} email
 * @returns
 */
export const emailValidator = (email = '') =>
  !!String(email).toLowerCase().match(emailFormat);

export const getHeaderWithToken = () => {
  const localStorage = JSON.parse(
    window.localStorage.getItem('persist:purposit'),
  );
  const authToken = localStorage.authReducer;
  const token = JSON.parse(authToken);
  const headers = {};
  if (token && token.token) {
    headers.Authorization = `Bearer ${token.token}`;
  }
  return headers;
};

export const readFile = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result.split(/base64,/)[1]);
    };
    fileReader.onerror = () => {
      reject(fileReader.error);
    };
    fileReader.readAsDataURL(file);
  });

export const convertToBlob = (fileName) => {
  const splitDataURI = fileName;
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: 'image/jpeg' });
};
