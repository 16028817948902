import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import history from '~/services/history';
import EmptyList from '../EmptyList';

import { Container, Memory, MemoryThumbnail } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMemoryById } from '~/store/modules/event/eventAction';
import moment from 'moment';

export default function Memories() {
  const { userId } = useParams();
  const receiverId = useSelector((state) => state.receiverReducer);
  const Dispatch = useDispatch();
  const eventMemories = useSelector((state) => state.eventReducer.memoriesData);
  const updateMemories = localStorage.getItem('updateMemories');
  const handleAddMemory = () => {};

  useEffect(() => {
    if (updateMemories && updateMemories !== null) {
      handleMemoryData(receiverId.id || receiverId.receiver.id);
    } else {
    }
  }, [receiverId.id]);

  const handleMemoryData = (id) => {
    const child_id = parseInt(id);
    if (child_id) {
      Dispatch(fetchMemoryById(child_id));
      localStorage.removeItem('updateMemories');
    } else {
    }
  };

  const groupedMemories =
    eventMemories &&
    eventMemories.reduce((acc, memory) => {
      const eventId = memory.event_id;
      if (
        !acc[eventId] ||
        new Date(memory.created_at) < new Date(acc[eventId].created_at)
      ) {
        acc[eventId] = memory;
      }
      return acc;
    }, {});

  const filteredMemories = groupedMemories && Object.values(groupedMemories);

  return (
    <>
      {filteredMemories && filteredMemories.length > 0 ? (
        <>
          <Container>
            {filteredMemories &&
              filteredMemories.map((memory) => (
                <Memory key={memory.id}>
                  <MemoryThumbnail
                    image={memory.url}
                    onClick={() =>
                      history.push({
                        pathname: `/event-album/${memory.event_id}`,
                        state: { userId },
                      })
                    }
                  />
                  <span>
                    {memory.album_name ? memory.album_name : 'No Album Name'}
                  </span>
                  <small>
                    {memory && moment(memory.created_at).format('DD MMM YYYY')}
                  </small>
                </Memory>
              ))}
          </Container>
        </>
      ) : (
        <EmptyList>
          This is where you create a photo album for this event that will be
          shared with your invitees!
        </EmptyList>
      )}
    </>
  );
}
