import React, { useEffect, useState } from 'react';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
// import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { toast } from 'react-toastify';

import { SocialLoginWrapper, SocialLoginButton } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  googlesignInRequest,
  signUpRequest,
  checkedUserRequest,
} from '~/store/modules/auth/authAction';
import {
  fetchUserByEmail,
  fetchUserByIdDone,
} from '~/store/modules/user/userAction';
import history from '~/services/history';
import { getuserChecked } from '~/api/authAPI';
import Button from '../Button';

export default function SocialLogin() {
  const dispatch = useDispatch();

  const {
    loading,
    token,
    signed,
    email: LoginEmail,
  } = useSelector((state) => state.authReducer);
  const profile = useSelector((state) => state.userReducer.profile);
  const [isFetchByEmail, setIsFetchByEmail] = useState(false);
  const checkUser = useSelector((state) => state.authReducer);

  // const responseFacebook = (response) => {
  // };
  const responseGoogle = async (response) => {
    let userData = response.profileObj;
    if (userData && userData.email) {
      await getuserChecked(userData.email)
        .then((res) => {
          if (res && res.data) {
            toast.success('Signing success');
            dispatch(
              googlesignInRequest(userData.email, 'V1a2r3u4n', 'google'),
            );
          } else if (res.response) {
            dispatch(
              signUpRequest(
                userData.name,
                userData.email,
                'V1a2r3u4n',
                'google',
              ),
            );
            setTimeout(() => {
              dispatch(
                googlesignInRequest(userData.email, 'V1a2r3u4n', 'google'),
              );
            }, 1000);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };
  useEffect(() => {
    if (token && signed && !isFetchByEmail) {
      dispatch(fetchUserByEmail(LoginEmail));
      setIsFetchByEmail(true);
    }
  });

  useEffect(() => {
    if (profile && isFetchByEmail) {
      history.push('/welcome');
    }
  });

  return (
    <SocialLoginWrapper>
      <p>OR SIGN WITH A SOCIAL ACCOUNT</p>
      <div>
        <SocialLoginButton>
          <FaFacebook size={25} />
          <span>Facebook</span>
          {/* <FacebookLogin
            appId="233276146286655" //APP ID NOT CREATED YET
            fields="name,picture"
            callback={responseFacebook}
          /> */}
        </SocialLoginButton>
        {/* <SocialLoginButton>
          <FaGoogle size={25} />
          <span>Google</span>
        </SocialLoginButton> */}
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Google Login"
          render={(renderProps) => (
            <SocialLoginButton
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <FaGoogle size={25} />
              GOOGLE
            </SocialLoginButton>
          )}
          // isSignedIn={true}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </div>
      <p className="privacy">
        By signing up you agree to our
        <br /> <strong>Terms</strong> and <strong>Privacy</strong>.
      </p>
    </SocialLoginWrapper>
  );
}
