import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 720px;
  margin: 50px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 20px;
    padding-bottom: 40px;
    margin: 0;
    min-height: calc(100% - 135px);
  }

  .share-list {
    max-width: 100%;
    max-height: 60px;
    margin-top: 10px;
  }

  > div:last-of-type {
    width: 100%;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    max-width: initial;
    flex-direction: column;
    margin-top: -80px;
    margin-bottom: 10px;
  }

  &:last-child {
    margin-top: -37px;
  }

  > button:last-of-type {
    max-width: 90%;
    margin-top: 40px;
  }
`;

export const Contributions = styled.div`
  margin: 10px 0 0;
  display: flex;
  flex: 2;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: row-reverse;
    margin: 20px auto;
    align-items: center;
    max-width: 90%;
  }

  .contributors {
    display: flex;
    flex: 1;
    align-items: center;

    .avatar-row {
      display: flex;

      .image-wrapper {
        width: 20px;
      }

      img {
        margin: 0;
        border: 2px solid white;
      }
    }
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 16px;
    margin-left: 30px;

    &.raised {
      color: #888;
      font-size: 18px;
      margin: 0;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  height: 230px;
`;

export const GiftWishesButton = styled.button`
  min-width: 90%;
  background-color: #fff;
  border: 0;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  max-height: 74px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${darken(0.05, '#fff')};
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }

  h2 {
    color: #333;
  }

  p {
    color: #666;
  }

  svg {
    max-width: 40px;
    max-height: 42px;
    margin-right: 10px;

    #box {
      fill: #999;
    }

    #lace {
      stroke: #ed1e79;
    }
  }
`;

export const OtherButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  flex: 1;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 10px;
  }

  > button {
    padding: 10px;
    width: 100%;
    max-width: 309px;
    border: 0;
    margin-bottom: 5px;
  }

  @media (min-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    > button {
      max-width: 49%;
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }
`;

export const GiftWishesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0px 20px;
  width: 100%;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    grid-template-columns: auto;
  }
`;
