import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 100%;

  @media (max-width: 767px) {
    height: 70vh;
    margin-top: 20vh;
  }

  svg {
    max-width: 70px;
    margin-bottom: 30px;
  }

  h2 {
    max-width: 260px;
  }

  p {
    padding: 20px 0;
    font-size: 18px;
    line-height: 1.2;
    max-width: 280px;
  }
`;

export const OptionsWrapper = styled.div`
  flex: 1;
  height: 100%;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
