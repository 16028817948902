import styled from 'styled-components';

import { ReactComponent as OpenBoxSVG } from '~/assets/images/open-gift-2.svg';

export const OuterContainer = styled.div`
  background-color: #f6f6f6;
  min-height: calc(100% - 90px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  max-width: 720px;
  min-height: calc(100vh - 200px);
  margin: 40px auto;
  width: 90%;
  background: ${({ colors }) =>
    `linear-gradient(0deg, ${colors.end || '#ed1e79'}, ${
      colors.start || '#93278f'
    })`};
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    height: 100%;
  }

  div {
    max-width: 370px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 130px;
    }
  }

  svg {
    margin-bottom: 30px;
  }

  h1 {
    margin-bottom: 20px;
  }

  button {
    margin-top: 40px;
  }
`;

export const OpenBox = styled(OpenBoxSVG)`
  max-height: 80px;

  #box-cover > g {
    animation: rotate 3s infinite;
    transform-origin: left bottom;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-4deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  #star-shine {
    animation: fadeInOut 3s infinite;
  }

  @keyframes fadeInOut {
    0% {
      transform: translate(100px, 30px);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(100px, 0px);
    }
  }

  #star-1 {
    animation: star-1 3s infinite;
  }

  @keyframes star-1 {
    0% {
      transform: translate(22px, 30px);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(22px, 0px);
    }
  }

  #star-2 {
    animation: star-2 3s infinite;
  }

  @keyframes star-2 {
    0% {
      transform: translate(22px, 30px);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(46.84, 0px);
    }
  }

  #star-3 {
    animation: star-3 3s infinite;
    animation-delay: 1s;
  }

  @keyframes star-3 {
    0% {
      transform: translate(22px, 30px);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(0, 0px);
    }
  }

  #path-1 {
    animation: path-1 3s infinite;
    animation-delay: 3s;
  }

  @keyframes path-1 {
    0% {
      transform: translate(22px, 30px);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(43px, 19px);
    }
  }

  #path-2 {
    animation: path-2 3s infinite;
    animation-delay: 3s;
  }

  @keyframes path-2 {
    0% {
      transform: translate(22px, 30px);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(11px, 20px);
    }
  }

  #path-3 {
    animation: path-3 3s infinite;
    animation-delay: 3s;
  }

  @keyframes path-3 {
    0% {
      transform: translate(22px, 30px);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(0px, 39px);
    }
  }
`;
