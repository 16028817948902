import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import Context from '~/context';
import history from '~/services/history';
import { Mobile, Default } from '~/config/ReactResponsiveConfig';

import LaterScreen from './LaterScreen';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import { ReactComponent as Gifts } from '~/assets/images/gifts.svg';
import { ReactComponent as GiftIcon } from '~/assets/images/gift-icon.svg';

import { OuterContainer, Container } from './styles';

export default function OnBoarding() {
  const { flow, setFlow } = useContext(Context);
  const [showLater, setShowLater] = useState(false);
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const { first_name, type, group_name, id } = receiver && receiver;
  const handleStart = () => {
    setTimeout(() => {
      history.push('/add-gift');
    }, 1000);
  };

  const handleLater = () => {
    if (flow.onboarding) {
      setShowLater(true);
      setFlow(null);
    } else {
      history.push(`/receiver-profile/${id}`);
    }
  };

  return (
    <>
      {showLater ? (
        <LaterScreen />
      ) : (
        <>
          <Mobile>
            <SubHeader
              buttonText="Later"
              buttonFunction={() => history.push(`/receiver-profile/${id}`)}
              hideBackButton
              mobileDescVisible={false}
              backgroundColor="#f15a24"
            >
              <h2>Add Gift</h2>
            </SubHeader>
          </Mobile>
          <OuterContainer>
            <Container>
              {flow.giftOption !== 'one-gift' ? (
                <div>
                  <Gifts alt="Gifts" />
                  <h1>
                    Let’s create a list of special gifts&nbsp;for&nbsp;
                    {receiver && type === 'individual'
                      ?first_name&& first_name
                      : group_name && group_name}
                  </h1>
                  <h2>
                    A gift can be aything you believe{' '}
                    {receiver && type === 'individual'
                      ? first_name && first_name
                      :group_name && group_name}{' '}
                    will love. A material item, an activity, an experience,
                    travel or even&nbsp;education.
                  </h2>
                  <Button
                    text="Add First Gift"
                    backgroundColor="#93278F"
                    textColor="#ffffff"
                    buttonFunction={handleStart}
                  />
                  <Default>
                    <div onClick={handleLater}>
                      <span className="link">Later</span>
                    </div>
                  </Default>
                </div>
              ) : (
                <div>
                  <GiftIcon alt="Gift" className="gift" />
                  <h1>
                    Let’s create a specialgift&nbsp;for&nbsp;
                    {receiver ? first_name : ''}
                  </h1>
                  <h2>
                    A gift can be aything you believe{' '}
                    {receiver ? first_name : ''} will love. A material item, an
                    activity, an experience, travel or even&nbsp;education.
                  </h2>
                  <Button
                    text="Add Gift"
                    backgroundColor="#93278F"
                    textColor="#ffffff"
                    buttonFunction={handleStart}
                  />
                  <Default>
                    <div onClick={handleLater}>
                      <span className="link">Later</span>
                    </div>
                  </Default>
                </div>
              )}
            </Container>
          </OuterContainer>
        </>
      )}
    </>
  );
}
