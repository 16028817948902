import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import Button from '~/components/Button';
import { OuterContainer, Container, Balloons } from './styles';
import { getLogin, getSignUp } from '~/api/authAPI';
import { fetchUserByEmail } from '~/store/modules/user/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { signInSuccess } from '~/store/modules/auth/authAction';
import { sendPasswordMessage } from '~/api/userAPI';
import { getJWTToken } from '~/utils/utility';
import FollowEvent from '../FollowEvent';

export default function RSVPConfirmed() {
  const { colors } = useContext(ThemeContext);
  const { path } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = location.state && location.state;
  const wontAttend = path === 'wont-attend';
  const [isFetchByEmail, setIsFetchByEmail] = useState(false);
  const [loader, setLoader] = useState(false);
  const tokenData = JSON.parse(getJWTToken());
  const getCheck = localStorage.getItem('rspv_data');

  const userRequestCheck = (email, password) => {
    const payload = {
      email: email,
      password: password,
      signupType: 'NULL',
    };

    setTimeout(async () => {
      await getLogin(payload)
        .then((res) => {
          dispatch(signInSuccess(res.data.token));
          if (res.data.token) {
            setTimeout(() => {
              history.push('/welcome');
            }, 4000);
          }
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    }, 2000);
    setTimeout(() => {
      dispatch(fetchUserByEmail(email));
    }, 4000);
  };

  const handleSubmit = async () => {
    if (userData.checked === 'true') {
      localStorage.removeItem('rspv_data');
      localStorage.removeItem('event_name');
      history.push('/');
    } else {
      localStorage.removeItem('rspv_data');
      localStorage.removeItem('event_name');
      history.push('/register', {
        name: userData.name,
        email: userData.email,
      });
    }
  };

  const handleClose = () => {
    localStorage.removeItem('rspv_data');
    localStorage.removeItem('event_name');
    history.push('/my-profile');
  };

  return getCheck &&
    getCheck === 'party_with_venue_novenue' &&
      tokenData.token === null ? (
    <FollowEvent userData={userData} />
  ) : (
    <OuterContainer>
      <Container colors={{ start: colors.purple, end: colors.pink }}>
        <div>
          <Balloons />
          <h1>
            {wontAttend
              ? 'We hope to see you soon!.'
              : getCheck &&
                getCheck === 'party_with_venue_not_attend' &&
                tokenData &&
                tokenData.token !== null
              ? 'Thanks again and hope to see you next time!'
              : getCheck &&
                getCheck === 'party_with_venue_novenue' &&
                tokenData &&
                tokenData.token !== null
              ? 'Thanks again and hope to see you soon!'
              : 'Fantastic, we cant wait to see\xa0you\xa0at the event!.'}
          </h1>
          {tokenData && tokenData.token !== null ? (
            <h3>
              Meanwhile, you can see this event on Purposit and check how
              it’s&nbsp; progressing.
            </h3>
          ) : (
            <h3>
              If you would like to follow this event, see photos and/or make a
              contribution, sign up to Purposit. It’s&nbsp;free.
            </h3>
          )}
          {tokenData && tokenData.token ? (
            <Button
              text="Close"
              textColor="#fff"
              buttonFunction={handleClose}
              backgroundColor={colors.plum}
            />
          ) : (
            <>
              {!loader ? (
                <Button
                  text="Sign Up / Login"
                  textColor="#fff"
                  buttonFunction={handleSubmit}
                  backgroundColor={colors.plum}
                />
              ) : (
                <Button
                  text="Please wait..."
                  textColor="#fff"
                  buttonFunction={handleSubmit}
                  backgroundColor={colors.plum}
                />
              )}
            </>
          )}
        </div>
      </Container>
    </OuterContainer>
  );
}
