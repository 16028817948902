import person1 from '~/assets/images/person1.jpg';
import person2 from '~/assets/images/person2.jpg';
import person3 from '~/assets/images/person3.jpg';
import person4 from '~/assets/images/person4.jpg';

const contactsList = [
  {
    id: 1,
    name: 'Marty Sawyer',
    email: 'my@email.com',
    used: true,
    avatar: person1,
  },
  {
    id: 2,
    name: 'Marty Sawyer',
    email: 'my@email.com',
    used: false,
    avatar: person2,
  },
  {
    id: 3,
    name: 'Sabrina Yael',
    email: 'my@email.com',
    used: true,
    avatar: person3,
  },
  {
    id: 4,
    name: 'Lorraine Agoras',
    email: 'my@email.com',
    used: false,
    avatar: person4,
  },
];

export default contactsList;
