import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FabIndicator from '~/assets/images/fab-indicator.svg';

export const Container = styled.div`
  max-width: 720px;
  margin: 0px auto 40px;
  width: 90%;
  background-color: #f6f6f6;
  padding: 20px;
  position: relative;

  @media (max-width: 767px) {
    margin: 0;
    padding: 0 20px 40px;
    background: white;
    width: 100%;
    flex: 1;

    > div {
      padding: 20px 0;
    }
  }

  .MuiFab-primary {
    right: calc(50% - 430px);
    bottom: 80px;
    position: fixed;

    @media (max-width: 900px) {
      right: 20px;
      position: fixed;
    }

    &.show-indicator {
      position: absolute;
      right: 20px;

      @media (max-width: 767px) {
        right: 20px;
        bottom: -80px;
        position: absolute;
        margin-bottom: 20px;
      }

      &:before {
        content: '';
        display: block;
        width: 280px;
        height: 200px;
        background-image: url(${FabIndicator});
        position: absolute;
        right: 80px;
        bottom: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;

        @media (max-width: 767px) {
          width: 130px;
          background-size: cover;
          height: 120px;
          bottom: 20px;
          right: 70px;
        }
      }
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  max-width: 720px;
  margin: 0 auto;
  width: 90%;
  display: flex;

  .MuiTabs-indicator {
    top: 0;
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    width: 125px;
    height: 44px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin: 0 1px;
    cursor: pointer;
    background-color: #f1f1f1;
    opacity: 1;

    @media (max-width: 767px) {
      background-color: #f0f0f0;
      flex: 1;
    }

    .MuiTab-wrapper {
      color: #888;
    }

    &.MuiTab-textColorInherit.Mui-selected {
      background-color: #f6f6f6;

      @media (max-width: 767px) {
        background-color: #fff;
      }

      span {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;

export const UserInfo = styled.div`
  max-width: 720px;
  margin: 0px auto -20px;
  width: 90%;
  display: flex;

  > div:first-of-type {
    margin: 0;
    transform: translateY(-50%);
  }

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    margin: 0px auto -30px;
    transform: translateY(-60px);

    > div:first-of-type {
      margin: 0 auto;
      transform: translateY(0);
    }
  }

  h2 {
    color: #1a1a1a;
  }
`;

export const UserData = styled.div`
  margin-left: 40px;
  margin-top: 8px;

  > div {
    display: flex;
  }

  @media (max-width: 767px) {
    margin: 8px auto;
  }
`;

export const SubHeaderDesktop = styled.div`
  padding: 0 30px;
  height: 140px;
  width: 100%;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.purple}, ${theme.colors.pink})`};
`;

export const Modal = styled.div`
  width: 100%;
  height: calc(100% + 145px);
  background: #000000bf;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  &.visible {
    display: block;
  }

  svg {
    position: fixed;
    right: 5px;
    top: 2px;
    cursor: pointer;
  }
`;

export const LoaderDiv = styled.div`
  margin-top: 25px;
  align-items: center;
  margin-left: 50%;
`;

export const LoadingText = styled.div`
  color: #13287f;
  margin-bottom: 25px;
`;