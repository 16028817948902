import React, { useContext, useEffect } from 'react';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';

import { ReactComponent as PartyInvite } from '~/assets/images/envelope-icon.svg';
import { ReactComponent as WebsiteIcon } from '~/assets/images/website-icon.svg';

import SubHeader from '~/components/SubHeader';

import { Container, BigButton } from './styles';
import { fetchAllEvent } from '~/store/modules/event/eventAction';
import { useDispatch, useSelector } from 'react-redux';

export default function EventType() {
  const { colors } = useContext(ThemeContext);
  const Dispatch = useDispatch();
  const receiverData = useSelector((state) => state.receiverReducer.receiver);

  useEffect(() => {
    Dispatch(fetchAllEvent(receiverData.id));
  }, [Dispatch]);

  const handleCheck = () => {
    if (receiverData.events.length === 0) {
      history.push('/gift-options');
    } else {
      history.push('/choose-event');
    }
  };
  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
      >
        <h2>Invite Options</h2>
        <h3>
          This gift has been added to 'live' events.
          What&nbsp;would&nbsp;you&nbsp;like&nbsp;to&nbsp;do?
        </h3>
      </SubHeader>
      <Container>
        <BigButton onClick={() => history.push('/gift-options')}>
          <div className="image-wrapper">
            <PartyInvite alt="Create Event" />
          </div>
          <div className="text-content">
            <h2>Create a new event for this gift</h2>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>

        <BigButton
          backgroundColor={
            receiverData && receiverData.events.length === 0
              ? '#b3b3b3'
              : colors.wine
          }
          onClick={handleCheck}
        >
          <div className="image-wrapper">
            <WebsiteIcon alt="Existing Event" />
          </div>
          <div className="text-content">
            <h2>Invite friends to an existing event</h2>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>
      </Container>
    </>
  );
}
