import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { IoMdHeartEmpty, IoMdHeart } from 'react-icons/io';
import LilGirl from '~/assets/images/lilgirl.jpg';
import { Container, Media, Likes, Comment } from './styles';

import EmptyList from '~/pages/ArchivedItems/EmptyList';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMEmoriesComment,
  createMemoriesLike,
} from '~/store/modules/event/eventAction';
import { MdSend } from 'react-icons/md';
import { Button, CircularProgress, TextField } from '@material-ui/core';

export default function ListView({ eventId }) {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const memories = useSelector((state) => state.eventReducer.memoriesData);
  const [comments, setComments] = useState('');
  const userIdd = useSelector((state) => state.userReducer.profile.id);
  const [loader, setLoader] = useState(false);
  const [likeLoader, setLikeLoader] = useState(false);

  const matchingMemory =
    memories &&
    memories.filter((memory) => memory.event_id === parseInt(eventId));

  const handleClick = (item) => {
    setLoader(true);
    if (comments !== '') {
      const payload = {
        event_memories_id: parseInt(item.id),
        user_id: userIdd,
        comments: comments,
        child_id: item.child_id,
      };
      try {
        Dispatch(addMEmoriesComment(payload));
        setTimeout(() => {
          setLoader(false);
          setComments('');
        }, 3000);
      } catch (error) {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const handleLike = async (item) => {
    setLikeLoader(true);
    const payload = {
      event_memories_id: item.id,
      user_id: userIdd,
      is_like: 1,
      child_id: item.child_id,
    };
    Dispatch(createMemoriesLike(payload));
    setTimeout(() => {
      setLikeLoader(false);
    }, 3000);
  };

  return matchingMemory && matchingMemory.length !== 0 ? (
    <Container>
      {matchingMemory &&
        matchingMemory.map((item) => (
          <Fragment>
            <Link
              to={`/event-album/${eventId}/post/${item.id}`}
              key={item.id}
              className="marginremove"
            >
              <Media src={item.url} />
            </Link>

            <Link to="#" className="marginremove">
              <Likes className="loadercss">
                {!likeLoader ? (
                  item &&
                  item.likes &&
                  item.likes.some((like) => like.user_id === userIdd) ? (
                    <IoMdHeart
                      size={20}
                      color={'red'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleLike(item)}
                    />
                  ) : (
                    <IoMdHeartEmpty
                      size={20}
                      color={'red'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleLike(item)}
                    />
                  )
                ) : (
                  <CircularProgress size={20} />
                )}
                {item && item.likesCount} friends liked this.
              </Likes>
              <div
                className={`${
                  item && item.comments && item.comments.length > 2
                    ? 'commentclass'
                    : ''
                }`}
              >
                {item &&
                  item.comments.map((comment) => (
                    <Comment key={comment.comments}>
                      <img
                        src={comment.user_image ? comment.user_image : LilGirl}
                        alt={'image'}
                      />
                      <div>
                        <strong>{comment.user_name}</strong>
                        <span>{comment.comments}</span>
                      </div>
                    </Comment>
                  ))}
              </div>
            </Link>

            <div
              style={{
                display: 'flex',
                border: '2px solid #4b5cab',
                width: 'auto',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <TextField
                label=""
                variant="standard"
                style={{ padding: '1px' }}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className="btntextfield"
              />

              {!loader ? (
                <Button
                  variant="contained"
                  color="#3851b7"
                  endIcon={<MdSend className="mdcss" />}
                  onClick={() => handleClick(item)}
                  className="memoriesbtn"
                ></Button>
              ) : (
                <Button
                  variant="contained"
                  color="#3851b7"
                  endIcon={<CircularProgress size={29} color="blue" />}
                  className="memoriesbtn"
                ></Button>
              )}
            </div>
          </Fragment>
        ))}
    </Container>
  ) : (
    <EmptyList>
      <h2 className="notempty">
        This is where you create a photo album for this event that will be
        shared with your invitees!{' '}
      </h2>
    </EmptyList>
  );
}
