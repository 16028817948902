import { takeLatest, call, put, all } from 'redux-saga/effects';
import { getNotification, getThankuCard, getThankyouPendingCard } from '../../../api/notificationAPI';
import {
  getNotificationByUserIdDone,
  fetchThankuCardDone,
  fetchThankuCardDonePending,
} from '../notification/notificationAction';

export function* watchGetNotification({ userId }) {
  try {
    const result = yield call(getNotification, userId);
    if (result.status === 200) {
      yield put(getNotificationByUserIdDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetThankuCard({ userId }) {
  try {
    const result = yield call(getThankuCard, userId);
    if (result.status === 200) {
      yield put(fetchThankuCardDone(result));
    }
  } catch (err) {
    return err;
  }
}
export function* watchGetThankuCardPending({ userId }) {
  try {
    const result = yield call(getThankyouPendingCard, userId);
    if (result.status === 200) {
      yield put(fetchThankuCardDonePending(result));
    }
  } catch (err) {
    return err;
  }
}
export default all([
  takeLatest('GET_NOTIFICATION_BY_USER_ID', watchGetNotification),
  takeLatest('FETCH_THANKU_CARD', watchGetThankuCard),
  takeLatest('FETCH_THANKU_CARD_PENDING', watchGetThankuCardPending),
]);
