import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { IoIosCamera, IoIosVideocam } from 'react-icons/io';

import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import { Container, UploadBox } from './styles';
import { useSelector } from 'react-redux';
import { addThankyouCardImage, createThankyouGift } from '~/api/giftAPI';
import CircularIndeterminate from '~/components/Loader';
import { uploadGiftCardImage } from '~/api/imageAPI';
import { getThankyouCardDetail } from '~/api/notificationAPI';

export default function ThankyouCardDetails() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const location = useLocation();
  const { id } = useParams();
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [loader, setLoader] = useState(false);
  const [cardDetail, setCardDetail] = useState('');

  useEffect(() => {
    handleCardDetail(id);
  }, [id]);

  const handleCardDetail = async (id) => {
    setLoader(true);
    await getThankyouCardDetail(id)
      .then((res) => {
        setCardDetail(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log('err', err);
      });
  };
  return (
    <>
      <SubHeader mobileDescVisible={false}>
        <h2>CARD DETAILS</h2>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <UploadBox
              backgroundImage={
                cardDetail &&
                cardDetail.receiveThankyou &&
                cardDetail.receiveThankyou.thank_you_card_images &&
                cardDetail.receiveThankyou.thank_you_card_images[0]
                  ? cardDetail.receiveThankyou.thank_you_card_images[0].url
                  : ''
              }
            ></UploadBox>
            <div className="content">
              <h1>Thank You {cardDetail && cardDetail.parentName}</h1>
              <h3>
                for your contribution towards {''}
                {cardDetail &&
                cardDetail.childName &&
                cardDetail.childName.first_name !== null
                  ? cardDetail &&
                    cardDetail.childName &&
                    cardDetail.childName.first_name
                  : cardDetail &&
                    cardDetail.childName &&
                    cardDetail.childName.group_name}{' '}
                &apos;s {''}
                {cardDetail && cardDetail.giftTitle}.
              </h3>
              <p>
                {cardDetail &&
                  cardDetail.receiveThankyou &&
                  cardDetail.receiveThankyou.description}
              </p>
            </div>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}

ThankyouCardDetails.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      funded: PropTypes.bool,
    }),
  }).isRequired,
};
