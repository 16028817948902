import React, { useContext } from 'react';

import Context from '~/context';

import CVV from '~/assets/images/cvv.svg';
import AmexCVV from '~/assets/images/amex-cvv.svg';

import { Container } from './styles';

export default function CCVModal() {
  const { handleShowModal } = useContext(Context);

  return (
    <Container>
      <h2>
        (CVV) <br /> Card Verification Number
      </h2>
      <p>
        <strong>Visa, Mastercard, etc...</strong>
      </p>
      <p>A 3 digit code located on the back of your credit or debit card</p>
      <img src={CVV} alt="CVV" />
      <p>
        <strong>American Express</strong>
      </p>
      <p>
        A 4 digit code located above your card number on the front of your card
      </p>
      <img src={AmexCVV} alt="Amex CVV" />
      <button className="blue" type="submit" onClick={() => handleShowModal()}>
        Ok
      </button>
    </Container>
  );
}
