import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import Button from '~/components/Button';
import SubHeader from '~/components/SubHeader';

import { OuterContainer, Container, OpenGift } from './styles';
import {
  bankListById,
  cardDetailsByUserId,
} from '~/store/modules/bankAccount/bankAccountAction';

export default function LinkBankNotification() {
  const { colors } = useContext(ThemeContext);
  const Dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.profile);
  const userId = user.id;
  const bankAccountReducer = useSelector((state) => state.bankAccountReducer);
  const { userBankDetails } = bankAccountReducer;

  useEffect(() => {
    Dispatch(bankListById(userId));
    Dispatch(cardDetailsByUserId(userId));
  }, [userId]);

  console.log('userCardDetails', userBankDetails);
  return (
    <>
      <SubHeader mobileDescVisible={false}>
        <h2>Receive Funds</h2>
      </SubHeader>
      <OuterContainer>
        <Container colors={{ start: colors.purple, end: colors.primary }}>
          <div>
            <OpenGift />
            <h1>Hooray</h1>
            <h2>
              Your friends have already started to contribute to Eugene’s
              gift(s)
            </h2>
            <p>
              For them to continue to contribute, please link the bank account
              where you will receive the funds.
            </p>
          </div>
          {userBankDetails && userBankDetails ? (
            <Button
              text="Bank Account Already Link"
              buttonFunction={() => history.push('/payment-details')}
            />
          ) : (
            <Button
              text="Link Bank Account"
              buttonFunction={() => history.push('/link-bank')}
            />
          )}
        </Container>
      </OuterContainer>
    </>
  );
}
