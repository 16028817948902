import styled from 'styled-components';

import { ReactComponent as PersonWavingSVG } from '~/assets/images/person-waving.svg';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  padding: 25px 40px;
  align-items: center;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);
  }

  img {
    max-width: 120px;
    margin: 10px auto 30px;
  }

  button {
    margin-bottom: 30px;
    max-width: 330px;
  }

  form {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    label {
      font-size: 15px;
      margin-bottom: 10px;
      color: #888;
      font-weight: bold;
      text-transform: uppercase;
    }

    textarea {
      font-size: 18px;
      margin-top: 10px;
      max-width: 330px;
      width: 100%;
      height: 40px;
      padding: 10px 15px;
      border: 0;
      border-radius: 0;

      &::placeholder {
        color: #b7b7b7;
      }
    }

    textarea {
      min-height: 126px;
      font-size: 15px;
      line-height: 20px;
    }
  }

  p {
    max-width: 360px;
    margin: 0 auto 20px;
    color: #656565;
    font-size: 15px;
    text-align: center;

    a {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  h2 {
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 10px;
  }

  h3 {
    text-align: center;
    color: #656565;
    margin-bottom: 20px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }
`;

export const PersonWaving = styled(PersonWavingSVG)`
  max-width: 150px;
  max-height: 120px;

  #hand {
    animation: wave 2s ease-in-out infinite;
    transform-origin: bottom;
  }

  #head {
    animation: head 4s ease-in-out infinite;
    transform-origin: bottom;
  }

  #eyes {
    animation: eyes 4s ease-in-out infinite;
    transform-origin: bottom;
  }

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes head {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(3deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes eyes {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-2px);
    }

    100% {
      transform: translateY(-);
    }
  }
`;
export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;