import styled from 'styled-components';
// import { lighten } from 'polished';
import FormControl from '@material-ui/core/FormControl';

export const Container = styled.div`
  max-width: 720px;
  margin: 115px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 25px 40px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }

  form {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 47%;

  @media (max-width: 767px) {
    width: 100%;
  }

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input {
    font-size: 18px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }
`;

export const SelectControl = styled(FormControl)`
  max-width: 330px;
  width: 47%;

  input {
    background-color: #fff;
    border-radius: 50%;
  }
`;
