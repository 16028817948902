const initialState = {
  gift: null,
  createdGiftId: null,
  giftCreated: false,
  currentGift: null,
  showNotice: null,
  currentUser: null,
  isReceiverCreated: false,
  isUserUpdated: null,
  isGiftUpdated: null,
  isGiftDeleted: null,
  giftImageFormData: null,
};

export default function giftReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_INDIVIDUAL_RECEIVER_DONE':
      return {
        ...state,
        isReceiverCreated: true,
      };
    case 'ADD_Multiple_RECEIVER_DONE':
      return {
        ...state,
        isReceiverCreated: true,
      };
    case '@user/UPDATE_PROFILE_SUCCESS':
      return {
        ...state,
        isUserUpdated: true,
      };
    case 'FETCH_USER_BY_ID_DONE':
      return {
        ...state,
        isUserUpdated: false,
      };
    case 'ADD_GIFT':
      return {
        ...state,
        gift: action.data,
        imagee:action.imageFile,
        createdGiftId: null,
        giftCreated: false,
      };
    case 'ADD_GIFT_DONE':
      return {
        ...state,
        createdGiftId: action.giftId,
        giftCreated: true,
      };
    case 'CREATE_GIFT_DONE':
      return {
        ...state,
        giftCreated: action.boolean,
      };
    case 'FETCH_GIFT_DETAILS_BY_ID_DONE':
      return {
        ...state,
        currentGift: action.data,
        isGiftUpdated: false,
        isGiftDeleted: false,
      };
    case 'FETCH_GIFT':
      return {
        ...state,
        showNotice: action.boolean,
      };
    case 'UPDATE_GIFT_DONE':
      return {
        ...state,
        isGiftUpdated: true,
      };
    case 'DELETE_GIFT_DONE':
      return {
        ...state,
        isGiftDeleted: true,
      };
    case 'UPLOAD_GIFT_IMAGE':
      return {
        ...state,
        imageFormData: action.data,
      };
    default:
      return state;
  }
}
