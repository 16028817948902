import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IoIosArrowForward } from 'react-icons/io';
import Amex from '~/assets/images/card-flag-amex.png';
import MasterCard from '~/assets/images/card-flag-mc.png';
import Visa from '~/assets/images/card-flag-visa.png';
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
} from 'react-icons/fa';

import Context from '~/context';

import CCVModal from './CCVModal';
import Input from '~/components/Input';
import ModalWrapper from '~/components/ModalWrapper';
import { addCreditCard } from '~/store/modules/creditCard/creditCardAction';

import {
  Container,
  InputWrapper,
  InputRow,
  RadioButton,
  RadioControl,
  Card,
  SelectExisting,
  InputCard,
  InputCardValidation,
} from './styles';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import history from '~/services/history';
import { ThemeContext } from 'styled-components';
import { number, expirationDate as expirydate, cvv } from 'card-validator';

export default function CreditForm({ cards }) {
  const Dispatch = useDispatch();
  const { handleShowModal } = useContext(Context);
  const { colors } = useContext(ThemeContext);
  const [radioValue, setRadioValue] = useState(1);
  const [addNewCard, setAddNewCard] = useState(cards.length === 0);
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [CCV, setCCV] = useState('');
  const [isValidCardNumber, setIsValidCardNumber] = useState(true);
  const [isValidExpiry, setIsValidExpiry] = useState(true);
  const [isValidCVV, setIsValidCVV] = useState(true);
  const userIdd = useSelector((state) => state.userReducer.profile.id);

  const detectCardType = (cardNumber) => {
    const visaPattern = /^4/;
    const mastercardPattern = /^5[1-5]/;
    const amexPattern = /^3[47]/;
    const discoverPattern = /^6(?:011|5)/;

    if (visaPattern.test(cardNumber)) {
      return 'visa';
    } else if (mastercardPattern.test(cardNumber)) {
      return 'mastercard';
    } else if (amexPattern.test(cardNumber)) {
      return 'amex';
    } else if (discoverPattern.test(cardNumber)) {
      return 'discover';
    } else {
      return 'default'; // You can handle other card types or return 'unknown' if needed.
    }
  };

  const handleRadioChange = (event) => {
    setRadioValue(parseInt(event.target.value, 10));
  };
  const cardType = detectCardType(cardNumber);
  const handleCardNumberChange = (e) => {
    const inputCardNumber = e.target.value.replace(/\s/g, ''); // Remove spaces from the input
    const formattedCardNumber = inputCardNumber
      .replace(/(\d{4})/g, '$1 ')
      .trim();

    setCardNumber(formattedCardNumber);

    const validationResult = number(inputCardNumber);
    setIsValidCardNumber(validationResult.isValid);
  };

  const handleExpiryChange = (e) => {
    const inputExpiry = e.target.value;
    let inputExpiry1 = inputExpiry.replace(/\D/g, '');

    // Insert slash after the first two digits (MM)
    if (inputExpiry1.length > 2) {
      inputExpiry1 = inputExpiry1.replace(/^(\d{2})/, '$1/');
    }

    const enteredMonth = Number(inputExpiry1.substr(0, 2));
    const enteredYear = Number(inputExpiry1.substr(3, 2));

    // Get the current date, month, and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-11.
    const currentYear = currentDate.getFullYear() % 100; // Last two digits of the current year.

    // Custom validation for expiry format (MM/YY) - 12/33
    const expiryRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    const isValidFormat = expiryRegex.test(inputExpiry1);

    // Check if the entered month and year are not in the past
    const isValidMonth =
      enteredYear > currentYear ||
      (enteredYear === currentYear && enteredMonth >= currentMonth);
    const isValidYear = enteredYear >= currentYear;

    setIsValidExpiry(isValidFormat && isValidMonth && isValidYear);
    setExpirationDate(inputExpiry1);
  };

  const handleCVVChange = (e) => {
    const inputCVV = e.target.value;
    setCCV(inputCVV);

    const validationResult = cvv(inputCVV);
    setIsValidCVV(validationResult.isValid);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedCardNumber = cardNumber && cardNumber.replace(/\s/g, '');
    const [month, year] = expirationDate && expirationDate.split('/');
    const data = {
      number: `${formattedCardNumber}`,
      exp_month: parseInt(month),
      exp_year: parseInt(20 + year),
      cvc: parseInt(CCV),
      currency: 'AUD',
    };
    if (
      isValidCardNumber === true &&
      isValidExpiry === true &&
      isValidCVV === true
    ) {
      Dispatch(addCreditCard(userIdd, data));
      history.push({
        pathname: '/success',
        state: {
          destination: `/payment-details`,
          message: 'Credit Card Saved!',
          colors: { start: colors.lightPurple, end: colors.purple },
        },
      });
    } else {
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {addNewCard ? (
        <>
          <Container>
            <InputWrapper>
              <input
                type="text"
                placeholder="4000 1234 5678 9089"
                value={cardNumber}
                onChange={handleCardNumberChange}
                maxLength="19" // Adjust the maximum length based on your requirements (e.g., 19 for 16 digits + 3 spaces)
              />

              <InputCard style={{marginTop:"-6px"}}>
                {cardType === 'visa' && (
                  <img src={Visa} alt="Visa" width={35} height={25} />
                )}
                {cardType === 'mastercard' && (
                  <img src={MasterCard} alt="MasterCard" width={35} height={25}/>
                )}
                {cardType === 'amex' && (
                  <img src={Amex} alt="American Express" width={35} height={25}/>
                )}
                {/* {cardType === 'discover' && <FaCcDiscover />} */}
              </InputCard>
              {!isValidCardNumber && (
                <InputCardValidation>Invalid card number</InputCardValidation>
              )}
            </InputWrapper>
            <InputRow>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="MM/YY"
                  value={expirationDate}
                  onChange={handleExpiryChange}
                  maxLength="5"
                />
                {!isValidExpiry && (
                  <InputCardValidation>Invalid expiry date</InputCardValidation>
                )}
              </InputWrapper>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="000"
                  value={CCV}
                  onChange={handleCVVChange}
                  maxLength="3"
                />
                {!isValidCVV && (
                  <InputCardValidation>Invalid CVV</InputCardValidation>
                )}
              </InputWrapper>
            </InputRow>
            {cardNumber !== '' && expirationDate !== '' && CCV !== '' ? (
              <Button text="Save" buttonFunction={(e) => handleSubmit(e)} />
            ) : (
              <Button text="Save" buttonFunction={(e) => handleSubmit1(e)} />
            )}
          </Container>
          {cards.length !== 0 && (
            <SelectExisting
              className="select-existing"
              onClick={() => setAddNewCard(false)}
            >
              Or select existing card.
            </SelectExisting>
          )}
        </>
      ) : (
        <>
          <RadioControl component="fieldset">
            <RadioGroup
              aria-label="funds-instructions"
              name="funds-instructions"
              value={radioValue}
              onChange={handleRadioChange}
            >
              {cards.map((card) => (
                <Card key={card.id}>
                  <FormControlLabel
                    value={card.id}
                    control={<RadioButton />}
                    labelPlacement="start"
                    label={
                      <div>
                        xxxx - xxxx - xxxx -<span>{card.number}</span>
                      </div>
                    }
                  />
                </Card>
              ))}
            </RadioGroup>
          </RadioControl>
          <Card onClick={() => setAddNewCard(true)}>
            <span>Enter new card</span>
            <IoIosArrowForward size={25} color="#d6d6d6" />
          </Card>
        </>
      )}
      <ModalWrapper>
        <CCVModal />
      </ModalWrapper>
    </>
  );
}

CreditForm.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      number: PropTypes.string,
    }),
  ),
};

CreditForm.defaultProps = {
  cards: [],
};
