import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';

import { setEventId } from '../../store/modules/event/eventAction';

import { Container, CheckboxControl } from './styles';

export default function EventList({ events }) {
  const Dispatch = useDispatch();
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    Dispatch(setEventId(e.target.value));
    setValue(e.target.value);
  };
  return (
    <Container
      aria-label="gender"
      name="gender1"
      value={value}
      onChange={handleChange}
    >
      {events.map((event) => (
        <CheckboxControl
          key={event.id}
          value={event.id}
          control={<Radio color="primary" />}
          label={event.title}
          labelPlacement="start"
          checked={parseInt(value) === event.id}
        />
      ))}
    </Container>
  );
}

EventList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
};
