import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  max-width: 720px;
  margin: 50px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
    padding-bottom: 40px;
    margin: 0;
    min-height: calc(100% - 135px);
  }

  > div {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      max-width: initial;
    }

    &:last-child {
      margin-top: -37px;
    }

    > button:last-of-type {
      max-width: 90%;
      margin-top: 40px;
    }
  }

  .row {
    margin: 10px 0 0;
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;

    .contributors {
      display: flex;
      flex: 1;
      align-items: center;

      .avatar-row {
        display: flex;

        .image-wrapper {
          width: 20px;
        }

        img {
          margin: 0;
          border: 2px solid white;
        }
      }
    }

    span {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 16px;
      margin-left: 30px;

      &.raised {
        color: #888;
        font-size: 18px;
        margin: 0;
      }
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  height: 230px;
`;

export const SingleGiftCard = styled.div`
  min-width: 90%;
  margin-bottom: 10px;
  background-color: #fff;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .single-gift-header {
    background-color: ${({ headerBackgroundColor }) => headerBackgroundColor};
    max-height: 74px;
    text-align: center;

    h1 {
      color: #fff;
    }
  }

  > div {
    padding: 16px 25px;
    width: 100%;
    display: flex;
    flex-direction: column;

    > div:first-of-type {
      display: flex;
      justify-content: space-between;
      color: #888;
      text-transform: uppercase;
      font-size: 18px;
    }
  }

  .progress-bar {
    margin-top: 10px;

    > div:first-of-type {
      background-color: #e9ecee;
      border-radius: 2px;
    }

    > div:last-of-type {
      display: flex;
      justify-content: space-between;
      color: #888;
      margin-top: 10px;
      font-size: 16px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
`;

export const OtherButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 10px;

  > button {
    max-width: 49%;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }
`;
