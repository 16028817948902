import React, { useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Mobile } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';
import Context from '~/context';

import SuccessScreen from './SuccessScreen';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import DatePicker from '~/components/DatePicker';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as EnvelopeIcon } from '~/assets/images/envelope-icon.svg';

import {
  Container,
  InputWrapper,
  InputColumn,
  CheckboxControl,
  BigButton,
  RadioButton,
  RadioControl,
  InputColumnHide,
} from './styles';
import { createEventInvitation } from '~/store/modules/event/eventAction';
import { updateEventInvitation } from '~/api/eventAPI';

export default function EditEvent() {
  const location = useLocation();
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { flow } = useContext(Context);
  const [checkboxValue, setCheckboxValue] = useState(true);
  const [radioValue, setRadioValue] = useState('manual');
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const { type } = useParams();
  const [date, setDate] = useState(new Date());
  const invitationData = useSelector((state) => state.eventReducer.event);
  const cardDetails = location.state && location.state;
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const receiver_ids = useSelector((state) => state.receiverReducer.receiver);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleCheckboxChange = () => {
    setCheckboxValue(!checkboxValue);
  };

  const handleSubmit = async () => {
    setSubmitButtonLoading(true);
    if (cardDetails && cardDetails.event_type === 'edit') {
      const data = {
        title: cardDetails.title,
        description: cardDetails.description,
        user_id: cardDetails.user_id,
        child_id: parseInt(cardDetails.child_id),
        color: cardDetails.color,
        invite_greeting: cardDetails.invite_greeting,
        image: cardDetails.image,
        event_id: cardDetails.event_id,
        card_type: radioValue,
      };
      await updateEventInvitation(data)
        .then((res) => {
          setSubmitButtonLoading(false);
          history.push({
            pathname: '/success',
            state: {
              destination: `/event-details/${cardDetails.event_id}`,
              message: 'Event updated.',
              colors: { start: colors.lightPurple, end: colors.orange },
            },
          });
        })
        .catch((err) => {
          console.log('err', err);
          setSubmitButtonLoading(false);
        });
    } else if (checkboxValue === true && type !== 'celebratory') {
      cardDetails.cardData.gift_ids = cardDetails.gift_id;
      history.push('/manage-cards/celebratory', {
        cardData: cardDetails.cardData,
        receiverData: cardDetails && cardDetails.receiverData,
      });
      setSubmitButtonLoading(false);
    } else if (checkboxValue === true && type === 'celebratory') {
      if (
        cardDetails &&
        cardDetails.receiverData &&
        cardDetails.receiverData.id
      ) {
        cardDetails.cardData.child_id =
          cardDetails && cardDetails.receiverData.id;
      }
      Dispatch(createEventInvitation(cardDetails.cardData));
      setSubmitButtonLoading(false);
      setShowSuccessScreen(true);
      localStorage.removeItem('giftId');
      localStorage.removeItem('cardData');
      localStorage.removeItem('addinfo');
    } else {
      cardDetails.cardData.card_type = radioValue;
      cardDetails.cardData.gift_ids = cardDetails.gift_id;
      if (checkboxValue) {
        cardDetails.cardData.celebratory_card_date = date;
      }
      if (
        cardDetails &&
        cardDetails.receiverData &&
        cardDetails.receiverData.id
      ) {
        cardDetails.cardData.child_id =
          cardDetails && cardDetails.receiverData.id;
      }
      Dispatch(createEventInvitation(cardDetails.cardData));
      setSubmitButtonLoading(false);
      setShowSuccessScreen(true);
      localStorage.removeItem('giftId');
      localStorage.removeItem('cardData');
      localStorage.removeItem('addinfo');
    }
  };

  return (
    <>
      {showSuccessScreen ? (
        <SuccessScreen
          event_id={invitationData.id}
          receiver_id={
            (receiver_ids && receiver_ids.id) ||
            (cardDetails &&
              cardDetails.receiverData &&
              cardDetails.receiverData.id)
          }
        />
      ) : (
        <>
          <SubHeader
            backgroundColor={colors.lightPurple}
            buttonText="Next"
            buttonFunction={() => handleSubmit()}
            submitButtonLoading={submitButtonLoading}
          >
            {type === 'celebratory' ? (
              <>
                <h2>Celebratory Card</h2>
                <h3>When would you like the celebratory card to be ready?</h3>
              </>
            ) : (
              <>
                {flow.giftOption === 'one-gift' ? (
                  <>
                    <h2>Manage Cards</h2>
                    <h3>Choose how you want to thank your gift contributors</h3>
                  </>
                ) : (
                  <>
                    <h2>THANK YOU CARDS</h2>
                    <h3>
                      Choose how you want to thank your gift contributors.
                    </h3>
                  </>
                )}
              </>
            )}
          </SubHeader>
          <Container>
            {type === 'celebratory' ? (
              <InputWrapper>
                <DatePicker
                  label="date"
                  name="date"
                  date={date}
                  onChange={handleDateChange}
                  minDate={new Date()}
                />
                <p>
                  Your card will include all messages received until11:59pm of
                  the day before the date you chose.
                </p>
                <p>
                  You will be notified once the card is ready so you can edit it
                  and share with contributors and thegift receiver(s).
                </p>
              </InputWrapper>
            ) : (
              <>
                <form>
                  <InputColumn>
                    <InputWrapper>
                      <div>
                        <label>Thank&nbsp;You Cards</label>
                        <span className="info-button">i</span>
                      </div>
                      <div className="card-options">
                        <RadioControl component="fieldset">
                          <RadioGroup
                            aria-label="send-card"
                            name="send-card"
                            value={radioValue}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="auto"
                              control={<RadioButton />}
                              label="Automatically send cards to&nbsp;contributors."
                            />
                            <FormControlLabel
                              value="manual"
                              control={<RadioButton />}
                              label="I will manually send cards via&nbsp;the&nbsp;app"
                            />
                          </RadioGroup>
                        </RadioControl>
                      </div>
                    </InputWrapper>
                  </InputColumn>
                  {/* <InputColumnHide>
                    <InputWrapper>
                      <div>
                        <label>Celebratory Cards</label>
                        <span className="info-button">i</span>
                      </div>

                      <div>
                        <CheckboxControl
                          control={
                            <Checkbox
                              checked={checkboxValue}
                              onChange={handleCheckboxChange}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="I want a card to be created with the messages
                  from all contributors so I can share it with
                  Mark and Jane. I agree with the $5 fee which
                  will be automatically deducted from the first
                  gift contribution made."
                        />
                      </div>
                    </InputWrapper>
                    <div>
                      <BigButton onClick={() => handleSubmit()}>
                        <div className="image-wrapper">
                          <EnvelopeIcon alt="Envelope" />
                        </div>
                        <div className="text-content">
                          <h2>Sample Card</h2>
                        </div>
                      </BigButton>
                    </div>
                  </InputColumnHide> */}
                </form>
                <Mobile>
                  <Button
                    text="Next"
                    textColor="#ffffff"
                    backgroundColor={colors.purple}
                    buttonFunction={() => handleSubmit()}
                  />
                </Mobile>
              </>
            )}
          </Container>
        </>
      )}
    </>
  );
}
