import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 720px;
  margin: 0 auto 40px;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 50px 25px 25px;
  align-items: center;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  max-width: 720px;
  margin: 40px auto 0;
  width: 90%;
  display: flex;

  @media (max-width: 767px) {
    margin: 0 auto;
    width: 100%;
  }

  .MuiTabs-indicator {
    top: 0;
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    width: 125px;
    height: 44px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin: 0 1px;
    cursor: pointer;
    background-color: #f1f1f1;
    opacity: 1;

    @media (max-width: 767px) {
      flex: 1;
    }

    .MuiTab-wrapper {
      color: #888;
    }

    &.MuiTab-textColorInherit.Mui-selected {
      background-color: #f6f6f6;

      span {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;


export const IconContainer = styled.div`
  .inputfile {
    display: none;

    & + label {
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      color: #f1e5e6;
      background-color: ${(props) => props.backgroundColor};
      display: flex;
      align-items: center;
      margin: 0;
      width: ${(props) => `${props.size}px`};
      height: ${(props) => `${props.size}px`};
      transition: background-color 0.3s;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }
    }

    &:focus + label,
    &.has-focus + label,
    & + label:hover {
      outline: none;
      background-color: ${darken(0.5, '#d5d5d5')};
    }
  }
`;