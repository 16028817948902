const initialState = {
  id: null,
  receiver: null,
  receiverType: null,
  gifts: null,
  events: null,
  isReceiver: false,
  isReceiverUpdate: null,
  isDeleted: null,
  isReceiverError: false,
  imageFormData: null,
  fetchedReceiver: false,
  isReceiverCreated: false,
  archivedEvent: null,
  archivedProfile: null,
};

export default function receiverReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_RECEIVER':
      return {
        ...state,
        receiverType: action.receiverType,
        isReceiverCreated: false,
      };

    case 'ADD_RECEIVER_DONE':
      return {
        ...state,
        id: action.id,
        receiver: action.result.data,
        isReceiver: true,
        fetchedReceiver: true,
        isReceiverCreated: true,
      };

    case 'FETCH_RECEIVER_BY_ID_DONE':
      return {
        ...state,
        receiver: action.data,
        gifts: action.data.gifts,
        events: action.data.events,
        isReceiverUpdate: false,
        isDeleted: false,
        isReceiver: false,
        isReceiverError: false,
        fetchedReceiver: false,
        isReceiverCreated: false,
      };
    case 'FETCH_ARCHIVE_EVENT_BY_ID_DONE':
      return {
        ...state,
        archivedEvent: action.data,
      };
    case 'FETCH_ARCHIVE_RECEIVER_BY_ID_DONE':
      return {
        ...state,
        archivedProfile: action.data,
      };

    case 'FETCH_RECEIVER_BY_ID':
      return {
        ...state,
        id: action.id,
        gift: null,
        events: null,
      };

    case 'FETCH_RECEIVER_BY_ID_ERROR':
      return {
        ...state,
        id: null,
        receiver: null,
        gifts: null,
        events: null,
        isReceiverError: true,
      };

    case 'SET_IS_RECEIVER':
      return {
        ...state,
        isReceiver: action.bools,
      };
    case 'UPDATE_RECEIVER_DONE':
      return {
        ...state,
        isReceiverUpdate: action.bools,
        fetchedReceiver: true,
      };
    case 'DELETE_RECEIVER_DONE':
      return {
        ...state,
        isDeleted: action.bools,
        fetchedReceiver: true,
      };
    case 'FETCH_GIFT_DETAILS_BY_ID_DONE':
      return {
        ...state,
        currentReceiverGift: action.data,
      };
    case 'UPLOAD_RECEIVER_IMAGE':
      return {
        ...state,
        imageFormData: action.data,
        fetchedReceiver: true,
      };
    case 'UPLOAD_RECEIVER_IMAGE_DONE':
      return {
        ...state,
        receiver: { ...state.receiver, child_image: action.imagePath },
      };
    default:
      return state;
  }
}
