import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';
import contacts from './contactsList';
import SubHeader from '~/components/SubHeader';
import malePlaceholder from '~/assets/images/avatar-placeholder.png';
import femalePlaceholder from '~/assets/images/girl-face.svg';

import { Container, InputColumn, Card } from './styles';
import { getReceiverByUserId } from '~/api/receiverAPI';
import CircularIndeterminate from '~/components/Loader';

export default function ArchivedItems() {
  const { colors } = useContext(ThemeContext);
  const user = useSelector((state) => state.userReducer.profile);
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState('');

  const getReceiverList = async (id) => {
    setLoader(true);
    await getReceiverByUserId(id)
      .then((res) => {
        setList(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getReceiverList(user.id);
  }, [user]);

  return (
    <>
      <SubHeader backgroundColor={colors.purple}>
        <h2>Archived Items</h2>
        <h3>
          Select the profile related to the item you’d like to make visible
          again:
        </h3>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            {list && list.length > 0 ? (
              <>
                <InputColumn>
                  {list.map((contact) => (
                    <Card
                      key={contact.id}
                      onClick={() =>
                        history.push(`/archived-items/${contact.id}/`)
                      }
                    >
                      <div>
                        <img
                          src={
                            contact.child_image
                              ? contact.child_image
                              : contact.gender === 'M'
                              ? malePlaceholder
                              : femalePlaceholder
                          }
                          alt={`${
                            contact.first_name
                              ? contact.first_name + ' ' + contact.last_name
                              : contact.group_name
                          }`}
                        />
                        <div>
                          <h3>{`${
                            contact.first_name
                              ? contact.first_name + ' ' + contact.last_name
                              : contact.group_name
                          }`}</h3>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={20} color="#d6d6d6" />
                      </div>
                    </Card>
                  ))}
                </InputColumn>
              </>
            ) : (
              <h3 style={{ fontSize: 'large' }}>
                You haven&apos;t archived any profiles.
              </h3>
            )}
          </>
        ) : (
          <CircularIndeterminate />
        )}
      </Container>
    </>
  );
}
