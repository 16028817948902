import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import Context from '~/context';

import { ReactComponent as Archive } from '~/assets/images/archive.svg';
import { ReactComponent as Trash } from '~/assets/images/trash-cant.svg';

import { Container } from './styles';
import { useSelector } from 'react-redux';
import { createReceiverArchive } from '~/api/receiverAPI';
import CircularIndeterminate from '~/components/Loader';

export default function ArchiveReceiverProfile() {
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const [loader, setLoader] = useState(false);
  const [check, setCheck] = useState(false);
  const getFollow = localStorage.getItem('follow');
  const profile = useSelector((state) => state.userReducer.profile);

  const handleArchiveProfile = async () => {
    setLoader(true);
    const data = {
      child_id: receiver.id,
      user_id: profile.id,
    };

    await createReceiverArchive(data)
      .then((res) => {
        if (res && res.data) {
          handleShowModal();
          history.push({
            pathname: '/success',
            state: {
              destination: `/my-profile`,
              message: 'Profile Archived!',
              colors: { start: colors.lightPurple, end: colors.purple },
            },
          });
        } else {
          setLoader(false);
          setCheck(true);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <Container>
      {!check ? (
        <>
          <Archive />
          <h2>
            Before you archive/hide <br /> this gift receiver’s page, be aware
            that:
          </h2>
          {getFollow && getFollow !== null ? (
            <p>
              You will no longer be able to see any gifts, events or memories
              associated with this profile.
            </p>
          ) : (
            <p>
              Friends who joined events or contributed to the gifts linked to
              this receiver(s) will continue to see the profile, but will no
              longer be able to make contributions.
            </p>
          )}
          <small>
            You can unhide this event by going to <br /> My Profile / Menu
            /Manage View
          </small>
          <button type="submit" onClick={() => handleShowModal()}>
            Cancel
          </button>

          {!loader ? (
            <button
              type="submit"
              className="blue"
              onClick={() => handleArchiveProfile()}
            >
              Archive
            </button>
          ) : (
            <button type="submit" className="blue">
              <CircularIndeterminate />
            </button>
          )}
        </>
      ) : (
        <>
          <Trash />
          <h2>Sorry, you are unable to archive this profile.</h2>
          <p>
            This profile cannot be archived because you have open events and/or
            partially funded gifts linked to this receiver(s) profile.
          </p>
          <p>
            We recommend you complete the funding of gifts and wait for all
            existing events to expiry.
          </p>
          <button type="submit" onClick={() => handleShowModal()}>
            Close
          </button>
        </>
      )}
    </Container>
  );
}
