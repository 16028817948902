import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';
import SubHeader from '~/components/SubHeader';
import { Container, GiftCard } from './styles';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

export default function TermsAndConditions() {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
        mobileDescVisible={false}
      >
        <h2>Terms and Conditions</h2>
      </SubHeader>
      <Container className="terms">
        <GiftCard>
          <div>
            <h4>
              This document sets out the terms and conditions on which Purposit
              has agreed to provide You with the Purposit Services (Terms and
              Conditions). You acknowledge that You have read, understood and
              agree to be legally bound by these Terms and Conditions.{' '}
            </h4>
            <p className="bborderline"></p>

            <table>
              <tbody>
                <tr>
                  <td>
                    <h3>1. &emsp;&nbsp;Formation of an Agreement</h3>
                  </td>
                </tr>
                <br />

                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">1.1</p>
                      <p className="paragraph">
                        It is a condition to Purposit agreeing to provide You
                        with the Purposit Services that You are capable of
                        forming a legally binding contract.
                      </p>
                    </span>{' '}
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">1.2</p>
                      <p className="paragraph">
                        Upon Purposit providing you with an Account, an
                        agreement is formed between You and Purposit, comprising
                        the terms and conditions contained in:
                        <br /> <br />
                        (a) &emsp; these Terms and Conditions; and
                        <br /> <br />
                        (b) &emsp; any applicable User Documentation.
                      </p>
                    </span>{' '}
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading"> 1.3</p>
                      <p className="paragraph">
                        If there is any inconsistency between these Terms and
                        Conditions and the User Documentation, the provisions of
                        the User Documentation will prevail to the extent of
                        that inconsistency.
                      </p>
                    </span>{' '}
                  </td>
                </tr>
                <p className="bborderline"></p>
                <tr>
                  <td>
                    <h3>2. &emsp;&nbsp;Access to Platform</h3>
                  </td>
                </tr>
                <br />

                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">2.1 </p>
                      <p className="paragraph">
                        Purposit agrees to provide You with the Purposit
                        Services for the duration of the Term on the terms and
                        conditions contained in the Agreement.
                      </p>
                    </span>{' '}
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">2.2</p>
                      <p className="paragraph">
                        You must advise Purposit if there is any change in Your
                        email or postal address or mobile phone number or any
                        other information reasonably required by Purposit for
                        the administration of your Account.
                      </p>
                    </span>{' '}
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">2.3 </p>
                      <p className="paragraph">
                        Purposit may, from time to time, make modifications to
                        the Platform, including modifications to the design,
                        functionality and/or appearance of the Platform,
                        although Purposit is not obliged or required to do so
                        and will not be liable for any changes to the Platform,
                        its functionality, compatibility or use on any Devices.
                      </p>
                    </span>{' '}
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">2.4 </p>
                      <p className="paragraph">
                        Purposit may access the Platform or Your Account without
                        notice to You, for any purpose associated with the
                        overall administration or management of the Platform,
                        including, but not limited to, providing support to You.
                      </p>
                    </span>{' '}
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">2.5</p>
                      <p className="paragraph">
                        In subscribing to receive the Purposit Services, You
                        acknowledge and agree that:
                        <br /> <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            {' '}
                            a copy of the App may be required for any particular
                            Device, the use of which will be subject to the App
                            T&CS, and that You are responsible for ensuring that
                            You have read and understood the Agreement and the
                            App T&CS;
                          </p>
                        </span>
                        <br /> <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            {' '}
                            Purposit is entitled to assume that any person using
                            any relevant security identifier (e.g. password and
                            log-in) to access the Platform is authorised to do
                            so, and Purposit shall not be liable to You, and You
                            shall hold Purposit (and keep Purposit held)
                            harmless, from any liability whatsoever for any
                            action, non-action or response by Purposit in
                            relation to such request;
                          </p>
                        </span>
                        <br /> <br />
                        <span className="innerbox">
                          <p className="heading">(c)</p>
                          <p className="paragraph1">
                            {' '}
                            You are responsible for the confidentiality and use
                            of any security identifiers and that, if You become
                            aware of any loss or theft or unauthorised use of
                            any of Your security identifiers, You must notify
                            Purposit immediately.
                          </p>
                        </span>
                      </p>
                    </span>{' '}
                  </td>
                </tr>
                <p className="bborderline"></p>
                <tr>
                  <td>
                    <h3>3. &emsp;&nbsp;Accounts and Content</h3>
                  </td>
                </tr>
                <br />

                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">3.1</p>
                      <p className="paragraph">
                        You acknowledge and agree that:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            You are solely responsible for the submission and
                            input of Data and that all activities that occur in
                            respect of Your Account, including its completeness,
                            accuracy and correctness, and for obtaining all
                            necessary licences and consents for its use;
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            Purposit acts as a portal for the online
                            distribution and publication of third party Content
                            and that:
                            <br />
                            <br />
                            <span className="innerbox">
                              <p className="heading">(1)</p>
                              <p className="paragraph1">
                                Purposit does not act as moderator or editor of
                                such Content and makes no warranty that such
                                Content will actually be made available via the
                                Platform and accepts no liability for, or
                                related to, any third party Content that is
                                accessible via the Platform, howsoever
                                arising;and
                              </p>
                            </span>
                            <br />
                            <span className="innerbox">
                              <p className="heading">(2)</p>
                              <p className="paragraph1">
                                the Platform may contain links to other third
                                party websites that are not controlled by
                                Purposit and whose content, products, services,
                                practices, policies or performance are not
                                reviewed or endorsed by Purposit; and
                              </p>
                            </span>
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(c)</p>
                          <p className="paragraph1">
                            Purposit has the right (but not the obligation) to
                            take any action it considers appropriate in respect
                            of any Content provided to it, including to reject,
                            approve or modify such Content, and takes no
                            responsibility, and You agree to hold Purposit
                            harmless, for the deletion or failure to store any
                            Content, whether or not the Content was made
                            available via the Platform or not.
                          </p>
                        </span>
                      </p>
                    </span>
                  </td>
                </tr>
                <br />

                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">3.2</p>
                      <p className="paragraph">
                        You represent and warrant to Purposit that any Data
                        submitted by You or otherwise derived from Your use of
                        the platform will not:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            infringe upon or otherwise misappropriate any
                            Intellectual Property Right or other proprietary
                            right, or right of privacy, of any person;
                          </p>
                        </span>
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            violate any Applicable Law;
                          </p>
                        </span>
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(c)</p>
                          <p className="paragraph1">
                            be defamatory, obscene, threatening, extortionate,
                            objectionable or derogatory of any ethnic, racial,
                            gender, religious, professional or age, contain
                            child pornography, contain adult pornography or
                            otherwise offend generally accepted community
                            standards in Australia; or
                          </p>
                        </span>
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(d)</p>
                          <p className="paragraph1">
                            promote the use or sale of illegal drugs, tobacco,
                            alcohol, firearms/weapons or otherwise promote any
                            particular political agenda or message.
                          </p>
                        </span>
                      </p>
                    </span>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">3.3</p>
                      <p className="paragraph">
                        In subscribing to receive the Purposit Services, You,
                        acknowledge and agree that:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            when an Organiser invites people to provide
                            Contributions and/ or when You provide Contributions
                            to an Organiser, that arrangement is between the
                            Organiser and the Contributor and that Purposit will
                            not be, and will not be deemed to be, a party to or
                            assume any liability for any such arrangement, and
                            that the relevant Organiser and Contributor(s) shall
                            be solely responsible for that arrangement,
                            including, but not limited to:
                            <br />
                            <br />
                            <span className="innerbox">
                              <p className="heading">(1)</p>
                              <p className="paragraph1">
                                Purposit does not act as moderator or editor of
                                such Content and makes no warranty that such
                                Content will actually be made available via the
                                Platform and accepts no liability for, or
                                related to, any third party Content that is
                                accessible via the Platform, howsoever
                                arising;and
                              </p>
                            </span>
                            <br />
                            <span className="innerbox">
                              <p className="heading">(2)</p>
                              <p className="paragraph1">
                                the Platform may contain links to other third
                                party websites that are not controlled by
                                Purposit and whose content, products, services,
                                practices, policies or performance are not
                                reviewed or endorsed by Purposit; and
                              </p>
                            </span>
                          </p>
                        </span>
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            Purposit does not endorse any Gift, Event Organiser
                            or Recipient, or make any guarantee, express or
                            implied, that any Content or information provided
                            through the Platform by a User is true, accurate,
                            not misleading or deceptive or otherwise
                            appropriate, or that any Gifts or Contributions will
                            be used in accordance with any purpose stated or
                            implied by an Organiser or that an Organiser is
                            acting honestly or in compliance with its
                            obligations under any Applicable Law.
                          </p>
                        </span>
                      </p>
                    </span>
                  </td>
                </tr>
                <p className="bborderline"></p>

                <tr>
                  <td>
                    <h3>4. &emsp;&nbsp;General obligations</h3>
                  </td>
                </tr>
                <br />

                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">4.1</p>
                      <p className="paragraph">
                        In subscribing to receive the Purposit Services, You
                        acknowledge and agree that:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            You are over 18 years old or, if You are not over 18
                            years old as at the date of creating an Account, You
                            will give notice to Purposit terminating this
                            Agreement within 30 days of turning 18 years old if
                            you no longer wish to receive the Purposit Services
                            and be bound by the Agreement;
                          </p>
                        </span>
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            You must, at all times:
                            <br />
                            <br />
                            <span className="innerbox">
                              <p className="heading">(A)</p>
                              <p className="paragraph1">
                                use the Platform and the Content strictly in
                                accordance with, and for the purposes
                                contemplated by, this Agreement;
                              </p>
                            </span>
                            <br />
                            <span className="innerbox">
                              <p className="heading">(B)</p>
                              <p className="paragraph1">
                                not tamper or modify, or attempt to tamper or
                                modify, the Platform, copy, adapt, reproduce,
                                store, distribute, print, display, perform,
                                broadcast, publish, communicate to the public,
                                make available to the public or create
                                derivative works of the whole or any part of the
                                Platform or any Content (including all
                                trade-marks) or seek to disassemble or reverse
                                engineer the Platform; and
                              </p>
                            </span>
                            <br />
                            <span className="innerbox">
                              <p className="heading">(C)</p>
                              <p className="paragraph1">
                                take all necessary precautions not to post, send
                                or otherwise make available through the
                                Platform, any material that contains any virus,
                                trojan, worm or similar deleterious program that
                                may damage or interfere with the operation of
                                the Platform; and
                              </p>
                            </span>
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(c)</p>
                          <p className="paragraph1">
                            ensure that You do not use the Platform for any
                            transaction that is or may be prohibited by banks or
                            their relevant payment affiliates for processing,
                            including for the purpose of any prohibited money
                            transfer or money lending arrangement.
                          </p>
                        </span>
                      </p>
                    </span>
                  </td>
                </tr>
                <p className="bborderline"></p>
                <tr>
                  <td>
                    <h3>5. &emsp;&nbsp;Platform</h3>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">5.1</p>
                      <p className="paragraph">
                        You acknowledge that the Platform may not be free from
                        fault or interruption and that the Platform may be
                        unavailable during planned or unplanned outages or
                        maintenance periods.
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">5.2</p>
                      <p className="paragraph">
                        Purposit may, in its sole discretion, for any reason:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            change or restrict access to Your Account and/or the
                            Platform; and
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            modify, suspend, terminate or close and remove any
                            Account or Content otherwise displayed on the
                            Platform.
                            <br />
                          </p>
                        </span>
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">5.3</p>
                      <p className="paragraph">
                        To the extent that You access the Platform through a
                        mobile device, Your wireless service carrier's charges,
                        data rates and/or other fees may apply. In addition,
                        downloading, installing or accessing the Platform may be
                        prohibited or restricted by Your carrier, and the
                        Platform may not work with all carriers or Devices.
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">5.4</p>
                      <p className="paragraph">
                        You agree that we may communicate with You regarding the
                        Platform or Your Account by SMS, MMS, text message,
                        email or other electronic means to Your mobile Device
                        and that certain information about Your usage of the
                        Platform from such mobile Device may be communicated to
                        us.
                      </p>
                    </span>
                    <br />
                  </td>
                </tr>
                <p className="bborderline"></p>

                <tr>
                  <td>
                    <h3>6. &emsp;&nbsp;Fees and payment</h3>
                  </td>
                </tr>
                <br />

                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">6.1</p>
                      <p className="paragraph">
                        You must pay Purposit all Fees (if any) payable by You
                        in connection with Your access to and/or use of the
                        Platform (as applicable).
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">6.2</p>
                      <p className="paragraph">
                        You acknowledge and agree that:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            Purposit will not receive, hold or otherwise control
                            any Contribution(s) submitted by a Contributor other
                            than an amount equal to its Fee(s) payable to it,
                            which amount each Contributor and Organiser
                            irrevocably acknowledges and agrees:
                            <br />
                            <br />
                            <span className="innerbox">
                              <p className="heading">(1)</p>
                              <p className="paragraph1">
                                will be immediately due and payable to Purposit
                                upon a Contributor submitting a Contribution,
                                which each Contributor hereby irrevocably
                                directs and authorises Purposit and/ or its
                                third-party payment processor(s) to deduct from
                                any Contribution(s);
                              </p>
                            </span>
                            <br />
                            <span className="innerbox">
                              <p className="heading">(2)</p>
                              <p className="paragraph1">
                                are non-refundable, including as a result of any
                                Organiser withdrawing an Invitation or failing
                                to purchase the relevant Gift after or before
                                the date of the Contribution;
                              </p>
                            </span>
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            all payments are processed and disbursed by
                            Purposit’s third party payment provider, who, at the
                            date of this Agreement is Stripe, Inc [US], whose
                            terms and conditions are contained at the website
                            &nbsp;
                            <a
                              href="https://stripe.com/au"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="linktext"
                            >
                              https://stripe.com/au
                            </a>{' '}
                            &nbsp; and which you agree to be bound by and which
                            you agree that any breach of will be deemed to a
                            breach of these Terms and Conditions;
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(c)</p>
                          <p className="paragraph1">
                            You may be asked to provide customary payment
                            information to Purposit or its third party
                            processors, such as name, billing address, direct
                            debit and credit card information either to Purposit
                            or its third-party payment processor(s) and that
                            Purposit and any third-party payment processor(s)
                            are irrevocably authorised to initiate credit
                            entries to recover the Fees from any payment method
                            provided by You and to debit Your account to
                            discharge Your obligations pursuant to this
                            Agreement, which may include transaction or
                            brokerage fees payable to any third party service
                            providers, and that Purposit and/or its agents are
                            not responsible for withholding or payment of any
                            sales, use, personal property or other governmental
                            tax or levy imposed on its Fees;
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(d)</p>
                          <p className="paragraph1">
                            Purposit or its third-party payment processor(s)
                            may, from time to time, undertake any
                            pre-authorization of your credit card and/ or charge
                            your credit card a nominal amount to verify your
                            credit card and that additional charges or expenses
                            may be charged by Your bank or credit card provider
                            as a result of the collection of Fees by Purposit or
                            its third-party payment processor(s) and that
                            Purposit disclaims all liability in relation to such
                            charges or expenses;
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(e)</p>
                          <p className="paragraph1">
                            Purposit holds the benefit of each right, promise
                            and obligation, including the benefit of each
                            indemnity and release, contained in this Agreement,
                            on its own behalf and for the benefit of each of its
                            third-party payment processor(s); and
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(f)</p>
                          <p className="paragraph1">
                            in no event shall Purposit be liable to any User for
                            any loss, damage or liability resulting from the any
                            third-party payment processor(s) or any organisers
                            acts or omissions, including any negligent acts or
                            omissions; and
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(g)</p>
                          <p className="paragraph1">
                            unless otherwise expressly specified, all Fees are
                            exclusive of GST and any other applicable federal
                            and state taxes and duties (which must be paid by
                            You).
                            <br />
                          </p>
                        </span>
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">6.3</p>
                      <p className="paragraph">
                        Purposit (or its agents) may either:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            send you a receipt for any Contributions and/ or
                            Fees paid by You via email following receipt of the
                            Fees and/ or processing of the relevant
                            Contribution; and/ or
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            issue You with invoices for any other Fees not
                            already paid or satisfied in accordance with this
                            Agreement from time to time, which, unless specified
                            otherwise, must be paid within seven (7) days of the
                            date of invoice.
                            <br />
                          </p>
                        </span>
                      </p>
                    </span>
                    <br />
                  </td>
                </tr>
                <p className="bborderline"></p>
                <tr>
                  <td>
                    <h3>7. &emsp;&nbsp;Liability</h3>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">7.1</p>
                      <p className="paragraph">
                        To the maximum extent permitted by law, Purposit's
                        liability under this Agreement or under any guarantee,
                        condition or warranty (including, without limitation,
                        any guarantee, condition or warranty of merchantability,
                        acceptable quality, fitness for purpose or fitness for
                        disclosed result), or any other right or remedy, under
                        any legislation or implied into this Agreement by any
                        legislation (Statutory Warranties) is hereby excluded.
                        Where Purposit is liable under any Statutory Warranties,
                        and any legislation avoids or prohibits provisions in a
                        contract excluding or modifying the application of, or
                        exercise of, or liability under, such Statutory
                        Warranties, Purposit's liability for any breach of such
                        Statutory Warranties shall be limited, at Purposit's
                        option, refunding any Fees paid to Purposit by You
                        pursuant to this Agreement. You acknowledge and agree
                        that reliance by Purposit on this limitation of
                        liability is fair and reasonable in all the
                        circumstances.
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">7.2</p>
                      <p className="paragraph">
                        To the maximum extent permitted by law, Purposit
                        excludes all liability (whether arising in contract,
                        tort or otherwise) that it may have for any Loss arising
                        from:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            the use of, or reliance on, the Platform, including
                            an Organiser’s profile or any information provided
                            by an Organiser in relation to a proposed Gift;
                            <br />
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            the availability or security of the Platform or any
                            Content provided by Purposit or any third party
                            system which integrates, in any way, with the
                            Platform;
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(c)</p>
                          <p className="paragraph1">
                            quality, correctness, accuracy, completeness or
                            suitability of the Platform, Data or Content; or
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(d)</p>
                          <p className="paragraph1">
                            any action or inaction by a User or Purposit’s third
                            party payment provider(s), whether that Loss is
                            direct, indirect or consequential Loss (including
                            loss or corruption of data, loss of any agreement,
                            loss of any business revenue, loss of profits,
                            failure to realise expected profits or savings or
                            any other commercial loss or economic loss of any
                            kind).
                            <br />
                          </p>
                        </span>
                        <br />
                      </p>
                    </span>
                  </td>
                </tr>
                <p className="bborderline"></p>
                <tr>
                  <td>
                    <h3>8. &emsp;&nbsp;Indemnity</h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <br />
                    <span className="box" style={{ marginLeft: '20px' }}>
                      <p className="heading"></p>
                      <p className="paragraph">
                        You agree to indemnify, and keep indemnified, Purposit
                        and each of its officers, employees, agents and
                        contractors (Indemnified Persons) from and against any
                        Loss of any kind which those Indemnified Persons suffer
                        or incur arising out of or in connection with:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            a breach by You of any provision of this Agreement
                            (including, without limitation, any warranty given
                            under this Agreement);
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            any Claim against any Indemnified Person for which
                            liability is excluded under clause 7;
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(c)</p>
                          <p className="paragraph1">
                            any Claim against any Indemnified Person by any
                            third party relating to Your access to or use of the
                            Platform or Content; or
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(d)</p>
                          <p className="paragraph1">
                            any claim, action, demand, remedy, suit, proceeding
                            or right of action against an Indemnified Person by
                            You or third party relating to:
                            <br />
                            <br />
                            <span className="innerbox">
                              <p className="heading">(1)</p>
                              <p className="paragraph1">
                                an allegation or finding that any information
                                You provide or have include, uploaded, collected
                                or otherwise displayed on Your Account, profile
                                infringes the Intellectual Property Rights or
                                privacy rights of that or any other third party;
                                or
                                <br />
                                <br />
                              </p>
                            </span>
                            <span className="innerbox">
                              <p className="heading">(2)</p>
                              <p className="paragraph1">
                                an allegation or finding that any information
                                You provide or have include, uploaded, collected
                                or otherwise displayed on Your Account, profile
                                or any Data or Content is illegal, fraudulent,
                                inaccurate, incorrect, incomplete, misleading or
                                deceptive or likely to mislead or deceive.
                                <br />
                              </p>
                            </span>
                          </p>
                        </span>
                        <br />
                      </p>
                    </span>
                  </td>
                </tr>
                <p className="bborderline"></p>

                <tr>
                  <td>
                    <h3>
                      9. &emsp;&nbsp;Intellectual Property, privacy and
                      confidentiality
                    </h3>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">9.1</p>
                      <p className="paragraph">
                        You acknowledge and agree that all Intellectual Property
                        Rights in and relating to the Platform and the User
                        Documentation is owned by Purposit, its Associates or
                        licensors.
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">9.2</p>
                      <p className="paragraph">
                        Subject to the terms of this Agreement, Purposit grants
                        You, a non-exclusive and non-transferable, royalty-free
                        licence:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            to:
                            <br />
                            <br />
                            <span className="innerbox">
                              <p className="heading">(1)</p>
                              <p className="paragraph1">
                                access, store and process the Content on Your
                                Devices; and
                              </p>
                            </span>
                            <br />
                            <span className="innerbox">
                              <p className="heading">(2)</p>
                              <p className="paragraph1">
                                use and reproduce the Content for purposes
                                consistent with this Agreement in any medium or
                                format; and
                              </p>
                            </span>
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            to use the Platform for the duration of the Term.
                          </p>
                        </span>
                        <br />
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">9.3</p>
                      <p className="paragraph">
                        You hereby:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            unconditionally and irrevocably grant to Purposit, a
                            perpetual worldwide, royalty-free, non-exclusive
                            licence (including the right to sub-licence and
                            transfer those rights to third parties) to use,
                            reproduce, copy, store, modify, adapt, distribute,
                            publish and create derivate works from, and
                            otherwise exercise all Intellectual Property Rights
                            in, any Data or Content You provide or has been
                            included, uploaded or otherwise collected through,
                            Your use of the Platform for any purpose and in any
                            form, without compensation to You; and
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            irrevocably consent, and undertake to Purposit that
                            You have obtained the consent of any other person
                            necessary, for Purposit and its successors and
                            nominees, to do or omit to do anything which, but
                            for Your consent, would constitute a breach of Your
                            or any other person’s moral rights in any in any
                            Data or Content you provide
                            <br />
                          </p>
                        </span>
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">9.4</p>
                      <p className="paragraph">
                        Purposit’s commitment to privacy is set out in the
                        “Privacy Policy” available at &nbsp;
                        <a
                          href="https://app.purpositdev.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="linktext"
                        >
                          www.purposit.com.
                        </a>{' '}
                        &nbsp; You acknowledge that You have read and understood
                        the Privacy Policy and consent to the collection, use
                        and disclosure of personal information in accordance
                        with the Privacy Policy. You acknowledge and agree to
                        comply with all Applicable Laws in respect of privacy as
                        if You were subject to such Applicable Laws.
                        <br />
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">9.5</p>
                      <p className="paragraph">
                        You acknowledge and agree that:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            all information, Data and Content provided to us or
                            otherwise obtained through the provision of the
                            Platform, may be deleted from the Platform at the
                            end of the Term; and
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            it is solely Your responsibility to ensure that any
                            information, Data or Content that You require is
                            appropriately backed-up and/or replicated as You may
                            require, and that Purposit is not liable for any
                            Loss arising from, or as a result of, any loss of,
                            corruption to, or deletion from our Platform of any
                            information, Data or Content.
                            <br />
                          </p>
                        </span>
                      </p>
                    </span>
                    <br />
                  </td>
                </tr>
                <p className="bborderline"></p>

                <tr>
                  <td>
                    <h3>10. &emsp;&nbsp;Term and termination</h3>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">10.1</p>
                      <p className="paragraph">
                        This Agreement commences on and from the date that You
                        have been provided with an Account and continues until
                        terminated.
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">10.2</p>
                      <p className="paragraph">
                        Either party may terminate this Agreement by notice to
                        the other party in writing (including by way of email).
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">10.3</p>
                      <p className="paragraph">
                        Any termination of this Agreement will be without
                        prejudice to the rights of each party against the other
                        in respect of anything done or omitted to be done in
                        accordance with this Agreement prior to such termination
                        or expiration (as the case may be).
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">10.4</p>
                      <p className="paragraph">
                        Upon any termination of this Agreement for any reason:
                        <br />
                        <br />
                        <span className="innerbox">
                          <p className="heading">(a)</p>
                          <p className="paragraph1">
                            Your rights to access and use the Platform shall
                            cease;
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(b)</p>
                          <p className="paragraph1">
                            You acknowledge that all access, through the
                            Platform, to the Data or Content shall be suspended;
                            and
                            <br />
                          </p>
                        </span>
                        <br />
                        <span className="innerbox">
                          <p className="heading">(c)</p>
                          <p className="paragraph1">
                            You must immediately pay all Fees due or payable to
                            Purposit under this Agreement.
                            <br />
                          </p>
                        </span>
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">10.5</p>
                      <p className="paragraph">
                        The provisions of clauses 1, 7, 8, 9, 12 and 13 together
                        with this clause 10.5 and each other clause, which, by
                        its terms, is intended to survive termination, shall
                        survive termination of this Agreement.
                      </p>
                    </span>
                    <br />
                  </td>
                </tr>
                <p className="bborderline"></p>

                <tr>
                  <td>
                    <h3>11. &emsp;&nbsp;Variation</h3>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">11.1</p>
                      <p className="paragraph">
                        Purposit may vary the Agreement at any time by providing
                        You with not less than seven (7) days’ prior notice of
                        such variation, including by way electronic notification
                        via the Application or when accessing the Platform via
                        the Website.
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">11.2</p>
                      <p className="paragraph">
                        Upon the giving of a notice in accordance with clause
                        11.1, You may terminate this Agreement by notice in
                        writing to Purposit within seven (7) days of the date
                        that Purposit gives the notice.
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">11.3</p>
                      <p className="paragraph">
                        If You accept those changes following notification to
                        you, those changes will be effective immediately,
                        otherwise if you continue the use the Platform following
                        the effective date of the changes, You will be deemed to
                        have accepted the Agreement as varied.
                      </p>
                    </span>
                  </td>
                </tr>
                <p className="bborderline"></p>

                <tr>
                  <td>
                    <h3>12. &emsp;&nbsp;Notices</h3>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">12.1</p>
                      <p className="paragraph">
                        All notices and other communications in connection with
                        this Agreement Terms and Conditions, aside for a notice
                        under clause 11.1:
                        <br />
                        <br />
                        <span className="box">
                          <p className="heading">(a)</p>
                          <p className="paragraph">must be in writing; and</p>
                        </span>
                        <br />
                        <span className="box">
                          <p className="heading">(b)</p>
                          <p className="paragraph">
                            take effect from the time they are received unless a
                            later time is specified.
                          </p>
                        </span>
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">12.2</p>
                      <p className="paragraph">
                        Notices for You will be sent to the email address
                        specified in the information contained in your Account
                        (or such other address as You may notify Purposit of
                        from time to time). Notices for Purposit must be sent to
                        &nbsp;
                        <a
                          href="mailto:admin@purposit.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="linktext"
                        >
                          admin@purposit.com
                        </a>{' '}
                        &nbsp; or as otherwise notified to You from time to
                        time.
                      </p>
                    </span>
                  </td>
                </tr>
                <p className="bborderline"></p>
                <tr>
                  <td>
                    <h3>13. &emsp;&nbsp;General</h3>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="box">
                      <p className="heading">13.1</p>
                      <p className="paragraph">
                        This Agreement constitutes the entire agreement of the
                        parties concerning its subject matter and supersedes all
                        previous agreements, understandings and negotiations
                        concerning that subject matter.
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">13.2</p>
                      <p className="paragraph">
                        This Agreement is governed by the law in force in the
                        State of New South Wales. You submit to the
                        non-exclusive jurisdiction of the courts of New South
                        Wales and courts of appeal from them for determining any
                        dispute concerning this Agreement.
                      </p>
                    </span>
                    <br />
                    <span className="box">
                      <p className="heading">13.3</p>
                      <p className="paragraph">
                        If any provision of this Agreement is held to be
                        unlawful, invalid, unenforceable or in conflict with any
                        rule of law, statute, ordinance or regulation it must be
                        read down so that is valid and enforceable (or, if it
                        cannot be so read down, severed) so that the validity
                        and enforceability of the remaining provisions are not
                        affected.
                      </p>
                    </span>
                  </td>
                </tr>
                <p className="bborderline"></p>
                <tr>
                  <td>
                    <h3>14. &emsp;&nbsp;Definitions</h3>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        In these Terms and Conditions, the following words have
                        these meanings unless a contrary intention appears:
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Account</b> means Your personal profile through which
                        You can use the Platform.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Agreement</b> means, together, these Terms and
                        Conditions and the User Documentation.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>App</b> means any application that has been designed
                        for iOS and Android operating systems to be used by
                        Users to access the Platform.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Applicable Laws</b> means any and all statutes,
                        regulations, by-laws, ordinances or subordinate
                        legislation in force from time to time which pertains to
                        Your use of, and access to, the Platform and the
                        provision and/or receipt of Contributions and the
                        purchase of Gifts (as applicable) including, without
                        limitation, those relating to trade practices, fair
                        trading, intellectual property, privacy and
                        confidentiality.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Associates</b> has the meaning given to that term in
                        the Corporations Act 2001 (Cth).
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Claim</b> means any claim, action, demand, remedy,
                        suit, proceeding or right of action.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Contribution</b> means money given towards a Gift.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Contributor</b> means a person who has donated money
                        towards a Gift.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Data</b> means any data or information that You
                        manually input into or is otherwise recorded by the
                        Platform through Your use of the Platform.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Device</b> means any unit of equipment, fixed or
                        portable, capable of receiving and accessing the
                        Platform, whether through the App or otherwise.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Event</b> means a physical or online event created by
                        an Organiser and registered with the Platform.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Fees</b> means the fees payable to Purposit for the
                        provision of the Platform as published by it from time
                        to time.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Gift</b> means a physical gift or gifts and/ or
                        experience nominated by an Organiser to be provided to a
                        Recipient.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>GST</b> has the meaning given to that term in the A
                        New Tax System (Accessories and Services Tax) Act 1999
                        (Cth).
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Intellectual Property Rights </b>means any and all:
                        <br />
                        <br />
                        <span className="boxlast">
                          <p className="heading">(a)</p>
                          <p className="paragraph">
                            current and future registered and unregistered
                            rights in respect of copyright, designs,
                            semiconductor or circuit layouts, trade marks, trade
                            names, business names, trade secrets, know-how,
                            confidential information, patents, inventions and
                            discoveries;
                          </p>
                        </span>
                        <br />
                        <span className="boxlast">
                          <p className="heading">(b)</p>
                          <p className="paragraph">
                            applications for grant of any of the above;
                          </p>
                        </span>{' '}
                        <br />
                        <span className="boxlast">
                          <p className="heading">(c)</p>
                          <p className="paragraph">
                            other rights of a similar nature arising (or capable
                            of arising) under statute or at common law in the
                            Commonwealth of Australia or anywhere else in the
                            world; and
                          </p>
                        </span>{' '}
                        <br />
                        <span className="boxlast">
                          <p className="heading">(d)</p>
                          <p className="paragraph">
                            other intellectual property as defined in Article 2
                            of the Convention Establishing the World
                            Intellectual Property Organisation 1967.
                          </p>
                        </span>
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Invitation</b> means an invitation created by an
                        Organiser and sent to Invitees to attend (or be aware
                        of) an Event or Contribute to a Gift.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Invitee</b> means an existing or non User of the
                        Platform who will receive Invitations for an Event or to
                        Contribute to a Gift.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Loss</b> means loss, damage, cost, expense or
                        liability of any kind, including, without limitation,
                        liability to a third party, any loss of profits or
                        indirect, consequential, incidental, special exemplary
                        or punitive loss or damage.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Organiser</b> means a User who creates and/or manages
                        events and/or Gifts for a Recipient.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Purposit</b> means Purposit Australia Pty Ltd ACN 611
                        311 267 of 5 Keston Avenue, Mosman NSW 2088.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Recipient</b> means a person for whom an Organiser
                        has nominated to receive a Gift or for whom an Event has
                        been organised.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Platform</b> means the platform available through the
                        Website and the App provided by Purposit for the
                        creation of Event and Gift registries and the sharing of
                        information and media amongst social networks.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Purposit Services</b> means the provision of access
                        to the Platform for the creation of events and gift
                        registries for friends of nominated recipients to
                        facilitate contributions towards those gifts and the
                        sharing of information and media among those networks.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Term</b> means the period commencing on the date the
                        Agreement is formed in accordance with clause 1.2 and
                        continuing until terminated in accordance with this
                        Agreement.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>User</b> means a User of the platform.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>User Documentation</b> means any user manual, service
                        standards, fee schedule or other documentation relating
                        to the Platform as may be published by Purposit, from
                        time to time.
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>Website</b> means &nbsp;
                        <a
                          href="https://app.purpositdev.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="linktext"
                        >
                          www.purposit.com.
                        </a>{' '}
                        &nbsp;
                      </p>
                    </span>
                    <br />
                    <span className="boxlast">
                      <p className="heading"></p>
                      <p className="paragraph">
                        <b>You</b> means the person whose details are provided
                        to Purposit when registering to receive the Purposit
                        Services or any executors, administrators, successors,
                        permitted assigns of person authorised to use your
                        Account.
                      </p>
                    </span>
                    <br />
                  </td>
                </tr>
                <p className="bborderline"></p>
              </tbody>
            </table>
          </div>
        </GiftCard>
      </Container>
    </>
  );
}
