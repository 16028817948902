import styled from 'styled-components';

import { ReactComponent as OpenGiftSVG } from '~/assets/images/open-gift.svg';

export const OuterContainer = styled.div`
  background-color: #f6f6f6;
  min-height: calc(100vh - 230px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: calc(100% - 57px);
  }
`;

export const Container = styled.div`
  max-width: 720px;
  min-height: calc(100vh - 200px);
  margin: 40px auto;
  width: 90%;
  background: ${({ colors }) =>
    `linear-gradient(0deg, ${colors.end || '#ed1e79'}, ${
      colors.start || '#93278f'
    })`};
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    height: 100%;
  }

  div {
    max-width: 370px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 130px;
    }
  }

  svg {
    margin-bottom: 20px;
  }

  button {
    width: 100%;

    @media (min-width: 768px) {
      max-width: 270px;
    }
  }

  h2 {
    margin-bottom: 10px;
  }

  p {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const OpenGift = styled(OpenGiftSVG)`
  max-width: 96px;
  max-height: 120px;

  #contents > * {
    opacity: 0;
    animation: appear 2s ease-in-out infinite;
    animation-delay: 0.6s;

    &:nth-child(1) {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.5s;
    }
    &:nth-child(5) {
      animation-delay: 0.6s;
    }
    &:nth-child(6) {
      animation-delay: 0.7s;
    }
    &:nth-child(7) {
      animation-delay: 0.8s;
    }
    &:nth-child(8) {
      animation-delay: 0.9s;
    }
    &:nth-child(9) {
      animation-delay: 1s;
    }
    &:nth-child(10) {
      animation-delay: 1.1s;
    }
  }

  @keyframes appear {
    0% {
      transform: translate(0, 45%);
      opacity: 1;
    }
    80% {
      opacity: 1;
    }

    100% {
      transform: translate(0, -5%);
      opacity: 0;
    }
  }
`;
