import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import theme from '~/styles/themes/main';

export default function Button({
  text,
  backgroundColor,
  textColor,
  buttonFunction,
  className,
}) {
  return (
    <Container
      backgroundColor={backgroundColor}
      textColor={textColor}
      onClick={buttonFunction}
      className={className}
    >
      <span>{text}</span>
    </Container>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
  buttonFunction: PropTypes.func.isRequired,
};

Button.defaultProps = {
  backgroundColor: theme.colors.primary,
  textColor: theme.colors.secondary,
  className: '',
};
