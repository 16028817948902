import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IoIosCalendar } from 'react-icons/io';

import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import {
  Container,
  ImageWrapper,
  OtherButtonsWrapper,
  SingleGiftCard,
  DateContainer,
} from './styles';

function OneGift({ event, raised, value, handleInvitation }) {
  const { colors } = useContext(ThemeContext);
  const receiverId = useSelector((state) => state.receiverReducer.receiverId);
  const percentage = parseInt((raised / value) * 100, 10);
  const { title } = event.gift.length ? event.gift[0] : '';

  return (
    <>
      <SubHeader
        more
        backgroundColor={colors.red}
        backButtonFunction={() =>
          history.push(`/receiver-profile/${receiverId}`)
        }
      >
        <h2 className="hide-desktop">Event Details</h2>
        <h2 className="hide-mobile">{event.name}</h2>
        <div>
          <h1 className="hide-desktop">{event.name}</h1>
          <DateContainer>
            <IoIosCalendar />
            {event.date}
          </DateContainer>
        </div>
      </SubHeader>
      <Container>
        <ImageWrapper backgroundImage={event.background} />
        <div>
          <SingleGiftCard headerBackgroundColor={colors.red}>
            <div className="single-gift-header">
              <h1>{title}</h1>
            </div>
            <div>
              <div>
                <span>Amount to be raised:</span>
                <span>
                  <strong>${value}</strong>
                </span>
              </div>

              <div className="progress-bar">
                <LinearProgress variant="determinate" value={percentage} />
                <div>
                  <span>${raised}</span>
                  <span>{percentage}%</span>
                </div>
              </div>
            </div>
          </SingleGiftCard>

          <OtherButtonsWrapper>
            <Button
              text="View Invite"
              backgroundColor="#fff"
              textColor="#999"
              buttonFunction={() => history.push()}
            />
            <Button
              text="Memories"
              backgroundColor="#fff"
              textColor="#999"
              buttonFunction={() => history.push('/event-album/1')}
            />
          </OtherButtonsWrapper>
          <div className="row">
            <div className="contributors">
              <div className="avatar-row">
                {/* {event.contributors.avatars.slice(0, 4).map(avatar => (
                  <div className="image-wrapper" key={avatar.image}>
                    <Avatar image={avatar.image} size={40} />
                  </div>
                ))} */}
              </div>
              <Link to={`/event-contributors/${event.id}`}>
                <span>
                  {event ? event.contributors.total : ''} contributors
                </span>
              </Link>
            </div>
            <span className="raised">
              <strong>${raised}</strong> <small>Raised</small>
            </span>
          </div>
          <Button text="Invite Friends" buttonFunction={handleInvitation} />
        </div>
      </Container>
    </>
  );
}

OneGift.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    background: PropTypes.string,
    raised: PropTypes.number,
    gift: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      raised: PropTypes.number,
    }),
    contributors: PropTypes.shape({
      total: PropTypes.number,
      avatars: PropTypes.array,
    }),
    rsvps: PropTypes.shape({
      total: PropTypes.number,
      avatars: PropTypes.array,
    }),
  }).isRequired,
  handleInvitation: PropTypes.func.isRequired,
};

export default OneGift;
