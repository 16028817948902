import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  getAddBankAccount,
  getBankList,
  getCardDetailsByUserId,
  getUpdateBankAccount,
  getBankDetailsById,
} from '../../../api/bankAccountAPI';
import {
  addBankAccountDone,
  bankListByIdDone,
  cardDetailsByUserIdDone,
  updateBankAccountDone,
  bankDetailsByIdDone,
  errorBankAccount,
} from '../bankAccount/bankAccountAction';

export function* watchAddBankAccount({ receiverId, data }) {
  try {
    const result = yield call(getAddBankAccount, receiverId, data);
    if (result.status === 200) {
      yield put(addBankAccountDone(result));
    }
  } catch (err) {
    yield put(errorBankAccount(err));
    return err;
  }
}

export function* watchGetBankListById({ receiverId }) {
  try {
    const result = yield call(getBankList, receiverId);
    if (result.status === 200) {
      yield put(bankListByIdDone(result.data));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetBankDetailsById({ receiverId, accountId }) {
  try {
    const result = yield call(getBankDetailsById, receiverId, accountId);
    if (result.status === 200) {
      yield put(bankDetailsByIdDone(result.data));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetCardDetailsByUserId({ userId }) {
  try {
    const result = yield call(getCardDetailsByUserId, userId);
    if (result.status === 200) {
      yield put(cardDetailsByUserIdDone(result.data));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetUpdateBankAccount({ receiverId, data }) {
  try {
    const result = yield call(getUpdateBankAccount, receiverId, data);
    if (result.status === 200) {
      yield put(updateBankAccountDone(result));
    }
  } catch (err) {
    return err;
  }
}

export default all([
  takeLatest('ADD_BANK_ACCOUNT', watchAddBankAccount),
  takeLatest('BANK_LIST_BY_ID', watchGetBankListById),
  takeLatest('CARD_DETAILS_BY_USER_ID', watchGetCardDetailsByUserId),
  takeLatest('UPDATE_BANK_ACCOUNT', watchGetUpdateBankAccount),
  takeLatest('BANK_DETAILS_BY_ID', watchGetBankDetailsById),
]);
