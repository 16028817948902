import Contribution from '~/assets/images/notification-icon-1.svg';
import Milestone from '~/assets/images/notification-icon-2.svg';
import Target from '~/assets/images/notification-icon-3.svg';
import Almost from '~/assets/images/notification-icon-4.svg';
import WillAttend from '~/assets/images/notification-icon-5.svg';
import WontAttend from '~/assets/images/notification-icon-6.svg';
import TimeToInvite from '~/assets/images/notification-icon-7.svg';
import Celebration from '~/assets/images/notification-icon-8.svg';
import Gift from '~/assets/images/notification-icon-9.svg';
import LinkBank from '~/assets/images/notification-icon-10.svg';
import PartyComing from '~/assets/images/notification-icon-11.svg';
import EventNearEnd from '~/assets/images/notification-icon-12.svg';
import Steps from '~/assets/images/notification-icon-15.svg';
import Ready from '~/assets/images/notification-icon-16.svg';
import Cake from '~/assets/images/notification-icon-17.svg';
import Envelope from '~/assets/images/envelope-heart.svg';
import OpenGift from '~/assets/images/open-gift-2.svg';

const notificationType = [
  {
    id: 1,
    icon: Contribution,
    title: 'Paul has contributed',
    description: '$120 towards Eugene’s Mountain Bike gift...',
    path: '/contribution',
  },
  {
    id: 2,
    icon: Milestone,
    title: 'Super! Elizabeth’s',
    description: 'Europe Trip is already 50% funded.',
    path: '/gift-details',
  },
  {
    id: 3,
    icon: Target,
    title: 'Yeah! Eugene’s Mountain Bike',
    description: 'has reached your funding target',
    path: '/target-reached',
  },
  {
    id: 4,
    icon: Almost,
    title: 'So close! Eugene’s Birthday event',
    description: 'has passed but the gift is not fully funded yet.',
    path: '/almost-there',
  },
  {
    id: 5,
    icon: WillAttend,
    title: 'Paul will attend',
    description: 'Eugene’s Birthday Party',
    path: '/party-rsvp/1',
  },
  {
    id: 6,
    icon: WontAttend,
    title: 'Mario won’t  attend',
    description: 'Eugene’s Birthday Party',
    path: '/party-rsvp/2',
  },
  {
    id: 7,
    icon: TimeToInvite,
    title: 'Time to invite friends for',
    description: 'Eugene’s event coming soon',
    path: '/event-near',
  },
  {
    id: 8,
    icon: Celebration,
    title: 'Eugene’s celebration',
    description: 'is ready for you to review and share',
    path: '/event-details/1',
  },
  {
    id: 9,
    icon: Gift,
    title: 'Hooray! Eugene’s Mountain Bike',
    description: 'is fully funded.',
    path: '/gift-funded/',
  },
  {
    id: 10,
    icon: LinkBank,
    title: 'Link your bank account to receive',
    description: 'contributions for Eugene.',
    path: '/link-bank-notification',
  },
  {
    id: 11,
    icon: PartyComing,
    title: 'Eugene’s birthday',
    description: 'is coming. Time to organize a gift or party?',
    path: '/party-coming',
  },
  {
    id: 12,
    icon: EventNearEnd,
    title: 'Eugene’s birthday party',
    description: 'is tomorrow. How exciting!',
    path: '/event-near-end',
  },
  {
    id: 13,
    icon: Steps,
    title: 'Easy steps to create',
    description: 'Eugene’s first event',
    path: '/create-first-event',
  },
  {
    id: 14,
    icon: Ready,
    title: 'Ready to organize a gift or party',
    description: 'for a friend or family memeber? Lets do it!',
    path: '/onboarding',
    state: { fromNotification: true },
  },
  {
    id: 15,
    icon: Cake,
    title: 'Your birthday is coming up!',
    description: 'Time to organize a party or gift for you?',
    path: '/birthday-coming',
  },

  //add other who i follow notification
  {
    id: 16,
    icon: Envelope,
    title: 'You received a thank you card',
    description: 'from enguene',
    path: '/thank-you-cards',
  },
  {
    id: 17,
    icon: OpenGift,
    title: 'Jenny has invited you to',
    description: 'help fund a gift for enguene',
    path: '/event-detail',
  },
];

export const getNotificaiton = (id) => {
  return notificationType.find((item) => item.id === id);
};
