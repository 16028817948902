import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import people from './people';

import EditIndividual from './EditIndividual';
import EditMultiple from './EditMultiple';

export default function EditReceiver() {
  const { receiverId } = useParams();
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const screens = {
    individual: <EditIndividual receiverId={receiverId} />,
    multiple: <EditMultiple receiverId={receiverId} />,
  };

  return <>{screens[receiver.type]}</>;
}
