import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Action
import { createEvent, editEvent } from '../../store/modules/event/eventAction';

import { Mobile } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';
import Context from '~/context';

import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';

import placeholder from './placeholder';
import CircularIndeterminate from '~/components/Loader';

import {
  Container,
  InputWrapper,
  InputColumn,
  SelectControl,
  DatePickerStyled as DatePicker,
  TimePickerStyled as TimePicker,
  RadioControl,
  RadioButton,
  InputValidation,
  LoaderDiv,
  LoadingText,
} from './styles';
import { useLocation } from 'react-router-dom';
import {
  giftMultiRec,
  giftSingleRec,
  partyMultiRec,
  partySingleRec,
} from '~/api/eventAPI';

export default function EditEvent() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const eventReducer = useSelector((state) => state.eventReducer);
  const organiserName = useSelector(
    (state) => state.userReducer.profile.first_name,
  );
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const { event, eventEditAddOption, isEventEdit } = eventReducer;
  const location = useLocation();
  const eventData = location.state && location.state;
  const [formError, setFormError] = useState(null);
  const [formError1, setFormError1] = useState(null);
  const [loader, setLoader] = useState(false);

  const [relation, setRelation] = useState(
    eventEditAddOption ? event.data.invite_greeting : '',
  );
  const [date, setDate] = useState(
    eventEditAddOption
      ? new Date(event.data.date)
      : new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000),
  );
  const [rsvpDate, setRsvpDate] = useState(
    eventEditAddOption ? new Date(event.data.date) : new Date(),
  );

  const [startsAt, setStartsAt] = useState('');
  const [endsAt, setEndsAt] = useState(
    new Date(eventEditAddOption ? event.data.venue_end_date : ''),
  );
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const { first_name, id, group_name } =
    receiver || (eventData && eventData.receiverData);

  const { flow } = useContext(Context);
  const [venue, setVenue] = useState('no');
  const [rsvp, setRsvp] = useState('no');
  const [eventTitle, setEventTitle] = useState('');
  const [venueName, setVenueName] = useState(
    eventEditAddOption ? event.data.venue : '',
  );
  const [des, setDec] = useState('');
  const [description, setDescription] = useState('');
  const [selectUser, setSelectUser] = useState('');
  const [selectType, setSelectType] = useState('');

  const validateForm = () => {
    if (eventTitle === '') {
      setFormError(`validation failed: event title required!`);
      return 'validationFailed';
    }
    if (des === '') {
      setFormError(`validation failed: event invite required!`);
      return 'validationFailed';
    }
    if (relation === '') {
      setFormError(`validation failed: select invite greeting`);
      return 'validationFailed';
    }
  };

  const validateForm1 = () => {
    if (startsAt === '') {
      setFormError1(`validation failed: start time required!`);
      return 'validationFailed';
    }
    if (venueName === '') {
      setFormError1(`validation failed: venue required!`);
      return 'validationFailed';
    }
  };

  const handleVenueChange = (event) => {
    setVenue(event.target.value);
  };
  const handleRsvpChange = (event) => {
    setRsvp(event.target.value);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleRsvpDateChange = (newDate) => {
    setRsvpDate(newDate);
  };
  const handleStartsAt = (newDate) => {
    setStartsAt(newDate);
  };

  const handleEndsAt = (newDate) => {
    setEndsAt(newDate);
  };

  const handleRelationChange = (event) => {
    setRelation(event.target.value);
  };

  useEffect(() => {
    if (eventData.giftdata === 'null') {
    } else if (eventData.editgiftdata) {
      setEventTitle(eventData.editgiftdata.name);
      setDec(eventData.editgiftdata.description.replace(/\\n/g, '\n'));
      setDescription(eventData.editgiftdata.description);
      setStartsAt(
        new Date(
          eventEditAddOption ? eventData.editgiftdata.venue_start_date : '',
        ),
      );
      setLoader(false);
      setSelectUser('single');
      setSelectType('gift');
    } else {
      if (
        ((receiver && receiver.group_name === null) ||
          (eventData &&
            eventData.receiverData &&
            eventData.receiverData.group_name === null)) &&
        eventData.type === 'party' &&
        eventData.user === 'single'
      ) {
        getPartySingleRec();
      } else if (
        ((receiver && receiver.group_name === null) ||
          (eventData &&
            eventData.receiverData &&
            eventData.receiverData.group_name === null)) &&
        eventData.type === 'gift' &&
        eventData.user === 'single'
      ) {
        getSingleGiftRec();
      } else if (
        ((receiver && receiver.group_name !== null) ||
          (eventData &&
            eventData.receiverData &&
            eventData.receiverData.group_name !== null)) &&
        eventData.type === 'party' &&
        eventData.user === 'multiple'
      ) {
        getMultiPartyRec();
      } else if (
        ((receiver && receiver.group_name !== null) ||
          (eventData &&
            eventData.receiverData &&
            eventData.receiverData.group_name !== null)) &&
        eventData.type === 'gift' &&
        eventData.user === 'multiple'
      ) {
        getMultiGiftRec();
      } else if (
        ((receiver && receiver.group_name !== null) ||
          (eventData &&
            eventData.receiverData &&
            eventData.receiverData.group_name !== null)) &&
        eventData.type === 'party' &&
        eventData.user === 'single'
      ) {
        getPartySingleRec();
      } else if (
        ((receiver && receiver.group_name !== null) ||
          (eventData &&
            eventData.receiverData &&
            eventData.receiverData.group_name !== null)) &&
        eventData.type === 'gift' &&
        eventData.user === 'single'
      ) {
        getSingleGiftRec();
      }
    }
  }, []);

  const getSingleGiftRec = async () => {
    setLoader(true);
    await giftSingleRec(eventData.giftdata.filekey)
      .then((res) => {
        setEventTitle(
          res.title.replace('<RECEIVERS_NAME>', first_name || group_name),
        );
        setDec(
          res.body
            .replace(/<RECEIVERS_NAME>/g, first_name || group_name)
            .replace(/<ORGANISER_FIRST_NAME>/g, organiserName)
            .replace(/\\n/g, '\n')
            .replace(/<HERHIS>/g, first_name || group_name)
            .replace(/<HERHIM>/g, first_name || group_name)
            .replace(/<SHEHE>/g, first_name || group_name)
            .replace(/<GIFT_NAME>/g, eventData.giftdata.name)
            .replace(/<I_HAVE_REC_HAS>/g, 'null')
            .replace(/<IWE>/g, 'null')
            .replace(/<ME_RECNAME>/g, 'null')
            .replace(/<WE_RECEIVERS_NAME>/g, first_name || group_name)
            .replace(/<CONTRIBUTION_DATE>/g, 'null'),
        );
        setDescription(res.body);
        setLoader(false);
        setSelectUser('single');
        setSelectType('gift');
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getMultiGiftRec = async () => {
    setLoader(true);
    await giftMultiRec(eventData.giftdata.filekey)
      .then((res) => {
        setEventTitle(
          res.title.replace('<RECEIVERS_NAME>', first_name || group_name),
        );
        setDec(
          res.body
            .replace(/<RECEIVERS_NAME>/g, first_name || group_name)
            .replace(/<ORGANISER_FIRST_NAME>/g, organiserName)
            .replace(/\\n/g, '\n')
            .replace(/<HERHIS>/g, first_name || group_name)
            .replace(/<HERHIM>/g, first_name || group_name)
            .replace(/<SHEHE>/g, first_name || group_name)
            .replace(/<GIFT_NAME>/g, eventData.giftdata.name)
            .replace(/<I_HAVE_REC_HAS>/g, 'null')
            .replace(/<IWE>/g, 'null')
            .replace(/<ME_RECNAME>/g, 'null')
            .replace(/<WE_RECEIVERS_NAME>/g, first_name || group_name)
            .replace(/<CONTRIBUTION_DATE>/g, 'null'),
        );
        setDescription(res.body);
        setLoader(false);
        setSelectUser('multiple');
        setSelectType('gift');
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getMultiPartyRec = async () => {
    setLoader(true);
    await partyMultiRec(eventData.giftdata.filekey)
      .then((res) => {
        setEventTitle(
          res.title.replace('<RECEIVERS_NAME>', first_name || group_name),
        );
        setDec(
          res.body
            .replace(/<RECEIVERS_NAME>/g, first_name || group_name)
            .replace(/<ORGANISER_FIRST_NAME>/g, organiserName)
            .replace(/\\n/g, '\n')
            .replace(/<HERHIS>/g, first_name || group_name)
            .replace(/<HERHIM>/g, first_name || group_name)
            .replace(/<SHEHE>/g, first_name || group_name)
            .replace(/<GIFT_NAME>/g, eventData.giftdata.name)
            .replace(/<I_HAVE_REC_HAS>/g, 'null')
            .replace(/<IWE>/g, 'null')
            .replace(/<ME_RECNAME>/g, 'null')
            .replace(/<WE_RECEIVERS_NAME>/g, first_name || group_name)
            .replace(/<CONTRIBUTION_DATE>/g, 'null'),
        );
        setDescription(res.body);
        setLoader(false);
        setSelectUser('multiple');
        setSelectType('party');
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getPartySingleRec = async () => {
    setLoader(true);
    await partySingleRec(eventData.giftdata.filekey)
      .then((res) => {
        setEventTitle(
          res.title.replace('<RECEIVERS_NAME>', first_name || group_name),
        );
        setDec(
          res.body
            .replace(/<RECEIVERS_NAME>/g, first_name || group_name)
            .replace(/<ORGANISER_FIRST_NAME>/g, organiserName)
            .replace(/\\n/g, '\n')
            .replace(/<HERHIS>/g, first_name || group_name)
            .replace(/<HERHIM>/g, first_name || group_name)
            .replace(/<SHEHE>/g, first_name || group_name)
            .replace(/<GIFT_NAME>/g, eventData.giftdata.name)
            .replace(/<I_HAVE_REC_HAS>/g, 'null')
            .replace(/<IWE>/g, 'null')
            .replace(/<ME_RECNAME>/g, 'null')
            .replace(/<WE_RECEIVERS_NAME>/g, first_name || group_name)
            .replace(/<CONTRIBUTION_DATE>/g, 'null'),
        );
        setDescription(res.body);
        setLoader(false);
        setSelectUser('single');
        setSelectType('party');
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleSubmit = () => {
    const error = validateForm();
    if (venue !== 'no' && venueName === '') {
      const error1 = validateForm1();
      if (error1 !== 'validationFailed') {
      }
    } else {
      if (error !== 'validationFailed') {
        if (!eventEditAddOption) {
          setSubmitButtonLoading(true);

          const data = {
            title: eventTitle,
            description: des,
            event_date: date,
            venue_start_date: startsAt === '' ? null : startsAt,
            venue_end_date: endsAt,
            venue: venue === 'yes' ? venueName : venue,
            child_id: id,
            invite_greeting: relation,
            type: 'onegift',
            rspv_date: rsvp === 'yes' ? rsvpDate : null,
          };
          Dispatch(createEvent(data));
          setSubmitButtonLoading(false);
          history.push('/invite-design', {
            eventData: data,
            imageKey: eventData.giftdata.imagekey,
            description: {
              des: description || des,
              name: first_name || group_name,
              giftName: eventData.giftdata.name,
              organiser: organiserName,
            },
            selectedType: selectType || eventData.type,
            selectedUser: selectUser || eventData.user,
            receiverData: eventData && eventData.receiverData,
          });
        }
        if (eventEditAddOption) {
          setSubmitButtonLoading(true);
          if (eventData.editgiftdata) {
            const data = {
              title: eventTitle,
              description: des,
              event_date: date,
              child_id: id,
              invite_greeting: relation,
              type: event.data.type,
            };
            Dispatch(editEvent(event.data.id, data));
            setSubmitButtonLoading(false);
          } else {
            const data = {
              title: eventTitle,
              description: des,
              event_date: date,
              venue_start_date: startsAt,
              venue_end_date: endsAt,
              venue: venue === 'yes' ? venueName : venue,
              child_id: id,
              invite_greeting: relation,
              type: event.data.type,
              rspv_date: rsvp === 'yes' ? rsvpDate : null,
            };

            Dispatch(editEvent(event.data.id, data));
            setSubmitButtonLoading(false);
          }
        }
      }
    }
  };

  if (isEventEdit) {
    if (eventData.editgiftdata) {
      const data = {
        title: eventTitle,
        description: des,
        event_date: date,
        venue_start_date: startsAt,
        venue_end_date: endsAt,
        venue: venue === 'yes' ? venueName : venue,
        child_id: id,
        invite_greeting: relation,
        type: event.data.type,
        rspv_date: rsvp === 'yes' ? rsvpDate : null,
      };
      history.push('/invite-designs', {
        eventData: data,
        imageKey: eventData.editgiftdata.eventImage,
        description: {
          des: des || description,
          name: first_name || group_name,
          giftName: eventData.editgiftdata.name,
          organiser: organiserName,
        },
        selectedType: selectType || eventData.type,
        selectedUser: selectUser || eventData.user,
        receiverData: eventData && eventData.receiverData,
      });
    } else {
      history.push(`/event-details/${event.data.id}`);
    }
  }

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
    if (formError1 !== null) {
      setFormError1(null);
    }
  }, 3000);

  return (
    <>
      <SubHeader
        backgroundColor={colors.lightPurple}
        buttonText="Next"
        buttonFunction={() => handleSubmit()}
        submitButtonLoading={submitButtonLoading}
      >
        {eventData.type === 'party' ? (
          <>
            <h2>EDIT EVENT</h2>
            <h3>
              Enter or edit the details of {first_name || group_name}'s event
            </h3>
          </>
        ) : (
          <>
            <h2>EDIT GIFT INVITE</h2>
            <h3>
              Enter the details of {first_name || group_name}'s gift invite.
            </h3>
          </>
        )}
      </SubHeader>
      <InputValidation>
        {formError}
        {formError1}
      </InputValidation>
      <Container>
        {!loader ? (
          <>
            <form>
              <InputColumn>
                <InputWrapper>
                  <label htmlFor="choose-greeting">Invite Greeting</label>
                  <SelectControl variant="filled">
                    <InputLabel
                      id="choose-greeting"
                      disableAnimation
                      shrink={false}
                    >
                      Choose a greeting
                    </InputLabel>
                    <Select
                      labelId="choose-greeting"
                      id="relation"
                      value={relation}
                      onChange={handleRelationChange}
                    >
                      <MenuItem value="Family">
                        Dear Family and Friends
                      </MenuItem>
                      <MenuItem value="Friends">Dear Friends</MenuItem>
                      <MenuItem value="NoGreeting">No Greeting</MenuItem>
                    </Select>
                  </SelectControl>
                </InputWrapper>
                <InputWrapper>
                  <label htmlFor="gift-description">EVENT INVITE</label>
                  <textarea
                    rows={4}
                    name="gift-description"
                    placeholder={placeholder}
                    value={des}
                    onChange={(e) => setDec(e.target.value)}
                    // onFocus={(e) => (e.target.replacedText = '')}
                    // onBlur={(e) => (e.target.replacedText = replacedText)}
                  />
                </InputWrapper>
              </InputColumn>
              <InputColumn>
                <InputWrapper>
                  <Input
                    name="event-tile"
                    label="Event Title"
                    placeholder="Eg: Surfing lessons..."
                    value={eventTitle}
                    onChange={(e) => setEventTitle(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper>
                  <DatePicker
                    label={
                      !flow.giftOption.includes('multiple-gifts')
                        ? 'DATE ( GIFT CONTRIBUTIONS BY )'
                        : 'Date of Event'
                    }
                    name="gift-expected"
                    date={date}
                    onChange={handleDateChange}
                    minDate={
                      new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000)
                    }
                  />
                </InputWrapper>
                {(flow.giftOption &&
                  !flow.giftOption.includes('multiple-gifts')) || (
                  <>
                    {eventData.editgiftdata ? (
                      ''
                    ) : (
                      <InputWrapper>
                        <RadioControl component="fieldset">
                          <label>Is there a venue for this event?</label>
                          <RadioGroup
                            aria-label="venue"
                            name="venue"
                            value={venue}
                            onChange={handleVenueChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<RadioButton />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<RadioButton />}
                              label="No"
                            />
                          </RadioGroup>
                        </RadioControl>
                      </InputWrapper>
                    )}
                    {eventData.editgiftdata
                      ? ''
                      : venue !== 'no' && (
                          <>
                            <InputWrapper className="time-picker">
                              <InputColumn>
                                <TimePicker
                                  label="Starts at"
                                  name="Starts At"
                                  date={startsAt}
                                  onChange={handleStartsAt}
                                  minDate={new Date()}
                                />
                              </InputColumn>
                              <InputColumn>
                                <TimePicker
                                  label="Ends at (Optional)"
                                  name="ends-at"
                                  date={endsAt}
                                  onChange={handleEndsAt}
                                  minDate={new Date()}
                                />
                              </InputColumn>
                            </InputWrapper>
                            <InputWrapper>
                              <Input
                                label="Venue"
                                placeholder="Add venue here"
                                value={venueName}
                                onChange={(e) => setVenueName(e.target.value)}
                              />
                            </InputWrapper>
                            <InputWrapper>
                              <RadioControl component="fieldset">
                                <label>RSVP Required?</label>
                                <RadioGroup
                                  aria-label="rsvp"
                                  name="rsvp"
                                  value={rsvp}
                                  onChange={handleRsvpChange}
                                >
                                  <FormControlLabel
                                    value="yes"
                                    control={<RadioButton />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<RadioButton />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </RadioControl>
                            </InputWrapper>
                            {rsvp !== 'no' && (
                              <>
                                <InputWrapper>
                                  <DatePicker
                                    label="RSVP DATE"
                                    name="gift-expected"
                                    date={rsvpDate}
                                    onChange={handleRsvpDateChange}
                                    minDate={new Date()}
                                  />
                                </InputWrapper>
                              </>
                            )}
                          </>
                        )}
                  </>
                )}
              </InputColumn>
            </form>
            <Mobile>
              <Button
                text="Save"
                textColor="#ffffff"
                backgroundColor={colors.purple}
                buttonFunction={() => handleSubmit()}
              />
            </Mobile>
          </>
        ) : (
          <LoaderDiv>
            <CircularIndeterminate />
            <LoadingText>Loading...</LoadingText>
          </LoaderDiv>
        )}
      </Container>
    </>
  );
}
