import styled from 'styled-components';

import { ReactComponent as BalloonsSVG } from '~/assets/images/balloons.svg';

export const OuterContainer = styled.div`
  background-color: #f6f6f6;
  min-height: calc(100% - 90px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  max-width: 720px;
  min-height: calc(100vh - 200px);
  margin: 40px auto;
  width: 90%;
  background: ${({ colors }) =>
    `linear-gradient(0deg, ${colors.end || '#ed1e79'}, ${
      colors.start || '#93278f'
    })`};
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    height: 100%;
  }

  div {
    max-width: 370px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 130px;
    }
  }

  svg {
    margin-bottom: 30px;
  }

  h1 {
    margin-bottom: 20px;
  }

  button {
    margin-top: 40px;
  }
`;

export const Balloons = styled(BalloonsSVG)`
  max-width: 140px;
  animation: upDownBlue 8s infinite;

  #red-baloon {
    transform: translateX(50px);
    animation: upDownRed 8s infinite;
  }

  #blue-baloon {
    animation: upDownBlue 12s infinite;
  }

  #laces > g {
    animation: rotate 8s infinite;
  }

  #details > * {
    animation: fadeInOut 4s infinite;

    &:nth-child(1) {
      animation-delay: 1s;
    }
    &:nth-child(3) {
      animation-delay: 3s;
    }
  }

  @keyframes upDownRed {
    0% {
      transform: translate(50px, 0);
    }
    50% {
      transform: translate(50px, 20px);
    }
    100% {
      transform: translate(50px, 0);
    }
  }

  @keyframes upDownBlue {
    0% {
      transform: translate(0, 28px);
    }
    50% {
      transform: translate(0, 38px);
    }
    100% {
      transform: translate(0, 28px);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(8deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes fadeInOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
