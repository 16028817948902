import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import history from '~/services/history';
import HideFooter from '~/utils/HideFooter';
import { Mobile, Default } from '~/config/ReactResponsiveConfig';

import Button from '~/components/Button';
import SubHeader from '~/components/SubHeader';

import { OuterContainer, Container, SuccessCheckSVG } from './styles';

export default function GiftSaved() {
  const theme = useContext(ThemeContext);
  const colors = { start: theme.colors.orange, end: theme.colors.lightPurple };
  const receiver = useSelector((state) => state.receiverReducer);

  return (
    <>
      <HideFooter />
      <Mobile>
        <SubHeader
          buttonText="Later"
          backgroundColor={theme.colors.orange}
          buttonFunction={() =>
            history.push(`/receiver-profile/${receiver.id}`)
          }
          hideBackButton
          mobileDescVisible={false}
        />
      </Mobile>
      <OuterContainer>
        <Container colors={colors}>
          <div>
            <SuccessCheckSVG />
            <h1>Gift Saved</h1>
            <h3>
              Are you ready to create an invite so friends can contribute to
              {receiver.first_name} gift?
            </h3>
          </div>
          <div className="button-wrapper">
            <Button
              backgroundColor="#ffffff70"
              textColor="#fff"
              text="YES, Let's do it"
              buttonFunction={() => history.push('/event-goal')}
            />
          </div>
          <Default>
            <Link to={`/receiver-profile/${receiver.id}`}>Later</Link>
          </Default>
        </Container>
      </OuterContainer>
    </>
  );
}
