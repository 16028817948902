import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useSelector, useDispatch } from 'react-redux';
import { createGift, updateGift } from '~/store/modules/gift/giftAction';

import Context from '~/context';
import { Mobile } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import {
  Container,
  InputWrapper,
  InputColumn,
  RadioButton,
  RadioControl,
  InputValidation,
} from './styles';
import { useLocation } from 'react-router-dom';

export default function EditEvent() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { flow } = useContext(Context);
  const [additionalInstruction, setAdditionalInstruction] = useState('');
  const [minContributionValue, setminContributionValue] = useState(null);
  const [setContributionValue, setSetContributionValue] = useState(null);
  const [fundingLimitValue, setFundingLimitValue] = useState('stop-at-100');
  const [contributionInstructions, setContributionInstructions] =
    useState('invitees-decide');
  const [contributionParams, setContributionParams] =
    useState('min-contribution');
  const GiftData = useSelector((state) => state.giftReducer.gift);
  const Giftimage = useSelector((state) => state.giftReducer.imagee);
  const GiftId = useSelector((state) => state.giftReducer.createdGiftId);
  const GiftCreated = useSelector((state) => state.giftReducer.giftCreated);
  const [formError, setFormError] = useState(null);
  const location = useLocation();
  const listCheck = location.state && location.state;

  const handleFundingLimit = (event) => {
    setFundingLimitValue(event.target.value);
  };

  const handleContributionInstructions = (event) => {
    setContributionInstructions(event.target.value);
  };
  const checkedOnboarding = JSON.parse(localStorage.getItem('flow'));

  const handleContributionParams = (event) => {
    setContributionParams(event.target.value);
  };
  const handleAdditionalInstruction = (event) => {
    setAdditionalInstruction(event.target.value);
  };

  const handleContributionValue = (event) => {
    if (contributionParams === 'min-contribution') {
      setminContributionValue(event.target.value);
      setSetContributionValue(null);
    } else {
      setSetContributionValue(event.target.value);
      setminContributionValue(null);
    }
  };

  const handleSubmit = () => {
    const data = {
      additional_instruction: additionalInstruction
        ? additionalInstruction
        : 'null',
      minimum_contribution_amount: minContributionValue,
      set_contribution_amount: setContributionValue,
      contribution_instruction: contributionInstructions,
      contribution_params: contributionParams,
      funding_limit: fundingLimitValue,
      // ...GiftData,
    };
    Dispatch(updateGift(GiftId, data));

    setTimeout(() => {
      listCheck && listCheck.list === 'list'
        ? history.push('/choose-gifts')
        : history.push('/added-gifts');
    }, 1000);
  };
  if (GiftCreated) {
    const path = GiftId
      ? {
          pathname: '/success',
          state: {
            destination: `/gift-details/${GiftId}`,
            message: 'Gift Updated.',
            colors: { start: colors.lightPurple, end: colors.orange },
          },
        }
      : '/manage-cards';
    // if (checkedOnboarding.onboarding === false) {
    //   history.push(
    //     checkedOnboarding.onboarding === false ? '/added-gifts' : path,
    //   );
    // } else {
    //   history.push(flow.onboarding ? '/onboarding-success/2' : path);
    // }
  }

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 2000);

  return (
    <>
      <SubHeader
        backgroundColor={GiftId ? colors.orange : colors.lightPurple}
        buttonText="Save"
        buttonFunction={() => handleSubmit()}
        hideBackButton
      >
        <h2>Gift Instruction</h2>
        <h3>Choose how you want the gift to be funded</h3>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        <form>
          <InputColumn>
            <InputWrapper>
              <div>
                <label>Funding Limit</label>
              </div>
              <div className="contribution-options">
                <RadioControl component="fieldset">
                  <RadioGroup
                    aria-label="funding-limit"
                    name="funding-limit"
                    value={fundingLimitValue}
                    onChange={handleFundingLimit}
                  >
                    <FormControlLabel
                      value="stop-at-100"
                      control={<RadioButton />}
                      label="Stop contributions once gift is 100% funded"
                    />
                    <FormControlLabel
                      value="allow-over-100"
                      control={<RadioButton />}
                      label="Allow contributions over 100% funding"
                    />
                  </RadioGroup>
                </RadioControl>
              </div>
            </InputWrapper>
            <InputWrapper>
              <div>
                <label>Contribution Instructions</label>
              </div>

              <div>
                <div className="contribution-options">
                  <RadioControl component="fieldset">
                    <RadioGroup
                      aria-label="contribution-instructions"
                      name="contribution-instructions"
                      value={contributionInstructions}
                      onChange={handleContributionInstructions}
                    >
                      <FormControlLabel
                        value="invitees-decide"
                        control={<RadioButton />}
                        label="Each invitee can decide how much to give"
                      />
                      <FormControlLabel
                        value="set-params"
                        control={<RadioButton />}
                        label="I want to set parameters for contributions"
                      />
                    </RadioGroup>
                  </RadioControl>
                  {contributionInstructions === 'set-params' && (
                    <div className="contribution-params">
                      <RadioControl component="fieldset">
                        <RadioGroup
                          aria-label="contribution-params"
                          name="contribution-params"
                          value={contributionParams}
                          onChange={handleContributionParams}
                        >
                          <div>
                            <FormControlLabel
                              value="min-contribution"
                              control={<RadioButton />}
                              label="Minimum contribution"
                            />
                            <input
                              value={
                                contributionParams === 'min-contribution'
                                  ? minContributionValue
                                  : ''
                              }
                              disabled={
                                contributionParams !== 'min-contribution'
                              }
                              onChange={handleContributionValue}
                              placeholder="$0"
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              value="set-contribution"
                              control={<RadioButton />}
                              label="Set contribution"
                            />
                            <input
                              value={
                                contributionParams === 'set-contribution'
                                  ? setContributionValue
                                  : ''
                              }
                              disabled={
                                contributionParams !== 'set-contribution'
                              }
                              onChange={handleContributionValue}
                              placeholder="$0"
                            />
                          </div>
                        </RadioGroup>
                      </RadioControl>
                    </div>
                  )}
                </div>
              </div>
            </InputWrapper>
          </InputColumn>
          <InputColumn>
            <InputWrapper>
              <label htmlFor="additional-instructions">
                Additional Instructions
              </label>
              <textarea
                value={additionalInstruction}
                rows={4}
                onChange={handleAdditionalInstruction}
                name="additional-instructions"
                placeholder="Add any additional instructions for your invitees..."
              />
            </InputWrapper>
          </InputColumn>
        </form>
        <Mobile>
          <Button
            text="Next"
            textColor="#ffffff"
            backgroundColor={colors.purple}
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
    </>
  );
}
