import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import { Mobile } from '~/config/ReactResponsiveConfig';

import Gifts from './Gifts';
import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import { Container, TotalContainer, OutterWrapper } from './styles';

export default function ContributionGiftList() {
  const { colors } = useContext(ThemeContext);
  const [total, setTotal] = useState('0');
 
  const handleSubmit = () => {
    history.push({ pathname: '/card-payment', state: { subtotal: total } });
  };

  return (
    <>
      <SubHeader
        mobileNavButtons={false}
        backgroundColor={colors.lightPurple}
        buttonText="Next"
        buttonBackgroundColor="#D28DC9"
        buttonTextColor="#fff"
        buttonFunction={() => handleSubmit()}
      >
        <h2>Contribution</h2>
        <h3>
          Great. Let’s enter the amount you wish to contribute towards Eugene’s
          gift(s).
        </h3>
      </SubHeader>
      <OutterWrapper>
        <Mobile>
          <Button text="Next" buttonFunction={() => handleSubmit()} />
        </Mobile>
        <TotalContainer>
          <div>
            <div>
              <h2>TOTAL AMOUNT CONTRIBUTED</h2>
            </div>
            <div>
              <span>{`$${total}`}</span>
            </div>
          </div>
          <p>
            No one can see how much you are contributing except the organiser of
            the event
          </p>
        </TotalContainer>
        <Container>
          <Gifts setTotal={setTotal} />
        </Container>
      </OutterWrapper>
    </>
  );
}
