import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { IoIosMail, IoIosLock } from 'react-icons/io';
import history from '~/services/history';

import { signInRequest } from '~/store/modules/auth/authAction';
import { fetchUserByEmail } from '~/store/modules/user/userAction';
import SocialLogin from '~/components/SocialLogin';

import {
  TopWrapper,
  InputWrapper,
  ContainerFooter,
} from '~/pages/_layout/auth/styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insert a valid email.')
    .required('A valid email is required.'),
  password: Yup.string().required('Please, insert your password.'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const {
    loading,
    token,
    signed,
    email: LoginEmail,
  } = useSelector((state) => state.authReducer);
  const [isFetchByEmail, setIsFetchByEmail] = useState(false);

  const profile = useSelector((state) => state.userReducer.profile);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password, 'NULL'));
  }

  useEffect(() => {
    if (token && signed && !isFetchByEmail) {
      dispatch(fetchUserByEmail(LoginEmail));
      setIsFetchByEmail(true);
    }
  });
  useEffect(() => {
    if (profile && isFetchByEmail) {
      history.push('/welcome');
    }
  });

  return (
    <>
      <TopWrapper>
        <h2>Log In</h2>
        <Form schema={schema} onSubmit={handleSubmit}>
          <InputWrapper>
            <IoIosMail size={25} color="#a6cce2" />
            <Input name="email" type="email" placeholder="Email" />
          </InputWrapper>
          <InputWrapper>
            <IoIosLock size={25} color="#a6cce2" />
            <Input name="password" type="password" placeholder="Password" />
          </InputWrapper>
          <div className="forgotpassword">
            <a href="/forgot-password" className="forgotancher">
              Forgot your password?
            </a>
          </div>
          <button type="submit">{loading ? 'Loading...' : 'Login'}</button>
        </Form>
        <SocialLogin />
      </TopWrapper>

      <ContainerFooter>
        <Link to="/register">
          Don&apos;t have an account? <strong>SIGN UP</strong>
        </Link>
      </ContainerFooter>
    </>
  );
}
