import React, { useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import history from '~/services/history';
import { Default } from '~/config/ReactResponsiveConfig';
import GiftImage from '~/assets/images/bike-gift.jpg';

import Button from '~/components/Button';
import {
  Container,
  GiftCard,
  TotalContainer,
  TotalContainer2,
  ErrorContainer,
  Container2,
} from './styles';

import giftsList from './gifts';
import CircularIndeterminate from '~/components/Loader';
import { getEventGiftByOneOrMoreId } from '~/api/eventAPI';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getContribution, getContributionMultiple } from '~/api/giftAPI';

var raised = 0;
var percentages = 0;
var funded = 0;
export default function Gifts({
  setTotal,
  giftId,
  total,
  setBothErrorMessage,
  bothErrorMessage,
  setErrorMessage,
  errorMessage,
  setRemainingAmount,
  remainingAmount,
  setContriAmount,
  contriAmount,
  selectedGifts,
  setSelectedGifts,
  setChecked,
  check,
}) {
  const event = useParams();
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const user_id = queryParams.get('u');
  const [loader, setLoader] = useState(false);
  const [valid, setValid] = useState('');
  const [giftLength, setGiftLength] = useState('');
  const [selectedId, setSelectedId] = useState([null]);
  // const checked = user_id && user_id.split('?checked=');
  const eventName = user_id && user_id.split('?n=');
  const getCheck = localStorage.getItem('rspv_data');
  const splitByQuestionMark = eventName && eventName[1].split('?');
  const eventName1 = splitByQuestionMark && splitByQuestionMark[0];
  const getFollow = localStorage.getItem('follow');

  const [gifts, setGifts] = useState(
    giftsList
      .map((gift) => ({ ...gift, contribution: '' }))
      .filter((gift) => gift.id === parseInt(giftId, 10)),
  );

  const handleSum = (newGifts) => {
    let contributionArray = [];
    let newTotal = 0;
    newGifts.forEach((gift) => {
      if (gift.contribution) {
        contributionArray.push(gift.contribution);
      }
      newTotal += !gift.contribution ? 0 : parseInt(gift.contribution);
    });
    let commaSeparatedContributions = contributionArray.join(',');
    setTotal(newTotal);
    setContriAmount(commaSeparatedContributions);
  };

  const getGift = async (giftId) => {
    setLoader(true);
    await giftPercentage();
    const giftIdArray = giftId && giftId.split(',');
    if (giftIdArray.length === 1) {
      await getEventGiftByOneOrMoreId(giftIdArray[0])
        .then((res) => {
          setGifts(res);
          setLoader(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    } else if (giftIdArray.length > 1) {
      const giftIdsString = giftIdArray.join(',');
      await getEventGiftByOneOrMoreId(giftIdsString)
        .then((res) => {
          setGifts(res);
          setLoader(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    } else {
    }
  };
  const validatePrice = (inputValue, price, id) => {
    const maxPrice = parseInt(price); // Maximum allowed price (adjust as needed)

    gifts.map((gift) => {
      if (gift.id === id) {
        const inputPrice = parseFloat(inputValue);
        if (inputPrice > remainingAmount) {
          setErrorMessage(`Amount cannot exceed $${remainingAmount}.`);
        } else if (inputPrice === 0) {
          setErrorMessage(`Amount cannot be accept $${inputPrice}.`);
          // setBothErrorMessage(`Both amount field is required.`);
        } else {
          setErrorMessage('');
        }
      } else {
      }
    });
  };
  const validateMultiplePrice = (inputValue, price, id) => {
    gifts.map((gift, i) => {
      if (gift.id === id) {
        const maxPrice = parseInt(price) - parseInt(raised[i]); // Maximum allowed price (adjust as needed)
        const inputPrice = parseFloat(inputValue);
        if (inputPrice > maxPrice) {
          setErrorMessage(`Amount cannot exceed $${maxPrice}.`);
          setValid(id);
          setGiftLength(gifts.length);
          // setBothErrorMessage(`Both amount field is required.`);
        } else if (inputPrice === 0) {
          setErrorMessage(`Amount cannot be accept $${inputPrice}.`);
          setValid(id);
          setGiftLength(gifts.length);
          // setBothErrorMessage(`Both amount field is required.`);
        } else {
          setErrorMessage('');
        }
      } else {
      }
    });
  };

  useEffect(() => {
    getGift(giftId);
    // setChecked(checked ? checked : '');
  }, [giftId]);

  const handleContribution = async (id, contribution) => {
    const newGifts = gifts.map((gift) => {
      if (gift.id === id) {
        return { ...gift, contribution };
      } else {
      }
      return gift;
    });
    setSelectedId(id);
    setGifts(newGifts);
    handleSum(newGifts);
    collectObject(newGifts, id, contribution);
  };

  const collectObject = (newGifts, id, contribution) => {
    const dataObject = {
      user_id: parseInt(user_id),
      gift_id: id,
      event_id: parseInt(event.giftId),
      contriAmount: contribution,
    };

    if (!isNaN(contribution)) {
      // Add or update the object in selectedGifts
      setSelectedGifts((prevSelectedGifts) => {
        const existingIndex = prevSelectedGifts.findIndex(
          (selectedGift) => selectedGift.gift_id === id,
        );
        if (existingIndex !== -1) {
          // Update the existing object
          prevSelectedGifts[existingIndex] = dataObject;
        } else {
          // Add the new object
          prevSelectedGifts.push(dataObject);
        }
        return [...prevSelectedGifts];
      });
      // }
    } else {
      // If id matches the currently selected gift, you can remove it here
      if (id === dataObject.gift_id) {
        setSelectedGifts((prevSelectedGifts) =>
          prevSelectedGifts.filter(
            (selectedGift) => selectedGift.gift_id !== id,
          ),
        );
      }
    }
  };
  const giftPercentage = async () => {
    const inputString = giftId;
    const idArray = inputString && inputString.split(',');
    if (idArray.length === 1) {
      await getContribution(giftId, user_id)
        .then((res) => {
          const contributions = res.data.contribution
            .map((cont) => parseInt(cont.contribution))
            .reduce((a, b) => a + b, 0);
          raised = contributions;
          funded = res.data.gift_price;
          percentages = Number(
            ((contributions / parseInt(res.data.gift_price)) * 100).toFixed(2),
          );
          setRemainingAmount(res.data.gift_price - contributions);
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else if (idArray.length > 1) {
      await getContributionMultiple(giftId, user_id)
        .then((res) => {
          const contributions = res.data.map((cont) => {
            // Assuming cont.contribution is an array of contribution values
            return cont.contribution.map((ea) => ea.contribution);
          });
          const sums = contributions.map((ea) => {
            const sum = ea.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            return sum.toFixed(2); // Round the sum to 2 decimal places
          });
          raised = sums;
          const giftPrices = res.data.map((cont) =>
            parseFloat(cont.gift_price),
          );
          percentages = sums.map((sum, index) => {
            return Number(
              ((parseFloat(sum) / giftPrices[index]) * 100).toFixed(2),
            );
          });
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const handleSubmit = () => {
    eventName1 && localStorage.setItem('event_name', eventName1);
    if (total === '0') {
      toast.error('amount is required');
    } else if (total !== '0' && total !== 0 && errorMessage === '') {
      if (getCheck && getCheck === 'party_with_venue_attend') {
      } else if (getCheck && getCheck === 'party_with_venue_not_attend') {
      } else {
        localStorage.removeItem('rspv_data');
        localStorage.removeItem('event_name');
        localStorage.setItem('rspv_data', 'party_with_venue_norspv');
      }
      history.push({
        pathname: '/card-payment',
        state: {
          subtotal: total,
          selectedGifts: selectedGifts,
          // check: checked ? checked[1] : '',
        },
      });
    } else {
    }
  };

  return (
    <>
      {!loader ? (
        <>
          {gifts && gifts.length === 1 ? (
            <Container>
              {gifts &&
                gifts.map((gift) => (
                  <GiftCard key={gift.id}>
                    <div>
                      <img
                        src={
                          gift.gift_images.length !== 0
                            ? gift.gift_images[0].url
                            : GiftImage
                        }
                        alt={gift.title}
                      />
                      <div>
                        <div>
                          <div>
                            <h3>{gift.title}</h3>
                            <small>{gift.date}</small>
                          </div>
                          {getFollow && getFollow !== null ? (
                            ''
                          ) : (
                            <div>
                              <span className="gift-price">${gift.cost}</span>
                            </div>
                          )}
                        </div>
                        <div>
                          <p>{gift.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="progress-bar">
                      <LinearProgress
                        variant="determinate"
                        value={gift ? percentages : ''}
                      />
                      <div>
                        {getFollow && getFollow !== null ? (
                          <span></span>
                        ) : (
                          <span>${raised}</span>
                        )}
                        <span>{gift ? percentages : ''}%</span>
                      </div>
                    </div>
                    <div className="contribution">
                      <span>Enter your contribution</span>
                      {raised && raised === parseInt(gift.cost) ? (
                        <input
                          placeholder="$0"
                          value={gift.contribution}
                          type="number"
                          min={0}
                          disabled
                          onChange={(e) =>
                            handleContribution(
                              gift.id,
                              parseFloat(e.target.value),
                            )
                          }
                          onBlur={(e) =>
                            validatePrice(e.target.value, gift.cost, gift.id)
                          }
                        />
                      ) : (
                        <input
                          placeholder="$0"
                          value={gift.contribution}
                          type="number"
                          min={0}
                          onChange={(e) =>
                            handleContribution(
                              gift.id,
                              parseFloat(e.target.value),
                            )
                          }
                          onBlur={(e) =>
                            validatePrice(e.target.value, gift.cost, gift.id)
                          }
                        />
                      )}
                    </div>
                    <ErrorContainer>{errorMessage}</ErrorContainer>
                  </GiftCard>
                ))}
              <Default>
                <TotalContainer>
                  <div>
                    <div>
                      <h2>TOTAL AMOUNT CONTRIBUTED</h2>
                    </div>
                    <div>
                      <span>{`$${total}`}</span>
                    </div>
                  </div>

                  <p>
                    No one can see how much you are contributing except the
                    organiser of the event
                  </p>

                  <Button text="Next" buttonFunction={() => handleSubmit()} />
                </TotalContainer>
              </Default>
            </Container>
          ) : (
            <>
              <div style={{ width: '90%' }}>
                <Default>
                  <TotalContainer2>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <h2>TOTAL AMOUNT CONTRIBUTED</h2>
                      </div>
                      <div>
                        <span>{`$${total}`}</span>
                      </div>
                    </div>

                    <p>
                      No one can see how much you are contributing except the
                      organiser of the event
                    </p>
                  </TotalContainer2>
                </Default>
              </div>
              <br />
              <Container2>
                {gifts &&
                  gifts.map((gift, index) => (
                    <GiftCard key={gift.id}>
                      <div>
                        <img
                          src={
                            gift.gift_images.length !== 0
                              ? gift.gift_images[0].url
                              : GiftImage
                          }
                          alt={gift.title}
                        />
                        <div>
                          <div>
                            <div>
                              <h3>{gift.title}</h3>
                              <small>{gift.date}</small>
                            </div>
                            <div>
                              {getFollow && getFollow !== null ? (
                                ''
                              ) : (
                                <span className="gift-price">${gift.cost}</span>
                              )}
                            </div>
                          </div>
                          <div>
                            <p>{gift.description}</p>
                          </div>
                        </div>
                      </div>
                      <div className="progress-bar">
                        <LinearProgress
                          variant="determinate"
                          value={parseInt(percentages[index])}
                        />
                        <div>
                          {getFollow && getFollow !== null ? (
                            <span></span>
                          ) : (
                            <span>${raised[index]}</span>
                          )}
                          <span>{percentages[index]}%</span>
                        </div>
                      </div>

                      <div className="contribution">
                        <span>Enter your contribution</span>
                        &emsp;
                        {!errorMessage || gift.id === valid ? (
                          <>
                            {raised[index] === gift.cost ? (
                              <input
                                placeholder="$0"
                                value={gift.contribution}
                                type="number"
                                disabled
                                min={0}
                                onChange={(e) =>
                                  handleContribution(
                                    gift.id,
                                    parseFloat(e.target.value),
                                  )
                                }
                                onBlur={(e) =>
                                  validateMultiplePrice(
                                    e.target.value,
                                    gift.cost,
                                    gift.id,
                                  )
                                }
                              />
                            ) : (
                              <input
                                placeholder="$0"
                                value={gift.contribution}
                                type="number"
                                min={0}
                                onChange={(e) =>
                                  handleContribution(
                                    gift.id,
                                    parseFloat(e.target.value),
                                  )
                                }
                                onBlur={(e) =>
                                  validateMultiplePrice(
                                    e.target.value,
                                    gift.cost,
                                    gift.id,
                                  )
                                }
                              />
                            )}
                          </>
                        ) : (
                          <input
                            placeholder="$0"
                            value={gift.contribution}
                            type="number"
                            min={0}
                            disabled
                            onChange={(e) =>
                              handleContribution(
                                gift.id,
                                parseFloat(e.target.value),
                              )
                            }
                            onBlur={(e) =>
                              validateMultiplePrice(
                                e.target.value,
                                gift.cost,
                                gift.id,
                              )
                            }
                          />
                        )}
                      </div>
                      <ErrorContainer>
                        {gift.id === valid && gifts.length === giftLength
                          ? errorMessage
                          : ''}
                      </ErrorContainer>
                      {/* <ErrorContainer>{giftLength === gifts.length ? bothErrorMessage : ""}</ErrorContainer> */}
                    </GiftCard>
                  ))}
              </Container2>
            </>
          )}
        </>
      ) : (
        <CircularIndeterminate />
      )}
    </>
  );
}
