import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import history from '~/services/history';
import Context from '~/context';
import { ReactComponent as Archive } from '~/assets/images/archive.svg';

import { Container } from './styles';
import { createArchivedEvent } from '~/store/modules/event/eventAction';
import CircularIndeterminate from '~/components/Loader';
import { ReactComponent as TrashCant } from '~/assets/images/trash-cant.svg';
import { createArchiveEvent } from '~/api/eventAPI';

export default function HideAlbum({ eventId, receiverId }) {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const receiver = useSelector((state) => state.receiverReducer);
  const [loader, setLoader] = useState(false);
  const [check, setCheck] = useState(false);

  const handleArchiveAlbum = async () => {
    setLoader(true);
    const data = {
      eventId: eventId,
    };
    await createArchiveEvent(data)
      .then((res) => {
        if (res && res.data.message === 'event archived successfully') {
          handleShowModal();
          history.push({
            pathname: '/success',
            state: {
              destination: `/receiver-profile/${
                receiver.id || receiverId
              }/events`,
              message: 'Event Archived.',
              colors: { start: colors.lightPurple, end: colors.purple },
            },
          });
        } else {
          setLoader(false);
          setCheck(true);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
    // Dispatch(createArchivedEvent(data));
  };

  return (
    <Container>
      {!check ? (
        <>
          <Archive />
          <h2>
            Before you archive/hide
            <br /> this event, be aware:
          </h2>
          <p>
            You can only archive events once the event date has passed. Your
            invitees will continue to view the event but no longer contribute
            into it.
          </p>
          <small>
            You can unhide this event by going to <br /> My Profile / Menu
            /Manage View
          </small>
          <button type="submit" onClick={() => handleShowModal()}>
            Cancel
          </button>
          {!loader ? (
            <button type="submit" className="blue" onClick={handleArchiveAlbum}>
              Archive
            </button>
          ) : (
            <button type="submit" className="blue">
              <CircularIndeterminate />
            </button>
          )}
        </>
      ) : (
        <>
          <TrashCant />
          <h2>Sorry, you are unable to archive this event.</h2>
          <p className="eventarchived">
            This event is still open. Please change the date of the event or
            wait until the date has passed.
          </p>
          <button type="submit" onClick={() => handleShowModal()}>
            Cancel
          </button>
        </>
      )}
    </Container>
  );
}
