import { apiAxiosInstance, imageAxiosInstance } from '../services/axios';

export const getLogin = async (payload) => {
  try {
    const result = await apiAxiosInstance.post('singin', payload);
    const { data } = result;
    if (data.token) {
      apiAxiosInstance.defaults.headers.authorization = `Bearer ${data.token}`;
      imageAxiosInstance.defaults.headers.authorization = `Bearer ${data.token}`;
    }
    return result;
  } catch (err) {
    return err;
  }
};
export const getGoogleLogin = async (payload) => {
  try {
    const result = await apiAxiosInstance.post('singingoogle', payload);
    const { data } = result;
    if (data.token) {
      apiAxiosInstance.defaults.headers.authorization = `Bearer ${data.token}`;
      imageAxiosInstance.defaults.headers.authorization = `Bearer ${data.token}`;
    }
    return result;
  } catch (err) {
    return err;
  }
};

export const getuserChecked = async (payload) => {
  try {
    const result = await apiAxiosInstance.post('/checkeduser', {
      email: payload,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const getSignUp = async (payload) => {
  try {
    const result = await apiAxiosInstance.post('singup', payload);
    return result;
  } catch (err) {
    return err;
  }
};

