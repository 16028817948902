const initialState = {
  userBankDetails: null,
  userCardDetails: null,
  isCreateBankAccount: null,
  failureBankAccount: null,
};

export default function bankAccountReducer(state = initialState, action) {
  switch (action.type) {
    case 'BANK_LIST_BY_ID_DONE':
      return { ...state, userBankDetails: action.data };
    case 'CARD_DETAILS_BY_USER_ID_DONE':
      return { ...state, userCardDetails: action.data };
    case 'FAILURE_BANK_ACCOUNT':
      return { ...state, failureBankAccount: action.data };
    case 'ADD_BANK_ACCOUNT_DONE':
      if (action.data) {
        return { ...state, isCreateBankAccount: true };
      }
      return state;
    default:
      return state;
  }
}
