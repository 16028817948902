import React from 'react';
import { useParams } from 'react-router-dom';

import Avatar from '~/components/Avatar';

import { Container, ContactList, Contact } from './styles';

import gifts from '../gifts';

export default function WontContribute() {
  const { id } = useParams();

  const gift = gifts.find((x) => x.id === parseInt(id, 10));
  return (
    <Container>
      {gift.contributions.length > 0 ? (
        <ContactList>
          {gift.contributions.map((contact) => (
            <Contact key={contact.id}>
              <div>
                <div className="contact-info">
                  <Avatar size={55} image={contact.avatar} />
                  <div>
                    <h3>{contact.name}</h3>
                    <span>
                      {contact.date}
                      {contact.thanked}
                    </span>
                  </div>
                </div>
              </div>
            </Contact>
          ))}
        </ContactList>
      ) : (
        <h3>You haven&apos;t received any contributions yet.</h3>
      )}
    </Container>
  );
}
