import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';
import history from '~/services/history';
import Context from '~/context';

import { ReactComponent as Archive } from '~/assets/images/archive.svg';
import { ReactComponent as ArchiveT } from '~/assets/images/archive-cant.svg';

import { Container } from './styles';
import { createArchiveGift } from '~/api/eventAPI';
import CircularIndeterminate from '~/components/Loader';

export default function ArchiveGift({ giftId }) {
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const receiver = useSelector((state) => state.receiverReducer);
  const [loader, setLoader] = useState(false);
  const [check, setCheck] = useState(false);

  const handleArchiveAlbum = async () => {
    setLoader(true);
    await createArchiveGift(giftId)
      .then((res) => {
        if (res && res.message === 'Gift is archived') {
          handleShowModal();
          history.push({
            pathname: '/success',
            state: {
              destination: `/receiver-profile/${receiver.id}/`,
              message: 'Gift Archived!',
              colors: { start: colors.lightPurple, end: colors.primary },
            },
          });
        } else {
          setLoader(false);
          setCheck(true);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <Container>
      {!check ? (
        <>
          <Archive />
          <h2>
            Before you archive/hide <br />
            this gift, be aware that:
          </h2>
          <p>
            Friends who contributed to this gift will continue to see it but
            will no longer be able to make contributions.
          </p>
          <small>
            You can restore this gift by going to <br /> My Profile / Menu
            /Manage View
          </small>
          <button type="submit" onClick={() => handleShowModal()}>
            Cancel
          </button>
          {!loader ? (
            <button type="submit" className="blue" onClick={handleArchiveAlbum}>
              Archive
            </button>
          ) : (
            <button type="submit" className="blue">
              <CircularIndeterminate />
            </button>
          )}
        </>
      ) : (
        <>
          <ArchiveT className='svgcss'/>
          <h2>Sorry, you are unable to archive this gift.</h2>
          <p>
            This gift cannot be archived because it has been added to an
            existing open event(s) you created.
          </p>
          <p className='eventarchived'>
            We recommend you remove the gifts from these events, if no
            contribution has been made, or wait for the related open events to
            expiry.
          </p>
          <button type="submit" onClick={() => handleShowModal()}>
            Cancel
          </button>
        </>
      )}
    </Container>
  );
}
