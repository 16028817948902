import styled from 'styled-components';

export const Container = styled.div`
  height: 90px;
  padding: 0 0 0 30px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  aside {
    display: flex;
    align-items: center;
  }

  @media (max-width: 767px) {
    height: 50px;
    display: ${({ loggedIn }) => (loggedIn ? 'none' : 'flex')};
  }
`;

export const Logo = styled.div`
  cursor: pointer;
  img {
    max-width: 125px;

    @media (max-width: 767px) {
      max-width: 90px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  padding: 10px 0;

  div {
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      border-radius: 50%;
      max-width: 55px;
      margin-right: 15px;
    }

    strong {
      display: block;
      color: #888;
      font-size: 16px;
      background: #fff;
    }
  }

  > div:last-of-type {
    display: none;
  }

  ${({ visible }) =>
    visible
      ? `&:hover {
    > div:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
    }
  }
  `
      : ''}
`;

export const Menu = styled.div`
  border-left: 1px solid #cecece;
  flex: 1;
  height: 90px;
  width: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 60px;
  transition: background 0.2s;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

export const ProfileOptions = styled.div`
  position: absolute;
  width: 220px;
  left: calc(50% - 80px);
  top: 100%;
  z-index: 1;
  background: #f6f6f6;
  padding: 15px 5px;
  box-shadow: 0px 4px 6px 2px #00000024;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #f6f6f6;
  }

  a {
    padding: 5px 10px;
    color: #000;
    width: 100%;
    text-align: left;
    border-radius: 2px;

    &:hover {
      background-color: #e6e6e6;
    }
  }
`;
