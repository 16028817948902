import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import { Mobile } from '~/config/ReactResponsiveConfig';
import SubHeader from '~/components/SubHeader';
import AvatarUpload from '~/components/AvatarUpload';
import Input from '~/components/Input';
import DatePicker from '~/components/DatePicker';
import Button from '~/components/Button';
import { addReceiver } from '~/store/modules/receiver/receiverAction';
import { ThemeContext } from 'styled-components';

import {
  Container,
  InputRow,
  InputWrapper,
  RadioControl,
  RadioButton,
  SelectControl,
  InputValidation,
} from './styles';
import history from '~/services/history';

export default function AddIndividual() {
  const { type } = useParams();
  const dispatch = useDispatch();
  const [gender, setGender] = useState('');
  const { colors } = useContext(ThemeContext);
  const [relation, setRelation] = useState('Other');
  const [birthDate, setBirthDate] = useState(new Date());
  const [city, setCity] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [formError, setFormError] = useState(null);
  const [file, setFile] = useState();
  const user = useSelector((state) => state.userReducer.profile);
  const receiver = useSelector((state) => state);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const isReceiverCreated = useSelector(
    (state) => state.receiverReducer.isReceiverCreated,
  );
  const [receiverImageFile, setReceiverImageFile] = useState(null);

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleRelationChange = (event) => {
    setRelation(event.target.value);
  };

  const handleDateChange = (newDate) => {
    setBirthDate(newDate);
  };

  const handleImage = async (event) => {
    setReceiverImageFile(event.target.files[0]);
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  const checkedOnboarding = JSON.parse(localStorage.getItem('flow'));

  const validateForm = () => {
    if (firstName === '') {
      setFormError(`validation failed: first name required!`);
      return 'validationFailed';
    }
    if (lastName === '') {
      setFormError(`validation failed: last name required!`);
      return 'validationFailed';
    }
    if (city === '') {
      setFormError(`validation failed: location required!`);
      return 'validationFailed';
    }
    if (gender === '') {
      setFormError(`validation failed: gender required!`);
      return 'validationFailed';
    }
    if (relation === '') {
      setFormError(`validation failed: relation required!`);
      return 'validationFailed';
    }
  };

  const handleSubmit = () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      dob: birthDate,
      location: city,
      gender,
      relation,
      user_id: user.id,
      type,
    };
    const error = validateForm();

    if (error !== 'validationFailed') {
      const formData = new FormData();
      if (receiverImageFile) {
        formData.append('file', receiverImageFile);
      }
      dispatch(addReceiver(data, receiverImageFile ? formData : null));
      if (checkedOnboarding.onboarding === false) {
        setSubmitButtonLoading(true);
        setTimeout(() => {
          if (receiver) {
            setSubmitButtonLoading(false);
            history.push('/first-gift');
          }
        }, 2000);
        // history.push({
        //   pathname: '/success',
        //   state: {
        //     destination: `/my-profile`,
        //     message: 'Receiver Created!',
        //     colors: { start: colors.lightPurple, end: colors.purple },
        //   },
        // });
      } else {
        history.push('/onboarding-success/1');
      }
    }
  };

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 2000);

  return (
    <>
      <SubHeader
        buttonBackgroundColor="#01e3fa"
        buttonText="Next"
        buttonTextColor="#142484"
        mobileNavButtons={false}
        buttonFunction={() => handleSubmit()}
        submitButtonLoading={submitButtonLoading}
      >
        <h2>ADD GIFT RECEIVER</h2>
        <h3>
          Add a few details about the person you want to
          fund&nbsp;gift(s)&nbsp;for
        </h3>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        <form onSubmit={handleSubmit}>
          <AvatarUpload
            image={file && file}
            gender={gender}
            onChange={handleImage}
          />
          <InputRow>
            <InputWrapper>
              <Input
                type="text"
                label="First Name"
                name="first_name"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                label="Surname"
                name="last_name"
                placeholder="Surname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </InputWrapper>
          </InputRow>
          <InputRow>
            <InputWrapper>
              <DatePicker
                label="Date of Birth"
                name="dob"
                date={birthDate}
                onChange={handleDateChange}
                maxDate={new Date()}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                label="City Where He or She Lives"
                name="location"
                placeholder="Mosman NSW 2088"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </InputWrapper>
          </InputRow>
          <RadioControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={gender}
              onChange={handleGenderChange}
            >
              <FormControlLabel
                value="F"
                control={<RadioButton />}
                label="Female"
              />
              <FormControlLabel
                value="M"
                control={<RadioButton />}
                label="Male"
              />
              <FormControlLabel
                value="O"
                control={<RadioButton />}
                label="Other"
              />
            </RadioGroup>
          </RadioControl>
          <SelectControl variant="filled">
            <InputLabel id="relation-label" disableAnimation shrink={false}>
              {firstName ? `I am ${firstName}'s` : 'Relation'}
            </InputLabel>
            <Select
              labelId="relation-label"
              id="relation"
              name="relation"
              value={relation}
              onChange={handleRelationChange}
            >
              <MenuItem value="Other">Other</MenuItem>
              <MenuItem value="Father">Father</MenuItem>
              <MenuItem value="Mother">Mother</MenuItem>
              <MenuItem value="Brother">Brother</MenuItem>
              <MenuItem value="Sister">Sister</MenuItem>
              <MenuItem value="Uncle">Uncle</MenuItem>
              <MenuItem value="Aunt">Aunt</MenuItem>
            </Select>
          </SelectControl>
        </form>
        <Mobile>
          <Button
            text="Next"
            backgroundColor="#01e3fa"
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
    </>
  );
}
