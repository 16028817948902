import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
// Action
import { fetchAllEvent } from '../../store/modules/event/eventAction';
import history from '~/services/history';

import EventList from '~/components/EventList';
import SubHeader from '~/components/SubHeader';

import { Container, LoaderDiv, LoadingText ,InputValidation} from './styles';
import CircularIndeterminate from '~/components/Loader';

export default function ChooseEvent() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const event = useSelector((state) => state.eventReducer.events);
  const selectedEventId = useSelector(
    (state) => state.eventReducer.selectedEventId,
  );
  const [formError, setFormError] = useState(null);
  const eventData = useSelector((state) => state.receiverReducer.events);
  const receiverData = useSelector((state) => state.receiverReducer.receiver);

  const [loader, setLoader] = useState(false);

  const validateForm = () => {
    if (selectedEventId === null) {
      setFormError(`validation failed: select event!`);
      return 'validationFailed';
    }
  };
  useEffect(() => {
    setLoader(true);
    Dispatch(fetchAllEvent(receiverData.id));
    if (eventData && eventData) {
      setLoader(false);
    }
  }, [Dispatch]);

  const handleSubmit = () => {
    const error = validateForm();
    if (error !== 'validationFailed') {
      if (selectedEventId !== null) {
        history.push(`/event-details/${selectedEventId}`);
      }
    }
  };

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 3000);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonText="Next"
        buttonFunction={() => handleSubmit()}
      >
        <h2>Choose Event</h2>
        <h3>Select the event you want to invite friends to:</h3>
      </SubHeader>
      <InputValidation>
        {formError}
      </InputValidation>
      <Container>
        {!loader ? (
          <EventList events={eventData} />
        ) : (
          <LoaderDiv>
            <CircularIndeterminate />
            <LoadingText>Loading...</LoadingText>
          </LoaderDiv>
        )}
      </Container>
    </>
  );
}
