import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';

import Context from '~/context';

import { ReactComponent as PartyInvite } from '~/assets/images/envelope-icon.svg';
import { ReactComponent as WebsiteIcon } from '~/assets/images/website-icon.svg';
import { ReactComponent as UnsurePerson } from '~/assets/images/unsure-person-icon.svg';

import LaterScreen from './LaterScreen';
import FindOutMoreModal from './FindOutMoreModal';

import SubHeader from '~/components/SubHeader';
import ModalWrapper from '~/components/ModalWrapper';

import { Container, BigButton } from './styles';

export default function SelectReceiver() {
  const { colors } = useContext(ThemeContext);
  const { handleShowModal, flow, setFlow } = useContext(Context);
  const [showLater, setShowLater] = useState(false);

  const handlePartyInvitation = () => {
    setFlow({ inviteOption: 'party-invitation' });
    history.push('/add-event');
  };

  const handleWebpage = () => {
    setFlow({ inviteOption: 'webpage' });
    history.push('/edit-page');
  };

  return (
    <>
      {showLater ? (
        <LaterScreen />
      ) : (
        <>
          <SubHeader
            backgroundColor={colors.primary}
            buttonBackgroundColor="#4FF3FE"
            buttonText={flow.onboarding ? 'Later' : ''}
            buttonTextColor="#142484"
            mobileNavButtons={false}
            buttonFunction={() => setShowLater(true)}
            hideBackButton={flow.onboarding}
          >
            <h2>INVITE OPTIONS </h2>
            <h3 style={{ color: '#142484' }}>
              Invite friends to contribute to gift(s) by sending them:
            </h3>
          </SubHeader>
          <Container>
            <BigButton onClick={handlePartyInvitation}>
              <div className="image-wrapper">
                <PartyInvite alt="An Individual" />
              </div>
              <div className="text-content">
                <h2>A party invitation</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>

            <BigButton backgroundColor={colors.purple} onClick={handleWebpage}>
              <div className="image-wrapper">
                <WebsiteIcon alt="An Individual" />
              </div>
              <div className="text-content">
                <h2>A webpage of gift wishes</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>
            <BigButton
              backgroundColor="#CCCCCC"
              onClick={() => history.push('/add-event')}
            >
              <div className="image-wrapper">
                <UnsurePerson alt="An Individual" />
              </div>
              <div className="text-content">
                <h2>I’m not sure yet</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>
            <span role="presentation" onClick={() => handleShowModal()}>
              Find out more...
            </span>
          </Container>

          <ModalWrapper>
            <FindOutMoreModal />
          </ModalWrapper>
        </>
      )}
    </>
  );
}
