import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: #0000009e;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: ${({ checked }) => (checked ? 'flex' : 'none')};

  svg {
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    max-width: 60px;
    max-height: 60px;
  }
`;
