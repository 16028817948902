import React, { useEffect, useState } from 'react';

import GiftImage from '~/assets/images/bike-gift.jpg';
import EmptyList from '../EmptyList';
import moment from 'moment';

import CardCheckOverlay from '~/components/CardCheckOverlay';

import { Container, GiftCard } from './styles';

import gifts from './gifts';
import { useSelector } from 'react-redux';

export default function Gifts({ selectedGiftsIds, setSelectedGiftsIds }) {
  const eventData = useSelector((state) => state.receiverReducer.archivedEvent);
  const [checkedGifts, setCheckedGifs] = useState();

  const handleSelectGift = (id) => {
    setCheckedGifs((prevCheckedEvents) => {
      const updatedCheckedGifts = prevCheckedEvents.map((gift) => ({
        ...gift,
        checked: gift.id === id ? !gift.checked : gift.checked,
      }));
      const updatedSelectedGiftIds = updatedCheckedGifts
        .filter((gift) => gift.checked)
        .map((gift) => gift.id);
      setSelectedGiftsIds(updatedSelectedGiftIds);
      return updatedCheckedGifts;
    });
  };

  useEffect(() => {
    if (eventData && eventData) {
      const checkedEvents = eventData.gifts.map((gift) => ({
        ...gift,
        checked: false,
      }));
      setCheckedGifs(checkedEvents);
    }
  }, [eventData]);

  return (
    <>
      {checkedGifts && checkedGifts.length > 0 ? (
        <Container>
          {checkedGifts &&
            checkedGifts.map((gift) => (
              <GiftCard key={gift.id} onClick={() => handleSelectGift(gift.id)}>
                <div>
                  <img
                    src={
                      gift.gift_images.length === 0
                        ? GiftImage
                        : gift.gift_images[0].url
                    }
                    alt={gift.name}
                  />
                  <div>
                    <div>
                      <div>
                        <h3>{gift.name}</h3>
                        <small>{moment(gift.date).format('MMMM Do')}</small>
                      </div>
                    </div>
                    <div>
                      <p>{gift.desc}</p>
                    </div>
                  </div>
                </div>
                <CardCheckOverlay checked={gift.checked} />
              </GiftCard>
            ))}
        </Container>
      ) : (
        <EmptyList>Click below to add one or more gifts for Eugene.</EmptyList>
      )}
    </>
  );
}
