import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import Context from '~/context';

import gift from './gift';

import FullyFunded from '~/pages/GiftDetails/ModalScreens/FullyFunded';

import SubHeader from '~/components/SubHeader';
import ModalWrapper from '~/components/ModalWrapper';
import Button from '~/components/Button';
import CircularProgress from '~/components/CircularProgress';

import { Container, Column, Card } from './styles';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContributionById } from '~/api/userAPI';
import CircularIndeterminate from '~/components/Loader';
import { fetchReceiverById } from '~/store/modules/receiver/receiverAction';
import { fetchGiftDetailsById } from '~/store/modules/gift/giftAction';

export default function AlmostThere() {
  const { colors } = useContext(ThemeContext);
  const Dispatch = useDispatch();
  const location = useLocation();
  const funding = location && location.data;
  const { handleShowModal } = useContext(Context);
  const notifications = useSelector(
    (state) => state.notificationReducer.notifications,
  );
  const gift = useSelector((state) => state.giftReducer.currentGift);

  const user = useSelector((state) => state.userReducer.profile);
  const userId = user && user.id;

  const [loader, setLoader] = useState(false);
  const [giftData, setGiftData] = useState('');
  var contributions = 0;

  useEffect(() => {
    getUserDetail();
    Dispatch(fetchReceiverById(parsedData && parsedData.child_id));
    Dispatch(fetchGiftDetailsById(funding && funding.gift_id));
  }, [parsedData && parsedData.child_id]);

  const getUserDetail = async (id) => {
    const giftId = funding && funding.gift_id;
    setLoader(true);
    try {
      await getContributionById(giftId).then((res) => {
        setGiftData(res.data);
        setLoader(false);
      });
    } catch (error) {
      console.log('err', error);
      setLoader(false);
    }
  };

  const giftPrice = giftData && parseFloat(giftData.price);
  contributions =
    giftData &&
    giftData.contributor_contributions.reduce(
      (total, contribution) => total + parseFloat(contribution.donation),
      0,
    );
  // Calculating percentage
  const percentage = (contributions / giftPrice) * 100;
  const parsedData =
    funding && funding ? JSON.parse(funding && funding.data) : '';

  const contributionData = {
    user_id: userId && userId,
    gift_id: funding && funding.gift_id,
    contribution: giftData && parseInt(giftData.price) - contributions,
    child_id: parsedData && parsedData.child_id,
  };

  const handleInvite = () => {
    const invitePage =
      gift && gift.eventExists ? '/gift-options' : '/event-type';
    history.push(invitePage);
  };

  return (
    <>
      <SubHeader backgroundColor={colors.purple} mobileDescVisible={false}>
        <h2>Gift Funding</h2>
      </SubHeader>
      <Container className="loader">
        {!loader ? (
          <>
            <Column>
              <h2>You’re almost there!</h2>
              <CircularProgress
                percentage={percentage && percentage.toFixed(0)}
                size={150}
              />
            </Column>
            <Column>
              <Card>
                <h3>
                  {parsedData && parsedData.childName}&apos;s{' '}
                  {giftData && giftData.name}
                </h3>
                <div className="description">
                  <div>
                    <span>has received</span>
                    <span className="large">
                      ${contributions && contributions}
                    </span>
                  </div>
                  <div>
                    <span>required to reach target</span>
                    <span className="large blue">
                      ${giftData && parseInt(giftData.price) - contributions}
                    </span>
                  </div>
                </div>
              </Card>
              <Button
                text="Invite More Friends to contribute"
                backgroundColor="#c4c4c4"
                textColor="#fff"
                buttonFunction={handleInvite}
              />
              {giftData && parseInt(giftData.price) - contributions === 0 ? (
                <Button
                  text="FUND GIFT TO 100% YOURSELF"
                  backgroundColor={'#c4c4c4'}
                  textColor={'white'}
                />
              ) : (
                <Button
                  text="FUND GIFT TO 100% YOURSELF"
                  buttonFunction={() => handleShowModal()}
                />
              )}
            </Column>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularIndeterminate />
          </div>
        )}
      </Container>

      <ModalWrapper>
        <FullyFunded data={contributionData} />
      </ModalWrapper>
    </>
  );
}
