import React, { useEffect } from 'react';
import { getNotificaiton } from '../Notifications/MainNotifications/notificationTypes';

import history from '~/services/history';
import { Container } from './styles';
import { useDispatch } from 'react-redux';
import { setNotificationData } from '~/store/modules/notification/notificationAction';
import { readThankuCardNotification } from '~/api/notificationAPI';

export default function NotificationCard({ notification }) {
  const state = notification.state ? notification.state : {};
  const notifictionType = getNotificaiton(notification.notification_type);
  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(setNotificationData(''));
  }, [notifictionType]);

  const handleNotification = async (data) => {
    Dispatch(setNotificationData(data));
    await readThankuCardNotification(data.notification_id)
      .then((res) => {
        if (notifictionType && notifictionType.path === '/gift-details') {
          history.push({ pathname: `/gift-details/${data.gift_id}`, data });
        } else if (
          notifictionType &&
          notifictionType.path === '/event-detail'
        ) {
          history.push({
            pathname: `/event-details/${data.event_id}`,
            data: data,
          });
        } else {
          console.log('else');
          history.push({
            pathname:
              (notifictionType && notifictionType.path) || notification.path,
            data,
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  return (
    <Container>
      <div className="image-wrapper">
        <img
          src={
            (notifictionType && notifictionType.icon) ||
            (notification && notification.icon)
          }
          alt="Gift"
        />
      </div>
      <div
        className="description"
        onClick={() => handleNotification(notification)}
      >
        <span>{notification.title}</span>
        <small>{notification.message}</small>
      </div>
    </Container>
  );
}
