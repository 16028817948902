import styled from 'styled-components';
import { darken } from 'polished';

import AuthBG from '~/assets/images/auth-bg.jpg';

export const Wrapper = styled.div`
  min-height: calc(100% - 90px);
  padding: 40px;
  background-image: url(${AuthBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    min-height: calc(100% - 50px);
    padding: 10px;
  }
`;

export const TopWrapper = styled.div`
  padding: 40px 54px;

  @media (max-width: 767px) {
    padding: 30px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 395px;
  text-align: center;
  background: linear-gradient(135deg, #15a9d6, #113189);
  color: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);

  @media (max-width: 767px) {
    width: 90%;
  }

  h2 {
    font-size: 25px;
    font-weight: 600;
    line-height: 28px;
  }

  h3 {
    max-width: 215px;
    margin: 0 auto;
    font-size: 17px;
    line-height: 18px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input {
      background: none;
      border: 0;
      height: 36px;
      color: #fff;
      font-size: 18px;
      padding-left: 10px;
      flex: 1;
      border-radius: 0;

      &::placeholder {
        color: #fff;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 0px transparent inset;
        font-size: 16px !important;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    span {
      color: #ffffff;
      align-self: start;
      margin: 10px 0 0;
      width: 100%;
      flex-basis: 100%;
      font-size: 14px;
      background: #d243797a;
      padding: 2px 5px;
      border-radius: 2px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    button {
      height: 60px;
      background: ${(props) => props.theme.colors.primary};
      font-weight: bold;
      color: ${(props) => props.theme.colors.secondary};
      border: 0;
      font-size: 20px;
      letter-spacing: 1.2px;
      transition: background 0.2s;
      text-transform: uppercase;
      margin: 25px auto 32px;
      width: 100%;
      cursor: pointer;
      max-width: 302px;

      &:hover {
        background: ${darken(0.1, '#64e0f7')};
      }
    }

    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const InputWrapper = styled.div`
  border-bottom: 1px solid #a6cce2;
  padding: 0 5px;
  margin: 0 auto 15px;
  max-width: 265px;
  font-size: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  svg {
    width: 35px;
    height: 36px;
    padding-left: 10px;
  }
`;

export const ContainerFooter = styled.div`
  width: 100%;
  background: #0f3275;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: #fff;
    font-size: 16px;
    opacity: 0.8;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }
  }
`;
