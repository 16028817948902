import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content } from './styles';

import Header from '~/components/Header';
import Footer from '~/components/Footer';

export default function AuthLayout({ children }) {
  return (
    <>
      <Header />
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
      <Footer />
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
