import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SuccessCheck } from '~/assets/images/success-check.svg';

import { Container } from './styles';

export default function CardCheckOverlay({ checked = false }) {
  return (
    <Container checked={checked}>
      <SuccessCheck />
    </Container>
  );
}

CardCheckOverlay.propTypes = {
  checked: PropTypes.bool.isRequired,
};
