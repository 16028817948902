import React, { useEffect, useState } from 'react';
import history from '~/services/history';
import { convertUTCDateToString } from '../../../utils/utility';
import Button from '~/components/Button';
import EmptyList from '../EmptyList';
import { Container, Card, Contributions } from './styles';
import { useSelector } from 'react-redux';
import profileImage from '~/assets/images/avtar-square-image.png';
import Avatar from '~/components/Avatar';

export default function Pending() {
  const notificationReducer = useSelector((state) => state.notificationReducer);
  const { thankuCardPending } = notificationReducer;
  const pendingCard = thankuCardPending ? thankuCardPending : '';

  const handleSubmit = (card) => {
    localStorage.setItem('cardPendingData', JSON.stringify(card));
    history.push({ pathname: '/send-thank-you-card', card });
  };
  const handleNewEntry = (card) => {
    localStorage.setItem('cardPendingData', JSON.stringify(card));
    localStorage.setItem('type', 'multiple');
    history.push({ pathname: '/send-thank-you-card', card });
  };

  const filteredCards =
    pendingCard &&
    pendingCard.reduce((accumulator, currentCard) => {
      const isFullyFunded = currentCard.isFullyFunded;
      const isMatchingAndFunded = accumulator.some(
        (card) =>
          card.giftDetails.id === currentCard.giftDetails.id &&
          card.isFullyFunded,
      );

      if (!isFullyFunded || !isMatchingAndFunded) {
        accumulator.push(currentCard);
      }

      return accumulator;
    }, []);

  return (
    <>
      {filteredCards && filteredCards.length > 0 ? (
        <Container>
          {filteredCards.map((card) => {
            const fullName = `${card.users.first_name}\xa0${card.users.last_name}`;
            const truncatedFullName = fullName && fullName.slice(0, 11);

            return (
              <Card key={card.contributor.id}>
                <Contributions>
                  <div className="avatar-content">
                    {card.isFullyFunded ? (
                      <div className="avatar-inner">
                        <span className="aa">
                          <Avatar size={50} />
                        </span>
                        <span className="bb">
                          <Avatar size={50} />
                        </span>
                        <span className="cc">
                          <Avatar size={50} />
                        </span>
                      </div>
                    ) : (
                      <img
                        src={card.imageUrl ? card.imageUrl : profileImage}
                        alt={card.users.first_name}
                      />
                    )}

                    <div className="avatar-right">
                      <div>
                        <h3>
                          {card.isFullyFunded
                            ? 'Contributors to'
                            : truncatedFullName && truncatedFullName + '..'}
                        </h3>
                        <small>
                          {card.isFullyFunded
                            ? (card.children.first_name !== null
                                ? card.children.first_name + "'s"
                                : card.children &&
                                  card.children.group_name + "'s") +
                              ' ' +
                              card.giftDetails.title
                            : convertUTCDateToString(
                                card.contributor.created_at,
                              )}
                        </small>
                      </div>
                      <div className="donation_price">
                        <span className="donation">
                          {card.isFullyFunded
                            ? ''
                            : `$${card.contributor.contribution.split('.')[0]}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </Contributions>
                <div>
                  {card.isFullyFunded ? (
                    <Button
                    className='allCard'
                      text="SEND THANK YOU ALL CARD"
                      textColor={'white'}
                      backgroundColor={'#729fcf'}
                      buttonFunction={() => handleNewEntry(card)}
                    />
                  ) : (
                    <Button
                      text="Send Thank You Card"
                      buttonFunction={() => handleSubmit(card)}
                    />
                  )}
                </div>
              </Card>
            );
          })}
        </Container>
      ) : (
        <EmptyList>You have not received a thank you card yet.</EmptyList>
      )}
    </>
  );
}
