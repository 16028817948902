import React, { useState, useContext } from 'react';
import Context from '~/context';
import history from '~/services/history';

import DeleteProfile from '../DeleteProfile';
import ArchiveProfile from '../ArchiveProfile';
import ArchiveReceiverProfile from '../ArchiveReceiverProfile';

export default function Options({ receiver }) {
  const { handleShowModal } = useContext(Context);
  const [selectedOption, setSelectedOption] = useState('');
  const getFollow = localStorage.getItem('follow');

  function handleEditProfile() {
    history.push(`/edit-receiver/${receiver.id}`);
    handleShowModal();
  }
  const handleCancel = () => {
    handleShowModal();
  };

  return (
    selectedOption || (
      <>
        {getFollow && getFollow !== null ? (
          <>
            <button
              type="submit"
              className="red"
              onClick={() => setSelectedOption(<ArchiveReceiverProfile />)}
            >
              Archive Profile
            </button>

            <button type="submit" onClick={handleCancel}>
              Cancel
            </button>
          </>
        ) : (
          <>
            <button type="submit" onClick={handleEditProfile}>
              Edit Profile
            </button>
            <button
              type="submit"
              onClick={() => setSelectedOption(<ArchiveProfile />)}
            >
              Archive Profile
            </button>
            <button
              type="submit"
              className="red"
              onClick={() =>
                setSelectedOption(
                  <DeleteProfile receiver={receiver} hasDonations={false} />,
                )
              }
            >
              Delete Profile
            </button>
            <button type="submit" onClick={() => handleShowModal()}>
              Cancel
            </button>
          </>
        )}
      </>
    )
  );
}
