import React, { useState, useContext } from 'react';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import history from '~/services/history';

import contactsList from './contactsList';

import SubHeader from '~/components/SubHeader';

import { Container, InputColumn, CheckboxControl, Card } from './styles';

export default function BankGiftReceivers() {
  const { colors } = useContext(ThemeContext);
  const [contacts, setContacts] = useState(
    contactsList.map((contact) => ({ ...contact, checked: true })),
  );

  const handleContactCheck = (id) => (e) => {
    setContacts(
      contacts.map((contact) => ({
        ...contact,
        checked: contact.id === id ? !contact.checked : contact.checked,
      })),
    );
  };

  const handleNext = () => {
    history.push({
      pathname: '/success',
      state: {
        destination: `/payment-details`,
        message: 'Account Linked!',
        colors: { start: colors.lightPurple, end: colors.purple },
      },
    });
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonText="Save"
        buttonTextColor="#fff"
        buttonFunction={handleNext}
      >
        <h2>Gift Receiver(s)</h2>
        <h3>
          This bank account will be used to receive funds for gifts related to:
        </h3>
      </SubHeader>
      <Container>
        <InputColumn>
          {contacts.length > 0 ? (
            <>
              {contacts.map((contact) => (
                <CheckboxControl
                  key={contact.id}
                  control={
                    <Card>
                      <div>
                        <img src={contact.avatar} alt={contact.name} />
                        <div>
                          <h3>{contact.name}</h3>
                        </div>
                      </div>
                      <div>
                        <Checkbox
                          checked={contact.checked}
                          onChange={handleContactCheck(contact.id)}
                          name={toString(contact.id)}
                          color="primary"
                        />
                      </div>
                    </Card>
                  }
                />
              ))}
            </>
          ) : (
            <h3>You have no contacts yet.</h3>
          )}
        </InputColumn>
        <InputColumn>
          <p>
            NOTE: This will now be the default bank account to receive funds for
            all gift receivers you selected.
          </p>
        </InputColumn>
      </Container>
    </>
  );
}
