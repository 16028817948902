import React, { useState } from 'react';

import HaveContributed from './HaveContributed';
import WontContribute from './WontContribute';

import SubHeader from '~/components/SubHeader';

import { Container, StyledTabs as Tabs, StyledTab as Tab } from './styles';

function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EventContributors() {
  const tabs = [<HaveContributed />, <WontContribute />];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(tabs[newValue]);
    setValue(newValue);
  };

  return (
    <>
      <SubHeader mobileDescVisible={false}>
        <h2>Event Contributors</h2>
      </SubHeader>

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Event Contributors Tab"
      >
        <Tab
          label="Have Contributed"
          key={a11yProps(0).key}
          id={a11yProps(0).id}
        />
        <Tab
          label="Won't Contribute"
          key={a11yProps(1).key}
          id={a11yProps(1).id}
        />
      </Tabs>
      <Container>{activeTab}</Container>
    </>
  );
}
