import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import Switch from '@material-ui/core/Switch';

import history from '~/services/history';

import { Container, SettingBar } from './styles';

export default function AppSetting({
  text,
  toggle,
  checked,
  name,
  children,
  destination,
}) {
  const [showChildren, setShowChildren] = useState(false);

  const handleClick = () => {
    if (children || showChildren) {
      setShowChildren(!showChildren);
    } else if (destination) {
      history.push(destination);
    }
  };
  return (
    <Container>
      <SettingBar onClick={() => handleClick()} className={toggle || 'pointer'}>
        <span>{text}</span>

        {toggle && (
          <Switch
            checked={checked}
            onChange={(e) => toggle(e)}
            color="primary"
            name={name}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        )}
        {children && (
          <IoIosArrowDown
            size={20}
            color="#d6d6d6"
            className={showChildren && 'open'}
          />
        )}
        {destination && (
          <IoIosArrowForward size={20} color="#d6d6d6" className="arrow" />
        )}
      </SettingBar>

      {showChildren && children}
    </Container>
  );
}
