import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import history from '~/services/history';
import { Mobile } from '~/config/ReactResponsiveConfig';

// Action
import { updateReceiver } from '~/store/modules/receiver/receiverAction';

import SubHeader from '~/components/SubHeader';
import AvatarUpload from '~/components/AvatarUpload';
import Input from '~/components/Input';
import Button from '~/components/Button';

import { Container, InputWrapper, SelectControl } from './styles';

export default function EditMultiple() {
  const Dispatch = useDispatch();
  const { receiverId } = useParams();
  const { colors } = useContext(ThemeContext);
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const [relation, setRelation] = useState(receiver.relation);
  const [location, setLocation] = useState(receiver.location);
  const [name, setName] = useState(receiver.group_name);
  const isReceiverUpdate = useSelector(
    (state) => state.receiverReducer.isReceiverUpdate,
  );
  const profile = useSelector((state) => state.userReducer.profile);

  const handleRelationChange = (event) => {
    setRelation(event.target.value);
  };
  const handleSubmit = () => {
    const data = {
      group_name: name,
      location,
      relation,
      user_id: profile.id,
    };
    Dispatch(updateReceiver(receiverId, data));
  };
  if (isReceiverUpdate) {
    history.push({
      pathname: '/success',
      state: {
        destination: `/receiver-profile/${receiverId}`,
        message: 'Profile Saved!',
        colors: { start: colors.lightPurple, end: colors.purple },
      },
    });
  }

  return (
    <>
      <SubHeader
        buttonBackgroundColor="#01e3fa"
        buttonText="Next"
        buttonTextColor="#142484"
        mobileNavButtons={false}
        buttonFunction={() => handleSubmit()}
      >
        <h2>EDIT GIFT RECEIVERS</h2>
        <h3>
          Add a few details about the people you want to fund one or
          more&nbsp;gifts&nbsp;for
        </h3>
      </SubHeader>
      <Container>
        <form>
          <AvatarUpload />
          <InputWrapper>
            <Input
              label="Names of Receivers"
              name="first-name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label="City Where They Live"
              name="city"
              value={location}
              onChange={(event) => setLocation(event.target.value)}
            />
          </InputWrapper>
          <SelectControl variant="filled">
            <InputLabel id="relation-label" disableAnimation shrink={false}>
              I am their...
            </InputLabel>
            <Select
              labelId="relation-label"
              id="relation"
              value={relation}
              onChange={handleRelationChange}
            >
              <MenuItem value="Other">Other</MenuItem>
              <MenuItem value="Father">Father</MenuItem>
              <MenuItem value="Mother">Mother</MenuItem>
              <MenuItem value="Brother">Brother</MenuItem>
              <MenuItem value="Sister">Sister</MenuItem>
              <MenuItem value="Uncle">Uncle</MenuItem>
              <MenuItem value="Aunt">Aunt</MenuItem>
            </Select>
          </SelectControl>
        </form>
        <Mobile>
          <Button
            text="Next"
            backgroundColor="#01e3fa"
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
    </>
  );
}
