import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.button`
  ${({ backgroundColor, textColor, theme }) => css`
    background: ${backgroundColor || theme.colors.primary};
    padding: 13px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    color: ${textColor || theme.colors.secondary};
    width: 100%;
    max-width: 179px;
    cursor: pointer;
    transition: background 0.2s;
    text-align: center;
    border: 0;

    @media (min-width: 979px) {
      min-width: 175px;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 315px;
    }

    &:hover {
      background: ${darken(0.05, backgroundColor) ||
      darken(0.05, theme.colors.primary)};
    }
  `}
`;
