import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import './config/ReactotronConfig';

import Routes from './routes';
import history from './services/history';

import { store, persistor } from './store';

import GlobalStyles from './styles/global';
import main from './styles/themes/main';
import muiTheme from './styles/themes/mui-theme';

function App() {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={main}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Router history={history}>
                <Routes />
                <GlobalStyles />
                <ToastContainer autoClose={3000}  progressClassName='custom-progress-bar' />
              </Router>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
