import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';
import SubHeader from '~/components/SubHeader';
import { Container, GiftCard } from './styles';
import { darken } from 'polished';

export default function FAQs() {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
        mobileDescVisible={false}
      >
        <h2>FAQ's</h2>
      </SubHeader>
      <Container className="scroller">
        <GiftCard>
          <div>
            <h1>Starting on Purposit</h1>
            <h3>Who should use Purposit?</h3>
            <p className="paragraph">
              Purposit was designed for friends and families to help fund
              meaningful, useful and better gifts for the people they love and
              care about. It caters for anyone or any group regardless of
              financial status, beliefs and age groups. <br /> <br />
              The platform is open to fund gifts and manage events for anyone,
              from babies to grandparents, from friends to relatives, from
              teachers to colleagues, from individuals to couples or groups,
              from yourself to someone you want to help.
            </p>
            <h3>
              What is expected from me as an organiser (eg. managing gifts and
              events)?
            </h3>
            <p className="paragraph">
              Purposit is about trust and transparency. By using the system, the
              organiser agrees to only use the funds for the specific gift(s)
              they were raised for.
              <br /> <br />
              An organiser is anyone who wants to create a gift or event for
              someone else. It can be a parent creating a gift for his child, or
              a mum arranging a gift for her daughter’s teacher. It can be a
              husband organising a party for his wife or a teen planning a
              surprise gift for his best friend.
              <br /> <br /> An organiser is expected to be at least 13 years of
              age. If you are under 13 years of age, you are not authorised to
              create profiles, events and gifts. We suggest you ask your own
              parents to create profiles and raise funds under their names or
              supervision. <br /> <br />
              If you are creating a profile for someone whom you are not legally
              responsible for and that person is under 18 years of age, you are
              required to have approval from that person’s parents or caretakers
              to use photos or pictures of that child or children.
              <br /> <br />
              An organiser is also expected to have an Australian bank account
              to receive the contributions for the gift or gifts.
            </p>
            <h3>Who is not allowed to use Purposit?</h3>
            <p className="paragraph">
              Purposit was designed for friends, families and colleagues to
              create and raise money for gifts for a person or people in a
              responsible and trustworthy manner. Organisers are not allowed to
              use the funds to engage in illegal or fraudulent activities using
              the platform.
              <br /> <br />
              Children (under 13 years old) are not allowed to create profiles,
              events and gifts. Teenagers (from 13 years to 18 years) are
              allowed to use the platform under their parent’s supervision and
              approval.
              <br /> <br />
              If you are not the parent or primary caretaker for a child, then
              you are required to have approval from the person legally
              responsible for that child in order to use photos, pictures and
              videos.
            </p>
            <h3>Do all organisers need to sign up to use the platform?</h3>
            <p className="paragraph">
              Yes, and the sign-up process – as well as the download of the app
              – are both free for all users. Purposit aims to help fund amazing
              gifts for a given person or people while making the experience
              private and personal for all parties involved. The privacy of each
              person is very important for us and, for this reason, the profile
              of a gift receiver is only visible to those friends who were
              invited to contribute to a gift by the organiser.
              <br /> <br />
              That said, invitees and contributors do not need to sign up to
              make a contribution to a gift. Once they click on the invitation
              link or code, they will be allowed to contribute to the gift or
              gifts without having to open a Purposit account.
              <br /> <br /> After contributing the invitee will have the option
              to sign up. By signing up, your contributors will be able to see
              the profile of the gift receiver, follow how funding is
              progressing, comment on photos or videos posted by the organiser
              and keep stored all the thank you cards they receive.
              <br /> <br />
              Remember, privacy is a key consideration for us and our users, so
              the profile of the gift receiver(s) is private and only accessible
              by users who sign up to the platform.
            </p>
            <h3>Are the app and web versions of Purposit the same? </h3>
            <p className="paragraph">
              Yes, both the app version – available on the Apple Store and
              Google Play Store – and the web version offer pretty much the same
              functionality and features with minor adjustments due to different
              technical environments. For the best user experience, we recommend
              users of the web version to use Chrome, Safari or Firefox and have
              their browsers in public mode.
            </p>
            <h3>
              What could cause the Purposit website to not work properly on my
              device?
            </h3>
            <p className="paragraph">
              We have extensively tested the application across a large number
              of devices, browsers and operating systems so users can enjoy
              Purposit everywhere. However, there are certain instances where
              functionality may be affected.
              <br />
              <br />
              If you ever encounter any issues logging in or using the website,
              please make sure that: (1) your browser settings allow cookies;
              (2) you are not using the private/incognito mode on your browser -
              Safari, Firefox or Chrome; and (3) you are using the latest
              version of your device’s operating system (eg. IOS or Android).
              <br />
              <br />
              Also, feel free to contact us and we will be thrilled to help you.
            </p>
            <h3>Do I need to have cookies enabled on my phone or computer?</h3>
            <p className="paragraph">
              Purposit uses cookies on its web application to provide you with a
              better, seamless user experience. We use different cookies. Some
              facilitate the completion of application functions while others
              help the website remember your information and settings when you
              visit it in the future. Most websites – including online banking
              web pages – use different types of cookies.
              <br />
              <br />
              If your browser has been set up to disable or block cookies, some
              functions may be affected. To have the best Purposit experience,
              we recommend you have your browser settings to enable cookies (via
              your browser privacy settings).
            </p>
            <h3>Is Purposit similar to a gift registry?</h3>
            <p className="paragraph">
              Not really. While we allow users to create lists of gift wishes,
              Purposit is a very different platform compared to the classic or
              standard gift registry.
              <br />
              <br />
              With the typical gift registry model, an invitee has to purchase a
              full item – which may be out of her or his budget – from a
              specific shop or gift retailer and within a certain time frame.
              Also, the options are limited to what that shop offers, and gifts
              are typically material items. In most cases, it can be quite an
              impersonal experience.
              <br />
              <br />
              Purposit is quite different. Firstly, your invitees can simply
              chip in for any of the gift ideas you shared with them, so they
              can stick to their budgets. In addition, your shared gift ideas
              can include non-material items like activities, experiences and
              travelling and are not tied up to a certain shop or retailer. With
              Purposit, your gifts also have no expiry date or set timeframes,
              so you can use the same gifts multiple times, until they are fully
              funded.
              <br />
              <br />
              Purposit provides a personal and lasting experience where your
              invitees can track funding, comment of photos or videos you
              posted, and keep a record of all thank you cards they received.
              It’s a unique, private platform like no other.
            </p>
            <br />
            <h1>Creating and Managing Gifts & Events</h1>
            <h3>What is a gift?</h3>
            <p className="paragraph">
              Our mantra is ‘gifting with a purpose’. At Purposit, a gift is
              something which you believe will be enjoyed, useful and fun for
              the person or people receiving it. A present can be anything:
              material items, activities, experiences, travelling or education.
              For every gift you will set a monetary value, which reflects the
              cost for that gift to be realised (eg. purchased or paid for).
            </p>
            <h3>How long will these gifts remain ‘live’ in the system for?</h3>
            <p className="paragraph">
              For as long as you want or need it to. Purposit allows the
              organisers to set gifts with different time horizons. Some gifts
              will be long-term dated such as university tuitions, wedding
              anniversary trip or an exchange programme and will remain ‘open’
              in the platform for years, if that’s what your goal is. Other
              gifts will be more short-term focused, such as a surfboard, a spa
              massage or concert tickets. That gives the organiser the
              flexibility to balance the short-term needs of the receiver with
              the raising of funds for long-term goals.
              <br />
              <br />
              The gifts only ‘close’ when either the full amount is reached or
              when the organiser decides to ‘fund gift to 100%’ (eg. chip in to
              get it to 100%). The gifts do not ‘expire’ with the events you
              create (eg. birthday, Christmas, graduation) so you can keep
              raising money for a gift for as long as needed (and select the
              same gift for multiple events over time if you want).
            </p>
            <h3>
              Are the contributions made by friends subject to getting the gift
              100% funded?
            </h3>
            <p className="paragraph">
              No. Once payments are processed by our payment gateway provider
              Stripe, the contributions are paid directly to the bank account
              specified by the relevant organiser, less the fees payable to
              Purposit and Stripe, irrespective of whether the targeted amount
              has been reached or not.
            </p>
            <h3>Can I cancel or delete a gift?</h3>
            <p className="paragraph">
              If no contribution has been made towards a gift you created, then
              you can delete that gift. Once a contribution has been made
              towards a particular gift, you can no longer delete it. You can
              still edit it (as circumstances change) and fund it to 100%. But
              the gift will remain in the system permanently for your
              contributor’s future reference.
            </p>
            <h3>What does ‘fund gift to 100%’ mean?</h3>
            <p className="paragraph">
              So time has come for the money to be used for a particular gift
              (say Jenny’s concert tickets) but the amount raised for this gift
              has not reached 100% yet. In that case, the organiser can click on
              ‘fund gift to 100%’. That means that the organiser commits to chip
              in and use his/her own money to fill the gap and get the gift
              fully funded. The organiser will not be charged in the system (eg.
              no fees are paid by the organiser), but he/she is expected to
              honour this commitment and provide the money to purchase/pay for
              that gift.
              <br />
              <br />
              When the organiser clicks on ‘fund gift to 100%’, he/she can send
              a thank you card to everyone who contributed to that gift. High
              fives all around!
            </p>
            <h3>
              Does the platform accept contributions for a gift after the gift
              is 100% funded?
            </h3>
            <p className="paragraph">
              Unless requested by the organiser, once a gift is fully funded, a
              contributor can no longer contribute to it. But the contributor
              can go on the profile page of the gift receiver, look at other
              unfunded gifts that the organiser may have created for that person
              (or people) and contribute to one or more of them.
            </p>
            <h3>How many gifts should I create for my receiver(s)?</h3>
            <p className="paragraph">
              It depends on who the receiver of the gift is and the occasion.
              Purposit allows organisers to fund as many gifts as required. So,
              you can choose as many gifts as you want, but remember - the more
              gifts you have selected, the longer it may take for these gifts to
              be fully funded.
              <br />
              <br />
              Also keep in mind that you (organiser) do not have to include all
              gifts you created in every event (eg. birthday). You can select
              the gifts that may be more appropriate for that event and the
              audience/friends you are targeting.
            </p>
            <h3>Is there a limit to the value of a given gift?</h3>
            <p className="paragraph">
              No. The value is your best estimation of the actual cost to
              purchase or pay for the gift. It can be anything from a few bucks
              to several thousand dollars. As you would expect, the higher the
              dollar value of a gift, the longer it will take to actually raise
              funds. So the sooner you start, the higher chances of getting it
              to 100%.
              <br />
              <br />
              For more costly gifts, we encourage the organiser to break the
              cost into ‘smaller gifts’ if possible. That makes contributions
              from friends look more relevant (as the % progress bar moves up
              faster) and also makes the gift more easily achievable. For
              example, a university fund focused on paying for the first year of
              tuition fees (e.g. Uni Fund - Year 1) will move towards 100% of
              funding much faster than a ‘university fund’ gift aimed at paying
              for all fees during the entire duration of the course. If you get
              Uni Fund - Year 1 fully funded, then you can create a new gift
              called Uni Fund - Year 2.
            </p>
            <h3>What is an event?</h3>
            <p className="paragraph">
              An event is any occasion or celebration when you want to fund a
              gift for someone. It could be a baby shower, birthday, Christmas,
              graduation or anniversary. These are the typical dates or
              milestones where friends and families would usually give or
              contribute to a gift.
              <br />
              <br />
              Events are the triggers to invite your circle of friends and
              relatives to contribute towards the gifts you created for the
              receiver or receivers.
              <br />
              <br />
              Purposit allows organisers to create 3 types of events: (1) Fund
              One Gift; (2) Gift List Page; and (3) Party Invitation. We discuss
              each event type in the following paragraphs.
              <br />
              <br />
              Regardless of the event, we have a no peer pressure policy. We aim
              to give all contributors a personal, enjoyable experience while
              sticking to their own budgets. For this reason, no invitee or
              contributor can see how much others are giving. That way, nobody
              feels ‘peer pressured’ to give more than they want or can.
            </p>
            <h3>What is a “Fund One Gift’ event?</h3>
            <p className="paragraph">
              This event is designed for you to quickly fund one special gift
              for someone or group. This is typically used when you want to
              quickly fund a gift for a friend, colleague or teacher.
              <br />
              <br />
              This event option allows the organiser to set the parameters for
              the gift funding, including minimum contribution and flat
              contributions (eg. everyone gives the same amount). If the gift is
              not funded by ‘the contribution by’ date, the organiser can invite
              more friends or press ‘fund gift to 100%’.
              <br />
              <br />
              You (organiser) can send thank you cards automatically or manually
              to contributors. You can also create a Purposit celebratory e-card
              comprised of all messages from all contributors, which can be
              shared with the gift receiver.
            </p>
            <h3>What is a “Gift List Page” event?</h3>
            <p className="paragraph">
              The Gift List Page is designed for organisers to create and share
              a webpage displaying a list of gift ideas for someone. This option
              caters for those organisers who only want to create an online gift
              list that can be a linked to an invitation or simply shared with
              friends.
              <br />
              <br />
              This allows users to use another invite platform to create an
              invitation for a party or occasion and just need the gift list
              page from Purposit. This is a popular option among parents
              organising events for their children (eg. birthdays, Xmas) or
              siblings organising gifts their parents (eg. wedding anniversary).
              <br />
              <br />
              You (organiser) will be able to track how funding for each gift is
              progressing, view contributors, send thank you cards and also
              create a Purposit celebratory e-card with the messages from all
              contributors.
              <br />
              <br />
              You can add one or more gifts to your receiver’s list. The more
              items you add, the longer it may take for the presents to be fully
              funded. We recommend between 3 and 5 gifts maximum with 1-2 items
              related to items needed in the future. Having a number of gift
              ideas give your invitees discretion to choose the gifts that
              better resonate with them.
              <br />
              <br />
              If your gifts are not funded as planned, do not worry. You can use
              the same gift or gifts for multiple events or until they are fully
              funded. This is particularly helpful for organisers who want to
              raise funds for long-dated, costly gifts.
            </p>
            <h3>What is a “Party Invitation” event?</h3>
            <p className="paragraph">
              This is our original event option and a favourite among parents
              funding gifts for their children. Organisers can create an
              invitation for a party and add a list of gif ideas to it so your
              invitees can chip in to any of them, if they want.
              <br />
              <br />
              Party Invitation allows you (organisers) to completely manage an
              occasion or celebration directly from the app. You can create an
              invitation for a party - using one of our many templates-, set
              location and time, request invitees to RSVP, track who has
              contributed and send thank you cards.
              <br />
              <br />
              Organisers can also upload photos and videos for this event so
              friends and family can see, like and comment on them. All the
              media you upload will also appear on the MEMORIES tab where an
              album will be created for this event.
              <br />
              <br />
              You will also be able to create and share our Purposit celebratory
              e-card with all messages from contributors.
              <br />
              <br />
              You can add one or more gifts to your party invitation. The more
              items you include, the longer it may take for the presents to be
              fully funded. We recommend between 3 and 5 gifts maximum with 1-2
              items related to items needed in the future. Having a number of
              gift ideas give your invitees discretion to choose the gifts that
              better resonate with them.
              <br />
              <br />
              Depending on the gifts you selected (and their timeframe), the
              same gifts can be displayed in several events over time. The gifts
              do not ‘expire’ with the events so you can keep raising money for
              a gift for as long as needed.
              <br />
              <br />
              Importantly, you can use different gifts for different events.
              Each time an event is created, you can select gifts from your
              existing list or you can create a new gift specifically for that
              event.
            </p>
            <h3>How many gifts should an organiser add to an event?</h3>
            <p className="paragraph">
              With the exception of the Fund One Gift event, where you can only
              have one present linked, you can add as many gifts as you like for
              other types of events. Remember that, the more gifts you include,
              the longer it will take for any of these gifts to get fully
              funded.
              <br />
              <br />
              Instead of giving your friends/contributors too many options, we
              suggest having 3-5 gifts per event. That way, it is very clear to
              your invitees (and potential contributors) that you have selected
              the gifts that matter the most to the receiver or receivers.
            </p>
            <h3>How many people should I invite to an event?</h3>
            <p className="paragraph">
              You can invite as many as you want. The right number will depend
              on the type of event you created or for whom the gift is for.
              <br />
              <br />
              Example, if the event was created to fund a gift for a teacher at
              school, you will likely invite all the parents of the kids on your
              child’s class, hence the number can be 15-30.
              <br />
              <br />
              Regardless of the number, we suggest keeping it private and
              restricted to those people who would usually give a gift on that
              occasion or to that person. Rather than sending blast invites to
              all your social media friends, focus on the real friends and the
              relatives with whom you have a close bond and genuinely care about
              the receiver or receivers of the gift(s). <br />
              <br />
              Also note that your friends and family can contribute from
              anywhere in the world. While they cannot download the app, they
              can use the website and have access to all the features. So don’t
              forget to invite grandma living overseas! She will certainly love
              to be part of that.
            </p>
            <h3>How many times can I send the same invite to friends?</h3>
            <p className="paragraph">
              As long as the event has not passed, you can send the invite to
              friends as many times as you want.
              <br />
              <br />
              If you are using the app on mobiles, you can send the invites
              directly from your own, personal email. That allows you to
              personal the message for each receiver. If can also track who you
              have invited by checking your mailbox (SENT items folder).
              <br />
              <br />
              The app allows you to invite friends by email and social media.
              You can also simply copy a link to the invite page or download a
              QR code.
              <br />
              <br />
              Note that if you are using the website, there is a limit of 750
              characters on the email invitation field, which should allow you
              to enter 25-30 emails. If you need to invite more friends or enter
              more emails, you can always do that by clicking on the banner
              INVITE FRIENDS on the EVENT DETAILS page.
              <br />
              <br />
              If your event has passed, you can no longer invite friends unless
              you go to EDIT and change the date of the event. The exception is
              for Fund One Gift events, where you can continue to invite friends
              until the gift is fully funded.
            </p>
            <h3>Can I have multiple events running at the same time?</h3>
            <p className="paragraph">
              Absolutely! You can create as many events as you like and you can
              also have multiple events running at the same time. Here is an
              example:
              <br />
              <br />
              Mark’s 8th birthday is coming soon. Mark’s mum wants to invite his
              classmates for a cake celebration at school. She also wants to
              have a small gathering of family and friends at their home. Mark’s
              mum can simply (1) create one birthday event for school - and send
              the invite to the parents of her son’s classmates -, and (2)
              create another event inviting family and friends to come to Mark’s
              party at their home.
              <br />
              <br />
              Importantly, those who are invited to the first event (school
              celebration) cannot see the details of the second event (home
              party), unless they were invited to both events. This way, the
              organiser can tailor the event to a specific audience.
            </p>
            <h3>Can I remove a gift from an event?</h3>
            <p className="paragraph">
              Yes, as long as no contribution has been made to that gift under
              that event yet, you can. If a contribution has been made, you can
              no longer remove it. That said, you can always edit the gift if
              needed.
            </p>
            <h3>
              Can a friend make a contribution to a gift outside an event?
            </h3>
            <p className="paragraph">
              Sure thing! If a friend has contributed to a previous gift for the
              receiver(s), and that friend has also signed up to Purposit (for
              free!), he or she will be able to follow the profile of the
              receiver(s). This allows that friend to see the receiver’s
              profile, see his or her own past contributions to that receiver,
              check how the funding of each gift is progressing, and also
              contribute towards any of the existing unfunded gifts at any time.
            </p>
            <h3>Can friends and family contribute from overseas?</h3>
            <p className="paragraph">
              Yes. Our secure payment system - provided and managed by Stripe -
              caters for international credit cards so relatives and close
              friends living abroad can contribute. They are not required to
              sign up to Purposit to contribute. But if they do, they will also
              be able to follow the receiver’s profile, see photos and videos,
              and post comments on events. That’s a great way for those far away
              to feel like they are still in the loop and are part of the
              receiver or receivers’ circle, despite the distance.
            </p>
            <h3>
              Can friends make contributions without signing up to Purposit?
            </h3>
            <p className="paragraph">
              Absolutely, your invitees are no required to sign up to the app to
              make a contribution. They can simply click on the invitation link
              or code and be directed to the event page where they can make a
              contribution and write a message for the organiser or gift
              receiver. Once a contribution is made, they have the option to
              sign up so they can follow the profile of the gift receiver, if
              they wish.
            </p>
            <h3>Can organisers upload photos and videos of events?</h3>
            <p className="paragraph">
              Sure! In fact, we encourage organisers to add photos and videos to
              events. The more personalised and interesting you make the gift
              receiver’s page, the more appealing it will be to your friends and
              family.
              <br />
              <br />
              Once an event is created, you (organiser) can add media – photos
              and videos – on the MEMORIES tab. Just look for the album related
              to the event you are interested and click on it.
              <br />
              <br />
              Your friends can see these photos/videos and also make comments on
              them. That helps create small, private social networks around the
              gift receiver(s) comprised only of those people who truly care for
              him/her/they (and care enough to contribute towards their gifts).
              <br />
              <br />
              Note that a friend will only be able to see and comment on photos
              and videos that were added to events (albums) to which they were
              invited. That keeps each event private and restricted to only
              those who were invited to it.
            </p>
            <h3>
              Can I as an organiser send thank you cards to my contributors?
            </h3>
            <p className="paragraph">
              Yes. Purposit was designed with the goal of making the experience
              between organiser, contributor and gift receiver as personal as
              possible.
              <br />
              <br />
              Once a contribution towards a gift is made, the organiser will
              receive a notification of the contribution and will be prompted to
              send a personalised thank you card to the contributor. The
              organiser can add photos, video and messages to make it more
              personal and meaningful for the contributor.
              <br />
              <br />
              When a gift reaches 100% of funding, the organiser also receives a
              notification and has the ability to send a thank you card to
              everyone who contributed to that gift over time – all in one go.
              <br />
              <br />
              For convenience, the organiser also has the option to send
              automatic thank you cards once a contribution is received. This
              option suits events with large number of invitees. That said, once
              a gift is fully funded the organiser will be prompted to send the
              thank you all card manually (one card for all).
            </p>
            <h3>What is a Purposit celebratory e-card?</h3>
            <p className="paragraph">
              To make the Purposit experience even more personal and memorable
              for everyone involved, we developed a proprietary celebratory
              card.
              <br />
              <br />
              Our special e-card is comprised of all messages sent by the
              contributors. It includes video and written messages from everyone
              and the organiser can share it with the gift receiver(s). This is
              the perfect event card that everyone can cherish and keep forever.
              <br />
              <br />
              You (organiser) can request an e-card when you create an event.
              Even if the event has passed, you can still request one to grant
              the gift receiver(s) with a nice memory.
              <br />
              <br />
              For everyone who contributed and signed up to Purposit, the e-card
              will be available and stored on both the EVENT DETAILS page as
              well as on the album of the event (under MEMORIES tab). It also
              appears under the Thank You Cards tab.
              <br />
              <br />
              We charge a small fee to create the card as it requires manual
              work as well as storage for the media. But priced cheaper than a
              regular card, this will certainly be a hit with your friends and
              gift receivers. <br />
              <br />
              Please note that the e-card feature may not be available in
              certain markets and it is currently under development, so we will
              let you know once this personalized feature is available in your
              region.
            </p>
            <h3>
              Who can see the profile of the person or people I created an event
              for?
            </h3>
            <p className="paragraph">
              You as the organiser are fully in control here and decide who can
              see the profile of your gift receiver(s). Only those friends who
              are invited by you to events can see the profile of that receiver.
              Purposit does not allow anyone to invite him/herself to ‘connect
              with someone else’s profile. Instead, the organisers are the ones
              controlling the process, keeping it private and personal.
            </p>
            <h3>
              What information can friends see on the profile I created for a
              gift receiver(s)?
            </h3>
            <p className="paragraph">
              Friends can see all the gifts you created for your gift receiver
              and how funding of each one of these gifts is progressing. Note
              that your friends cannot see the actually costs of the gifts
              unless you have opted to make that visible.
              <br />
              <br />
              Friends can never see how much money has been raised. Instead,
              they can see how much progress has been made in % terms for each
              gift (towards 100% funding) and can also see their own
              contributions to these gifts.
              <br />
              <br />
              In addition, friends can see details of the event(s) they were
              invited to, including photos or videos that the organiser may have
              uploaded. Note that friends can only see the events they were
              invited to.
            </p>
            <h3>
              Once a friend is invited to an event, can that friend see all
              events and photos that exist on that person’s or people’ profile?
            </h3>
            <p className="paragraph">
              No, a friend/contributor can only see the events he or she was
              invited to. The friend/contributor can only see the photos related
              to the event he or she was invited to. That provides the organiser
              with the tools to tailor events to each audience and to control
              exactly what friends/contributors will be able to see on the
              profile page of the gift receiver(s).
            </p>
            <h3>What is the Memories tab?</h3>
            <p className="paragraph">
              This is where you will see all the albums related to the events
              you create. Every event will trigger the creation of an album.
              <br />
              <br />
              There you can upload photos and videos of that event, so your
              invitees can see and comment on them. You can also request or view
              the Purposit celebratory e-card there. It’s literally like an
              album with some fun memories of that event and it what represented
              for you, you invitees and the person or people receiving the
              gift(s).
              <br />
              <br />
              You can also archive or hide the album if you do not want to have
              that on display for your invitees.
            </p>
            <h3>Can I delete gifts I created for someone? </h3>
            <p className="paragraph">
              Yes you can, but only if no more than one contribution has been
              received. Once you receive multiple contributions for that event,
              it would be unfair to the contributions if suddenly the gift
              disappeared.
              <br />
              <br />
              That said, you can always edit the gift, change the type of gift
              if needed or the cost of it if circumstances change. You can also
              hide the gift from your screen if you like. In that case, your
              contributors will no longer be able to contribute to it.
            </p>
            <h3>Can I delete events I created for someone? </h3>
            <p className="paragraph">
              Yes, but only if no more than one contribution has been made to
              that event. Once multiple contributions have been made, it would
              be unfair to your contributors if the event suddenly disappeared.
              <br />
              <br />
              You can however edit it or archive it if you want, In the case,
              the event will still be visible to your contributors.
            </p>
            <h3>Can I delete the profile of a gift receiver I created? </h3>
            <p className="paragraph">
              Yes, but we highly discourage that if multiple contributions have
              been made under that gift receiver’s profile. Once you delete a
              profile, all information including gifts, events, contributions,
              thank you cards will be permanently deleted from our app.
              Contributors to this profile will be notified that you opted to
              delete it and will no longer be able to see any information about
              it.
              <br />
              <br />
              Instead, we suggest you hide or archive the profile instead. Under
              this scenario, contributors can no longer contribute to gifts but
              can still see the profile on their feed. You can restore it
              whenever you want on the main Menu button.
              <br />
              <br />
              However, you can always hide or archive the profile so it does not
              appear on your screen anymore. The contribution on the other side
            </p>
            <br />
            <h1>Getting & Managing Contributions </h1>
            <h3>Are my funds secure?</h3>
            <p className="paragraph">
              Purposit was designed to keep the personal and financial
              information of users the safest it can possibly be. Purposit
              engaged worldwide payment gateway provider Stripe, with its
              bank-grade security systems and its proprietary encryption
              software, to process all financial transactions and safely store
              the banking details of our users. Purposit’s platform also
              incorporates SSL certification and an encryption algorithm to
              provide further protection to users in regards to their personal
              information.
            </p>
            <h3>Where does the money contributed by friends go?</h3>
            <p className="paragraph">
              The money raised for each gift will be securely sent (via our
              payment gateway provider Stripe) to the bank account the organiser
              nominated to receive the funds.
            </p>
            <h3>
              When does the organiser receive the contributions in their
              designated account?
            </h3>
            <p className="paragraph">
              After a person makes a contribution for a given gift, the order
              will be processed, and the money will typically become available
              on the organiser’s designated bank account after 1-2 business days
              in Australia. Alternatively, the organiser can also set the
              transfer to be made once a week. To change it, go to MENU &gt;
              BANKING DETAILS and edit the account linked to the gift
              receiver(s).
            </p>
            <h3>
              Do I need to be 13yo or over to add my bank account details and
              receive contributions for gifts?
            </h3>
            <p className="paragraph">
              Yes, as per the guidelines of our payment gateway provider Stripe.
              If you were under 13yo, you are not authorised to create events or
              add bank details. Under those circumstances, the best approach
              would be to ask one of your own parents or guardians to enter
              their bank account details and manage the contributions for you.
              Once you turn 13, you can replace their account details with yours
              if you have one.
            </p>
            <h3>
              Do I need to have an Australian bank account to receive funds?
            </h3>
            <p className="paragraph">
              At the moment, yes. To receive funds an organiser is required to
              link its bank details so our payment gateway provider Stripe can
              complete the transfer. We are constantly looking at other, equally
              secure ways to allow organisers to receive contributions and hope
              to have other options available in the near future.
            </p>
            <h3>What payment methods does Purposit accept?</h3>
            <p className="paragraph">
              Purposit accepts credit card payments from Visa, Master Card and
              American Express processed through our payment gateway provider
              Stripe, Inc. Stripe is used worldwide and is well regarded for its
              secure way of sending money and managing financial information.
            </p>
            <h3>
              Are the contributions made by friends subject to getting the gift
              100% funded?
            </h3>
            <p className="paragraph">
              No. Once the payments are processed, the contributions - less any
              fees payable to Purposit and Stripe - are paid directly to the
              bank account specified by the relevant organiser, irrespective of
              whether the targeted amount for the gift has been reached or not.
            </p>
            <h3>
              Can I see and track how much money I’ve raised as an organiser?
            </h3>
            <p className="paragraph">
              Absolutely. On the gifts tab under the profile of your gift
              receiver, you can see how much money has been raised for each gift
              and how far these gifts are from reaching 100%. You can also see
              who has contributed to each gift.
            </p>
            <h3>Can friends see how much money I’m raising for a gift?</h3>
            <p className="paragraph">
              The contributors can see how much progress has been made towards
              completing each gift in % terms. Contributors can only see the
              actual value of the gift if you opt to let them see it.
              <br />
              <br />
              We tend to recommend not to show the cost of the gift unless
              really necessary. Instead of focusing on dollar amount, we think
              it’s important to make the experience about the contribution to
              the gift itself and the journey to get to 100% funding.
            </p>
            <h3>Can every contributor see how much money others are giving?</h3>
            <p className="paragraph">
              No. Every contributor will have different financial circumstances
              and their contributions will reflect that. We do not believe that
              creating peer pressure - by letting everyone see how much others
              are giving - is in the spirit of the platform. The only person who
              can see all contributions is the organiser who is organising the
              event(s) and managing the gifts for the receiver.
            </p>
            <h3>What fees do I pay as an organiser?</h3>
            <p className="paragraph">
              None. Organiser do not pay any fees for using the platform. Small
              fees apply only to contributors in relation to costs associated
              with the payment gateway and platform-related costs. Download of
              the app is also done free of charge.
              <br />
              <br />
              The only time when an organiser may incur a fee is if they request
              a Purposit celebratory e-card after an event has passed. For
              e-cards requested before the event has passed, the fee will be
              added to the cost of gift and shared by the contributors.
            </p>
            <h3>What fees do I pay as a contributor?</h3>
            <p className="paragraph">
              Contributions incur a small convenience fee of 2.9% plus 90c which
              help Purposit cover processing fees as well as costs related to
              management and maintenance of the platform. International cards
              may also be subject to foreign exchange fees. There is no
              subscription fee for users of the app or website.
            </p>{' '}
            <h3>Can I get a refund?</h3>
            <p className="paragraph">
              We strongly encourage users of the platform to only make
              contributions to organisers they know and trust. If a contributor
              considers himself or herself entitled to a refund, we encourage
              the contributor to contact the organiser directly. If both parties
              cannot agree on a refund, please contact us and we will do our
              best to help. Please also be aware that the fees you may have paid
              with your contribution as well as the fee associated with the
              e-card are non-refundable.
            </p>
          </div>
        </GiftCard>
      </Container>
    </>
  );
}
