import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import Button from '~/components/Button';
import { OuterContainer, Container, Balloons } from './styles';
import { getJWTToken } from '~/utils/utility';

export default function RSVPClosedConfirmed(props) {
  const location = useLocation();
  const { colors } = useContext(ThemeContext);
  const { path } = useParams();
  const tokenData = JSON.parse(getJWTToken());
  const checkRSPV = location.state && location.state;

  const handleSubmit = () => {
    localStorage.removeItem('rspv_data');
    localStorage.removeItem('event_name');

    history.push('/');
  };

  const handleClose = () => {
    localStorage.removeItem('rspv_data');
    localStorage.removeItem('event_name');

    history.push('/my-profile');
  };

  return (
    <OuterContainer>
      <Container colors={{ start: colors.purple, end: colors.pink }}>
        <div>
          <Balloons />
          {checkRSPV && checkRSPV.rspv === 'rspvChecked' ? (
            <h1> Fantastic, we can’t wait to see you at the event!.</h1>
          ) : checkRSPV && checkRSPV.rspv === 'norspv' ? (
            <h1> Thanks again and hope to see you next time!.</h1>
          ) : checkRSPV && checkRSPV.rspvAttend === 'contAttend' ? (
            <h1> Fantastic, we can’t wait to see you at the event!.</h1>
          ) : checkRSPV && checkRSPV.rspvAttend === 'contNotAttend' ? (
            <h1> Thanks again and hope to see you next time!</h1>
          ) : checkRSPV &&
            checkRSPV.rspvAttend === 'venue_no_attending_rspv' ? (
            <h1> Thanks again and hope to see you next time!.</h1>
          ) : checkRSPV && checkRSPV.rspvAttend === 'venue_norspv' ? (
            <h1> Thanks again and hope to see you next time!</h1>
          ) : checkRSPV && checkRSPV.rspvAttend === 'no_venue' ? (
            <h1> Thanks again and hope to see you soon!</h1>
          ) : (
            <h1>
              {props && props.data ? 'No worries.' : ''} We hope to see you
              soon!.
            </h1>
          )}

          {tokenData && tokenData.token !== null ? (
            <h3>
              Meanwhile, you can see this event on Purposit and check how
              it’s&nbsp; progressing.
            </h3>
          ) : (
            <h3>
              If you would like to follow this event, see photos and/or make a
              contribution, sign up to Purposit. It’s&nbsp;free.
            </h3>
          )}
          {tokenData && tokenData.token !== null ? (
            <Button
              text="Close"
              textColor="#fff"
              buttonFunction={handleClose}
              backgroundColor={colors.plum}
            />
          ) : (
            <Button
              text="Sign Up / Login"
              textColor="#fff"
              buttonFunction={handleSubmit}
              backgroundColor={colors.plum}
            />
          )}
        </div>
      </Container>
    </OuterContainer>
  );
}
