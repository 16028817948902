const gifts = [
  {
    id: 1,
    name: 'Mountain Bike',
    date: 'by 10th Birthday',
    price: 720,
    desc: 'Lorem ipsum dolor sit amet, consectetuer and then some adipiscing elit, sed...',
  },
  {
    id: 2,
    name: 'Miniature Train',
    date: 'by 10th Birthday',
    price: 720,
    desc: 'Lorem ipsum dolor sit amet, consectetuer and then some adipiscing elit, sed...',
  },
  {
    id: 3,
    name: 'Mountain Bike',
    date: 'by 10th Birthday',
    price: 720,
    desc: 'Lorem ipsum dolor sit amet, consectetuer and then some adipiscing elit, sed...',
  },
];

export default gifts;
