import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fab from '@material-ui/core/Fab';
import { IoIosAdd } from 'react-icons/io';
import giftImage from '../../../assets/images/gifts.svg';
// import Context from '~/context';
import history from '~/services/history';
import EmptyList from '../EmptyList';
import {
  Container,
  GiftCard,
  LoaderDiv,
  LoadingText,
  Container1,
} from './styles';
import { convertUTCDateToString } from '~/utils/utility';
import CircularIndeterminate from '~/components/Loader';

const giftRaised = (gift) => {
  const contributions = gift.contributor_contributions
    .map((cont) => parseInt(cont.contribution))
    .reduce((a, b) => a + b, 0);
  return contributions;
};

const giftRaisedEvent = (gift, eventId) => {
  const contributionsForEvent = gift.contributor_contributions
    // .filter((cont) => cont.event_id === eventId)
    .map((cont) => parseInt(cont.contribution))
    .reduce((a, b) => a + b, 0);

  return contributionsForEvent;
};

export default function Gifts() {
  const gifts = useSelector((state) => state.receiverReducer.gifts);
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const checkedOnboardingGift = localStorage.getItem('onbordingGift');
  const checkedOnboarding = localStorage.getItem('onbording');
  const [loader, setLoader] = useState(false);
  const getFollow = localStorage.getItem('follow');
  const events = useSelector((state) => state.receiverReducer.events);

  useEffect(() => {
    setLoader(true);
    if (gifts && gifts) {
      setLoader(false);
    }
  });

  const handleAddGift = () => {
    if (
      checkedOnboardingGift === 'laterGift' ||
      checkedOnboarding === 'later'
    ) {
      history.push('/add-gift');
    } else {
      const nextPage = gifts.length ? '/add-gift' : '/first-gift';
      history.push(nextPage);
    }
  };
  const handleSubmit = (giftId) => {
    let latestEventId = null;
    let latestEventDate = new Date(0); // Initialize with a very old date
    events.forEach((event) => {
      event.event_gifts.forEach((gift) => {
        if (gift.gift_id === giftId) {
          const eventDate = new Date(event.event_date);
          if (eventDate > latestEventDate) {
            latestEventDate = eventDate;
            latestEventId = event.id;
          }
        }
      });
    });

    history.push(`/gift-details/${giftId}?eventId=${latestEventId}`);
    // history.push(`/gift-details/${giftId}?eventId=${eventId}`);
  };
  return getFollow && getFollow !== null ? (
    <>
      {events && events.length > 0 ? (
        <Container1>
          {!loader ? (
            <Container>
              {gifts.map((item) => {
                return (
                  <GiftCard key={item.id} onClick={() => handleSubmit(item.id)}>
                    <div>
                      {item && (
                        <img
                          key={item.id}
                          src={
                            item.gift_images.length > 0 &&
                            item.gift_images[0].url !== null
                              ? item.gift_images[0].url
                              : giftImage
                          }
                          alt={item.name}
                        />
                      )}
                      <div>
                        <div>
                          <div>
                            <h3>{item.name}</h3>
                            <small>{convertUTCDateToString(item.date)}</small>
                          </div>
                          <div>
                            {getFollow && getFollow !== null ? (
                              ''
                            ) : (
                              <span className="gift-price">${item.price}</span>
                            )}
                          </div>
                        </div>
                        <div>
                          <p>{item.desc}</p>
                        </div>
                        <div>{/* <small>Event : {event.title}</small> */}</div>
                      </div>
                    </div>

                    <div className="progress-bar">
                      <LinearProgress
                        variant="determinate"
                        value={(giftRaisedEvent(item) / item.price) * 100}
                      />
                      <div>
                        <span>
                          {Number(
                            (
                              (giftRaisedEvent(item) / item.price) *
                              100
                            ).toFixed(2),
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  </GiftCard>
                );
              })}
              {/* )} */}
            </Container>
          ) : (
            <LoaderDiv>
              <CircularIndeterminate />
              <LoadingText>Loading...</LoadingText>
            </LoaderDiv>
          )}
        </Container1>
      ) : (
        <EmptyList>
          Click below to add one or more gifts for{' '}
          {receiver && receiver.first_name}.
        </EmptyList>
      )}
      {getFollow && getFollow !== null ? (
        ''
      ) : (
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => handleAddGift()}
          className={gifts && gifts.length ? '' : 'show-indicator'}
        >
          <IoIosAdd color="#fff" size={45} />
        </Fab>
      )}
    </>
  ) : (
    <>
      {gifts && gifts.length > 0 ? (
        <Container1>
          {!loader ? (
            <Container>
              {gifts.map((gift) => (
                <GiftCard
                  key={gift.id}
                  onClick={() => history.push(`/gift-details/${gift.id}`)}
                >
                  <div>
                    <img
                      src={
                        gift.gift_images.length > 0 &&
                        gift.gift_images[0].url !== null
                          ? gift.gift_images[0].url
                          : giftImage
                      }
                      alt={gift.name}
                    />
                    <div>
                      <div>
                        <div>
                          <h3>{gift.name}</h3>
                          <small>{convertUTCDateToString(gift.date)}</small>
                        </div>
                        <div>
                          {getFollow && getFollow !== null ? (
                            ''
                          ) : (
                            <span className="gift-price">${gift.price}</span>
                          )}
                        </div>
                      </div>
                      <div>
                        <p>{gift.desc}</p>
                      </div>
                    </div>
                  </div>
                  <div className="progress-bar">
                    <LinearProgress
                      variant="determinate"
                      value={(giftRaised(gift) / gift.price) * 100}
                    />
                    <div>
                      {getFollow && getFollow !== null ? (
                        <span></span>
                      ) : (
                        <span>${giftRaised(gift)}</span>
                      )}
                      <span>
                        {Number(
                          ((giftRaised(gift) / gift.price) * 100).toFixed(2),
                        )}
                        %
                      </span>
                    </div>
                  </div>
                </GiftCard>
              ))}
            </Container>
          ) : (
            <LoaderDiv>
              <CircularIndeterminate />
              <LoadingText>Loading...</LoadingText>
            </LoaderDiv>
          )}
        </Container1>
      ) : (
        <EmptyList>
          Click below to add one or more gifts for{' '}
          {receiver && receiver.first_name}.
        </EmptyList>
      )}
      {getFollow && getFollow !== null ? (
        ''
      ) : (
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => handleAddGift()}
          className={gifts && gifts.length ? '' : 'show-indicator'}
        >
          <IoIosAdd color="#fff" size={45} />
        </Fab>
      )}
    </>
  );
}
