import styled from 'styled-components';

export const TextInput = styled.input`
  font-size: 18px;
  margin-bottom: 15px;
  max-width: 330px;
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : '#fff')};
  border-radius: 0;

  &::placeholder {
    color: #b7b7b7;
  }

  &.error {
    border: 1px solid ${({ theme }) => theme.colors.red};
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
`;

export const Label = styled.label`
  font-size: 15px;
  margin-bottom: 10px;
  color: #888;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Info = styled.span`
  margin-left: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: inline-block;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
`;
