const initialState = {
    userCreditCardDetails: null,
  };
  
  export default function creditCardReducer(state = initialState, action) {
    switch (action.type) {
      case 'ADD_CREDIT_CARD':
        if (action.data) {
          return { ...state, userCreditCardDetails: true };
        }
        return state;
      default:
        return state;
    }
  }
  