import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 25px;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    padding: 25px 20px;
    margin: 0;
    min-height: calc(100% - 135px);
    justify-content: initial;

    button {
      margin-bottom: 30px;
    }
  }

  p,
  a {
    text-transform: none;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: #888;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  a {
    font-size: 17px;
    margin: 0 auto;
  }

  button {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;

  &:first-of-type {
    margin-bottom: 20px;
    max-height: 340px;
    overflow: scroll;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const Card = styled.div`
  background: white;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  width: 100%;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > div:first-child {
    display: flex;
    align-items: center;
  }

  img {
    width: 55px;
    height: 55px;
    min-width: 55px;
    border-radius: 28px;
    object-fit: cover;
    object-position: center;
    margin-right: 20px;

    @media (max-width: 767px) {
      margin-right: 10px;
    }
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }
`;
