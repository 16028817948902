import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { getNotificationByUserId } from '~/store/modules/notification/notificationAction';

import history from '~/services/history';

// import notifications from './notifications';

import NotificationCard from '~/components/NotificationCard';
import { Badge, NotificationList, Scroll } from './styles';

export default function Notifications() {
  const Dispatch = useDispatch();
  const node = useRef();
  const badge = useRef();
  const [hasUnread, setHasUnread] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const profile = useSelector((state) => state.userReducer.profile);
  const notifications = useSelector(
    (state) => state.notificationReducer.notifications,
  );
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target) || badge.current.contains(e.target)) {
      return;
    }
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if (!notifications) Dispatch(getNotificationByUserId(profile.id));
  // }, []);

  const handleShowNotifications = () => {
    setHasUnread(true);
    if (isMobile) {
      history.push('/notifications');
    } else {
      setVisible(!visible);
    }
  };

  const filteredNotifications =
    notifications &&
    notifications.filter((notification) => notification.user_id === profile.id);

  return (
    <>
      <Badge
        hasUnread={hasUnread}
        color="#ff00ff"
        onClick={(e) => handleShowNotifications(e)}
        ref={badge}
        count={filteredNotifications && filteredNotifications.length}
      >
        <IoIosNotificationsOutline size={32} />
      </Badge>
      <NotificationList visible={visible} ref={node}>
        <Scroll onClick={() => setVisible(false)}>
          {filteredNotifications &&
            filteredNotifications.map((notification) => (
              <NotificationCard
                key={notification.notification_id}
                notification={notification}
              />
            ))}
        </Scroll>
      </NotificationList>
    </>
  );
}
