import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import moment from 'moment';
import history from '~/services/history';
// Action
import {
  addEventInvites,
  addEmailDetails,
} from '~/store/modules/event/eventAction';
import { toast } from 'react-toastify';
import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';
import { Container, RadioControl, RadioButton } from './styles';
import { sendEmail } from '~/api/userAPI';
import SubHeaderButtonWithLoader from '~/components/SubHeader/SubHeaderButtonWithLoader';
import { bankListById } from '~/store/modules/bankAccount/bankAccountAction';

export default function EmailDetails() {
  const Dispatch = useDispatch();
  const location = useLocation();
  const { colors } = useContext(ThemeContext);
  const [emailSubject, setEmailSubject] = useState('');
  const [reminder, setGender] = useState('7');
  const [previousDate, setPreviousDate] = useState('');
  const userProfile = useSelector((state) => state.userReducer.profile);
  const eventId = useSelector(
    (state) => state && state.eventReducer.event.data,
  );
  const bankAccountReducer = useSelector((state) => state.bankAccountReducer);
  const { userBankDetails, userCardDetails } = bankAccountReducer;

  const { id: userId, email: emailId } = userProfile;
  const invite_data = location.state && location.state;
  const [loader, setLoader] = useState(false);

  const parseCustomDate = (customDate) => {
    const parsedDate = moment(customDate, 'dddd Do MMMM YYYY', true);
    return parsedDate.isValid() ? parsedDate : null;
  };
  const getDetail = useSelector((state) => state);
  const handleSubmit = () => {
    const childId = getDetail && getDetail.receiverReducer.receiver;
    // if (userBankDetails === null) {
    //   toast.error('Your bank account must be added first!');
    // } else {
    if (previousDate === '') {
      const parsedDate = parseCustomDate(invite_data.data.inviteData.eventDate);
      const sevenDaysAgoDate = parsedDate.clone().subtract(7, 'days');
      const sevenDate = sevenDaysAgoDate.format('yyyy-MM-DD HH:mm:ss');
      const data = {
        user_id: userId,
        child_id: childId && childId.id,
        title: invite_data.data.inviteData.eventName,
        event_id: eventId.id,
        event_time: invite_data.data.inviteData.event_time
          ? invite_data.data.inviteData.event_time
          : 'null',
        event_date: invite_data.data.inviteData.eventDate,
        sendmail_date: sevenDate,
      };
      history.push('/email-list', { emailDetail: data });
    } else {
      const data = {
        user_id: userId,
        child_id: childId && childId.id,
        title: invite_data.data.inviteData.eventName,
        event_id: eventId.id,
        event_time: invite_data.data.inviteData.event_time
          ? invite_data.data.inviteData.event_time
          : 'null',
        event_date: invite_data.data.inviteData.eventDate,
        sendmail_date: previousDate,
      };
      history.push('/email-list', { emailDetail: data });
    }
    // }
    // Dispatch(addEventInvites(data));
  };

  const handleGenderChange = (event) => {
    const parsedDate = parseCustomDate(invite_data.data.inviteData.eventDate);
    if (event.target.value === '1') {
      setGender(event.target.value);
      const oneDaysAgoDate = parsedDate.clone().subtract(1, 'days');

      const oneDate = oneDaysAgoDate.format('yyyy-MM-DD HH:mm:ss');
      setPreviousDate(oneDate);
    } else if (event.target.value === '3') {
      setGender(event.target.value);
      const threeDaysAgoDate = parsedDate.clone().subtract(3, 'days');
      const threeDate = threeDaysAgoDate.format('yyyy-MM-DD HH:mm:ss');
      setPreviousDate(threeDate);
    } else if (event.target.value === '7') {
      setGender(event.target.value);

      const sevenDaysAgoDate = parsedDate.clone().subtract(7, 'days');
      const sevenDate = sevenDaysAgoDate.format('yyyy-MM-DD HH:mm:ss');
      setPreviousDate(sevenDate);
    }
  };

  useEffect(() => {
    Dispatch(bankListById(userId));
  }, [userId]);

  const handleMailSend = async () => {
    if (userBankDetails === null) {
      toast.error('Your bank account must be added first!');
    } else {
      setLoader(true);
      let inviteDetail = invite_data.data.inviteData;
      inviteDetail.email = emailId;
      inviteDetail.event_id = eventId.id;
      inviteDetail.user_id = userId;

      if (inviteDetail) {
        await sendEmail(inviteDetail)
          .then((ea) => {
            setLoader(false);
            toast.success('Mail sent successfully');
          })
          .catch((err) => {
            setLoader(false);
            console.log('err', err);
          });
      }
    }
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
        buttonFunction={handleSubmit}
        buttonText="Next"
      >
        <h2>Email Details</h2>
        <h3>Review email title and add a reminder for your invitees.</h3>
      </SubHeader>
      <Container>
        <Input
          name="subject"
          label="Event Title"
          value={
            emailSubject === ''
              ? invite_data && invite_data.data.inviteData.eventName
              : emailSubject
          }
          onChange={(e) => setEmailSubject(e.target.value)}
          placeholder="Jack's 8th Birthday"
        />

        <RadioControl component="fieldset">
          <FormLabel component="legend">Send reminder to invitees</FormLabel>
          <RadioGroup
            aria-label="reminder"
            name="reminder1"
            value={reminder}
            onChange={handleGenderChange}
          >
            <FormControlLabel
              value="7"
              control={<RadioButton />}
              label="7 days before event"
            />
            <FormControlLabel
              value="3"
              control={<RadioButton />}
              label="3 days before event"
            />
            <FormControlLabel
              value="1"
              control={<RadioButton />}
              label="1 day before event"
            />
          </RadioGroup>
        </RadioControl>
        {!loader ? (
          <Button
            text="Send me a test invite"
            backgroundColor={colors.pink}
            textColor="#fff"
            buttonFunction={() => {
              handleMailSend();
            }}
          />
        ) : (
          <SubHeaderButtonWithLoader
            backgroundColor={'grey'}
            text={'Send me a test invite'}
            textColor={'white'}
            disableBoolean={true}
          />
        )}
      </Container>
    </>
  );
}
