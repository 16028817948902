import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    width: 100%;
    padding: 20px;
    margin: 0;
    min-height: calc(100% - 57px);
    justify-content: initial;
  }

  p {
    color: #666;
    margin-bottom: 40px;
  }

  button {
    max-width: 100%;
  }

  h2 {
    color: ${({ theme }) => theme.colors.lightPurple};
    text-align: center;
    margin-bottom: 20px;
  }

  small:first-of-type {
    color: #bfbfbf;
  }
`;

export const Card = styled.div`
  background: #fff;
  text-align: center;
  margin-top: 50px;
  position: relative;
  padding: 60px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50px;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
  }

  h3 {
    font-size: 20px;
  }

  span {
    color: #666;

    &.large {
      font-size: 20px;
      line-height: 22px;
    }
  }
`;

export const Column = styled.div`
  flex: 1;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;

  > span {
    color: #666;

    &.large {
      font-size: 20px;
    }
  }

  svg {
    max-width: 96px;
    margin-bottom: 20px;
  }

  &:last-of-type {
    span:last-of-type {
      margin-bottom: 20px;
      flex: 1;
    }
  }
`;
