import LilGirl from '~/assets/images/lilgirl.jpg';

const memories = [
  {
    id: 1,
    image: LilGirl,
    title: 'Birthday',
    date: '12 Jan 2020',
  },
  {
    id: 2,
    image: LilGirl,
    title: 'Birthday',
    date: '12 Jan 2020',
  },
  {
    id: 3,
    image: LilGirl,
    title: 'Birthday',
    date: '12 Jan 2020',
  },
  {
    id: 4,
    image: LilGirl,
    title: 'Birthday',
    date: '12 Jan 2020',
  },
  {
    id: 5,
    image: LilGirl,
    title: 'Birthday',
    date: '12 Jan 2020',
  },
];

export default memories;
