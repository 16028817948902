import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import main from '../themes/main';

import textaAltRegular from '~/assets/fonts/textaaltregular.ttf';
import textaAltBook from '~/assets/fonts/textaaltbook.ttf';
import textaAltBold from '~/assets/fonts/textaaltbold.ttf';
import textaAltHeavy from '~/assets/fonts/textaaltheavy.ttf';

export default createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

  @font-face {
    font-family: 'Text Alt';
    font-weight: 400;
    src: url(${textaAltRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Text Alt';
    font-weight: 300;
    src: url(${textaAltBook}) format('truetype');
  }

  @font-face {
    font-family: 'Text Alt';
    font-weight: 600;
    src: url(${textaAltBold}) format('truetype');
  }

  @font-face {
    font-family: 'Text Alt';
    font-weight: 800;
    src: url(${textaAltHeavy}) format('truetype');
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Text Alt', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, .MuiTypography-root.MuiTypography-body1 {
    font-family: 'Text Alt', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 14px;
  }

  .MuiPickersToolbar-toolbar {
    background-color: ${main.colors.primary};

    .MuiTypography-root {
      color: ${main.colors.secondary};
      &.MuiTypography-subtitle1 {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.75;
    }

      &.MuiTypography-h4 {
      font-size: 2.125rem;
      font-weight: 400;
      line-height: 1.235;
      }
    }
  }

  .MuiPickersModal-dialogRoot {
    border-radius: 0;

    .MuiTypography-root, .MuiButton-textPrimary, .MuiTypography-root.MuiTypography-body1 {
      color: #122a7d;

      &.MuiPickersToolbarText-toolbarBtnSelected {
        color: #fff;
        border-radius: 5px;
      }
    }

    .MuiPickersDay-dayDisabled {

      .MuiTypography-root {
        color: #bdbdbd;
      }
    }

    .MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding h2 {
      font-size: 60px;
    }
  }

  .MuiTypography-root, .MuiListItem-root {
      font-size: 17px;
      color: #888;
      font-weight: bold;

    &.MuiTypography-body1, &.MuiFormControlLabel-label {
      font-size: 17px;
      color: #888;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  body {
    .MuiInput-underline:before {
      border-bottom: 0;
    }

    .MuiFilledInput-root {
      background: #fff;
      box-shadow: 0px 0px 10px 0px #00000021;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;

      &:hover {
        background-color: #fff;
      }

      &:before,
      &:after {
        display: none;
      }

      .MuiFilledInput-input {
        padding: 18px 12px 18px;
      }
    }

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
      color: #888;
      font-weight: bold;

      &.Mui-focused {
        color: #888;
      }

      &.MuiFormLabel-filled {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  input {
    border-radius: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
  }

  .hide-mobile {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .hide-desktop {
    @media (min-width: 768px) {
      display: none;
    }
  }


.czDIUw > div .other-options.open {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
  pointer-events: initial !important;
  position: static !important;
  margin-top: 0px !important;
}

.czDIUw > div > div.open, .czDIUw > div button.open {
  height: initial !important;
  background: white !important;
  margin-bottom: 10px !important;
}

.czDIUw > div .other-options .options {
  /* background: none !important; */
  padding: 15px !important;
  display: grid !important;
  grid-template-columns: auto auto !important;
  grid-template-columns: repeat(3,1fr) !important;
  grid-gap: 5px !important;
  min-height: 150px !important;
  height: auto !important;
}

.czDIUw > div > div{
  background: none !important !important;
}
.AYgvT > div:first-child{
  background: white !important;
}
.czDIUw > div .other-options.open {
  display: block !important;
  height: 300px !important;
  opacity: 1 !important;
  pointer-events: initial !important;
  position: static !important;
  margin-top: 0px !important;
  overflow-y: auto !important;
}

.czDIUw > div > div:nth-child(even) .open, .czDIUw > div button:nth-child(even) .open{
  transform: translateX(calc(-0% - 0px)) !important;
}
.czDIUw > div button {
  height: auto !important;
  max-width: calc(50% - 10px);
  margin-bottom: 20px;
  width: 100%;
}
.czDIUw > div .other-options .options button p {
  font-weight: bold !important;
  color: #888 !important;
  display: flex !important;
  margin-left: 4px !important;
  min-width: 90px !important;
  text-align: start !important;
  min-height: 40px !important;
}

.iAvHoz > div .other-options.open {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
  pointer-events: initial !important;
  position: static !important;
  margin-top: 0px !important;
}

.iAvHoz > div > div.open, .iAvHoz > div button.open {
  height: initial !important;
  background: white !important;
  margin-bottom: 10px !important;
}

.iAvHoz > div .other-options .options {
  /* background: none !important; */
  padding: 15px !important;
  display: grid !important;
  grid-template-columns: auto auto !important;
  grid-template-columns: repeat(3,1fr) !important;
  grid-gap: 5px !important;
  min-height: 150px !important;
  height: auto !important;
}

.iAvHoz > div > div{
  background: none !important !important;
}
.AYgvT > div:first-child{
  background: white !important;
}

.iAvHoz > div .other-options.open {
  display: block !important;
  height: 300px !important;
  opacity: 1 !important;
  pointer-events: initial !important;
  position: static !important;
  margin-top: 0px !important;
  overflow-y: auto !important;
}

.iAvHoz > div > div:nth-child(even) .open, .iAvHoz > div button:nth-child(even) .open{
  transform: translateX(calc(-0% - 0px)) !important;
}
.iAvHoz > div button {
  height: auto !important;
  max-width: calc(50% - 10px);
  margin-bottom: 20px;
  width: 100%;
}
.iAvHoz > div .other-options .options button p {
  font-weight: bold !important;
  color: #888 !important;
  display: flex !important;
  margin-left: 4px !important;
  min-width: 90px !important;
  text-align: start !important;
  min-height: 40px !important;
}

body .MuiFilledInput-root .MuiFilledInput-input {
  // padding: 18px 18px 18px !important;
  font-size: 17px !important;
  color: black !important;
}
.icCvsL {
  max-width: inherit !important;
  min-height: auto !important;
}
.image-wrapper.modal-open {
  width: 350px !important;
}
.eDRIxX > div:first-child {
  padding-right: 10px !important;
}
.iYqHoQ {
  max-width: inherit !important;
  min-height: auto !important;
}
.description-wrapper {
  margin-right: 12px !important;
}
img.sc-bwzfXH.ewmzCr {
  height: 50px;
  width: 50px;
}
img.eventimage {
  max-width: 400px;
  display: flex;
  align-items: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  height: 230px;
}
svg.close {
  position: fixed;
  right: 20px;
  top: 2px;
  color: white;
  cursor: pointer;
  background: #000000c9;
  border-radius: 33px;
  width: 25px;
  height: 25px;
}
svg.closepop {
  position: absolute;
  color: white;
  cursor: pointer;
  background: #000000c9;
  border-radius: 33px;
  width: 25px;
  height: 25px;
}
.buttonIcons{
  width: 50px !important;
  max-width: 50px !important;
  height: 50px !important;
  margin:0px !important
}
.icontext{
  text-align: center;
  margin-top: 4px;
  font-size: 15px;
}
.overlay{
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #181818e6; /* Black with 50% opacity */
  z-index: 999; /* Ensure the overlay is above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}
.marginbutton{
  margin-top:18px !important;
}
.loadercss svg.MuiCircularProgress-svg {
  margin-right: 0px !important;
  color: red;
}

.loadercss .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate {
  margin-right: 7px;
}

.fabicon{
  right: calc(50% - 430px);
    bottom: 80px;
    position: fixed;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.memoriesbtn {
  background: #768ed1;
  border-radius: initial;
}
svg.mdcss {
  font-size: 29px !important;
}
.MuiFormControl-root.MuiTextField-root.btntextfield {
  width: calc(100% - 80px);
  padding: 5px !important;
}
input.inputfilecss {
  width: 47px;
  height: 44px;
  border-radius: 72px;
}
input#inputfilecss {
  width: 47px;
  height: 44px;
  border-radius: 72px;
}
.commentclass {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 108px !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}
.fabicon label button span:first-child {
  position: relative;
}

.fabicon label button span:first-child input {
  min-width: 56px;
  height: 50px;
  position: absolute;
  z-index: 5;
  opacity: 0;
}

span.fabaddcss {
  margin-top: 10px;
}
a.marginremove {
  margin-bottom: 0px !important;
}
h2.notempty {
  font-size: 20px;
}

button.sendcard{
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  background: #01e3fa;
  border: none;
}
.sendcard .MuiBox-root-6 {
  display: flex;
  padding: 16px;
}
.imagecss img.sc-bwzfXH.cWaAJq {
  width: 100%;
}
.chlidprofile img.sc-bwzfXH.ewmzCr {
  height: 50px;
  width: 100%;
}
.loader.sc-fyjYeE.bBfrUY {
  display: flex;
  justify-content: center;
}

button.sc-eNQAEJ.fuRzsb.allCard {
  margin-top: 5px;
}

.Toastify__toast--success {
  border: 1px solid #e5e5e5 !important;
  background: linear-gradient(159deg, rgb(77, 43, 139), rgb(237, 30, 121)) !important;
}
.Toastify__toast--warning {
  border: 1px solid #e5e5e5 !important;
  background: linear-gradient(159deg, rgb(77, 43, 139), rgb(237, 30, 121)) !important;
}

.Toastify__toast--error {
  border: 1px solid #e5e5e5 !important;
  background: linear-gradient(159deg, rgb(77, 43, 139), rgb(237, 30, 121)) !important;
}
.Toastify__toast-container {;
  color: white !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.Toastify__close-button {
  color: white !important;
}
.custom-progress-bar {
  background-color: #f6f6f6 !important;
}
a.forgotancher {
  text-decoration: underline;
}
.forgotpassword1 {
  padding: 0px 1px 6px;
  margin-top: -10px;
}
h3.forgotSuccess {
  padding: 16px;
}

`;
