import React, { useState } from 'react';

import WillAttend from './WillAttend';
import WontAttend from './WontAttend';
import NoResponse from './NoResponse';

import SubHeader from '~/components/SubHeader';

import { Container, StyledTabs as Tabs, StyledTab as Tab } from './styles';

function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EventRSVPs() {
  const tabs = [<WillAttend />, <WontAttend />, <NoResponse />];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(tabs[newValue]);
    setValue(newValue);
  };

  return (
    <>
      <SubHeader mobileDescVisible={false}>
        <h2>Event Contributors</h2>
      </SubHeader>

      <Tabs value={value} onChange={handleChange} aria-label="Event RSVPs Tab">
        <Tab label="Will Attend" key={a11yProps(0).key} id={a11yProps(0).id} />
        <Tab label="Won't Attend" key={a11yProps(1).key} id={a11yProps(1).id} />
        <Tab label="No Response" key={a11yProps(2).key} id={a11yProps(2).id} />
      </Tabs>
      <Container>{activeTab}</Container>
    </>
  );
}
