import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io';
import LilGirl from '~/assets/images/lilgirl.jpg';

import { Container, Media, Likes, Comment } from './styles';

import { useDispatch, useSelector } from 'react-redux';
// import Button from '~/components/Button';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MdSend } from 'react-icons/md';
import {
  addMEmoriesComment,
  createMemoriesLike,
} from '~/store/modules/event/eventAction';

export default function Post() {
  const Dispatch = useDispatch();
  const { postId, eventId } = useParams();
  const { colors } = useContext(ThemeContext);
  const memories = useSelector((state) => state.eventReducer.memoriesData);
  const [comments, setComments] = useState('');
  const userIdd = useSelector((state) => state.userReducer.profile.id);
  const [loader, setLoader] = useState(false);
  const [likeLoader, setLikeLoader] = useState(false);

  const matchingMemory =
    memories &&
    memories.find(
      (memory) =>
        memory.event_id === parseInt(eventId) && memory.id === parseInt(postId),
    );

  const handleClick = () => {
    setLoader(true);
    if (comments !== '') {
      const payload = {
        event_memories_id: parseInt(postId),
        user_id: userIdd,
        comments: comments,
        child_id: matchingMemory.child_id,
      };

      try {
        Dispatch(addMEmoriesComment(payload));
        setTimeout(() => {
          setLoader(false);
          setComments('');
        }, 3000);
      } catch (error) {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const handleLike = async (item) => {
    setLikeLoader(true);
    const payload = {
      event_memories_id: item.id,
      user_id: userIdd,
      is_like: 1,
      child_id: item.child_id,
    };
    Dispatch(createMemoriesLike(payload));
    setTimeout(() => {
      setLikeLoader(false);
    }, 3000);
  };

  return (
    <Container>
      <div key={matchingMemory.id}>
        <Media src={matchingMemory.url} />
        <Likes className="loadercss">
          {!likeLoader ? (
            matchingMemory &&
            matchingMemory.likes &&
            matchingMemory.likes.some((like) => like.user_id === userIdd) ? (
              <IoMdHeart
                size={20}
                color={'red'}
                style={{ cursor: 'pointer' }}
                onClick={() => handleLike(matchingMemory)}
              />
            ) : (
              <IoMdHeartEmpty
                size={20}
                color={'red'}
                style={{ cursor: 'pointer' }}
                onClick={() => handleLike(matchingMemory)}
              />
            )
          ) : (
            <CircularProgress size={20} />
          )}
          {matchingMemory && matchingMemory.likesCount} friends liked this.
        </Likes>
        <div
          className={`${
            matchingMemory &&
            matchingMemory.comments &&
            matchingMemory.comments.length > 2
              ? 'commentclass'
              : ''
          }`}
        >
          {matchingMemory &&
            matchingMemory.comments.map((comment) => (
              <Comment key={comment.comments}>
                <img
                  src={comment.user_image ? comment.user_image : LilGirl}
                  alt={'image'}
                />
                <div>
                  <strong>{comment.user_name}</strong>
                  <span>{comment.comments}</span>
                </div>
              </Comment>
            ))}
        </div>
        <div
          style={{
            display: 'flex',
            border: '2px solid #4b5cab',
            width: 'auto',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          className="textfieldcss"
        >
          <TextField
            label=""
            variant="standard"
            style={{ padding: '1px' }}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="btntextfield"
          />
          {!loader ? (
            <Button
              variant="contained"
              color="#3851b7"
              endIcon={<MdSend className="mdcss" />}
              onClick={handleClick}
              className="memoriesbtn"
            ></Button>
          ) : (
            <Button
              variant="contained"
              color="#3851b7"
              endIcon={<CircularProgress size={29} color="blue" />}
              className="memoriesbtn"
            ></Button>
          )}
        </div>
      </div>
    </Container>
  );
}
