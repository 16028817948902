import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;

  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
  }
`;

export const MediaWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 20px;
  }
`;

export const Media = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #c1c4c4;
  padding-bottom: 100%;
  position: relative;
`;

export const Close = styled.div`
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background: #bfbfbf;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  width: 100%;
  padding: 20px;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input {
    font-size: 18px;
    margin-bottom: 0;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }
`;
