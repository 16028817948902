import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import Context from '~/context';

// Action
import { deleteReceiver } from '~/store/modules/receiver/receiverAction';

import { ReactComponent as TrashCan } from '~/assets/images/trash-can.svg';
import { ReactComponent as TrashCant } from '~/assets/images/trash-cant.svg';

import { Container } from './styles';

export default function DeleteProfile({ receiver }) {
  const { receiverId } = useParams();
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const isDeleted = useSelector((state) => state.receiverReducer.isDeleted);
  const { gifts } = receiver;
  const contributor_contributions = gifts.filter(
    (cont) => cont.contributor_contributions.length > 0,
  );

  const hasContribution = contributor_contributions.length !== 0;
  const { first_name, type, group_name } = receiver;
  const formattedName =
    type === 'individual'
      ? first_name[first_name.length - 1] === 's'
        ? `${first_name}'`
        : `${first_name}'s`
      : group_name[group_name.length - 1] === 's'
      ? `${group_name}'`
      : `${group_name}'s`;

  const handleDeleteProfile = () => {
    Dispatch(deleteReceiver(receiverId));
  };
  if (isDeleted) {
    history.push({
      pathname: '/success',
      state: {
        destination: `/my-profile`,
        message: 'Profile Deleted!',
        colors: { start: colors.lightPurple, end: colors.pink },
      },
    });
  }

  return (
    <Container>
      {hasContribution ? (
        <>
          <TrashCant />
          <h2>Sorry, you are unable to delete this receiver(s) profile.</h2>
          <p>
            This profile cannot be deleted because you have received more than
            one contribution to gifts you created for this receiver(s).
          </p>
          <p>
            We recommend you archive the receiver(s) profile once all gifts are
            funded and events related to this receiver have expired.
          </p>

          <button type="submit" onClick={() => handleShowModal()}>
            Close
          </button>
        </>
      ) : (
        <>
          <TrashCan />
          <h2>Are you sure you want to delete {formattedName} profile?</h2>
          <p>
            All information about the profile, gifts, contributors, events,
            photos, cards and memories will be permanently deleted.
          </p>
          <p>
            All followers will no longer be able to view the profile. We
            recommend archiving the profile instead. That way you can always
            retreive it and your friends can continue to view it.
          </p>

          <button type="submit" onClick={() => handleShowModal()}>
            Cancel
          </button>
          <button
            type="submit"
            className="red"
            onClick={() => handleDeleteProfile()}
          >
            Delete
          </button>
        </>
      )}
    </Container>
  );
}
