import React, { useState, useContext, useEffect } from 'react';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import { IoIosTrash } from 'react-icons/io';

import history from '~/services/history';
import { toast } from 'react-toastify';
import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import { Container, Card, InputColumn, InputWrapper } from './styles';
import { useLocation } from 'react-router-dom';

export default function EmailList() {
  const location = useLocation();
  const email_data = location && location.state;
  const { colors } = useContext(ThemeContext);
  const [contacts, setContacts] = useState([]);
  const [emails, setEmails] = useState('');
  const [errors, setErrors] = useState({ textarea: false });

  const validateEmail = (email) =>
    /* eslint-disable no-useless-escape */
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    );
  const handleAddToList = () => {
    const emailsArray = emails.replace(/\s+/g, '').split(',');

    const validEmails = emailsArray
      .filter((email) => validateEmail(email) && { email })
      .map((email) => ({ id: email, email }));

    if (validEmails.length > 0) {
      setErrors({ textarea: false });

      const allContacts = [...contacts, ...validEmails];

      const flags = new Set();
      const uniqueContacts = allContacts.filter((contact) => {
        if (flags.has(contact.email)) {
          return false;
        }
        flags.add(contact.email);
        return true;
      });

      setContacts(uniqueContacts);
    } else {
      setErrors({ textarea: true });
    }
  };

  const handleRemoveContact = (id) => {
    setContacts(contacts.filter((contact) => contact.id !== id));
  };
  const handleMove = () => {
    if (contacts.length !== 0) {
      history.push({
        pathname: '/review-contacts',
        state: { contacts, email_data },
      });
    } else {
      toast.error('enter alteast one email!');
    }
  };

  useEffect(() => {
    if (email_data.emailDetail && email_data.checked === 'true') {
      setContacts(email_data.contacts);
    }
  }, []);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonText="Next"
        buttonTextColor="#fff"
        buttonFunction={() => handleMove()}
      >
        <h2>Email List</h2>
        <h3>
          Paste or enter your list of emails, separated by commas or semicolons.
        </h3>
      </SubHeader>
      <Container>
        <InputColumn>
          <InputWrapper>
            <textarea
              rows={8}
              placeholder="Enter your emails here..."
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              className={errors.textarea ? 'error' : ''}
            />
          </InputWrapper>

          <Button text="Add To List" buttonFunction={() => handleAddToList()} />
        </InputColumn>

        <InputColumn>
          {contacts.length === 0 && <h3>No contacts added.</h3>}
          <>
            {contacts.map((contact) => (
              <Card key={contact.email}>
                <div>
                  <span>{contact.email}</span>
                </div>
                <div>
                  <IoIosTrash
                    size={20}
                    color="#888"
                    onClick={() => handleRemoveContact(contact.id)}
                  />
                </div>
              </Card>
            ))}
          </>
        </InputColumn>
      </Container>
    </>
  );
}
