import React, { useState, useContext, useEffect } from 'react';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';

import history from '~/services/history';

import contactsList from './contactsList';
import { useSelector } from 'react-redux';
import { getContributionById } from '~/api/userAPI';
import CircularIndeterminate from '~/components/Loader';
import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import profileImage from '~/assets/images/avatar-placeholder.png';

import {
  Container,
  InputColumn,
  InputWrapper,
  CheckboxControl,
  Card,
} from './styles';
import { useLocation } from 'react-router-dom';
import { addThankyouCardImage, createThankyouGift } from '~/api/giftAPI';
import { uploadGiftCardImage } from '~/api/imageAPI';
import SubHeaderButtonWithLoader from '~/components/SubHeader/SubHeaderButtonWithLoader';

export default function AddRecipients() {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [giftData, setGiftData] = useState(null);
  const targetReached = localStorage.getItem('targetReached');
  const [giftId, setGiftId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [itemSelect, setItemSelect] = useState([]);
  const recipient = location && location.fileData;
  const profile = useSelector((state) => state.userReducer.profile);

  const [loaderButton, setLoaderButton] = useState(false);

  const card_type = localStorage.getItem('type');

  const handleContactCheck = (item) => {
    setGiftData(
      giftData.map((contact) => ({
        ...contact,
        checked:
          contact.contribution_id === item.contribution_id
            ? !contact.checked
            : contact.checked,
      })),
    );
    setItemSelect((prevItemSelect) => {
      if (item.checked) {
        return Array.isArray(prevItemSelect)
          ? prevItemSelect.filter(
              (selectedItem) =>
                selectedItem.contribution_id !== item.contribution_id,
            )
          : [];
      } else {
        return Array.isArray(prevItemSelect)
          ? [...prevItemSelect, item]
          : [item];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (giftData) {
      setGiftData(
        giftData.map((contact) => ({
          ...contact,
          checked: !selectAll && true,
        })),
      );
      setItemSelect((prevItemSelect) => {
        if (!selectAll) {
          const allItems = giftData.map((contact) => contact);
          return Array.from(new Set([...prevItemSelect, ...allItems]));
        } else {
          return [];
        }
      });
    }
  };
  useEffect(() => {
    contributionById(
      (notificationData && notificationData.gift_id) ||
        (recipient && recipient.giftId),
    );
    localStorage.removeItem('contributionIds');
  }, [notificationData]);

  const contributionById = async (id) => {
    setLoader(true);
    setGiftId(id);
    try {
      await getContributionById(id).then((res) => {
        if (res && res.data) {
          setGiftData(
            res.data.contributor_contributions.map((contact) => ({
              ...contact,
              checked: false,
              visible: true,
            })),
          );
          setLoader(false);
        }
      });
    } catch (error) {
      console.log('err', error);
      setLoader(false);
    }
  };

  const handleSend = async () => {
    if (itemSelect === null || (itemSelect && itemSelect.length === 0)) {
      toast.error('Please select one or more recipients');
    } else {
      const description = recipient.description;
      const selectFile = recipient.selectFile;
      let redirectOccurred = false;
      setLoaderButton(true);

      try {
        for (const ea of itemSelect) {
          const payload = {
            description: description,
            user_id: ea.user_id,
            sender_id:
              card_type && card_type === 'multiple'
                ? profile.id
                : notificationData.sender_id,
            contributer_id: ea.contribution_id,
            card_type:
              card_type && card_type === 'multiple'
                ? 'fully funded'
                : 'contributed funded',
          };

          const res = await createThankyouGift(giftId, payload);
          const formData = new FormData();
          formData.append('file', selectFile);

          const uploadImageResponse = await uploadGiftCardImage(
            giftId,
            formData,
          );
          const imageData = {
            card_id: res.data.id,
            url: uploadImageResponse.data,
          };

          await addThankyouCardImage(imageData);
        }

        redirectOccurred = true;
      } catch (error) {
        console.error('Error during processing:', error);
      } finally {
        setLoaderButton(false);

        if (redirectOccurred) {
          card_type && card_type === 'multiple'
            ? history.push({ pathname: '/thank-you-sent', giftId, tab: 'tab3' })
            : history.push({
                pathname: '/thank-you-sent',
                giftId,
              });
        }
      }
    }
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        mobileDescVisible={false}
        backButtonFunction={
          () =>
            targetReached && targetReached === 'yes'
              ? history.push('/create-thank-you-cards')
              : card_type && card_type === 'multiple'
              ? history.push('/send-thank-you-card')
              : history.push('/gift-funded')
          // history.goBack()
        }
      >
        <h2>Add Recipients</h2>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <InputColumn>
              <InputWrapper>
                <div>
                  <CheckboxControl
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Select all past contributors to this gift."
                  />
                </div>
              </InputWrapper>
            </InputColumn>
            <InputColumn className="list">
              <div>
                {giftData && giftData.length > 0 ? (
                  <>
                    {giftData.map((contact) => (
                      <CheckboxControl
                        key={contact.contribution_id} // Use 'key' instead of 'id'
                        control={
                          <Card>
                            <div>
                              <img
                                src={
                                  contact.user.avatar
                                    ? contact.user.avatar
                                    : profileImage
                                }
                                alt={`${contact.user.first_name} ${contact.user.last_name}`}
                              />
                              <div>
                                <h3>{`${contact.user.first_name} ${contact.user.last_name}`}</h3>
                              </div>
                            </div>

                            <div>
                              <Checkbox
                                checked={contact && contact.checked}
                                onChange={() => handleContactCheck(contact)} // Use 'key' instead of 'id'
                                name={toString(contact.contribution_id)} // Use 'key' instead of 'id'
                                color="primary"
                              />
                            </div>
                          </Card>
                        }
                      />
                    ))}
                  </>
                ) : (
                  <h3>No contacts found.</h3>
                )}
              </div>
              {!loaderButton ? (
                <Button text="Send" buttonFunction={handleSend} />
              ) : (
                <SubHeaderButtonWithLoader
                  backgroundColor={'grey'}
                  text={'Send'}
                  textColor={'white'}
                  disableBoolean={true}
                />
              )}
            </InputColumn>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: 'auto',
            }}
          >
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}
