import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import guests from './guests';

import { ReactComponent as WillAttend } from '~/assets/images/notification-icon-5.svg';
import { ReactComponent as WontAttend } from '~/assets/images/notification-icon-6.svg';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import Avatar from '~/components/Avatar';

import { Container, Column, Card } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getEventCount } from '~/api/eventAPI';
import CircularIndeterminate from '~/components/Loader';

export default function PartyRSVP() {
  const { colors } = useContext(ThemeContext);
  const { id } = useParams();
  const location = useLocation();
  const Dispatch = useDispatch();
  const getData = location && location.data;
  const [giftData, setGiftData] = useState('');
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [loader, setLoader] = useState(false);
  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : JSON.parse(getData && getData.data);

  useEffect(() => {
    getUserDetail(parsedData.gift_id);
  }, [parsedData.gift_id]);

  const getUserDetail = async (id) => {
    const payload = {
      user_id: parsedData && parsedData.userId,
      event_id: parsedData && parsedData.event_id,
    };
    setLoader(true);
    try {
      await getEventCount(payload).then((res) => {
        setGiftData(res.data);
        setLoader(false);
      });
    } catch (error) {
      console.log('err', error);
      setLoader(false);
    }
  };

  return (
    <>
      <SubHeader backgroundColor={colors.purple} mobileDescVisible={false}>
        <h2>Party RSVP&apos;s</h2>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <Column>
              <h2>
                {parseInt(id) === 1
                  ? 'Guest Confirmed!'
                  : 'Sorry I can’t make it!'}
              </h2>
              <Card>
                <Avatar
                  image={
                    giftData && giftData.user ? giftData.user.user_image : ''
                  }
                  size={100}
                />
                <h3>
                  {(parsedData && parsedData.parentName) ||
                    (giftData && giftData.user && giftData.user.first_name)}
                  {(parsedData && parsedData.parentLastName) ||
                    (giftData && giftData.user && giftData.user.last_name)}
                </h3>
                <span>
                  {parseInt(id) === 1
                    ? 'will be attending'
                    : "won't be attending"}
                </span>
                <span className="large">
                  {parsedData && parsedData.childName}&apos;s Birthday Party
                </span>
              </Card>
            </Column>
            <Column>
              {<WillAttend />}
              <span className="large">
                {giftData &&
                  giftData.attendingCount &&
                  giftData.attendingCount.length}{' '}
                Guests
              </span>
              <span>have now confirmed their attendance</span>
              <Button
                text="VIEW EVENT DETAILS"
                buttonFunction={() =>
                  history.push(
                    `/event-details/${parsedData && parsedData.event_id}`,
                    { childId: parsedData.child_id },
                  )
                }
              />
            </Column>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}
