import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import Button from '~/components/Button';
import { OuterContainer, Container, OpenBox } from './styles';
import { getJWTToken } from '~/utils/utility';
import { useLocation } from 'react-router-dom';

export default function FollowEvent() {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const tokenData = JSON.parse(getJWTToken());
  const userData = location.state && location.state;
  const event_names = localStorage.getItem('event_name');

  const handleSubmit = async () => {
    if (userData.checked === 'true') {
      localStorage.removeItem('rspv_data');
      localStorage.removeItem('event_name');

      history.push('/');
    } else {
      localStorage.removeItem('rspv_data');
      localStorage.removeItem('event_name');

      history.push('/register', {
        name: userData.name,
        email: userData.email,
      });
    }
  };

  return (
    <OuterContainer>
      <Container colors={{ start: colors.purple, end: colors.pink }}>
        <div>
          <OpenBox />
          <h1>Follow {event_names && event_names ? event_names : "Eugene’s"} event on Purposit!</h1>
          <h3>
            If you would like to follow this event, see photos and/or make a
            contribution, sign up to Purposit. It’s free.
          </h3>
          {tokenData && tokenData.token ? (
            <Button
              text="Close"
              textColor="#fff"
              buttonFunction={() => history.push('/')}
              backgroundColor={colors.plum}
            />
          ) : (
            <>
              <Button
                text="Sign Up / Login"
                textColor="#fff"
                buttonFunction={handleSubmit}
                backgroundColor={colors.plum}
              />
            </>
          )}
        </div>
      </Container>
    </OuterContainer>
  );
}
