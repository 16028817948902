import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import { IoIosAdd } from 'react-icons/io';
import { convertUTCDateToString } from '~/utils/utility';
import history from '~/services/history';
import EmptyList from '../EmptyList';
import { Container } from './styles';
import openGift from '../../../assets/images/open-gift.svg';

export default function Events() {
  const events = useSelector((state) => state.receiverReducer.events);
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const getFollow = localStorage.getItem('follow');

  const handleAddEvent = () => {
    history.push('/event-goal');
  };

  const sortedEvents = events && events.sort(
    (a, b) => new Date(a.event_date) - new Date(b.event_date),
  );

  return (
    <>
      {events && events.length > 0 ? (
        <Container>
          {getFollow && getFollow !== null
            ? sortedEvents &&
              sortedEvents.map((event) => (
                <Link to={`/event-details/${event.id}/`} key={event.id}>
                  <div className="event-card">
                    {receiver.events_image.map(
                      (data) =>
                        data.id === event.id && (
                          <span key={data.id}>
                            {data.event_invitations.map((ea) => (
                              <span key={ea.id}>
                                <img
                                  src={ea.image !== null ? ea.image : openGift}
                                  alt={event.title}
                                />
                              </span>
                            ))}
                          </span>
                        ),
                    )}
                    {receiver.events_image.map(
                      (data) =>
                        data.id === event.id && (
                          <span key={data.id}>
                            {data.event_invitations.length === 0 ? (
                              <img src={openGift} alt={event.title} />
                            ) : (
                              ''
                            )}
                          </span>
                        ),
                    )}
                    <div>
                      <div>
                        <div>
                          <h3>{event.title}</h3>
                          <small>
                            {convertUTCDateToString(event.event_date)}
                          </small>
                        </div>
                      </div>
                      <div>
                        <span>
                          <strong>{event.event_invites.length}</strong> friends
                        </span>
                        <span>
                          <strong>
                            {event.contributor_contributions.length}
                          </strong>{' '}
                          contributors
                        </span>
                        <span>
                          <strong>{event.event_gifts.length}</strong> gifts
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            : events.map((event) => (
                <Link to={`/event-details/${event.id}/`} key={event.id}>
                  <div className="event-card">
                    {receiver.events_image.map(
                      (data) =>
                        data.id === event.id && (
                          <span key={data.id}>
                            {data.event_invitations.map((ea) => (
                              <span key={ea.id}>
                                <img
                                  src={ea.image !== null ? ea.image : openGift}
                                  alt={event.title}
                                />
                              </span>
                            ))}
                          </span>
                        ),
                    )}
                    {receiver.events_image.map(
                      (data) =>
                        data.id === event.id && (
                          <span key={data.id}>
                            {data.event_invitations.length === 0 ? (
                              <img src={openGift} alt={event.title} />
                            ) : (
                              ''
                            )}
                          </span>
                        ),
                    )}
                    <div>
                      <div>
                        <div>
                          <h3>{event.title}</h3>
                          <small>
                            {convertUTCDateToString(event.event_date)}
                          </small>
                        </div>
                      </div>
                      <div>
                        <span>
                          <strong>{event.event_invites.length}</strong> friends
                        </span>
                        <span>
                          <strong>
                            {event.contributor_contributions.length}
                          </strong>{' '}
                          contributors
                        </span>
                        <span>
                          <strong>{event.event_gifts.length}</strong> gifts
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
        </Container>
      ) : (
        <EmptyList>
          Click below to create your first event for{' '}
          {receiver && receiver.first_name} and start inviting friends
        </EmptyList>
      )}
      {getFollow && getFollow !== null ? (
        ''
      ) : (
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => handleAddEvent()}
          className={events && events.length ? '' : 'show-indicator'}
        >
          <IoIosAdd color="#fff" size={45} />
        </Fab>
      )}
    </>
  );
}
