import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import Context from '~/context';

import history from '~/services/history';

import DeleteEvent from '../DeleteEvent';
import ArchiveEvent from '../ArchiveEvent';

export default function Options({ eventId, userId }) {
  const { handleShowModal } = useContext(Context);
  const [selectedOption, setSelectedOption] = useState('');
  const event = useSelector((state) => state.eventReducer.event.data);
  const receiver = useSelector((state) => state.receiverReducer.receiver);

  let hasDonations = 0;
  if (event) {
    const { gift } = event;
    if (gift.length > 0) {
      hasDonations = gift.map((crr) => crr.raised);
    }
  }
  // const handleEditGift = () => {
  //   history.push(`/edit-gift/${5}`);
  //   handleShowModal();
  // };
  const handleEditEvent = () => {
    history.push('/edit-event', {
      type: 'gift',
      user: 'single',
      editgiftdata: event,
    });
    handleShowModal();
  };

  return (
    selectedOption || (
      <>
        {/* <button type="submit" onClick={handleEditGift}>
          Edit Gift
        </button> */}
        <button type="submit" onClick={handleEditEvent}>
          Edit Event
        </button>
        <button
          type="submit"
          onClick={() =>
            setSelectedOption(
              <ArchiveEvent eventId={eventId} receiverId={receiver.id}/>,
            )
          }
        >
          Archive Event
        </button>
        <button
          type="submit"
          className="red"
          onClick={() =>
            setSelectedOption(
              <DeleteEvent
                eventId={eventId}
                userId={userId}
                hasDonations={hasDonations}
              />,
            )
          }
        >
          Delete Event
        </button>
      </>
    )
  );
}
