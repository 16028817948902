import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  .inputfile {
    display: none;

    & + label {
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      color: #f1e5e6;
      display: flex;
      align-items: center;
      margin: 0;
      width: ${(props) => `${props.size}px`};
      height: ${(props) => `${props.size}px`};
      transition: background-color 0.3s;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }
    }

    &:focus + label,
    &.has-focus + label,
    & + label:hover {
      outline: none;
      background-color: ${darken(0.5, '#d5d5d5')};
    }
  }
`;
