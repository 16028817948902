import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 20px;
  text-align: center;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    width: 100%;
    padding: 20px;
    margin: 0;
    min-height: calc(100% - 135px);
    justify-content: initial;
  }

  p {
    color: ${({ theme }) => theme.colors.lightPurple};
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  button {
    max-width: 100%;
  }

  h2 {
    color: ${({ theme }) => theme.colors.lightPurple};
    text-align: center;
    margin-bottom: 20px;
  }

  small:first-of-type {
    color: #bfbfbf;
  }
`;

export const Card = styled.div`
  background: #fff;
  text-align: center;
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    font-size: 20px;
  }

  span {
    color: #666;

    &.large {
      font-size: 30px;
    }
  }
`;

export const Column = styled.div`
  flex: 1;

  > img {
    max-width: 100px;
    margin-bottom: 20px;
  }
`;
export const ContainerDiv = styled.div`
  padding: 10px 20px 10px 10px;
  background: #fff;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccd7db;
  display: flex;
  max-width: 320px;
  width: 100%;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 10px;
  }

  .image-wrapper {
    display: flex;
    align-items: center;

    img {
      max-width: 50px;
      max-height: 50px;
      margin-right: 10px;
      width: 100%;
    }
  }

  .description {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    span {
      color: #222627;
      font-size: 18px;
      font-weight: bold;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    small {
      color: #7a858a;
    }
  }
`;