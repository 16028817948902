import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';

import history from '~/services/history';
import { Mobile } from '~/config/ReactResponsiveConfig';

import Gifts from './Gifts';
import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import { toast } from 'react-toastify';

import { Container, TotalContainer, OutterWrapper } from './styles';
import CircularIndeterminate from '~/components/Loader';
import { getEventGiftById } from '~/api/eventAPI';
import {
  bankListById,
  cardDetailsByUserId,
} from '~/store/modules/bankAccount/bankAccountAction';
import { useDispatch, useSelector } from 'react-redux';

export default function ContributionOneGift() {
  const { colors } = useContext(ThemeContext);
  const [total, setTotal] = useState('0');
  const { giftId } = useParams();
  const [loader, setLoader] = useState(false);
  const [giftIds, setGiftIds] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [bothErrorMessage, setBothErrorMessage] = useState('');
  const [remainingAmount, setRemainingAmount] = useState('0');
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const user_id = queryParams.get('u');
  const [contriAmount, setContriAmount] = useState('');
  const [selectedGifts, setSelectedGifts] = useState([]);
  const eventName = user_id && user_id.split('?n=');
  const Dispatch = useDispatch();
  const splitByQuestionMark = eventName && eventName[1].split('?');
  const eventName1 = splitByQuestionMark && splitByQuestionMark[0];
  const queryParams1 = splitByQuestionMark[1];
  const rsvp = queryParams1 && queryParams1.split('=')[1];
  const getFollow = localStorage.getItem('follow');
  const bankAccountReducer = useSelector((state) => state.bankAccountReducer);
  const { userBankDetails, userCardDetails } = bankAccountReducer;
  const user = useSelector((state) => state.userReducer.profile);
  const userId = user && user.id;

  useEffect(() => {
    if (rsvp === 'no') {
      localStorage.setItem('rspv', rsvp);
    }
    Dispatch(bankListById(userId));
    Dispatch(cardDetailsByUserId(userId));
  }, []);

  const handleSubmit = () => {
    if (giftIds && giftIds.message) {
    } else if (total === '0') {
      toast.error('amount is required');
    } else if (getFollow !== null && userBankDetails === null) {
      const giftData = {
        subtotal: total,
        selectedGifts: selectedGifts,
      };
      localStorage.setItem('giftData', JSON.stringify(giftData));
      history.push({
        pathname: '/payment-details',
        state: {
          subtotal: total,
          selectedGifts: selectedGifts,
        },
      });
    } else if (getFollow !== null && userBankDetails !== null) {
      const giftData = {
        subtotal: total,
        selectedGifts: selectedGifts,
      };
      localStorage.setItem('giftData', JSON.stringify(giftData));
      history.push({
        pathname: '/card_payment',
      });
    } else if (total !== '0' && total !== 0 && errorMessage === '') {
      history.push({
        pathname: '/card-payment',
        state: {
          subtotal: total,
          selectedGifts: selectedGifts,
          check: rsvp ? rsvp : '',
        },
      });
    } else {
    }
  };

  const getGiftS = async (giftId) => {
    setLoader(true);
    await getEventGiftById(giftId)
      .then((res) => {
        setGiftIds(res);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getGiftS(giftId);
  }, [giftId]);

  return (
    <>
      <SubHeader
        mobileNavButtons={false}
        backgroundColor={colors.lightPurple}
        buttonText="Next"
        buttonBackgroundColor="#D28DC9"
        buttonTextColor="#fff"
        buttonFunction={() => handleSubmit()}
      >
        <h2>Contribution</h2>
        <h3>
          Great. Let’s enter the amount you wish to contribute towards{' '}
          {eventName1 && eventName1}.
        </h3>
      </SubHeader>
      <OutterWrapper>
        <Mobile>
          <Button text="Next" buttonFunction={() => handleSubmit()} />

          <TotalContainer>
            <div>
              <div>
                <h2>TOTAL AMOUNT CONTRIBUTED</h2>
              </div>
              <div>
                <span>{`$${total}`}</span>
              </div>
            </div>
            <p>
              No one can see how much you are contributing except the organiser
              of the event
            </p>
          </TotalContainer>
        </Mobile>
        <Container>
          {!loader ? (
            <>
              {giftIds && giftIds.message ? (
                <div>
                  <h2>{giftIds.message}</h2>
                </div>
              ) : (
                <Gifts
                  setTotal={setTotal}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  giftId={giftIds}
                  total={total}
                  event_id={giftId}
                  gift_id={giftIds}
                  user_id={user_id}
                  remainingAmount={remainingAmount}
                  setRemainingAmount={setRemainingAmount}
                  setContriAmount={setContriAmount}
                  contriAmount={contriAmount}
                  bothErrorMessage={bothErrorMessage}
                  setBothErrorMessage={setBothErrorMessage}
                  selectedGifts={selectedGifts}
                  setSelectedGifts={setSelectedGifts}
                  // setChecked={setChecked}
                  // check={check}
                />
              )}
            </>
          ) : (
            <CircularIndeterminate />
          )}
        </Container>
      </OutterWrapper>
    </>
  );
}
