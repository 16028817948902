import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { sendFCMToken } from '~/api/userAPI';

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
var firebaseConfig = {
  apiKey: 'AIzaSyBiF0DhJ8dPXqKn-EzJPjsrS-tzprUHmNE',
  authDomain: 'webapp-purposit2.firebaseapp.com',
  projectId: 'webapp-purposit2',
  storageBucket: 'webapp-purposit2.appspot.com',
  messagingSenderId: '48356236864',
  appId: '1:48356236864:web:68a5785a60e325e7cac9a5',
  measurementId: 'G-BY7MENPWLH',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BCnWYqWOv7yZvfQx_jtWJokdzK-OBxm_f4tjR_JTypjxJZfkkFfKeScd7q4fjSIXgmItStBeN82Ut3qmCJNIPTU',
  })
    .then(async (currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        const getId = localStorage.getItem('persist:purposit');
        const parsedData = JSON.parse(getId);

        // Access the token inside authReducer
        const user = parsedData.userReducer
          ? JSON.parse(parsedData.userReducer).profile
          : null;
        const payload = {
          user_id: user.id,
          firebase_token: currentToken,
        };
        await sendFCMToken(payload)
          .then((res) => {})
          .catch((err) => {
            console.log('err', err);
          });
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
