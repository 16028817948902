import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container1 = styled.div`
  padding: 10px 20px 10px 10px;
  background: #fff;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccd7db;
  display: flex;
  max-width: 420px;
  width: 100%;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 10px;
  }

  .image-wrapper {
    display: flex;
    align-items: center;

    img {
      max-width: 50px;
      max-height: 50px;
      margin-right: 10px;
      width: 100%;
    }
  }

  .description {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    span {
      color: #222627;
      font-size: 18px;
      font-weight: bold;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    small {
      color: #7a858a;
    }
  }
`;
export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }
  form {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const GiftCard = styled.div`
  background: white;
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;
  position: relative;

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 115px;

    img {
      width: 80px;
      height: 80px;
      min-width: 80px;
      object-fit: cover;
      object-position: center;
      margin-right: 20px;

      @media (max-width: 767px) {
        margin-right: 10px;
      }
    }

    div {
      display: flex;
    }

    > div {
      min-height: 80px;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;

      > div {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        div:first-child {
          flex-direction: column;
        }

        div:last-child {
          align-items: center;

          @media (max-width: 767px) {
            align-items: start;
          }
        }
      }
    }

    h3 {
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 18px;
    }

    svg {
      margin-right: -10px;
    }

    small {
      color: #aeaeae;
      font-size: 13px;
    }

    p {
      font-size: 12px;
      line-height: 15px;
      color: #656565;
    }
  }
`;

export const NotificationList = styled.div`
  position: absolute;
  width: 340px;
  left: calc(50% - 154px);
  top: calc(100% + 20px);
  z-index: 1;
  background: #f6f6f6;
  padding: 15px 5px;
  box-shadow: 0px 4px 6px 2px #00000024;
  display: ${(props) => (props.visible ? 'block' : 'none')};

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #f6f6f6;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 360px;
  padding: 0px 5px;
  background: #ededed;
  padding: 10px 10px;
`;
