/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import DefaultLayout from '~/pages/_layout/default';
import AuthLayout from '~/pages/_layout/auth';
import { store } from '~/store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  allAccess,
  ...rest
}) {
  const { signed } = store.getState().authReducer;

  if (!allAccess) {
    if (!signed && isPrivate) {
      return <Redirect to="/" />;
    }

    if (signed && !isPrivate) {
      return <Redirect to="/my-profile" />;
    }
  }

  const Layout = signed || allAccess ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  allAccess: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  allAccess: false,
};
