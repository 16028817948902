import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '~/services/history';
import {
  getLogin,
  getSignUp,
  getGoogleLogin,
  getuserChecked,
} from '~/api/authAPI';
import {
  signInSuccess,
  signFailure,
  checkedUserRequest,
  signUpSuccess,
  userFailure,
} from './authAction';

export function* signIn({ payload }) {
  let checkResponse;
  try {
    const response = yield call(getLogin, payload);
    checkResponse = response;
    const { token } = response.data;
    yield put(signInSuccess(token));
    yield put(userFailure(''));
    toast.success('Signing Success');
  } catch (err) {
    if (checkResponse.response.data.errorDetail === 'Googleee credentials!') {
      toast.error('Your account already associate with google!');
    } else {
      toast.error('Authentication failed. Verify your credentials.');
    }
    yield put(signFailure());
  }
}
export function* signInGoogle({ payload }) {
  try {
    const response = yield call(getGoogleLogin, payload);
    const { token } = response.data;
    yield put(signInSuccess(token));
  } catch (err) {
    toast.error('Authentication failed. Verify your credentials.');
    yield put(signFailure());
  }
}

export function* signupChecked({ payload }) {
  try {
    const response = yield call(getuserChecked, payload);
    if (response.status === 200) {
      yield put(signUpSuccess(response));
      toast.success('User already created');
    } else {
      yield put(userFailure(response.response.data.message));
    }
  } catch (err) {
    toast.error('Something went wrong.');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const result = yield call(getSignUp, payload);
    if (result.status === 200) {
      // toast.success('signing success');
    } else {
      toast.error('email already exits');
    }
  } catch (err) {
    toast.error('Registration failed. Please, verify your information.');
    yield put(signFailure());
  }
}

// export function signInWithoutLogin({ payload }) {
//   let checkResponse;
//   try {
//     const response = yield call(getLogin, payload);
//     checkResponse = response;
//     const { token } = response.data;
//     yield put(signInSuccess(token));
//     yield put(userFailure(''));
//     toast.success('signing success');
//   } catch (err) {
//     if (checkResponse.response.data.errorDetail === 'Googleee credentials!') {
//       toast.error('Your account already associate with google!');
//     } else {
//       toast.error('Authentication failed. Verify your credentials.');
//     }
//     yield put(signFailure());
//   }
// }

export function signOut() {
  localStorage.clear();
  window.location.replace('/');
  // localStorage.removeItem('persist:purposit');
  // history.push('/');
}

export default all([
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_REQUEST_GOOGLE', signInGoogle),
  takeLatest('@auth/SIGN_UP_CHECKED_REQUEST', signupChecked),

  takeLatest('@auth/SIGN_USER_FAILURE', userFailure),
  takeLatest('@auth/SIGN_UP_USER_REQUEST', signUpSuccess),

  takeLatest('@auth/SIGN_OUT', signOut),
]);
