import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';

import history from '~/services/history';

import contributor from './contributor';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import Avatar from '~/components/Avatar';

import { Container, Column, Card } from './styles';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserById } from '~/store/modules/user/userAction';
import { getContributionById, getUserById } from '~/api/userAPI';
import CircularIndeterminate from '~/components/Loader';
import profileImage from '~/assets/images/avatar-placeholder.png';

export default function ContributionNotification() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { gift } = contributor;
  const percentage = parseInt((gift.contributed / gift.price) * 100, 10);
  const location = useLocation();
  const getData = location && location.data;
  const [userData, setUserData] = useState('');
  const [giftData, setGiftData] = useState('');
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  // Parse the JSON string
  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : JSON.parse(getData && getData.data);
  const [loader, setLoader] = useState(false);

  const contriAmount = parsedData.contriAmount;
  const giftId = parsedData.giftId;
  const senderId = parsedData.senderId;
  const childName = parsedData.child_name;

  useEffect(() => {
    getUserDetail(senderId || (notificationData && notificationData.sender_id));
  }, [senderId]);

  const getUserDetail = async (id) => {
    setLoader(true);
    try {
      await getUserById(id).then((res) => {
        setUserData(res.data);
        setLoader(false);
      });
      await getContributionById(giftId).then((res) => {
        setGiftData(res.data);
        setLoader(false);
      });
    } catch (error) {
      console.log('err', error);
      setLoader(false);
    }
  };

  const totalDonations =
    giftData &&
    giftData.contributor_contributions.reduce((sum, contribution) => {
      return sum + parseFloat(contribution.donation);
    }, 0);

  // Step 2: Calculate percentage
  const price = parseFloat(giftData && giftData.price);
  const percentages = (totalDonations / price) * 100;

  const handleSubmit = () => {
    const gift = {
      giftData: giftData,
      senderId: senderId,
      childName: childName,
      userName: userData && userData.first_name,
    };
    history.push({ pathname: '/create-thank-you-cards', gift });
  };

  return (
    <>
      <SubHeader backgroundColor={colors.purple} mobileDescVisible={false}>
        <h2>Contribution</h2>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <Column>
              <h2>Hooray!</h2>
              <Card>
                <div className="imagecss">
                  <Avatar
                    image={
                      userData && userData.user_image
                        ? userData.user_image
                        : profileImage
                    }
                    size={100}
                  />
                </div>
                <h3>
                  {userData && userData.first_name + ' ' + userData.last_name}
                </h3>
                <span>Has contributed</span>
                <span className="large">${contriAmount}</span>
                <span>
                  to {childName && childName}&apos;s {giftData && giftData.name}
                </span>
              </Card>
            </Column>
            <Column>
              <small>Gift funding now stands at:</small>
              <div className="progress-bar">
                <LinearProgress variant="determinate" value={percentage} />
                <div>
                  <span>{percentages.toFixed(2)}%</span>
                  <span>${giftData && giftData.price}</span>
                </div>
              </div>
              <p>
                Sample message from contributor. Lorem ipsum dolor sit amet,
                consectetuer and then some adipiscing elit, consectetuer and
                sed.
              </p>
              <div className="button-wrapper">
                <Button
                  text="Send Thank You Card"
                  buttonFunction={() => handleSubmit()}
                />
              </div>
            </Column>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}
