import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Mobile } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import AvatarUpload from '~/components/AvatarUpload';
import Input from '~/components/Input';
import Button from '~/components/Button';

import {
  Container,
  InputWrapper,
  InputColumn,
  InputValidation,
} from './styles';
import { getUserByEmail, sendMessage } from '~/api/userAPI';
import { useSelector } from 'react-redux';

export default function WriteMessage() {
  const { colors } = useContext(ThemeContext);
  const [email, setEmail] = useState('');
  const [contributors, setContributors] = useState('');
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState(null);
  const [loader, setLoader] = useState(false);
  const rspv = localStorage.getItem('rspv');
  const getCheck = localStorage.getItem('rspv_data');
  const getFollow = localStorage.getItem('follow');
  const userData = useSelector((state) => state.userReducer.profile);
  const getUserData = localStorage.getItem('giftData');
  const giftData = JSON.parse(getUserData);
  const giftCheck = localStorage.getItem('gift');

  function validateEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      return false;
    }
    return true;
  }
  const validateForm = () => {
    if (email === '') {
      setFormError(`validation failed: email required!`);
      return 'validationFailed';
    }
    if (!validateEmail(email)) {
      setFormError(`Validation failed: invalid email format!`);
      return 'validationFailed';
    }
    if (contributors === '') {
      setFormError(`validation failed: contributor name required!`);
      return 'validationFailed';
    }
    if (message === '') {
      setFormError('Validation failed: message required!');
      return 'validationFailed';
    }
  };

  const validateForm1 = () => {
    if (message === '') {
      setFormError('Validation failed: message required!');
      return 'validationFailed';
    }
  };

  const handleSubmit = async () => {
    const error = validateForm();
    const data = {
      name: contributors,
      email: email,
      message: message,
    };
    if (error !== 'validationFailed') {
      setLoader(true);
      await sendMessage(data)
        .then(async (res) => {
          await getUserByEmail(email)
            .then((res) => {
              if (res && res.data) {
                rspv && rspv === 'no'
                  ? history.push('/no-rsvp', {
                      email: email,
                      name: contributors,
                      checked: 'true',
                    })
                  : getCheck === 'party_with_venue_novenue'
                  ? history.push('/rsvp-closed-confirmed', {
                      rspvAttend: 'no_venue',
                    })
                  : history.push('/rsvp-confirmed', {
                      email: email,
                      name: contributors,
                      checked: 'true',
                    });
                setLoader(false);
              } else {
                getCheck === 'party_with_venue_novenue'
                  ? history.push('/rsvp-closed-confirmed', {
                      rspvAttend: 'no_venue',
                    })
                  : history.push('/rsvp-confirmed', {
                      email: email,
                      name: contributors,
                      checked: 'false',
                    });
                setLoader(false);
              }
            })
            .catch((err) => {
              setLoader(false);
            });
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    }
  };

  const handleSubmitMessage = async () => {
    const error = validateForm1();
    const data = {
      name: userData && userData.first_name,
      email: userData && userData.email,
      message: message,
    };

    // const giftId = giftData && giftData.selectedGifts[0].gift_id;

    const eventId = giftData && giftData.selectedGifts[0].event_id;
    if (error !== 'validationFailed') {
      setLoader(true);
      await sendMessage(data)
        .then(async (res) => {
          if (res) {
            if (giftCheck === 'single') {
              history.push({
                pathname: '/success',
                state: {
                  destination: `/event-details/${eventId}/`,
                  message: 'Thanks for your contribution!',
                  colors: { start: colors.lightPurple, end: colors.purple },
                },
              });
            } else if (giftCheck === 'list') {
              history.push({
                pathname: '/success',
                state: {
                  destination: `/event-details/${eventId}/`,
                  message: 'Thanks for your contribution!',
                  colors: { start: colors.lightPurple, end: colors.purple },
                },
              });
            } else {
              history.push({
                pathname: '/success',
                state: {
                  // destination: `/gift-details/${giftId}?eventId=${eventId}`,
                  destination: `/my-profile`,
                  message: 'Thanks for your contribution!',
                  colors: { start: colors.lightPurple, end: colors.purple },
                },
              });
            }
          } else {
          }
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    }
  };

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 3000);

  return (
    <>
      {getFollow !== null ? (
        <SubHeader
          backgroundColor={colors.lightPurple}
          buttonText="Save Message"
          buttonTextColor="#fff"
          buttonBackgroundColor={colors.plum}
          mobileNavButtons={false}
          buttonFunction={() => handleSubmitMessage()}
          submitButtonLoading={loader}
        >
          <h2>Write Message</h2>
          <h3>
            Enter your details and write your special message for the
            recipient(s).
          </h3>
        </SubHeader>
      ) : (
        <SubHeader
          backgroundColor={colors.lightPurple}
          buttonText="Save Message"
          buttonTextColor="#fff"
          buttonBackgroundColor={colors.plum}
          mobileNavButtons={false}
          buttonFunction={() => handleSubmit()}
          submitButtonLoading={loader}
        >
          <h2>Write Message</h2>
          <h3>
            Enter your details and write your special message for the
            recipient(s).
          </h3>
        </SubHeader>
      )}
      <InputValidation>{formError}</InputValidation>
      {getFollow !== null ? (
        <Container>
          <form>
            <div className="container">
              <InputColumn>
                <InputWrapper>
                  <label htmlFor="message">
                    Your Message
                    <textarea
                      id="message"
                      rows={4}
                      name="message"
                      placeholder="Dear..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </label>
                </InputWrapper>
              </InputColumn>
            </div>
          </form>
          <Mobile>
            <Button
              text="Next"
              backgroundColor="#01e3fa"
              buttonFunction={() => handleSubmitMessage()}
            />
          </Mobile>
        </Container>
      ) : (
        <Container>
          <form>
            <AvatarUpload />
            <div className="container">
              <InputColumn>
                <InputWrapper>
                  <Input
                    label="YOUR EMAIL"
                    name="email"
                    placeholder="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Input
                    label="NAME OF CONTRIBUTOR(S)"
                    name="contributors"
                    placeholder="Eg: Mark, Dani and Lisa, Granpa Frank"
                    value={contributors}
                    onChange={(e) => setContributors(e.target.value)}
                  />
                </InputWrapper>
              </InputColumn>
              <InputColumn>
                <InputWrapper>
                  <label htmlFor="message">
                    Your Message
                    <textarea
                      id="message"
                      rows={4}
                      name="message"
                      placeholder="Dear..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </label>
                </InputWrapper>
              </InputColumn>
            </div>
          </form>
          <Mobile>
            <Button
              text="Next"
              backgroundColor="#01e3fa"
              buttonFunction={() => handleSubmit()}
            />
          </Mobile>
        </Container>
      )}
    </>
  );
}
