import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import gift from './gift';

import SubHeader from '~/components/SubHeader';

import Button from '~/components/Button';
import { Container, ImageWrapper, Card } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getReceiverById } from '~/api/receiverAPI';
import { fetchReceiverByIdDone } from '~/store/modules/receiver/receiverAction';
import { fetchGiftDetailsById } from '~/store/modules/gift/giftAction';
import { getFetchGiftDetailsById } from '~/api/giftAPI';
import CircularIndeterminate from '~/components/Loader';

export default function EventNear() {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const Dispatch = useDispatch();
  const getData = location && location.data;
  const [giftData, setGiftData] = useState('');
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [loader, setLoader] = useState(false);
  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : JSON.parse(getData && getData.data);

  useEffect(() => {
    const giftId = parsedData && parsedData.gift_id;
    const idsArray = giftId && giftId.split(',');
    const firstId =
      idsArray && idsArray.length > 0 ? idsArray && idsArray[0] : giftId;

    handleGetChild(firstId);
  }, [parsedData.child_id]);

  const handleGetChild = async (id) => {
    setLoader(true);
    await getFetchGiftDetailsById(id)
      .then((res) => {
        setGiftData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log('##', err);
      });
    await getReceiverById(parsedData.child_id)
      .then((res) => {
        Dispatch(fetchReceiverByIdDone(res.data));
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log('##', err);
      });
  };

  return (
    <>
      <SubHeader backgroundColor={colors.purple}>
        <h2>EVENT COMING SOON</h2>
        <h3>It’s time to share it with friends!</h3>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <ImageWrapper
              backgroundImage={
                giftData && giftData.gift_images
                  ? giftData.gift_images[0].url
                  : gift.image
              }
            />
            <div>
              <Card>
                <h2>
                  {parsedData && parsedData.childName}&apos;s Birthday Party
                </h2>
                <span>is only 5 weeks away!</span>
              </Card>
              <p>Time to review this event and invite friends!</p>
              <div className="button-wrapper">
                <Button
                  text="VIEW EVENT DETAILS "
                  buttonFunction={() =>
                    history.push(`/event-details/${parsedData.event_id}`, {
                      childId: parsedData.child_id,
                    })
                  }
                />
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 'auto',
            }}
          >
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}
