import React, { useState, useContext } from 'react';
// import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import history from '~/services/history';

import Context from '~/context';

import contactsList from './contactsList';

import DeleteBankModal from './DeleteBankModal';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import ModalWrapper from '~/components/ModalWrapper';

import { Container, InputColumn, CheckboxControl, Card } from './styles';

export default function BeforeDeleteBank() {
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);

  const [contacts, setContacts] = useState(
    contactsList.map((contact) => ({ ...contact, checked: true })),
  );

  const handleContactCheck = (id) => (e) => {
    setContacts(
      contacts.map((contact) => ({
        ...contact,
        checked: contact.id === id ? !contact.checked : contact.checked,
      })),
    );
  };

  // const handleNext = () => {
  //   history.push('/payment-details');
  // };

  return (
    <>
      <SubHeader backgroundColor={colors.purple}>
        <h2>Before Delete</h2>
        <h3>
          This bank account is the default option for the
          gift&nbsp;receivers&nbsp;below:
        </h3>
      </SubHeader>
      <Container>
        <InputColumn>
          {contacts.length > 0 ? (
            <>
              {contacts.map((contact) => (
                <CheckboxControl
                  key={contact.id}
                  control={
                    <Card>
                      <div>
                        <img src={contact.avatar} alt={contact.name} />
                        <div>
                          <h3>{contact.name}</h3>
                        </div>
                      </div>
                      <div>
                        <Checkbox
                          checked={contact.checked}
                          onChange={handleContactCheck(contact.id)}
                          name={toString(contact.id)}
                          color="primary"
                        />
                      </div>
                    </Card>
                  }
                />
              ))}
            </>
          ) : (
            <h3>You have no contacts yet.</h3>
          )}
        </InputColumn>
        <InputColumn>
          <p>
            Would you like to link another account to their profiles so you can
            continue to receive funds&nbsp;for&nbsp;their&nbsp;gifts?
          </p>
          <Button
            text="Link Another Bank Account"
            buttonFunction={() => {
              history.push('/change-bank-account');
            }}
          />
          <button
            className="no-thanks"
            type="submit"
            onClick={() => handleShowModal()}
          >
            No, thanks.
          </button>
        </InputColumn>
      </Container>
      <ModalWrapper>
        <DeleteBankModal />
      </ModalWrapper>
    </>
  );
}
