import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MdMoreVert } from 'react-icons/md';
import { IoIosTrash } from 'react-icons/io';
// Action
import {
  bankListById,
  cardDetailsByUserId,
} from '~/store/modules/bankAccount/bankAccountAction';
import Context from '~/context';
import history from '~/services/history';

// import accounts from './accounts';
// import cards from './cards';

import DeleteCreditCardModal from './DeleteCreditCardModal';
import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import ModalWrapper from '~/components/ModalWrapper';

import {
  Container,
  InputWrapper,
  InputColumn,
  RadioButton,
  RadioControl,
  BankAccount,
} from './styles';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function PaymentsAndTransfers() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const [radioValue, setRadioValue] = useState('each');
  const user = useSelector((state) => state.userReducer.profile);
  const userId = user.id;
  const receiverReducer = useSelector((state) => state.receiverReducer);
  const { receiverId } = receiverReducer;
  const bankAccountReducer = useSelector((state) => state.bankAccountReducer);
  const { userBankDetails, userCardDetails } = bankAccountReducer;
  const getFollow = localStorage.getItem('follow');
  const location = useLocation();

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  useEffect(() => {
    Dispatch(bankListById(userId));
    Dispatch(cardDetailsByUserId(userId));
  }, [userId]);

  const handleEditBank = (id) => {
    // history.push('/edit-bank-account')
    history.push({
      pathname: '/edit-bank-account',
      state: { accountId: id, receiverId },
    });
  };
 
  const handleCheck = (e) => {
    e.preventDefault();
    toast.error('Please Link Your Bank Account!');
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.secondary}
        mobileDescVisible={false}
        backButtonFunction={() => history.push('/my-profile')}
      >
        <h2>BANKING & CARD&nbsp;DETAILS</h2>
      </SubHeader>
      <Container>
        <form>
          <InputColumn>
            <InputWrapper>
              <span className="label">BANK ACCOUNT(S) - TO RECEIVE FUNDS</span>
              {userBankDetails ? (
                <>
                  <BankAccount
                    onClick={() =>
                      handleEditBank(userBankDetails && userBankDetails.id)
                    }
                  >
                    <div>
                      <span>
                        {`${userBankDetails.first_name} ${userBankDetails.last_name}`}
                      </span>
                      &nbsp;|&nbsp;
                      <span>{userBankDetails.account_number}</span>
                    </div>
                    <MdMoreVert size={30} color="#d6d6d6" />
                  </BankAccount>
                </>
              ) : getFollow && getFollow !== null ? (
                <Button
                  text="LINK BANK ACCOUNT"
                  buttonFunction={() =>
                    history.push('/link-bank/', {
                      userData: location.state,
                    })
                  }
                />
              ) : (
                <Button
                  text="LINK BANK ACCOUNT"
                  buttonFunction={() => history.push('/link-bank/')}
                />
              )}
            </InputWrapper>
            <InputWrapper>
              <span className="label">
                CREDIT CARD(S) - TO CONTRIBUTE TO GIFTS
              </span>
              {userCardDetails
                ? userCardDetails.map((card) => (
                    <BankAccount key={card.customer_id}>
                      <div>
                        <span>xxxx-xxxx-xxxx-{card.last4}</span>
                      </div>
                      <IoIosTrash
                        size={25}
                        color="#d6d6d6"
                        onClick={handleShowModal}
                      />
                    </BankAccount>
                  ))
                : ''}
              {getFollow !== null && userBankDetails === null ? (
                <Button
                  text="ADD CREDIT CARD"
                  buttonFunction={(e) => handleCheck(e)}
                />
              ) : (
                <Button
                  text="ADD CREDIT CARD"
                  buttonFunction={() => history.push('/add-credit-card')}
                />
              )}
            </InputWrapper>
          </InputColumn>
          <InputColumn>
            <InputWrapper>
              <span className="label">FUNDING INSTRUCTIONS</span>
              <p>
                This applies to every profile, gift and event you create and
                manage on Purposit
              </p>
              <RadioControl component="fieldset">
                <RadioGroup
                  aria-label="funds-instructions"
                  name="funds-instructions"
                  value={radioValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="each"
                    control={<RadioButton />}
                    label="Receive funds as each contribution is made"
                  />
                  <FormControlLabel
                    value="weekly"
                    control={<RadioButton />}
                    label="Receive funds weekly untill funding is complete"
                  />
                </RadioGroup>
              </RadioControl>
            </InputWrapper>
          </InputColumn>
        </form>
      </Container>
      <ModalWrapper>
        <DeleteCreditCardModal />
      </ModalWrapper>
    </>
  );
}
