import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import history from '~/services/history';
import Context from '~/context';

import { ReactComponent as Archive } from '~/assets/images/archive.svg';

import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { hideMemoriesEvent } from '~/store/modules/event/eventAction';
import CircularIndeterminate from '~/components/Loader';

export default function HideAlbum() {
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const receiver = useSelector((state) => state.receiverReducer);
  const { eventId, postId } = useParams();
  const Dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const handleArchiveAlbum = () => {
    const payload = {
      event_id: parseInt(eventId),
      child_id: receiver.id,
    };
    Dispatch(hideMemoriesEvent(payload));
    setLoader(true);
    setTimeout(() => {
      handleShowModal();
      history.push({
        pathname: '/success',
        state: {
          destination: `/receiver-profile/${receiver.id}/memories`,
          message: 'Album Archived.',
          colors: { start: colors.lightPurple, end: colors.purple },
        },
      });
    }, 2000);
  };

  return (
    <Container>
      <Archive />
      <h2>
        Before you archive this <br /> album be aware:
      </h2>
      <p>
        Your invitees and contributors will no longer be able to view the album,
        comments or likes.
      </p>
      <small>
        You can unhide this event by going to <br /> My Profile / Menu /Manage
        View
      </small>
      <button type="submit" onClick={() => handleShowModal()}>
        Cancel
      </button>

      {!loader ? (
        <button
          type="submit"
          className="blue"
          onClick={() => handleArchiveAlbum()}
        >
          Archive
        </button>
      ) : (
        <button type="submit" className="blue">
          <CircularIndeterminate />
        </button>
      )}
    </Container>
  );
}
