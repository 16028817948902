import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 20px;

  @media (max-width: 767px) {
    padding: 0;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    > div,
    button {
      height: 50px;
      max-width: calc(50% - 10px);
      margin-bottom: 20px;
      width: 100%;

      @media (min-width: 768px) {
        &:nth-child(even) {
          .open {
            transform: translateX(calc(-50% - 10px));
          }

          .top-detail {
            margin-left: auto;
          }
        }
      }

      &.open {
        height: initial;
        background: white;
        margin-bottom: 185px;
      }

      @media (max-width: 767px) {
        max-width: 100%;

        &.open {
          height: initial;
          background: white;
          margin-bottom: 165px;
        }
      }
    }

    .other-options {
      width: 100%;
      margin-top: -20px;
      z-index: 1;
      position: relative;
      height: 0;
      opacity: 0;
      transition: 0.3s all;
      pointer-events: none;
      display: none;

      &:nth-child(odd) {
        &.open + button {
          position: absolute;
          right: 0;
        }
      }

      &.open {
        display: block;
        height: 170px;
        opacity: 1;
        pointer-events: initial;
        position: absolute;
        margin-top: 0px;

        @media (max-width: 767px) {
          margin-bottom: 20px;
          margin-top: -20px;
        }

        .options button {
          opacity: 1;
        }
      }

      .top-detail {
        background: #fff;
        height: 20px;
        margin-top: -5px;
        display: none;

        @media (min-width: 768px) {
          width: calc(50% - 10px);
          display: block;
        }
      }

      .options {
        background: white;
        padding: 15px;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 20px;
        min-height: 150px;
        height: 150px;

        @media (max-width: 767px) {
          display: grid;
          grid-gap: 20px;
          overflow-x: scroll;
          scroll-snap-type: x proximity;
        }

        button {
          transition: 0.2s;
          transition-delay: 0.2s;
          opacity: 0;
          margin-bottom: 0;
          border: 0;
          background: white;

          img {
            width: 94px;
            height: 94px;
            border: 2px solid #fff;
            transition: 0.3s;
            padding: 3px;
            object-fit: cover;
            object-position: center;
          }

          p {
            font-weight: bold;
            color: #888;
          }

          &:hover {
            img {
              border: 2px solid ${({ theme }) => theme.colors.primary};
            }
          }
        }
      }
    }
  }
`;

export const BigButton = styled.div`
  color: ${({ theme }) => theme.colors.purple};
  background-color: #fff;
  text-align: left;
  transition: all 0.5s;
  border: 0;
  cursor: pointer;

  &.open > div:first-child > svg {
    transform: rotate(90deg);
  }

  > div:first-child {
    padding: 0 15px;
    max-width: 398px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    h2 {
      line-height: 1.2;
    }

    .image-wrapper {
      margin-right: 15px;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        margin-right: 10px;
      }

      svg {
        width: 27px;
        height: 27px;
        margin: 0 auto;
      }
    }

    .eventIcon {
      width: 27px;
      height: 27px;
    }

    .text-content {
      flex: 1;
    }

    > svg {
      opacity: 0.4;
      transition: all 0.5s;
    }

    &:hover {
      box-shadow: 0px 0px 10px 0px #cacaca8a;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const LoaderDiv = styled.div`
  margin-top: 25px;
  align-items: center;
  margin-left: 50%;
`;

export const LoadingText = styled.div`
  position: absolute;
  top: 35px;
  color: #13287f;
`;
