import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';
import { IoIosCheckmark } from 'react-icons/io';

import history from '~/services/history';

import Avatar from '~/components/Avatar';
import Button from '~/components/Button';

import { Container, ContactList, Contact } from './styles';

import gifts from '../gifts';

export default function HaveContributed() {
  const { colors } = useContext(ThemeContext);
  const { id } = useParams();

  const gift = gifts.find((x) => x.id === parseInt(id, 10));
  return (
    <Container>
      {gift.contributions.length > 0 ? (
        <ContactList>
          {gift.contributions.map((contact) => (
            <Contact key={contact.id}>
              <div>
                <div className="contact-info">
                  <Avatar size={55} image={contact.avatar} />
                  <div>
                    <h3>{contact.name}</h3>
                    <span>
                      {contact.date}
                      {contact.thanked && ' | Thank you card sent'}
                    </span>
                  </div>
                </div>
                <div className="donated-value">
                  <span>${contact.donation}</span>
                  {contact.thanked && (
                    <IoIosCheckmark size={30} color={colors.primary} />
                  )}
                </div>
              </div>
              {contact.thanked || (
                <div className="invite-button">
                  <Button
                    text="Send Thank You Card"
                    buttonFunction={() =>
                      history.push('/create-thank-you-cards')
                    }
                  />
                </div>
              )}
            </Contact>
          ))}
        </ContactList>
      ) : (
        <h3>You haven&apos;t received any contributions yet.</h3>
      )}
    </Container>
  );
}
