import styled from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  width: 100%;
  background: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;

  .content {
    max-width: 390px;
  }

  h2 {
    color: ${({ theme }) => theme.colors.secondary};
    border-top: 3px solid;
    border-bottom: 3px solid;
    width: 100%;
    text-align: center;
    padding: 8px;
    font-size: 24px;
    margin-bottom: 30px;
    margin-top: 25px;

    @media (max-width: 767px) {
      padding: 6px;
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }

  p {
    ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;

    &:last-of-type {
      border-bottom: 3px solid;
      margin-bottom: 20px;
      padding-bottom: 30px;
    }
  }

  img {
    max-width: 123px;

    &.stripe {
      max-width: 123px;
    }

    &.norton {
      max-width: 94px;
      margin-bottom: 15px;
    }

    @media (max-width: 767px) {
      max-width: 70px;

      &.norton {
        max-width: 70px;
      }
    }
  }
`;
