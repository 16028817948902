import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Wrapper } from './styles';

function Footer() {
  return (
    <Wrapper>
      <div />
      <Container>
        <div>
          <p>© 2020 Purposit®</p>
        </div>
        <div>
          <p>
            <Link to="/terms">TERMS</Link>
          </p>
          <p>
            <Link to="/privacy">PRIVACY</Link>
          </p>
          <p>
            <Link to="/cookies">COOKIE POLICY</Link>
          </p>
          <p>
            <a href="mailto:support@purposit.com">CONTACT</a>
          </p>
        </div>
      </Container>
    </Wrapper>
  );
}

export default Footer;
