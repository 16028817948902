import { ReactComponent as AnniversaryIcon } from '~/assets/images/anniversary-icon.svg';
import { ReactComponent as BirthdayCakeIcon } from '~/assets/images/birthday-cake-icon.svg';
import { ReactComponent as CulturalIcon } from '~/assets/images/book-icon.svg';
import { ReactComponent as ChristmasIcon } from '~/assets/images/christmas-icon.svg';
import { ReactComponent as CalendarIcon } from '~/assets/images/calendar-icon.svg';
import GiftImage from '~/assets/images/bike-gift.jpg';

const eventTypes = [
  {
    id: 1,
    visible: false,
    title: 'Birthday',
    icon: BirthdayCakeIcon,
    options: [
      {
        slug: 'birthday',
        name: 'Birthday',
        image: GiftImage,
      },
      {
        slug: 'anniversary',
        name: 'Anniversary',
        image: GiftImage,
      },
    ],
  },
  {
    id: 2,
    visible: false,
    title: 'Christmas',
    icon: ChristmasIcon,
    options: [
      {
        slug: 'Christmas',
        name: 'Christmas',
        image: GiftImage,
      },
      {
        slug: 'anniversary',
        name: 'Anniversary',
        image: GiftImage,
      },
    ],
  },
  {
    id: 3,
    visible: false,
    title: 'Cultural & Beliefs',
    icon: CulturalIcon,
    options: [
      {
        slug: 'birthday',
        name: 'Birthday',
        image: GiftImage,
      },
      {
        slug: 'Christmas',
        name: 'AnnChristmasiversary',
        image: GiftImage,
      },
    ],
  },
  {
    id: 4,
    visible: false,
    title: 'Anniversary',
    icon: AnniversaryIcon,
    options: [
      {
        slug: 'birChristmasthday',
        name: 'BirtChristmashday',
        image: GiftImage,
      },
      {
        slug: 'anniChristmasversary',
        name: 'AnniversChristmasary',
        image: GiftImage,
      },
    ],
  },
  {
    id: 5,
    visible: false,
    title: 'Other Occasion',
    icon: CalendarIcon,
    options: [
      {
        slug: 'birthethday',
        name: 'Birthdathey',
        image: GiftImage,
      },
      {
        slug: 'anniversarythe',
        name: 'theAnniversary',
        image: GiftImage,
      },
    ],
  },
];

export default eventTypes;
