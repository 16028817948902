import styled from 'styled-components';
import { ReactComponent as CircularProgressSVG } from '~/assets/images/circular-progress.svg';

export const Container = styled.div`
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: ${({ theme }) => theme.colors.pink};
  }
`;

export const CircularProgressBar = styled(CircularProgressSVG)`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  #front {
    stroke-dasharray: 339.292;
    stroke-dashoffset: 339.292;
    animation: progress 2s ease-in forwards;
  }

  @keyframes progress {
    from {
      stroke-dashoffset: 339.292;
    }
    to {
      stroke-dashoffset: ${({ percentage }) => 339.292 - percentage};
    }
  }
`;
