import React, { useState, useContext, useEffect } from 'react';

import Context from '~/context';
import moment from 'moment';
import GiftImage from '~/assets/images/bike-gift.jpg';
import EmptyList from '../EmptyList';

import CardCheckOverlay from '~/components/CardCheckOverlay';
import { Container, Card } from './styles';
import { useSelector } from 'react-redux';

export default function Gifts({ selectedEventIds, setSelectedEventIds }) {
  const [checkedEvents, setCheckedEvents] = useState();
  const eventData = useSelector((state) => state.receiverReducer.archivedEvent);

  const handleSelectEvent = (id) => {
    setCheckedEvents((prevCheckedEvents) => {
      const updatedCheckedEvents = prevCheckedEvents.map((event) => ({
        ...event,
        checked: event.id === id ? !event.checked : event.checked,
      }));
      const updatedSelectedEventIds = updatedCheckedEvents
        .filter((event) => event.checked)
        .map((event) => event.id);
      setSelectedEventIds(updatedSelectedEventIds);
      return updatedCheckedEvents;
    });
  };

  useEffect(() => {
    if (eventData && eventData.events && eventData.events_image) {
      const checkedEvents = eventData.events.map((event) => {
        // Find the corresponding image data by matching IDs
        const matchingImage = eventData.events_image.find(
          (image) => image.id === event.id,
        );
        // Combine event and image data
        return {
          ...event,
          checked: false,
          eventImage: matchingImage ? matchingImage.event_invitations : null,
        };
      });
      setCheckedEvents(checkedEvents);
    }
  }, [eventData]);

  return (
    <>
      {checkedEvents && checkedEvents.length > 0 ? (
        <Container>
          {checkedEvents.map((event) => (
            <Card
              className="event-card"
              key={event.id}
              onClick={() => handleSelectEvent(event.id)}
            >
              <img
                src={
                  event.eventImage.length === 0
                    ? GiftImage
                    : event.eventImage[0].image
                }
                alt={event.title}
              />
              <div className="event-data">
                <div>
                  <div>
                    <h3>{event.title}</h3>
                    <small>{moment(event.event_date).format('MMMM Do')}</small>
                  </div>
                </div>
                <div>
                  <span>
                    <strong>{0}</strong> friends
                  </span>
                  <span>
                    <strong>{event.contributor_contributions.length}</strong>{' '}
                    contributors
                  </span>
                  <span>
                    {/* <strong>{event.events}</strong> events */}
                    <strong>0</strong> events
                  </span>
                </div>
              </div>
              <CardCheckOverlay checked={event.checked} />
            </Card>
          ))}
        </Container>
      ) : (
        <EmptyList>
          Click below to create your first event for Eugene and start inviting
          friends
        </EmptyList>
      )}
    </>
  );
}
