import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 50px 25px 25px;
  align-items: center;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 20px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: ${({ path }) => (path === 'step-2' ? 'row' : 'column')};
    align-items: start;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
`;

export const SecureBankingBadge = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 20px;
  padding: 10px 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }

  svg {
    margin-right: 10px;
  }
`;
export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;
