import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';
import Context from '~/context';

import { ReactComponent as Gifts } from '~/assets/images/gifts.svg';
import { ReactComponent as GiftIcon } from '~/assets/images/gift-icon.svg';
import { ReactComponent as UnsurePerson } from '~/assets/images/unsure-person-icon.svg';

import LaterScreen from './LaterScreen';
import FindOutMoreModal from './FindOutMoreModal';

import SubHeader from '~/components/SubHeader';
import ModalWrapper from '~/components/ModalWrapper';

import { Container, BigButton } from './styles';

export default function StepTwo() {
  const { colors } = useContext(ThemeContext);
  const { handleShowModal, setFlow } = useContext(Context);
  const [showLater, setShowLater] = useState(false);
  const receiverName = useSelector((state) => state.receiverReducer.receiver);

  const handleNext = (giftOption) => {
    localStorage.setItem('onbordingGift', 'laterGift');
    setFlow({ giftOption });
    history.push('/first-gift');
  };

  return (
    <>
      {showLater ? (
        <LaterScreen />
      ) : (
        <>
          <SubHeader
            buttonText="Later"
            buttonFunction={() => setShowLater(true)}
            hideBackButton
          >
            <h2>Gift Option</h2>
            <h3>{`What do you want to do for ${
              receiverName ? receiverName.first_name || receiverName.group_name : ''
            } ?`}</h3>
          </SubHeader>
          <Container>
            <BigButton onClick={() => handleNext('one-gift')}>
              <div className="image-wrapper">
                <GiftIcon alt="One gift" />
              </div>
              <div className="text-content">
                <h2>Fund one special gift</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>
            <BigButton
              backgroundColor={colors.lightPurple}
              onClick={() => handleNext('multiple-gifts')}
            >
              <div className="image-wrapper">
                <Gifts alt="Multiple Gifts" />
              </div>
              <div className="text-content">
                <h2>Fund multiple gifts</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>
            <BigButton
              backgroundColor="#CCCCCC"
              onClick={() => history.push('/add-event')}
            >
              <div className="image-wrapper">
                <UnsurePerson alt="An Individual" />
              </div>
              <div className="text-content">
                <h2>I’m not sure yet</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>
            <a onClick={() => handleShowModal()}>Find out more...</a>
          </Container>
          <ModalWrapper>
            <FindOutMoreModal />
          </ModalWrapper>
        </>
      )}
    </>
  );
}
