import React, { useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import GiftImage from '~/assets/images/bike-gift.jpg';

import { Container, GiftCard } from './styles';

import giftsList from './gifts';

export default function Gifts({ setTotal }) {
  const [gifts, setGifts] = useState(
    giftsList.map((gift) => ({ ...gift, contribution: '' })),
  );

  const handleSum = (newGifts) => {
    let newTotal = 0;
    newGifts.forEach((gift) => {
      newTotal +=
        gift.contribution === '' ? 0 : parseInt(gift.contribution, 10);
    });
    setTotal(newTotal);
  };

  const handleContribution = (id, contribution) => {
    const newGifts = gifts.map((gift) => {
      if (gift.id === id) {
        return { ...gift, contribution };
      }
      return gift;
    });

    setGifts(newGifts);
    handleSum(newGifts);
  };

  return (
    <Container>
      {gifts.map((gift) => (
        <GiftCard key={gift.id}>
          <div>
            <img src={GiftImage} alt={gift.name} />
            <div>
              <div>
                <div>
                  <h3>{gift.name}</h3>
                  <small>{gift.date}</small>
                </div>
                <div>
                  <span className="gift-price">${gift.price}</span>
                </div>
              </div>
              <div>
                <p>{gift.desc}</p>
              </div>
            </div>
          </div>
          <div className="progress-bar">
            <LinearProgress variant="determinate" value={50} />
            <div>
              <span>$0</span>
              <span>50%</span>
            </div>
          </div>
          <div className="contribution">
            <span>Enter your contribution</span>
            <input
              placeholder="$0"
              value={gift.contribution}
              type="number"
              onChange={(e) => handleContribution(gift.id, e.target.value)}
            />
          </div>
        </GiftCard>
      ))}
    </Container>
  );
}
