import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #393c41;
  color: #fff;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  a {
    color: #fff;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  p {
    opacity: 0.8;
  }

  > div {
    display: flex;
    flex: 1;
    max-width: 400px;

    &:last-of-type {
      justify-content: space-around;

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
`;
