import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import history from '~/services/history';
import HideFooter from '~/utils/HideFooter';
import Context from '~/context';

import {
  OuterContainer,
  Container,
  SuccessCheckSVG,
  OnBoardStepsSVG,
} from './styles';

export default function OnBoarding() {
  const { step } = useParams();
  const { flow } = useContext(Context);
  const nextPage =
    flow.giftOption === 'one-gift' ? '/event-goal' : '/event-goal';

  setTimeout(
    () => (step === '1' ? history.push('/step-2') : history.push(nextPage)),
    3000,
  );

  return (
    <OuterContainer>
      <HideFooter />
      <Container>
        <div>
          <SuccessCheckSVG />
          <h1>{step === '1' ? 'Receiver(s) Saved!' : 'Gift(s) Saved'}</h1>
          <OnBoardStepsSVG alt="On Boarding" step={step} />
        </div>
      </Container>
    </OuterContainer>
  );
}
