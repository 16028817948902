import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import history from '~/services/history';

// Action
import { fetchContactList } from '~/store/modules/event/eventAction';

// import contactsList from './contactsList';

import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';

import {
  Container,
  InputColumn,
  InputWrapper,
  CheckboxControl,
  Card,
} from './styles';

export default function MyContacts() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const eventReducer = useSelector((state) => state.eventReducer);
  const { eventInviteesDetails, contactList } = eventReducer;
  const [contacts, setContacts] = useState(
    // contactList &&
    //   contactList.map((contact) => ({
    //     ...contact,
    //     checked: false,
    //     visible: true,
    //   })),
  );

  const [search, setSearch] = useState('');
  const [pastValue, setPastValue] = useState(false);

  useEffect(() => {
    Dispatch(fetchContactList());
  }, []);

  const handleContactCheck = (id) => (e) => {
    setContacts(
      contacts.map((contact) => ({
        ...contact,
        checked: contact.id === id ? !contact.checked : contact.checked,
      })),
    );
  };

  const handlePastChange = () => {
    setPastValue(!pastValue);
  };

  const handleSearch = (keyword) => {
    setSearch(keyword);

    const liveSearch = contacts.map((contact) => ({
      ...contact,
      visible:
        contact.email.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 &&
        true,
    }));

    if (liveSearch.length > 0) {
      setContacts(liveSearch);
    } else {
      setContacts(
        contacts.map((contact) => ({
          ...contact,
          visible: true,
        })),
      );
    }
  };

  const handleNext = () => {
    const selectedContacts = contacts.filter(
      (contact) => contact.checked === true,
    );
    const emails = selectedContacts.map((crr) => crr.email);
    const data = { invitee: emails, ...eventInviteesDetails };
    history.push({
      pathname: '/review-contacts',
      state: { contacts: data },
    });
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonText="Next"
        buttonTextColor="#fff"
        buttonFunction={handleNext}
      >
        <h2>My Contacts</h2>
        <h3>
          Search and select your invitees from&nbsp;your contact&nbsp;list
        </h3>
      </SubHeader>
      <Container>
        <InputColumn>
          <InputWrapper>
            <Input
              placeholder="Search my contacts..."
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
              name="search"
            />
          </InputWrapper>
          <InputWrapper>
            <div>
              <CheckboxControl
                control={
                  <Checkbox
                    checked={pastValue}
                    onChange={handlePastChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Only show contacts used in the past."
              />
            </div>
          </InputWrapper>
        </InputColumn>
        <InputColumn>
          {contacts && contacts.length > 0 ? (
            <>
              {contacts
                .filter((contact) =>
                  pastValue ? contact.used === true : contact,
                )
                .map(
                  (contact) =>
                    !contact.visible || (
                      <CheckboxControl
                        key={contact.id}
                        control={
                          <Card>
                            <div>
                              {/* <img src={contact.avatar} alt={contact.name} /> */}
                              <div>
                                <h3>{contact.email}</h3>
                              </div>
                            </div>
                            <div>
                              <Checkbox
                                checked={contact.checked}
                                onChange={handleContactCheck(contact.id)}
                                name={toString(contact.email)}
                                color="primary"
                              />
                            </div>
                          </Card>
                        }
                      />
                    ),
                )}
            </>
          ) : (
            <>
              {search.length === 0 ? (
                <h3>You have no contacts yet.</h3>
              ) : (
                <h3>No matches found for {search}.</h3>
              )}
            </>
          )}
        </InputColumn>
      </Container>
    </>
  );
}
