import styled from 'styled-components';
import { lighten } from 'polished';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';

export const Container = styled.div`
  max-width: 720px;
  margin: 115px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }

  form {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }
`;

export const PrivacyNotice = styled.div`
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 18px 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 12px 2px;
    margin-bottom: 15px;
  }

  svg {
    margin-right: 25px;
    min-width: 25px;

    @media (max-width: 767px) {
      margin-right: 10px;
    }
  }

  span {
    font-size: 13px;
    color: #888;
  }
`;

export const RadioControl = styled(FormControl)`
  max-width: 330px;
  width: 100%;

  .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const RadioButton = styled(Radio)`
  &.MuiRadio-root {
    &.Mui-checked {
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
      }
    }

    &:hover {
      background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
    }
  }
`;

export const InputRow = styled.div`
  display: grid;
  grid-template-columns: ${({ social }) => (social ? '1fr' : '1fr 1fr')};
  width: 100%;
  grid-gap: 10px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
