import GiftImage from '~/assets/images/golf-clubs.jpg';

const gift = {
  id: 1,
  image: GiftImage,
  title: 'Mountain Bike',
  contributed: 50,
  receiver: 'Eugene',
  price: 250,
  end: '10th January 2018',
};
export default gift;
