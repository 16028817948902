import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { TimePickerInput } from './styles';

export default function TimePicker({
  name,
  label,
  onChange,
  date,
  className,
  maxDate,
  minDate,
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePickerInput
        name={name}
        className={`date-picker ${className}`}
        label={label}
        value={date}
        onChange={onChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        maxDate={maxDate}
        minDate={minDate}
      />
    </MuiPickersUtilsProvider>
  );
}

TimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

TimePicker.defaultProps = {
  className: '',
  maxDate: new Date(2100, 1, 1),
  minDate: new Date(1900, 1, 1),
};
