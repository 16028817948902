import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import { lighten } from 'polished';

export const Container = styled.div`
  max-width: 720px;
  margin: 50px auto;
  width: 90%;
  background-color: #f6f6f6;
  flex-direction: column;
  display: flex;
  padding: 30px 40px;
  align-items: start;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  button {
    margin-top: 20px;
    max-width: 300px;
  }

  legend {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888 !important;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
  }
`;

export const RadioControl = styled(FormControl)`
  width: 100%;
  margin-top: 10px;

  .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .MuiTypography-root.MuiTypography-body1 {
    text-transform: none;
    font-weight: normal;
  }
`;

export const RadioButton = styled(Radio)`
  &.MuiRadio-root {
    &.Mui-checked {
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
      }
    }

    &:hover {
      background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
    }
  }
`;

export const SelectControl = styled(FormControl)`
  max-width: 330px;
  width: 100%;

  input {
    background-color: #fff;
    border-radius: 50%;
  }
`;
