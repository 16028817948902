import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import guests from './guests';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import Avatar from '~/components/Avatar';

import { Container, Column, Card } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getReceiverById } from '~/api/receiverAPI';
import { fetchReceiverByIdDone } from '~/store/modules/receiver/receiverAction';
import CircularIndeterminate from '~/components/Loader';
import { getNotificationByUserId } from '~/store/modules/notification/notificationAction';

export default function CreateFirstEvent() {
  const location = useLocation();
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const guest = guests.find((g) => g.id === parseInt(1, 10));
  const getData = location && location.data;
  const [giftData, setGiftData] = useState('');
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [loader, setLoader] = useState(false);
  const profile = useSelector((state) => state.userReducer.profile);

  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : JSON.parse(getData && getData.data);

  useEffect(() => {
    handleGetChild(parsedData.child_id);
    Dispatch(getNotificationByUserId(profile.id));
  }, [parsedData.child_id]);

  const handleGetChild = async (id) => {
    setLoader(true);
    await getReceiverById(id)
      .then((res) => {
        setGiftData(res.data);
        Dispatch(fetchReceiverByIdDone(res.data));
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log('##', err);
      });
  };

  console.log('giftDatagiftData', profile);

  return (
    <>
      <SubHeader backgroundColor={colors.purple} mobileDescVisible={false}>
        <h2>Create First Event</h2>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <Column>
              <h2>Well done creating a profile for</h2>
              <Card>
                <Avatar
                  image={
                    giftData && giftData.child_image
                      ? giftData.child_image
                      : guest.avatar
                  }
                  size={100}
                />
                <h3 style={{ textTransform: 'capitalize' }}>
                  {giftData && giftData.first_name}{' '}
                  {giftData && giftData.last_name}
                </h3>
                <span className="large">
                  You can now create events and organize gifts for{' '}
                  {giftData && giftData.first_name}.
                </span>
              </Card>
            </Column>
            <Column>
              <span className="notice">
                Click below to get started. In a few steps, you will be ready to
                invite friends to this event.
              </span>
              {/* {profile && profile.receiver && profile.receiver.length === 0 && ( */}
              <Button
                text="Create An Event"
                buttonFunction={() =>
                  history.push(`/event-goal`, { inviteEvent: 'inviteEvent' })
                }
              />
              {/* )} */}
            </Column>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}
