import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media (max-width: 767px) {
    width: 100%;
    padding: 15px;
    margin: 0;
    grid-template-columns: auto;
  }
`;

export const ContactList = styled.div`
  column-count: 2;
  display: block;

  @media (max-width: 767px) {
    column-count: 1;
  }
`;

export const Contact = styled.div`
  background: white;
  border-top: 1px solid #eee;
  box-shadow: 0px 4px 6px 0px #cacaca8a;
  display: inline-block;
  padding: 15px;
  width: 100%;

  > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  .contact-info {
    display: flex;

    span {
      @media (min-width: 768px) {
        font-size: 12px;
        color: #656565;
      }
    }
  }

  .donated-value {
    display: flex;
    flex-direction: column;
  }

  .invite-button {
    margin-top: 10px;

    button {
      max-width: 100%;
    }
  }
`;
