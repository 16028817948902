import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// Action
import { setIsReceiver } from '../../store/modules/receiver/receiverAction';

import AddIndividual from './AddIndividual';
import AddMultiple from './AddMultiple';
import SelectReceiver from './SelectReceiver';

export default function AddGiftReceiver() {
  const Dispatch = useDispatch();
  const { type } = useParams();

  useEffect(() => {
    Dispatch(setIsReceiver(true));
  }, [Dispatch]);

  function getScreen() {
    const screens = {
      individual: <AddIndividual />,
      multiple: <AddMultiple />,
    };
    return screens[type];
  }

  return <>{type ? getScreen() : <SelectReceiver />}</>;
}
