import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import Context from '~/context';

import GiftList from '~/components/GiftList';
import SubHeader from '~/components/SubHeader';

import { Container, LoaderDiv, LoadingText } from './styles';
import { getReceiverById } from '~/api/receiverAPI';
import CircularIndeterminate from '~/components/Loader';
import { CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

export default function ChooseGifts() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const eventData = location.state && location.state;
  const { flow } = useContext(Context);
  const receiverName = useSelector((state) => state.receiverReducer.receiver);
  const [giftData, setGiftData] = useState('');
  const [loader, setLoader] = useState(false);

  // const { event_gifts } = event ? event.data : '';
  // const gift = event_gifts.map((crr) => crr.gift);
  useEffect(() => {
    setLoader(true);
    if (location.state && location.state) {
      localStorage.setItem('cardData', JSON.stringify(location.state.cardData));
      localStorage.setItem('addinfo', JSON.stringify(location.state.addDetail));
    }
    fecthGift();
    localStorage.removeItem('giftId');
  }, []);

  function fecthGift() {
    setLoader(true);
    getReceiverById(
      (receiverName && receiverName.id) ||
        (eventData && eventData.receiverData && eventData.receiverData.id),
    )
      .then((ea) => {
        setGiftData(ea.data.gifts);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoader(false);
      });
  }

  const addinfo = localStorage.getItem('addinfo');
  const getInfo = JSON.parse(addinfo);

  const handleSubmit = () => {
    const cardDATA = localStorage.getItem('cardData');
    const cardParseData = JSON.parse(cardDATA);
    const getGiftId = localStorage.getItem('giftId');
    const cardData = location.state && location.state;

    if (cardData === undefined) {
      const cardData = { cardData: cardParseData };
      if (getGiftId) {
        if (!cardData.gift_id) {
          cardData.gift_id = '';
        }
        cardData.gift_id = getGiftId;
      }
      history.push('/manage-cards', cardData);
    } else {
      if (getGiftId) {
        if (!cardData.gift_id) {
          cardData.gift_id = '';
        }
        cardData.gift_id = getGiftId;
      }
      history.push('/manage-cards', cardData);
    }

    // if (flow.onboarding) {
    // history.push('/manage-cards', cardData);
    // } else if (flow.giftOption === 'multiple-gifts-invite') {
    //   history.push('/select-promo');
    // } else {
    //   history.push('/gift-instruction');
    // }
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.lightPurple}
        buttonText="Next"
        buttonFunction={() => handleSubmit()}
      >
        <h2>Choose Gifts</h2>
        <h3>
          Select the gifts you want to add to{' '}
          {(receiverName && receiverName.first_name) ||
            (receiverName && receiverName.group_name) ||
            (eventData &&
              eventData.receiverData &&
              eventData.receiverData.first_name) ||
            (eventData &&
              eventData.receiverData &&
              eventData.receiverData.group_name)}
          ’s event.
        </h3>
      </SubHeader>
      <Container>
        {!loader ? (
          <>{giftData && <GiftList gifts={giftData} addInfo={getInfo} />} </>
        ) : (
          <LoaderDiv>
            <CircularProgress size={20} color={'secondary'} />
            <LoadingText>Loading...</LoadingText>
          </LoaderDiv>
        )}
      </Container>
    </>
  );
}
