import receivers from '~/context/receivers';
import gifts from '~/context/gifts';

import background from '~/assets/images/lilgirl.jpg';
import giftImage from '~/assets/images/golf-clubs.jpg';
import person1 from '~/assets/images/person1.jpg';
import person2 from '~/assets/images/person2.jpg';
import person3 from '~/assets/images/person3.jpg';
import person4 from '~/assets/images/person4.jpg';

import main from '~/styles/themes/main';

const events = [
  {
    id: 1,
    mainColor: main.colors.red,
    type: 'oneGift',
    name: "Eugene's Graduation Gift",
    date: 'March 26th',
    receiver: receivers[1],
    gift: {
      name: 'Golf Clubs',
      value: 680,
      raised: 335,
    },
    background: giftImage,
    raised: 360,
    friends: 24,
    gifts,
    contributors: {
      total: 16,
      avatars: [
        { image: person1 },
        { image: person2 },
        { image: person3 },
        { image: person4 },
      ],
    },
    rsvps: {
      total: 2,
      avatars: [{ image: person1 }, { image: person2 }],
    },
  },
  {
    id: 2,
    type: 'party',
    name: 'Magic 10th Birthday',
    date: 'March 26th',
    receiver: receivers[1],
    background,
    raised: 360,
    friends: 24,
    gifts,
    contributors: {
      total: 16,
      avatars: [
        { image: person1 },
        { image: person2 },
        { image: person3 },
        { image: person4 },
      ],
    },
    rsvps: {
      total: 2,
      avatars: [{ image: person1 }, { image: person2 }],
    },
  },
  {
    id: 3,
    type: 'giftList',
    name: 'Gift List',
    date: 'March 26th',
    receiver: receivers[1],
    background,
    raised: 360,
    friends: 24,
    gifts,
    contributors: {
      total: 16,
      avatars: [
        { image: person1 },
        { image: person2 },
        { image: person3 },
        { image: person4 },
      ],
    },
    rsvps: {
      total: 2,
      avatars: [{ image: person1 }, { image: person2 }],
    },
  },
];

export default events;
