/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { all } from 'redux-saga/effects';
import authSaga from './auth/authSaga';
import userSaga from './user/userSaga';
import receiverSaga from '~/store/modules/receiver/receiverSaga';
import giftSaga from '~/store/modules/gift/giftSaga';
import eventSaga from '../../store/modules/event/eventSaga';
import bankAccountSaga from './bankAccount/bankAccountSaga';
import notificationSaga from './notification/notificationSaga';
import creditCardSaga from './creditCard/creditCardSaga';

export default function* rootSaga() {
  yield all([
    authSaga,
    userSaga,
    receiverSaga,
    giftSaga,
    eventSaga,
    bankAccountSaga,
    notificationSaga,
    creditCardSaga,
  ]);
}
