import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import { Mobile, Default } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';

import {
  Container,
  InputValidation,
  InputWrapper,
  PersonWaving,
} from './styles';
import { getUserByEmail } from '~/api/userAPI';
import CircularIndeterminate from '~/components/Loader';
import { getEventGiftById, getEventGiftByOneOrMoreId } from '~/api/eventAPI';
import { getJWTToken } from '~/utils/utility';
import RSVPClosedConfirmed from '../RSVPClosedConfirmed';

export default function RSVPCant() {
  const { giftId } = useParams();
  const { colors } = useContext(ThemeContext);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [formError, setFormError] = useState(null);
  const [loader, setLoader] = useState(false);
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const user_id = queryParams.get('u');
  const eventName = user_id && user_id.split('?n=');
  const [gifts, setGifts] = useState('');
  const tokenData = JSON.parse(getJWTToken());

  const getGiftS = async (giftId) => {
    setLoader(true);
    await getEventGiftById(giftId)
      .then((res) => {
        getEventGiftS(res);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getGiftS(giftId);
  }, [giftId]);

  const getEventGiftS = async (giftId) => {
    setLoader(true);
    const giftIdArray = giftId && giftId.split(',');
    if (giftIdArray.length === 1) {
      await getEventGiftByOneOrMoreId(giftIdArray[0])
        .then((res) => {
          setGifts(res);
          setLoader(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    } else if (giftIdArray.length > 1) {
      const giftIdsString = giftIdArray.join(',');
      await getEventGiftByOneOrMoreId(giftIdsString)
        .then((res) => {
          setGifts(res);
          setLoader(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    } else {
    }
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name === '') {
      setFormError(`validation failed: name required!`);
      return 'validationFailed';
    }
    if (email === '') {
      setFormError(`validation failed: email required!`);
      return 'validationFailed';
    }
    if (!emailRegex.test(email)) {
      setFormError('Validation failed: enter a valid email address!');
      return 'validationFailed';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = validateForm();
    if (error !== 'validationFailed') {
      setLoader(true);
      await getUserByEmail(email)
        .then((res) => {
          if (res && res.data) {
            history.push('/rsvp-confirmed/wont-attend', {
              email: email,
              name: name,
              checked: 'true',
            });
            setLoader(false);
          } else {
            history.push('/rsvp-confirmed/wont-attend', {
              email: email,
              name: name,
              checked: 'false',
            });
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
    // history.push('/rsvp-confirmed/wont-attend');
  };

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 3000);

  return (
    <>
      {tokenData && tokenData.token ? (
        <RSVPClosedConfirmed data={'yes'} />
      ) : (
        <>
          <SubHeader
            backgroundColor={colors.lightPurple}
            mobileDescVisible={false}
            hideBackButton
          >
            <Mobile>
              <h2>RSVP</h2>
            </Mobile>
            <Default>
              <h2>No worries, thanks for letting us know.</h2>
            </Default>

            <h3>
              Please enter your name and email so we can notify the event
              organiser
            </h3>
          </SubHeader>
          <InputValidation>{formError}</InputValidation>
          <Container>
            {!loader ? (
              <>
                <PersonWaving />
                <Mobile>
                  <h2>
                    No worries. Thanks for letting us know you can’t make it.
                  </h2>
                  <h3>
                    Please enter your name and/or email so we can notify the
                    event organiser
                  </h3>
                </Mobile>
                <form>
                  <InputWrapper>
                    <Input
                      label="Your Name"
                      name="name"
                      placeholder="name"
                      type="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Input
                      label="Your Email"
                      name="email"
                      placeholder="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputWrapper>

                  <Button
                    text="DONE"
                    backgroundColor="#01e3fa"
                    buttonFunction={(e) => handleSubmit(e)}
                  />

                  <p>
                    <Link to={`/rspv-gift-wishes/${giftId}}?u=${user_id}`}>
                      Change of plans, I would like to contribute
                    </Link>
                  </p>
                </form>
              </>
            ) : (
              <CircularIndeterminate />
            )}
          </Container>
        </>
      )}
    </>
  );
}