import React from 'react';

import ThankYouNotifications from './ThankYou';
import MainNotifications from './MainNotifications';
import { Container } from './styles';

export default function Notifications() {
  return (
    <Container>
      <ThankYouNotifications />
      <MainNotifications />
    </Container>
  );
}
