import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';

import history from '~/services/history';
import HideFooter from '~/utils/HideFooter';

import Button from '~/components/Button';

import { OuterContainer, Container, SuccessCheckSVG } from './styles';

export default function LaterScreen() {
  const receiver = useSelector((state) => state.receiverReducer);
  const user = useSelector((state) => state.userReducer.profile);
  const theme = useContext(ThemeContext);
  const colors = { start: theme.colors.lightPurple, end: theme.colors.pink };

  return (
    <OuterContainer>
      <HideFooter />
      <Container colors={colors}>
        <div>
          <SuccessCheckSVG />
          <h1>Nice Work!</h1>
          <h3>
            {user.first_name}'s profile is saved and waiting for you to add a
            gift or gifts to it.
          </h3>
        </div>
        <div className="button-wrapper">
          <Button
            backgroundColor="#ffffff70"
            textColor="#fff"
            text="Got it, Thanks!"
            buttonFunction={() => history.push('/my-profile/')}
          />
        </div>
      </Container>
    </OuterContainer>
  );
}
