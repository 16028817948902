import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import HideFooter from '~/utils/HideFooter';

import Button from '~/components/Button';

import { OuterContainer, Container, OpenGift } from './styles';

export default function OnboardingCompleted() {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <HideFooter />
      <OuterContainer>
        <Container colors={{ end: colors.purple, start: colors.primary }}>
          <div>
            <OpenGift />
            <h1>Giddy Up!</h1>
            <h2>
              You can now sit back and relax while your friends contribute
              towards the gift(s) you&nbsp;shared.
            </h2>
          </div>

          <Button
            text="Close"
            backgroundColor="#01e3fa8a"
            buttonFunction={() => history.push('/event-details/1')}
          />
        </Container>
      </OuterContainer>
    </>
  );
}
