import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  width: 100%;
  margin: 0px auto;

  @media (max-width: 767px) {
    width: 100%;
  }

  label,
  .label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input,
  textarea {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }

  textarea {
    min-height: 400px;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 767px) {
      min-height: 415px;
    }
  }

  &.upload-photo {
    > div {
      margin-bottom: 20px;
    }

    p {
      color: #888;
    }

    .upload-wrapper {
      display: flex;

      > div {
        margin-right: 20px;
      }
    }
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 48%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;
  }
`;
export const DeleteIcon = styled.div`
  font-size: 20px;
  color: white;
  background-color: red;
  border-radius: 20px;
  margin-left: -9px;
  margin-top: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;
export const ImageDiv = styled.div`
  display: flex;
`;
