import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 20px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 15px;
  }

  > label {
    background: white;
    box-shadow: 0px 0px 10px 0px #cacaca8a;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    max-height: 115px;

    .gift-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    h3 {
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 18px;
    }

    small {
      color: #aeaeae;
      font-size: 13px;
    }
  }

  button {
    max-width: 100%;
    max-height: 50px;
  }
`;

export const CheckboxControl = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
  }

  .MuiTypography-root.MuiTypography-body1 {
    text-transform: none;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
  }
`;
export const LoaderDiv = styled.div`
  margin-top: 25px;
  align-items: center;
  margin-left: 50%;
`;

export const LoadingText = styled.div`
  color: #13287f;
  margin-bottom: 25px;
`;
export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;
