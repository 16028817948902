import React, { useContext } from 'react';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';

import { ReactComponent as Gifts } from '~/assets/images/gifts.svg';
import { ReactComponent as GiftIcon } from '~/assets/images/gift-icon.svg';

import SubHeader from '~/components/SubHeader';

import { Container, BigButton } from './styles';

export default function GiftGoal() {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
      >
        <h2>Goal of Gift</h2>
        <h3>I want to invite friends to contributo to:</h3>
      </SubHeader>
      <Container>
        <BigButton onClick={() => history.push('/choose-gifts')}>
          <div className="image-wrapper">
            <GiftIcon alt="One gift" />
          </div>
          <div className="text-content">
            <h2>Fund one special gift</h2>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>

        <BigButton
          backgroundColor={colors.lightPurple}
          onClick={() => history.push('/choose-gifts')}
        >
          <div className="image-wrapper">
            <Gifts alt="Multiple Gifts" />
          </div>
          <div className="text-content">
            <h2>Fund multiple gifts</h2>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>
      </Container>
    </>
  );
}
