import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosPin } from 'react-icons/io';

import AboutImage from '~/assets/images/menu-about.png';
import FaqsImage from '~/assets/images/menu-faqs.png';
import FeedbackImage from '~/assets/images/menu-feedback.png';
import LogoutImage from '~/assets/images/menu-logout.png';
import SettingsImage from '~/assets/images/menu-settings.png';
import TutorialsImage from '~/assets/images/menu-tutorials.png';

import Context from '~/context';
import history from '~/services/history';
import { signOut } from '~/store/modules/auth/authAction';

import Avatar from '~/components/Avatar';
import { Container, Badge } from './styles';

export default function Menu() {
  const profile = useSelector((state) => state.userReducer.profile);
  const { handleShowMenu } = useContext(Context);
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(signOut());
  }

  function handleOpenProfile() {
    history.push('/my-profile');
    handleShowMenu();
  }

  return (
    <Container>
      <div>
        <div role="presentation" onClick={handleOpenProfile}>
          <Avatar size={70} image={profile ? profile.user_image : null} />
          <h3>
            {profile && profile.first_name} {profile && profile.last_name}
          </h3>
          <div className="location">
            <IoIosPin size={15} /> {profile && profile.city},{' '}
            {profile && profile.country}
          </div>
        </div>
        <nav>
          <ul>
            <li>
              <Link to="/app-settings" onClick={() => handleShowMenu()}>
                <img
                  src={SettingsImage}
                  className="menu-icon"
                  alt="App Settings"
                />
                App Settings
                <Badge>
                  <span>5</span>
                </Badge>
              </Link>
            </li>
            <li>
              <a to="#" href="#">
                <img
                  src={TutorialsImage}
                  className="menu-icon"
                  alt="Tutorials"
                />
                Tutorials
              </a>
            </li>
            <li>
              <Link to="/faqs" onClick={() => handleShowMenu()}>
                <img src={FaqsImage} className="menu-icon" alt="FAQ's" />
                FAQ's
              </Link>
            </li>
            <li>
              <Link to="/about_us" onClick={() => handleShowMenu()}>
                <img src={AboutImage} className="menu-icon" alt="About Us" />
                About Us
              </Link>
            </li>
            <li className="divider" />
            <li>
              <a to="#">
                <img src={FeedbackImage} className="menu-icon" alt="Feedback" />
                Feedback
              </a>
            </li>
            <li>
              <a to="#" onClick={handleLogout}>
                <img src={LogoutImage} className="menu-icon" alt="Logout" />
                Logout
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </Container>
  );
}
