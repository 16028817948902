import styled from 'styled-components';

export const Wrapper = styled.div`
  background: linear-gradient(135deg, #53bedf, #132c80);
  min-height: calc(100vh - 64px);
  padding-bottom: 20px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #222;

  nav ul {
    list-style-type: none;
    padding: 0;
    margin: 30px auto 0;
  }

  nav ul li {
    font-size: 1em;
    transform: translateX(-30px);
    opacity: 0;

    &.divider {
      margin-bottom: 40px;
    }
  }

  nav ul li:nth-child(1) {
    transition: all 0.3s ease-out 0.2s;
  }

  nav ul li:nth-child(2) {
    transition: all 0.3s ease-out 0.4s;
  }

  nav ul li:nth-child(3) {
    transition: all 0.3s ease-out 0.6s;
  }

  nav ul li:nth-child(4) {
    transition: all 0.3s ease-out 0.8s;
  }

  nav ul li:nth-child(5) {
    transition: all 0.3s ease-out 1s;
  }

  nav ul li:nth-child(6) {
    transition: all 0.3s ease-out 1.2s;
  }

  nav ul li:nth-child(7) {
    transition: all 0.3s ease-out 1.4s;
  }

  nav ul li a {
    text-decoration: none;
    display: flex;
    padding: 5px 0;
    margin-top: 5px;
    color: #fff;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  &.show-menu main {
    height: 100%;
    box-shadow: 0 0 100px 10px rgba(0, 0, 0, 0.7);
    transform: scale(0.7);
    right: -12%;
    cursor: pointer;
    overflow: hidden;
    transition: all (0.2);

    @media (max-width: 767px) {
      transform: scale(0.9);
      right: -70%;
    }
  }

  &.show-menu nav ul li {
    transform: translateX(0);
    opacity: 1;
  }

  &.show-menu nav ul li:nth-child(1) {
    transition: all 0.3s linear 0;
  }

  &.show-menu nav ul li:nth-child(2) {
    transition: all 0.3s linear 0;
  }

  &.show-menu nav ul li:nth-child(3) {
    transition: all 0.3s linear 0;
  }

  &.show-menu nav ul li:nth-child(4) {
    transition: all 0.3s linear 0;
  }

  &.show-menu nav ul li:nth-child(5) {
    transition: all 0.3s linear 0;
  }

  &.show-menu nav ul li:nth-child(7) {
    transition: all 0.3s linear 0;
  }

  &.show-menu nav ul li:nth-child(8) {
    transition: all 0.3s linear 0;
  }

  main {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      background-color: #f5f5f5;
      overflow-y: scroll;
    }
  }
`;
