import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    background: #fff;
  }

  a {
    color: #333;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const Media = styled.img`
  background-color: #c1c4c4;
  width: 100%;
  height: auto;
`;

export const Comment = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #fff;

  img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
  }

  strong {
    margin-right: 5px;
  }

  > div {
    max-height: 40px;
    overflow: hidden;
  }
`;

export const Likes = styled.div`
  background-color: #f1f1f1;
  width: 100%;
  display: flex;
  padding: 10px;

  svg {
    margin-right: 5px;
  }
`;
