import React, { useContext } from 'react';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';

import SubHeader from '~/components/SubHeader';

import { Container, BigButton } from './styles';

export default function AddInvitees() {
  const { colors } = useContext(ThemeContext);

  // const handleSubmit = () => {};

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
      >
        <h2>Add Invitees</h2>
        <h3>How would you like to add your invitee&nbsp;details?</h3>
      </SubHeader>
      <Container>
        <BigButton onClick={() => history.push('/my-contacts')}>
          <div className="text-content">
            <h3>Choose from my contacts</h3>
          </div>
          <IoIosArrowForward size={25} color={colors.primary} />
        </BigButton>

        <BigButton onClick={() => history.push('/email-list')}>
          <div className="text-content">
            <h3>Copy and paste list of emails</h3>
          </div>
          <IoIosArrowForward size={25} color={colors.primary} />
        </BigButton>

        <BigButton onClick={() => history.push('/add-invitee')}>
          <div className="text-content">
            <h3>Enter contacts manually</h3>
          </div>
          <IoIosArrowForward size={25} color={colors.primary} />
        </BigButton>
      </Container>
    </>
  );
}
