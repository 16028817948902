import styled from 'styled-components';

export const SocialLoginWrapper = styled.div`
  max-width: 302px;
  margin: 0 auto;

  p {
    font-size: 13px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
    line-height: 19px;
    font-weight: 400;

    &.privacy {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

export const SocialLoginButton = styled.div`
  border: 1px solid white;
  height: 44px;
  color: white;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  max-width: 48%;
  min-width: 100px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;

  transition: border-color 0.5s;

  span {
    max-width: 67px;
    display: block;
    font-size: 12px;
  }

  svg {
    margin-right: 10px;
  }

  &:hover {
    border-color: #a6cce2;

    span {
      color: #a6cce2;
      transition: color 0.5s;
    }
    svg {
      fill: #a6cce2;
      transition: fill 0.5s;
    }
  }
`;
