import React, { useContext, useState } from 'react';

import history from '~/services/history';

import Context from '~/context';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, Container } from './styles';
import { IoIosClose } from 'react-icons/io';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

export default function Options(data) {
  const { handleShowModal } = useContext(Context);
  const { id } = useParams();
  const receiver = useSelector((state) => state.userReducer.profile);
  const [modalVisible, setModalVisible] = useState(false);
  const shareUrl = `${process.env.REACT_APP_PURPOSIT_BASE_URL}/contribution-one-gift/${id}?u=${receiver.id}`;
  const title = data && data.inviteData.eventName;

  const handleModalVisible = (value) => {
    setModalVisible(value);
  };

  const handleGoToPage = (page) => {
    handleShowModal();
    if (page === '/email-details') {
      history.push(page, { data });
    } else {
      history.push(page);
    }
  };

  const handleShow = () => {
    setModalVisible(true);
  };

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
  }

  const handleCopy = () => {
    let makeLink = `${process.env.REACT_APP_PURPOSIT_BASE_URL}/contribution-one-gift/${id}?u=${receiver.id}`;
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(makeLink);
      return;
    }
    navigator.clipboard.writeText(makeLink).then(
      function () {
        toast.success('Copied!');
        handleShowModal();
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      },
    );
  };
  
  const handleClose = () => {
    localStorage.setItem('close', 'open');
    // handleShowModal();
  };
  return (
    <>
      <button type="submit" onClick={() => handleGoToPage('/email-details')}>
        SEND EMAIL
      </button>
      <button type="submit" onClick={handleShow}>
        SHARE VIA SOCIAL MEDIA
      </button>
      <button type="submit" onClick={handleCopy}>
        COPY LINK
      </button>
      <button type="submit">DOWNLOAD QR CODE</button>
      <button type="submit" onClick={handleClose}>
        Cancel
      </button>
      {modalVisible && (
        <div className="overlay" onClick={() => handleModalVisible(false)}>
          <Modal
            className={modalVisible && 'visible'}
            onClick={() => handleModalVisible(false)}
          >
            <h1>Share</h1>
            <br />
            <Container>
              <div>
                <FacebookShareButton
                  className="buttonIcons"
                  url={shareUrl}
                  quote={title}
                >
                  <FacebookIcon className="icons" size={50} round={true} />
                </FacebookShareButton>
                <p className="icontext">Facebook</p>
              </div>{' '}
              <div style={{ marginLeft: '11px', marginRight: '21px' }}>
                <TwitterShareButton
                  className="buttonIcons"
                  url={shareUrl}
                  title={title}
                >
                  <TwitterIcon className="icons" size={50} round={true} />
                </TwitterShareButton>
                <p className="icontext">Twitter</p>
              </div>{' '}
              <div>
                <LinkedinShareButton className="buttonIcons" url={shareUrl}>
                  <LinkedinIcon className="icons" size={50} round={true} />
                </LinkedinShareButton>
                <p className="icontext">Linkedin</p>
              </div>
              <div style={{ marginLeft: '19px', marginRight: '6px' }}>
                <WhatsappShareButton
                  className="buttonIcons"
                  url={`${shareUrl}\n`}
                  title={`${title}\n`}
                >
                  <WhatsappIcon className="icons" size={50} round={true} />
                </WhatsappShareButton>
                <p className="icontext">WhatsApp</p>
              </div>{' '}
              <div>
                <TelegramShareButton
                  className="buttonIcons"
                  url={shareUrl}
                  title={title}
                >
                  <TelegramIcon className="icons" size={50} round={true} />
                </TelegramShareButton>
                <p className="icontext">Telegram</p>
              </div>{' '}
              <IoIosClose
                className="closepop"
                onClick={() => handleModalVisible(false)}
                color="#fff"
                size={50}
              />
            </Container>
          </Modal>
        </div>
      )}
    </>
  );
}
