const bankAccounts = [
  {
    id: 1,
    name: 'Bill Gates',
    number: '9123-9234',
  },
  {
    id: 2,
    name: 'Warren Buffet',
    number: '9123-9234',
  },
];

export default bankAccounts;
