import person1 from '~/assets/images/person1.jpg';
import person2 from '~/assets/images/person2.jpg';
import person3 from '~/assets/images/person3.jpg';
import person4 from '~/assets/images/person4.jpg';

const receivers = [
  {
    id: 1,
    firstName: 'Paul',
    lastName: 'Jenkins',
    avatar: person1,
    location: 'Melbourne, Australia',
  },
  {
    id: 2,
    firstName: 'Johnny',
    lastName: 'Cage',
    avatar: person2,
    location: 'Los Angeles, USA',
  },
  {
    id: 3,
    firstName: 'Blanka',
    lastName: 'Cage',
    avatar: person3,
    location: 'Amazon, Brazil',
  },
  {
    id: 4,
    firstName: 'Homer',
    lastName: 'Simpson',
    avatar: person4,
    location: 'Springfield, USA',
  },
];

export default receivers;
