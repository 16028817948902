import axios from 'axios';
// import { getJWTToken} from '../utils/utility';

const { REACT_APP_PURPOSIT_API_URL } = process.env;

// global config
axios.defaults.baseURL = REACT_APP_PURPOSIT_API_URL;

// axios instance for data services
const apiAxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

// axios insance for image services
const imageAxiosInstance = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export { apiAxiosInstance, imageAxiosInstance };
