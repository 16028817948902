import React, { useContext, useEffect } from 'react';

import Context from '~/context';
// import { Container } from './styles';

function HideFooter() {
  const { handleHideFooter } = useContext(Context);

  useEffect(() => {
    handleHideFooter(true);
    // returned function will be called on component unmount
    return () => {
      handleHideFooter(false);
    };
  }, [handleHideFooter]);

  return <></>;
}

export default HideFooter;
