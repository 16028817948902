import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import history from '~/services/history';

import accounts from './accounts';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import {
  Container,
  InputWrapper,
  RadioButton,
  RadioControl,
  BankAccount,
} from './styles';

export default function ChangeBankAccount() {
  const { colors } = useContext(ThemeContext);
  const [radioValue, setRadioValue] = useState(1);

  const handleRadioChange = (event) => {
    setRadioValue(parseInt(event.target.value, 10));
  };

  const handleNext = () => {
    history.push({
      pathname: '/success',
      state: {
        destination: `/delete-bank-account`,
        message: 'Account Linked!',
        colors: { start: colors.lightPurple, end: colors.purple },
      },
    });
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.secondary}
        buttonBackgroundColor="#8991C1"
        buttonText="Next"
        buttonTextColor="#fff"
        buttonFunction={handleNext}
      >
        <h2>SELECT NEW ACCOUNT</h2>
        <h3>Select a new account to replace the account you want to delete:</h3>
      </SubHeader>
      <Container>
        <form>
          <InputWrapper>
            <span className="label">BANK ACCOUNT(S) - TO RECEIVE FUNDS</span>

            <RadioControl component="fieldset">
              <RadioGroup
                aria-label="funds-instructions"
                name="funds-instructions"
                value={radioValue}
                onChange={handleRadioChange}
              >
                {accounts.map((account) => (
                  <BankAccount key={account.id}>
                    <FormControlLabel
                      value={account.id}
                      control={<RadioButton />}
                      labelPlacement="start"
                      label={
                        <div>
                          <span>{account.name}</span>&nbsp;|&nbsp;
                          <span>{account.number}</span>
                        </div>
                      }
                    />
                  </BankAccount>
                ))}
              </RadioGroup>
            </RadioControl>
            <Button
              text="ADD BANK ACCOUNT
              "
              buttonFunction={() =>
                history.push({
                  pathname: '/link-bank/',
                  state: { origin: 'change-bank-account' },
                })
              }
            />
          </InputWrapper>
        </form>
      </Container>
    </>
  );
}
