import React from 'react';
import { useSelector } from 'react-redux';
import EmptyList from '../EmptyList';
import { convertUTCDateToString } from '../../../utils/utility';
import { Container, Card } from './styles';
import profileImage from '~/assets/images/avtar-square-image.png';

export default function Sent() {
  const notificationReducer = useSelector((state) => state.notificationReducer);
  const { thankuCard } = notificationReducer;
  const sentCard = thankuCard ? thankuCard.sentThankyou : '';

  return (
    <>
      {sentCard.length > 0 ? (
        <Container>
          {sentCard.map((card) => (
            <Card key={card.user_id}>
              <div>
                <img
                  src={
                    card.user.user_image ? card.user.user_image : profileImage
                  }
                  alt={card.user.first_name}
                />
                <div>
                  <div>
                    <div>
                      <h3>
                        {' '}
                        {`${card.user.first_name}\xa0${card.user.last_name}`}
                      </h3>
                      <small>{convertUTCDateToString(card.date)}</small>
                    </div>
                    <div />
                  </div>
                  <div>
                    <p>{card.description}</p>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </Container>
      ) : (
        <EmptyList>You have not sent a thank you card yet.</EmptyList>
      )}
    </>
  );
}
