import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }
`;
