import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { darken } from 'polished';
// Action
import {
  fetchThankuCard,
  fetchThankuCardPending,
} from '~/store/modules/notification/notificationAction';

import history from '~/services/history';

import Received from './Received';
import Sent from './Sent';
import Pending from './Pending';
import SubHeader from '~/components/SubHeader';

import { Container, StyledTabs as Tabs, StyledTab as Tab } from './styles';

function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ThankYouCards() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const userProfile = useSelector((state) => state.userReducer.profile);
  const { id } = userProfile;
  const tabs = [<Received />, <Sent />, <Pending />];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [value, setValue] = useState(0);

  const location = useLocation();

  useEffect(() => {
    localStorage.removeItem('cardPendingData');
    localStorage.removeItem('type');
  }, [location]);
  useEffect(() => {
    Dispatch(fetchThankuCard(id));
    Dispatch(fetchThankuCardPending(id));

    if (location && location.state && location.state.tab === 'tab3') {
      setActiveTab(tabs[2]);
      setValue(2);
    }
  }, []);

  const handleSubmit = () => {
    history.push('/onboarding-success/2');
  };

  const handleChange = (event, newValue) => {
    setActiveTab(tabs[newValue]);
    setValue(newValue);
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.1, colors.orange)}
        buttonTextColor="#ffffff"
        buttonFunction={() => handleSubmit()}
        mobileDescVisible={false}
        backButtonFunction={
          location.state && location.state.lastPage
            ? () => history.push('/my-profile')
            : () => history.push('/my-profile')
        }
      >
        <h2>Thank You Cards</h2>
      </SubHeader>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Received" key={a11yProps(0).key} id={a11yProps(0).id} />
        <Tab label="Sent" key={a11yProps(1).key} id={a11yProps(1).id} />
        <Tab label="Pending" key={a11yProps(2).key} id={a11yProps(2).id} />
      </Tabs>
      <Container>{activeTab}</Container>
    </>
  );
}
