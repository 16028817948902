import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  IoIosArrowRoundBack,
  IoIosMenu,
  IoIosMore,
  IoMdMore,
} from 'react-icons/io';

import theme from '~/styles/themes/main';
import history from '~/services/history';
import Context from '~/context';

import { Container, Side, MobileDesc, Content } from './styles';

import ThankYouNotifications from '~/components/Notifications/ThankYou';
import MainNotifications from '~/components/Notifications/MainNotifications';
import SubHeaderButton from './SubHeaderButton';
import SubHeaderButtonWithLoader from './SubHeaderButtonWithLoader';

export default function SubHeader({
  backgroundColor,
  buttonBackgroundColor,
  buttonTextColor,
  buttonText,
  children,
  buttonFunction,
  hideBackButton,
  backButtonFunction,
  mobileNavButtons,
  mobileDescVisible,
  mobileShowMenu,
  mobileShowNotifications,
  more,
  className,
  submitButtonLoading
}) {
  const { handleShowMenu, handleShowModal } = useContext(Context);
  const [showNavButtons, setShowNavButtons] = useState(mobileNavButtons);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        if (!mobileNavButtons) {
          setShowNavButtons(false);
        }
      } else {
        setShowNavButtons(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mobileNavButtons]);

  return (
    <Container backgroundColor={backgroundColor} className={className}>
      <div className="subheader-main">
        {showNavButtons && (
          <Side side={mobileShowNotifications ? 'space-between' : 'flex-start'}>
            {mobileShowMenu ? (
              <IoIosMenu size={52} onClick={handleShowMenu} />
            ) : (
              hideBackButton || (
                <IoIosArrowRoundBack
                  size={52}
                  onClick={backButtonFunction || (() => history.goBack())}
                />
              )
            )}
            {mobileShowNotifications && <ThankYouNotifications />}
          </Side>
        )}
        <Content>{children}</Content>
        {showNavButtons && (
          <Side side={mobileShowNotifications ? 'space-between' : 'flex-end'}>
            {mobileShowNotifications && <MainNotifications />}
            {more ? (
              <>
                <IoIosMore
                  size={52}
                  onClick={handleShowModal}
                  className="hide-mobile"
                />
                <IoMdMore
                  size={52}
                  onClick={handleShowModal}
                  className="hide-desktop"
                />
              </>
            ) : (
              <>
              {!submitButtonLoading ?
              <SubHeaderButton
                backgroundColor={buttonBackgroundColor}
                text={buttonText}
                textColor={buttonTextColor}
                buttonFunction={buttonFunction}
              />
             :
             <>
              <SubHeaderButtonWithLoader
                backgroundColor={buttonBackgroundColor}
                text={buttonText}
                textColor={buttonTextColor}
                disableBoolean={true}
              />
           </>
              }
           
            </>
            )}
          </Side>
        )}
      </div>

      {mobileDescVisible && (
        <MobileDesc backgroundColor={backgroundColor}>{children}</MobileDesc>
      )}
    </Container>
  );
}

SubHeader.propTypes = {
  backgroundColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  buttonFunction: PropTypes.func,
  hideBackButton: PropTypes.bool,
  backButtonFunction: PropTypes.func,
  mobileNavButtons: PropTypes.bool,
  mobileDescVisible: PropTypes.bool,
  mobileShowMenu: PropTypes.bool,
  mobileShowNotifications: PropTypes.bool,
  more: PropTypes.bool,
  className: PropTypes.string,
};

SubHeader.defaultProps = {
  backgroundColor: theme.colors.purple,
  buttonBackgroundColor: theme.colors.primary,
  buttonTextColor: theme.colors.secondary,
  buttonText: '',
  children: '',
  buttonFunction: () => {},
  hideBackButton: false,
  backButtonFunction: () => {
    history.goBack();
  },
  mobileNavButtons: true,
  mobileDescVisible: true,
  mobileShowMenu: false,
  mobileShowNotifications: false,
  more: false,
  className: '',
};
