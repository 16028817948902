import person1 from '~/assets/images/person1.jpg';
import person2 from '~/assets/images/person2.jpg';
import person3 from '~/assets/images/person3.jpg';
import person4 from '~/assets/images/person4.jpg';

const contacts = [
  {
    id: 1,
    name: 'Mary',
    date: '01 January 2020',
    donation: 720,
    invited: false,
    avatar: person1,
  },
  {
    id: 2,
    name: 'Joanne',
    date: '26 March 2020',
    donation: 200,
    invited: true,
    avatar: person2,
  },
  {
    id: 3,
    name: 'Joanne',
    date: '26 March 2020',
    donation: 200,
    invited: false,
    avatar: person3,
  },
  {
    id: 4,
    name: 'Joanne',
    date: '26 March 2020',
    donation: 200,
    invited: false,
    avatar: person4,
  },
];

export default contacts;
