import styled from 'styled-components';

import EditPenIcon from '~/assets/images/edit-pen-icon.svg';

export const Container = styled.div`
  max-width: auto;
  min-height: auto;
  margin: 10px auto;
  width: 90%;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  padding: 20px;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .letter-container {
    max-width: 390px;
    min-height: 400px;
    background: white;
    padding: 17px;
    border: 1px solid #f1f1f1;

    .image-wrapper {
      position: relative;
      cursor: pointer;

      &.modal-open {
        z-index: 3;

        &:after {
          content: '';
        }
      }

      &:after {
        content: url(${EditPenIcon});
        display: block;
        width: 26px;
        height: 26px;
        position: absolute;
        right: 5px;
        top: 5px;
      }

      img {
        max-width: 360px;
        height: 225px;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }

    > div {
      position: relative;
    }
  }
`;

export const LetterTextContainer = styled.div`
  max-width: 325px;
  margin: 0 auto;
  padding: 30px 25px;

  p {
    font-size: 15px;
    color: #888;
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: calc(100% + 145px);
  background: #000000bf;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  &.visible {
    display: block;
  }

  svg {
    position: fixed;
    right: 5px;
    top: 2px;
    cursor: pointer;
  }
`;

export const ImagePicker = styled.div`
  padding: 15px;
  display: none;
  position: absolute;
  flex-direction: column;
  top: 100px;
  z-index: 3;
  width: 100%;
  max-width: initial;

  &.visible {
    display: flex;
    padding: 0;
  }

  p {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  label {
    border: 2px solid ${(props) => props.theme.colors.primary};
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      transition: 0.2s;
      opacity: 1;
      margin-bottom: 0;
      border: 0;
      height: 62px;
      width: 62px;
      border: 2px solid transparent;
      background: transparent;
      cursor: pointer;

      &:last-child {
        background: transparent;
        border: 2px solid ${(props) => props.theme.colors.primary};
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;
        height: 100%;
        transition: 0.2s;
        object-fit: cover;
        object-position: center;
      }

      p {
        font-weight: bold;
        color: #888;
      }

      &:hover {
        border: 2px solid ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const ColorPicker = styled.div`
  padding: 15px;
  display: none;
  position: absolute;
  flex-direction: column;
  top: 100px;
  z-index: 3;
  width: 100%;
  max-width: initial;

  &.visible {
    display: flex;
    padding: 0;
  }

  p {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  label {
    border: 2px solid ${(props) => props.theme.colors.primary};
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      img {
        width: 100%;
        height: 100%;
        transition: 0.2s;
        object-fit: cover;
        object-position: center;
      }

      p {
        font-weight: bold;
        color: #888;
      }

      &:hover {
        border: 2px solid ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const Title = styled.section`
  background-color: ${({ backgroundColor }) => backgroundColor};
  min-height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 33px;
  text-align: center;
  margin: -45px auto 0;
  z-index: 2;
  position: relative;
  max-width: 325px;
  cursor: pointer;

  &:after {
    content: url(${EditPenIcon});
    display: ${(props) => (props.isEditing ? 'none' : 'block')};
    width: 26px;
    height: 26px;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  h2 {
    white-space: pre-line;
    font-size: 20px;
    font-weight: bold;
    color: ${({ color }) => color};
  }
`;

export const ColorButton = styled.button`
  transition: 0.2s;
  opacity: 1;
  margin-bottom: 0;
  border: 0;
  height: 62px;
  width: 62px;
  border: 2px solid transparent;
  cursor: pointer;
  background: ${({ backgroundColor }) => backgroundColor};
`;
export const GridImage = styled.div`
  display: flex;
  flex-wrap: wrap !important;
  justify-content: start !important;
`;

export const ImageContainer = styled.button`
  transition: 0.2s;
  opacity: 1;
  margin-bottom: 8px !important;
  border: 0;
  height: 62px;
  width: 62px;
  border: 2px solid transparent;
  background: transparent;
  cursor: pointer;
  margin-right: 8px;
`;

export const UploadContainer = styled.div`
  margin-bottom: 8px !important;
`;
