import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { IoIosMail, IoIosLock } from 'react-icons/io';
import history from '~/services/history';
import SocialLogin from '~/components/SocialLogin';

import {
  TopWrapper,
  InputWrapper,
  ContainerFooter,
} from '~/pages/_layout/auth/styles';
import { forgotPassword } from '~/api/userAPI';
import { toast } from 'react-toastify';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insert a valid email.')
    .required('A valid email is required.'),
});

export default function ForgotPassword() {
  const {
    loading,
    token,
    signed,
    email: LoginEmail,
  } = useSelector((state) => state.authReducer);

  const profile = useSelector((state) => state.userReducer.profile);
  const [loader, setLoader] = useState(false);
  const [resetData, setReserData] = useState('');

  const handleSubmit = async ({ email }) => {
    setLoader(true);

    const payload = {
      email: email,
    };
    await forgotPassword(payload)
      .then((res) => {
        setReserData(res);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log('err', err);
      });
  };


  return (
    <>
      <TopWrapper>
        <h2>Forgot Password</h2>
        <Form schema={schema} onSubmit={handleSubmit}>
          <InputWrapper>
            <IoIosMail size={25} color="#a6cce2" />
            <Input name="email" type="email" placeholder="Email" />
          </InputWrapper>
          {resetData && resetData.message === 'Email sent successfully' ? (
            <>
              <h3 className="forgotSuccess1">
                An email has been sent to your registered email address. Please
                check your inbox and
                <br /> <strong>update</strong> your <strong>password</strong>.
              </h3>
            </>
          ) : resetData && resetData.message === 'User not found' ? (
            <h3 className="forgotSuccess1">
              There is no account associated with the email
              <br /> <strong>address</strong> you <strong>provided</strong>.
            </h3>
          ) : (
            ''
          )}
          <button type="submit">
            {loader ? 'Loading...' : 'Retrieve Password'}
          </button>
        </Form>
        <SocialLogin />
      </TopWrapper>
      <ContainerFooter>
        <Link to="/">
          Already have an account? <strong>LOG IN</strong>
        </Link>
      </ContainerFooter>
    </>
  );
}
