import React from 'react';
import { useLocation } from 'react-router-dom';

import history from '~/services/history';
import HideFooter from '~/utils/HideFooter';

import { OuterContainer, Container, SuccessCheckSVG } from './styles';

export default function SuccessScreen() {
  const location = useLocation();
  const { message, destination, colors } = location.state;
  const originState = location.state.state ? location.state.state : {};

  setTimeout(
    () => history.push({ pathname: destination, state: originState }),
    3000,
  );

  return (
    <>
      <OuterContainer>
        <Container colors={colors}>
          <div>
            <SuccessCheckSVG />
            <h1>{message}</h1>
          </div>
        </Container>
      </OuterContainer>
      <HideFooter />
    </>
  );
}
