import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import { Mobile, Default } from '~/config/ReactResponsiveConfig';
import { useDispatch } from 'react-redux';
import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';

import {
  Container,
  InputWrapper,
  EnvelopeHeart,
  InputValidation,
} from './styles';
import {
  fetchUserByEmail,
  fetchUserByIdDone,
} from '~/store/modules/user/userAction';
import { useSelector } from 'react-redux';
import CircularIndeterminate from '~/components/Loader';
import { getUserByEmail } from '~/api/userAPI';
import { OpenGift } from '../ContributionThanks/styles';
import { createAttendance } from '~/api/eventAPI';

export default function RSVPSkipOnly() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const [email, setEmail] = useState('');
  const [formError, setFormError] = useState(null);
  const [name, setName] = useState('');
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const eventname = queryParams.get('name');
  const [loader, setLoader] = useState(false);

  const getRspv = localStorage.getItem('rspv');
  const rspv_data = localStorage.getItem('rspv_data');
  const event_names = localStorage.getItem('event_name');

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name === '') {
      setFormError(`validation failed: name required!`);
      return 'validationFailed';
    }
    if (email === '') {
      setFormError(`validation failed: email required!`);
      return 'validationFailed';
    }
    if (!emailRegex.test(email)) {
      setFormError('Validation failed: enter a valid email address!');
      return 'validationFailed';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem('rspv');

    const error = validateForm();
    if (error !== 'validationFailed') {
      setLoader(true);
      await handleAttendance();
      await getUserByEmail(email)
        .then((res) => {
          if (res && res.data) {
            if (rspv_data && rspv_data === 'party_with_venue_attend') {
              history.push('/rsvp-closed-confirmed', {
                rspvAttend: 'contAttend',
              });
            } else if (
              rspv_data &&
              rspv_data === 'party_with_venue_not_attend'
            ) {
              history.push('/rsvp-closed-confirmed', {
                rspvAttend: 'contNotAttend',
              });
            } else if (rspv_data && rspv_data === 'party_with_venue_norspv') {
              history.push('/rsvp-closed-confirmed', {
                // rspvAttend: 'venue_norspv',
                rspvAttend: 'contAttend',
              });
            } else if (rspv_data && rspv_data === 'party_with_venue_novenue') {
              history.push('/rsvp-closed-confirmed', {
                rspvAttend: 'no_venue',
              });
            } else {
              history.push('/rsvp-confirmed', {
                email: email,
                name: name,
                checked: 'true',
              });
            }
            setLoader(false);
          } else {
            if (rspv_data && rspv_data === 'party_with_venue_attend') {
              history.push('/rsvp-closed-confirmed', {
                rspvAttend: 'contAttend',
              });
            } else if (
              rspv_data &&
              rspv_data === 'party_with_venue_not_attend'
            ) {
              history.push('/rsvp-closed-confirmed', {
                rspvAttend: 'contNotAttend',
              });
            } else if (rspv_data && rspv_data === 'party_with_venue_norspv') {
              history.push('/rsvp-closed-confirmed', {
                rspvAttend: 'venue_norspv',
              });
            } else if (rspv_data && rspv_data === 'party_with_venue_novenue') {
              history.push('/rsvp-closed-confirmed', {
                rspvAttend: 'no_venue',
              });
            } else {
              history.push('/rsvp-confirmed', {
                email: email,
                name: name,
                checked: 'false',
              });
            }
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const handleSubmitAttend = async () => {
    await handleAttendance();
    localStorage.removeItem('rspv');
    rspv_data && rspv_data === 'party_with_venue_norspv'
      ? history.push('/rsvp-closed-confirmed', {
          rspvAttend: 'venue_no_attending_rspv',
        })
      : history.push('/rsvp-closed-confirmed', { rspv: 'norspv' });
  };

  const handleAttendance = async () => {
    if (rspv_data && rspv_data === 'party_with_venue_attend') {
      const payload = {
        email: email,
        event_names: event_names,
        attend: 1,
        not_attend: 0,
      };
      await createAttendance(payload)
        .then((res) => {
          console.log('res', res);
        })
        .catch((err) => {
          console.log('@@@err', err);
        });
    } else if (rspv_data && rspv_data === 'party_with_venue_not_attend') {
      console.log('CONFIRM I am NOT ATTENDANCE');
      const payload = {
        email: email,
        event_names: event_names,
        attend: 0,
        not_attend: 1,
      };
      await createAttendance(payload)
        .then((res) => {
          console.log('res', res);
        })
        .catch((err) => {
          console.log('@@@err', err);
        });
    } else if (rspv_data && rspv_data === 'party_with_venue_norspv') {
      const payload = {
        email: email,
        event_names: event_names,
        attend: 0,
        not_attend: 1,
      };
      await createAttendance(payload)
        .then((res) => {
          console.log('res', res);
        })
        .catch((err) => {
          console.log('@@@err', err);
        });
    } else if (getRspv && getRspv === 'no') {
      const payload = {
        email: email,
        event_names: event_names,
        attend: 0,
        not_attend: 1,
      };
      await createAttendance(payload)
        .then((res) => {
          console.log('res', res);
        })
        .catch((err) => {
          console.log('@@@err', err);
        });
    }
  };

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 3000);

  return (
    <>
      <SubHeader backgroundColor={colors.lightPurple} mobileDescVisible={false}>
        <h2>
          <Mobile>RSVP</Mobile>
          {getRspv && getRspv === 'no' ? (
            <Default>
              WILL YOU ALSO BE ATTENDING{' '}
              {event_names !== '' ? event_names : 'PARTY'}?
            </Default>
          ) : rspv_data && rspv_data === 'party_with_venue_attend' ? (
            <Default>
              THANKS FOR To RSPV'ING TO{' '}
              {event_names !== '' ? event_names : 'PARTY'}?.
            </Default>
          ) : rspv_data && rspv_data === 'party_with_venue_not_attend' ? (
            <Default>
              THANKS FOR YOUR CONTRIBUTION TO{' '}
              {event_names !== '' ? event_names : 'GIFTS'}.
            </Default>
          ) : rspv_data && rspv_data === 'party_with_venue_norspv' ? (
            <Default>
              {' '}
              WILL YOU ALSO BE ATTENDING{' '}
              {event_names !== '' ? event_names : 'PARTY'}?.
            </Default>
          ) : rspv_data && rspv_data === 'party_with_venue_novenue' ? (
            <Default>
              {' '}
              THANKS FOR YOUR CONTRIBUTION TO{' '}
              {event_names !== '' ? event_names : 'GIFTS'}.
            </Default>
          ) : (
            <Default>
              THANKS FOR YOUR CONTRIBUTION TO{' '}
              {event_names !== '' ? event_names : 'GIFTS'}.
            </Default>
          )}
        </h2>
        <h3>
          Please enter your name and email so we can notify the event organiser.
        </h3>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        {!loader ? (
          <>
            {rspv_data && rspv_data === 'party_with_venue_novenue' ? (
              <OpenGift />
            ) : (
              <EnvelopeHeart />
            )}
            <Mobile>
              {getRspv && getRspv === 'no' ? (
                <h2>
                  WILL YOU ALSO BE ATTENDING{' '}
                  {event_names !== '' ? event_names : 'PARTY'}?
                </h2>
              ) : rspv_data && rspv_data === 'party_with_venue_attend' ? (
                <h2>
                  THANKS FOR To RSPV'ING TO{' '}
                  {event_names !== '' ? event_names : 'PARTY'}?.
                </h2>
              ) : rspv_data && rspv_data === 'party_with_venue_not_attend' ? (
                <h2>
                  THANKS FOR YOUR CONTRIBUTION TO{' '}
                  {event_names !== '' ? event_names : 'GIFTS'}.
                </h2>
              ) : rspv_data && rspv_data === 'party_with_venue_norspv' ? (
                <h2>
                  WILL YOU ALSO BE ATTENDING{' '}
                  {event_names !== '' ? event_names : 'PARTY'}?.
                </h2>
              ) : rspv_data && rspv_data === 'party_with_venue_novenue' ? (
                <h2>
                  THANKS FOR YOUR CONTRIBUTION TO{' '}
                  {event_names !== '' ? event_names : 'GIFTS'}.
                </h2>
              ) : (
                <h2>
                  Thanks for RSVP’ing to{' '}
                  {eventname && eventname
                    ? eventname
                    : event_names !== ''
                    ? event_names
                    : ''}
                  .
                </h2>
              )}
              {/* <h2>Thanks for RSVP’ing to {eventname && eventname}.</h2> */}
              <h3>
                Please enter your name and email so we can notify the event
                organiser.
              </h3>
            </Mobile>
            <form>
              <InputWrapper>
                <Input
                  label="Your Name"
                  name="name"
                  placeholder="name"
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  label="Your Email"
                  name="email"
                  placeholder="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputWrapper>

              {getRspv && getRspv === 'no' ? (
                <>
                  <Button
                    text="Done"
                    backgroundColor="#01e3fa"
                    buttonFunction={(e) => handleSubmit(e)}
                  />
                  <button
                    onClick={handleSubmitAttend}
                    style={{
                      background: 'none',
                      fontSize: '16px',
                      textTransform: 'none',
                      border: 'none',
                      color: 'rgb(19, 40, 127)',
                    }}
                  >
                    Sorry, I won't be able to attend.
                  </button>
                </>
              ) : (
                <>
                  <Button
                    text={
                      rspv_data && rspv_data === 'party_with_venue_attend'
                        ? 'CONFIRM MY ATTENDANCE'
                        : rspv_data &&
                          rspv_data === 'party_with_venue_not_attend'
                        ? "CONFIRM I'M NOT ATTENDANCE"
                        : rspv_data && rspv_data === 'party_with_venue_norspv'
                        ? 'I WILL BE ATTENDING'
                        : rspv_data && rspv_data === 'party_with_venue_novenue'
                        ? 'Done'
                        : 'Done'
                    }
                    backgroundColor="#01e3fa"
                    buttonFunction={(e) => handleSubmit(e)}
                  />
                  {rspv_data && rspv_data === 'party_with_venue_norspv' && (
                    <button
                      onClick={handleSubmitAttend}
                      style={{
                        background: 'none',
                        fontSize: '16px',
                        textTransform: 'none',
                        border: 'none',
                        color: 'rgb(19, 40, 127)',
                      }}
                    >
                      Sorry, I won't be able to attend.
                    </button>
                  )}
                </>
              )}
            </form>
          </>
        ) : (
          <CircularIndeterminate />
        )}
      </Container>
    </>
  );
}
