import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { IoIosLock } from 'react-icons/io';

import Context from '~/context';
import history from '~/services/history';

import Amex from '~/assets/images/card-flag-amex.svg';
import MasterCard from '~/assets/images/card-flag-mc.svg';
import Visa from '~/assets/images/card-flag-visa.svg';

import ModalScreen from './ModalScreen';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import CreditCardForm from '~/components/CreditCardForm';
import ModalWrapper from '~/components/ModalWrapper';

import { Container, SecureBankingBadge, InputColumn, CardsRow } from './styles';
import { useSelector } from 'react-redux';
import CreditForm from '~/components/CreditForm';

export default function AddCreditCard() {
  const { colors } = useContext(ThemeContext);
  const { showModal, handleShowModal } = useContext(Context);
  const [showSecure, setShowSecure] = useState(false);
  // const Dispatch = useDispatch();

  const userIdd = useSelector((state) => state.userReducer.profile.id);

  useEffect(() => {
    if (showSecure && !showModal) {
      setShowSecure(false);
    }
  }, [showModal, showSecure]);

  const handleShowSecureNotice = () => {
    setShowSecure(true);
    handleShowModal();
  };
  return (
    <>
      <SubHeader backgroundColor={colors.secondary}>
        <h2>Add Credit Card</h2>
        <h3>Add a credit card to contribute to gifts</h3>
      </SubHeader>
      <Container>
        <SecureBankingBadge onClick={handleShowSecureNotice}>
          <IoIosLock /> Secure Payment
        </SecureBankingBadge>
        <InputColumn>
          <form>
            <CreditForm />
          </form>

          <CardsRow>
            <img src={MasterCard} alt="MasterCard" />
            <img src={Visa} alt="Visa" />
            <img src={Amex} alt="American Express" />
          </CardsRow>
        </InputColumn>
      </Container>
      <ModalWrapper closeColor={colors.primary}>
        {showSecure && <ModalScreen />}
      </ModalWrapper>
    </>
  );
}
