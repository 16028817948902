import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IoIosMenu } from 'react-icons/io';
import { Link } from 'react-router-dom';

import history from '~/services/history';
import Context from '~/context';

import Notifications from '~/components/Notifications';
import Avatar from '~/components/Avatar';

import { Logo, Container, Profile, Menu, ProfileOptions } from './styles';

import logoImage from '~/assets/images/purposit-logo.png';

export default function Header() {
  const { handleShowMenu, resetFlow } = useContext(Context);
  const profile = useSelector((state) => state.userReducer.profile);
  const [visible, setVisible] = useState(false);
  const handleResetFlow = useCallback(() => resetFlow, [resetFlow]);

  useEffect(() => {
    handleResetFlow();
  }, [handleResetFlow]);

  return (
    <Container loggedIn={!!profile}>
      <Logo onClick={() => history.push('/my-profile')}>
        <img src={logoImage} alt="Purposit" />
      </Logo>
      {profile && (
        <aside>
          <Notifications />
          <Profile
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
            visible={visible}
          >
            <div
              role="presentation"
              onClick={() => {
                setVisible(false);
                history.push('/my-profile');
              }}
            >
              <Avatar image={profile.user_image} size={55} />
              <strong>{profile.first_name}</strong>
            </div>
            <ProfileOptions>
              <Link to="/edit-profile" onClick={() => setVisible(false)}>
                Edit Profile
              </Link>
              <Link to="/payment-details" onClick={() => setVisible(false)}>
                Payment Details
              </Link>
            </ProfileOptions>
          </Profile>
          <Menu onClick={handleShowMenu}>
            <IoIosMenu size={40} color="#999" />
          </Menu>
        </aside>
      )}
    </Container>
  );
}
