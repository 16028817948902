import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { darken } from 'polished';
import giftImage from '../../assets/images/gifts.svg';

import Context from '~/context';
import history from '~/services/history';

import GiftSaved from './GiftSaved';
// Action
import { fetchReceiverById } from '../../store/modules/receiver/receiverAction';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

// import GiftImage from '~/assets/images/bike-gift.jpg';
import { Container } from './styles';

// import gifts from './gifts';

export default function AddedGiftsList() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { flow } = useContext(Context);
  const [giftSaved, setGiftSaved] = useState(false);
  const receiverId = useSelector((state) => state.receiverReducer.receiver.id);
  const gifts = useSelector((state) => state.receiverReducer.gifts);
  const checkedOnboarding = JSON.parse(localStorage.getItem('flow'));

  const handleSubmit = () => {
    if (checkedOnboarding.onboarding === false) {
      setGiftSaved(true);
    } else {
      flow.onboarding
        ? history.push('/onboarding-success/2')
        : setGiftSaved(true);
    }
  };

  const handleAddGift = () => {
    history.push('/add-gift');
  };
  useEffect(() => {
    Dispatch(fetchReceiverById(receiverId));
    if (receiverId) {
      setTimeout(() => {
        Dispatch(fetchReceiverById(receiverId));
      }, 1000);
    }
  }, [Dispatch, receiverId]);

  return (
    <>
      {giftSaved ? (
        <GiftSaved />
      ) : (
        <>
          <SubHeader
            backgroundColor={colors.orange}
            buttonBackgroundColor={darken(0.1, colors.orange)}
            buttonText="Next"
            buttonTextColor="#ffffff"
            buttonFunction={() => handleSubmit()}
            mobileDescVisible={false}
            hideBackButton
          >
            <h2>GIFTS CREATED</h2>
          </SubHeader>
          <Container>
            {gifts &&
              gifts.map((gift) => (
                <div className="gift-card">
                  <img
                    src={
                      gift.gift_images.length > 0 &&
                      gift.gift_images[0].url !== null
                        ? gift.gift_images[0].url
                        : giftImage
                    }
                    alt="{gift.name}"
                  />
                  <div>
                    <div>
                      <div>
                        <h3>{gift.name}</h3>
                        <small>{gift.timeframe}</small>
                      </div>
                      <div>
                        <span className="gift-price">${gift.price}</span>
                      </div>
                    </div>
                    <div>
                      <p>{gift.desc}</p>
                    </div>
                  </div>
                </div>
              ))}

            <Button
              text="Add New Gift"
              textColor={colors.purple}
              backgroundColor="#ffffff"
              buttonFunction={() => handleAddGift()}
            />
          </Container>
        </>
      )}
    </>
  );
}
