import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.button`
  ${({ backgroundColor, textColor, theme }) => css`
    background: #cccccc;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    color: #666666;
    width: 100%;
    max-width: 179px;
    // cursor: pointer;
    transition: background 0.2s;
    text-align: center;
    border: 0;
    max-height: 51px;
    min-height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    > span {
      line-height: 0;
    }

    @media (max-width: 767px) {
      background: none;
      text-transform: capitalize;
      color: #fff;
      font-weight: normal;
    }

    @media (min-width: 979px) {
      min-width: 175px;
    }

    &:hover {
      background: ${darken(0.05, backgroundColor) ||
      darken(0.05, theme.colors.primary)};
    }

    svg {
      font-size: 52px;
    }
  `}
`;
