import { apiAxiosInstance } from '../services/axios';
import { getJWTToken } from '../utils/utility';

const tokenData = JSON.parse(getJWTToken());
const headers = {};
if (tokenData && tokenData.token) {
  headers.Authorization = `Bearer ${tokenData.token}`;
}

export const getAddBankAccount = async (receiverId, data) => {
  try {
    const result = await apiAxiosInstance.post(
      `receiver/${receiverId}/account`,
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getBankList = async (receiverId) => {
  try {
    const result = await apiAxiosInstance.get(
      `/receiver/${receiverId}/account`,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getBankDetailsById = async (receiverId, accountId) => {
  try {
    const result = await apiAxiosInstance.get(
      `/receiver/${receiverId}/account/${accountId}`,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getUpdateBankAccount = async (receiverId, data) => {
  try {
    const result = await apiAxiosInstance.put(
      `/receiver/${receiverId}/account/${receiverId}`,
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getCardDetailsByUserId = async (userId) => {
  try {
    const result = await apiAxiosInstance.get(`/user/${userId}/payment/card`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const getAddCreditCard = async (receiverId, data) => {
  try {
    const result = await apiAxiosInstance.post(
      `/user/${receiverId}/payment/card`,
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};
