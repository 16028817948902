import styled from 'styled-components';

import { ReactComponent as OpenGiftSVG } from '~/assets/images/open-gift.svg';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  padding: 25px 40px;
  align-items: center;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);
  }

  img {
    max-width: 120px;
    margin: 10px auto 30px;
  }

  button {
    margin-bottom: 30px;
    max-width: 330px;
  }

  form {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    label {
      font-size: 15px;
      margin-bottom: 10px;
      color: #888;
      font-weight: bold;
      text-transform: uppercase;
    }

    textarea {
      font-size: 18px;
      margin-top: 10px;
      max-width: 330px;
      width: 100%;
      height: 40px;
      padding: 10px 15px;
      border: 0;
      border-radius: 0;

      &::placeholder {
        color: #b7b7b7;
      }
    }

    textarea {
      min-height: 126px;
      font-size: 15px;
      line-height: 20px;
    }
  }

  h2 {
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 10px;
  }

  h3 {
    text-align: center;
    color: #656565;
    margin-bottom: 20px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }
`;

export const OpenGift = styled(OpenGiftSVG)`
  max-width: 96px;
  max-height: 120px;

  #contents > * {
    opacity: 0;
    animation: appear 4s ease-in-out infinite;
    animation-delay: 0.6s;

    &:nth-child(1) {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.5s;
    }
    &:nth-child(5) {
      animation-delay: 0.6s;
    }
    &:nth-child(6) {
      animation-delay: 0.7s;
    }
    &:nth-child(7) {
      animation-delay: 0.8s;
    }
    &:nth-child(8) {
      animation-delay: 0.9s;
    }
    &:nth-child(9) {
      animation-delay: 1s;
    }
    &:nth-child(10) {
      animation-delay: 1.1s;
    }
  }

  @keyframes appear {
    0% {
      transform: translate(0, 45%);
      opacity: 1;
    }
    80% {
      opacity: 1;
    }

    100% {
      transform: translate(0, -5%);
      opacity: 0;
    }
  }
`;
