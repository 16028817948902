import React from 'react';
import { FaPlus } from 'react-icons/fa';

import { Container } from './styles';

export default function UploadFile({ size = 15, onChange }) {
  return (
    <Container size={size}>
      <input
        type="file"
        name="file[]"
        id="file"
        className="inputfile"
        data-multiple-caption="{count} files selected"
        multiple=""
        onChange={(e) => onChange(e)}
      />
      <label htmlFor="file">
        <span>
          <FaPlus size={15} />
        </span>
      </label>
    </Container>
  );
}
