import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .arrow {
    display: none;
  }

  div:not(:first-of-type) > div {
    background: #f1f1f1;
    > span {
      font-weight: normal;
    }

    .arrow {
      display: block;
    }
  }
`;

export const SettingBar = styled.div`
  background: #fff;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccd7db;
  min-height: 59px;

  &.pointer {
    cursor: pointer;
  }

  > span {
    font-size: 17px;
    font-weight: bold;
    color: #232627;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #dcdbdd;
  }

  > svg {
    transition: 0.5s all;
  }

  .open {
    transform: rotate(-180deg);
  }
`;
