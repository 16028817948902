import { apiAxiosInstance } from '../services/axios';
import { getJWTToken } from '../utils/utility';

const tokenData = JSON.parse(getJWTToken());
const headers = {};
if (tokenData && tokenData.token) {
  headers.Authorization = `Bearer ${tokenData.token}`;
}

export const getAddIndividualReceiver = async (data) => {
  try {
    const result = await apiAxiosInstance.post('receiver', data, { headers });
    return result;
  } catch (err) {
    return err;
  }
};
export const getAddMultipleReceiver = async (data) => {
  try {
    const result = await apiAxiosInstance.post('receiver', data, { headers });
    return result;
  } catch (err) {
    return err;
  }
};
export const getReceiverById = async (receiverId) => {
  try {
    const result = await apiAxiosInstance.get(`receiver/${receiverId}`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const getArchivedEventById = async (receiverId) => {
  try {
    const result = await apiAxiosInstance.get(
      `receiver/${receiverId}/archive`,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getUpdateReceiver = async (receiverId, data) => {
  try {
    const result = await apiAxiosInstance.put(`receiver/${receiverId}`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const getDeleteReceiver = async (receiverId) => {
  try {
    const result = await apiAxiosInstance.delete(`receiver/${receiverId}`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const getReceiverByUserId = async (userid) => {
  try {
    const result = await apiAxiosInstance.get(`receiver/${userid}/byuserid`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const createArchiveProfile = async (data) => {
  try {
    const result = await apiAxiosInstance.post(
      `receiver/${data.receiver_id}/archive_profile`,
      data,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getArchivedReceiverById = async (receiverId) => {
  try {
    const result = await apiAxiosInstance.get(
      `receiver/${receiverId}/get_archive_profile`,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};
export const createReceiverArchive = async (data) => {
  try {
    const result = await apiAxiosInstance.post(
      `receiver/${data.child_id}/child_archive_profile`,
      data,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};