import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import history from '~/services/history';
import { Mobile } from '~/config/ReactResponsiveConfig';
// Action
import {
  updateReceiver,
  uploadImageAction,
} from '~/store/modules/receiver/receiverAction';
import SubHeader from '~/components/SubHeader';
import DatePicker from '../../../components/DatePicker';
import AvatarUpload from '~/components/AvatarUpload';
import Input from '~/components/Input';
import Button from '~/components/Button';

import {
  Container,
  InputRow,
  InputWrapper,
  RadioControl,
  RadioButton,
  SelectControl,
} from './styles';

export default function EditIndividual() {
  const Dispatch = useDispatch();
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const { colors } = useContext(ThemeContext);
  const [relation, setRelation] = useState(receiver.relation);
  const [firstName, setFirstName] = useState(receiver.first_name);
  const [lastName, setLastName] = useState(receiver.last_name);
  const [date, setDate] = useState(receiver.dob);
  const [location, setLocation] = useState(receiver.location);
  const { receiverId } = useParams();
  const [gender, setGender] = useState(receiver.gender);
  // const receiver = people.find(x => x.id === parseInt(receiverId, 10));
  const isReceiverUpdate = useSelector(
    (state) => state.receiverReducer.isReceiverUpdate,
  );
  const profile = useSelector((state) => state.userReducer.profile);

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleDateChange = (newDate) => {
    setDate(newDate);
  };
  const handleRelationChange = (event) => {
    setRelation(event.target.value);
  };

  const handleUploadImage = (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    Dispatch(uploadImageAction(receiver.id, formData));
  };

  const handleSubmit = () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      dob: date,
      location,
      gender,
      relation,
      user_id: profile.id,
    };
    Dispatch(updateReceiver(receiverId, data));
  };
  if (isReceiverUpdate) {
    history.push({
      pathname: '/success',
      state: {
        destination: `/receiver-profile/${receiverId}`,
        message: 'Profile Saved!',
        colors: { start: colors.lightPurple, end: colors.purple },
      },
    });
  }

  return (
    <>
      <SubHeader
        buttonBackgroundColor="#01e3fa"
        buttonText="Save"
        buttonTextColor="#142484"
        buttonFunction={() => handleSubmit()}
        mobileDescVisible={false}
      >
        <h2>EDIT RECEIVER DETAILS</h2>
      </SubHeader>
      <Container>
        <form>
          <AvatarUpload
            image={receiver.child_image}
            gender={receiver.gender}
            onChange={handleUploadImage}
          />
          <InputRow>
            <InputWrapper>
              <Input
                label="First Name"
                name="first_name"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                label="Surname"
                name="last_name"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </InputWrapper>
          </InputRow>
          <InputRow>
            <InputWrapper>
              {/* <Input
                label="Date of Birth"
                name="dob"
                value= {moment(receiver.data.dob).format('MM/DD/YYYY')}
                onChange ={(event)=> setDate(event.target.value)}
              /> */}
              <DatePicker
                label="Date of Birth"
                name="dob"
                date={
                  date === ''
                    ? moment(receiver.dob).format('MM/DD/YYYY')
                    : moment(date).format('MM/DD/YYYY')
                }
                onChange={handleDateChange}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                label="City Where He or She Lives"
                name="location"
                value={location}
                onChange={(event) => setLocation(event.target.value)}
              />
            </InputWrapper>
          </InputRow>
          <RadioControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={gender}
              onChange={handleGenderChange}
            >
              <FormControlLabel
                value="F"
                control={<RadioButton />}
                label="Female"
              />
              <FormControlLabel
                value="M"
                control={<RadioButton />}
                label="Male"
              />
              <FormControlLabel
                value="O"
                control={<RadioButton />}
                label="Other"
              />
            </RadioGroup>
          </RadioControl>
          <SelectControl variant="filled">
            <InputLabel id="relation-label" disableAnimation shrink={false}>
              I am {firstName}'s {relation}
            </InputLabel>
            <Select
              labelId="relation-label"
              id="relation"
              value={relation}
              onChange={handleRelationChange}
            >
              <MenuItem value="Other">Other</MenuItem>
              <MenuItem value="Father">Father</MenuItem>
              <MenuItem value="Mother">Mother</MenuItem>
              <MenuItem value="Brother">Brother</MenuItem>
              <MenuItem value="Sister">Sister</MenuItem>
              <MenuItem value="Uncle">Uncle</MenuItem>
              <MenuItem value="Aunt">Aunt</MenuItem>
            </Select>
          </SelectControl>
        </form>
        <Mobile>
          <Button
            text="Save"
            backgroundColor="#01e3fa"
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
    </>
  );
}
