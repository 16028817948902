export function getNotificationByUserId(userId) {
  return {
    type: 'GET_NOTIFICATION_BY_USER_ID',
    userId,
  };
}
export function getNotificationByUserIdDone(data) {
  return {
    type: 'GET_NOTIFICATION_BY_USER_ID_DONE',
    data,
  };
}
export function fetchThankuCard(userId) {
  return {
    type: 'FETCH_THANKU_CARD',
    userId,
  };
}

export function fetchThankuCardDone(data) {
  return {
    type: 'FETCH_THANKU_CARD_DONE',
    data,
  };
}


export function fetchThankuCardPending(userId) {
  return {
    type: 'FETCH_THANKU_CARD_PENDING',
    userId,
  };
}

export function fetchThankuCardDonePending(data) {
  return {
    type: 'FETCH_THANKU_CARD_DONE_PENDING',
    data,
  };
}





export function setNotificationData(data) {
  return {
    type: 'FETCH_GIFT_CARD_DONE',
    data,
  };
}
