import React from 'react';
import PropTypes from 'prop-types';

import Input from '~/components/Input';

import { InputWrapper, InputRow, DatePickerStyled } from './styles';

export default function FirstForm({ formData }) {
  const {
    date,
    setDate,
    firstName,
    setFirstName,
    surname,
    setSurname,
    bsb,
    setBSB,
    accountNumber,
    setAccountNumber,
    confirmAccountNumber,
    setConfirmAccountNumber,
  } = formData;
  const maxDate = new Date();

  const formatSuburb = (value) => {
    return value.replace(/(\d{3})(\d{3})/, '$1-$2');
  };

  const handlebsb = (value) => {
    const inputSuburb = value;
    const formattedSuburb = formatSuburb(inputSuburb);
    setBSB(formattedSuburb);
  };
  return (
    <InputRow>
      <InputWrapper>
        <Input
          label="First Name"
          name="first-name"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label="Last Name"
          name="surname"
          placeholder="Surname"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <DatePickerStyled
          name="date-of-birth"
          label="Date of Birth"
          date={date}
          onChange={(newDate) => setDate(newDate)}
          maxDate={maxDate.setFullYear(maxDate.getFullYear() - 18)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label="BSB"
          placeholder="000-000"
          name="BSB"
          value={bsb}
          onChange={(e) => handlebsb(e.target.value)}
          maxLength={7}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label="Account Number"
          placeholder="000123456"
          name="account-number"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          maxLength={9}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label="Confirm Account Number"
          placeholder="000123456"
          name="confirm-account-number"
          value={confirmAccountNumber}
          onChange={(e) => setConfirmAccountNumber(e.target.value)}
          maxLength={9}
        />
      </InputWrapper>
    </InputRow>
  );
}

FirstForm.propTypes = {
  formData: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    handleDateChange: PropTypes.func,
    firstName: PropTypes.string,
    setFirstName: PropTypes.func,
    surname: PropTypes.string,
    setSurname: PropTypes.func,
    bsb: PropTypes.string,
    setBSB: PropTypes.func,
    accountNumber: PropTypes.string,
    setAccountNumber: PropTypes.func,
    confirmAccountNumber: PropTypes.string,
    setConfirmAccountNumber: PropTypes.func,
  }).isRequired,
};
