import React, { useContext } from 'react';

import history from '~/services/history';

import Context from '~/context';

export default function Options() {
  const { handleShowModal } = useContext(Context);

  const handleGoToPage = (page) => {
    handleShowModal();
    history.push(page);
  };

  return (
    <>
      <button type="submit" onClick={() => handleGoToPage('/email-details')}>
        SEND EMAIL
      </button>
      <button type="submit">SHARE VIA SOCIAL MEDIA</button>
      <button type="submit">COPY LINK</button>
      <button type="submit">DOWNLOAD QR CODE</button>
      <button type="submit" onClick={() => handleShowModal()}>
        Cancel
      </button>
    </>
  );
}
