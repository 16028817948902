import contacts from '~/context/contacts';

import Bike from '~/assets/images/bike-gift.jpg';
import Golf from '~/assets/images/golf-clubs.jpg';

const gifts = [
  {
    id: 1,
    image: Bike,
    name: 'Mountain Bike',
    date: 'by 10th Birthday',
    price: 720,
    raised: 620,
    eventExists: true,
    desc: 'Lorem ipsum dolor sit amet, consectetuer and then some adipiscing elit, sed...',
    contributions: contacts,
  },
  {
    id: 2,
    image: Golf,
    name: 'Skateboard',
    date: 'by 12th Birthday',
    price: 200,
    raised: 210,
    eventExists: false,
    desc: 'Lorem ipsum dolor sit amet, consectetuer and then some adipiscing elit, sed...',
    contributions: contacts,
  },
  {
    id: 3,
    image: Bike,
    name: 'Computer',
    date: 'by 15th Birthday',
    price: 2000,
    raised: 200,
    eventExists: true,
    desc: 'Lorem ipsum dolor sit amet, consectetuer and then some adipiscing elit, sed...',
    contributions: [],
  },
];

export default gifts;
