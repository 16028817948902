import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { IoIosMail, IoIosLock } from 'react-icons/io';
import history from '~/services/history';
import SocialLogin from '~/components/SocialLogin';
import { toast } from 'react-toastify';

import {
  TopWrapper,
  InputWrapper,
  ContainerFooter,
} from '~/pages/_layout/auth/styles';
import { updatePassword } from '~/api/userAPI';

const schema = Yup.object().shape({
  password: Yup.string()
    .required('Please, insert your password.')
    .min(6, 'Password must be at least 6 characters long.')
    .test('password-filled', 'Please, insert your password.', function (value) {
      return value !== undefined && value.trim() !== '';
    }),
  confirmpassword: Yup.string()
    .required('Please, insert your confirm password.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default function ResetPassword() {
  const {
    loading,
    signed,
    email: LoginEmail,
  } = useSelector((state) => state.authReducer);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams && searchParams.get('token');
  const [loader, setLoader] = useState(false);
  const [resetData, setReserData] = useState('');

  const handleSubmit = async (data) => {
    setLoader(true);
    const payload = {
      token: token,
      password: data.password,
    };
    await updatePassword(payload)
      .then((res) => {
        if (res && res.message === 'Token has expired') {
          toast.error('Token has expired');
          setLoader(false);
        } else if (res && res.message === 'Password Changed Successfully') {
          toast.success('Password Update Successfully');
          setTimeout(() => {
            history.push('/');
          }, 1000);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log('err', err);
      });
  };

  return (
    <>
      <TopWrapper>
        <h2>Create New Password</h2>
        <Form schema={schema} onSubmit={handleSubmit}>
          <InputWrapper>
            <IoIosLock size={25} color="#a6cce2" />
            <Input name="password" type="password" placeholder="Password" />
          </InputWrapper>
          <InputWrapper>
            <IoIosLock size={25} color="#a6cce2" />
            <Input
              name="confirmpassword"
              type="password"
              placeholder="Confirm Password"
            />
          </InputWrapper>

          <button type="submit">{loader ? 'Loading...' : 'Update'}</button>
        </Form>
        {/* <SocialLogin /> */}
      </TopWrapper>
    </>
  );
}
