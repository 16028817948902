import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { IoIosClose } from 'react-icons/io';

import Context from '~/context';

import { Container, Modal, CloseWrapper } from './styles';

export default function ModalWrapper({ closeColor, children }) {
  const { showModal, handleShowModal } = useContext(Context);
  const handleClose=()=>{
    localStorage.setItem('close', 'open');
    handleShowModal()
    }
  return (
    showModal && (
      <>
        <Modal className="visible" onClick={handleShowModal} />
        <Container>{children}</Container>
        <CloseWrapper>
          <IoIosClose onClick={handleClose} color={closeColor} size={50} />
        </CloseWrapper>
      </>
    )
  );
}

ModalWrapper.propTypes = {
  closeColor: PropTypes.string,
};

ModalWrapper.defaultProps = {
  closeColor: '#fff',
};
