import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 25px;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    padding: 25px 20px;
    margin: 0;
    min-height: calc(100% - 135px);
    justify-content: initial;

    button {
      margin-bottom: 30px;
    }
  }

  button {
    min-width: 100%;
  }

  svg {
    cursor: pointer;
  }

  h3 {
    color: #888;
  }
`;

export const Card = styled.div`
  background: white;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  width: 100%;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #888;

  @media (max-width: 767px) {
    font-size: 17px;
  }

  > div {
    width: 50%;
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;

    &:first-child {
      width: 40%;
      max-width: 40%;
    }

    &:last-child {
      width: 10%;
      max-width: 10%;
      display: flex;
      justify-content: flex-end;
    }
  }

  img {
    width: 55px;
    height: 55px;
    min-width: 55px;
    border-radius: 28px;
    object-fit: cover;
    object-position: center;
    margin-right: 20px;

    @media (max-width: 767px) {
      margin-right: 10px;
    }
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input,
  textarea {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &.currency-input {
      width: 90%;
    }

    &::placeholder {
      color: #b7b7b7;
    }
  }

  textarea {
    min-height: 126px;
    font-size: 15px;
    line-height: 20px;
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;

  &:last-of-type {
    margin-bottom: 0;
    max-height: 340px;
    overflow: scroll;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;
