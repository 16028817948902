import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import Context from '~/context';
import history from '~/services/history';

import { ReactComponent as PartyInvite } from '~/assets/images/envelope-icon.svg';
import { ReactComponent as WebsiteIcon } from '~/assets/images/website-icon.svg';
import { ReactComponent as GiftIcon } from '~/assets/images/gift-icon.svg';

import SubHeader from '~/components/SubHeader';
import ModalWrapper from '~/components/ModalWrapper';
import FindOutMoreModal from './FindOutMoreModal';

import { Container, BigButton } from './styles';
import { useLocation } from 'react-router-dom';

export default function EventGoal() {
  const location = useLocation();
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const receiverName = useSelector((state) => state.receiverReducer.receiver);
  const getData = location && location.state;

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
      >
        {getData && getData.birthday === 'birthday' ? (
          <>
            <h2>BIRTHDAY INVITE OPTIONS</h2>
            <h3>Select the type of event invitation you want to create:</h3>
          </>
        ) : getData && getData.inviteEvent === 'inviteEvent' ? (
          <>
            <h2>EVENT INVITE OPTIONS</h2>
            <h3>Select the type of event invitation you want to create:</h3>
          </>
        ) : (
          <>
            <h2>Invite Options</h2>
            <h3>Invite friends to contribute to gift(s) by sending them:</h3>
          </>
        )}
      </SubHeader>
      <Container>
        {(receiverName && receiverName.group_name === null) ||
        (getData && getData.receiver.group_name) === null ? (
          <>
            <BigButton
              onClick={() =>
                history.push('/add-event', {
                  user: 'single',
                  type: 'party',
                  receiver: getData && getData.receiver,
                })
              }
            >
              <div className="image-wrapper">
                <PartyInvite alt="Party Invite" />
              </div>
              <div className="text-content">
                <h2>A PARTY invitation with one or more gifts</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>

            <BigButton
              onClick={() =>
                history.push('/add-event', {
                  user: 'single',
                  type: 'gift',
                  receiver: getData && getData.receiver,
                })
              }
              backgroundColor={colors.lightPurple}
            >
              <div className="image-wrapper">
                <GiftIcon alt="Gift Icon" />
              </div>
              <div className="text-content">
                <h2>An invitation with one special gift </h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>
            <BigButton
              backgroundColor={colors.wine}
              onClick={() => history.push('/edit-page')}
            >
              <div className="image-wrapper">
                <WebsiteIcon alt="A webpage" />
              </div>
              <div className="text-content">
                <h2>An invitation with a list&nbsp;of gifts</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>
          </>
        ) : (
          <>
            <BigButton
              onClick={() =>
                history.push('/add-event', {
                  user: 'multiple',
                  type: 'party',
                  receiver: getData && getData.receiver,
                })
              }
            >
              <div className="image-wrapper">
                <PartyInvite alt="Party Invite" />
              </div>
              <div className="text-content">
                <h2>A PARTY invitation with one or more gifts</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>

            <BigButton
              onClick={() =>
                history.push('/add-event', {
                  user: 'multiple',
                  type: 'gift',
                  receiver: getData && getData.receiver,
                })
              }
              backgroundColor={colors.lightPurple}
            >
              <div className="image-wrapper">
                <GiftIcon alt="Gift Icon" />
              </div>
              <div className="text-content">
                <h2>An invitation with one special gift </h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>
            <BigButton
              backgroundColor={colors.wine}
              onClick={() => history.push('/edit-page')}
            >
              <div className="image-wrapper">
                <WebsiteIcon alt="A webpage" />
              </div>
              <div className="text-content">
                <h2>An invitation with a list&nbsp;of gifts</h2>
              </div>
              <IoIosArrowForward size={25} />
            </BigButton>
          </>
        )}

        <span role="presentation" onClick={handleShowModal}>
          Find out more...
        </span>
      </Container>
      <ModalWrapper>
        <FindOutMoreModal />
      </ModalWrapper>
    </>
  );
}
