import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { IoIosLock } from 'react-icons/io';
import { useLocation, useParams } from 'react-router-dom';

import { Mobile } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';
import Context from '~/context';
// Action
import { addBankAccount } from '~/store/modules/bankAccount/bankAccountAction';

import FirstForm from './FirstForm';
import SecondForm from './SecondForm';
import ModalScreen from './ModalScreen';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import ModalWrapper from '~/components/ModalWrapper';

import { Container, SecureBankingBadge, InputValidation } from './styles';

export default function LinkBank() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { handleShowModal, flow } = useContext(Context);
  const { path } = useParams();
  const { state } = useLocation();
  const origin = state ? state.origin : false;
  const receiverReducer = useSelector((state) => state.receiverReducer);
  const { receiverId } = receiverReducer;
  const userProfile = useSelector((state) => state.userReducer.profile);
  const { id } = userProfile;
  const maxDate = new Date();
  const [date, setDate] = useState(
    maxDate.setFullYear(maxDate.getFullYear() - 18),
  );
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [bsb, setBSB] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [suburb, setSuburb] = useState('');
  const [giftImageFile, setGiftImageFile] = useState(null);
  const [giftImageFileback, setGiftImageFileBack] = useState(null);
  const [frontImageFile, setFrontImageFile] = useState(null);
  const [backImageFile, setBackImageFile] = useState(null);
  const [postCode, setPostCode] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [states, setStates] = useState('');
  const [formError, setFormError] = useState(null);
  const [formError2, setFormError2] = useState(null);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const getFollow = localStorage.getItem('follow');

  const bankAccountReducer = useSelector((state) => state.bankAccountReducer);
  const { isCreateBankAccount } = bankAccountReducer;
  const formData = {
    date,
    setDate,
    firstName,
    setFirstName,
    surname,
    setSurname,
    bsb,
    setBSB,
    giftImageFile,
    setGiftImageFile,
    giftImageFileback,
    setGiftImageFileBack,
    accountNumber,
    setAccountNumber,
    confirmAccountNumber,
    setConfirmAccountNumber,
    streetAddress,
    suburb,
    postCode,
    setStreetAddress,
    setSuburb,
    setPostCode,
    frontImageFile,
    backImageFile,
    setFrontImageFile,
    setBackImageFile,
    phone,
    setPhone,
    city,
    setCity,
    states,
    setStates,
  };
  const bsbWithoutHyphen = bsb.replace(/-/g, '');

  const isNumber = (str) => /^[0-9]+$/.test(str);
  const isCharacter = (str) => /^[a-zA-Z]+$/.test(str);
  const isValidInput = (str) => isNumber(str) || isCharacter(str);

  const validateForm = () => {
    if (firstName === '') {
      setFormError(`validation failed: first name required!`);
      return 'validationFailed';
    }
    if (surname === '') {
      setFormError(`validation failed: last name required!`);
      return 'validationFailed';
    }
    if (bsbWithoutHyphen === '') {
      setFormError('Validation failed: BSB required!');
      return 'validationFailed';
    }
    if (isCharacter(bsbWithoutHyphen)) {
      setFormError('Validation failed: BSB must be number!');
      return 'validationFailed';
    }
    if (!isValidInput(bsbWithoutHyphen)) {
      setFormError('Validation failed: enter valid bsb number!');
      return 'validationFailed';
    }
    const cleanedAccountNumber = accountNumber.trim();
    const cleanedConfirmAccountNumber = confirmAccountNumber.trim();

    if (cleanedAccountNumber === '') {
      setFormError('Validation failed: account number are required!');
      return 'validationFailed';
    }
    if (!isValidInput(cleanedAccountNumber)) {
      setFormError('Validation failed: enter valid account number!');
      return 'validationFailed';
    }
    if (isCharacter(cleanedAccountNumber)) {
      setFormError('Validation failed: account number must be a  number!');
      return 'validationFailed';
    }
    if (cleanedConfirmAccountNumber === '') {
      setFormError('Validation failed: confirm account number are required!');
      return 'validationFailed';
    }
    if (!isValidInput(cleanedConfirmAccountNumber)) {
      setFormError('Validation failed: enter valid confirm account number!');
      return 'validationFailed';
    }
    if (isCharacter(cleanedConfirmAccountNumber)) {
      setFormError(
        'Validation failed: confirm account number must be a  number!',
      );
      return 'validationFailed';
    }
    // Check if both fields match
    if (cleanedAccountNumber !== cleanedConfirmAccountNumber) {
      setFormError('Validation failed: Confirm account numbers do not match!');
      return 'validationFailed';
    }
  };

  const validateForm2 = () => {
    if (streetAddress === '') {
      setFormError2(`validation failed: address required!`);
      return 'validationFailed';
    }
    if (phone === '') {
      setFormError2(`validation failed: phone number required!`);
      return 'validationFailed';
    }
    if (!isValidInput(phone)) {
      setFormError('Validation failed: enter valid phone number!');
      return 'validationFailed';
    }
    if (isCharacter(phone)) {
      setFormError('Validation failed: phone number must be a number!');
      return 'validationFailed';
    }
    if (suburb === '') {
      setFormError2(`validation failed: suburb required!`);
      return 'validationFailed';
    }
    if (city === '') {
      setFormError2(`validation failed: city required!`);
      return 'validationFailed';
    }
    if (states === '') {
      setFormError2(`validation failed: state required!`);
      return 'validationFailed';
    }
    if (isNumber(states)) {
      setFormError('Validation failed: enter valid state name!');
      return 'validationFailed';
    }
    if (postCode === '') {
      setFormError2(`validation failed: postCode required!`);
      return 'validationFailed';
    }
    if (!isValidInput(postCode)) {
      setFormError('Validation failed: enter valid postCode!');
      return 'validationFailed';
    }
    if (isCharacter(postCode)) {
      setFormError('Validation failed: postCode must be a number!');
      return 'validationFailed';
    }
    if (giftImageFile === null) {
      setFormError2(`validation failed: front side image required!`);
      return 'validationFailed';
    }
    if (giftImageFileback === null) {
      setFormError2(`validation failed: back side image required!`);
      return 'validationFailed';
    }
  };
  const handleSubmit = () => {
    const bsbWithoutHyphen = bsb.replace(/-/g, '');
    const error = validateForm();
    if (path === 'step-2') {
      const step2formError = validateForm2();
      if (step2formError !== 'validationFailed') {
        const data = {
          first_name: firstName,
          last_name: surname,
          dob: date,
          bsb: bsbWithoutHyphen,
          account_number: accountNumber,
          address: streetAddress,
          suburb,
          postcode: postCode,
          user_id: id,
          email: userProfile.email,
          phone: phone,
          city: city,
          state: states,
          giftImageFile: frontImageFile,
          giftImageFileback: backImageFile,
        };
        Dispatch(addBankAccount(id, data));
        if (isCreateBankAccount === null) {
          setSubmitButtonLoading(true);
        } else if (isCreateBankAccount === true) {
          setSubmitButtonLoading(false);
        }
      }
    }

    if (error !== 'validationFailed') {
      if (path !== 'step-2') {
        history.push('/link-bank/step-2');
      }
    }
  };

  if (isCreateBankAccount) {
    if (flow.giftOption) {
      history.push('/onboarding-completed');
    } else {
      const destination =
        origin && origin === 'change-bank-account'
          ? '/delete-bank-account'
          : getFollow !== null
          ? {
              pathname: '/success',
              state: {
                destination: `/card_payment`,
                message: 'Account Linked!',
                colors: { start: colors.lightPurple, end: colors.purple },
                userData: state,
              },
            }
          : {
              pathname: '/success',
              state: {
                destination: `/payment-details`,
                message: 'Account Linked!',
                colors: { start: colors.lightPurple, end: colors.purple },
                userData: state,
              },
            };
      history.push(destination);
    }
  }

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
    if (formError2 !== null) {
      setFormError2(null);
    }
  }, 3000);

  return (
    <>
      <SubHeader
        backgroundColor={colors.secondary}
        buttonBackgroundColor="#8991C1"
        buttonText={path !== 'step-2' ? 'Next' : 'Done'}
        buttonTextColor="#fff"
        buttonFunction={() => handleSubmit()}
        submitButtonLoading={submitButtonLoading}
      >
        <h2>Link Account</h2>
        <h3>Let's add a bank account to receive the gift contibutions.</h3>
      </SubHeader>
      <InputValidation>
        {formError}
        {formError2}
      </InputValidation>
      <Container>
        <SecureBankingBadge onClick={handleShowModal}>
          <IoIosLock /> Secure Banking
        </SecureBankingBadge>

        <form path={path}>
          {path !== 'step-2' ? (
            <FirstForm formData={formData} />
          ) : (
            <SecondForm formData={formData} detail={giftImageFile} />
          )}
        </form>
        <Mobile>
          <Button
            text={path !== 'step-2' ? 'Next' : 'Save'}
            textColor="#ffffff"
            backgroundColor={colors.purple}
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
      <ModalWrapper closeColor={colors.primary}>
        <ModalScreen />
      </ModalWrapper>
    </>
  );
}
