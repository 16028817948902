import React, { useEffect } from 'react';

import history from '~/services/history';
import HideFooter from '~/utils/HideFooter';

import { OuterContainer, Container, EnvelopeSent } from './styles';
import { useLocation } from 'react-router-dom';

export default function Sent() {
  const location = useLocation();
  const giftId = location && location.giftId;
  const tab = location && location.tab;

  setTimeout(
    () =>
      tab && tab
        ? history.push({
            pathname: `/thank-you-cards`,
            state: { lastPage: 'sent', tab },
          })
        : history.push({
            pathname: `/gift-details/${giftId}`,
            state: { lastPage: 'sent' },
          }),
    3000,
  );

  useEffect(() => {
    localStorage.removeItem('targetReached');
  }, []);

  return (
    <OuterContainer>
      <Container>
        <div>
          <EnvelopeSent />
          <h1>Card Sent!</h1>
        </div>
      </Container>
      <HideFooter />
    </OuterContainer>
  );
}
