export function addCreditCard(receiverId, data) {
  return {
    type: 'ADD_CREDIT_CARD',
    receiverId,
    data,
  };
}
export function addCreditCardDone(data) {
    return {
      type: 'ADD_CREDIT_CARD_DONE',
      data,
    };
  }