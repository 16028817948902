import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import { IoIosTrash } from 'react-icons/io';
// Action
import {
  addEmailDetails,
  createEventInvites,
} from '~/store/modules/event/eventAction';
import { toast } from 'react-toastify';
import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import { Container, Card } from './styles';

export default function ReviewContacts() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { state } = useLocation();
  const eventReducer = useSelector((state) => state.eventReducer);
  const { event, isInviteSent } = eventReducer;
  const eventId = event.data;
  const [inviteeEmail, setInvteeEmail] = useState(state ? state.contacts : []);

  const checkemail = useSelector((state) => state.eventReducer.eventEmailData);
  const [contacts] = useState(state ? state.contacts : []);

  const handleAddMore = () => {
    history.push('/email-list', {
      contacts: inviteeEmail,
      emailDetail: state.email_data,
      checked: 'true',
    });
  };
  const handleRemoveContact = (email) => {
    setInvteeEmail(inviteeEmail.filter((contact) => contact.email !== email));
  };
  const handleSend = () => {
    const data = { ...contacts, invitee: inviteeEmail };
    if (inviteeEmail.length !== 0) {
      const emailIds = inviteeEmail.map((item) => item.email).join(', ');
      const sendData = {
        user_id:
          state.email_data.emailDetail.user_id ||
          state.email_data.emailDetail.emailDetail.user_id,
        child_id:
          state.email_data.emailDetail.child_id ||
          state.email_data.emailDetail.emailDetail.child_id,
        event_id:
          state.email_data.emailDetail.event_id ||
          state.email_data.emailDetail.emailDetail.event_id,
        event_time:
          state.email_data.emailDetail.event_time ||
          state.email_data.emailDetail.emailDetail.event_time,
        sendmail_date:
          state.email_data.emailDetail.sendmail_date ||
          state.email_data.emailDetail.emailDetail.sendmail_date,
        title:
          state.email_data.emailDetail.title ||
          state.email_data.emailDetail.emailDetail.title,
        email_arr: emailIds,
      };
      Dispatch(addEmailDetails(sendData));
    } else {
      toast.error('enter alteast one email!');
    }
    // Dispatch(createEventInvites(eventId.id, data));
    // history.push('/invitation-sent');
  };
  if (checkemail) {
    history.push('/invitation-sent');
  }

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonText="Send"
        buttonTextColor="#fff"
        buttonFunction={handleSend}
      >
        <h2>Review List</h2>
        <h3>Review your list prior and fill in the missing fields</h3>
      </SubHeader>
      <Container>
        {inviteeEmail && inviteeEmail.length > 0 ? (
          <>
            {inviteeEmail.map((item) => (
              <Card key={item.email}>
                {item.email}
                {/* <div>
                  <span>{contact.name}</span>
                </div>
                <div>
                  <span>{contact.email}</span>
                </div> */}
                <div>
                  <IoIosTrash
                    size={20}
                    color="#888"
                    onClick={() => handleRemoveContact(item.email)}
                  />
                </div>
              </Card>
            ))}
            <Button text="Add More" buttonFunction={handleAddMore} />
          </>
        ) : (
          <h3>You have no contacts yet.</h3>
        )}
      </Container>
    </>
  );
}
