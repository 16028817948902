import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';
import SubHeader from '~/components/SubHeader';
import { Container, GiftCard } from './styles';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

export default function Cookies() {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
        mobileDescVisible={false}
      >
        <h2>Cookie Policy</h2>
      </SubHeader>
      <Container className="scroller">
        <GiftCard>
          <div>
            <h1>Purposit Cookie Policy</h1>
            <p className="mainparaheading">Effective 01 May, 2020</p>
            <br />
            <h3>Does Purposit use cookies?</h3>
            <br />
            <p className="paragraph">
              Yes. As described in our 
              <Link to="/privacy" className="linktext">
                Privacy Policy
              </Link>
              , Purposit and our marketing partners, affiliates, and analytics
              or service providers use cookies and other technologies to ensure
              everyone who uses Purposit has the best possible experience.
              Cookies also help us keep your account safe.
              <br /> <br />
              By choosing to use the Site after having been notified of our use
              of cookies in the ways described in this Cookie Policy, and, in
              applicable jurisdictions, through notice and unambiguous
              acknowledgement of your consent, you agree to such use.
              <br /> <br />
            </p>
            <h3>What is a cookie?</h3>
            <p className="paragraph">
              A cookie is a small text file that is placed on your hard drive by
              a web page server. Cookies contain information that can later be
              read by a web server in the domain that issued the cookie to you.
              Some of the cookies will only be used if you use certain features
              or select certain preferences, and some cookies will always be
              used. You can find out more about each cookie by viewing our
              current cookie list below.
              <br /> <br />
            </p>
            <h3>What types of cookies does Purposit use?</h3>
            <p className="paragraph">
              The list below details the cookies placed on your browser when you
              visit our site and what they are used for.
              <br />
              <br />
            </p>
            <span className="box1">
              <p className="subHeading">
                {' '}
                <b className="boldcss">Category:</b> Strictly necessary
              </p>
            </span>
            <span className="box1">
              <p className="subHeading">
                <b className="boldcss">Purpose:</b> Purposit.com uses cookies
                that are essential in order to enable you to access our site and
                use its features. These cookies allow us to provide services
                such as secure user login, payments, and other critical
                features. The information collected through these cookies is
                used for this purpose only and is never shared with third
                parties.
              </p>
            </span>
            <br />
            <span className="box1">
              <p className="subHeading">
                {' '}
                <b className="boldcss">Category:</b> Functionality
              </p>
            </span>
            <span className="box1">
              <p className="subHeading">
                <b className="boldcss">Purpose:</b> These cookies allow us to
                remember the choices you make and provide enhanced, more
                personal features. Examples include your username, language,
                region and customisations. The information collected through
                these cookies is used for this purpose only and is never shared
                with third parties.
              </p>
            </span>
            <br />
            <span className="box1">
              <p className="subHeading">
                {' '}
                <b className="boldcss">Category:</b> Performance & Analytics
              </p>
            </span>
            <span className="box1">
              <p className="subHeading">
                <b className="boldcss">Purpose:</b> We work with third parties
                who use cookies to collect data that helps us understand how
                people are using our site. We use this information to identify
                improvements we can make to the user experience. Those third
                parties do not have access to your personal information and only
                provide general trends on all users, For example, how much time
                users spend on the Site, type of devices commonly used (mobiles
                versus desktops versus tablets) and their locations (by state or
                country).
              </p>
            </span>
            <br />
            <h3>How to disable cookies?</h3>
            <p className="paragraph">
              You can generally accept or decline the use of cookies through
              functionality built into your web browser. To learn more how to
              control cookie settings through your browser:
            </p>
            <br />
            <div className="boxdot">
              <ul style={{ listStyleType: 'disc' }}>
                <li>
                  <p className="textpara">
                    Click&nbsp;
                    <a
                      href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linktext"
                    >
                      here
                    </a>{' '}
                    to learn more about the “Private Browsing” setting and
                    managing cookie settings in Firefox
                  </p>
                </li>
                <li>
                  <p className="textpara">
                    {' '}
                    Click&nbsp;
                    <a
                      href="https://support.google.com/chrome/answer/95647?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linktext"
                    >
                      here
                    </a>{' '}
                    to learn more about “Incognito” and managing cookie settings
                    in Chrome
                  </p>
                </li>
                <li>
                  <p className="textpara">
                    {' '}
                    Click&nbsp;
                    <a
                      href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linktext"
                    >
                      here
                    </a>{' '}
                    to learn more about “InPrivate” and managing cookie settings
                    in Internet Explorer
                  </p>
                </li>
                <li>
                  <p className="textpara">
                    {' '}
                    Click&nbsp;
                    <a
                      href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linktext"
                    >
                      here
                    </a>{' '}
                    to learn more about “Private Browsing” and managing cookie
                    settings in Safari
                  </p>
                </li>
              </ul>
            </div>
            <br />
            <p className="paragraph">
              To control privacy settings within mobile applications, please
              visit the privacy settings of your Android or iOS device and
              select{' '}
              <a
                href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="linktext"
              >
                “opt-out of interest based ads” (Android)
              </a>
               or 
              <a
                href="https://support.apple.com/en-us/105131"
                target="_blank"
                rel="noopener noreferrer"
                className="linktext"
              >
                “limit ad tracking” (Apple iOS).
              </a>{' '}
            </p>
            <br />
            <h3>Where can I find more information about cookies?</h3>
            <p className="paragraph">
              If you want to learn more about cookies or how to control, disable
              or delete them, please visit{' '}
              <a
                href="http://www.aboutcookies.org"
                target="_blank"
                rel="noopener noreferrer"
                className="linktext"
              >
                http://www.aboutcookies.org
              </a>{' '}
               for detailed guidance.
            </p>
            <br />
            <h3>How Can I Contact Purposit?</h3>
            <p className="paragraph">
              If you have any questions about our Cookie Policy or our data
              practices, please contact us at 
              <a
                href="mailto:admin@purposit.com"
                target="_blank"
                rel="noopener noreferrer"
                className="linktext"
              >
                admin@purposit.com
              </a>
              .
            </p>
            <br />
          </div>
        </GiftCard>
      </Container>
    </>
  );
}
