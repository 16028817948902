import React from 'react';
import EmptyList from './EmptyList';
import { useDispatch, useSelector } from 'react-redux';
import history from '~/services/history';
import Avatar from '~/components/Avatar';
import { Container, Card } from './styles';
import { fetchReceiverByIdDone } from '~/store/modules/receiver/receiverAction';

export default function Gifts() {
  const followProfile = useSelector((state) => state.userReducer.followProfile);
  const uniqueIds = new Set();
  const Dispatch = useDispatch();

  const handleOpenReceiver = (id) => {
    const mergedObjects = [];

    followProfile.forEach((obj) => {
      const existingObject = mergedObjects.find(
        (mergedObj) => mergedObj.id === obj.id,
      );

      if (existingObject) {
        Object.keys(obj).forEach((key) => {
          if (Array.isArray(obj[key])) {
            if (key === 'events' || key === 'events_image' || key === 'gifts') {
              obj[key].forEach((item) => {
                const existingItems = existingObject[key].map((e) => e.id);
                if (!existingItems.includes(item.id)) {
                  existingObject[key].push(item);
                }
              });
            } else {
              existingObject[key] = existingObject[key].concat(obj[key]);
            }
          } else if (
            typeof obj[key] === 'object' &&
            obj[key] !== null &&
            !['events', 'events_image', 'gifts'].includes(key)
          ) {
            existingObject[key] = { ...existingObject[key], ...obj[key] };
          } else {
            existingObject[key] = obj[key];
          }
        });
      } else {
        mergedObjects.push({ ...obj });
      }
    });
    const foundItem = mergedObjects.find((item) => item.id === id);
    if (foundItem) {
      localStorage.setItem('follow', 'follow');
      Dispatch(fetchReceiverByIdDone(foundItem));
      history.push(`/receiver-profile/${id}`, {
        followUser: 'yes',
        followData: foundItem,
      });
    } else {
      console.log('Item not found');
    }
  };

  return (
    <>
      {followProfile && followProfile.length > 0 ? (
        <Container>
          {followProfile.map((receiver) => {
            if (!uniqueIds.has(receiver.id)) {
              uniqueIds.add(receiver.id);

              return (
                <Card
                  key={receiver.id}
                  onClick={() => handleOpenReceiver(receiver.id)}
                  className='chlidprofile'
                >
                  <Avatar image={receiver.child_image} size={55} />
                  <div>
                    <h3>
                      {receiver.type === 'individual'
                        ? receiver.first_name
                        : receiver.group_name}{' '}
                      {receiver.last_name}
                    </h3>
                    <small>{receiver.location}</small>
                  </div>
                </Card>
              );
            }
            return null;
          })}
        </Container>
      ) : (
        <EmptyList />
      )}
    </>
  );
}
