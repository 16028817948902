import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import Context from '~/context';
// Action
import { deleteGift } from '~/store/modules/gift/giftAction';

import { ReactComponent as TrashCan } from '~/assets/images/trash-can.svg';
import { ReactComponent as TrashCant } from '~/assets/images/trash-cant.svg';

import { Container } from './styles';

export default function DeleteGift({ hasDonations, giftId }) {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const receiver = useSelector((state) => state.receiverReducer);
  const giftReducer = useSelector((state) => state.giftReducer);
  const { receiverId } = receiver;
  const { isGiftDeleted } = giftReducer;

  const id = receiverId !== undefined ? receiverId : receiver.id;

  const handleArchiveGift = () => {
    handleShowModal();
    history.push({
      pathname: '/success',
      state: {
        destination: `/receiver-profile/${id}/`,
        message: 'Gift Archived!',
        colors: { start: colors.lightPurple, end: colors.primary },
      },
    });
  };

  const handleDeleteGift = () => {
    Dispatch(deleteGift(giftId));
  };

  if (isGiftDeleted) {
    history.push({
      pathname: '/success',
      state: {
        destination: `/receiver-profile/${id}/`,
        message: 'Gift Deleted!',
        colors: { start: colors.lightPurple, end: colors.pink },
      },
    });
    handleShowModal();
  }

  const handleEditGift = () => {
    handleShowModal();
    history.push(`/edit-gift/${giftId}`);
  };

  return (
    <Container>
      {hasDonations ? (
        <>
          <TrashCant />
          <h2>Sorry, you are unable to delete this gift.</h2>
          <p>
            This gift cannot be deleted because you have already received
            contributions for it.
          </p>
          <p>
            We recommend either archiving it or editing the gift for changing
            circumstances.
          </p>
          <button type="submit" onClick={() => handleEditGift()}>
            Edit Gift
          </button>
          <button
            type="submit"
            className="blue"
            onClick={() => handleArchiveGift()}
          >
            Archive
          </button>
        </>
      ) : (
        <>
          <TrashCan />
          <h2>Are you sure you want to delete this gift?</h2>
          <p>All information about this gift will be permanently deleted.</p>
          <p>
            Be aware that you will not be able to delete this gift if you have
            received more than one contribution.
          </p>

          <button type="submit" onClick={() => handleShowModal()}>
            Cancel
          </button>
          <button
            type="submit"
            className="red"
            onClick={() => handleDeleteGift()}
          >
            Delete
          </button>
        </>
      )}
    </Container>
  );
}
