import styled from 'styled-components';

export const OuterContainer = styled.div`
  background-color: #f6f6f6;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: calc(100% - 57px);
    min-height: calc(100% - 57px);
  }
`;

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background: linear-gradient(180deg, #f15a24, #93278f);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    height: 100%;
  }

  div {
    max-width: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1 {
    line-height: 1;
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 30px;
  }

  button {
    max-width: 318px;
    font-size: 20px;
    padding: 11px;
    margin-bottom: 20px;
  }

  svg {
    max-width: 107px;
    margin-bottom: 30px;

    &.gift {
      max-height: 70px;
    }
  }

  .link {
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 1px;
    opacity: 0.75;
    transition: 0.5s opacity;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;
