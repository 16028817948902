import produce from 'immer';

const initialState = {
  email: null,
  token: null,
  signed: false,
  loading: false,
  response:null
};

export default function authReducer(state = initialState, action)
 {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        draft.email = action.payload.email;
        break;
      }
      case '@auth/SIGN_IN_REQUEST_GOOGLE': {
        draft.loading = true;
        draft.email = action.payload.email;
        break;
      }
      case '@auth/SIGN_UP_CHECKED_REQUEST': {
        draft.loading = true;
        draft.email = action.payload.email;

        break;
      }
      case '@auth/SIGN_UP_USER_REQUEST': {
        draft.response = action.payload.response;
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_USER_FAILURE': {
        draft.loading = false;
        draft.response = action.payload.errdata;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        draft.loading = false;
        draft.response = null;
        break;
      }
      default:
    }
  });
}
