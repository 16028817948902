import React, { useState, useContext, useEffect } from 'react';

import { ThemeContext } from 'styled-components';
import SubHeader from '~/components/SubHeader';
import {
  Container,
  Container1,
  InputWrapper,
  InputValidation,
  Scroll,
  NotificationList,
} from './styles';
import { darken } from 'polished';
import history from '~/services/history';
import { useDispatch, useSelector } from 'react-redux';
import { Mobile } from '~/config/ReactResponsiveConfig';
import Input from '~/components/Input';
import Button from '~/components/Button';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { changePassword } from '~/api/userAPI';
import { toast } from 'react-toastify';
import {
  updatePasswordFailure,
  updateUserPassword,
} from '~/store/modules/user/userAction';
import {
  getAllNotification,
  readThankuCardNotification,
} from '~/api/notificationAPI';
import NotificationCard from '~/components/NotificationCard';
import EmptyList from '../ArchivedItems/EmptyList';
import { getNotificaiton } from '~/components/Notifications/MainNotifications/notificationTypes';
import { setNotificationData } from '~/store/modules/notification/notificationAction';
import CircularIndeterminate from '~/components/Loader';

export default function NotificationLists() {
  const { colors } = useContext(ThemeContext);
  const profile = useSelector((state) => state.userReducer.profile);
  const [notificationUserData, setNotificationUserData] = useState('');
  const [loader, setLoader] = useState(false);
  const Dispatch = useDispatch();

  useEffect(() => {
    handleGetNotification(profile.id);
  }, [profile.id]);

  const handleGetNotification = async (id) => {
    setLoader(true);
    await getAllNotification(id)
      .then((res) => {
        setNotificationUserData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log('##', err);
      });
  };

  const handleNotification = async (item) => {
    Dispatch(setNotificationData(item));

    await readThankuCardNotification(item.notification_id)
      .then((res) => {
        const notifictionType = getNotificaiton(item.notification_type);

        if (notifictionType && notifictionType.path === '/gift-details') {
          history.push({
            pathname: `/gift-details/${item.gift_id}`,
            data: item,
          });
        } else if (
          notifictionType &&
          notifictionType.path === '/event-detail'
        ) {
          history.push({
            pathname: `/event-details/${item.event_id}`,
            data: item,
          });
        } else {
          console.log('else');
          history.push({
            pathname: (notifictionType && notifictionType.path) || item.path,
            data: item,
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const sortedNotificationData =
    notificationUserData &&
    notificationUserData.sort((a, b) => {
      return a.is_read.localeCompare(b.is_read);
    });

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
        mobileDescVisible={false}
      >
        <h2>Notification</h2>
      </SubHeader>

      {sortedNotificationData && sortedNotificationData.length > 0 ? (
        <Container>
          {!loader ? (
            <>
              <Scroll>
                {sortedNotificationData &&
                  sortedNotificationData.map((item) => {
                    const notifictionType = getNotificaiton(
                      item.notification_type,
                    );

                    return (
                      <Container1 key={item.id}>
                        <div className="image-wrapper">
                          <img
                            src={
                              (notifictionType && notifictionType.icon) ||
                              (item && item.icon)
                            }
                            alt="Gift"
                          />
                        </div>
                        <div
                          className="description"
                          onClick={() => handleNotification(item)}
                        >
                          <span
                            style={{
                              color:
                                item.is_read === 'F' ? '#222627' : '#7a858a',
                            }}
                          >
                            {item.title}
                          </span>
                          <small>{item.message}</small>
                        </div>
                      </Container1>
                    );
                  })}
              </Scroll>
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CircularIndeterminate />
            </div>
          )}
        </Container>
      ) : (
        <Container>
          <EmptyList>Notification list is empty</EmptyList>
        </Container>
      )}
    </>
  );
}
