import axios from 'axios';
import { apiAxiosInstance, imageAxiosInstance } from '../services/axios';
import { getJWTToken } from '../utils/utility';
import { inviteTemplates } from './userAPI';

const tokenData = JSON.parse(getJWTToken());
const headers = {};
if (tokenData && tokenData.token) {
  headers.Authorization = `Bearer ${tokenData.token}`;
}

export const getEvent = async (id) => {
  try {
    const result = await apiAxiosInstance.get(`event?receiverId=${id}`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const getEventById = async (eventId) => {
  try {
    const result = await apiAxiosInstance.get(`event/${eventId}`, { headers });
    return result;
  } catch (err) {
    return err;
  }
};
export const getEventByIdd = async (eventId) => {
  try {
    const result = await apiAxiosInstance.get(`event/get_event/${eventId}`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const createEvent = async (data) => {
  try {
    const result = await apiAxiosInstance.post('event', data, { headers });
    return result;
  } catch (err) {
    return err;
  }
};
export const createEventInvitation = async (data) => {
  try {
    const result = await apiAxiosInstance.post(
      'event/event_invitation_create',
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};
export const updateEventInvitation = async (data) => {
  try {
    const result = await apiAxiosInstance.put(
      `event/event_invitation_update/${data.user_id}`,
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const deleteEvent = async (eventId) => {
  try {
    const result = await apiAxiosInstance.delete(`event/${eventId}`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const getEditevent = async (eventId, data) => {
  try {
    const result = await apiAxiosInstance.put(`event/${eventId}`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const GetCreateEventInvites = async (eventId, data) => {
  try {
    const result = await apiAxiosInstance.post(
      `event/${eventId}/invite`,
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getContactList = async () => {
  try {
    const result = await apiAxiosInstance.get(`user`, { headers });
    return result;
  } catch (err) {
    return err;
  }
};

export const createRspvEvent = async (data) => {
  try {
    const result = await apiAxiosInstance.post('event/create_rspv', data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const getRSPVEvent = async (data) => {
  try {
    const result = await apiAxiosInstance.post('event/get_rspv_user', data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const giftSingleRec = async (filekey) => {
  try {
    const payload = {
      filekey: filekey,
      filename: 'giftsinglerec',
    };
    const response = await inviteTemplates(payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const giftMultiRec = async (filekey) => {
  try {
    const payload = {
      filekey: filekey,
      filename: 'giftmultirec',
    };
    const response = await inviteTemplates(payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const partyMultiRec = async (filekey) => {
  try {
    const payload = {
      filekey: filekey,
      filename: 'partymultirec',
    };
    const response = await inviteTemplates(payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const partySingleRec = async (filekey) => {
  try {
    const payload = {
      filekey: filekey,
      filename: 'partysinglerec',
    };
    const response = await inviteTemplates(payload);
    return response.data;
  } catch (err) {
    console.error('Error fetching data:', err);
  }
};

export const saveEmailDetail = async (data) => {
  try {
    const result = await apiAxiosInstance.post('event/email', data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const getEventGiftById = async (eventId, user_id) => {
  try {
    const result = await apiAxiosInstance.get(
      `event/event_gift/${eventId}?user_id=${user_id}`,
      { headers },
    );
    return result.data;
  } catch (err) {
    return err;
  }
};

export const getSingleEventGiftById = async (data) => {
  try {
    const result = await apiAxiosInstance.post(
      `event/event_single_gift`,
      data,
      { headers },
    );
    return result.data;
  } catch (err) {
    return err;
  }
};

export const getEventGiftByOneOrMoreId = async (eventId) => {
  try {
    const result = await apiAxiosInstance.get(`event/event_gifts/${eventId}`, {
      headers,
    });
    return result.data;
  } catch (err) {
    return err;
  }
};

export const createArchiveEvent = async (data) => {
  try {
    const result = await apiAxiosInstance.post(
      `event/${data.eventId}/archive`,
      data,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const unHideEvent = async (data) => {
  try {
    const result = await apiAxiosInstance.post('event/unhide_event', data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const createArchiveGift = async (giftId, data) => {
  try {
    const result = await apiAxiosInstance.post(
      `gift/${giftId}/gift_archive`,
      data,
      {
        headers,
      },
    );
    return result.data;
  } catch (err) {
    return err;
  }
};

export const unHideGift = async (data) => {
  try {
    const result = await apiAxiosInstance.post('gift/unhide_gift', data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const unHideReceivers = async (data) => {
  try {
    const result = await apiAxiosInstance.post(
      'receiver/unhide_receiver',
      data,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const addcreateEventImage = async (data, imageFile) => {
  try {
    const result = await imageAxiosInstance.post(
      `event/${data.event_id}/event_done_image`,
      imageFile,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getEventImageDone = async (data) => {
  try {
    const result = await apiAxiosInstance.post(`event/event_memories`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const getMomoriesById = async (child_id) => {
  try {
    const result = await apiAxiosInstance.get(
      `event/event_memories_by_id/${child_id}`,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const updateMemoriesName = async (data) => {
  try {
    const result = await apiAxiosInstance.put(
      `event/update_memories_name/${data.event_id}`,
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const createMemoriesComment = async (data) => {
  try {
    const result = await apiAxiosInstance.post('event/memories_comment', data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const createMemoriesLike = async (data) => {
  try {
    const result = await apiAxiosInstance.post('event/memories_like', data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const deleteMemoriesEvent = async (data) => {
  try {
    const result = await apiAxiosInstance.put(
      `event/delete_memories_event/${data.id}`,
      data,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const hideMemoriesEvent = async (data) => {
  try {
    const result = await apiAxiosInstance.put(
      `event/hide_memories_event/${data.event_id}`,
      data,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const deleteMemoriesAlbum = async (data) => {
  try {
    const result = await apiAxiosInstance.put(
      `event/delete_memories_album/${data.event_id}`,
      data,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};
export const createAttendance = async (data) => {
  try {
    const result = await apiAxiosInstance.post('event/check_attendance', data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const getEventCount = async (data) => {
  try {
    const result = await apiAxiosInstance.post('event/get_event_count', data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};
