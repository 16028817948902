import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import userReducer from './user/userReducer';
import receiverReducer from './receiver/receiverReducer';
import giftReducer from './gift/giftReducer';
import eventReducer from './event/eventReducer';
import bankAccountReducer from './bankAccount/bankAccountReducer';
import notificationReducer from './notification/notificationReducer';
import creditCardReducer from './creditCard/creditCardReducer';

const reducers = combineReducers({
  authReducer,
  userReducer,
  receiverReducer,
  giftReducer,
  eventReducer,
  bankAccountReducer,
  notificationReducer,
  creditCardReducer,
});

export default reducers;
