import React, { useState, useContext } from 'react';

import { ThemeContext } from 'styled-components';
import SubHeader from '~/components/SubHeader';
import { Container, InputWrapper, InputValidation, EyeIcon } from './styles';
import { darken } from 'polished';
import history from '~/services/history';
import { useDispatch, useSelector } from 'react-redux';
import { Mobile } from '~/config/ReactResponsiveConfig';
import Input from '~/components/Input';
import Button from '~/components/Button';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { changePassword } from '~/api/userAPI';
import { toast } from 'react-toastify';
import {
  updatePasswordFailure,
  updateUserPassword,
} from '~/store/modules/user/userAction';

export default function ChangePassword() {
  const { colors } = useContext(ThemeContext);
  const Dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [formError, setFormError] = useState(null);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { id } = useSelector((state) => state.userReducer.profile);

  const changePasswordData = useSelector(
    (state) => state.userReducer.passwordChange,
  );

  const validateForm = () => {
    if (currentPassword === '') {
      setFormError(`validation failed: current password required!`);
      return 'validationFailed';
    }
    if (newPassword === '') {
      setFormError(`validation failed: new password required!`);
      return 'validationFailed';
    }
    if (confirmNewPassword === '') {
      setFormError(`validation failed: confirm password required!`);
      return 'validationFailed';
    }
    if (newPassword !== confirmNewPassword) {
      setFormError(
        `Validation failed: new password and confirm password do not match!`,
      );
      return 'validationFailed';
    }
    if (currentPassword === newPassword) {
      setFormError(`Validation failed: new password must be different from current password!`);
      return 'validationFailed';
    }
  };

  const handleTogglePassword = (data) => {
    if (data === 'currentPassword') {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (data === 'newPassword') {
      setShowNewPassword(!showNewPassword);
    } else if (data === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
    }
  };

  const handleSubmit = async () => {
    const error = validateForm();
    if (error !== 'validationFailed') {
      const data = {
        oldPassword: currentPassword,
        newPassword: newPassword,
      };
      Dispatch(updateUserPassword(id, data));
    }
  };

  if (
    changePasswordData &&
    changePasswordData.message === 'please enter correct current password'
  ) {
    setFormError(`validation failed: ${changePasswordData.message}`);
    Dispatch(updatePasswordFailure(''));
  } else if (
    changePasswordData &&
    changePasswordData.message === 'Password Changed Successfully'
  ) {
    Dispatch(updatePasswordFailure(''));
    history.push({
      pathname: '/success',
      state: {
        destination: `/app-settings`,
        message: 'Password changed successfully!',
        colors: { start: colors.lightPurple, end: colors.secondary },
      },
    });
  }

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 2000);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonText="Done"
        buttonTextColor="#fff"
        mobileDescVisible={false}
        buttonFunction={handleSubmit}
      >
        <h2>Change Password</h2>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        <form>
          <InputWrapper>
            <Input
              label="Enter Current Password"
              name="currentPassword"
              placeholder="Eg: 875762"
              value={currentPassword}
              type={showCurrentPassword ? 'text' : 'password'}
              onChange={(event) => setCurrentPassword(event.target.value)}
            />
            <EyeIcon onClick={() => handleTogglePassword('currentPassword')}>
              {showCurrentPassword ? (
                <AiOutlineEye size={25} />
              ) : (
                <AiOutlineEyeInvisible size={25} />
              )}
            </EyeIcon>
          </InputWrapper>
          <InputWrapper>
            <Input
              label="Enter new password"
              name="newPassword"
              placeholder="Eg: 123456"
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              maxLength={15}
            />
            <EyeIcon onClick={() => handleTogglePassword('newPassword')}>
              {showNewPassword ? (
                <AiOutlineEye size={25} />
              ) : (
                <AiOutlineEyeInvisible size={25} />
              )}
            </EyeIcon>
          </InputWrapper>
          <InputWrapper>
            <Input
              label="Enter confirm password"
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Eg: 123456"
              value={confirmNewPassword}
              onChange={(event) => setConfirmNewPassword(event.target.value)}
              maxLength={15}
            />
            <EyeIcon onClick={() => handleTogglePassword('confirmPassword')}>
              {showConfirmPassword ? (
                <AiOutlineEye size={25} />
              ) : (
                <AiOutlineEyeInvisible size={25} />
              )}
            </EyeIcon>
          </InputWrapper>
        </form>
        <Mobile>
          <Button
            text="Next"
            backgroundColor="#01e3fa"
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
    </>
  );
}
