import React from 'react';

import CreditCardLock from '~/assets/images/credit-card-lock.svg';
import Norton from '~/assets/images/norton.svg';
import Stripe from '~/assets/images/stripe.svg';

import { Container } from './styles';

function ModalScreen() {
  return (
    <Container>
      <img src={CreditCardLock} alt="Safe Banking" />
      <div className="content">
        <h2>SECURE BANKING</h2>
        <p>
          Purposit uses worldwide payment gateway provider Stripe, with it’s
          bank-grade security system and it’s proprietary encryption software to
          process all financial transactions and safely store your personal and
          credit card details.
        </p>

        <p>
          Purposit platform also incorporates SSL certification and an
          encryption algorithm to further protect your personal information.{' '}
        </p>
      </div>

      <img src={Norton} alt="Norton" className="norton" />
      <img src={Stripe} alt="Stripe" className="stripe" />
    </Container>
  );
}

export default ModalScreen;
