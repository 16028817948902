const initialState = {
  events: [],
  selectedEventId: null,
  event: {},
  isEventDeleted: null,
  eventEditAddOption: false,
  isEventEdit: false,
  eventInviteesDetails: null,
  contactList: null,
  isInviteSent: null,
  eventInvitation: [],
  eventEmailDetails: null,
  eventEmailData: null,
  eventArchived: null,
  eventrspv: null,
  memoriesData: null,
};
export default function eventReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_ALL_EVENT_DONE':
      return { ...state, events: action.result.data };
    case 'CREATE_EVENT_INVITATION_DONE':
      return { ...state, eventInvitation: action.data.data };
    case 'CREATE_ARCHIVED_EVENT_DONE':
      return { ...state, eventArchived: action.data.data };
    case 'SET_EVENT_ID':
      return { ...state, selectedEventId: action.eventId };
    case 'CREATE_EVENT_DONE':
      return { ...state, event: action.data.data };
    case 'CREATE_RSPV_EVENT_DONE':
      return { ...state, eventrspv: action.data };
    case 'CREATE_EMAIL_DONE':
      return { ...state, eventEmailData: action.data };
    case 'FETCH_EVENT_BY_ID_DONE':
      return {
        ...state,
        event: action.data,
        eventEditAddOption: true,
        isEventEdit: false,
      };
    case 'DELETE_EVENT_DONE':
      if (action.data) {
        return { ...state, isEventDeleted: true };
      }
      break;
    case 'FETCH_RECEIVER_BY_ID_DONE':
      return { ...state, isEventDeleted: false, eventEditAddOption: false };
    case 'EDIT_EVENT_DONE':
      if (action.data) {
        return { ...state, isEventEdit: true };
      }
      break;
    case 'ADD_EVENT_INVITES':
      return { ...state, eventInviteesDetails: action.data };
    case 'ADD_EMAIL_DETAILS':
      return { ...state, eventEmailDetails: action.data };
    case 'FETCH_CONTACT_LIST_DONE':
      return { ...state, contactList: action.data.data };
    case 'FETCH_EVENT_IMAGE_BY_ID_DONE':
      return { ...state, memoriesData: action.data.data };
    case 'CREATE_EVENT_INVITES_DONE':
      if (action.data) {
        return { ...state, isInviteSent: true };
      }
      break;
    default:
      return state;
  }
}
