import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 25px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    grid-template-columns: auto;
  }

  button {
    max-width: 100%;
    width: 100%;

    @media (max-width: 767px) {
      margin-top: 20px;
      max-width: 100%;
    }
  }

  svg {
    cursor: pointer;
  }
`;

export const Card = styled.div`
  background: white;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  width: 100%;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #888;

  @media (max-width: 767px) {
    font-size: 17px;
  }

  > div {
    width: 50%;
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;

    &:first-child {
      width: 40%;
      max-width: 40%;
    }

    &:last-child {
      width: 10%;
      max-width: 10%;
      display: flex;
      justify-content: flex-end;
    }
  }

  img {
    width: 55px;
    height: 55px;
    min-width: 55px;
    border-radius: 28px;
    object-fit: cover;
    object-position: center;
    margin-right: 20px;

    @media (max-width: 767px) {
      margin-right: 10px;
    }
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }
`;
