import React from 'react';
import { IoIosAdd } from 'react-icons/io';

import { Container } from './styles';

export default function UploadButton({
  backgroundColor = '#d5d5d5',
  size = 40,
  iconSize = 40,
  iconColor = '#fff',
  onChange,
  accept ="",
  id="file",
  htmlFor="file"
}) {
  return (
    <Container backgroundColor={backgroundColor} size={size}>
      <input
        type="file"
        name="file[]"
        id={id}
        className="inputfile"
        data-multiple-caption="{count} files selected"
        multiple=""
        onChange={(e) => onChange(e)}
        accept={accept}
      />
      <label htmlFor={htmlFor}>
        <span>
          <IoIosAdd size={iconSize} color={iconColor} />
        </span>
      </label>
    </Container>
  );
}
