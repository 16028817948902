import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import history from '~/services/history';
import { Mobile } from '~/config/ReactResponsiveConfig';
import { ThemeContext } from 'styled-components';
// Action
import { addReceiver } from '../../../store/modules/receiver/receiverAction';
import SubHeader from '~/components/SubHeader';
import AvatarUpload from '~/components/AvatarUpload';
import Input from '~/components/Input';
import Button from '~/components/Button';

import {
  Container,
  InputWrapper,
  SelectControl,
  InputValidation,
} from './styles';

export default function AddIndividual() {
  const { type } = useParams();
  const Dispatch = useDispatch();
  const [relation, setRelation] = useState('Other');
  const { colors } = useContext(ThemeContext);
  const [name, setName] = useState('');
  const [gender, setGender] = useState('F');
  const [location, setLocation] = useState('');
  const [formError, setFormError] = useState(null);
  const profile = useSelector((state) => state.userReducer.profile);
  const isReceiverCreated = useSelector(
    (state) => state.giftReducer.isReceiverCreated,
  );
  const receiver = useSelector((state) => state);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [receiverImageFile, setReceiverImageFile] = useState(null);
  const [file, setFile] = useState();

  const handleRelationChange = (event) => {
    setRelation(event.target.value);
  };
  const checkedOnboarding = localStorage.getItem('onbording');

  const handleImage = async (event) => {
    setReceiverImageFile(event.target.files[0]);
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  const validateForm = () => {
    if (name === '') {
      setFormError(`validation failed: name required!`);
      return 'validationFailed';
    }
    if (location === '') {
      setFormError(`validation failed: location required!`);
      return 'validationFailed';
    }
    if (relation === '') {
      setFormError(`validation failed: relation required!`);
      return 'validationFailed';
    }
  };

  const handleSubmit = () => {
    const data = {
      group_name: name,
      location,
      relation,
      user_id: profile.id,
      type,
    };

    const error = validateForm();
    if (error !== 'validationFailed') {
      const formData = new FormData();
      if (receiverImageFile) {
        formData.append('file', receiverImageFile);
      }
      Dispatch(addReceiver(data, receiverImageFile ? formData : null));
      if (checkedOnboarding) {
        setSubmitButtonLoading(true);
        setTimeout(() => {
          if (receiver) {
            setSubmitButtonLoading(false);
            history.push('/first-gift');
          }
        }, 2000);
        // history.push({
        //   pathname: '/success',
        //   state: {
        //     destination: `/my-profile`,
        //     message: 'Receiver Created!',
        //     colors: { start: colors.lightPurple, end: colors.purple },
        //   },
        // });
      } else {
        history.push('/onboarding-success/1');
      }
    }
  };
  // if (isReceiverCreated) {
  //   history.push('/onboarding-success/1');
  // }

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 2000);

  return (
    <>
      <SubHeader
        buttonBackgroundColor="#01e3fa"
        buttonText="Next"
        buttonTextColor="#142484"
        mobileNavButtons={false}
        buttonFunction={() => handleSubmit()}
        submitButtonLoading={submitButtonLoading}
      >
        <h2>ADD GIFT RECEIVERS</h2>
        <h3>
          Add a few details about the people you want to
          fund&nbsp;gift(s)&nbsp;for
        </h3>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        <form>
          <AvatarUpload
            image={file && file}
            gender={gender}
            onChange={handleImage}
          />
          <InputWrapper>
            <Input
              label="Names of Receivers"
              name="name"
              placeholder="Eg: Jack and Mary"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label="City Where They Live"
              name="city"
              placeholder="Mosman NSW 2088"
              value={location}
              onChange={(event) => setLocation(event.target.value)}
            />
          </InputWrapper>
          <SelectControl variant="filled">
            <InputLabel id="relation-label" disableAnimation shrink={false}>
              I am their...
            </InputLabel>
            <Select
              labelId="relation-label"
              id="relation"
              value={relation}
              onChange={handleRelationChange}
            >
              <MenuItem value="Other">Other</MenuItem>
              <MenuItem value="Father">Father</MenuItem>
              <MenuItem value="Mother">Mother</MenuItem>
              <MenuItem value="Brother">Brother</MenuItem>
              <MenuItem value="Sister">Sister</MenuItem>
              <MenuItem value="Uncle">Uncle</MenuItem>
              <MenuItem value="Aunt">Aunt</MenuItem>
            </Select>
          </SelectControl>
        </form>
        <Mobile>
          <Button
            text="Next"
            backgroundColor="#01e3fa"
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
    </>
  );
}
