import React from 'react';
import { IoIosHeartEmpty } from 'react-icons/io';

import history from '~/services/history';

import { Badge } from './styles';
import { useSelector } from 'react-redux';

export default function ThankYouNotifications() {
  const hasUnread = true;
  const notificationReducer = useSelector((state) => state.notificationReducer);
  const { thankuCard } = notificationReducer;
  const receivedCard = thankuCard ? thankuCard.receiveThankyou : '';

  return (
    <Badge
      hasUnread={hasUnread}
      color="#fe0200"
      onClick={() => history.push('/thank-you-cards')}
      count={receivedCard && receivedCard.length}
    >
      <IoIosHeartEmpty size={32} />
    </Badge>
  );
}
