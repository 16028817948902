import React from 'react';

import { Container } from './styles';

function FindOutMoreModal() {
  return (
    <Container>
      <h3>A Party Invitation</h3>

      <p>Ideal for those who want to manage a party or event for someone.</p>

      <p>
        You can create an invitation for a special occasion, request RSVPs, add
        photos, track contributions and send thank you cards.{' '}
      </p>

      <p>
        If your gifts do not get fully funded, you can use them for future
        events. That way you can start raising funds for future milestones like
        travel, education as well as costly items.{' '}
      </p>

      <p>Examples:</p>
      <ul>
        <li>
          A dad invites the family to his teen’s birthday party and shares gift
          wishes for him that they can contribute to
        </li>
        <li>
          A daughter invites friends to her parent’s wedding anniversary and
          shares gift ideas for the parents{' '}
        </li>
        <li>
          A grandpa invites the family for Xmas lunch and shares gift wishes for
          grandma that they can contribute to
        </li>
        <li>
          A woman invites friends for her partner’s 40th birthday bash and
          shares gift wish for him that they can contribute to){' '}
        </li>
      </ul>
      <h3>A webpage with a list of gifts</h3>

      <p>
        Ideal for those who want to simply create a list of gift wishes for
        someone that can be shared with friends.
      </p>

      <p>
        The webpage link you create can be sent via email and social media. It
        can also be added to other platform you may want to use to create an
        invitation (outside Purposit).
      </p>

      <p>
        The link will take your invitees directly to the webpage showing the
        list of gifts you chose. There, your friends can contribute to any of
        the gifts.
      </p>

      <p>
        You will be able to track contributions and send thank you cards to
        every participant.
      </p>
    </Container>
  );
}

export default FindOutMoreModal;
