import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Container, Media } from './styles';

import EmptyList from '~/pages/ReceiverProfile/EmptyList';
import { useDispatch, useSelector } from 'react-redux';
import { getEventDoneImage } from '~/store/modules/event/eventAction';

export default function GridView({ eventId }) {
  const Dispatch = useDispatch();
  const memories = useSelector((state) => state.eventReducer.memoriesData);

  const matchingMemory =
    memories &&
    memories.filter((memory) => memory.event_id === parseInt(eventId));

  return matchingMemory && matchingMemory.length === 0 ? (
    <EmptyList>
      <h2 className="notempty">
        This is where you create a photo album for this event that will be
        shared with your invitees!{' '}
      </h2>
    </EmptyList>
  ) : (
    <Container>
      {matchingMemory &&
        matchingMemory.map((item) => (
          <Link key={item.id} to={`/event-album/${eventId}/post/${item.id}`}>
            <Media image={item.url} />
          </Link>
        ))}
    </Container>
  );
}
