import styled from 'styled-components';

export const AvatarImage = styled.img`
  border-radius: 50%;
  max-width: ${({ size }) => `${size}px`};
  max-height: ${({ size }) => `${size}px`};
  margin-right: 15px;
  object-fit: cover;
  object-position: center;
`;
