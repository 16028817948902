import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';

import { ReactComponent as WriteMessage } from '~/assets/images/write-message.svg';
import { ReactComponent as RecordVideo } from '~/assets/images/record-video.svg';

import SubHeader from '~/components/SubHeader';

import { Container, BigButton } from './styles';
import { getJWTToken } from '~/utils/utility';

export default function MessageRecipient() {
  const { colors } = useContext(ThemeContext);
  const tokenData = JSON.parse(getJWTToken());
  const getCheck = localStorage.getItem('rspv_data');
  const getFollow = localStorage.getItem('follow');
  const giftCheck = localStorage.getItem('gift');
  const getUserData = localStorage.getItem('giftData');
  const giftData = JSON.parse(getUserData);

  const handleSkip = () => {
    const eventId = giftData && giftData.selectedGifts[0].event_id;
    giftCheck === 'single'
      ? history.push({
          pathname: '/success',
          state: {
            destination: `/event-details/${eventId}/`,
            message: 'Thanks for your contribution!',
            colors: { start: colors.lightPurple, end: colors.purple },
          },
        })
      : giftCheck === 'list'
      ? history.push({
          pathname: '/success',
          state: {
            destination: `/event-details/${eventId}/`,
            message: 'Thanks for your contribution!',
            colors: { start: colors.lightPurple, end: colors.purple },
          },
        })
      : history.push({
          pathname: '/success',
          state: {
            destination: `/my-profile`,
            message: 'Thanks for your contribution!',
            colors: { start: colors.lightPurple, end: colors.purple },
          },
        });
  };

  return (
    <>
      <SubHeader backgroundColor={colors.orange} hideBackButton>
        {getFollow !== null ? (
          <h2>ADD MESSAGE</h2>
        ) : (
          <h2>MESSAGE TO GIFT RECEIVER</h2>
        )}
        <h3>
          Super! Would you also like to send a message with your contribution?
        </h3>
      </SubHeader>
      <Container>
        <p>
          You can write a special message or record a short video sharing the
          joy.
        </p>
        <BigButton onClick={() => history.push('/write-message')}>
          <div className="image-wrapper">
            <WriteMessage alt="Gift Icon" />
          </div>
          <div className="text-content">
            <h2>Write Message</h2>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>
        <BigButton
          backgroundColor={colors.lightPurple}
          onClick={() => history.push('')}
        >
          <div className="image-wrapper">
            <RecordVideo alt="Party Invite" />
          </div>
          <div className="text-content">
            <h2>Record Video</h2>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>
        {tokenData &&
        tokenData.token !== null &&
        getCheck &&
        getCheck === 'party_with_venue_norspv' ? (
          <Link to="/no-rsvp/">
            <p className="pointerclass">Skip message...</p>
          </Link>
        ) : tokenData &&
          tokenData.token !== null &&
          getCheck &&
          getCheck !== 'party_with_venue_norspv' ? (
          <Link to="/rsvp-confirmed/">
            <p className="pointerclass">Skip message...</p>
          </Link>
        ) : getFollow !== null ? (
          <p className="pointerclass" onClick={handleSkip}>
            Skip message...
          </p>
        ) : (
          <Link to="/rsvp-skip">
            <p className="pointerclass">Skip message...</p>
          </Link>
        )}
      </Container>
    </>
  );
}
