export function addBankAccount(receiverId, data) {
  return {
    type: 'ADD_BANK_ACCOUNT',
    receiverId,
    data,
  };
}
export function addBankAccountDone(data) {
  return {
    type: 'ADD_BANK_ACCOUNT_DONE',
    data,
  };
}

export function errorBankAccount(data) {
  return {
    type: 'FAILURE_BANK_ACCOUNT',
    data,
  };
}


export function updateBankAccount(receiverId, data) {
  return {
    type: 'UPDATE_BANK_ACCOUNT',
    receiverId,
    data,
  };
}
export function updateBankAccountDone(data) {
  return {
    type: 'UPDATE_BANK_ACCOUNT_DONE',
    data,
  };
}
export function bankListById(receiverId) {
  return {
    type: 'BANK_LIST_BY_ID',
    receiverId,
  };
}

export function bankListByIdDone(data) {
  return {
    type: 'BANK_LIST_BY_ID_DONE',
    data,
  };
}
export function bankDetailsById(receiverId, accountId) {
  return {
    type: 'BANK_DETAILS_BY_ID',
    receiverId,
    accountId,
  };
}
export function bankDetailsByIdDone(data) {
  return {
    type: 'BANK_DETAILS_BY_ID_DONE',
    data,
  };
}
export function cardDetailsByUserId(userId) {
  return {
    type: 'CARD_DETAILS_BY_USER_ID',
    userId,
  };
}
export function cardDetailsByUserIdDone(data) {
  return {
    type: 'CARD_DETAILS_BY_USER_ID_DONE',
    data,
  };
}
