import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import guests from './guests';

import { ReactComponent as BirthdayCake } from '~/assets/images/notification-icon-17.svg';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import Avatar from '~/components/Avatar';

import { Container, Column, Card } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchReceiverById,
  fetchReceiverByIdDone,
} from '~/store/modules/receiver/receiverAction';
import CircularIndeterminate from '~/components/Loader';
import { getReceiverById } from '~/api/receiverAPI';

export default function PartyComing() {
  const { colors } = useContext(ThemeContext);
  const Dispatch = useDispatch();
  const guest = guests.find((g) => g.id === parseInt(1, 10));
  const location = useLocation();
  const getData = location && location.data;
  const [giftData, setGiftData] = useState('');
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [loader, setLoader] = useState(false);

  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : JSON.parse(getData && getData.data);

  useEffect(() => {
    handleGetChild(parsedData.child_id);
  }, [parsedData.child_id]);

  const handleGetChild = async (id) => {
    setLoader(true);
    await getReceiverById(id)
      .then((res) => {
        setGiftData(res.data);
        Dispatch(fetchReceiverByIdDone(res.data));
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log('##', err);
      });
  };

  return (
    <>
      <SubHeader backgroundColor={colors.purple} mobileDescVisible={false}>
        <h2>Birthday coming</h2>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <Column>
              <h2>Birthday coming!</h2>
              <Card>
                <Avatar
                  image={
                    giftData && giftData.child_image
                      ? giftData.child_image
                      : guest.avatar
                  }
                  size={100}
                />
                <h3 style={{ textTransform: 'capitalize' }}>
                  {giftData && giftData.first_name}{' '}
                  {giftData && giftData.last_name}
                </h3>
                <span className="large">
                  will be turning one year
                  <br />
                  older very soon.
                </span>
              </Card>
            </Column>
            <Column>
              <BirthdayCake />
              <span className="large">
                Lets’s organize a special gift or party for Brandon to celebrate
                the big day!
              </span>
              <Button
                text="Create a Birthday Event"
                buttonFunction={() =>
                  history.push(`/event-goal`, { birthday: 'birthday' })
                }
              />
            </Column>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}
