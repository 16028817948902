import styled from 'styled-components';
import { lighten } from 'polished';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }

  form {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      grid-template-columns: auto;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  > div {
    display: flex;

    &.card-options {
      flex-direction: column;
    }

    .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
      text-transform: none;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
    }
  }

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
    align-items: flex-start;

    > span:first-child {
      margin-top: -10px;
    }

    & + .info-button {
      margin-left: 10px;
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 50%;
      width: 18px;
      height: 18px;
      display: inline-block;
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }

  input,
  textarea {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }

  textarea {
    min-height: 400px;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 767px) {
      min-height: 415px;
    }
  }

  p {
    color: #888;
    font-size: 16px;

    &:first-of-type {
      margin: 20px 0;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 100%;
  }
`;

export const CheckboxControl = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      align-items: flex-start;
    }
  }

  .MuiButtonBase-root {
    margin-left: -10px;
  }

  @media (max-width: 767px) {
    span:last-of-type {
      margin-bottom: 30px;
    }
  }
`;

export const BigButton = styled.button`
  padding: 0 15px;
  max-width: 398px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #232627;
  background-color: #fff;
  text-align: left;
  transition: all 0.5s;
  border: 0;
  cursor: pointer;
  margin-top: 20px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  h2 {
    line-height: 1.2;
    font-size: 20px;
  }

  .image-wrapper {
    margin-right: 15px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin-right: 10px;
    }

    svg {
      width: 58px;
      height: 58px;
      margin: 0 auto;

      #letter {
        polygon {
          fill: #ff0079;
        }

        path {
          fill: #fff;
        }
      }
    }
  }

  .text-content {
    flex: 1;
  }

  &:hover {
    box-shadow: 0px 0px 10px 0px #cacaca8a;

    > svg {
      opacity: 1;
    }
  }
`;

export const RadioControl = styled(FormControl)`
  max-width: 330px;
  width: 100%;

  .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const RadioButton = styled(Radio)`
  &.MuiRadio-root {
    &.Mui-checked {
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
      }
    }

    &:hover {
      background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
    }
  }
`;
export const InputColumnHide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: none !important;
  
  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 100%;
  }
`;
