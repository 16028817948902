import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 20px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 15px;
    margin: 0;
  }

  > h3 {
    text-align: center;
    margin: 40px 0 20px;
    color: #888;
  }

  button {
    max-width: 100%;
    max-height: 50px;

    span {
      font-size: 15px;
    }
  }
`;

export const GiftCard = styled.div`
  background: white;
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  img {
    width: 120px;
    height: 120px;
    min-width: 120px;
    object-fit: cover;
    object-position: center;
    margin-right: 20px;

    @media (max-width: 767px) {
      width: 80px;
      height: 80px;
      min-width: 80px;
      margin-right: 10px;
    }
  }

  > div {
    min-height: 120px;

    @media (max-width: 767px) {
      min-height: initial;
    }
  }

  > div:first-child {
    display: flex;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 240px;

    @media (max-width: 767px) {
      min-width: 100%;
    }
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }

  svg {
    margin-right: -10px;
  }

  small {
    color: #aeaeae;
    font-size: 13px;
  }

  .progress-bar {
    margin-top: 10px;

    > div:first-of-type {
      background-color: #e9ecee;
      border-radius: 2px;
    }

    > div:last-of-type {
      display: flex;
      justify-content: space-between;
      color: #888;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 12px;
    line-height: 15px;
    color: #656565;

    @media (min-width: 768px) {
      flex: 1;
      overflow: hidden;
      max-height: 79px;
    }
  }
`;

export const ContactList = styled.div`
  margin-top: 30px;
  column-count: 2;
  display: block;

  @media (max-width: 767px) {
    column-count: 1;
  }
`;

export const Contact = styled.div`
  background: white;
  border-top: 1px solid #eee;
  box-shadow: 0px 4px 6px 0px #cacaca8a;
  display: inline-block;
  padding: 15px;
  width: 100%;

  span {
    font-size: 12px;

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  .contact-info {
    display: flex;

    > img {
      @media (max-width: 767px) {
        max-width: 40px;
        max-height: 40px;
      }
    }
  }

  .donated-value {
    display: flex;
    flex-direction: column;
  }

  .invite-button {
    margin-top: 10px;
  }
`;

export const FundingCompleted = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.pink};
  text-transform: uppercase;
  color: white;
  justify-content: center;
  min-height: 100px;
  flex-direction: column;
  width: 100%;
  position: fixed;
  bottom: 0;
  transition: all 0.5s;
  cursor: pointer;
  display: none;
  z-index: 2;

  @keyframes fadeIn {
    from {
      transform: translate3d(0, 100%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  &.show {
    display: flex;
    animation: fadeIn 1s ease-in-out;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 80%;
  }

  > svg {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
  }
`;
