import React from 'react';

import { Container } from './styles';

export default function EmptyList() {
  return (
    <Container>
      <p>
        Click below to add the person or people to whom you'd like to organise
        and fund gifts for.
      </p>
    </Container>
  );
}
