import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  getAddCreditCard,
} from '../../../api/bankAccountAPI';
import {
    addCreditCardDone,
} from '../creditCard/creditCardAction';


export function* watchAddCreditCard({ receiverId, data }) {
  try {
    const result = yield call(getAddCreditCard, receiverId, data);
    if (result.status === 200) {
      yield put(addCreditCardDone(result));
    }
  } catch (err) {
    return err;
  }
}

export default all([
  takeLatest('ADD_CREDIT_CARD', watchAddCreditCard),
]);
