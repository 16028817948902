import React from 'react';

import { Container } from './styles';

export default function SubHeaderButton({
  text,
  backgroundColor,
  textColor,
  buttonFunction,
}) {
  return (
    <>
      {text && (
        <Container
          backgroundColor={backgroundColor}
          textColor={textColor}
          onClick={buttonFunction}
        >
          <span>{text}</span>
        </Container>
      )}
    </>
  );
}
