export function fetchAllEvent(id) {
  return {
    type: 'FETCH_ALL_EVENT',
    id,
  };
}
export function fetchAllEventDone(result) {
  return {
    type: 'FETCH_ALL_EVENT_DONE',
    result,
  };
}
export function setEventId(eventId) {
  return {
    type: 'SET_EVENT_ID',
    eventId,
  };
}
export function fetchEventById(eventId) {
  return {
    type: 'FETCH_EVENT_BY_ID',
    eventId,
  };
}
export function fetchEventByIdFollow(eventId) {
  return {
    type: 'FETCH_EVENT_BY_IDD',
    eventId,
  };
}

export function fetchEventByIdDone(data) {
  return {
    type: 'FETCH_EVENT_BY_ID_DONE',
    data,
  };
}
export function createEvent(data) {
  return {
    type: 'CREATE_EVENT',
    data,
  };
}

export function createEventInvitation(data) {
  return {
    type: 'CREATE_EVENT_INVITATION',
    data,
  };
}

export function createArchivedEvent(data) {
  return {
    type: 'CREATE_ARCHIVED_EVENT',
    data,
  };
}
export function createArchivedEventDone(data) {
  return {
    type: 'CREATE_ARCHIVED_EVENT_DONE',
    data,
  };
}

export function createEventDone(data) {
  return {
    type: 'CREATE_EVENT_DONE',
    data,
  };
}

export function createEventInvitationDone(data) {
  return {
    type: 'CREATE_EVENT_INVITATION_DONE',
    data,
  };
}

export function deleteEvent(eventId) {
  return {
    type: 'DELETE_EVENT',
    eventId,
  };
}
export function deleteEventDone(data) {
  return {
    type: 'DELETE_EVENT_DONE',
    data,
  };
}
export function editEvent(eventId, data) {
  return {
    type: 'EDIT_EVENT',
    eventId,
    data,
  };
}
export function editEventDone(data) {
  return {
    type: 'EDIT_EVENT_DONE',
    data,
  };
}
export function addEventInvites(data) {
  return {
    type: 'ADD_EVENT_INVITES',
    data,
  };
}
export function createEventInvites(eventId, data) {
  return {
    type: 'CREATE_EVENT_INVITES',
    eventId,
    data,
  };
}
export function createEventInvitesDone(data) {
  return {
    type: 'CREATE_EVENT_INVITES_DONE',
    data,
  };
}
export function fetchContactList() {
  return {
    type: 'FETCH_CONTACT_LIST',
  };
}
export function fetchContactListDone(data) {
  return {
    type: 'FETCH_CONTACT_LIST_DONE',
    data,
  };
}
export function addEmailDetails(data) {
  return {
    type: 'ADD_EMAIL_DETAILS',
    data,
  };
}
export function createEmailDone(data) {
  return {
    type: 'CREATE_EMAIL_DONE',
    data,
  };
}
export function createRspv(data) {
  return {
    type: 'CREATE_RSPV_EVENT',
    data,
  };
}
export function createRspvEventDone(data) {
  return {
    type: 'CREATE_RSPV_EVENT_DONE',
    data,
  };
}
export function getRspv(data) {
  return {
    type: 'GET_RSPV_EVENT',
    data,
  };
}
export function addEventDoneImage(data, imageFile) {
  return {
    type: 'ADD_EVENT_IMAGE_DONE',
    data,
    imageFile,
  };
}
export function getEventDoneImage(data) {
  return {
    type: 'GET_EVENT_IMAGE_DONE',
    data,
  };
}
export function fetchEventImageByIdDone(data) {
  return {
    type: 'FETCH_EVENT_IMAGE_BY_ID_DONE',
    data,
  };
}
export function fetchMemoryById(id) {
  return {
    type: 'FETCH_MEMORY_BY_ID',
    id,
  };
}
export function addMEmoriesComment(data) {
  return {
    type: 'ADD_MEMORIES_COMMENT',
    data,
  };
}
export function createMemoriesLike(data) {
  return {
    type: 'CREAT_MEMORIES_LIKE',
    data,
  };
}
export function deleteMemoriesEvent(data) {
  return {
    type: 'DELETE_MEMORIES_EVENT',
    data,
  };
}
export function hideMemoriesEvent(data) {
  return {
    type: 'HIDE_MEMORIES_EVENT',
    data,
  };
}
export function deleteMemoriesAlbum(data) {
  return {
    type: 'DELETE_MEMORIES_ALBUM',
    data,
  };
}
