import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IoIosLock } from 'react-icons/io';

import { Mobile } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import AvatarUpload from '~/components/AvatarUpload';
import Input from '~/components/Input';
import DatePicker from '~/components/DatePicker';
import Button from '~/components/Button';
import { format } from 'date-fns';
// Action
import { updateProfileRequest } from '~/store/modules/user/userAction';

import {
  Container,
  InputWrapper,
  PrivacyNotice,
  RadioControl,
  RadioButton,
  InputRow,
  InputValidation,
} from './styles';

import {
  uploadProfileImage,
  fetchUserById,
} from '~/store/modules/user/userAction';

export default function Welcome() {
  const Dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.profile);
  const [gender, setGender] = useState(user.gender === null ? '' : user.gender);
  const [social] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(user.dob || new Date());
  const [city, setCity] = useState(user.city === null ? '' : user.city);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [formError, setFormError] = useState(null);

  if (user && user.account_status === 'ACTIVE') {
    history.push('/my-profile');
  }
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleDateOfBirthChange = (newDate) => {
    setDateOfBirth(newDate);
  };

  const handleSubmit = () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      dob: dateOfBirth,
      city,
      gender,
    };
    const error = validateForm();
    if (error !== 'validationFailed') {
      Dispatch(updateProfileRequest(user.id, data));
      history.push('/onboarding');
    }
  };

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 2000);

  const validateForm = () => {
    if (firstName === '') {
      setFormError(`validation failed: first name required!`);
      return 'validationFailed';
    }
    if (lastName === '' || lastName === null) {
      setFormError(`validation failed: last name required!`);
      return 'validationFailed';
    }
    if (city === '') {
      setFormError(`validation failed: location required!`);
      return 'validationFailed';
    }
    if (gender === '') {
      setFormError(`validation failed: gender required!`);
      return 'validationFailed';
    }
  };

  const handleProfileImage = (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    Dispatch(uploadProfileImage(user.id, formData));
  };

  return (
    <>
      <SubHeader
        buttonBackgroundColor="#01e3fa"
        buttonText="Next"
        buttonTextColor="#142484"
        mobileNavButtons={false}
        buttonFunction={() => handleSubmit()}
      >
        <h2>Welcome {firstName}</h2>
        <h3>
          Let’s finish your profile and get started.
          {social && ' All\xa0fields\xa0are\xa0optional.'}
        </h3>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        <form onSubmit={handleSubmit}>
          <AvatarUpload
            image={user && user.user_image}
            gender={gender}
            onChange={handleProfileImage}
          />
          <InputRow social={social}>
            {social || (
              <>
                <InputWrapper>
                  <Input
                    label="First Name"
                    name="first-name"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Input
                    label="Surname"
                    name="surname"
                    placeholder="Surname"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </InputWrapper>
              </>
            )}
            <InputWrapper>
              <DatePicker
                label="Date of Birth"
                name="birth-date"
                date={dateOfBirth}
                onChange={handleDateOfBirthChange}
                maxDate={new Date()}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                label="City Where You Live"
                name="city"
                placeholder="Mosman NSW 2088"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </InputWrapper>
          </InputRow>

          <RadioControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={gender}
              onChange={handleChangeGender}
            >
              <FormControlLabel
                value="F"
                control={<RadioButton />}
                label="Female"
              />
              <FormControlLabel
                value="M"
                control={<RadioButton />}
                label="Male"
              />
              <FormControlLabel
                value="O"
                control={<RadioButton />}
                label="Other"
              />
            </RadioGroup>
          </RadioControl>
        </form>
        <PrivacyNotice>
          <IoIosLock size={25} color="#888888" />
          <span>
            Your information is private and visible only to friends who are
            invited to the events you have organised.
          </span>
        </PrivacyNotice>
        <Mobile>
          <Button
            text="Next"
            backgroundColor="#01e3fa"
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
    </>
  );
}
