import styled from 'styled-components';
// import { lighten } from 'polished';
// import FormControl from '@material-ui/core/FormControl';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 50px 25px 25px;
  align-items: center;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 57px);
    align-items: center;
    display: flex;
    justify-content: center;

    button {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }

  button {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .no-thanks {
    text-transform: none;
    font-weight: normal;
    font-size: 17px;
    margin: 0 auto;
    line-height: 19px;
    color: #888;
    background: none;
    border: 0;
    cursor: pointer;

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    text-align: center;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  h2 {
    color: #666;
    width: 100%;
    text-align: center;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label,
  .label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  input,
  textarea {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }

  textarea {
    min-height: 400px;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 767px) {
      min-height: 415px;
    }
  }

  p {
    color: #888;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 15px;
  }

  .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    text-transform: none;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
    width: 100%;
  }
`;

export const BankAccount = styled.div`
  background: #fff;
  padding: 20px;
  font-weight: bold;
  color: #666;
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;

  &:first-of-type {
    margin-top: 10px;
  }

  &:last-of-type {
    margin-bottom: 20px;
  }
`;
