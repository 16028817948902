import person1 from '~/assets/images/person1.jpg';

const contributor = {
  name: 'Brandon Marshall',
  avatar: person1,
  gift: {
    title: 'Mountain Bike',
    contributed: 50,
    receiver: 'Eugene',
    price: 250,
  },
};

export default contributor;
