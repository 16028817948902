import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 305px;

  svg {
    max-width: 70px;
    margin-bottom: 30px;
  }

  h2 {
    max-width: 260px;
  }

  p {
    padding: 20px 0;
    font-size: 18px;
    line-height: 1.2;
    max-width: 280px;

    &:last-of-type {
      border-bottom: 1px solid #fff;
      margin-bottom: 20px;
    }
  }

  p.eventarchived {
    padding: 20px 0;
    font-size: 18px;
    line-height: 1.2;
    max-width: 280px;

    &:last-of-type {
      border-bottom: 1px !important;
      margin-bottom: 0px !important;
    }
  }
  small {
    text-align: left;
    margin-right: auto;
    font-size: 14px;
  }
  svg.svgcss {
    margin-bottom: 0px !important;
  }
`;
