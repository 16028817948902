import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  margin: 20px auto 40px;
  width: 90%;
  background-color: #fff;
  padding: 0px;
  position: relative;
  align-items: center;

  @media (min-width: 768px) {
    padding: 20px;
    background-color: #f6f6f6;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eeee;
    padding: 20px;

    h1 {
      color: ${({ theme }) => theme.colors.pink};

      & + h3,
      & + p {
        margin-bottom: 20px;
        text-align: center;
      }
    }

    h3 {
      color: ${({ theme }) => theme.colors.purple};
      max-width: 200px;
      text-align: center;
    }

    textarea {
      width: 100%;
      border: 0;
      background-color: #f9f9f9;
      padding: 10px;
      font-size: 16px;
      line-height: 19px;
      border: 1px solid #f9f9f9;

      &.error {
        border-color: red;
      }
    }

    p {
      font-size: 16px;
      color: #666;
    }
  }

  button {
    margin-top: 20px;
    max-width: 400px;

    @media (max-width: 767px) {
      max-width: initial;
      position: absolute;
      bottom: -70px;
    }
  }
`;

export const UploadBox = styled.div`
  height: 200px;
  max-width: 400px;
  width: 100%;
  background-color: #ddd;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : ''};
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid #ddd;

  &.error {
    border-color: red;
  }

  .inputfile {
    display: none;

    & + label {
      display: flex;
      flex: 1;
      height: 100%;
      justify-content: space-around;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      color: #f1e5e6;
      background-color: ${(props) => props.backgroundColor};
      display: flex;
      align-items: center;
      margin: 0;
      width: ${(props) => `${props.size}px`};
      height: ${(props) => `${props.size}px`};
      transition: background-color 0.3s;

      > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;

        &:first-of-type {
          border-right: 1px solid white;
          flex: 1;
          min-height: 100px;
        }

        span {
          color: #888;
        }
      }
    }

    &:focus + label,
    &.has-focus + label,
    & + label:hover {
      outline: none;
    }
  }
`;
