import { apiAxiosInstance } from '../services/axios';
import { getJWTToken } from '../utils/utility';

const tokenData = JSON.parse(getJWTToken());
const headers = {};
if (tokenData && tokenData.token) {
  headers.Authorization = `Bearer ${tokenData.token}`;
}

export const getAddGift = async (data) => {
  try {
    const result = await apiAxiosInstance.post('gift', data, { headers });
    return result;
  } catch (err) {
    return err;
  }
};
export const getFetchGiftDetailsById = async (id) => {
  try {
    const result = await apiAxiosInstance.get(`gift/${id}`, { headers });
    return result;
  } catch (err) {
    return err;
  }
};
export const getUpdateGift = async (giftId, data) => {
  try {
    const result = await apiAxiosInstance.put(`gift/${giftId}`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const getDeleteGift = async (giftId) => {
  try {
    const result = await apiAxiosInstance.delete(`gift/${giftId}`, { headers });
    return result;
  } catch (err) {
    return err;
  }
};

export const getContribution = async (giftId, user_id) => {
  try {
    const result = await apiAxiosInstance.get(
      `gift/${giftId}/contributionbyid?user_id=${user_id}`,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};
export const getContributionMultiple = async (giftId, user_id) => {
  try {
    const result = await apiAxiosInstance.get(
      `gift/${giftId}/contributionbyidmultiple?user_id=${user_id}`,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const createThankyouGift = async (giftId, data) => {
  try {
    const result = await apiAxiosInstance.post(
      `gift/${giftId}/thankyou`,
      data,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const addThankyouCardImage = async (data) => {
  try {
    const result = await apiAxiosInstance.post(
      'gift/save_thankyou_card',
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};
export const getContributionByUserId = async (data) => {
  try {
    const result = await apiAxiosInstance.post(
      `gift/get_contribution_id_by_userid`,
      data,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};
export const getThankyouContribution = async (giftId) => {
  try {
    const result = await apiAxiosInstance.get(
      `gift/${giftId}/thankyou_contributer`,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};
