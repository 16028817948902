import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 15px;
    margin: 0;
    grid-template-columns: auto;
  }
`;

export const Card = styled.div`
  background: #fff;
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  max-height: 115px;
  cursor: pointer;

  > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }

  svg {
    margin-right: -10px;
  }

  small {
    color: #aeaeae;
    font-size: 13px;
  }
`;
