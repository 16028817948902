export function addGift(data,imageFile) {
  return {
    type: 'ADD_GIFT',
    data,
    imageFile,
  };
}
export function addGiftDone(giftId) {
  return {
    type: 'ADD_GIFT_DONE',
    giftId,
  };
}
export function createGift(data, imageFile) {
  return {
    type: 'CREATE_GIFT',
    data,
    imageFile,
  };
}
export function createGiftDone(boolean) {
  return {
    type: 'CREATE_GIFT_DONE',
    boolean,
  };
}
export function fetchGiftDetailsById(id) {
  return {
    type: 'FETCH_GIFT_DETAILS_BY_ID',
    id,
  };
}
export function fetchGiftDetailsByIdDone(data) {
  return {
    type: 'FETCH_GIFT_DETAILS_BY_ID_DONE',
    data,
  };
}
export function setShowNotice(boolean) {
  return {
    type: 'SET_SHOW_NOTICE',
    boolean,
  };
}
export function updateGift(giftId, data) {
  return {
    type: 'UPDATE_GIFT',
    giftId,
    data,
  };
}
export function updateGiftDone(data) {
  return {
    type: 'UPDATE_GIFT_DONE',
    data,
  };
}
export function deleteGift(giftId) {
  return {
    type: 'DELETE_GIFT',
    giftId,
  };
}
export function deleteGiftDone(data) {
  return {
    type: 'DELETE_GIFT_DONE',
    data,
  };
}

export function uploadImageAction(giftId, data) {
  return {
    type: 'UPLOAD_GIFT_IMAGE',
    giftId,
    data,
  };
}
