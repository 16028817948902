import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';

import { Mobile, Default } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';

import { Container, InputWrapper, OpenGift } from './styles';

export default function ContributionThanks() {
  const { colors } = useContext(ThemeContext);
  const { path } = useParams();
  const wontAttend = path === 'wont-attend';
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const handleSubmit = () => {
    history.push('/rsvp-confirmed');
  };

  return (
    <>
      <SubHeader backgroundColor={colors.lightPurple} mobileDescVisible={false}>
        <Default>
          <h2>Thanks for your contribution to Eugene’s gifts.</h2>
        </Default>
        <Mobile>
          <h2>RSVP</h2>
        </Mobile>
        <h3>
          {wontAttend
            ? 'Please enter your name and/or email so we can notify the event orgoniser.'
            : 'Lastly, please enter your name and/or email so we can notify the event organiser of your RSVP.'}
        </h3>
      </SubHeader>
      <Container>
        <OpenGift />
        <Mobile>
          <h2>Thanks for your contribution towards Eugene’s gifts.</h2>
          <h3>
            {wontAttend
              ? 'Please enter your name and/or email so we can notify the event orgoniser.'
              : 'Lastly, please enter your name and/or email so we can notify the event organiser of your RSVP.'}
          </h3>
        </Mobile>
        <form>
          <InputWrapper>
            <Input
              label="Your Name"
              name="name"
              placeholder="name"
              type="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label="Your Email"
              name="email"
              placeholder="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapper>

          <Button
            text={
              wontAttend ? 'CONFIRM I’M NOT ATTENDING' : 'Confirm my Attendance'
            }
            backgroundColor="#01e3fa"
            buttonFunction={() => handleSubmit()}
          />
        </form>
      </Container>
    </>
  );
}
