import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { IoIosCamera, IoIosVideocam } from 'react-icons/io';

import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import { Container, UploadBox } from './styles';
import { useSelector } from 'react-redux';
import { addThankyouCardImage, createThankyouGift } from '~/api/giftAPI';
import CircularIndeterminate from '~/components/Loader';
import { uploadGiftCardImage } from '~/api/imageAPI';

export default function CreateThankYouCards() {
  const [text, setText] = useState('');
  const { page } = useParams();
  const [errors, setErrors] = useState({ textarea: false, background: false });
  const [backgroundImage, setBackgroundImage] = useState('');
  const location = useLocation();
  const funded = location && location.state ? location.state.funded : false;
  const getData = location && location.gift;
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [loader, setLoader] = useState(false);
  const [selectFile, setSelectFile] = useState('');

  // Parse the JSON string
  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : JSON.parse(getData && getData.data);
  const giftId =
    parsedData.giftId || (notificationData && notificationData.gift_id);
  const contributionId = parsedData.contributionId;
  const childName = parsedData.child_name;
  const parentName = parsedData.parentName;
  const giftName = parsedData.giftName;
  const targetReached = localStorage.getItem('targetReached');
  const profile = useSelector((state) => state.userReducer.profile);

  const getcontributionIds = localStorage.getItem('contributionIds');
  const handleUpload = async (e) => {
    setBackgroundImage(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    setSelectFile(file);
    setErrors({ ...errors, background: false });
  };

  const handleShowPreview = async () => {
    if (text && backgroundImage) {
      history.push({
        pathname: '/create-thank-you-cards/preview',
        state: { funded },
      });
    } else {
      setErrors({
        textarea: !text,
        background: !backgroundImage,
      });
    }
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    setErrors({ ...errors, textarea: false });
  };

  const handleButtonText = () => (funded ? 'Next' : 'Send');

  const handleButtonFunction = async () => {
   
    if (idsArray && idsArray) {
      if (targetReached === 'yes') {
        const fileData = {
          selectFile: selectFile,
          giftId: giftId,
          description: text,
        };
        history.push({ pathname: '/add-recipients', fileData });
      } else {
        setLoader(true);
        const fileData = {
          selectFile: selectFile,
          giftId: giftId,
          description: text,
        };
        history.push({ pathname: '/add-recipients', fileData });
        // const promises = idsArray.map(async (id) => {
        //   const payload = {
        //     description: text,
        //     user_id: notificationData.user_id,
        //     sender_id: notificationData.sender_id,
        //     contributer_id: id,
        //   };

        //   try {
        //     const res = await createThankyouGift(giftId, payload);
        //     const formData = new FormData();
        //     formData.append('file', selectFile);
        //     const ea = await uploadGiftCardImage(giftId, formData);
        //     const payloadForCardImage = {
        //       card_id: res.data.id,
        //       url: ea.data,
        //     };
        //     await addThankyouCardImage(payloadForCardImage);
        //     return true; // or any value indicating success
        //   } catch (err) {
        //     console.log('err', err);
        //     return false; // or any value indicating failure
        //   }
        // });

        // try {
        //   const results = await Promise.all(promises);

        //   // Check if all promises were successful
        //   const allSuccessful = results.every((result) => result);

        //   if (allSuccessful) {
        //     // Run history.push after all promises have resolved successfully
        //     history.push(
        //       funded
        //         ? '/add-recipients'
        //         : { pathname: '/thank-you-sent', giftId },
        //     );
        //   } else {
        //     // Handle the case where at least one promise failed
        //     console.log('One or more promises failed');
        //   }
        // } catch (error) {
        //   console.log('Error in Promise.all:', error);
        // } finally {
        //   setLoader(false);
        // }
      }
    } else {
      if (targetReached === 'yes') {
        const fileData = {
          selectFile: selectFile,
          giftId: giftId,
          description: text,
        };
        history.push({ pathname: '/add-recipients', fileData });
      } else {
        setLoader(true);
        const payload = {
          description: text,
          user_id: notificationData.sender_id,
          sender_id: profile.id,
          contributer_id: contributionId,
        };
        await createThankyouGift(giftId, payload)
          .then(async (res) => {
            const formData = new FormData();
            formData.append('file', selectFile);
            await uploadGiftCardImage(giftId, formData)
              .then(async (ea) => {
                const payload1 = {
                  card_id: res.data.id,
                  url: ea.data,
                };
                await addThankyouCardImage(payload1)
                  .then((res) => {
                    setLoader(false);
                    history.push(
                      funded
                        ? '/add-recipients'
                        : { pathname: '/thank-you-sent', giftId },
                    );
                  })
                  .catch((err) => {
                    console.log('err', err);
                    setLoader(false);
                  });
              })
              .catch((err) => {
                console.log('err', err);
                setLoader(false);
              });
          })
          .catch((err) => {
            console.log('err', err);
            setLoader(false);
          });
      }
    }
  };
  const idsArray =
    getcontributionIds &&
    getcontributionIds.split(',').map((id) => parseInt(id.trim()));

  return (
    <>
      <SubHeader mobileDescVisible={false}>
        <h2>
          {page === 'preview' ? 'Review Your Card' : 'Write Your Message'}
        </h2>
      </SubHeader>
      <Container>
        <UploadBox
          backgroundImage={backgroundImage}
          className={errors.background ? 'error' : ''}
        >
          <input
            type="file"
            name="file[]"
            id="file"
            className="inputfile"
            data-multiple-caption="{count} files selected"
            multiple=""
            onChange={(e) => handleUpload(e)}
          />
          <label htmlFor="file">
            {backgroundImage ? (
              ''
            ) : (
              <>
                <span>
                  <IoIosCamera size={60} color="#888" />
                  <span>Add Photo</span>
                </span>
                <span>
                  <IoIosVideocam size={60} color="#888" />
                  <span>Add Video</span>
                </span>
              </>
            )}
          </label>
        </UploadBox>
        <div className="content">
          {funded && <h3>Gift 100% funded!</h3>}
          <h1>
            {funded ? 'Thanks Again' : `Thank You ${parentName && parentName}`}
          </h1>
          {funded ? (
            <p>
              for your contribution towards {childName && childName}
              &apos;s {giftName && giftName}.
            </p>
          ) : (
            <h3>
              for your contribution towards {childName && childName}
              &apos;s {giftName && giftName}.
            </h3>
          )}
          {page === 'preview' ? (
            <p>{text}</p>
          ) : (
            <textarea
              rows={5}
              placeholder="Here's where you write a personalized message thanking your contributor."
              value={text}
              onChange={(e) => handleTextChange(e)}
              className={errors.textarea ? 'error' : ''}
            />
          )}
        </div>
        {!loader ? (
          <Button
            text={page === 'preview' ? handleButtonText() : 'Preview'}
            buttonFunction={
              page === 'preview' ? handleButtonFunction : handleShowPreview
            }
          />
        ) : (
          <button type="submit" className="sendcard">
            <CircularIndeterminate />
          </button>
        )}
      </Container>
    </>
  );
}

CreateThankYouCards.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      funded: PropTypes.bool,
    }),
  }).isRequired,
};
