import styled from 'styled-components';

export const Container = styled.div`
  width: 30%;
  margin-top: 100px;

  @media (max-width: 767px) {
    width: 90%;
    margin-top: 40px;
  }

  > div {
    width: 70%;
    margin: 30px auto 0;
    color: white;
    min-width: 200px;
  }

  img {
    cursor: pointer;

    &.menu-icon {
      max-width: 20px;
      margin-right: 10px;
    }
  }

  .location {
    display: flex;
    align-items: center;
    color: #70f6fc;
    font-size: 11px;
    text-transform: uppercase;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }
  }

  ul li {
    cursor: pointer;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 10px;
    cursor: pointer;
  }
`;

export const Badge = styled.div`
  background: #cf3c79;
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 20px;
`;
