import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import gift from './gift';

import SubHeader from '~/components/SubHeader';

import Button from '~/components/Button';
import { Container, ImageWrapper, Card } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCheckUserDOB } from '~/api/userAPI';

export default function BirthdayComing() {
  const { colors } = useContext(ThemeContext);
  const profile = useSelector((state) => state.userReducer.profile);

  const handleCheckDOB = async () => {
    await getCheckUserDOB(profile.id)
      .then((res) => {
        if (res.data.message === 'No event found!') {
          history.push(`/event-goal`, { receiver: res.data.receiver });
        } else if (res.data.message === 'Event found!') {
          history.push(`/event-details/${res.data.event_id}`, {
            childId: res.data.child_id,
          });
        } else if (res.data.message === 'DOB not matching') {
          history.push(`/add-receiver`);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <>
      <SubHeader backgroundColor={colors.purple} mobileDescVisible={false}>
        <h2>BIRTHDAY COMING</h2>
      </SubHeader>
      <Container>
        <ImageWrapper backgroundImage={profile && profile.user_image} />
        <div>
          <Card>
            <h2>
              Your birthday is coming up very soon&nbsp;
              {profile && profile.first_name}. How exciting!
            </h2>
          </Card>
          <div className="notice">
            <h3>
              Do you have a special gift you’d love to get for your birthday?{' '}
            </h3>
            <p>
              If so, let’s organise a birthday event for you! That way you can
              organise a party or simply share your gift wish or wishes with
              your loved ones.
            </p>
          </div>
          <div className="button-wrapper">
            <Button
              text=" CREATE A BIRTHDAY EVENT  "
              buttonFunction={handleCheckDOB}
            />
          </div>
        </div>
      </Container>
    </>
  );
}
