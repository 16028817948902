import styled from 'styled-components';

export const Modal = styled.div`
  width: auto;
  height: auto;
  background: white;
  display: none;
  position: absolute;
  padding: 40px;
  border-radius: 3px;
  z-index: 2;

  &.visible {
    display: block;
  }
  svg {
    position:initial;
    right: 5px;
    top: 2px;
    cursor: pointer;
  }
`;
export const Container = styled.div`
  display: flex;
`;