import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
// Action
import { deleteEvent } from '~/store/modules/event/eventAction';

import history from '~/services/history';
import Context from '~/context';

import { ReactComponent as TrashCan } from '~/assets/images/trash-can.svg';
import { ReactComponent as TrashCant } from '~/assets/images/trash-cant.svg';

import { Container } from './styles';

export default function DeleteAlbum({ hasDonations, eventId }) {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const receiver = useSelector((state) => state.receiverReducer);
  const { receiverId } = receiver;
  const eventReducer = useSelector((state) => state.eventReducer);
  const { isEventDeleted } = eventReducer;

  const handleArchiveEvent = () => {
    handleShowModal();
    history.push({
      pathname: '/success',
      state: {
        destination: `/receiver-profile/${receiverId}/`,
        message: 'Event Archived!',
        colors: { start: colors.lightPurple, end: colors.primary },
      },
    });
  };

  const handleDeleteEvent = () => {
    Dispatch(deleteEvent(eventId));
  };

  if (isEventDeleted) {
    handleShowModal();
    history.push({
      pathname: '/success',
      state: {
        destination: `/receiver-profile/${receiverId}/`,
        message: 'Event Deleted!',
        colors: { start: colors.lightPurple, end: colors.pink },
      },
    });
  }

  return (
    <Container>
      {hasDonations ? (
        <>
          <TrashCant />
          <h2>Sorry, unfortunately you can not delete this event</h2>
          <p>
            Given that your event has already received contributions
            fromfriends, you can no longer delete it
          </p>
          <p>
            Instead, we suggest you archive it. That way you can keep it out of
            your feed while still allowing your contributions to see it.
          </p>
          <button type="submit" onClick={() => handleShowModal()}>
            Cancel
          </button>
          <button
            type="submit"
            className="blue"
            onClick={() => handleArchiveEvent()}
          >
            Archive
          </button>
        </>
      ) : (
        <>
          <TrashCan />
          <h2>Are you sure you want to delete this event?</h2>
          <p>
            Once you delete an event, all information linked to it will be
            permanently deleted.
          </p>
          <p>
            Your invitees and contributors will no longer be able to view any
            information related to this event.
          </p>
          <p>
            We recommend archiving this event instead. That way you can retreive
            and re-use it in the future.
          </p>

          <button type="submit" onClick={() => handleShowModal()}>
            Cancel
          </button>
          <button
            type="submit"
            className="red"
            onClick={() => handleDeleteEvent()}
          >
            Delete
          </button>
        </>
      )}
    </Container>
  );
}
