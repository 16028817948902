import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  .date-picker .MuiInput-root {
    height: 40px;
  }

  input,
  textarea {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &.currency-input {
      width: 90%;
    }

    &::placeholder {
      color: #b7b7b7;
    }
  }

  textarea {
    min-height: 126px;
    font-size: 15px;
    line-height: 20px;
  }

  &.cost-row {
    flex-direction: row;
    align-items: flex-end;

    > div {
      flex-direction: column;

      &:first-child {
        width: 47%;

        label {
          display: block;
        }
      }

      &:last-child {
        width: 53%;
      }
    }
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 100%;
  }
`;

export const CheckboxControl = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
    margin-bottom: 15px;
  }

  .MuiTypography-root.MuiTypography-body1 {
    text-transform: none;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
  }
`;

export const ImageDiv = styled.div`
  display: flex;
`;

export const DeleteIcon = styled.div`
  font-size: 20px;
  color: white;
  background-color: red;
  border-radius: 20px;
  margin-left: -9px;
  margin-top: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;
