import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { IoIosCamera, IoIosVideocam } from 'react-icons/io';

import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import { Container, UploadBox } from './styles';
import { useSelector } from 'react-redux';
import { addThankyouCardImage, createThankyouGift } from '~/api/giftAPI';
import CircularIndeterminate from '~/components/Loader';
import { uploadGiftCardImage } from '~/api/imageAPI';

export default function SendThankYouCard() {
  const [text, setText] = useState('');
  const { page } = useParams();
  const [errors, setErrors] = useState({ textarea: false, background: false });
  const [backgroundImage, setBackgroundImage] = useState('');
  const location = useLocation();
  const funded = location && location.state ? location.state.funded : false;
  const getData = location && location.card;
  const [loader, setLoader] = useState(false);
  const [selectFile, setSelectFile] = useState('');
  const profile = useSelector((state) => state.userReducer.profile);

  const parsedData = localStorage.getItem('cardPendingData');
  const card_type = localStorage.getItem('type');
  const card = parsedData ? JSON.parse(parsedData) : getData && getData;

  // const targetReached = localStorage.getItem('targetReached');
  // const getcontributionIds = localStorage.getItem('contributionIds');

  const handleUpload = async (e) => {
    setBackgroundImage(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    setSelectFile(file);
    setErrors({ ...errors, background: false });
  };

  const handleShowPreview = async () => {
    if (text && backgroundImage) {
      history.push({
        pathname: '/send-thank-you-card/preview',
        state: { funded, getData, back: 'back' },
      });
    } else {
      setErrors({
        textarea: !text,
        background: !backgroundImage,
      });
    }
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    setErrors({ ...errors, textarea: false });
  };

  const handleButtonText = () => (funded ? 'Next' : 'Send');

  const handleButtonFunction = async () => {
    const giftId = card && card.contributor && card.contributor.gift_id;
    const contributionId = card && card.contributor && card.contributor.id;
    const user_id = card && card.users && card.users.id;

    if (card_type && card_type === 'multiple') {
      const fileData = {
        selectFile: selectFile,
        giftId: giftId,
        description: text,
      };

      history.push({ pathname: '/add-recipients', fileData });
    } else {
      setLoader(true);
      const payload = {
        description: text,
        user_id: user_id,
        sender_id: profile.id,
        contributer_id: contributionId,
      };

      await createThankyouGift(giftId, payload)
        .then(async (res) => {
          const formData = new FormData();
          formData.append('file', selectFile);
          await uploadGiftCardImage(giftId, formData)
            .then(async (ea) => {
              const payload = {
                card_id: res.data.id,
                url: ea.data,
              };
              await addThankyouCardImage(payload)
                .then((res) => {
                  setLoader(false);
                  history.push(
                    funded
                      ? '/add-recipients'
                      : { pathname: '/thank-you-sent', giftId, tab: 'tab3' },
                  );
                })
                .catch((err) => {
                  console.log('err', err);
                  setLoader(false);
                });
            })
            .catch((err) => {
              console.log('err', err);
              setLoader(false);
            });
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    }
  };

  return (
    <>
      <SubHeader
        mobileDescVisible={false}
        backButtonFunction={() =>
          card_type && card_type === 'multiple' && location.state !== undefined
            ? history.push('/send-thank-you-card')
            : card_type &&
              card_type === 'multiple' &&
              location.state === undefined
            ? history.push('/thank-you-cards')
            : history.goBack()
        }
      >
        <h2>
          {page === 'preview' ? 'Review Your Card' : 'Write Your Message'}
        </h2>
      </SubHeader>
      <Container>
        <UploadBox
          backgroundImage={backgroundImage}
          className={errors.background ? 'error' : ''}
        >
          <input
            type="file"
            name="file[]"
            id="file"
            className="inputfile"
            data-multiple-caption="{count} files selected"
            multiple=""
            onChange={(e) => handleUpload(e)}
          />
          <label htmlFor="file">
            {backgroundImage ? (
              ''
            ) : (
              <>
                <span>
                  <IoIosCamera size={60} color="#888" />
                  <span>Add Photo</span>
                </span>
                <span>
                  <IoIosVideocam size={60} color="#888" />
                  <span>Add Video</span>
                </span>
              </>
            )}
          </label>
        </UploadBox>
        <div className="content">
          {/* {funded && <h3>Gift 100% funded!</h3>} */}
          <h1>
            {funded
              ? 'Thanks Again'
              : `Thank You ${card && card.users && card.users.first_name}`}
          </h1>
          {funded ? (
            <p>
              for your contribution towards{' '}
              {(card && card.children && card.children.first_name) ||
                (card && card.children && card.children.group_name)}
              &apos;s {''}
              {card && card.giftDetails && card.giftDetails.title}.
            </p>
          ) : (
            <h3>
              for your contribution towards{' '}
              {(card && card.children && card.children.first_name) ||
                (card && card.children && card.children.group_name)}
              &apos;s {''}
              {card && card.giftDetails && card.giftDetails.title}.
            </h3>
          )}
          {page === 'preview' ? (
            <p>{text}</p>
          ) : (
            <textarea
              rows={5}
              placeholder="Here's where you write a personalized message thanking your contributor."
              value={text}
              onChange={(e) => handleTextChange(e)}
              className={errors.textarea ? 'error' : ''}
            />
          )}
        </div>
        {!loader ? (
          <Button
            text={page === 'preview' ? handleButtonText() : 'Preview'}
            buttonFunction={
              page === 'preview' ? handleButtonFunction : handleShowPreview
            }
          />
        ) : (
          <button type="submit" className="sendcard">
            <CircularIndeterminate />
          </button>
        )}
      </Container>
    </>
  );
}

SendThankYouCard.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      funded: PropTypes.bool,
    }),
  }).isRequired,
};
