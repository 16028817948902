import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import gift from './gift';

import SubHeader from '~/components/SubHeader';
import Avatar from '~/components/Avatar';
import profileImage from '~/assets/images/avatar-placeholder.png';

import Button from '~/components/Button';
import { Container, ContactList, Contact, SuccessCheckSVG } from './styles';
import { useSelector } from 'react-redux';
import CircularIndeterminate from '~/components/Loader';
import {
  getContributionByUserId,
  getFetchGiftDetailsById,
  getThankyouContribution,
} from '~/api/giftAPI';
import { convertUTCDateToString } from '~/utils/utility';

export default function GiftFunded() {
  const { colors } = useContext(ThemeContext);
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [loader, setLoader] = useState(false);
  const [giftData, setGiftData] = useState('');
  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : '';

  const childName = parsedData && parsedData.child_name;
  const senderId = parsedData.senderId;
  const parentName = parsedData.parentName;
  const giftId = notificationData && notificationData.gift_id;

  useEffect(() => {
    getUserDetail(giftId);
    localStorage.removeItem('contributionIds');
  }, [giftId]);

  const getUserDetail = async (id) => {
    setLoader(true);
    try {
      await getFetchGiftDetailsById(id).then((res) => {
        setGiftData(res.data);
        setLoader(false);
      });
    } catch (error) {
      console.log('err', error);
      setLoader(false);
    }
  };

  const handleSendCard = async () => {
    const gift = {
      giftData: giftData,
      senderId: senderId,
      childName: childName,
      userName: parentName ? parentName : 'Enguine',
      // contributer_id: contributionIdsString,
    };
    localStorage.setItem('contributionIds', contributionIdsString);
    history.push({
      pathname: '/create-thank-you-cards',
      gift,
      state: { funded: true },
    });
  };
  const contributionIds =
    giftData &&
    giftData.contributor_contributions &&
    giftData.contributor_contributions.map(
      (contribution) => contribution.contributionId,
    );

  // Joining contributionIds with commas
  const contributionIdsString = contributionIds && contributionIds.join(', ');

 
  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        mobileDescVisible={false}
       
      >
        <h2>Gift 100% Funded</h2>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <SuccessCheckSVG />
            <h2>
              {childName && childName}&apos; {giftData && giftData.name}
            </h2>
            <p>is now 100% funded</p>
            <ContactList>
              {giftData &&
                giftData.contributor_contributions &&
                giftData.contributor_contributions.map((contact) => (
                  <Contact key={contact.date}>
                    <div>
                      <div className="contact-info">
                        <Avatar
                          size={55}
                          image={
                            contact && contact.user.avatar
                              ? contact.user.avatar
                              : profileImage
                          }
                        />
                        <div>
                          <h3>{contact && contact.user.first_name}</h3>
                          <span>{convertUTCDateToString(contact.date)}</span>
                        </div>
                      </div>
                      <div className="donated-value">
                        <span>${contact && contact.donation}</span>
                      </div>
                    </div>
                  </Contact>
                ))}
            </ContactList>
            <Button
              text="Send Thank You Card To All"
              buttonFunction={
                () => handleSendCard()
                // history.push({
                //   pathname: '/create-thank-you-cards',
                //   state: { funded: true },
                // })
              }
            />
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}
