import React from 'react';

import { ReactComponent as PlayHappy } from '~/assets/images/play-happy.svg';
import { Container } from './styles';

export default function CelebratoryCard() {
  const cardExists = true;
  return cardExists ? (
    <Container>
      <h3>
        Eugene&apos;s celebratory card has 12 messages from friend and
        contributors. Watch it now!
      </h3>
      <PlayHappy />
    </Container>
  ) : (
    <Container>
      <h3 className="empty">
        There is no celebratory card for&nbsp;this&nbsp;event.
      </h3>
    </Container>
  );
}
