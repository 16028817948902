const bankAccounts = [
  {
    id: 1,
    number: '9234',
  },
  {
    id: 2,
    number: '1234',
  },
];

export default bankAccounts;
