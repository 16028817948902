import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 25px;
  display: flex;
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    width: 100%;
    padding: 20px;
    margin: 0;
    min-height: calc(100vh - 57px);
    justify-content: initial;
    flex-direction: column;
  }

  p {
    color: ${({ theme }) => theme.colors.lightPurple};
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  button {
    max-width: 100%;
    margin-bottom: 20px;
  }

  h2 {
    color: ${({ theme }) => theme.colors.lightPurple};
    text-align: center;
    margin-bottom: 20px;
  }

  small:first-of-type {
    color: #bfbfbf;
  }
`;

export const Card = styled.div`
  background: #fff;
  text-align: center;
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }

  span {
    color: #666;

    &.large {
      font-size: 30px;
      color: ${({ theme }) => theme.colors.purple};

      &.blue {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const Column = styled.div`
  max-width: 350px;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  > img {
    max-width: 100px;
    margin-bottom: 20px;
  }

  > div:first-of-type {
    margin-bottom: 30px;
  }
`;
