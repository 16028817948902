import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { darken } from 'polished';

import history from '~/services/history';

import Gifts from '../Gifts';
import Events from '../Events';
import Memories from '../Memories';
import SubHeader from '~/components/SubHeader';

import {
  Container,
  StyledTabs as Tabs,
  StyledTab as Tab,
  InputValidation,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArchivedEventById } from '~/store/modules/receiver/receiverAction';
import { unHideEvent, unHideGift } from '~/api/eventAPI';

function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ReceiverProfile() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { tab, userId } = useParams();
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [selectedGiftsIds, setSelectedGiftsIds] = useState([]);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [formError, setFormError] = useState(null);

  const validateForm = () => {
    if (selectedEventIds.length === 0) {
      setFormError(`validation failed: please select atleast one event!`);
      setSubmitButtonLoading(false);
      return 'validationFailed';
    }
  };
  const validateFormGift = () => {
    if (selectedGiftsIds.length === 0) {
      setFormError(`validation failed: please select atleast one gift!`);
      setSubmitButtonLoading(false);
      return 'validationFailed';
    }
  };
  const tabs = {
    gifts: (
      <Gifts
        selectedGiftsIds={selectedGiftsIds}
        setSelectedGiftsIds={setSelectedGiftsIds}
      />
    ),
    events: (
      <Events
        selectedEventIds={selectedEventIds}
        setSelectedEventIds={setSelectedEventIds}
      />
    ),
    memories: <Memories />,
  };
  const [activeTab, setActiveTab] = useState(tabs[tab] || tabs.gifts);
  const [value, setValue] = useState(tab ? Object.keys(tabs).indexOf(tab) : 0);

  const handleChange = (event, newValue) => {
    const tabName = Object.keys(tabs)[newValue];
    setActiveTab(tabs[tabName]);
    setValue(newValue);
  };

  const handleNext = async () => {
    setSubmitButtonLoading(true);
    if (value === 1) {
      const error = validateForm();
      if (error !== 'validationFailed') {
        const ids = {
          event_id: selectedEventIds,
        };
        await unHideEvent(ids)
          .then((res) => {
            if (res && res.data) {
              setSubmitButtonLoading(false);
              history.push({
                pathname: '/success',
                state: {
                  destination: `/app-settings`,
                  message: 'Items are now visible!',
                  colors: { start: colors.secondary, end: colors.primary },
                },
              });
            }
          })
          .catch((err) => {
            setSubmitButtonLoading(false);
            console.log('err', err);
          });
      }
    }
    if (value === 0) {
      const error = validateFormGift();
      if (error !== 'validationFailed') {
        const ids = {
          gift_id: selectedGiftsIds,
        };
        await unHideGift(ids)
          .then((res) => {
            if (res && res.data) {
              setSubmitButtonLoading(false);
              history.push({
                pathname: '/success',
                state: {
                  destination: `/app-settings`,
                  message: 'Gifts are now visible!',
                  colors: { start: colors.secondary, end: colors.primary },
                },
              });
            }
          })
          .catch((err) => {
            setSubmitButtonLoading(false);
            console.log('err', err);
          });
      }
    }
  };

  const handleEvent = () => {
    Dispatch(fetchArchivedEventById(userId));
  };

  useEffect(() => {
    if (value === 0) {
      handleEvent();
    }
  }, [value]);

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 3000);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonText="Unhide"
        buttonTextColor="#fff"
        buttonFunction={handleNext}
        submitButtonLoading={submitButtonLoading}
      >
        <h2>Archived Items</h2>
        <h3>
          Select the profile related to the item you’d like to make visible
          again
        </h3>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab
          label="Gifts"
          onClick={() => setActiveTab(tabs.gifts)}
          {...a11yProps(0)}
        />
        <Tab
          label="Events"
          onClick={() => setActiveTab(tabs.events)}
          {...a11yProps(1)}
        />
        <Tab
          label="Memories"
          onClick={() => setActiveTab(tabs.memories)}
          {...a11yProps(2)}
        />
      </Tabs>
      <Container>{activeTab}</Container>
    </>
  );
}
