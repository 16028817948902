import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 50px 25px 25px;
  align-items: center;
  position: relative;

  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    padding: 25px 20px 0;
    margin: 0;
    min-height: calc(100% - 135px);
    border-top: 20px solid #13297e;

    button {
      margin-top: 0;
    }
  }

  button {
    max-width: 100%;
    margin-bottom: 15px;
    margin-top: 17px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 100%;
  }
`;

export const SecureBankingBadge = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 20px;
  padding: 10px 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    background: ${({ theme }) => theme.colors.primary};
    margin-top: -45px;
  }

  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }

  svg {
    margin-right: 10px;
  }
`;

export const OrderTotal = styled.div`
  padding: 20px 20px 5px;
  font-size: 18px;
  color: #888;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &.total {
      font-weight: bold;
      font-size: 20px;

      > div {
        padding-top: 10px;
        &:last-of-type {
          border-top: 1px solid #d2d2d2;
          min-width: 80px;
          text-align: right;
        }
      }
    }
  }
`;

export const CardsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 40px;
    margin: 0 5px;
  }
`;
