import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';
import SubHeader from '~/components/SubHeader';
import { Container, GiftCard } from './styles';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

export default function PrivacyPolicies() {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
        mobileDescVisible={false}
      >
        <h2>Privacy Policy</h2>
      </SubHeader>
      <Container className="scroller">
        <GiftCard>
          <div>
            <br />
            <h3>Your privacy is important</h3>
            <br />
            <p className="paragraph">
              This privacy policy outlines how Purposit Australia Pty Ltd ACN
              611 311 267 (<b>we</b>, <b>our</b> or <b>us</b>) collects, uses
              and discloses personal information in relation to the use of the
              platform known as Purposit (<b>Platform</b>).
              <br /> <br />
              This privacy policy does not apply in respect of the collection,
              use or disclosure of information contained in employee records
              held by us where the collection, use or disclosure is directly
              related to a current or former employment relationship between us
              and an employee.
              <br /> <br />
            </p>
            <h3>The kinds of personal information we collect and hold</h3>
            <p className="paragraph">
              We collect and hold personal information about users of our
              Platform and persons whose information is uploaded onto the
              Platform by users (<b>you</b>).
              <br /> <br />
            </p>
            <p className="paragraph">
              The kinds of personal information we collect and hold may include
              your name, email address, residential address and other contact
              details, correspondence you send to us, billing information and
              information obtained when you use the Platform (whether via our
              website or our mobile application), including without limitation:
              <br />
              <br />
            </p>
            <div className="boxdot">
              <ul style={{ listStyleType: 'disc' }}>
                <li>
                  <p className="paratext">your username and password;</p>
                </li>
                <li>
                  <p className="paratext">your age and gender;</p>
                </li>
                <li>
                  <p className="paratext">
                    your activities on the Platform, including information that
                    you or others upload or post to the Platform, webpages and
                    advertisements on the Platform that you view, hyperlinks on
                    the Platform that you click on and search queries that you
                    conduct on the Platform; and
                  </p>
                </li>
                <li>
                  <p className="paratext">
                    your computer’s internet protocol address, your mobile
                    device’s identifier, standard server log information and
                    other information that we collect through the use of cookies
                    and other similar technologies (see below).
                  </p>
                </li>
              </ul>
            </div>
            <br />
            <p className="paragraph">
              When you use the Platform, or someone else uses the Platform and
              uploads or posts information about you to the Platform, we may
              also collect information or an opinion about your racial or ethnic
              origin, political opinions, membership of a political association,
              religious beliefs or affiliations, philosophical beliefs,
              membership of a professional or trade association, membership of a
              trade union, sexual orientation or practices, criminal record and
              health information (i.e. sensitive information).
              <br />
              <br />
            </p>
            <h3>Manner and purpose of collection of personal information</h3>
            <p className="paragraph">
              We will generally collect personal information about you by way of
              collecting information you or others upload onto the Platform,
              forms and other documents or information provided to us by you
              (including online), correspondence you provide to us and
              discussions with you.
              <br /> <br />
            </p>
            <p className="paragraph">
              We may also collect personal information about you from third
              parties. For example, we may collect personal information about
              you from service providers, related entities, business partners
              and other third parties, including, without limitation, third
              parties handling Platform support enquiries or providing payment
              processing or marketing services.
              <br /> <br />
            </p>
            <p className="paragraph">
              We will only collect, hold and use personal information (except
              sensitive information, see below) for the following purposes
              unless otherwise required or permitted by law or expressly
              approved by you:
              <br /> <br />
            </p>
            <div className="boxdot">
              <ul style={{ listStyleType: 'disc' }}>
                <li>
                  <p className="paratext">
                    to enable us to conduct and improve our business, including
                    to monitor and profile who is accessing and using the
                    Platform and to make information that you upload onto the
                    Platform available for viewing by other users of the
                    Platform;
                  </p>
                </li>
                <li>
                  <p className="paratext">
                    to communicate with you, provide customer support to you
                    (including trouble-shooting) and/or seek or receive feedback
                    from you regarding the Platform and any other services or
                    support we provide to you from time to time;
                  </p>
                </li>
                <li>
                  <p className="paratext">
                    to carry out marketing initiatives and analysis, including
                    without limitation by providing you with marketing materials
                    in relation to the Platform, our service offerings from time
                    to time, and our other functions and activities via any
                    medium including mail, telephone and email;
                  </p>
                </li>
                <li>
                  <p className="paratext">
                    to manage the payment and recovery of amounts payable to us
                    by you;
                  </p>
                </li>
                <li>
                  <p className="paratext">
                    to enforce the terms and conditions that govern the use of
                    the Platform and to comply with our legal obligations
                    including under applicable legislation and prevent illegal
                    activities; and
                  </p>
                </li>{' '}
                <li>
                  <p className="paratext">
                    for other purposes which are reasonably necessary in
                    connection with our ordinary functions and activities.
                  </p>
                </li>
              </ul>
            </div>
            <br />
            <p className="paragraph">
              We will only use sensitive information for the purposes for which
              it was provided or for a purpose directly related to such purpose,
              unless you agree otherwise or the use of such sensitive
              information is required or authorised by law.
              <br /> <br />
            </p>
            <p className="paragraph">
              If we are unable to collect personal information relating to you,
              we may be unable to provide you with access to the Platform or
              otherwise continue our relationship with you.
              <br /> <br />
            </p>
            <h3>Disclosure of your personal information</h3>
            <p className="paragraph">
              We may disclose personal information about you to the following
              types of entities if required in connection with the purposes
              listed above:
              <br /> <br />
            </p>
            <div className="boxdot">
              <ul style={{ listStyleType: 'disc' }}>
                <li>
                  <p className="paratext">other users of the Platform;</p>
                </li>
                <li>
                  <p className="paratext">
                    our associates and related entities and other third parties
                    who provide us with services in connection with our business
                    or the Platform generally;
                  </p>
                </li>
                <li>
                  <p className="paratext">
                    any industry body, tribunal or court or otherwise in
                    connection with any complaint made by you about us; and
                  </p>
                </li>
                <li>
                  <p className="paratext">
                    any entity involved in a transfer of all or part of our
                    assets or businesses; and
                  </p>
                </li>
                <li>
                  <p className="paratext">
                    other entities with your consent or as permitted or required
                    by law.
                  </p>
                </li>
              </ul>
            </div>
            <br />
            <p className="paragraph">
              Information you upload to the Platform will be made available to
              other users of the Platform (including to users located overseas).
              By using the Platform, you consent to the disclosure of your
              information to others (including to those located overseas).
              <br /> <br />
            </p>
            <h3>How we hold personal information</h3>
            <p className="paragraph">
              We hold personal information in paper form and electronic form. We
              have in place steps to protect the information we hold from
              misuse, interference and loss and from unauthorised access,
              modification or disclosure. For example, only authorised employees
              and service providers are provided with access to personal
              information that has been provided to us for purposes other than
              uploading such information on the platform (e.g. your Platform
              account password).
              <br /> <br />
            </p>
            <h3>How to obtain access to your personal information</h3>
            <p className="paragraph">
              You may obtain access to personal information which we hold about
              you by contacting us using the contact details set out below. When
              you request copies of your personal information held by us we will
              endeavour to provide you with such personal information as soon as
              reasonably practicable.
              <br /> <br />
            </p>
            <p className="paragraph">
              We may charge you a fee in relation to identifying and providing
              access to your personal information on a per-request basis. We may
              require you to verify your identity and specify what information
              you require before completing a request for access. There may be
              occasions when access to personal information we hold about you is
              denied. Such occasions would include where release of the
              information would have an unreasonable impact on the privacy of
              others.
              <br /> <br />
            </p>
            <h3>Accuracy and completeness of personal information</h3>
            <p className="paragraph">
              While we will endeavour to ensure that the personal information
              collected from you is up to date, accurate and complete, we will
              assume that any personal information provided by you is free from
              errors and omissions. You may request that we update or vary
              personal information that we hold about you using the contact
              details set out below.
              <br /> <br />
            </p>{' '}
            <h3>
              How to make a complaint about a breach of your privacy rights
            </h3>
            <p className="paragraph">
              If you are of the view we have breached the Australian Privacy
              Principles or any related privacy code in dealing with your
              personal information, you may make a complaint by writing to us
              using the contact details set out below and we will endeavour to
              provide you with confirmation as to how we propose to deal with
              the complaint as soon as reasonably practicable.
              <br /> <br />
            </p>
            <p className="paragraph">
              If you are not satisfied with our response to your complaint, you
              may make a complaint to the Office of the Australian Information
              Commissioner by visiting the following website and following the
              relevant steps: http://www.oaic.gov.au/privacy/privacy-complaints.
              <br /> <br />
            </p>
            <h3>Direct marketing communications</h3>
            <p className="paragraph">
              From time to time we may use your personal information (except
              sensitive information) to provide you with marketing materials in
              relation to the Platform, our business or our service offerings
              from time to time. If you would not like to receive direct
              marketing materials from us you may notify us using the contact
              details set out below.
              <br /> <br />
            </p>{' '}
            <h3>Cookies and other website related information</h3>
            <p className="paragraph">
              A cookie is a data file that a website transfers to your computer.
              This enables the website to track the pages you have visited. A
              cookie only contains information you supply. It cannot read data
              on your computer. The Platform may use cookies. You can set your
              browser to refuse cookies, however, this may mean you are unable
              to take full advantage of the Platform.
              <br /> <br />
            </p>
            <p className="paragraph">
              Our internet server may also automatically record details about
              any computer used to access the website (such as the IP address,
              domain name and browser type), the date and time of access, and
              details of the information downloaded. This information is used
              for internal statistical purposes and to improve the Platform. Any
              other information supplied to us (for example if you send us an
              email) is treated in accordance with this privacy policy.
              <br /> <br />
            </p>
            <h3>Links to external websites</h3>
            <p className="paragraph">
              The Platform may contain hyperlinks to third party websites. This
              privacy policy does not apply to those websites. We suggest that
              you consider the privacy policies set out on any website that you
              access after following a link from the Platform.
              <br /> <br />
            </p>{' '}
            <h3>Information about others</h3>
            <p className="paragraph">
              Before uploading personal information about another person to the
              Platform, or otherwise providing personal information to us, you
              must obtain that person’s consent to do so. If that person is
              under the age of 15 years, you must obtain the consent of that
              person’s parent or guardian. Unless we are notified otherwise, we
              will assume that all information about another person that you
              upload to the Platform, or otherwise provide to us, has been
              uploaded or provided to us with that person’s consent or, where
              that person is under the age of 15 years, the consent of that
              person’s parent or guardian.
              <br /> <br />
            </p>
            <h3>Information about children</h3>
            <p className="paragraph">
              If we become aware that we have obtained information about a
              person under the age of 15 without the consent of a parent or
              guardian of that person, we will remove that information from the
              Platform and destroy all records of the information that we hold.
              If you believe that information about a person under the age of 15
              years is available on the Platform, or that we otherwise hold such
              information, without the consent of a parent or guardian of that
              person, you can notify us using the contact details set out below.
              <br /> <br />
            </p>{' '}
            <h3>Variation of policy</h3>
            <p className="paragraph">
              We reserve the right to change this privacy policy from time to
              time to take account of new laws and technology, changes to our
              functions and activities and to make sure it remains appropriate.
              The updated policy will be available on our website.
              <br /> <br />
            </p>
            <h3>Contact details</h3>
            <p className="paragraph">
              Please direct all complaints, requests and queries in relation to
              your privacy to: Privacy Officer, Purposit Australia on e-mail{' '}
              <br />
              <a
                href="mailto:admin@purposit.com"
                target="_blank"
                rel="noopener noreferrer"
                className="linktext"
              >
                admin@purposit.com
              </a>
              .
              <br /> <br />
            </p>
          </div>
        </GiftCard>
      </Container>
    </>
  );
}
