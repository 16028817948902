import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  getEvent,
  getEventById,
  createEvent,
  deleteEvent,
  getEditevent,
  GetCreateEventInvites,
  getContactList,
  createEventInvitation,
  saveEmailDetail,
  createArchiveEvent,
  getEventByIdd,
  createRspvEvent,
  getRSPVEvent,
  addcreateEventImage,
  getEventImageDone,
  getMomoriesById,
  createMemoriesComment,
  createMemoriesLike,
  deleteMemoriesEvent,
  hideMemoriesEvent,
  deleteMemoriesAlbum,
} from '../../../api/eventAPI';
import {
  fetchAllEventDone,
  fetchEventByIdDone,
  createEventDone,
  createEmailDone,
  deleteEventDone,
  editEventDone,
  createEventInvitesDone,
  fetchContactListDone,
  createEventInvitationDone,
  createArchivedEventDone,
  createRspvEventDone,
  fetchEventImageByIdDone,
} from '../event/eventAction';

export function* watchGetEvent({ id }) {
  try {
    const result = yield call(getEvent(id));
    yield put(fetchAllEventDone(result));
  } catch (err) {
    return err;
  }
}

export function* watchGetEventById({ eventId }) {
  try {
    const result = yield call(getEventById, eventId);
    yield put(fetchEventByIdDone(result));
  } catch (err) {
    return err;
  }
}

export function* watchGetEventByIdd({ eventId }) {
  try {
    const result = yield call(getEventByIdd, eventId);
    yield put(fetchEventByIdDone(result));
  } catch (err) {
    return err;
  }
}

export function* watchCreateEvent({ data }) {
  try {
    const result = yield call(createEvent, data);
    if (result.data.id) {
      yield put(createEventDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchCreateEventInvitation({ data }) {
  try {
    const result = yield call(createEventInvitation, data);
    if (result.data.id) {
      yield put(createEventInvitationDone(result));
    }
  } catch (err) {
    return err;
  }
}
export function* watchCreateArchivedEvent({ data }) {
  try {
    const result = yield call(createArchiveEvent, data);
    if (result.data) {
      yield put(createArchivedEventDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchDeleteEvent({ eventId }) {
  try {
    const result = yield call(deleteEvent, eventId);
    if (result.status === 200) {
      yield put(deleteEventDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetEditEvent({ eventId, data }) {
  try {
    const result = yield call(getEditevent, eventId, data);
    if (result.status === 200) {
      yield put(editEventDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetCreateEventInvites({ eventId, data }) {
  try {
    const result = yield call(GetCreateEventInvites, eventId, data);
    if (result.status === 200) {
      yield put(createEventInvitesDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetContactList() {
  try {
    const result = yield call(getContactList);
    if (result.status === 200) {
      yield put(fetchContactListDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* saveEmailData({ data }) {
  try {
    const result = yield call(saveEmailDetail, data);
    if (result.data) {
      yield put(createEmailDone(result.data));
    }
  } catch (err) {
    return err;
  }
}
export function* watchCreateRspvEvent({ data }) {
  try {
    const result = yield call(createRspvEvent, data);
    if (result) {
      yield put(createRspvEventDone(result.data));
    }
  } catch (err) {
    return err;
  }
}

export function* getRspvEvent({ data }) {
  try {
    const result = yield call(getRSPVEvent, data);
    if (result) {
      yield put(createRspvEventDone(result.data));
    }
  } catch (err) {
    return err;
  }
}

export function* watchCreateEventDoneImage({ data, imageFile }) {
  try {
    const result = yield call(addcreateEventImage, data, imageFile);
    if (result) {
      const result = yield call(getEventImageDone, data);
      yield put(fetchEventImageByIdDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchGetEventDoneImage(data) {
  try {
    const result = yield call(getEventImageDone, data.data);
    yield put(fetchEventImageByIdDone(result));
  } catch (err) {
    return err;
  }
}

export function* watchGetMemoriesById({ id }) {
  try {
    const result = yield call(getMomoriesById, id);
    yield put(fetchEventImageByIdDone(result));
  } catch (err) {
    return err;
  }
}

export function* watchAddMemoriesComment({ data }) {
  try {
    const result = yield call(createMemoriesComment, data);
    if (result) {
      const result = yield call(getMomoriesById, data.child_id);
      yield put(fetchEventImageByIdDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchAddMemoriesLike({ data }) {
  try {
    const result = yield call(createMemoriesLike, data);
    if (result) {
      const result = yield call(getMomoriesById, data.child_id);
      yield put(fetchEventImageByIdDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchgetMemorieslikes(id) {
  try {
    const result = yield call(getMomoriesById, id);
    yield put(fetchEventImageByIdDone(result));
  } catch (err) {
    return err;
  }
}

export function* watchDeleteMemoriesEvent({ data }) {
  try {
    const result = yield call(deleteMemoriesEvent, data);
    if (result) {
      const result = yield call(getMomoriesById, data.child_id);
      yield put(fetchEventImageByIdDone(result));
    }
  } catch (err) {
    return err;
  }
}
export function* watchHideMemoriesEvent({ data }) {
  try {
    const result = yield call(hideMemoriesEvent, data);
    if (result) {
      const result = yield call(getMomoriesById, data.child_id);
      yield put(fetchEventImageByIdDone(result));
    }
  } catch (err) {
    return err;
  }
}

export function* watchHideMemoriesAlbum({ data }) {
  try {
    const result = yield call(deleteMemoriesAlbum, data);
    if (result) {
      const result = yield call(getMomoriesById, data.child_id);
      yield put(fetchEventImageByIdDone(result));
    }
  } catch (err) {
    return err;
  }
}


export default all([
  takeLatest('FETCH_ALL_EVENT', watchGetEvent),
  takeLatest('FETCH_EVENT_BY_ID', watchGetEventById),
  takeLatest('FETCH_EVENT_BY_IDD', watchGetEventByIdd),
  takeLatest('CREATE_EVENT', watchCreateEvent),
  takeLatest('CREATE_EVENT_INVITATION', watchCreateEventInvitation),
  takeLatest('CREATE_ARCHIVED_EVENT', watchCreateArchivedEvent),
  takeLatest('ADD_EMAIL_DETAILS', saveEmailData),
  takeLatest('DELETE_EVENT', watchDeleteEvent),
  takeLatest('EDIT_EVENT', watchGetEditEvent),
  takeLatest('CREATE_EVENT_INVITES', watchGetCreateEventInvites),
  takeLatest('FETCH_CONTACT_LIST', watchGetContactList),
  takeLatest('CREATE_RSPV_EVENT', watchCreateRspvEvent),
  takeLatest('GET_RSPV_EVENT', getRspvEvent),
  takeLatest('ADD_EVENT_IMAGE_DONE', watchCreateEventDoneImage),
  takeLatest('GET_EVENT_IMAGE_DONE', watchGetEventDoneImage),
  takeLatest('FETCH_MEMORY_BY_ID', watchGetMemoriesById),
  takeLatest('ADD_MEMORIES_COMMENT', watchAddMemoriesComment),
  takeLatest('CREAT_MEMORIES_LIKE', watchAddMemoriesLike),
  takeLatest('DELETE_MEMORIES_EVENT', watchDeleteMemoriesEvent),
  takeLatest('HIDE_MEMORIES_EVENT', watchHideMemoriesEvent),
  takeLatest('DELETE_MEMORIES_ALBUM', watchHideMemoriesAlbum),
]);
