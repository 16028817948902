export function updateProfileRequest(userId, data) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    userId,
    data,
  };
}

export function updateProfileSuccess(data) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    data,
  };
}

export function updateProfileFailure() {
  return {
    type: '@user/UPDATE_PROFILE_FAILURE',
  };
}

export function fetchUserById(userId) {
  return {
    type: 'FETCH_USER_BY_ID',
    userId,
  };
}

export function followUserByEmail(email) {
  return {
    type: 'FETCH_FOLLOWUSER_BY_EMAIL',
    email,
  };
}

export function fetchUserByEmail(email) {
  return {
    type: 'FETCH_USER_BY_EMAIL',
    email,
  };
}

export function fetchUser() {
  return {
    type: 'FETCH_USER',
  };
}

export function fetchUserDone(data) {
  return {
    type: '@user/FETCH_USER_DONE',
    data,
  };
}

export function fetchUserByIdDone(data) {
  return {
    type: 'FETCH_USER_BY_ID_DONE',
    data,
  };
}

export function fetchFollowUserByIdDone(data) {
  return {
    type: 'FETCH_FOLLOWUSER_BY_ID_DONE',
    data,
  };
}


export function uploadProfileImage(userId, data) {
  return {
    type: 'UPLOAD_PROFILE_IMAGE',
    userId,
    data,
  };
}

export function uploadProfileImageDone(imagePath) {
  return {
    type: 'UPLOAD_PROFILE_IMAGE_DONE',
    imagePath,
  };
}
export function updateUserPassword(userId, data) {
  return {
    type: '@user/UPDATE_USER_PASSWORD',
    userId,
    data,
  };
}
export function updatePasswordSuccess(data) {
  return {
    type: '@user/UPDATE_PASSWORD_SUCCESS',
    data,
  };
}

export function updatePasswordFailure(data) {
  return {
    type: '@user/UPDATE_PASSWORD_FAILURE',
    data,
  };
}
