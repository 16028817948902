export function signInRequest(email, password, signupType) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: {
      email,
      password,
      signupType,
    },
  };
}

export function googlesignInRequest(email, password, signupType) {
  return {
    type: '@auth/SIGN_IN_REQUEST_GOOGLE',
    payload: {
      email,
      password,
      signupType,
    },
  };
}

export function signInSuccess(token) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: {
      token,
    },
  };
}
export function signUpSuccess(response) {
  return {
    type: '@auth/SIGN_UP_USER_REQUEST',
    payload: {
      response,
    },
  };
}

export function signUpRequest(name, email, password, signupType) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: {
      name,
      email,
      password,
      signupType,
    },
  };
}
export function checkedUserRequest(email) {
  return {
    type: '@auth/SIGN_UP_CHECKED_REQUEST',
    payload: {
      email,
    },
  };
}

export function signUpRequestDone(data) {
  return {
    type: '@auth/SIGN_UP_REQUEST_DONE',
    data,
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function userFailure(errdata) {
  return {
    type: '@auth/SIGN_USER_FAILURE',
    payload: {
      errdata,
    },
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

