import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { IoIosTrash } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import history from '~/services/history';
// Action
import { bankDetailsById } from '~/store/modules/bankAccount/bankAccountAction';
import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';
import UploadButton from '~/components/UploadButton';

import { Container, InputWrapper, InputColumn } from './styles';

export default function EditBankAccount() {
  const Dispatch = useDispatch();
  const { state } = useLocation();
  const { colors } = useContext(ThemeContext);
  const { receiverId, accountId } = state;

  const handleSubmit = () => {
    history.push('/before-delete-bank');
  };
  useEffect(() => {
    Dispatch(bankDetailsById(receiverId, accountId));
  });

  return (
    <>
      <SubHeader
        backgroundColor={colors.secondary}
        buttonBackgroundColor={colors.secondary}
        buttonText={<IoIosTrash size={40} />}
        buttonTextColor="#fff"
        buttonFunction={() => handleSubmit()}
        mobileDescVisible={false}
      >
        <h2>Edit Bank Account</h2>
      </SubHeader>
      <Container>
        <form>
          <InputColumn>
            <InputWrapper>
              <InputWrapper>
                <Input label="First Name" placeholder="John" />
              </InputWrapper>
              <InputWrapper>
                <Input label="Last Name" placeholder="Smith" />
              </InputWrapper>
              <InputWrapper>
                <Input label="BSB" placeholder="000-000" />
              </InputWrapper>
              <InputWrapper>
                <Input label="Account Number" placeholder="000 000 000" />
              </InputWrapper>
              <InputWrapper>
                <Input
                  label="Confirm Account Number"
                  placeholder="000 000 000"
                />
              </InputWrapper>
            </InputWrapper>
          </InputColumn>
          <InputColumn>
            <InputWrapper>
              <Input label="Street Address" placeholder="1 Sample Street" />
            </InputWrapper>
            <InputWrapper>
              <Input label="State" placeholder="Eg: Vic" />
            </InputWrapper>
            <InputWrapper>
              <Input label="Post Code" placeholder="3000" />
            </InputWrapper>
            <InputWrapper className="upload-photo">
              <label>Upload Photo ID</label>
              <UploadButton onChange={() => alert('Photo uploaded')} />
              <p>
                A photo of a government iissued ID or passport is required to
                satisfy Australian anti-money laundering laws.
              </p>
            </InputWrapper>

            <Button
              text="Save"
              textColor="#ffffff"
              backgroundColor={colors.purple}
              buttonFunction={() => handleSubmit()}
            />
          </InputColumn>
        </form>
      </Container>
    </>
  );
}
