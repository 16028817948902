import LilGirl from '~/assets/images/lilgirl.jpg';

const media = [
  {
    id: 1,
    image: LilGirl,
    liked: false,
  },
  {
    id: 2,
    image: LilGirl,
    liked: false,
  },
  {
    id: 3,
    image: LilGirl,
    liked: false,
  },
  {
    id: 4,
    image: LilGirl,
    liked: false,
  },
  {
    id: 5,
    image: LilGirl,
    liked: false,
  },
];

export default media;
