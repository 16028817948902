import React, { useContext } from 'react';
import { darken } from 'polished';
import { ThemeContext } from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';

import history from '~/services/history';
import Context from '~/context';

import { ReactComponent as Gift } from '~/assets/images/gift-icon.svg';
import { ReactComponent as Gifts } from '~/assets/images/gifts.svg';

import SubHeader from '~/components/SubHeader';

import { Container, BigButton } from './styles';

export default function EventType() {
  const { colors } = useContext(ThemeContext);
  const { setFlow } = useContext(Context);

  const handleOneGift = () => {
    setFlow({ giftOption: 'one-gift-invite' });
    history.push('/add-event', { user: 'single', type: 'gift' });
  };

  const handleMultipleGifts = () => {
    setFlow({ giftOption: 'multiple-gifts-invite' });
    history.push('/add-event', { user: 'single', type: 'party' });
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.purple}
        buttonBackgroundColor={darken(0.05, colors.purple)}
        buttonTextColor="#fff"
      >
        <h2>Gift Options</h2>
        <h3>I want to invite friends to contribute to:</h3>
      </SubHeader>
      <Container>
        <BigButton onClick={handleOneGift}>
          <div className="image-wrapper">
            <Gift alt="This gift" />
          </div>
          <div className="text-content">
            <h2>This gift only</h2>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>

        <BigButton
          backgroundColor={colors.purple}
          onClick={handleMultipleGifts}
        >
          <div className="image-wrapper">
            <Gifts alt="Multiple gifts" />
          </div>
          <div className="text-content">
            <h2>Multiple gifts</h2>
          </div>
          <IoIosArrowForward size={25} />
        </BigButton>
      </Container>
    </>
  );
}
