import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

// import notifications from '~/components/Notifications/MainNotifications/notifications';

import NotificationCard from '~/components/NotificationCard';
import SubHeader from '~/components/SubHeader';
import { Container } from './styles';
import { useSelector } from 'react-redux';

export default function Notifications() {
  const { colors } = useContext(ThemeContext);
  const notifications = useSelector(
    (state) => state.notificationReducer.notifications,
  );

  return (
    <>
      <SubHeader backgroundColor={colors.purple} mobileDescVisible={false}>
        <h2>Notifications</h2>
      </SubHeader>
      <Container>
        {notifications && notifications.map((notification) => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
      </Container>
    </>
  );
}
