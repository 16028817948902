import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { IoIosCalendar } from 'react-icons/io';
import { convertUTCDateToString } from '~/utils/utility';

import history from '~/services/history';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';
import Avatar from '~/components/Avatar';
import { IoIosClose, IoIosCheckmark } from 'react-icons/io';
import Envelope from '~/assets/images/birthday-envelope.png';
import { ReactComponent as GiftIcon } from '~/assets/images/gift-icon.svg';
import openGift from '../../../assets/images/open-gift.svg';

import {
  Container,
  ImageWrapper,
  GiftWishesButton,
  OtherButtonsWrapper,
  DateContainer,
  Modal,
  ModelContainer,
  Title,
  LetterTextContainer,
  ButtonText,
  ButtonTextLine,
  ParagraphText,
  ModelContainerr,
  SingleGiftCard,
  ImageWrapper1,
  Container1,
  Containergrid,
  GiftCard2,
  Container11,
  InputColumn11,
  InputWrapper11,
  ContainerMain,
  Card,
} from './styles';
import { LinearProgress } from '@material-ui/core';
import { GiftCard } from '~/pages/GiftDetails/styles';
import { createRspv, getRspv } from '~/store/modules/event/eventAction';

function Party({ event, raised, handleInvitation, eventData }) {
  const { colors } = useContext(ThemeContext);
  const receiverId = useSelector((state) => state.receiverReducer);
  const [modalVisible, setModalVisible] = useState(false);
  const [eventDetail, setEventDetail] = useState([]);
  const userIdd = useSelector((state) => state.userReducer.profile);
  let uniqueUserArray = [];
  const Dispatch = useDispatch();
  const rspvUserCheck = useSelector((state) => state.eventReducer.eventrspv);
  const location = useLocation();
  const getChildId = location && location.state;
  const eventNewDetail = location && location.data;
  const handleModalVisible = (value) => {
    setModalVisible(value);
  };
  let percentages = 0;
  let totalContribution = 0;
  let contributionPercentage = 0;
  let totalContributionprice = 0;
  let overallPercentage = 0;
  const handleShow = () => {
    setEventDetail(eventData.event.data);
    setModalVisible(true);
  };

  useEffect(() => {
    if (
      (getFollow && getFollow !== null) ||
      (eventNewDetail && eventNewDetail.event_id !== null)
    ) {
      const payload = {
        event_id: event && event.id,
        email: userIdd.email,
      };
      Dispatch(getRspv(payload));
    } else {
    }
  }, [event]);

  useEffect(() => {
    localStorage.removeItem('gift');
  }, []);

  const date_string = eventData.event.data && eventData.event.data.date;
  // Parse the input date string using Moment.js
  const dateObject = moment(date_string);

  const today = new Date();

  // Format the date object as desired
  const formatted_date = dateObject.format('dddd Do MMMM YYYY');

  const date_string_rspv =
    eventData.event.data && eventData.event.data.rspv_date;
  const dateObject_rspv = moment(date_string_rspv);
  const rspv_formatted_date = dateObject_rspv.format('dddd Do MMMM YYYY');
  const getFollow = localStorage.getItem('follow');

  const date_string1 =
    eventData.event.data && eventData.event.data.venue_start_date;
  const date_string2 =
    eventData.event.data && eventData.event.data.venue_end_date;

  // Parse the input date strings using Moment.js
  const dateObject1 = moment(date_string1);
  const dateObject2 = moment(date_string2);

  // Format the time components
  const formatted_time1 = dateObject1.format('h:mma');
  const formatted_time2 = dateObject2.format('h:mma');

  // Combine the formatted time components
  const time_range = `${formatted_time1}-${formatted_time2}`;
  const startvenue_range = `${formatted_time1}`;

  function formatTextWithLineBreaks(text) {
    if (!text) return null; // Handle empty or undefined text

    const paragraphs = text.split('\n\n');
    const formattedParagraphs = paragraphs.map((paragraph, index) => (
      <p key={index}>
        <br />
        {paragraph.split('\n').map((line, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    ));

    return formattedParagraphs;
  }

  const formattedText = formatTextWithLineBreaks(
    eventData.event.data && eventData.event.data.description,
  );
  const eventDate = new Date(date_string);
  const isEventDateGreaterThanToday = eventDate > today;
  const handleInvite = () => {
    const image = eventData.event.data.eventImage
      ? eventData.event.data.eventImage
      : openGift;
    const greeting =
      eventData.event.data &&
      eventData.event.data.invite_greeting === 'NoGreeting'
        ? ''
        : eventData.event.data && eventData.event.data.invite_greeting;

    const eventDate =
      eventData.event.data && eventData.event.data.date !== ''
        ? formatted_date
        : '';
    const venue =
      eventData.event.data && eventData.event.data.venue !== 'no'
        ? eventData.event.data && eventData.event.data.venue
        : '';
    const event_time =
      date_string1 && date_string2 !== ''
        ? time_range
        : date_string1 && !date_string2
        ? startvenue_range
        : '';

    const rspvDate =
      eventData.event.data && eventData.event.data.rspv_date !== ''
        ? rspv_formatted_date
        : '';

    const payloadData = {
      eventName: eventData.event.data && eventData.event.data.name,
      image: image,
      greeting: greeting,
      eventDate: eventDate,
      venue: venue,
      event_time: event_time,
      formattedText: eventData.event.data && eventData.event.data.description,
      color: eventData.event.data && eventData.event.data.color,
      rspv_date: rspvDate,
    };

    handleInvitation(payloadData, 'checked');
  };

  if (event) {
    event &&
      event.event_gifts.forEach((event) => {
        const { gift } = event;
        totalContribution = gift.contributor_contributions.reduce(
          (total, contribution) =>
            total + parseFloat(contribution.contribution),
          0,
        );
        const price = parseFloat(gift.price);
        percentages = (totalContribution / price) * 100;
      });

    let totalGiftPrice = 0;

    // Iterate through event gifts
    event.event_gifts.forEach((gift, index) => {
      // Calculate total contribution for the current gift
      const giftContribution = gift.gift.contributor_contributions.reduce(
        (total, contribution) => total + parseFloat(contribution.contribution),
        0,
      );

      // Add the current gift's contribution to the total contribution
      totalContributionprice += giftContribution;

      // Add the current gift's price to the total gift price
      totalGiftPrice += parseFloat(gift.gift.price);
    });

    // Calculate the overall percentage based on the total contribution and total gift price
    overallPercentage = (totalContributionprice / totalGiftPrice) * 100;

    const uniqueUsers = new Map();
    event.event_gifts.forEach((gift, index) => {
      gift.gift.contributor_contributions.forEach((contribution) => {
        const userName = `${contribution.user.first_name} ${contribution.user.last_name}`;
        uniqueUsers.set(userName, contribution.user);
      });

      uniqueUserArray = Array.from(uniqueUsers.values());
    });
  }

  const handleContribute = () => {
    if (event && event.event_gifts.length === 0) {
      console.log('zero');
    } else if (event.event_gifts.length === 1) {
      localStorage.setItem('gift', 'single');
      history.push(
        `/gift-contribution/${event.event_gifts[0].event_id}?gift_Ids=${event.event_gifts[0].gift_id}`,
      );
    } else if (event.event_gifts.length > 1) {
      localStorage.setItem('gift', 'list');
      history.push(
        `/contribution-one-gift/${event.id}?u=${userIdd.id}?n=${event.name}`,
      );
    }
  };

  const handleAttend = () => {
    const payload = {
      event_id: event.id,
      user_id: userIdd.id,
      email: userIdd.email,
    };
    Dispatch(createRspv(payload));
    setTimeout(() => {
      const payload = {
        event_id: event && event.id,
        email: userIdd.email,
      };
      Dispatch(getRspv(payload));
    }, 1000);
  };

  return (
    <>
      {(getFollow && getFollow !== null) ||
      (eventNewDetail && eventNewDetail.event_id !== null) ? (
        <SubHeader
          backgroundColor={colors.red}
          backButtonFunction={() =>
            eventNewDetail && eventNewDetail.event_id !== null
              ? history.goBack()
              : history.push(
                  `/receiver-profile/${
                    receiverId.id || receiverId.receiver.id
                  }/events`,
                )
          }
        >
          <h2 className="hide-desktop">Event Details</h2>
          <h2 className="hide-mobile">{event.name}</h2>
          <div>
            <h1 className="hide-desktop">{event.name}</h1>
            <DateContainer>
              <IoIosCalendar />
              {convertUTCDateToString(event.date)}
            </DateContainer>
          </div>
        </SubHeader>
      ) : (
        <SubHeader
          more
          backgroundColor={colors.purple}
          backButtonFunction={() =>
            history.push(
              `/receiver-profile/${
                (getChildId && getChildId.childId) ||
                (receiverId && receiverId.receiver && receiverId.receiver.id) ||
                (receiverId && receiverId.id) ||
                (getChildId && getChildId.receiverIdd)
              }/events`,
            )
          }
        >
          <h2 className="hide-desktop">Event Details</h2>
          <h2 className="hide-mobile">{event.name}</h2>
          <div>
            <h1 className="hide-desktop">{event.name}</h1>
            <DateContainer>
              <IoIosCalendar />
              {convertUTCDateToString(event.date)}
            </DateContainer>
          </div>
        </SubHeader>
      )}

      {getFollow &&
      getFollow !== null &&
      event &&
      event.event_gifts.length > 1 ? (
        <Fragment>
          <ContainerMain>
            <Container11>
              <InputColumn11>
                <InputWrapper11>
                  <ImageWrapper1
                    backgroundImage={
                      event.eventImage ? event.eventImage : openGift
                    }
                  />
                  <div className="contributorss">
                    <div className="avatar-row">
                      {/* {uniqueUserArray
                        ? uniqueUserArray.slice(0, 4).map((avatar) => (
                            <div className="image-wrapper">
                              <Avatar size={35} />
                            </div>
                          ))
                        : ''} */}
                      {event
                        ? event.contributors.avatars
                            .slice(0, 4)
                            .map((avatar) => (
                              <div className="image-wrapper" key={avatar.image}>
                                <Avatar size={35} />
                              </div>
                            ))
                        : ''}
                    </div>
                    <span
                      style={{
                        color: '#888',
                        marginBottom: '11px',
                        fontWeight: '600',
                      }}
                    >
                      {/* {uniqueUserArray ? uniqueUserArray.length : ''}{' '} */}
                      {event ? event.contributors.total : ''} Contributors
                    </span>
                  </div>
                </InputWrapper11>
              </InputColumn11>
              <InputColumn11>
                <div className="btnContainer">
                  <Button
                    text="GIft Card"
                    backgroundColor="#fff"
                    textColor="#999"
                    buttonFunction={() => history.push('/event-album/1')}
                    className="spaceBw"
                  />
                  <Button
                    text="View Invite"
                    backgroundColor="#fff"
                    textColor="#999"
                    buttonFunction={() => handleShow()}
                    className="spaceBw"
                  />
                </div>
              </InputColumn11>
              <br />
              <br />
            </Container11>
            <div className="contry">
              <IoIosCheckmark size={40} color="60b91e" />
              <span style={{ color: '#888', marginLeft: '1px' }}>
                You have contributed ${`${totalContributionprice.toFixed(2)}`}
              </span>
            </div>
            <div className="gift-container">
              {event.event_gifts &&
                event.event_gifts.map((gift, index) => {
                  const totalContribution =
                    gift.gift.contributor_contributions.reduce(
                      (total, contribution) =>
                        total + parseFloat(contribution.contribution),
                      0,
                    );
                  contributionPercentage =
                    (totalContribution / parseFloat(gift.gift.price)) * 100;
                  return (
                    <Card key={gift.gift.id}>
                      {gift.gift.gift_images.length !== 0 ? (
                        <Avatar
                          image={gift.gift.gift_images[0].url}
                          size={55}
                        />
                      ) : (
                        <GiftIcon />
                      )}
                      <div>
                        <h3>{gift.gift.name}</h3>
                        <small>
                          {`${contributionPercentage.toFixed(2)}`}% Funded
                        </small>
                      </div>
                    </Card>
                  );
                })}
              {/* <GiftCard2> */}
              {(getFollow && getFollow !== null) ||
              (eventNewDetail && eventNewDetail.event_id !== null) ? (
                isEventDateGreaterThanToday === true ? (
                  <Button
                    text=" I WOULD LIKE TO CONTRIBUTE"
                    buttonFunction={handleContribute}
                    textColor="white"
                    className="marginbutton1"
                  />
                ) : (
                  <Button
                    text=" I WOULD LIKE TO CONTRIBUTE"
                    textColor="white"
                    className="marginbutton1"
                    backgroundColor="grey"
                  />
                )
              ) : (
                ''
              )}
              {/* </GiftCard2> */}
            </div>
          </ContainerMain>
        </Fragment>
      ) : (
        <Container>
          <ImageWrapper
            backgroundImage={event.eventImage ? event.eventImage : openGift}
          />
          <div>
            {(getFollow && getFollow !== null) ||
            (eventNewDetail && eventNewDetail.event_id !== null) ? (
              <>
                <SingleGiftCard headerBackgroundColor={colors.red}>
                  <div className="single-gift-header">
                    <h2>
                      {event && event.receiver.firstName !== null
                        ? event.receiver.firstName
                        : event && event.receiver.groupName}
                      &apos;s Gift Wishes
                    </h2>
                  </div>
                  <div>
                    <div>
                      <span>Amount to be raised:</span>
                      <span>{/* <strong>${111}</strong> */}</span>
                    </div>

                    <div className="progress-bar">
                      <LinearProgress
                        variant="determinate"
                        value={percentages}
                      />
                      <div>
                        <span></span>
                        <span>{percentages.toFixed(2)}%</span>
                      </div>
                    </div>
                  </div>
                </SingleGiftCard>
              </>
            ) : (
              <GiftWishesButton>
                <GiftIcon />
                <h2>
                  {event && event.receiver.firstName !== null
                    ? event.receiver.firstName
                    : event && event.receiver.groupName}
                  &apos;s Gift Wishes
                </h2>
              </GiftWishesButton>
            )}
            <OtherButtonsWrapper>
              <Button
                text="View Invite"
                backgroundColor="#fff"
                textColor="#999"
                buttonFunction={() => handleShow()}
              />
              <Button
                text="Memories"
                backgroundColor="#fff"
                textColor="#999"
                buttonFunction={() => history.push(`/event-album/${event.id}`)}
              />
            </OtherButtonsWrapper>
            <div className="row">
              {getFollow && getFollow !== null ? (
                <div className="contributors">
                  <div className="avatar-row">
                    {event
                      ? event.contributors.avatars.slice(0, 4).map((avatar) => (
                          <div className="image-wrapper" key={avatar.image}>
                            <Avatar size={40} />
                          </div>
                        ))
                      : ''}
                  </div>
                  <span style={{ color: '#888' }}>
                    {event ? event.contributors.total : ''} contributors
                  </span>
                </div>
              ) : (
                <div className="contributors">
                  <div className="avatar-row">
                    {event
                      ? event.contributors.avatars.slice(0, 4).map((avatar) => (
                          <div className="image-wrapper" key={avatar.image}>
                            <Avatar size={40} />
                          </div>
                        ))
                      : ''}
                  </div>
                  <Link to={`/event-contributors/${event.id}`}>
                    <span>
                      {event ? event.contributors.total : ''} contributors
                    </span>
                  </Link>
                </div>
              )}
              {(getFollow && getFollow !== null) ||
              (eventNewDetail && eventNewDetail.event_id !== null) ? (
                ''
              ) : (
                <span className="raised">
                  <strong>${raised}</strong> <small>Raised</small>
                </span>
              )}
            </div>

            <div className="row">
              <div className="contributors">
                <div className="avatar-row">
                  {event
                    ? event.rspv.avatars.slice(0, 4).map((avatar) => (
                        <div className="image-wrapper" key={avatar.image}>
                          <Avatar image={avatar.image} size={40} />
                        </div>
                      ))
                    : ''}
                </div>
                {(getFollow && getFollow !== null) ||
                (eventNewDetail && eventNewDetail.event_id !== null) ? (
                  <>
                    <IoIosCheckmark size={40} color="60b91e" />
                    <span style={{ color: '#888', marginLeft: '1px' }}>
                      You have contributed ${totalContribution}
                    </span>
                  </>
                ) : (
                  <Link to={`/event-rsvps/${event.id}`}>
                    <span>{event ? event.rspv.total : ''} RSVP&apos;s</span>
                  </Link>
                )}
              </div>
            </div>
            {(getFollow && getFollow !== null) ||
            (eventNewDetail && eventNewDetail.event_id !== null) ? (
              isEventDateGreaterThanToday === true ? (
                <Button
                  text=" I WOULD LIKE TO CONTRIBUTE"
                  buttonFunction={handleContribute}
                  textColor="white"
                  className="marginbutton"
                />
              ) : (
                <Button
                  text=" EVENT ENDED"
                  textColor="white"
                  className="marginbutton"
                  backgroundColor="grey"
                />
              )
            ) : (
              <Button
                text="Invite Friends"
                buttonFunction={() => handleInvite()}
              />
            )}

            {getFollow !== null && event.venue !== 'no' ? (
              <Fragment>
                <div className="borderLine"></div>
                <div className="vendeCs">
                  <p className="eventheading">Event details:</p>
                  <span style={{ textAlign: 'center' }}>
                    <p className="subHeading">{formatted_date}</p>
                    <p className="subHeading">{event.venue}</p>
                    <p className="subHeading">
                      {date_string1 && date_string2 !== ''
                        ? time_range
                        : date_string1 && !date_string2
                        ? startvenue_range
                        : ''}
                    </p>
                  </span>
                </div>
                <div className="borderLineaa">
                  {isEventDateGreaterThanToday === true &&
                  event.venue !== 'no' &&
                  event.rspv_date !== null ? (
                    <>
                      {rspvUserCheck && rspvUserCheck.event_id ? (
                        <Button
                          text="I’M ATTENDING"
                          // buttonFunction={handleContribute}
                          textColor="white"
                          backgroundColor="#888"
                          className="marginbuttona"
                        />
                      ) : (
                        <Button
                          text="I WOULD LOVE TO ATTEND"
                          buttonFunction={handleAttend}
                          textColor="white"
                          className="marginbuttonaa"
                        />
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Fragment>
            ) : (
              ''
            )}
          </div>
        </Container>
      )}
      <Modal
        className={modalVisible && 'visible'}
        onClick={() => handleModalVisible(false)}
        backgroundImage={
          'https://dev-purposit.s3.ap-southeast-2.amazonaws.com/templates/jpeg/32.jpg'
        }
      >
        <ModelContainerr backgroundImage={Envelope}>
          <ModelContainer>
            <div className="letter-container">
              <div
                role="presentation"
                className={`image-wrapper ${'modal-open'}`}
              >
                {eventData && eventData.event.data ? (
                  <ImageWrapper
                    backgroundImage={
                      eventData.event.data.eventImage
                        ? eventData.event.data.eventImage
                        : openGift
                    }
                  />
                ) : (
                  <img src={openGift} alt="Image" />
                )}
              </div>
              <div>
                <Title
                  className={`image-wrapper ${'modal-open'}`}
                  backgroundColor={eventDetail && eventDetail.color}
                  color={'#fff'}
                >
                  <h2>{eventDetail && eventDetail.name}</h2>
                </Title>
                <LetterTextContainer>
                  <p>
                    {(eventDetail &&
                      eventDetail.invite_greeting === 'NoGreeting') ||
                    eventDetail.invite_greeting === 'No Greeting' ? (
                      ''
                    ) : (
                      <>
                        {eventDetail &&
                        eventDetail.invite_greeting ===
                          'Dear Family and Friends'
                          ? 'Dear Family'
                          : eventDetail.invite_greeting}
                        ,
                        <br />
                      </>
                    )}
                    {formattedText}
                  </p>
                </LetterTextContainer>
              </div>
              <ButtonTextLine></ButtonTextLine>
              <br />
              <Title
                className={`image-wrapper ${'modal-open'}`}
                color="#13287f"
              >
                <h3> We'd love for you to join us:</h3>
              </Title>
              <ParagraphText>
                {eventDetail && eventDetail.date !== '' ? (
                  <p> {formatted_date}</p>
                ) : (
                  ''
                )}
                {eventDetail && eventDetail.venue !== 'no' ? (
                  <p> {eventDetail && eventDetail.venue}</p>
                ) : (
                  ''
                )}
                {date_string1 && date_string2 !== '' ? (
                  <p>{time_range}</p>
                ) : date_string1 && !date_string2 ? (
                  <p>{startvenue_range}</p>
                ) : (
                  ''
                )}
              </ParagraphText>
              {/* <br />
              <ButtonText onClick={() => handleInvite()}>Invite Friends</ButtonText>
              <br />
              <br />
              <ParagraphText>
                <br />
                <p
                  style={{ color: '#13287f', cursor: 'pointer' }}
                  onClick={() => handleModalVisible(false)}
                >
                  {' '}
                  Remind me letter
                </p>
              </ParagraphText> */}
              {/* <br /> */}
            </div>
          </ModelContainer>
        </ModelContainerr>
        <IoIosClose
          className="close"
          onClick={() => handleModalVisible(false)}
          color="#fff"
          size={50}
        />
      </Modal>
    </>
  );
}

Party.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    background: PropTypes.string,
    raised: PropTypes.number,
    gift: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      raised: PropTypes.number,
    }),
    contributors: PropTypes.shape({
      total: PropTypes.number,
      avatars: PropTypes.array,
    }),
    rsvps: PropTypes.shape({
      total: PropTypes.number,
      avatars: PropTypes.array,
    }),
  }).isRequired,
  handleInvitation: PropTypes.func.isRequired,
};

export default Party;
