import React, { useState, useContext } from 'react';

import Context from '~/context';

import SubHeader from '~/components/SubHeader';
import AppSetting from '~/components/AppSetting';
import { Container } from './styles';

export default function AppSettings() {
  const { handleShowMenu } = useContext(Context);
  const [appSettings, setAppSettings] = useState({
    notifications: true,
    whoIManage: true,
    whoIFollow: true,
  });

  const handleAppSettings = (e) => {
    setAppSettings({
      ...appSettings,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      <SubHeader
        backgroundColor="#c1c1c1"
        mobileDescVisible={false}
        backButtonFunction={handleShowMenu}
      >
        <h2>App Settings</h2>
      </SubHeader>
      <Container>
        <AppSetting
          text="Notifications"
          toggle={(e) => handleAppSettings(e)}
          name="notifications"
          checked={appSettings.notifications}
        />
        <AppSetting text="All Notification" destination="/notification-list" />
        <AppSetting text="Manage Views">
          <AppSetting
            text="Who I Manage"
            toggle={(e) => handleAppSettings(e)}
            name="whoIManage"
            checked={appSettings.whoIManage}
          />
          <AppSetting
            text="Who I Follow"
            toggle={(e) => handleAppSettings(e)}
            name="whoIFollow"
            checked={appSettings.whoIFollow}
          />
          <AppSetting
            text="Archived Profiles"
            destination="/archived-profiles"
          />
          <AppSetting text="Archived Items" destination="/archived-items" />
        </AppSetting>
        <AppSetting text="Change Password" destination="/change-password" />
        <AppSetting text="Terms and Conditions" destination="/terms" />
        <AppSetting text="Delete Account" />
      </Container>
    </>
  );
}
