import React, { useState, useContext } from 'react';
import Context from '~/context';

import history from '~/services/history';

import FullyFunded from '../FullyFunded';
import DeleteGift from '../DeleteGift';
import ArchiveGift from '../ArchiveGift';

// import gifts from '../../gifts';

export default function Options({ giftId, raised }) {
  const { handleShowModal } = useContext(Context);
  const [selectedOption, setSelectedOption] = useState('');
  // const gift = gifts.find(x => x.id === parseInt(giftId, 10));
  function handleEditGift() {
    handleShowModal();
    history.push(`/edit-gift/${giftId}`);
  }

  return (
    selectedOption || (
      <>
        <button
          type="submit"
          onClick={() => setSelectedOption(<FullyFunded giftId={giftId} />)}
        >
          Complete Gift Funding
        </button>
        <button type="submit" onClick={handleEditGift}>
          Edit Gift
        </button>
        <button
          type="submit"
          onClick={() => setSelectedOption(<ArchiveGift giftId={giftId} />)}
        >
          Archive Gift
        </button>
        <button
          type="submit"
          className="red"
          onClick={() =>
            setSelectedOption(
              <DeleteGift giftId={giftId} hasDonations={raised > 0} />,
            )
          }
        >
          Delete Gift
        </button>
        <button type="submit" onClick={() => handleShowModal()}>
          Cancel
        </button>
      </>
    )
  );
}
