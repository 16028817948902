export function addReceiver(data, imageFile) {
  return {
    type: 'ADD_RECEIVER',
    data,
    imageFile,
  };
}

export function addReceiverDone(id, result) {
  return {
    type: 'ADD_RECEIVER_DONE',
    id,
    result,
  };
}

export function fetchReceiverById(id) {
  return {
    type: 'FETCH_RECEIVER_BY_ID',
    id,
  };
}

export function fetchReceiverByIdDone(data) {
  return {
    type: 'FETCH_RECEIVER_BY_ID_DONE',
    data,
  };
}

export function fetchArchivedEventById(id) {
  return {
    type: 'FETCH_ARCHIVE_EVENT_BY_ID',
    id,
  };
}

export function fetchArchivedEventByIdDone(data) {
  return {
    type: 'FETCH_ARCHIVE_EVENT_BY_ID_DONE',
    data,
  };
}

export function fetchReceiverByIdError() {
  return {
    type: 'FETCH_RECEIVER_BY_ID_ERROR',
  };
}

export function setIsReceiver(bools) {
  return {
    type: 'SET_IS_RECEIVER',
    bools,
  };
}

export function updateReceiver(id, data) {
  return {
    type: 'UPDATE_RECEIVER',
    id,
    data,
  };
}

export function updateReceiverDone(bools) {
  return {
    type: 'UPDATE_RECEIVER_DONE',
    bools,
  };
}

export function deleteReceiver(id) {
  return {
    type: 'DELETE_RECEIVER',
    id,
  };
}

export function deleteReceiverDone(bools) {
  return {
    type: 'DELETE_RECEIVER_DONE',
    bools,
  };
}

export function uploadImageAction(id, data) {
  return {
    type: 'UPLOAD_RECEIVER_IMAGE',
    id,
    data,
  };
}

export function uploadImageActionDone(imagePath) {
  return {
    type: 'UPLOAD_RECEIVER_IMAGE_DONE',
    imagePath,
  };
}
export function fetchArchiveReceiverById(id) {
  return {
    type: 'FETCH_ARCHIVE_RECEIVER_BY_ID',
    id,
  };
}
export function fetchArchiveReceiverByIdDone(data) {
  return {
    type: 'FETCH_ARCHIVE_RECEIVER_BY_ID_DONE',
    data,
  };
}
