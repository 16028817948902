import contacts from './contacts';

const gifts = [
  {
    id: 1,
    name: 'Mountain Bike',
    date: 'by 10th Birthday',
    price: 720,
    raised: 620,
    desc: 'Lorem ipsum dolor sit amet, consectetuer and then some adipiscing elit, sed...',
    contributions: contacts,
  },
  {
    id: 2,
    name: 'Skateboard',
    date: 'by 12th Birthday',
    price: 200,
    raised: 150,
    desc: 'Lorem ipsum dolor sit amet, consectetuer and then some adipiscing elit, sed...',
    contributions: contacts,
  },
  {
    id: 3,
    name: 'Computer',
    date: 'by 15th Birthday',
    price: 2000,
    raised: 0,
    desc: 'Lorem ipsum dolor sit amet, consectetuer and then some adipiscing elit, sed...',
    contributions: [],
  },
];

export default gifts;
