import React from 'react';
import { useParams } from 'react-router-dom';

import SelectProfile from './SelectProfile';
import SelectItems from './SelectItems';

export default function ArchivedItems() {
  const { userId } = useParams();

  return <>{userId ? <SelectItems /> : <SelectProfile />}</>;
}
