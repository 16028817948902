import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  margin: 115px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  padding: 25px 40px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    min-height: calc(100% - 135px);
    flex-direction: column;

    button {
      margin-bottom: 30px;
      max-width: 330px;
    }
  }

  form {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    label {
      font-size: 15px;
      margin-bottom: 10px;
      color: #888;
      font-weight: bold;
      text-transform: uppercase;
    }

    textarea {
      font-size: 18px;
      margin-top: 10px;
      max-width: 330px;
      width: 100%;
      height: 40px;
      padding: 10px 15px;
      border: 0;
      border-radius: 0;

      &::placeholder {
        color: #b7b7b7;
      }
    }

    textarea {
      min-height: 126px;
      font-size: 15px;
      line-height: 20px;
    }
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }
`;

export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;
