import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';
import { Mobile, Default } from '~/config/ReactResponsiveConfig';
import { useDispatch } from 'react-redux';
import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';

import {
  Container,
  InputWrapper,
  EnvelopeHeart,
  InputValidation,
} from './styles';
import {
  fetchUserByEmail,
  fetchUserByIdDone,
} from '~/store/modules/user/userAction';
import { useSelector } from 'react-redux';
import CircularIndeterminate from '~/components/Loader';
import { getUserByEmail } from '~/api/userAPI';

export default function RSVPOnly() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const [email, setEmail] = useState('');
  const [formError, setFormError] = useState(null);
  const [name, setName] = useState('');
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const eventname = queryParams.get('name');
  const [loader, setLoader] = useState(false);

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name === '') {
      setFormError(`validation failed: name required!`);
      return 'validationFailed';
    }
    if (email === '') {
      setFormError(`validation failed: email required!`);
      return 'validationFailed';
    }
    if (!emailRegex.test(email)) {
      setFormError('Validation failed: enter a valid email address!');
      return 'validationFailed';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = validateForm();
    if (error !== 'validationFailed') {
      setLoader(true);
      await getUserByEmail(email)
        .then((res) => {
          if (res && res.data) {
            history.push('/rsvp-confirmed', {
              email: email,
              name: name,
              checked: 'true',
            });
            setLoader(false);
          } else {
            history.push('/rsvp-confirmed', {
              email: email,
              name: name,
              checked: 'false',
            });
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 3000);

  return (
    <>
      <SubHeader backgroundColor={colors.lightPurple} mobileDescVisible={false}>
        <h2>
          <Mobile>RSVP</Mobile>
          <Default>Thanks for RSVP’ing to {eventname}.</Default>
        </h2>
        <h3>
          Please enter your name and email so we can notify the event organiser.
        </h3>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        {!loader ? (
          <>
            <EnvelopeHeart />
            <Mobile>
              <h2>Thanks for RSVP’ing to {eventname}.</h2>
              <h3>
                Please enter your name and email so we can notify the event
                organiser.
              </h3>
            </Mobile>
            <form>
              <InputWrapper>
                <Input
                  label="Your Name"
                  name="name"
                  placeholder="name"
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  label="Your Email"
                  name="email"
                  placeholder="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputWrapper>

              <Button
                text="Confirm my Attendance"
                backgroundColor="#01e3fa"
                buttonFunction={(e) => handleSubmit(e)}
              />
            </form>
          </>
        ) : (
          <CircularIndeterminate />
        )}
      </Container>
    </>
  );
}
