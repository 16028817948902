import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import { Mobile } from '~/config/ReactResponsiveConfig';
import history from '~/services/history';

import Context from '~/context';

import SubHeader from '~/components/SubHeader';
import Input from '~/components/Input';
import Button from '~/components/Button';
import UploadButton from '~/components/UploadButton';
import DatePicker from '~/components/DatePicker';
import { addGift, createGift } from '~/store/modules/gift/giftAction';

import {
  Container,
  InputWrapper,
  InputColumn,
  CheckboxControl,
  ImageDiv,
  DeleteIcon,
  InputValidation,
} from './styles';
import { IoIosClose } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

export default function AddGift() {
  const Dispatch = useDispatch();
  const location = useLocation();
  const { colors } = useContext(ThemeContext);
  const { flow } = useContext(Context);
  const [checkValue, setCheckValue] = useState(false);
  const [date, setDate] = useState(new Date());
  const [giftName, setGiftName] = useState('');
  const [giftCost, setGiftCost] = useState('');
  const [text, setText] = useState('');
  const [formError, setFormError] = useState(null);
  const giftReceiverId = useSelector((state) => state.receiverReducer.id);
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const { first_name, type, group_name } = receiver;
  const isReceiver = useSelector((state) => state.receiverReducer.isReceiver);
  const [textPlaceholder, setTextPlaceholder] = useState(
    `${
      receiver.first_name || receiver.group_name
    } has been dreaming about surfing lessons for years. Let's make it happen!`,
  );
  const gift_data = location.state && location.state;
  const [giftImageFile, setGiftImageFile] = useState(null);
  const [file, setFile] = useState();
  const handleDateChange = (newDate) => {
    setDate(newDate);
  };
  const handleChange = () => {
    setCheckValue(!checkValue);
  };

  const validateForm = () => {
    if (giftName === '') {
      setFormError(`validation failed: gift name required!`);
      return 'validationFailed';
    }
    if (giftCost === '') {
      setFormError(`validation failed: gift cost required!`);
      return 'validationFailed';
    }
    if (text === '') {
      setFormError(`validation failed: description required!`);
      return 'validationFailed';
    }
  };

  const handleChangeImage = () => {
    setGiftImageFile(null);
  };

  function movetoGift() {
    history.push('/gift-instruction', { list: 'list' });
    // history.push('/choose-gifts');
  }

  const handleSubmit = () => {
    const data = {
      title: giftName,
      cost: giftCost,
      timeframe: date,
      description: text,
      is_deleted: false,
      child_id: giftReceiverId,
      is_completed: false,
      static_image_id: 0,
      all_card_status: false,
      gift_cost_visible: checkValue,
      gift_thumb_pic_key: null,
      is_archived: false,
    };
    const error = validateForm();
    if (error !== 'validationFailed') {
      const formData = new FormData();
      if (giftImageFile) {
        formData.append('file', giftImageFile);
      }
      if (isReceiver === false) {
        Dispatch(createGift(data, giftImageFile ? formData : null));
      }
      if (isReceiver === true) {
        if (flow.onboarding === false) {
          Dispatch(createGift(data, giftImageFile ? formData : null));
        } else {
          Dispatch(createGift(data, giftImageFile ? formData : null));
        }
      }
      // if (isReceiver === true && flow.giftOption === 'multiple-gifts') {
      //   Dispatch(createGift(data, giftImageFile ? formData : null));
      // }
      if (gift_data && gift_data.data === 'list') {
        movetoGift();
      } else {
        history.push(
          flow.giftOption !== 'one-gift'
            ? '/gift-instruction'
            : '/gift-instruction',
        );
      }
    }
  };
  // /added-gifts
  setTimeout(() => {
    if (formError !== null) {
      setFormError(null);
    }
  }, 2000);

  const handleGiftImage = async (event) => {
    setGiftImageFile(event.target.files[0]);
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      <SubHeader
        backgroundColor={colors.orange}
        buttonBackgroundColor="#01e3fa"
        buttonText="Next"
        buttonTextColor="#142484"
        buttonFunction={() => handleSubmit()}
      >
        <h2>{flow.giftOption !== 'one-gift' ? 'Add Gifts' : 'Create Gift'}</h2>
        <h3>
          Create a gift wish for{' '}
          {receiver && type === 'individual' ? first_name : group_name}
        </h3>
      </SubHeader>
      <InputValidation>{formError}</InputValidation>
      <Container>
        <form>
          <InputColumn>
            <InputWrapper>
              <Input
                name="gift-name"
                label="Gift Name"
                placeholder="Eg: Surfing lessons..."
                value={giftName}
                onChange={(e) => setGiftName(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper className="cost-row">
              <div>
                <label htmlFor="gift-cost">Total Cost in AUD</label>
                <input
                  name="gift-cost"
                  className="currency-input"
                  placeholder="$420"
                  value={giftCost}
                  type="number"
                  onChange={(e) => setGiftCost(e.target.value)}
                />
              </div>
              <div>
                <CheckboxControl
                  control={
                    <Checkbox
                      checked={checkValue}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Make cost of the gift
                visible to invitees."
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <DatePicker
                label="Gift Expected By"
                name="gift-expected"
                date={date}
                onChange={handleDateChange}
                minDate={new Date()}
              />
            </InputWrapper>
          </InputColumn>
          <InputColumn>
            <InputWrapper>
              <label htmlFor="gift-description">Gift Description</label>
              <textarea
                rows={4}
                name="gift-description"
                placeholder={textPlaceholder}
                onFocus={() => setTextPlaceholder('')}
                onBlur={() =>
                  setTextPlaceholder(
                    `${receiver.first_name} has been dreaming about surfing lessons for years. Let's make it happen!`,
                  )
                }
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="gift">Gift Picture</label>
              <div>
                {giftImageFile && giftImageFile !== null ? (
                  file && (
                    <ImageDiv>
                      <img
                        src={file}
                        alt={'image'}
                        height="90px"
                        width="90px"
                      />
                      <DeleteIcon>
                        <IoIosClose onClick={handleChangeImage} />
                      </DeleteIcon>
                    </ImageDiv>
                  )
                ) : (
                  <span>
                    <UploadButton onChange={handleGiftImage} />
                  </span>
                )}
              </div>
            </InputWrapper>
          </InputColumn>
        </form>
        <Mobile>
          <Button
            text="Save"
            textColor="#ffffff"
            backgroundColor={colors.purple}
            buttonFunction={() => handleSubmit()}
          />
        </Mobile>
      </Container>
    </>
  );
}
