import styled from 'styled-components';
import { darken } from 'polished';
import EditPenIcon from '~/assets/images/edit-pen-icon.svg';

export const Container = styled.div`
  max-width: 720px;
  margin: 50px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
    padding-bottom: 40px;
    margin: 0;
    min-height: calc(100% - 135px);
  }

  > div {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      max-width: initial;
    }

    &:last-child {
      margin-top: -37px;
    }

    > button:last-of-type {
      max-width: 90%;
      margin-top: 40px;
    }
  }

  .row {
    margin: 10px 0 0;
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;

    .contributors {
      display: flex;
      flex: 1;
      align-items: center;

      .avatar-row {
        display: flex;

        .image-wrapper {
          width: 20px;
        }

        img {
          margin: 0;
          border: 2px solid white;
        }
      }
    }

    span {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 16px;
      margin-left: 30px;

      &.raised {
        color: #888;
        font-size: 18px;
        margin: 0;
      }
    }
  }
  .borderLine {
    border: 1px solid aqua;
    margin-top: 25px;
    width: 90%;
  }
  .vendeCs {
    margin-top: 12px;
  }
  .eventheading {
    text-align: center;
    font-size: 19px;
    margin-bottom: 4px;
  }
  p.subHeading {
    color: #888;
    font-size: 15px;
    font-weight: 500;
  }

  button.sc-eNQAEJ.kRijZM.marginbuttonaa {
    max-width: none !important;
    margin-top: 12px;
  }
  .borderLineaa {
    width: 90% !important;
  }
  button.sc-eNQAEJ.junHiX.marginbuttona {
    width: 100% !important;
    max-width: 100%;
    margin-top: 12px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  height: 230px;
`;

export const GiftWishesButton = styled.button`
  min-width: 90%;
  background-color: #fff;
  border: 0;
  padding: 16px 25px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-height: 74px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${darken(0.05, '#fff')};
  }

  h2 {
    color: #888;
    margin-left: 10px;
  }

  svg {
    max-width: 40px;
    max-height: 42px;

    #box {
      fill: #999;
    }

    #lace {
      stroke: #ed1e79;
    }
  }
`;

export const OtherButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 10px;

  > button {
    max-width: 49%;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }
`;
export const Modal = styled.div`
  width: 100%;
  height: auto;
  background: #000000;
  display: none;
  position: absolute;

  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  top: 0;
  left: 0;
  z-index: 2;

  &.visible {
    display: block;
  }

  svg {
    position: fixed;
    right: 5px;
    top: 2px;
    cursor: pointer;
  }
`;
export const ModelContainer = styled.div`
  max-width: auto;
  min-height: auto;
  margin: 10px auto;
  width: 90%;
  // background-image: ${(props) => `url(${props.backgroundImage})`};
  // background-size: contain;
  // background-position: center top;
  // background-repeat: no-repeat;
  padding: 20px;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .letter-container {
    max-width: 390px;
    min-height: 400px;
    background: white;
    padding: 17px;
    border: 1px solid #f1f1f1;

    .image-wrapper {
      position: relative;
      cursor: pointer;

      &.modal-open {
        z-index: 3;

        &:after {
          content: '';
        }
      }

      &:after {
        content: url(${EditPenIcon});
        display: block;
        width: 26px;
        height: 26px;
        position: absolute;
        right: 5px;
        top: 5px;
      }

      img {
        max-width: 360px;
        height: 225px;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }

    > div {
      position: relative;
    }
  }
`;
export const Title = styled.section`
  background-color: ${({ backgroundColor }) => backgroundColor};
  min-height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 33px;
  text-align: center;
  margin: -45px auto 0;
  z-index: 2;
  position: relative;
  max-width: 325px;
  cursor: pointer;

  &:after {
    content: url(${EditPenIcon});
    display: ${(props) => (props.isEditing ? 'none' : 'block')};
    width: 26px;
    height: 26px;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  h2 {
    white-space: pre-line;
    font-size: 20px;
    font-weight: bold;
    color: ${({ color }) => color};
  }
  h3 {
    white-space: pre-line;
    font-size: 19px;
    font-weight: bold;
    color: ${({ color }) => color};
  }
`;
export const LetterTextContainer = styled.div`
  max-width: 325px;
  margin: 0 auto;
  padding: 30px 10px;

  p {
    font-size: 15px;
    color: #888;
  }
`;

export const ButtonText = styled.button`
  min-width: 175px;
  background: #01e3fa;
  padding: 13px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  color: #13287f;
  width: 100%;
  max-width: 325px;
  cursor: pointer;
  transition: background 0.2s;
  text-align: center;
  border: 0;
  margin-left: 12px;
`;

export const ButtonTextLine = styled.div`
  min-width: 175px;
  background: #01e3fa;
  padding: 1px;
  width: 100%;
  max-width: 325px;
  transition: background 0.2s;
  border: 0;
  margin-bottom: 10px;
  margin-left: 12px;
`;

export const ParagraphText = styled.section`
    align-items: center;
    justify-content: center;
    margin-top: -15px
    max-width: 325px;

  p {
    white-space: pre-line;
    font-size: 17px;
    color: #888;
    text-align: center;
  }

  
`;
export const ModelContainerr = styled.div`
  max-width: auto;
  min-height: auto;
  margin: 10px auto;
  width: 90%;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  padding: 20px;
  display: flex;
  justify-content
    margin: 0;: center;

  @media (max-width: 767px) {
    padding: 0;
    width: 100%;
  }

      img {
        max-width: 360px;
        height: 225px;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }

    div {
      position: relative;
    }
  }
`;
export const GiftWishesColorButton = styled.button`
  min-width: 90%;
  background-color: #d10f69;
  border: 0;
  padding: 16px 25px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-height: 74px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${darken(0.05, '#d10f69')};
  }

  h2 {
    color: #fff;
    margin-left: 60px;
    margin-right: 60px;
  }

  svg {
    max-width: 40px;
    max-height: 42px;

    #box {
      fill: #999;
    }

    #lace {
      stroke: #ed1e79;
    }
  }
`;
export const SingleGiftCard = styled.div`
  min-width: 90%;
  margin-bottom: 10px;
  background-color: #fff;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .single-gift-header {
    background-color: ${({ headerBackgroundColor }) => headerBackgroundColor};
    max-height: 60px;
    text-align: center;

    h2 {
      color: #fff;
    }
  }

  > div {
    padding: 13px 25px;
    width: 100%;
    display: flex;
    flex-direction: column;

    > div:first-of-type {
      display: flex;
      justify-content: space-between;
      color: #888;
      text-transform: uppercase;
      font-size: 18px;
    }
  }

  .progress-bar {
    margin-top: 10px;

    > div:first-of-type {
      background-color: #e9ecee;
      border-radius: 2px;
    }

    > div:last-of-type {
      display: flex;
      justify-content: space-between;
      color: #888;
      margin-top: 10px;
      font-size: 16px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
`;
export const ImageWrapper1 = styled.div`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  width: 80px !important;
  background-size: cover;
  background-position: center;
  height: 80px !important;
  border-radius: 72px;
`;

export const Container1 = styled.div`
  max-width: 720px;
  margin: 50px auto;
  width: 75%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
    padding-bottom: 40px;
    margin: 0;
    min-height: calc(100% - 135px);
  }

  > div {
    // max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      max-width: initial;
    }

    &:last-child {
      // margin-top: -37px;
    }

    > button:last-of-type {
      max-width: 90%;
      margin-top: 40px;
    }
  }

  .row {
    margin: 10px 0 0;
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;

    .contributors {
      display: flex;
      flex: 1;
      align-items: center;

      .avatar-row {
        display: flex;

        .image-wrapper {
          width: 20px;
        }

        img {
          margin: 0;
          border: 2px solid white;
        }
      }
    }

    span {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 16px;
      margin-left: 30px;

      &.raised {
        color: #888;
        font-size: 18px;
        margin: 0;
      }
    }
  }
`;

export const Containergrid = styled.div`
  // display: flex !important;
  // flex-direction: row !important;
  // flex-wrap: wrap !important;
  // align-content: flex-start !important;
  // justify-content: flex-end !important;
  // align-items: center !important;
`;

export const GiftCard2 = styled.div`
  background: white;
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin-bottom: 20px; /* Add margin between GiftCard2 components */

  @media (max-width: 767px) {
    flex-direction: column;
  }

  img {
    width: 120px;
    height: 120px;
    min-width: 120px;
    object-fit: cover;
    object-position: center;
    margin-right: 20px;

    @media (max-width: 767px) {
      width: 80px;
      height: 80px;
      min-width: 80px;
      margin-right: 10px;
    }
  }

  > div {
    min-height: 120px;

    @media (max-width: 767px) {
      min-height: initial;
    }
  }

  > div:first-child {
    display: flex;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 240px;

    @media (max-width: 767px) {
      min-width: 100%;
    }
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }

  svg {
    margin-right: -10px;
  }

  small {
    color: #aeaeae;
    font-size: 13px;
  }

  .progress-bar {
    margin-top: 10px;

    > div:first-of-type {
      background-color: #e9ecee;
      border-radius: 2px;
    }

    > div:last-of-type {
      display: flex;
      justify-content: space-between;
      color: #888;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 12px;
    line-height: 15px;
    color: #656565;

    @media (min-width: 768px) {
      flex: 1;
      overflow: hidden;
      max-height: 79px;
    }
  }

  @media (min-width: 768px) {
    flex: 0 0 calc(50% - 20px);
    margin-right: 20px;
    box-sizing: border-box;
  }
`;

export const Container11 = styled.div`
  max-width: 720px;
  margin: 15px auto;
  width: 100%;
  background-color: #f6f6f6;
  padding: 1px;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    padding: 25px 20px;
    margin: 0;
    min-height: calc(100% - 135px);
    justify-content: initial;

    button {
      margin-bottom: 30px;
    }
  }
  .contributorss {
    display: flex;
    flex: 1;
    align-items: center;

    .avatar-row {
      display: flex;
      margin-right: 20px;
      .image-wrapper {
        width: 20px;
      }

      img {
        margin: 0;
        border: 2px solid white;
      }
    }
  }
  button {
    min-width: 100%;
  }

  svg {
    cursor: pointer;
  }

  h3 {
    color: #888;
  }
`;

export const Card11 = styled.div`
  background: white;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  width: 100%;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #888;

  @media (max-width: 767px) {
    font-size: 17px;
  }

  > div {
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
    max-width: 90%;

    &:last-child {
      width: 10%;
      max-width: 10%;
      display: flex;
      justify-content: flex-end;
    }
  }

  img {
    width: 55px;
    height: 55px;
    min-width: 55px;
    border-radius: 28px;
    object-fit: cover;
    object-position: center;
    margin-right: 20px;

    @media (max-width: 767px) {
      margin-right: 10px;
    }
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }
`;

export const InputWrapper11 = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input,
  textarea {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &.currency-input {
      width: 90%;
    }

    &::placeholder {
      color: #b7b7b7;
    }
  }
  .contributorss {
    display: flex;
    align-items: flex-end;
    margin-left: 15px;
    margin-bottom: 3px;
  }
  textarea {
    min-height: 126px;
    font-size: 15px;
    line-height: 20px;
    border: 1px solid white;

    &.error {
      border: 1px solid ${({ theme }) => theme.colors.red};
    }
  }
`;

export const InputColumn11 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
  margin-left: 3px;
  &:last-of-type {
    margin-bottom: 0;
    max-height: 340px;
  }
  .spaceBw {
    margin-bottom: 5px !important;
  }
  button.sc-eNQAEJ.fnwsnj.spaceBw {
    max-width: 90% !important;
    width: 30% !important;
    min-width: 55%;
    padding: 6px !important;
  }
  .btnContainer {
    display: grid;
    justify-items: end;
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const ContainerMain = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  align-items: center;
  align-items: start;
  justify-content: space-between;

  .gift-container {
    flex-wrap: wrap;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 13px;

    @media (max-width: 767px) {
      width: 100%;
      padding: 15px;
      margin: 0;
      grid-template-columns: auto;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
    width: 100%;
    padding: 25px 20px;
    margin: 0;
    min-height: calc(100% - 135px);
    justify-content: initial;

    button {
      margin-bottom: 30px;
    }
  }
  .contry {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 6px;
  }
  button.sc-eNQAEJ.bDeHiK.marginbutton1 {
    max-width: initial;
  }
  button.sc-eNQAEJ.kRijZM.marginbutton1 {
    max-width: inherit;
  }
`;
export const Card = styled.div`
  background: #fff;
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  max-height: 115px;
  cursor: pointer;

  > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }
  img.sc-bwzfXH.ewmzCr {
    border-radius: 4px !important;
  }
  svg {
    max-width: 50px;
    max-height: 50px;
    margin-right: 15px;
    object-fit: cover;
    object-position: center;
    #box {
      fill: #999;
    }

    #lace {
      stroke: #ed1e79;
    }
  }
  small {
    color: #aeaeae;
    font-size: 14px;
    font-weight: 600;
  }
`;
