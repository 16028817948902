import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 305px;

  svg {
    max-width: 70px;
    margin-bottom: 30px;
  }

  h3 {
    max-width: 260px;
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    padding: 20px 0;
    font-size: 18px;
    line-height: 1.2;
    max-width: 280px;
  }
`;
