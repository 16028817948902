import styled from 'styled-components';
import { ReactComponent as SuccessCheck } from '~/assets/images/success-check.svg';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    width: 100%;
    padding: 20px;
    margin: 0;
    min-height: calc(100% - 135px);
  }

  p {
    color: #000;
    font-weight: bold;
  }

  button {
    max-width: 300px;
    margin-top: 20px;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.purple};
    text-align: center;
  }
`;

export const SuccessCheckSVG = styled(SuccessCheck)`
  max-width: 86px;
  max-height: 112px;

  g {
    stroke: ${({ theme }) => theme.colors.primary};
  }

  #check {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: check 1s forwards 1s;
  }

  @keyframes check {
    from {
      stroke-dashoffset: 200;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  #circle {
    stroke-dasharray: 500;
    stroke-dashoffset: 0;
    animation: circle 1s ease-in-out;
  }

  @keyframes circle {
    from {
      stroke-dashoffset: 500;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export const ContactList = styled.div`
  margin-top: 30px;
  column-count: 2;
  display: block;

  @media (max-width: 767px) {
    column-count: 1;
  }
`;

export const Contact = styled.div`
  background: white;
  border-top: 1px solid #eee;
  box-shadow: 0px 4px 6px 0px #cacaca8a;
  display: inline-block;
  padding: 10px 15px;
  width: 100%;

  > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  .contact-info {
    display: flex;
  }

  .donated-value {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: #666;
  }
`;
