import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { IoIosCalendar, IoIosPin } from 'react-icons/io';
import { FaPencilAlt } from 'react-icons/fa';
import Context from '~/context';
import { convertUTCDateToString } from '~/utils/utility';
// Action
import {
  fetchReceiverById,
  fetchReceiverByIdDone,
  uploadImageAction,
} from '../../store/modules/receiver/receiverAction';

import Options from './ModalScreens/Options';

import Gifts from './Gifts';
import Events from './Events';
import Memories from './Memories';

import Header from '~/components/Header';
import SubHeader from '~/components/SubHeader';
import AvatarUpload from '~/components/AvatarUpload';
import ModalWrapper from '~/components/ModalWrapper';

import {
  Container,
  StyledTabs as Tabs,
  StyledTab as Tab,
  UserInfo,
  UserData,
  SubHeaderDesktop,
  More,
  LoaderDiv,
  LoadingText,
} from './styles';
import CircularIndeterminate from '~/components/Loader';
import {
  fetchEventImageByIdDone,
  fetchMemoryById,
} from '~/store/modules/event/eventAction';
import { getNotificationByUserId } from '~/store/modules/notification/notificationAction';

function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ReceiverProfile() {
  const Dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const { receiverId, tab } = useParams();
  const tabs = { gifts: <Gifts />, events: <Events />, memories: <Memories /> };
  const [activeTab, setActiveTab] = useState(tabs[tab] || tabs.gifts);
  const [value, setValue] = useState(tab ? Object.keys(tabs).indexOf(tab) : 0);
  const receiver = useSelector((state) => state.receiverReducer.receiver);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const followUser = location && location.state;
  const gifts = useSelector((state) => state.receiverReducer);
  const getFollow = localStorage.getItem('follow');
  const eventMemories = useSelector((state) => state.eventReducer.memoriesData);
  const profile = useSelector((state) => state.userReducer.profile);

  const handleChange = (event, newValue) => {
    const tabName = Object.keys(tabs)[newValue];
    setActiveTab(tabs[tabName]);
    setValue(newValue);
  };

  const handleChangeFollow = (event, newValue) => {
    const tabName = Object.keys(tabs)[newValue];
    setActiveTab(tabs[tabName]);
    setValue(newValue);
  };

  useEffect(() => {
    setLoader(true);
    Dispatch(getNotificationByUserId(profile.id));
    // Dispatch(fetchEventImageByIdDone(''));
    if (eventMemories && eventMemories !== null) {
    } else {
      Dispatch(fetchMemoryById(receiverId));
    }
    const floww = { giftOption: '', onboarding: false };
    localStorage.setItem('flow', JSON.stringify(floww));
    if (followUser && followUser.followUser === 'yes') {
      // Dispatch(fetchReceiverByIdDone(followUser.followData));
    } else if (getFollow && getFollow !== null) {
    } else {
      Dispatch(fetchReceiverById(receiverId));
    }
    if (receiverId && receiverId) {
      setLoader(false);
    }

    localStorage.removeItem('close');
  }, [Dispatch, receiverId]);

  const handleReceiverImage = (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    Dispatch(uploadImageAction(receiver.id, formData));
  };

  return (
    <>
      <Header />
      <SubHeader
        backgroundColor={colors.orange}
        mobileDescVisible
        mobileShowMenu
        more
        className="hide-desktop"
      >
        <h2>Receiver Profile</h2>
      </SubHeader>
      <SubHeaderDesktop className="hide-mobile" />

      {followUser && followUser.followUser === 'yes' ? (
        <>
          <UserInfo>
            <AvatarUpload
              image={followUser ? followUser.followData.child_image : ''}
              gender={followUser && followUser.followData.gender}
              onChange={handleReceiverImage}
            />
            <UserData>
              <div>
                <h2>
                  {followUser && followUser.followData.first_name !== null
                    ? followUser.followData.first_name +
                      ' ' +
                      followUser.followData.last_name
                    : followUser && followUser.followData.group_name}
                </h2>
                <More onClick={handleShowModal}>
                  <FaPencilAlt />
                </More>
              </div>
              <div>
                {followUser && followUser.followData.dob === null ? (
                  <span>
                    <IoIosPin size={12} color={colors.purple} />
                    {followUser ? followUser.followData.location : ''}
                  </span>
                ) : (
                  <>
                    <span>
                      <IoIosCalendar size={12} color={colors.purple} />
                      {followUser
                        ? convertUTCDateToString(followUser.followData.dob)
                        : ''}
                    </span>
                    <span>
                      <IoIosPin size={12} color={colors.purple} />
                      {followUser ? followUser.followData.location : ''}
                    </span>
                  </>
                )}
              </div>
            </UserData>
          </UserInfo>
          <Tabs
            value={value}
            onChange={handleChangeFollow}
            aria-label="simple tabs example"
          >
            <Tab
              label="Gifts"
              component={Link}
              to={`/receiver-profile/${receiverId}/`}
              {...a11yProps(0)}
            />
            <Tab
              component={Link}
              to={`/receiver-profile/${receiverId}/events`}
              label="Events"
              {...a11yProps(1)}
            />
            <Tab
              component={Link}
              to={`/receiver-profile/${receiverId}/memories`}
              label="Memories"
              {...a11yProps(2)}
            />
          </Tabs>
          <Container>
            {!loader ? (
              activeTab
            ) : (
              <LoaderDiv>
                <CircularIndeterminate />
                <LoadingText>Loading...</LoadingText>
              </LoaderDiv>
            )}
          </Container>

          <ModalWrapper>
            <Options receiver={followUser || ''} />
          </ModalWrapper>
        </>
      ) : (
        <>
          <UserInfo>
            <AvatarUpload
              image={receiver ? receiver.child_image : ''}
              gender={receiver && receiver.gender}
              onChange={handleReceiverImage}
            />
            <UserData>
              <div>
                <h2>
                  {receiver && receiver.first_name !== null
                    ? receiver.first_name + ' ' + receiver.last_name
                    : receiver && receiver.group_name}
                </h2>
                <More onClick={handleShowModal}>
                  <FaPencilAlt />
                </More>
              </div>
              <div>
                {receiver && receiver.dob === null ? (
                  <span>
                    <IoIosPin size={12} color={colors.purple} />
                    {receiver ? receiver.location : ''}
                  </span>
                ) : (
                  <>
                    <span>
                      <IoIosCalendar size={12} color={colors.purple} />
                      {receiver ? convertUTCDateToString(receiver.dob) : ''}
                    </span>
                    <span>
                      <IoIosPin size={12} color={colors.purple} />
                      {receiver ? receiver.location : ''}
                    </span>
                  </>
                )}
              </div>
            </UserData>
          </UserInfo>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              label="Gifts"
              component={Link}
              to={`/receiver-profile/${receiverId}/`}
              {...a11yProps(0)}
            />
            <Tab
              component={Link}
              to={`/receiver-profile/${receiverId}/events`}
              label="Events"
              {...a11yProps(1)}
            />
            <Tab
              component={Link}
              to={`/receiver-profile/${receiverId}/memories`}
              label="Memories"
              {...a11yProps(2)}
            />
          </Tabs>
          <Container>
            {!loader ? (
              activeTab
            ) : (
              <LoaderDiv>
                <CircularIndeterminate />
                <LoadingText>Loading...</LoadingText>
              </LoaderDiv>
            )}
          </Container>

          <ModalWrapper>
            <Options receiver={receiver || ''} />
          </ModalWrapper>
        </>
      )}
    </>
  );
}
