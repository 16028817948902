import styled from 'styled-components';

export const Container = styled.div`
  color: #fff;
  font-size: 16px;
  padding: 0 40px;
  max-width: 660px;

  @media (max-width: 767px) {
    padding: 20px;
    overflow: auto;
    height: 100vh;
  }

  h3 {
    color: ${({ theme }) => theme.colors.primary};
    &:last-of-type {
      margin-top: 40px;
    }
  }

  ul {
    margin-top: 10px;
    margin-left: 40px;
    list-style: disc;
  }
`;
