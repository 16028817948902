import styled from 'styled-components';
import { lighten } from 'polished';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';

export const Container = styled.div`
  background: #fff;
  padding: 20px;
`;

export const SelectExisting = styled.button`
  border: 0;
  padding: 0;
  background: none;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 1px solid ${({ theme }) => theme.colors.primary};

    &::placeholder {
      color: #b7b7b7;
    }
  }

  textarea {
    min-height: 400px;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 767px) {
      min-height: 415px;
    }
  }

  &.upload-photo {
    > div {
      margin-bottom: 20px;
    }

    p {
      color: #888;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  > div:first-of-type {
    margin-right: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 100%;
  }
`;

export const RadioControl = styled(FormControl)`
  width: 100%;

  @media (min-width: 768px) {
    max-width: 330px;
  }

  .MuiFormGroup-root {
    flex-direction: row;
    margin-bottom: 0;
  }

  .MuiFormControlLabel-labelPlacementStart {
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const RadioButton = styled(Radio)`
  &.MuiRadio-root {
    &.Mui-checked {
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
      }
    }

    &:hover {
      background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
    }
  }
`;

export const Card = styled.div`
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  background: #fff;
  padding: 5px 10px;
  color: #666;
  font-size: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  min-height: 52px;

  span {
    padding: 5px;
    font-size: 18px;

    & + svg {
      margin-right: 9px;
    }
  }
`;

export const InputCard = styled.div`
  position: absolute;
  margin-left: 33%;
  font-size: 40px;
`;

export const InputCardValidation = styled.div`
  color: red;
  margin-top: -14px;
  margin-bottom: 10px;
`;
