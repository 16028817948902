import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from '~/components/DatePicker';
import Radio from '@material-ui/core/Radio';
import { lighten } from 'polished';
import TimePicker from '~/components/TimePicker';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 10px;
    margin: 0;
    min-height: calc(100% - 135px);

    button {
      margin-bottom: 30px;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      margin-bottom: 20px;
    }
  }
`;
export const TimePickerStyled = styled(TimePicker)`
  margin-bottom: 20px;
  input {
    height: 20px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input,
  textarea {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }

  textarea {
    min-height: 400px;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 767px) {
      min-height: 415px;
    }
  }

  &.time-picker {
    flex-direction: row;
    justify-content: space-between;

    > div {
      @media (max-width: 767px) {
        width: 48%;
      }
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 100%;
  }
`;

export const SelectControl = styled(FormControl)`
  max-width: 330px;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;

  .MuiFilledInput-root .MuiFilledInput-input {
    padding: 10px 12px 10px;
    height: 20px;
  }

  input {
    background-color: #fff;
    border-radius: 50%;
  }

  label {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 16px;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  margin-bottom: 20px;

  .MuiTypography-root .MuiPickersToolbarText-toolbarTxt .MuiTypography-h2 {
    font-size: 60px;
  }

  input {
    height: 20px;
  }
`;
export const InputValidation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
  justify-content: center;
`;
export const LoaderDiv = styled.div`
  margin-top: 25px;
  // align-items: center;
  // margin-left: 50%;
`;

export const LoadingText = styled.div`
  // position: absolute;
  top: 35px;
  color: #13287f;
`;

export const RadioControl = styled(FormControl)`
  max-width: 330px;
  width: 100%;

  label {
    margin-bottom: 0;
  }

  .MuiFormGroup-root {
    flex-direction: row;
    margin-bottom: 20px;
  }
`;

export const RadioButton = styled(Radio)`
  &.MuiRadio-root {
    &.Mui-checked {
      color: ${({ theme }) => theme.colors.primary};
      &:hover {
        background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
      }
    }
    &:hover {
      background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
    }
  }
`;

