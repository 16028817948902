export default {
  title: 'main',

  colors: {
    primary: '#01e3fa',
    secondary: '#13287f',
    text: '#888',
    pink: '#ed1e79',
    purple: '#622b8b',
    lightPurple: '#93278F',
    plum: '#D28DC9',
    wine: '#9E005D',
    orange: '#f15a24',
    red: '#D4145A',
  },
};
