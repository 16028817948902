import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import history from '~/services/history';

import Button from '~/components/Button';

import { Container, CheckboxControl } from './styles';

export default function GiftList({ gifts, addInfo }) {
  const { colors } = useContext(ThemeContext);
  const newGiftData = gifts.map((crr) => ({ checked: false, ...crr }));
  const initialChecks = newGiftData.reduce(
    (object, gift) => ({ ...object, [gift.id]: gift.checked }),
    {},
  );
  const [selectedGiftId, setSelectedGiftId] = useState(null);

  const handleGiftSelect = (id) => {
    if (
      (addInfo.type === 'gift' || addInfo.user === 'single') &&
      (addInfo.type === 'gift' || addInfo.user === 'multiple')
    ) {
      localStorage.removeItem('giftId');
      if (selectedGiftId === id) {
        setSelectedGiftId(null); // Deselect the gift if it's already selected
      } else {
        setSelectedGiftId(id); // Select the clicked gift
        localStorage.setItem('giftId', id);
      }
    } else {
    }
  };

  const [checkValue, setCheckValue] = useState(initialChecks);

  const handleChange = (id) => (event) => {
    // setCheckValue({ ...checkValue, [id]: event.target.checked });
    // const ids = Object.keys({ ...checkValue, [id]: event.target.checked }).map(Number);

    const updatedValue = { ...checkValue, [id]: event.target.checked };
    setCheckValue(updatedValue);

    const checkedIDs = Object.keys(updatedValue)
      .filter((key) => updatedValue[key])
      .map(Number);
    localStorage.setItem('giftId', checkedIDs);
  };

  const handleAddGift = () => {
    history.push('/add-gift', { data: 'list' });
  };

  const giftFunded = (contributor_contributions, price) => {
    const contribution = contributor_contributions.map((crr) =>
      parseInt(crr.contribution),
    );
    const raised =
      contribution.length > 0 ? contribution.reduce((a, b) => a + b) : '';
    const percentage = (raised / price) * 100;
    return percentage.toFixed(2);
  };

  return (
    <Container>
      {newGiftData.map((gift) => (
        <CheckboxControl
          key={gift.id}
          control={
            <div
              className="gift-card"
              onClick={() => handleGiftSelect(gift.id)}
            >
              <div>
                <h3>{gift.name}</h3>
                <small>
                  <strong>
                    {giftFunded(gift.contributor_contributions, gift.price)}
                  </strong>
                  % Funded
                </small>
              </div>
              {(addInfo.type === 'gift' || addInfo.user === 'single') &&
              (addInfo.type === 'gift' || addInfo.user === 'multiple') ? (
                <div>
                  <Checkbox
                    checked={selectedGiftId === gift.id}
                    name={gift.id}
                    color="primary"
                    disabled={
                      selectedGiftId !== gift.id && selectedGiftId !== null
                    }
                  />
                </div>
              ) : (
                <div>
                  <Checkbox
                    checked={checkValue[gift.id].checked}
                    onChange={handleChange(gift.id, gift)}
                    name={gift.id}
                    color="primary"
                  />
                </div>
              )}
            </div>
          }
        />
      ))}
      <Button
        text="Add New Gift"
        textColor={colors.purple}
        backgroundColor="#ffffff"
        buttonFunction={() => handleAddGift()}
      />
    </Container>
  );
}

GiftList.propTypes = {
  gifts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
