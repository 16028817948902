import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';

import history from '~/services/history';
import HideFooter from '~/utils/HideFooter';

import Button from '~/components/Button';

import { OuterContainer, Container, OpenGift } from './styles';
import {
  bankListById,
  cardDetailsByUserId,
} from '~/store/modules/bankAccount/bankAccountAction';
import { useDispatch, useSelector } from 'react-redux';

export default function OnboardingFinalStep() {
  const { colors } = useContext(ThemeContext);
  const Dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.profile);
  const userId = user.id;
  const bankAccountReducer = useSelector((state) => state.bankAccountReducer);
  const { userBankDetails, userCardDetails } = bankAccountReducer;

  const handleMove = () => {
    window.location.replace('/my-profile');
  };

  useEffect(() => {
    Dispatch(bankListById(userId));
    Dispatch(cardDetailsByUserId(userId));
  }, [userId]);

  return (
    <>
      <HideFooter />
      <OuterContainer>
        <Container colors={{ start: colors.purple, end: colors.primary }}>
          <div>
            <OpenGift />
            <h1>Hooray</h1>
            {userBankDetails === null ? (
              <h2>
                Final step. Let’s securely link a bank account where you can
                receive the gift contributions from your invitees.
              </h2>
            ) : (
              <h2>
                You can now sit back and relax while your friends react to your
                invite.
              </h2>
            )}
          </div>
          {userBankDetails === null ? (
            <Button
              text="Link Bank Account"
              buttonFunction={() => history.push('/link-bank')}
            />
          ) : (
            ''
          )}
          {userBankDetails !== null ? (
            <Button
              text="Close"
              buttonFunction={() => history.push('/my-profile')}
            />
          ) : (
            <Link to="#" onClick={handleMove}>
              Later
            </Link>
          )}
        </Container>
      </OuterContainer>
    </>
  );
}
