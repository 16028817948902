import styled from 'styled-components';
import { lighten } from 'polished';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import DatePicker from '~/components/DatePicker';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 48%;

  @media (max-width: 767px) {
    width: 100%;
  }

  label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #888;
    font-weight: bold;
    text-transform: uppercase;
  }

  input,
  textarea {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 330px;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #b7b7b7;
    }
  }

  textarea {
    min-height: 400px;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 767px) {
      min-height: 415px;
    }
  }

  &.upload-photo {
    > div {
      margin-bottom: 20px;
    }

    p {
      color: #888;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  flex-wrap: wrap;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const RadioControl = styled(FormControl)`
  max-width: 330px;
  width: 100%;

  .MuiFormGroup-root {
    flex-direction: row;
    margin-bottom: 20px;
  }
`;

export const RadioButton = styled(Radio)`
  &.MuiRadio-root {
    &.Mui-checked {
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
      }
    }

    &:hover {
      background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
    }
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  margin-bottom: 20px;

  .MuiTypography-root .MuiPickersToolbarText-toolbarTxt .MuiTypography-h2 {
    font-size: 60px;
  }

  input {
    height: 20px;
  }
`;
