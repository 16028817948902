import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IoIosArrowForward } from 'react-icons/io';
import Amex from '~/assets/images/card-flag-amex.svg';
import MasterCard from '~/assets/images/card-flag-mc.svg';
import Visa from '~/assets/images/card-flag-visa.svg';
import Context from '~/context';

import CCVModal from './CCVModal';
import Input from '~/components/Input';
import ModalWrapper from '~/components/ModalWrapper';
import { number, expirationDate as expirydate, cvv } from 'card-validator';

import {
  Container,
  InputWrapper,
  InputRow,
  RadioButton,
  RadioControl,
  Card,
  SelectExisting,
  InputCard,
  InputCardValidation,
} from './styles';

export default function CreditCardForm({ cards,cardNumber,setCardNumber,expirationDate, setExpirationDate,CCV, setCCV,isValidCardNumber, setIsValidCardNumber,isValidExpiry, setIsValidExpiry,isValidCVV, setIsValidCVV}) {
  const { handleShowModal } = useContext(Context);
  const [radioValue, setRadioValue] = useState(1);
  const [addNewCard, setAddNewCard] = useState(cards.length === 0);

  const handleRadioChange = (event) => {
    setRadioValue(parseInt(event.target.value, 10));
  };

  const detectCardType = (cardNumber) => {
    const visaPattern = /^4/;
    const mastercardPattern = /^5[1-5]/;
    const amexPattern = /^3[47]/;
    const discoverPattern = /^6(?:011|5)/;

    if (visaPattern.test(cardNumber)) {
      return 'visa';
    } else if (mastercardPattern.test(cardNumber)) {
      return 'mastercard';
    } else if (amexPattern.test(cardNumber)) {
      return 'amex';
    } else if (discoverPattern.test(cardNumber)) {
      return 'discover';
    } else {
      return 'default'; // You can handle other card types or return 'unknown' if needed.
    }
  };

  const cardType = detectCardType(cardNumber);
  const handleCardNumberChange = (e) => {
    const inputCardNumber = e.target.value.replace(/\s/g, ''); // Remove spaces from the input
    const formattedCardNumber = inputCardNumber
      .replace(/(\d{4})/g, '$1 ')
      .trim();

    setCardNumber(formattedCardNumber);

    const validationResult = number(inputCardNumber);
    setIsValidCardNumber(validationResult.isValid);
  };

  const handleExpiryChange = (e) => {
    const inputExpiry = e.target.value;
    let inputExpiry1 = inputExpiry.replace(/\D/g, '');

    // Insert slash after the first two digits (MM)
    if (inputExpiry1.length > 2) {
      inputExpiry1 = inputExpiry1.replace(/^(\d{2})/, '$1/');
    }

    const enteredMonth = Number(inputExpiry1.substr(0, 2));
    const enteredYear = Number(inputExpiry1.substr(3, 2));

    // Get the current date, month, and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-11.
    const currentYear = currentDate.getFullYear() % 100; // Last two digits of the current year.

    // Custom validation for expiry format (MM/YY) - 12/33
    const expiryRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    const isValidFormat = expiryRegex.test(inputExpiry1);

    // Check if the entered month and year are not in the past
    const isValidMonth =
      enteredYear > currentYear ||
      (enteredYear === currentYear && enteredMonth >= currentMonth);
    const isValidYear = enteredYear >= currentYear;

    setIsValidExpiry(isValidFormat && isValidMonth && isValidYear);
    setExpirationDate(inputExpiry1);
  };

  const handleCVVChange = (e) => {
    const inputCVV = e.target.value;
    setCCV(inputCVV);

    const validationResult = cvv(inputCVV);
    setIsValidCVV(validationResult.isValid);
  };

  return (
    <>
      {!addNewCard ? (
        <>
          <Container>
            <InputWrapper>
              <Input
                label="Card Number"
                placeholder="4000 1234 5678 9089"
                value={cardNumber}
                onChange={handleCardNumberChange}
                maxLength="19"
              />
              <InputCard>
                {cardType === 'visa' && <img src={Visa} alt="Visa" />}
                {cardType === 'mastercard' && (
                  <img src={MasterCard} alt="MasterCard" />
                )}
                {cardType === 'amex' && (
                  <img src={Amex} alt="American Express" />
                )}
              </InputCard>
              {!isValidCardNumber && (
                <InputCardValidation>Invalid card number</InputCardValidation>
              )}
            </InputWrapper>
            <InputRow>
              <InputWrapper>
                <Input
                  label="Expiry Date"
                  placeholder="MM/YY"
                  value={expirationDate}
                  onChange={handleExpiryChange}
                  maxLength="5"
                />
                {!isValidExpiry && (
                  <InputCardValidation>Invalid expiry date</InputCardValidation>
                )}

              </InputWrapper>
              <InputWrapper>
                <Input
                  label="CCV"
                  placeholder="000"
                  info
                  infoFunction={() => handleShowModal()}
                  value={CCV}
                  onChange={handleCVVChange}
                  maxLength="3"
                />
                {!isValidCVV && (
                  <InputCardValidation>Invalid CVV</InputCardValidation>
                )}
              </InputWrapper>
            </InputRow>
          </Container>
        </>
      ) : (
        <>
        </>
      )}
      <ModalWrapper>
        <CCVModal />
      </ModalWrapper>
    </>
  );
}

CreditCardForm.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      number: PropTypes.string,
    }),
  ),
};

CreditCardForm.defaultProps = {
  cards: [],
};
