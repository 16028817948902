import * as React from 'react';
import { Box, CircularProgress } from '@material-ui/core';


export default function CircularIndeterminate({colorName='secondary'}) {
  return (
    <Box sx={{ display: 'flex' ,padding:'10px'}}>
      <CircularProgress size={20} color={colorName}/>
    </Box>
  );
}