import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Memory = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  span {
    text-transform: uppercase;
  }
  small {
    color: #888;
  }
`;

export const MemoryThumbnail = styled.div`
  background-image: url(${({ image }) => image});
  width: 118px;
  height: 85px;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 3px solid #fff;
  box-shadow: 0 0 10px 3px #e8e8e8;
  position: relative;
  transform-origin: center center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.5s all;
  transform: rotate(0deg);

  @media (max-width: 767px) {
    width: 98px;
    height: 75px;
  }
`;
