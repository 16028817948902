import { createContext } from 'react';

import menu from './menu';
import modal from './modal';
import footer from './footer';
import flow from './flow';
import events from './events';
import receivers from './receivers';
import gifts from './gifts';

const Context = createContext({
  ...menu,
  ...modal,
  ...footer,
  ...flow,
  events,
  receivers,
  gifts,
});

export default Context;
