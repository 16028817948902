import React, { useState, useContext } from 'react';

import Context from '~/context';

import history from '~/services/history';

import DeleteAlbum from '../DeleteAlbum';
import HideAlbum from '../HideAlbum';

export default function Options({ eventId, userId,postId }) {
  const { handleShowModal } = useContext(Context);
  const [selectedOption, setSelectedOption] = useState('');

  function handleEditAlbum() {
    history.push(`/event-album/${eventId}/edit`);
    handleShowModal();
  }

  return (
    selectedOption || (
      <>
        <button type="submit" onClick={handleEditAlbum}>
          Edit Album
        </button>
        <button
          type="submit"
          onClick={() =>
            setSelectedOption(<HideAlbum eventId={eventId} userId={userId} />)
          }
        >
          Hide Album
        </button>
        <button
          type="submit"
          className="red"
          onClick={() =>
            setSelectedOption(<DeleteAlbum eventId={eventId} userId={userId} />)
          }
        >
          Delete Album
        </button>
      </>
    )
  );
}
