import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import history from '~/services/history';
import Context from '~/context';

import { ReactComponent as TrashCan } from '~/assets/images/trash-can.svg';

import { Container } from './styles';
import { useParams } from 'react-router-dom';
import CircularIndeterminate from '~/components/Loader';
import { deleteMemoriesAlbum } from '~/store/modules/event/eventAction';

export default function DeleteAlbum() {
  const { colors } = useContext(ThemeContext);
  const { handleShowModal } = useContext(Context);
  const receiver = useSelector((state) => state.receiverReducer);
  const { eventId, postId } = useParams();
  const Dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const handleDeleteAlbum = () => {
    const payload = {
      event_id: parseInt(eventId),
      child_id: receiver.id,
    };

    Dispatch(deleteMemoriesAlbum(payload));
    setLoader(true);
    setTimeout(() => {
      handleShowModal();
      history.push({
        pathname: '/success',
        state: {
          destination: `/receiver-profile/${receiver.id}/memories`,
          message: 'Album Deleted.',
          colors: { start: colors.lightPurple, end: colors.secondary },
        },
      });
    }, 2000);
  };

  return (
    <Container>
      <TrashCan />
      <h2>Are you sure you want to delete this album?</h2>
      <p>
        Once you delete an album all information linked to it will be
        permanently deleted.
      </p>
      <p>
        We recommend archiving the event, that way you can retrieve it and
        re-use it in the future.
      </p>
      <button type="submit" onClick={() => handleShowModal()}>
        Cancel
      </button>

      {!loader ? (
        <button
          type="submit"
          className="red"
          onClick={() => handleDeleteAlbum()}
        >
          Delete
        </button>
      ) : (
        <button type="submit" className="red">
          <CircularIndeterminate />
        </button>
      )}
    </Container>
  );
}
