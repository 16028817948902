import { apiAxiosInstance } from '../services/axios';

import { getJWTToken } from '../utils/utility';
const { REACT_APP_PURPOSIT_API_URL } = process.env;

const tokenData = JSON.parse(getJWTToken());
const headers = {};
if (tokenData && tokenData.token) {
  headers.Authorization = `Bearer ${tokenData.token}`;
}

export const getUser = async () => {
  try {
    const result = await apiAxiosInstance.get('user/', { headers });
    return result;
  } catch (err) {
    return err;
  }
};

export const getUserById = async (userId) => {
  try {
    const result = await apiAxiosInstance.get(`user/${userId}`, { headers });
    return result;
  } catch (err) {
    return err;
  }
};

export const getFollowUserByEmail = async (email) => {
  const payload = {
    email: email,
  };
  try {
    const result = await apiAxiosInstance.post(`user/i_follow`, payload, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const getUserByEmail = async (email) => {
  try {
    const result = await apiAxiosInstance.get(`user/email/${email}`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const getUpdateUser = async (userId, data) => {
  try {
    const result = await apiAxiosInstance.put(`user/${userId}`, data, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const changePassword = async (id, data) => {
  try {
    const result = await apiAxiosInstance.put(
      `user/${id}/changepassword`,
      data,
      { headers },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const sendEmail = async (payload) => {
  try {
    const result = await apiAxiosInstance.post(`user/sendmail`, payload, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const makeStripePayment = async (payload) => {
  try {
    const result = await apiAxiosInstance.post(`stripe/payment`, payload, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const makeCardStripePayment = async (payload) => {
  try {
    const result = await apiAxiosInstance.post(`stripe/card_payment`, payload, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const sendMessage = async (payload) => {
  try {
    const result = await apiAxiosInstance.post(`user/sendmessage`, payload, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const sendPasswordMessage = async (payload) => {
  try {
    const result = await apiAxiosInstance.post(
      `user/send_password_message`,
      payload,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};
export const getContributionById = async (id) => {
  try {
    const result = await apiAxiosInstance.get(`gift/contribution_gift/${id}`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const giftFundYourself = async (payload) => {
  try {
    const result = await apiAxiosInstance.post(
      `stripe/gift_fund_yourself`,
      payload,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getCheckUserDOB = async (userId) => {
  try {
    const result = await apiAxiosInstance.get(`user/${userId}/user_dob_check`, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const sendFCMToken = async (payload) => {
  try {
    const result = await apiAxiosInstance.post(`user/send_fcm_token`, payload, {
      headers,
    });
    return result;
  } catch (err) {
    return err;
  }
};

export const inviteTemplates = async (payload) => {
  try {
    const result = await apiAxiosInstance.post(
      `user/invite_templates`,
      payload,
      {
        headers,
      },
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const forgotPassword = async (payload) => {
  try {
    const response = await fetch(
      `${REACT_APP_PURPOSIT_API_URL}user/forgot_reset_password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error; // Re-throwing the error to be caught by the caller
  }
};

export const updatePassword = async (payload) => {
  try {
    const response = await fetch(
      `${REACT_APP_PURPOSIT_API_URL}user/update_password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error; // Re-throwing the error to be caught by the caller
  }
};
