const initialState = {
  profile: null,
  profileImageFormaData: null,
  passwordChange: '',
  profileImageUploaded: false,
  fetchedProfile: false,
  fetchedFollowProfile: false,
  followProfile: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case '@user/FETCH_USER_BY_EMAIL': {
      state.profile = action.payload.user;
      break;
    }
    case '@user/FETCH_USER_DONE':
      return { ...state, profile: action.data, fetchedProfile: true };
    case '@user/UPDATE_PROFILE_SUCCESS': {
      state.profile = action.data.data;
      state.fetchedProfile = false;
      break;
    }

    case '@user/UPDATE_PASSWORD_SUCCESS':
      return { ...state, passwordChange: action.data, fetchedProfile: true };
    case '@user/UPDATE_PASSWORD_FAILURE':
      return {
        ...state,
        passwordChange: action.data,
        fetchedProfile: true,
      };

    case '@auth/SIGN_OUT':
      return { ...state, profile: null };
    case 'FETCH_USER_BY_ID_DONE':
      return { ...state, profile: action.data.data, fetchedProfile: true };
    case 'FETCH_FOLLOWUSER_BY_ID_DONE':
      return {
        ...state,
        followProfile: action.data.data,
        fetchedFollowProfile: true,
      };
    case 'UPLOAD_PROFILE_IMAGE':
      return { ...state, profileImageFormaData: action.data };
    case 'UPLOAD_PROFILE_IMAGE_DONE':
      return {
        ...state,
        profile: {
          ...state.profile,
          user_image: action.imagePath,
        },
        profileImageFormaData: true,
        fetchedProfile: true,
      };
    default:
      return state;
  }
  return state;
}
