import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 15px;
    margin: 0;
    grid-template-columns: auto;
  }
`;

// export const Container = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-gap: 20px;

//   @media (max-width: 767px) {
//     width: 100%;
//     padding: 15px;
//     margin: 0;
//     grid-template-columns: auto;
//   }

//   > div {
//     background: white;
//     box-shadow: 0px 0px 10px 0px #cacaca8a;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 15px;
//     max-height: 115px;

//     img {
//       width: 80px;
//       height: 80px;
//       min-width: 80px;
//       object-fit: cover;
//       object-position: center;
//       margin-right: 20px;

//       @media (max-width: 767px) {
//         margin-right: 10px;
//       }
//     }

//     div {
//       display: flex;
//     }

//     > div {
//       min-height: 80px;
//       justify-content: space-between;
//       flex-direction: column;
//       width: 100%;

//       > div {
//         flex-direction: row;
//         justify-content: space-between;
//         align-items: flex-start;

//         div:first-child {
//           flex-direction: column;
//         }

//         div:last-child {
//           align-items: center;

//           @media (max-width: 767px) {
//             align-items: start;
//           }
//         }
//       }
//     }

//     h3 {
//       font-size: 18px;
//       letter-spacing: 0.5px;
//       line-height: 18px;
//     }

//     svg {
//       margin-right: -10px;
//     }

//     small {
//       color: #aeaeae;
//       font-size: 13px;
//     }

//     .gift-price {
//       font-size: 20px;
//       color: #878787;

//       @media (max-width: 767px) {
//         font-size: 16px;
//       }
//     }

//     p {
//       font-size: 12px;
//       line-height: 15px;
//       color: #656565;
//     }
//   }

//   button {
//     max-width: 100%;
//     max-height: 50px;
//   }
// `;

export const Card = styled.div`
  background: #fff;
  box-shadow: 0px 0px 10px 0px #cacaca8a;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  max-height: 115px;
  cursor: pointer;

  > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }

  svg {
    margin-right: -10px;
  }

  small {
    color: #aeaeae;
    font-size: 13px;
  }
`;
