import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';

import Target from '~/assets/images/notification-icon-3.svg';
import OpenGift from '~/assets/images/open-gift.svg';
import Archive from '~/assets/images/archive.svg';

import SubHeader from '~/components/SubHeader';
import NotificationCard from '~/components/NotificationCard';

import { Container, Column, Card, ContainerDiv } from './styles';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getContributionById, getUserById } from '~/api/userAPI';
import CircularIndeterminate from '~/components/Loader';
import history from '~/services/history';

const options = [
  {
    id: 1,
    icon: OpenGift,
    title: 'Allow contributions to continue',
    description: 'until the event date has passed.',
    path: '/funding-continues',
  },
  {
    id: 2,
    icon: Archive,
    title: 'No longer accept contributions',
    description: 'for this gift.',
    path: '/gift-funded',
  },
];

export default function TargetReached() {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const getData = location && location.data;
  const [userData, setUserData] = useState('');
  const [giftData, setGiftData] = useState('');
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationLinkData,
  );
  const [loader, setLoader] = useState(false);

  const parsedData =
    notificationData && notificationData
      ? JSON.parse(notificationData && notificationData.data)
      : JSON.parse(getData && getData.data);

  const senderId = parsedData && parsedData.senderId;
  const childName = parsedData && parsedData.child_name;

  useEffect(() => {
    getUserDetail(senderId || (notificationData && notificationData.sender_id));
    localStorage.removeItem('targetReached');
  }, [senderId]);

  const getUserDetail = async (id) => {
    const giftId = notificationData && notificationData.gift_id;
    setLoader(true);
    try {
      await getUserById(id).then((res) => {
        setUserData(res.data);
        setLoader(false);
      });
      await getContributionById(giftId).then((res) => {
        setGiftData(res.data);
        setLoader(false);
      });
    } catch (error) {
      console.log('err', error);
      setLoader(false);
    }
  };

  const handleNotification = (data) => {
    localStorage.setItem('targetReached', 'yes');
    history.push({ pathname: data.path, giftData });
  };

  return (
    <>
      <SubHeader backgroundColor={colors.purple} mobileDescVisible={false}>
        <h2>Target Reached</h2>
      </SubHeader>
      <Container>
        {!loader ? (
          <>
            <Column>
              <h2>Yeah!</h2>
              <img src={Target} alt="Target" />
              <Card>
                <h3>
                  {childName && childName}&apos;s {giftData && giftData.name}
                </h3>
                <span>Has reached your</span>
                <span className="large">${giftData && giftData.price}</span>
                <span>funding target</span>
              </Card>
            </Column>
            <Column>
              <p>What would you like to do next?</p>
              {options &&
                options.map((item) => (
                  <>
                    <ContainerDiv>
                      <div className="image-wrapper">
                        <img src={item.icon} alt="Gift" />
                      </div>
                      <div
                        className="description"
                        onClick={() => handleNotification(item)}
                      >
                        <span>{item.title}</span>
                        <small>{item.description}</small>
                      </div>
                    </ContainerDiv>
                  </>
                ))}
            </Column>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
}
