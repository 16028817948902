import React from 'react';
import PropTypes from 'prop-types';

import { Container, CircularProgressBar } from './styles';

export default function CircularProgress({ percentage, size }) {
  const barPercentage = parseFloat((percentage / 100) * 339.292, 10);

  return (
    <Container>
      <CircularProgressBar percentage={barPercentage} size={size} />
      <span>{percentage}%</span>
    </Container>
  );
}

CircularProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  size: PropTypes.number,
};

CircularProgress.defaultProps = {
  size: 120,
};
