import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import { useDispatch, useSelector } from 'react-redux';
import { onMessageListener, requestForToken } from '~/services/firebase';
import { toast } from 'react-toastify';

import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';

import AppSettings from '~/pages/AppSettings';
import ArchivedProfiles from '~/pages/ArchivedProfiles';
import ArchivedItems from '~/pages/ArchivedItems';

import Welcome from '~/pages/Welcome';
import OnBoarding from '~/pages/OnBoarding';
import StepTwo from '~/pages/StepTwo';
import OnBoardingSuccess from '~/pages/OnBoardingSuccess';
import AddGiftReceiver from '~/pages/AddGiftReceiver';
import EditReceiver from '~/pages/EditReceiver';
import FirstGift from '~/pages/FirstGift';
import AddGift from '~/pages/AddGift';
import EditGift from '~/pages/EditGift';
import AddedGiftsList from '~/pages/AddedGiftsList';
import GiftInstruction from '~/pages/GiftInstruction';
import GiftOptions from '~/pages/GiftOptions';
import SelectPromoType from '~/pages/SelectPromoType';
import AddEvent from '~/pages/AddEvent';
import EditEvent from '~/pages/EditEvent';
import EditPage from '~/pages/EditPage';
import ChooseGifts from '~/pages/ChooseGifts';
import InviteDesign from '~/pages/InviteDesign';
import InvitesEditDesign from '~/pages/InvitesEditDesign';
import ManageCards from '~/pages/ManageCards';
import ReceiverProfile from '~/pages/ReceiverProfile';
import EventGoal from '~/pages/EventGoal';
import Dashboard from '~/pages/Dashboard';
import EditProfile from '~/pages/EditProfile';
import MyProfile from '~/pages/MyProfile';
import Notifications from '~/pages/Notifications';
import LinkBank from '~/pages/LinkBank';
import AddCreditCard from '~/pages/AddCreditCard';
import CardPayment from '~/pages/CardPayment';
import GiftDetails from '~/pages/GiftDetails';
import EventContributors from '~/pages/EventContributors';
import EventRSVPs from '~/pages/EventRSVPs';
import GiftGoal from '~/pages/GiftGoal';
import EventType from '~/pages/EventType';
import ChooseEvent from '~/pages/ChooseEvent';
import EventDetails from '~/pages/EventDetails';
import ThankYouCards from '~/pages/ThankYouCards';
import CreateThankYouCards from '~/pages/CreateThankYouCards';
import ThankYouSent from '~/pages/CreateThankYouCards/Sent';
import AddInvitees from '~/pages/AddInvitees';
import AddInvitee from '~/pages/AddInvitee';
import EmailList from '~/pages/EmailList';
import MyContacts from '~/pages/MyContacts';
import ReviewContacts from '~/pages/ReviewContacts';
import InvitationSent from '~/pages/ReviewContacts/Sent';
import AddRecipients from '~/pages/AddRecipients';
import EventAlbum from '~/pages/EventAlbum';
import EditAlbum from '~/pages/EventAlbum/EditAlbum';
import SuccessScreen from '~/pages/SuccessScreen';
import PaymentsAndTransfers from '~/pages/PaymentsAndTransfers';
import EditBankAccount from '~/pages/EditBankAccount';
import BeforeDeleteBank from '~/pages/BeforeDeleteBank';
import BankGiftReceivers from '~/pages/BankGiftReceivers';
import ChangeBankAccount from '~/pages/ChangeBankAccount';
import DeleteBankAccount from '~/pages/DeleteBankAccount';

import ContributionNotification from '~/pages/ContributionNotification';
import TargetReached from '~/pages/TargetReached';
import FundingContinues from '~/pages/FundingContinues';
import GiftFunded from '~/pages/GiftFunded';
import AlmostThere from '~/pages/AlmostThere';
import PartyRSVP from '~/pages/PartyRSVP';
import PartyComing from '~/pages/PartyComing';
import EventNear from '~/pages/EventNear';
import EventNearEnd from '~/pages/EventNearEnd';
import LinkBankNotification from '~/pages/LinkBankNotification';
import CreateFirstEvent from '~/pages/CreateFirstEvent';
import BirthdayComing from '~/pages/BirthdayComing';

import RSVPGiftWish from '~/pages/RSVPGiftWish';

import ContributionGiftList from '~/pages/ContributionGiftList';
import ContributionOneGift from '~/pages/ContributionOneGift';
import MessageRecipient from '~/pages/MessageRecipient';
import WriteMessage from '~/pages/WriteMessage';
import RSVPOnly from '~/pages/RSVPOnly';
import RSVPConfirmed from '~/pages/RSVPConfirmed';
import RSVPClosedConfirmed from '~/pages/RSVPClosedConfirmed';
import ContributionThanks from '~/pages/ContributionThanks';
import RSVPCant from '~/pages/RSVPCant';
import RSVPHasGift from '~/pages/RSVPHasGift';
import FollowEvent from '~/pages/FollowEvent';
import EmailDetails from '~/pages/EmailDetails';
import OnboardingFinalStep from '~/pages/OnboardingFinalStep';
import OnboardingCompleted from '~/pages/OnboardingCompleted';
import ChangePassword from '~/pages/ChangePassword';

import RSVPGiftWishes from '~/pages/RSVPGiftWish';
import RSVPGiftWishCant from '~/pages/RSVPGiftWishCant';
import NoRSVP from '~/pages/NoRSVP';
import RSVPSkipOnly from '~/pages/RSVPSkipOnly';
import ContributionOneGifts from '~/pages/ContributionOneGifts';
import GiftContribution from '~/pages/GiftContribution';
import SelectCardPayment from '~/pages/SelectCardPayment';
import { getNotificationByUserId } from '~/store/modules/notification/notificationAction';
import NotificationList from '~/pages/NotificationList';
import NotificationLists from '~/pages/NotificationList';
import ThankyouCardDetails from '~/pages/ThankyouCardDetails';
import SendThankYouCard from '~/pages/SendThankYouCard';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';
import AboutUs from '~/pages/AboutUs';
import FAQs from '~/pages/FAQs';
import TermsAndConditions from '~/pages/TermsAndConditions';
import Cookies from '~/pages/Cookies';
import PrivacyPolicies from '~/pages/PrivacyPolicies';

export default function Routes() {
  const Dispatch = useDispatch();
  const profile = useSelector((state) => state.userReducer.profile);
  useEffect(() => {
    requestForToken();
    onMessageListener()
      .then((payload) => {
        console.log('@socketId', payload);
        Dispatch(getNotificationByUserId(profile.id));
        if (parseInt(payload.data.user_id) === profile.id) {
          if (payload.data && payload.data.notification_type) {
            toast.success(
              <>
                {payload.notification.title} <br />
                {payload.notification.body}
              </>,
            );
          } else {
            toast.success('New Notification Received');
          }
        }
      })
      .catch((err) => console.log('failed: ', err));
  }, [profile]);

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" component={SignUp} />
      <Route path="/rsvp-gift-wish/:path" component={RSVPGiftWish} allAccess />
      <Route path="/rsvp-gift-wish/" component={RSVPGiftWish} allAccess />
      <Route path="/rsvp-cant/:giftId" component={RSVPCant} allAccess />
      <Route path="/rsvp-cant/" component={RSVPCant} allAccess />
      <Route
        path="/contribution-gift-list/"
        component={ContributionGiftList}
        allAccess
      />
      <Route
        path="/contribution-one-gift/:giftId"
        component={ContributionOneGift}
        allAccess
      />
      <Route
        path="/gift-contribution/:giftId"
        component={GiftContribution}
        allAccess
      />

      <Route
        path="/contribution-one-gifts/:giftId"
        component={ContributionOneGifts}
        allAccess
      />
      <Route
        path="/message-recipient/"
        component={MessageRecipient}
        allAccess
      />
      <Route path="/write-message/" component={WriteMessage} allAccess />
      <Route path="/rsvp-only/" component={RSVPOnly} allAccess />
      <Route path="/rsvp-skip/" component={RSVPSkipOnly} allAccess />
      <Route path="/rsvp-has-gift/:giftId" component={RSVPHasGift} allAccess />
      <Route path="/rsvp-confirmed/:path" component={RSVPConfirmed} allAccess />
      <Route path="/rsvp-confirmed/" component={RSVPConfirmed} allAccess />
      <Route
        path="/rsvp-closed-confirmed/"
        component={RSVPClosedConfirmed}
        allAccess
      />
      <Route path="/no-rsvp/" component={NoRSVP} allAccess />
      <Route path="/follow-event/" component={FollowEvent} allAccess />
      <Route
        path="/contribution-thanks/:path"
        component={ContributionThanks}
        allAccess
      />
      <Route
        path="/contribution-thanks/"
        component={ContributionThanks}
        allAccess
      />
      <Route path="/app-settings" component={AppSettings} isPrivate />
      <Route path="/archived-profiles" component={ArchivedProfiles} isPrivate />
      <Route
        path="/archived-items/:userId"
        component={ArchivedItems}
        isPrivate
      />
      <Route path="/archived-items" component={ArchivedItems} isPrivate />
      <Route path="/welcome" component={Welcome} isPrivate />
      <Route path="/onboarding" component={OnBoarding} isPrivate />
      <Route
        path="/onboarding-success/:step"
        component={OnBoardingSuccess}
        isPrivate
      />
      <Route path="/step-2/" component={StepTwo} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/edit-profile" component={EditProfile} isPrivate />
      <Route path="/add-receiver/:type" component={AddGiftReceiver} isPrivate />
      <Route path="/add-receiver" component={AddGiftReceiver} isPrivate />
      <Route
        path="/edit-receiver/:receiverId"
        component={EditReceiver}
        isPrivate
      />
      <Route path="/first-gift" component={FirstGift} isPrivate />
      <Route path="/add-gift" component={AddGift} isPrivate />
      <Route path="/edit-gift/:giftId" component={EditGift} isPrivate />
      <Route path="/added-gifts" component={AddedGiftsList} isPrivate />
      <Route path="/select-promo" component={SelectPromoType} isPrivate />
      <Route path="/add-event" component={AddEvent} isPrivate />
      <Route path="/edit-event" component={EditEvent} isPrivate />
      <Route path="/edit-page" component={EditPage} isPrivate />
      <Route path="/choose-gifts" component={ChooseGifts} isPrivate />
      <Route path="/invite-design" component={InviteDesign} isPrivate />
      <Route path="/invite-designs" component={InvitesEditDesign} isPrivate />
      <Route path="/manage-cards/:type" component={ManageCards} isPrivate />
      <Route path="/manage-cards" component={ManageCards} isPrivate />
      <Route
        path="/receiver-profile/:receiverId/:tab"
        component={ReceiverProfile}
        isPrivate
      />
      <Route
        path="/receiver-profile/:receiverId"
        component={ReceiverProfile}
        isPrivate
      />
      <Route path="/event-goal" component={EventGoal} isPrivate />
      <Route
        path="/gift-instruction/:giftId"
        component={GiftInstruction}
        isPrivate
      />
      <Route path="/gift-instruction" component={GiftInstruction} isPrivate />
      <Route path="/link-bank/:path" component={LinkBank} isPrivate />
      <Route path="/link-bank" component={LinkBank} isPrivate />
      <Route path="/add-credit-card" component={AddCreditCard} isPrivate />
      <Route path="/card-payment" component={CardPayment} allAccess />
      <Route path="/my-profile" component={MyProfile} isPrivate />
      <Route path="/notifications" component={Notifications} isPrivate />
      <Route path="/gift-details/:id" component={GiftDetails} isPrivate />
      <Route path="/card_payment" component={SelectCardPayment} allAccess />

      <Route
        path="/event-contributors/:id"
        component={EventContributors}
        isPrivate
      />
      <Route path="/event-rsvps/:id" component={EventRSVPs} isPrivate />
      <Route path="/gift-goal" component={GiftGoal} isPrivate />
      <Route path="/event-type" component={EventType} isPrivate />
      <Route path="/choose-event" component={ChooseEvent} isPrivate />
      <Route path="/event-details/:id" component={EventDetails} isPrivate />
      <Route path="/thank-you-cards/" component={ThankYouCards} isPrivate />
      <Route
        path="/create-thank-you-cards/:page"
        component={CreateThankYouCards}
        isPrivate
      />
      <Route
        path="/create-thank-you-cards/"
        component={CreateThankYouCards}
        isPrivate
      />
      <Route
        path="/send-thank-you-card/:page"
        component={SendThankYouCard}
        isPrivate
      />
      <Route
        path="/send-thank-you-card/"
        component={SendThankYouCard}
        isPrivate
      />

      <Route
        path="/thank-you-card-detail/:id"
        component={ThankyouCardDetails}
        isPrivate
      />

      <Route path="/thank-you-sent/" component={ThankYouSent} isPrivate />
      <Route path="/email-details/" component={EmailDetails} isPrivate />
      <Route path="/add-invitees/" component={AddInvitees} isPrivate />
      <Route path="/add-invitee/" component={AddInvitee} isPrivate />
      <Route path="/email-list/" component={EmailList} isPrivate />
      <Route path="/my-contacts/" component={MyContacts} isPrivate />
      <Route path="/review-contacts/" component={ReviewContacts} isPrivate />
      <Route path="/invitation-sent/" component={InvitationSent} isPrivate />
      <Route path="/add-recipients/" component={AddRecipients} isPrivate />
      <Route
        path="/event-album/:eventId/post/:postId"
        component={EventAlbum}
        isPrivate
      />
      <Route
        path="/event-album/:eventId/edit"
        component={EditAlbum}
        isPrivate
      />
      <Route path="/event-album/:eventId" component={EventAlbum} isPrivate />
      <Route path="/success" component={SuccessScreen} allAccess />
      <Route
        path="/payment-details"
        component={PaymentsAndTransfers}
        isPrivate
      />
      <Route path="/edit-bank-account" component={EditBankAccount} isPrivate />
      <Route
        path="/before-delete-bank"
        component={BeforeDeleteBank}
        isPrivate
      />
      <Route
        path="/bank-gift-receivers"
        component={BankGiftReceivers}
        isPrivate
      />
      <Route
        path="/change-bank-account"
        component={ChangeBankAccount}
        isPrivate
      />
      <Route
        path="/delete-bank-account"
        component={DeleteBankAccount}
        isPrivate
      />
      <Route
        path="/contribution"
        component={ContributionNotification}
        isPrivate
      />
      <Route path="/target-reached" component={TargetReached} isPrivate />
      <Route path="/funding-continues" component={FundingContinues} isPrivate />
      <Route path="/gift-funded" component={GiftFunded} isPrivate />
      <Route path="/almost-there" component={AlmostThere} isPrivate />
      <Route path="/party-rsvp/:id" component={PartyRSVP} isPrivate />
      <Route path="/party-coming" component={PartyComing} isPrivate />
      <Route path="/event-near/" component={EventNear} isPrivate />
      <Route
        path="/link-bank-notification/"
        component={LinkBankNotification}
        isPrivate
      />
      <Route path="/final-step/" component={OnboardingFinalStep} isPrivate />
      <Route
        path="/onboarding-completed/"
        component={OnboardingCompleted}
        isPrivate
      />
      <Route path="/event-near-end/" component={EventNearEnd} isPrivate />
      <Route
        path="/create-first-event"
        component={CreateFirstEvent}
        isPrivate
      />
      <Route path="/birthday-coming/" component={BirthdayComing} isPrivate />
      <Route path="/gift-options/" component={GiftOptions} isPrivate />
      <Route path="/change-password/" component={ChangePassword} isPrivate />
      <Route
        path="/notification-list"
        component={NotificationLists}
        isPrivate
      />

      <Route
        path="/rspv-gift-wishes/:giftId"
        component={RSVPGiftWishes}
        allAccess
      />
      <Route
        path="/rsvp-gift-wish-cant/:giftId"
        component={RSVPGiftWishCant}
        allAccess
      />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/reset-password" exact component={ResetPassword} />
      <Route path="/about_us" component={AboutUs} isPrivate />
      <Route path="/faqs" component={FAQs} isPrivate />
      <Route path="/terms" component={TermsAndConditions} allAccess />
      <Route path="/cookies" component={Cookies} allAccess />
      <Route path="/privacy" component={PrivacyPolicies} allAccess />
    </Switch>
  );
}
