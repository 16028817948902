import styled, { css } from 'styled-components';

import { ReactComponent as SuccessCheck } from '~/assets/images/success-check.svg';
import { ReactComponent as OnBoardSteps } from '~/assets/images/onboard-steps.svg';

export const OuterContainer = styled.div`
  background-color: #f6f6f6;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  max-width: 720px;
  min-height: calc(100vh - 200px);
  margin: 40px auto;
  width: 90%;
  background: linear-gradient(0deg, #ed1e79, #93278f);
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 30px;
    margin: 0;
    height: 100%;
  }

  div {
    max-width: 370px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-bottom: 30px;
  }
`;

export const SuccessCheckSVG = styled(SuccessCheck)`
  max-width: 96px;

  #check {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: check 1s forwards 1s;
  }

  @keyframes check {
    from {
      stroke-dashoffset: 200;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  #circle {
    stroke-dasharray: 500;
    stroke-dashoffset: 0;
    animation: circle 1s ease-in-out;
  }

  @keyframes circle {
    from {
      stroke-dashoffset: 500;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export const OnBoardStepsSVG = styled(OnBoardSteps)`
  max-width: 340px;

  @media (max-width: 767px) {
    max-width: 290px;
  }

  ${({ step, theme }) =>
    css`
      #onboard-steps-success {
        g:nth-last-child(-n + ${step}) {
          g {
            stroke: ${theme.colors.secondary} !important;
            tspan {
              fill: ${theme.colors.secondary} !important;
            }

            path,
            circle {
              stroke: ${theme.colors.secondary} !important;
            }
          }

          path,
          circle {
            stroke: ${theme.colors.secondary} !important;
          }
        }
      }
    `}
`;
