import { createMuiTheme } from '@material-ui/core/styles';
import main from './main';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: main.colors.primary,
    },
    secondary: {
      main: main.colors.secondary,
    },
  },
  typography: {
    fontFamily: [
      'Text Alt',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
  },
});

export default theme;
