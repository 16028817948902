import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 720px;
  margin: 40px auto;
  width: 90%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 10px;
    margin: 0;
    min-height: calc(100% - 135px);
  }

  span {
    font-size: 20px;
    color: #888;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const BigButton = styled.button`
  padding: 20px 15px 20px 25px;
  margin-bottom: 18px;
  max-width: 398px;
  width: 95%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.pink};
  color: #fff;
  text-align: left;
  transition: all 0.5s;
  border: 0;
  cursor: pointer;

  @media (max-width: 767px) {
    padding: 0 5px 0 8px;
  }

  h2 {
    line-height: 1.2;
    max-width: 200px;
  }

  .image-wrapper {
    max-width: 70px;
    width: 100%;
    max-height: 56px;
    margin-right: 15px;

    @media (max-width: 767px) {
      margin-right: 10px;
    }

    svg {
      max-width: 70px;
      max-height: 56px;
      margin: 0 auto;

      #box {
        fill: ${({ theme }) => theme.colors.pink};
      }
    }
  }

  .text-content {
    flex: 1;
  }

  > svg {
    opacity: 0.4;
    transition: all 0.5s;
  }

  &:hover {
    background-color: ${({ backgroundColor, theme }) =>
      darken(0.08, backgroundColor || theme.colors.pink)};

    > svg {
      opacity: 1;
    }
  }
`;
