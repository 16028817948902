import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';

export const Container = styled(RadioGroup)`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    grid-template-columns: auto;
  }

  > label {
    background: white;
    box-shadow: 0px 0px 10px 0px #cacaca8a;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    max-height: 115px;

    .event-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 18px;
      text-transform: none;
      font-weight: normal;
      font-weight: bold;
    }
  }

  button {
    max-width: 100%;
    max-height: 50px;
  }
`;

export const CheckboxControl = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
  }
`;
