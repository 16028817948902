import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import history from '~/services/history';

import accounts from './accounts';

import SubHeader from '~/components/SubHeader';
import Button from '~/components/Button';

import { Container, InputWrapper, InputColumn, BankAccount } from './styles';

export default function DeleteBankAccount() {
  const { colors } = useContext(ThemeContext);

  const handleDelete = () => {
    history.push({
      pathname: '/success',
      state: {
        destination: `/payment-details`,
        message: 'Bank Account Deleted.',
        colors: { end: colors.lightPurple, start: colors.pink },
      },
    });
  };

  return (
    <>
      <SubHeader backgroundColor={colors.secondary} mobileDescVisible={false}>
        <h2>Delete Account</h2>
      </SubHeader>
      <Container>
        <form>
          <h2>You can now delete account</h2>
          <InputColumn>
            <InputWrapper>
              <BankAccount>
                <div>
                  <span>{accounts[1].name}</span>&nbsp;-&nbsp;
                  <span>{accounts[1].number}</span>
                </div>
              </BankAccount>

              <p>
                without affecting your ability to receive funds for your gift
                receivers.
              </p>
              <Button
                text="DELETE BANK ACCOUNT"
                buttonFunction={() => handleDelete()}
              />

              <button
                className="no-thanks"
                type="submit"
                onClick={() => history.push('/payment-details')}
              >
                No, thanks.
              </button>
            </InputWrapper>
          </InputColumn>
        </form>
      </Container>
    </>
  );
}
